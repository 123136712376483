<template>
  <v-tooltip bottom color="transparent">
    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on">
        <button
          @click="copyLink"
          class="text-button-reset mr-4"
          :disabled="disableButton"
          style="color: var(--v-info-darken1); white-space: nowrap"
        >
          <v-icon left color="var(--v-info-darken1)">mdi-link-variant</v-icon
          >{{
            $t(
              `account.team.userSettings.signUpLink.${
                teamIdpName ? "sso" : "regular"
              }.button`
            )
          }}<v-snackbar
            v-model="showCopySuccess"
            timeout="5000"
            color="success"
          >
            {{ $t("account.team.userSettings.signUpLink.success") }}
          </v-snackbar>
          <v-snackbar v-model="showCopyFail" timeout="5000" color="error">
            {{ $t("account.team.userSettings.signUpLink.failed") }}
          </v-snackbar>
        </button>
      </div>
    </template>
    <vitrue-tooltip :message="tooltipMessage" />
  </v-tooltip>
</template>

<script>
import { userTeamMemberInformation, getTeamSettings } from "@/customApi";
import VitrueTooltip from "@/components/common/VitrueTooltip.vue";
import { assessmentTypes } from "@/constants/constants.js";
import { AssessmentRedirectPages } from "@/services/authorization/url-redirect/redirect-constants.js";

export default {
  components: {
    VitrueTooltip
  },
  props: {
    refreshButton: Boolean,
    assessmentType: String
  },
  data() {
    return {
      loadingSucceeded: false,
      teamId: null,
      teamIdpName: null,
      allowShareableLink: false,
      showCopySuccess: false,
      showCopyFail: false
    };
  },
  async mounted() {
    await this.loadData();
  },
  computed: {
    disableButton() {
      return (
        !this.loadingSucceeded ||
        (!this.teamIdpName && !this.allowShareableLink)
      );
    },
    link() {
      const baseUrl = window.location.href.split("#")[0];
      let returnValue = null;
      if (this.teamIdpName) {
        returnValue = `${baseUrl}?teamid=${this.teamId}&idp=${this.teamIdpName}`;
      } else {
        returnValue = `${baseUrl}#/signUp?autojointeamid=${this.teamId}`;
      }

      if (this.assessmentType) {
        const page = AssessmentRedirectPages[this.assessmentType];
        returnValue += `&redirectPage=${page}&redirectAction=new`;
      }
      return returnValue;
    },
    tooltipMessage() {
      if (!this.loadingSucceeded) {
        return this.$t(`account.team.userSettings.signUpLink.loadingFailed`);
      }
      if (this.teamIdpName) {
        return this.$t(`account.team.userSettings.signUpLink.sso.tooltip`);
      }

      return this.$t(
        `account.team.userSettings.signUpLink.regular.${
          this.allowShareableLink ? "tooltip" : "disabledTooltip"
        }`
      );
    }
  },
  methods: {
    copyLink() {
      var component = this;
      navigator.clipboard.writeText(this.link).then(
        function () {
          component.showCopySuccess = true;
        },
        function () {
          component.showCopyFail = true;
        }
      );
    },
    async loadData() {
      try {
        let teamMemberInformation = await userTeamMemberInformation();
        this.teamId = teamMemberInformation.teamId;
        this.teamIdpName = teamMemberInformation.teamIDP;
        let teamSettings = await getTeamSettings();
        this.allowShareableLink = teamSettings.allowShareableLink;
        this.loadingSucceeded = true;
      } catch (ex) {
        this.$logger.captureException(ex);
      }
    }
  },
  watch: {
    async refreshButton(newVal) {
      if (newVal) {
        await this.loadData();
        this.$emit("buttonRefreshed");
      }
    }
  }
};
</script>
