<template>
  <vfl-callout
    backgroundColor="vflNeutralLight2"
    icon="Lightbulb"
    :icon-width="26"
  >
    <span class="align-self-center text-body-1"
      >Some of your pain has been with you for less than 3 months.
      <strong>Once pain lasts longer</strong>, it’s considered chronic and
      harder to address. Try to eliminate your pain with tips below before it
      got chronic.</span
    >
  </vfl-callout>
</template>
<script>
import VflCallout from "@/components/vitrue-foundation-library/VflCallout.vue";

export default {
  name: "MenopauseReportPainCallout",
  components: { VflCallout }
};
</script>
