<template>
  <svg
    width="641"
    height="637"
    viewBox="0 0 641 637"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M84.912 31.6703H41.7513C39.1855 31.6703 37.0977 33.7575 37.0977 36.3234V55.3216C37.0977 57.8874 39.1855 59.9746 41.7513 59.9746H84.912C87.4779 59.9746 89.5657 57.8874 89.5657 55.3216V54.9305H91.0848C93.2474 54.9305 95.0067 53.1705 95.0067 51.0079V40.6371C95.0067 38.4745 93.2474 36.7151 91.0848 36.7151H89.5657V36.3234C89.5657 33.7575 87.4779 31.6703 84.912 31.6703ZM84.912 34.2822C86.04 34.2822 86.9539 35.1961 86.9539 36.3234V39.327H91.0848C91.8082 39.327 92.3949 39.9137 92.3949 40.6371V51.0079C92.3949 51.7319 91.8082 52.3186 91.0848 52.3186H86.9539V55.3216C86.9539 56.4489 86.04 57.3627 84.912 57.3627H41.7513C40.624 57.3627 39.7095 56.4489 39.7095 55.3216V36.3234C39.7095 35.1961 40.624 34.2822 41.7513 34.2822H84.912Z"
      fill="#F08F22"
    />
    <path
      d="M46.8125 54.4082H44.5361C43.6433 54.4082 42.9199 53.6848 42.9199 52.792V38.4577C42.9199 37.5649 43.6433 36.8409 44.5361 36.8409H46.8125C47.7787 36.8409 48.5616 37.6244 48.5616 38.59V52.6591C48.5616 53.6253 47.7787 54.4082 46.8125 54.4082Z"
      fill="#F08F22"
    />
    <path
      d="M55.6308 54.4082H53.3545C52.4617 54.4082 51.7383 53.6848 51.7383 52.792V38.4577C51.7383 37.5649 52.4617 36.8409 53.3545 36.8409H55.6308C56.5965 36.8409 57.38 37.6244 57.38 38.59V52.6591C57.38 53.6253 56.5965 54.4082 55.6308 54.4082Z"
      fill="#FBDBB8"
    />
    <path
      d="M64.4492 54.4082H62.1728C61.2807 54.4082 60.5566 53.6848 60.5566 52.792V38.4577C60.5566 37.5649 61.2807 36.8409 62.1728 36.8409H64.4492C65.4155 36.8409 66.1983 37.6244 66.1983 38.59V52.6591C66.1983 53.6253 65.4155 54.4082 64.4492 54.4082Z"
      fill="#FBDBB8"
    />
    <path
      d="M73.2656 54.4082H70.9893C70.0965 54.4082 69.373 53.6848 69.373 52.792V38.4577C69.373 37.5649 70.0965 36.8409 70.9893 36.8409H73.2656C74.2319 36.8409 75.0147 37.6244 75.0147 38.59V52.6591C75.0147 53.6253 74.2319 54.4082 73.2656 54.4082Z"
      fill="#FBDBB8"
    />
    <path
      d="M82.084 54.4082H79.8076C78.9148 54.4082 78.1914 53.6848 78.1914 52.792V38.4577C78.1914 37.5649 78.9148 36.8409 79.8076 36.8409H82.084C83.0496 36.8409 83.8331 37.6244 83.8331 38.59V52.6591C83.8331 53.6253 83.0496 54.4082 82.084 54.4082Z"
      fill="#FBDBB8"
    />
  </svg>
</template>
<script>
export default {
  name: "TirednessOverlay"
};
</script>
