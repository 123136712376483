<template>
  <div class="d-flex" :style="widthStyle">
    <img :src="src" :alt="altText" />
  </div>
</template>

<script>
const PATH = "/images/brand/";

export default {
  name: "VidaLogo",
  props: {
    isIcon: {
      type: Boolean,
      default: false
    },
    isDark: {
      type: Boolean,
      default: false
    },
    width: {
      type: [Number, String],
      default: null
    }
  },
  computed: {
    src() {
      const filename = this.isDark
        ? this.isIcon
          ? "vida-logo-icon-white.svg"
          : "vida-logo-white.png"
        : this.isIcon
        ? "vida-logo-icon.svg"
        : "vida-logo.svg";

      return `${PATH}${filename}`;
    },
    widthStyle() {
      if (this.width === null) {
        const defaultWidth = this.isIcon ? 30 : 100;
        return { width: `${defaultWidth}px` };
      }

      if (typeof this.width === "number") {
        return { width: `${this.width}px` };
      }

      return { width: this.width };
    },
    altText() {
      return this.$t("layout.logo.alt");
    }
  }
};
</script>

<style scoped lang="scss">
img {
  height: auto;
  width: 100%;
}
</style>
