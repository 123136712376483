export const SimsItem = {
  BEAN_BAG: "beanBag",
  CHAIR_NO_ARMREST: "chairNoArmrest",
  CHAIR_WITH_ARMREST: "chairWithArmrest",
  CLUTTER: "clutter",
  ERGONOMIC_KEYBOARD: "ergonomicKeyboard",
  ERGONOMIC_MOUSE: "ergonomicMouse",
  GYM_BALL: "gymBall",
  KEYBOARD: "keyboard",
  KNEELING_CHAIR: "kneelingChair",
  LAPTOP_STAND: "laptopStand",
  MICRO_DESK: "microDesk",
  MOUSE: "mouse",
  SADDLE_CHAIR: "saddleChair",
  STACK_OF_PAPER: "stackOfPaper",
  STOOL: "stool"
};

export const ReportSeedQuestionId = {
  REPORT_SUMMARY: "report-summary",
  REPORT_SUMMARY_BAD_SCORE: "report-summary-bad-score",
  BIGGEST_RISK: "biggest-risk",
  IMMEDIATE_BEST_ACTION: "immediate-best-action",
  IMMEDIATE_QUICK_FIX_ACTION: "immediate-quick-fix-action",
  OPTIMISING_WORKSPACE_AND_HABITS: "optimising-workspace-and-habits",
  BE_PROACTIVE_NO_PAIN: "be-proactive-no-pain",
  BE_PROACTIVE_PAIN: "be-proactive-pain",
  PRODUCTIVITY_SATISFACTION_BOOST_PRODUCTIVITY:
    "productivity-satisfaction-boost-productivity",
  PRODUCTIVITY_SATISFACTION_IMPROVE_SETUP:
    "productivity-satisfaction-improve-setup",
  PRODUCTIVITY_SATISFACTION_WORK_HABITS_RETENTION:
    "productivity-satisfaction-work-habits-retention",
  WELLNESS_INITIATIVE_EMPLOYEE_BENEFIT: "wellness-initiative-employee-benefit",
  WELLNESS_INITIATIVE_IMPROVE_ROI: "wellness-initiative-improve-roi",
  COMPLICATING_FACTORS: "complicating-factors",
  HEALTH_CLAIMS: "health-claims"
};

export const ReportCardSeedQuestionId = {
  BREAK_PAIN_1: "break_pain_1",
  BREAK_BEHAVIOUR_1: "break_behaviour_1",
  BREAK_BEHAVIOUR_2: "break_behaviour_2",
  BREAK_BEHAVIOUR_3: "break_behaviour_3",
  BREAK_BEHAVIOUR_4: "break_behaviour_4",
  CHAIR_EQUIPMENT_1: "chair_equipment_1",
  CHAIR_EQUIPMENT_2: "chair_equipment_2",
  CHAIR_BEHAVIOUR_1: "chair_behaviour_1",
  CHAIR_PAIN_1: "chair_pain_1",
  CHAIR_EQUIPMENT_3: "chair_equipment_3",
  CHAIR_PAIN_2: "chair_pain_2",
  CHAIR_EQUIPMENT_4: "chair_equipment_4",
  CHAIR_PAIN_3: "chair_pain_3",
  CHAIR_EQUIPMENT_5: "chair_equipment_5",
  CHAIR_PAIN_4: "chair_pain_4",
  HOURS_PAIN_1: "hours_pain_1",
  HOURS_BEHAVIOUR_1: "hours_behaviour_1",
  HOURS_PAIN_2: "hours_pain_2",
  KEYBOARD_EQUIPMENT_1: "keyboard_equipment_1",
  KEYBOARD_BEHAVIOUR_1: "keyboard_behaviour_1",
  KEYBOARD_BEHAVIOUR_2: "keyboard_behaviour_2",
  KEYBOARD_EQUIPMENT_2: "keyboard_equipment_2",
  KEYBOARD_EQUIPMENT_3: "keyboard_equipment_3",
  KEYBOARD_EQUIPMENT_4: "keyboard_equipment_4",
  KEYBOARD_BEHAVIOUR_3: "keyboard_behaviour_3",
  LAPTOP_EQUIPMENT_1: "laptop_equipment_1",
  LAPTOP_EQUIPMENT_2: "laptop_equipment_2",
  LAPTOP_EQUIPMENT_3: "laptop_equipment_3",
  LAPTOP_EQUIPMENT_4: "laptop_equipment_4",
  MUSCLE_STRAIN_EQUIPMENT_1: "muscleStrain_equipment_1",
  MUSCLE_STRAIN_PAIN_1: "muscleStrain_pain_1",
  MUSCLE_STRAIN_EQUIPMENT_2: "muscleStrain_equipment_2",
  MUSCLE_STRAIN_EQUIPMENT_3: "muscleStrain_equipment_3",
  MUSCLE_STRAIN_EQUIPMENT_4: "muscleStrain_equipment_4",
  MUSCLE_STRAIN_EQUIPMENT_5: "muscleStrain_equipment_5",
  MUSCLE_STRAIN_EQUIPMENT_6: "muscleStrain_equipment_6",
  MUSCLE_STRAIN_EQUIPMENT_7: "muscleStrain_equipment_7",
  MUSCLE_STRAIN_BEHAVIOUR_1: "muscleStrain_behaviour_1",
  STANDING_DESK_BEHAVIOUR_1: "standingDesk_behaviour_1",
  STANDING_DESK_BEHAVIOUR_2: "standingDesk_behaviour_2",
  STANDING_DESK_BEHAVIOUR_3: "standingDesk_behaviour_3",
  STANDING_DESK_EQUIPMENT_1: "standingDesk_equipment_1",
  STANDING_DESK_EQUIPMENT_2: "standingDesk_equipment_2",
  ALTERNATE_CHAIR_PAIN_1: "alternativeErgonomicSeatCard_pain_1",
  ALTERNATE_CHAIR_EQUIPMENT_1: "alternativeErgonomicSeatCard_equipment_1",
  ALTERNATE_CHAIR_EQUIPMENT_2: "alternativeErgonomicSeatCard_equipment_2"
};
