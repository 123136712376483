<template>
  <div class="pa-4">
    <footer-controls />
  </div>
</template>

<script>
import FooterControls from "@/components/messaging/input/FooterControls.vue";

export default {
  name: "MessagingFooter",
  components: {
    FooterControls
  }
};
</script>
