<template>
  <transition name="fade">
    <div
      v-if="isVisible || loading"
      class="overlay rounded-lg"
      :class="{ visible: showOverlay }"
    >
      <div v-if="loading" class="loader">
        <v-progress-circular indeterminate size="64" color="white" />
      </div>

      <template v-else>
        <div
          class="confetti-container"
          v-if="currentState === $options.InteractionStates.WANT_TO_TRY"
        >
          <canvas
            :id="`confettiCanvas${recommendationId}`"
            ref="confettiCanvas"
            style="z-index: 5; pointer-events: none; width: 100%; height: 100%"
          />
        </div>

        <v-tooltip
          bottom
          :disabled="interactable"
          color="transparent"
          v-if="stateDetails.icon"
        >
          <template v-slot:activator="{ on, attrs }">
            <div v-on="on" v-bind="attrs" class="button-container">
              <v-hover v-slot="{ hover }">
                <div>
                  <v-btn
                    class="rounded-circle"
                    :class="{
                      'block-interaction': preventInteraction
                    }"
                    width="100"
                    height="100"
                    min-width="100"
                    :color="stateDetails.color"
                    @click="resetState"
                    :loading="settingState"
                  >
                    <img
                      width="36px"
                      height="36px"
                      :src="
                        hover ? '/images/icons/close.svg' : stateDetails.icon
                      "
                    />
                    <template v-slot:loader>
                      <v-progress-circular
                        size="36"
                        width="3"
                        indeterminate
                        color="white"
                      />
                    </template>
                  </v-btn>
                </div>
              </v-hover>
            </div>
          </template>
          <vitrue-tooltip :message="disabledTooltipMessage" />
        </v-tooltip>

        <recommendation-card-feedback
          class="feedback"
          v-if="showFeedback"
          :id="recommendationId"
        />
      </template>
    </div>
  </transition>
</template>

<script>
import {
  getInteractionStateDetails,
  updateRecommendationStatus,
  InteractionStates
} from "./recommendation-interaction-service.js";
import { LinkTypes } from "@/services/recommendations/link-types.js";
import VitrueTooltip from "@/components/common/VitrueTooltip.vue";
import VueConfetti from "vue-confetti";
import Vue from "vue";
import RecommendationCardFeedback from "@/components/common/report/cards/Recommendation/RecommendationCardFeedback.vue";
Vue.use(VueConfetti);

export default {
  name: "RecommendationOverlay",
  components: {
    RecommendationCardFeedback,
    VitrueTooltip
  },
  props: {
    currentState: {
      type: String,
      default: InteractionStates.NONE
    },
    linkType: String,
    interactable: Boolean,
    recommendationId: String,
    assessmentId: String,
    loading: Boolean,
    userInteraction: Boolean
  },
  LinkTypes: LinkTypes,
  InteractionStates: InteractionStates,
  data() {
    return {
      settingState: false,
      isVisible: this.currentState !== InteractionStates.NONE
    };
  },
  watch: {
    currentState(newVal, oldVal) {
      if (newVal !== InteractionStates.NONE) {
        this.isVisible = true;
      } else {
        setTimeout(() => {
          this.isVisible = false;
        }, 200);
      }

      if (
        newVal === InteractionStates.WANT_TO_TRY &&
        oldVal === InteractionStates.NONE
      ) {
        this.$nextTick(() => {
          this.setupConfetti();
        });
      }
    }
  },
  computed: {
    stateDetails() {
      return getInteractionStateDetails(this.currentState);
    },
    showOverlay() {
      return this.currentState !== InteractionStates.NONE || this.loading;
    },
    preventInteraction() {
      return (
        this.linkType === LinkTypes.APPOINTMENT ||
        (this.linkType === LinkTypes.REQUEST &&
          this.currentState === InteractionStates.COMPLETED) ||
        !this.interactable
      );
    },
    showFeedback() {
      return (
        this.userInteraction &&
        this.currentState === InteractionStates.NOT_INTERESTED
      );
    },
    disabledTooltipMessage() {
      if (this.currentState === InteractionStates.NOT_INTERESTED) {
        return this.$t("deskAssessmentRecommendationCards.userIsNotInterested");
      }
      return this.$t("deskAssessmentRecommendationCards.userHasCompletedCard");
    }
  },
  methods: {
    async resetState() {
      if (this.preventInteraction) {
        return;
      }

      this.settingState = true;
      await updateRecommendationStatus(
        this.assessmentId,
        this.recommendationId,
        InteractionStates.NONE
      );
      this.$emit("close");
      this.settingState = false;
    },
    setupConfetti() {
      const canvas = document.getElementById(
        `confettiCanvas${this.recommendationId}`
      );
      if (!canvas) {
        return;
      }
      this.$confetti.start({
        canvasElement: canvas,
        particlesPerFrame: 0.75,
        particles: [
          {
            type: "rect",
            size: 5,
            dropRate: 3
          }
        ],
        defaultColors: ["#F39848", "#F03589", "#3FB288", "#0E6ADF"]
      });

      setTimeout(() => {
        this.$confetti.stop();
      }, 2000);
    }
  }
};
</script>

<style scoped lang="scss">
.overlay {
  display: grid;
  grid-template-rows: 1fr auto 1fr;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-image: linear-gradient(
    rgba(0, 0, 0, 0.3),
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0.7)
  );
  z-index: 2;
  overflow: hidden;
}

.loader {
  display: grid;
  grid-row: 2;
  place-content: center;
}

.button-container {
  display: grid;
  grid-row: 2;
  place-content: center;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.125s ease-out;
}

.block-interaction {
  pointer-events: none;
}

.confetti-container {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  pointer-events: none;
  overflow: hidden;
}

.feedback {
  grid-row: 3;
  margin: 2rem 1rem auto;
}
</style>
