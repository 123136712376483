import i18n from "@/plugins/i18n";
import {
  formatDate,
  formatWithLocale
} from "@/services/date-formatting-service";

export function getConversationHeader(
  conversations,
  conversationIndex,
  conversation
) {
  if (conversations.length === conversationIndex + 1) {
    return i18n.t("aiAssistant.conversationHeaders.new");
  }

  const date = new Date(conversation.messages[0]?.createdAt || Date.now());
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);

  const formatTime = date => formatWithLocale(date, "h:mm a");

  if (date.toDateString() === today.toDateString()) {
    return i18n.t("aiAssistant.conversationHeaders.today", {
      time: formatTime(date)
    });
  }

  if (date.toDateString() === yesterday.toDateString()) {
    return i18n.t("aiAssistant.conversationHeaders.yesterday", {
      time: formatTime(date)
    });
  }

  // For dates within the current year or older
  const formattedDate = formatDate(date);
  return i18n.t("aiAssistant.conversationHeaders.pastDate", {
    date: formattedDate,
    time: formatTime(date)
  });
}
