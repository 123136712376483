<template>
  <div id="msk-health-culture-container" class="d-flex flex-column">
    <msk-health-culture-header class="mb-4" :loading="loading" />

    <vfl-alert
      v-if="error && !loading"
      :message="$t('mskOverview.error')"
      type="error"
      is-light
    />

    <msk-health-culture-not-enough-data
      v-if="!meetsMinSampleSizeThreshold && !loading"
      class="mb-4"
    />

    <div
      class="msk-health-culture-cards"
      :class="{ 'is-sample-data': !meetsMinSampleSizeThreshold }"
    >
      <template v-if="loading">
        <v-skeleton-loader
          v-for="(_, index) in cardCount"
          :key="index"
          type="image"
          class="msk-health-culture-card-loader"
        />
      </template>
      <template v-else>
        <msk-health-culture-card
          v-for="card in displayCardData"
          :key="card.key"
          :label="$t(card.label)"
          :stat="card.stat"
          :image-url="card.imageUrl"
          :tooltip-text="card.tooltipText ? $t(card.tooltipText) : undefined"
          :card-key="card.key"
        />
      </template>
    </div>
  </div>
</template>

<script>
import VflAlert from "@/components/vitrue-foundation-library/VflAlert.vue";
import MskHealthCultureHeader from "@/components/dashboard/cards/msk-health-culture/MskHealthCultureHeader.vue";
import { getMskHealthCulture } from "@/api/teamApi.js";
import MskHealthCultureCard from "@/components/dashboard/cards/msk-health-culture/MskHealthCultureCard.vue";
import { mskHealthCultureCardMapping } from "@/components/dashboard/cards/msk-health-culture/msk-health-culture.service.js";
import { mapGetters } from "vuex";
import MskHealthCultureNotEnoughData from "@/components/dashboard/cards/msk-health-culture/MskHealthCultureNotEnoughData.vue";
import { MINIMUM_SAMPLE_SIZE_FOR_ANALYTICS } from "@/components/dashboard/cards/constants.js";

export default {
  name: "MskHealthCulture",
  components: {
    MskHealthCultureNotEnoughData,
    MskHealthCultureCard,
    MskHealthCultureHeader,
    VflAlert
  },
  data() {
    return {
      apiData: null,
      loading: true,
      error: false
    };
  },
  computed: {
    ...mapGetters(["demoFeatures"]),
    ...mapGetters("adminDashboard", [
      "dashboardDemoMetricsState",
      "usePredefinedDashboardMetrics"
    ]),
    cardData() {
      if (!this.apiData) return [];

      return mskHealthCultureCardMapping
        .map(card => ({ ...card, stat: this.apiData[card.key] }))
        .filter(
          card =>
            !(
              card.key === "percentageThinkPostureAffectsPain" &&
              parseFloat(card.stat) === 0
            )
        );
    },
    cardCount() {
      return mskHealthCultureCardMapping.length;
    },
    usePredefinedMetrics() {
      return this.demoFeatures && this.usePredefinedDashboardMetrics;
    },
    meetsMinSampleSizeThreshold() {
      if (this.usePredefinedMetrics) return true;

      return this.apiData?.totalAssessed >= MINIMUM_SAMPLE_SIZE_FOR_ANALYTICS;
    },
    displayCardData() {
      return this.meetsMinSampleSizeThreshold
        ? this.cardData
        : this.getDummyCardData();
    }
  },
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      this.loading = true;

      try {
        this.apiData = this.usePredefinedMetrics
          ? this.dashboardDemoMetricsState
          : await getMskHealthCulture();
      } catch (ex) {
        this.$logger.captureException(ex);
        this.error = true;
      } finally {
        this.loading = false;
      }
    },
    getDummyCardData() {
      return mskHealthCultureCardMapping.map(card => ({
        ...card,
        stat: card.dummyValue
      }));
    }
  }
};
</script>

<style scoped lang="scss">
.msk-health-culture-cards {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 1rem;

  > div {
    flex: 0 0 278px;
  }

  &.is-sample-data {
    position: relative;

    &:after {
      background-color: hsla(0, 0%, 100%, 0.5);
      bottom: 0;
      content: "";
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
    }
  }
}

.msk-health-culture-card-loader {
  ::v-deep {
    > div {
      height: 104px;
    }
  }
}
</style>
