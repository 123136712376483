<template>
  <svg
    width="641"
    height="637"
    viewBox="0 0 641 637"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M123.659 125.991H130.242C130.772 125.991 131.201 126.42 131.201 126.949V179.389C131.201 179.918 130.772 180.348 130.242 180.348H72.441C71.9114 180.348 71.4824 179.918 71.4824 179.389V126.949C71.4824 126.42 71.9114 125.991 72.441 125.991H79.0244"
      fill="#EF2F4C"
    />
    <path d="M127.041 138.73H75.6406V175.847H127.041V138.73Z" fill="white" />
    <path
      d="M80.7647 131.639C79.803 131.639 79.0234 130.859 79.0234 129.898V122.084C79.0234 121.121 79.803 120.342 80.7647 120.342C81.7263 120.342 82.5059 121.121 82.5059 122.084V129.898C82.5059 130.859 81.7263 131.639 80.7647 131.639Z"
      fill="#1B242D"
    />
    <path
      d="M91.0518 131.639C90.0901 131.639 89.3105 130.859 89.3105 129.898V122.084C89.3105 121.121 90.0901 120.342 91.0518 120.342C92.0134 120.342 92.793 121.121 92.793 122.084V129.898C92.793 130.859 92.0134 131.639 91.0518 131.639Z"
      fill="#1B242D"
    />
    <path
      d="M101.341 131.639C100.379 131.639 99.5996 130.859 99.5996 129.898V122.084C99.5996 121.121 100.379 120.342 101.341 120.342C102.302 120.342 103.082 121.121 103.082 122.084V129.898C103.082 130.859 102.302 131.639 101.341 131.639Z"
      fill="#1B242D"
    />
    <path
      d="M111.628 131.639C110.666 131.639 109.887 130.859 109.887 129.898V122.084C109.887 121.121 110.666 120.342 111.628 120.342C112.59 120.342 113.369 121.121 113.369 122.084V129.898C113.369 130.859 112.59 131.639 111.628 131.639Z"
      fill="#1B242D"
    />
    <path
      d="M121.917 131.639C120.955 131.639 120.176 130.859 120.176 129.898V122.084C120.176 121.121 120.955 120.342 121.917 120.342C122.879 120.342 123.658 121.121 123.658 122.084V129.898C123.658 130.859 122.879 131.639 121.917 131.639Z"
      fill="#1B242D"
    />
    <path
      d="M90.8647 150.495H79.5039V142.043H90.8647V150.495Z"
      fill="#D6E5E9"
    />
    <path
      d="M107.021 150.495H95.6602V142.043H107.021V150.495Z"
      fill="#D6E5E9"
    />
    <path
      d="M123.177 150.495H111.816V142.043H123.177V150.495Z"
      fill="#D6E5E9"
    />
    <path
      d="M90.8647 161.515H79.5039V153.062H90.8647V161.515Z"
      fill="#D6E5E9"
    />
    <path
      d="M107.021 161.515H95.6602V153.062H107.021V161.515Z"
      fill="#D6E5E9"
    />
    <path
      d="M123.177 161.515H111.816V153.062H123.177V161.515Z"
      fill="#D6E5E9"
    />
    <path
      d="M90.8647 172.534H79.5039V164.082H90.8647V172.534Z"
      fill="#D6E5E9"
    />
    <path
      d="M107.021 172.534H95.6602V164.082H107.021V172.534Z"
      fill="#D6E5E9"
    />
    <path
      d="M123.177 172.534H111.816V164.082H123.177V172.534Z"
      fill="#D6E5E9"
    />
    <g clip-path="url(#clip0_3355_24246)">
      <path
        d="M563.054 268.616H407.17H399.777V255.614V192.377V63.8646H563.054V135.862V201.357V268.616Z"
        fill="#A8C9F3"
      />
      <path
        d="M511.195 157.247C511.195 153.496 514.236 150.456 517.986 150.456C518.664 150.456 519.318 150.558 519.937 150.743C519.838 150.062 519.784 149.366 519.784 148.658C519.784 140.715 526.224 134.276 534.167 134.276C535.072 134.276 535.956 134.363 536.815 134.524C539.025 130.907 542.999 128.483 547.549 128.483C554.435 128.483 560.022 134.017 560.125 140.878L560.334 140.868C566.733 140.868 571.919 146.055 571.919 152.454C571.919 158.851 566.733 164.039 560.334 164.039H517.787L517.796 164.029C514.134 163.927 511.195 160.934 511.195 157.247Z"
        fill="white"
      />
      <path
        d="M471.284 109.293C471.284 105.542 468.242 102.502 464.491 102.502C463.813 102.502 463.159 102.604 462.54 102.789C462.641 102.108 462.694 101.412 462.694 100.704C462.694 92.7609 456.255 86.3221 448.312 86.3221C447.407 86.3221 446.521 86.409 445.662 86.5694C443.452 82.9513 439.478 80.53 434.929 80.53C428.042 80.53 422.455 86.0627 422.354 92.9237L422.144 92.9141C415.746 92.9141 410.559 98.1006 410.559 104.5C410.559 110.897 415.746 116.085 422.144 116.085H464.692L464.682 116.075C468.343 115.973 471.284 112.98 471.284 109.293Z"
        fill="white"
      />
      <path
        d="M439.748 201.718C439.748 199.384 441.64 197.492 443.974 197.492C444.395 197.492 444.803 197.556 445.188 197.671C445.125 197.247 445.093 196.814 445.093 196.374C445.093 191.431 449.099 187.424 454.042 187.424C454.604 187.424 455.156 187.479 455.69 187.579C457.065 185.327 459.537 183.821 462.369 183.821C466.654 183.821 470.13 187.263 470.193 191.532L470.323 191.526C474.304 191.526 477.533 194.753 477.533 198.735C477.533 202.716 474.304 205.944 470.323 205.944H443.85L443.855 205.938C441.577 205.874 439.748 204.012 439.748 201.718Z"
        fill="white"
      />
    </g>
    <path
      d="M563.054 63.2582V268.618H399.778V61.737C398.823 61.9725 397.869 62.2143 396.92 62.4657C395.972 62.714 395.033 62.9717 394.098 63.239V279.043H568.738V68.3658C566.886 66.6219 564.989 64.9162 563.054 63.2582Z"
      fill="#002822"
    />
    <path
      d="M568.737 68.3652H394.094V59.7188H568.737V68.3652Z"
      fill="#002822"
    />
    <path
      d="M584.198 279.039H377.314C377.314 273.352 381.926 268.744 387.612 268.744H573.9C579.587 268.744 584.198 273.352 584.198 279.039Z"
      fill="#002822"
    />
    <path
      d="M486.672 67.8048V273.358H480.756V66.185C482.732 66.691 484.708 67.232 486.672 67.8048Z"
      fill="#002822"
    />
    <g clip-path="url(#clip1_3355_24246)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M404.714 329.671C404.714 237.677 371.846 150.411 371.846 150.411H360.604V329.671H404.714Z"
        fill="#F7BE7D"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M404.714 41.6221C404.714 97.4521 371.846 150.411 371.846 150.411H360.604V41.6221H404.714Z"
        fill="#F7BE7D"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M376.635 158.253L356.504 159.517V143.99L376.635 145.254V158.253Z"
        fill="#A8C5C1"
      />
      <path
        d="M380.904 227.904C376.235 196.921 372.107 170.183 366.242 154.904C370.054 171.881 370.809 197.526 373.28 227.904C376.225 264.128 375.137 301.636 374.746 328.799C374.746 328.799 378.106 329.538 380.274 329.557C382.59 329.577 386.182 328.6 386.182 328.6C385.595 307.096 386.29 263.65 380.904 227.904Z"
        fill="#E29946"
      />
    </g>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M574.609 288.463H591.711V278.584H574.609V288.463Z"
      fill="#B6D4FF"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M558.482 329.706C558.482 237.712 590.047 150.446 590.047 150.446H600.844V329.706C600.844 329.706 587.952 337.611 579.268 337.499C570.883 337.39 558.482 329.706 558.482 329.706Z"
      fill="#F7BE7D"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M558.482 41.6572C558.482 97.4872 590.047 150.447 590.047 150.447H600.844V41.6572H558.482Z"
      fill="#F7BE7D"
    />
    <path
      d="M585.03 49.5283H573.594C571.483 88.9144 584.834 124.651 592.946 139.505C579.458 99.6098 582.293 64.2415 585.03 49.5283Z"
      fill="#E29946"
    />
    <path
      d="M374.962 41.6211H386.398C388.509 81.0072 375.159 116.743 367.046 131.598C380.534 91.7025 377.699 56.3343 374.962 41.6211Z"
      fill="#E29946"
    />
    <path
      d="M578.874 235.848C583.542 204.865 587.671 178.127 593.535 162.848C589.723 179.824 588.968 205.47 586.498 235.848C583.552 272.071 584.641 309.579 585.032 336.742C585.032 336.742 581.672 337.482 579.503 337.501C577.187 337.521 573.596 336.543 573.596 336.543C574.182 315.039 573.488 271.593 578.874 235.848Z"
      fill="#E29946"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M585.115 158.287L603.928 159.55V144.023L585.115 145.287V158.287Z"
      fill="#A8C5C1"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M585.113 158.285L603.926 159.548V151.661L585.113 152.925V158.285Z"
      fill="#658C7D"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M376.412 157.943L356.506 159.248V151.104L376.412 152.41V157.943Z"
      fill="#658C7D"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M342.289 49.8051H617.739V41.6572H342.289V49.8051Z"
      fill="#A8C5C1"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M348.887 45.7319L342.29 55.2719L335.693 45.7319L342.29 36.1924L348.887 45.7319Z"
      fill="#A8C5C1"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M624.334 46.7178L617.737 56.2578L611.141 46.7178L617.737 37.1777L624.334 46.7178Z"
      fill="#A8C5C1"
    />
    <path
      d="M247.752 567.649L247.949 567.645C249.539 567.611 250.768 566.074 250.622 564.31L239.995 439.153L230.721 440.127L245.981 565.958C246.1 566.94 246.863 567.668 247.752 567.649Z"
      fill="#085A4F"
    />
    <path
      d="M145.437 567.649L145.244 567.645C143.649 567.611 142.421 566.074 142.571 564.31L153.194 439.153L162.468 440.127L147.208 565.958C147.089 566.94 146.326 567.668 145.437 567.649Z"
      fill="#085A4F"
    />
    <path
      d="M322.715 567.649L322.912 567.645C324.502 567.611 325.735 566.074 325.585 564.31L314.958 439.153L305.684 440.127L320.944 565.958C321.063 566.94 321.826 567.668 322.715 567.649Z"
      fill="#085A4F"
    />
    <path
      d="M148.602 420.947L129.741 311.097C127.319 296.226 137.768 282.626 151.337 282.992L187.715 283.97C200.263 284.309 211.171 294.211 214.661 308.435L242.257 420.947H148.602Z"
      fill="#085A4F"
    />
    <path
      d="M160.012 420.947L141.15 311.097C138.732 296.226 149.181 282.626 162.75 282.992L199.124 283.97C211.673 284.309 222.584 294.211 226.07 308.435L253.666 420.947H160.012Z"
      fill="#0A7F63"
    />
    <path
      d="M156.609 440.982H295.023C299.429 440.982 303.03 437.381 303.03 432.975V428.954C303.03 424.552 299.429 420.947 295.023 420.947H148.602V432.975C148.602 437.381 152.203 440.982 156.609 440.982Z"
      fill="#085A4F"
    />
    <path
      d="M223.612 440.982H310.923C314.47 440.982 317.374 438.078 317.374 434.531V427.398C317.374 423.851 314.47 420.947 310.923 420.947H223.612C220.064 420.947 217.16 423.851 217.16 427.398V434.531C217.16 438.078 220.064 440.982 223.612 440.982Z"
      fill="#0A7F63"
    />
    <path
      d="M424.649 319.485H390.975C390.975 312.603 396.556 307.026 403.438 307.026H411.288H427.083L424.649 319.485Z"
      fill="#B0B4AB"
    />
    <path
      d="M433.488 337.471H320.271L327.851 259.634H441.068L433.488 337.471Z"
      fill="#0A7F63"
    />
    <path
      d="M437.582 337.471H324.365L331.945 259.634H445.162L437.582 337.471Z"
      fill="#45391D"
    />
    <path
      d="M387.432 349.284H367.338L373.909 304.429H394.002L387.432 349.284Z"
      fill="#0A7F63"
    />
    <path d="M355.537 347.94H396.78V351.172H355.537V347.94Z" fill="#0A7F63" />
    <path
      d="M282.44 532.1C282.44 532.1 278.747 522.051 271.421 511.786C269.091 508.524 277.58 506.602 279.525 512.437C281.47 518.269 286.33 534.642 286.33 534.642L282.44 532.1Z"
      fill="#002822"
    />
    <path
      d="M327.35 554.47C327.35 554.47 316.103 535.196 304.637 540.565C299.553 542.942 299.703 549.798 306.971 554.901C310.95 557.697 326.907 566.363 326.907 566.363L327.35 554.47Z"
      fill="#002822"
    />
    <path
      d="M302.176 504.774C302.176 504.774 297.053 518.493 298.139 523.581C299.225 528.669 296.649 537.605 298.732 542.019C300.812 546.429 296.549 550.199 292.978 551.825C291.622 552.437 286.908 540.597 286.007 536.992C285.106 533.387 282.163 525.923 279.143 520.146C276.128 514.364 278.658 509.704 283.018 507.582C287.378 505.456 293.456 493.82 293.456 493.82L302.176 504.774Z"
      fill="#987759"
    />
    <path
      d="M278.173 227.25C272.939 220.857 263.217 219.131 263.217 219.131C244.067 203.043 227.479 225.929 224.197 234.326C220.916 242.718 218.928 256.977 205.09 263.755C180.505 275.795 207.204 296.363 207.204 296.363C218.35 271.755 270.339 315.644 267.955 317.597C316.546 339.084 290.078 241.798 278.173 227.25Z"
      fill="#070707"
    />
    <path
      d="M234.346 301.011C234.346 301.011 243.385 317.264 256.061 330.752C262.755 337.878 270.466 344.229 278.392 347.002C282.259 348.354 286.176 348.851 290.055 348.173C292.528 347.742 291.453 333.137 288.014 322.032C283.488 307.408 269.372 271.072 269.372 271.072L261.295 274.516C261.295 274.516 272.496 320.28 271.163 320.473L259.315 290.5L234.346 301.011Z"
      fill="#987759"
    />
    <path
      d="M217.086 375.16C217.086 375.16 303.87 380.278 315.64 388.278C335.233 401.597 307.101 510.974 307.101 510.974C307.101 510.974 283.822 511.717 281.974 505.555C281.974 505.555 286.16 448.401 282.494 438.53C278.831 428.658 281.092 414.973 281.092 414.973C281.092 414.973 235.558 421.922 221.096 418.501C206.637 415.081 217.086 375.16 217.086 375.16Z"
      fill="#17282F"
    />
    <path
      d="M317.461 526.057C317.461 526.057 322.399 539.846 326.513 543.031C330.626 546.216 335.648 554.886 340.085 556.912C344.522 558.938 345.169 560.001 343.49 563.548C342.855 564.889 329.255 567.153 326.239 564.981C323.219 562.809 322.961 558.468 309.781 552.66C303.815 550.033 302.675 545.003 304.636 540.566C306.596 536.129 303.73 523.315 303.73 523.315L317.461 526.057Z"
      fill="#B28B67"
    />
    <path
      d="M166.045 371.955C166.045 371.955 285.559 394.009 293.786 403.515C302.017 413.021 321.914 531.484 321.914 531.484C321.914 531.484 312.405 536.822 303.731 531.884C303.731 531.884 283.121 488.943 275.634 473.537C264.287 450.196 269.071 437.317 267.264 436.904C265.462 436.492 191.693 429.398 170.517 415.386C158.038 407.128 166.045 371.955 166.045 371.955Z"
      fill="#223A43"
    />
    <path
      d="M259.404 334.148C255.753 337.927 257.482 339.637 257.482 341.239C257.482 347.355 256.612 348.796 252.468 350.98C247.453 353.618 241.61 354.997 240.274 359.014L240.27 359.022C239.996 359.854 239.911 360.805 240.069 361.899C243.085 382.655 223.666 393.555 213.081 398.393C206.53 401.389 194.355 404.755 183.347 405.953C171.095 407.29 160.284 405.934 160.265 398.393C160.072 333.193 184.811 296.603 202.933 282.961C209.234 278.22 228.207 272.978 240.069 278.316C245.689 280.843 254.216 284.159 259.316 290.498C264.82 297.339 271.163 320.475 271.163 320.475C269.091 332.658 259.404 334.148 259.404 334.148Z"
      fill="#C5CFD6"
    />
    <path
      d="M205.09 306.099C205.19 306.561 203.379 322.945 214.264 339.288C218.231 345.246 200.73 342.808 212.512 355.561C213.852 351.543 247.453 353.616 252.468 350.977L230.129 331.492L205.09 306.099Z"
      fill="#AFB9C5"
    />
    <path
      d="M268.736 274.902C268.736 274.902 274.548 267.98 275.565 265.885C276.585 263.786 274.679 254.754 272.31 254.816C270.816 254.85 268.975 260.323 267.342 262.311C265.709 264.298 258.599 267.129 261.607 275.487C261.946 276.427 268.736 274.902 268.736 274.902Z"
      fill="#987759"
    />
    <path
      d="M253.962 288.744C255.599 291.205 253.6 298.1 244.306 295.954C238.205 294.545 226.315 284.65 228.461 279.6C229.481 277.197 235.332 271.562 239.006 256.911L240.073 257.415L257.964 265.951C257.964 265.951 256.026 271.77 254.59 277.351C253.38 282.039 253.284 287.727 253.962 288.744Z"
      fill="#B28B67"
    />
    <path
      d="M257.963 265.952C257.963 265.952 256.025 271.772 254.589 277.353C244.12 274.371 240.997 263.333 240.072 257.417L257.963 265.952Z"
      fill="#987759"
    />
    <path
      d="M268.152 269.156C268.152 269.156 243.07 274.972 239.685 259.296C236.303 243.62 230.787 233.818 246.66 229.438C262.528 225.059 266.942 230.367 269.346 235.324C271.745 240.281 274.588 267.238 268.152 269.156Z"
      fill="#B28B67"
    />
    <path
      d="M258.042 226.593C258.042 226.593 257.88 244.634 244.596 253.388C236.823 258.511 236.457 263.757 236.457 263.757C236.457 263.757 231.855 248.32 231.123 236.707C231.123 236.707 250.963 218.955 258.042 226.593Z"
      fill="#070707"
    />
    <path
      d="M256.463 229.778C256.463 229.778 263.873 232.247 267.174 234.974C272.058 239.018 273.329 252.16 272.747 255.434C272.747 255.434 274.08 232.59 266.723 226.77C258.928 220.596 256.463 229.778 256.463 229.778Z"
      fill="#070707"
    />
    <path
      d="M205.086 306.092C207.64 312.177 210.594 317.901 213.725 323.089C225.696 342.878 240.332 354.833 245.616 349.26C247.08 347.719 250.77 345.478 246.914 337.193C243.933 330.792 240.243 322.788 236.623 314.908C229.159 298.662 221.995 282.959 221.995 282.959C219.715 282.959 195.735 283.795 205.086 306.092Z"
      fill="#B28B67"
    />
    <path
      d="M230.129 331.494C230.95 331.001 276.079 341.963 290.056 345.375C292.621 345.999 294.135 346.373 294.135 346.373V351.638C294.135 351.638 247.438 353.829 240.147 350.479C232.852 347.132 225.893 339.336 225.893 339.336L230.129 331.494Z"
      fill="#B28B67"
    />
    <path
      d="M205.086 306.092C207.64 312.177 210.594 317.901 213.725 323.089C223.478 321.822 232.479 317.269 236.623 314.908C229.159 298.662 221.995 282.959 221.995 282.959C219.715 282.959 195.735 283.795 205.086 306.092Z"
      fill="#AFB9C5"
    />
    <path
      d="M290.057 345.376C290.057 345.376 304.92 341.105 309.056 342.284C311.976 343.116 315.704 347.433 317.645 349.906C318.212 350.626 317.692 351.681 316.775 351.674C314.287 351.647 310.262 351.481 308.159 350.376C306.172 349.332 303.214 351.631 299.921 351.647C297.39 351.658 294.135 351.639 294.135 351.639L290.057 345.376Z"
      fill="#B28B67"
    />
    <path
      d="M319.301 559.065C319.301 559.065 326.357 549.551 335.273 547.768C335.273 547.768 349.871 554.47 351.165 566.363H326.908L319.301 559.065Z"
      fill="#002822"
    />
    <path
      d="M282.441 532.101C283.254 532.05 295.66 529.759 297.998 533.98C300.756 538.949 300.671 550.812 294.035 554.085C292.509 554.836 290.665 554.089 290.068 552.495L282.441 532.101Z"
      fill="#002822"
    />
    <path
      d="M213.08 398.392C206.528 401.388 194.353 404.754 183.346 405.952C186.046 405.475 227.658 389.317 217.085 375.159C208.339 363.438 204.853 356.625 215.714 354.888C219.647 354.26 235.662 358.247 240.268 359.021C239.995 359.853 239.91 360.804 240.068 361.898C243.084 382.654 223.664 393.554 213.08 398.392Z"
      fill="#AFB9C5"
    />
    <path
      d="M314.86 351.171C314.86 351.171 310.97 346.152 304.65 346.872C298.325 347.596 294.135 351.637 294.135 351.637L314.86 351.171Z"
      fill="#0A7F63"
    />
    <path
      d="M515.668 363.438H209.411C206.01 363.438 203.252 360.681 203.252 357.28C203.252 353.879 206.01 351.121 209.411 351.121H515.668C519.069 351.121 521.823 353.879 521.823 357.28C521.823 360.681 519.069 363.438 515.668 363.438Z"
      fill="#002822"
    />
    <path
      d="M206.415 566.346C204.67 566.131 203.846 564.498 204.069 562.791L230.633 357.279L237.959 357.707L208.899 564.113C208.672 565.434 207.774 566.512 206.415 566.346Z"
      fill="#002822"
    />
    <path
      d="M514.984 566.346C516.725 566.131 517.549 564.498 517.329 562.791L490.761 357.279L483.439 357.707L512.496 564.113C512.727 565.434 513.62 566.512 514.984 566.346Z"
      fill="#002822"
    />
    <path
      d="M310.164 342.736L319.558 345.49C319.558 345.49 319.019 346.461 317.236 346.299C315.452 346.137 311.508 345.544 311.508 345.544L310.164 342.736Z"
      fill="#F5C99D"
    />
    <mask
      id="mask0_3355_24246"
      style="mask-type: luminance"
      maskUnits="userSpaceOnUse"
      x="558"
      y="480"
      width="63"
      height="45"
    >
      <path
        d="M563.128 505.34C563.128 505.34 552.718 493.196 562.485 485.482C562.485 485.482 569.623 481.16 575.44 481.51C575.44 481.51 567.195 495.338 569.861 496.712C572.528 498.087 577.938 480.645 577.938 480.645C577.938 480.645 594.175 479.07 599.4 481.58C599.4 481.58 585.519 492.941 588.443 493.616C591.364 494.294 606.36 483.143 606.36 483.143C606.36 483.143 621.473 485.714 620.664 494.934C619.859 504.156 614.653 507.001 612.365 506.756C610.077 506.511 596.685 501.4 596.396 503.274C596.103 505.152 604.164 511.278 609.806 511.568C609.806 511.568 599.152 523.317 592.071 519.924C584.99 516.535 584.386 511.157 579.969 509.143C575.555 507.125 571.787 507.131 573.61 509.187C575.434 511.243 583.421 513.242 585.945 517.706C588.472 522.171 590.734 525.185 583.409 524.081C576.083 522.98 564.175 517.617 563.65 511.479C563.128 505.34 563.128 505.34 563.128 505.34Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_3355_24246)">
      <path
        d="M563.128 505.34C563.128 505.34 552.718 493.196 562.485 485.482C562.485 485.482 569.623 481.16 575.44 481.51C575.44 481.51 567.195 495.338 569.861 496.712C572.528 498.087 577.938 480.645 577.938 480.645C577.938 480.645 594.175 479.07 599.4 481.58C599.4 481.58 585.519 492.941 588.443 493.616C591.364 494.294 606.36 483.143 606.36 483.143C606.36 483.143 621.473 485.714 620.664 494.934C619.859 504.156 614.653 507.001 612.365 506.756C610.077 506.511 596.685 501.4 596.396 503.274C596.103 505.152 604.164 511.278 609.806 511.568C609.806 511.568 599.152 523.317 592.071 519.924C584.99 516.535 584.386 511.157 579.969 509.143C575.555 507.125 571.787 507.131 573.61 509.187C575.434 511.243 583.421 513.242 585.945 517.706C588.472 522.171 590.734 525.185 583.409 524.081C576.083 522.98 564.175 517.617 563.65 511.479C563.128 505.34 563.128 505.34 563.128 505.34Z"
        fill="#036A44"
      />
    </g>
    <mask
      id="mask1_3355_24246"
      style="mask-type: luminance"
      maskUnits="userSpaceOnUse"
      x="553"
      y="495"
      width="61"
      height="25"
    >
      <path
        d="M613.243 495.181C613.243 495.181 572.197 493.596 563.131 505.336C563.131 505.336 561.126 511.341 554.958 516.808L553.994 519.431C553.994 519.431 561.11 511.93 563.653 511.475C563.653 511.475 559.675 496.932 613.243 495.181Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask1_3355_24246)">
      <path
        d="M613.243 495.181C613.243 495.181 572.197 493.596 563.131 505.336C563.131 505.336 561.126 511.341 554.958 516.808L553.994 519.431C553.994 519.431 561.11 511.93 563.653 511.475C563.653 511.475 559.675 496.932 613.243 495.181Z"
        fill="#06B777"
      />
    </g>
    <mask
      id="mask2_3355_24246"
      style="mask-type: luminance"
      maskUnits="userSpaceOnUse"
      x="545"
      y="515"
      width="13"
      height="21"
    >
      <path
        d="M554.956 516.81C554.956 516.81 546.144 523.327 545.533 534.968L547.131 535.111C547.131 535.111 549.944 523.073 555.032 518.366C560.118 513.659 554.956 516.81 554.956 516.81Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask2_3355_24246)">
      <path
        d="M554.956 516.81C554.956 516.81 546.144 523.327 545.533 534.968L547.131 535.111C547.131 535.111 549.944 523.073 555.032 518.366C560.118 513.659 554.956 516.81 554.956 516.81Z"
        fill="url(#paint0_linear_3355_24246)"
      />
    </g>
    <mask
      id="mask3_3355_24246"
      style="mask-type: luminance"
      maskUnits="userSpaceOnUse"
      x="465"
      y="447"
      width="73"
      height="57"
    >
      <path
        d="M526.733 490.066C526.733 490.066 543.294 480.793 535.568 468.207C535.568 468.207 529.384 460.477 522.759 458.491C522.759 458.491 526.294 477.257 522.759 477.7C519.226 478.142 520.331 456.502 520.331 456.502C520.331 456.502 502.888 448.114 496.043 448.776C496.043 448.776 506.863 467.103 503.327 466.66C499.795 466.218 487.651 447.671 487.651 447.671C487.651 447.671 469.766 444.358 466.896 454.956C464.025 465.556 468.662 470.855 471.313 471.517C473.961 472.178 490.964 471.959 490.522 474.167C490.079 476.376 478.597 479.908 472.194 477.919C472.194 477.919 479.259 495.365 488.536 494.48C497.809 493.599 500.68 487.858 506.421 487.416C512.161 486.973 516.356 488.52 513.485 490.066C510.615 491.61 500.899 490.572 496.263 494.515C491.626 498.455 487.871 500.886 496.485 502.653C505.097 504.419 520.553 503.314 523.643 496.689C526.733 490.066 526.733 490.066 526.733 490.066Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask3_3355_24246)">
      <path
        d="M526.733 490.066C526.733 490.066 543.294 480.793 535.568 468.207C535.568 468.207 529.384 460.477 522.759 458.491C522.759 458.491 526.294 477.257 522.759 477.7C519.226 478.142 520.331 456.502 520.331 456.502C520.331 456.502 502.888 448.114 496.043 448.776C496.043 448.776 506.863 467.103 503.327 466.66C499.795 466.218 487.651 447.671 487.651 447.671C487.651 447.671 469.766 444.358 466.896 454.956C464.025 465.556 468.662 470.855 471.313 471.517C473.961 472.178 490.964 471.959 490.522 474.167C490.079 476.376 478.597 479.908 472.194 477.919C472.194 477.919 479.259 495.365 488.536 494.48C497.809 493.599 500.68 487.858 506.421 487.416C512.161 486.973 516.356 488.52 513.485 490.066C510.615 491.61 500.899 490.572 496.263 494.515C491.626 498.455 487.871 500.886 496.485 502.653C505.097 504.419 520.553 503.314 523.643 496.689C526.733 490.066 526.733 490.066 526.733 490.066Z"
        fill="#06B777"
      />
    </g>
    <mask
      id="mask4_3355_24246"
      style="mask-type: luminance"
      maskUnits="userSpaceOnUse"
      x="475"
      y="458"
      width="57"
      height="52"
    >
      <path
        d="M475.061 458.267C475.061 458.267 521.43 473.281 526.729 490.065C526.729 490.065 526.509 497.572 531.146 506.183V509.496C531.146 509.496 526.29 498.234 523.639 496.687C523.639 496.687 534.017 482.115 475.061 458.267Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask4_3355_24246)">
      <path
        d="M475.061 458.267C475.061 458.267 521.43 473.281 526.729 490.065C526.729 490.065 526.509 497.572 531.146 506.183V509.496C531.146 509.496 526.29 498.234 523.639 496.687C523.639 496.687 534.017 482.115 475.061 458.267Z"
        fill="#036A44"
      />
    </g>
    <mask
      id="mask5_3355_24246"
      style="mask-type: luminance"
      maskUnits="userSpaceOnUse"
      x="528"
      y="504"
      width="8"
      height="27"
    >
      <path
        d="M531.145 506.185C531.145 506.185 538.296 517.043 534.22 530.263L532.38 529.769C532.38 529.769 534.165 515.21 530.423 507.884C526.684 500.562 531.145 506.185 531.145 506.185Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask5_3355_24246)">
      <path
        d="M531.145 506.185C531.145 506.185 538.296 517.043 534.22 530.263L532.38 529.769C532.38 529.769 534.165 515.21 530.423 507.884C526.684 500.562 531.145 506.185 531.145 506.185Z"
        fill="url(#paint1_linear_3355_24246)"
      />
    </g>
    <mask
      id="mask6_3355_24246"
      style="mask-type: luminance"
      maskUnits="userSpaceOnUse"
      x="538"
      y="420"
      width="95"
      height="75"
    >
      <path
        d="M552.725 477.275C552.725 477.275 530.897 465.048 541.084 448.459C541.084 448.459 549.234 438.272 557.966 435.65C557.966 435.65 553.307 460.392 557.966 460.975C562.622 461.557 561.167 433.031 561.167 433.031C561.167 433.031 584.16 421.972 593.185 422.844C593.185 422.844 578.922 447.004 583.577 446.422C588.236 445.84 604.243 421.39 604.243 421.39C604.243 421.39 627.821 417.024 631.605 430.994C635.389 444.964 629.276 451.95 625.782 452.825C622.291 453.697 599.877 453.407 600.46 456.316C601.042 459.228 616.177 463.883 624.617 461.264C624.617 461.264 615.305 484.26 603.079 483.095C590.855 481.931 587.072 474.363 579.504 473.781C571.936 473.198 566.405 475.235 570.189 477.275C573.973 479.311 586.779 477.94 592.892 483.137C599.005 488.333 603.954 491.535 592.602 493.864C581.251 496.194 560.875 494.736 556.801 486.007C552.725 477.275 552.725 477.275 552.725 477.275Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask6_3355_24246)">
      <path
        d="M552.725 477.275C552.725 477.275 530.897 465.048 541.084 448.459C541.084 448.459 549.234 438.272 557.966 435.65C557.966 435.65 553.307 460.392 557.966 460.975C562.622 461.557 561.167 433.031 561.167 433.031C561.167 433.031 584.16 421.972 593.185 422.844C593.185 422.844 578.922 447.004 583.577 446.422C588.236 445.84 604.243 421.39 604.243 421.39C604.243 421.39 627.821 417.024 631.605 430.994C635.389 444.964 629.276 451.95 625.782 452.825C622.291 453.697 599.877 453.407 600.46 456.316C601.042 459.228 616.177 463.883 624.617 461.264C624.617 461.264 615.305 484.26 603.079 483.095C590.855 481.931 587.072 474.363 579.504 473.781C571.936 473.198 566.405 475.235 570.189 477.275C573.973 479.311 586.779 477.94 592.892 483.137C599.005 488.333 603.954 491.535 592.602 493.864C581.251 496.194 560.875 494.736 556.801 486.007C552.725 477.275 552.725 477.275 552.725 477.275Z"
        fill="#06B777"
      />
    </g>
    <mask
      id="mask7_3355_24246"
      style="mask-type: luminance"
      maskUnits="userSpaceOnUse"
      x="546"
      y="435"
      width="75"
      height="68"
    >
      <path
        d="M620.834 435.364C620.834 435.364 559.708 455.155 552.723 477.278C552.723 477.278 553.016 487.172 546.902 498.523V502.89C546.902 502.89 553.305 488.047 556.799 486.011C556.799 486.011 543.119 466.799 620.834 435.364Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask7_3355_24246)">
      <path
        d="M620.834 435.364C620.834 435.364 559.708 455.155 552.723 477.278C552.723 477.278 553.016 487.172 546.902 498.523V502.89C546.902 502.89 553.305 488.047 556.799 486.011C556.799 486.011 543.119 466.799 620.834 435.364Z"
        fill="#036A44"
      />
    </g>
    <mask
      id="mask8_3355_24246"
      style="mask-type: luminance"
      maskUnits="userSpaceOnUse"
      x="541"
      y="495"
      width="10"
      height="36"
    >
      <path
        d="M547.256 498.522C547.256 498.522 537.83 512.839 543.205 530.263L545.63 529.61C545.63 529.61 543.275 510.418 548.208 500.766C553.137 491.114 547.256 498.522 547.256 498.522Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask8_3355_24246)">
      <path
        d="M547.256 498.522C547.256 498.522 537.83 512.839 543.205 530.263L545.63 529.61C545.63 529.61 543.275 510.418 548.208 500.766C553.137 491.114 547.256 498.522 547.256 498.522Z"
        fill="url(#paint2_linear_3355_24246)"
      />
    </g>
    <mask
      id="mask9_3355_24246"
      style="mask-type: luminance"
      maskUnits="userSpaceOnUse"
      x="511"
      y="525"
      width="82"
      height="44"
    >
      <path
        d="M551.958 568.045C574.362 568.045 592.524 548.903 592.524 525.284H511.393C511.393 548.903 529.555 568.045 551.958 568.045Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask9_3355_24246)">
      <path
        d="M551.958 568.045C574.362 568.045 592.524 548.903 592.524 525.284H511.393C511.393 548.903 529.555 568.045 551.958 568.045Z"
        fill="#FF9B21"
      />
    </g>
    <mask
      id="mask10_3355_24246"
      style="mask-type: luminance"
      maskUnits="userSpaceOnUse"
      x="508"
      y="564"
      width="88"
      height="7"
    >
      <path
        d="M595.691 570.224H508.988C508.988 567.312 511.35 564.951 514.261 564.951H590.415C593.33 564.951 595.691 567.312 595.691 570.224Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask10_3355_24246)">
      <path
        d="M568.091 564.915H536.422C536.422 567.913 537.284 570.344 538.348 570.344H566.164C567.228 570.344 568.091 567.913 568.091 564.915Z"
        fill="#E87613"
      />
    </g>
    <path
      d="M183.347 466.384V469.845C183.347 471.057 182.954 472.05 182.476 472.05H56.0699C55.5882 472.05 55.1953 471.057 55.1953 469.845V466.384C55.1953 465.172 55.5882 464.179 56.0699 464.179H182.476C182.954 464.179 183.347 465.172 183.347 466.384Z"
      fill="#B15E00"
    />
    <path
      d="M183.345 466.384V469.845C183.345 471.057 182.982 472.05 182.545 472.05H82.193C81.7521 472.05 81.3926 471.057 81.3926 469.845V466.384C81.3926 465.172 81.7521 464.179 82.193 464.179H182.545C182.982 464.179 183.345 465.172 183.345 466.384Z"
      fill="#E29946"
    />
    <path d="M151.352 602.85H61.6133V472.049H151.352V602.85Z" fill="#E29946" />
    <mask
      id="mask11_3355_24246"
      style="mask-type: luminance"
      maskUnits="userSpaceOnUse"
      x="86"
      y="472"
      width="91"
      height="131"
    >
      <path d="M176.5 602.851H86.7578V472.05H176.5V602.851Z" fill="white" />
    </mask>
    <g mask="url(#mask11_3355_24246)">
      <path d="M176.5 602.851H86.7578V472.05H176.5V602.851Z" fill="#F7BE7D" />
    </g>
    <path
      d="M92.4474 594.765H170.814V476.612H92.4474V594.765ZM171.681 595.629H91.584V475.745H171.681V595.629Z"
      fill="#B15E00"
    />
    <path d="M171.247 516.268H92.0176V515.4H171.247V516.268Z" fill="#B15E00" />
    <path
      d="M171.247 556.701H92.0176V555.834H171.247V556.701Z"
      fill="#B15E00"
    />
    <path
      d="M139.751 483.477H125.051C124.336 483.477 123.754 482.891 123.754 482.176C123.754 481.464 124.336 480.879 125.051 480.879H139.751C140.466 480.879 141.048 481.464 141.048 482.176C141.048 482.891 140.466 483.477 139.751 483.477Z"
      fill="#B15E00"
    />
    <path
      d="M139.751 523.012H125.051C124.336 523.012 123.754 522.43 123.754 521.715C123.754 521.003 124.336 520.418 125.051 520.418H139.751C140.466 520.418 141.048 521.003 141.048 521.715C141.048 522.43 140.466 523.012 139.751 523.012Z"
      fill="#B15E00"
    />
    <path
      d="M139.751 564.226H125.051C124.336 564.226 123.754 563.644 123.754 562.929C123.754 562.217 124.336 561.632 125.051 561.632H139.751C140.466 561.632 141.048 562.217 141.048 562.929C141.048 563.644 140.466 564.226 139.751 564.226Z"
      fill="#B15E00"
    />
    <defs>
      <linearGradient
        id="paint0_linear_3355_24246"
        x1="545.533"
        y1="525.443"
        x2="557.273"
        y2="525.443"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#3E815B" />
        <stop offset="1" stop-color="#277455" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_3355_24246"
        x1="528.93"
        y1="517.193"
        x2="535.477"
        y2="517.193"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#3E815B" />
        <stop offset="1" stop-color="#277455" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_3355_24246"
        x1="541.547"
        y1="513.034"
        x2="550.176"
        y2="513.034"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#3E815B" />
        <stop offset="1" stop-color="#277455" />
      </linearGradient>
      <clipPath id="clip0_3355_24246">
        <rect
          width="163.276"
          height="204.752"
          fill="white"
          transform="translate(399.777 63.8652)"
        />
      </clipPath>
      <clipPath id="clip1_3355_24246">
        <rect
          width="48.8608"
          height="218.064"
          fill="white"
          transform="translate(356.502 41.6221)"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "SymptomsBase"
};
</script>
