import store from "@/store.js";
import i18n from "@/plugins/i18n";

export const aiFeaturesDisabledMessage = (isHtml = false) => {
  const Messages = {
    Trial: {
      TEXT: i18n.t("aiSearch.disabledMessage.text"),
      HTML: i18n.t("aiSearch.disabledMessage.html")
    }
  };

  if (store.getters?.isTrialUser || store.getters?.subscriptionExpired) {
    return isHtml ? Messages.Trial.HTML : Messages.Trial.TEXT;
  }

  // Fallback
  return Messages.Trial.TEXT;
};
