<template>
  <v-card rounded="lg" class="fill-height px-5 py-3">
    <v-row
      class="fill-height"
      align-content="stretch"
      justify="center"
      no-gutters
    >
      <v-col
        cols="12"
        id="title"
        class="text-h6 justify-center pa-0 text-center"
        align-self="start"
      >
        {{ $t("deskAssessment.results.painContent.painDetails.title") }}
      </v-col>

      <v-col cols="10" align-self="start" style="max-width: 300px">
        <pain-person
          id="painPerson"
          class="ma-4"
          v-model="results.pain"
          disabled
          assessmentType="deskAssessment"
        />
      </v-col>
      <v-col cols="12" align-self="end" class="mt-5" v-if="showPainDetails">
        <p
          id="painDetailsText"
          class="vitrueDarkGrey--text font-weight-medium text-body-1"
        >
          {{ $t("deskAssessment.results.painContent.painDetails.description") }}
        </p>
        <div
          class="py-2 container"
          :class="$vuetify.breakpoint.xs ? '' : 'scollContainer'"
        >
          <v-row
            v-for="pain in orderedPainAreas"
            :key="pain.area"
            no-gutters
            class="my-2 px-2"
            justify="center"
            :class="pain.area === painArea ? 'grow' : ''"
            @click="$emit('painAreaSelected', pain.area)"
            :id="`row${pain.area}`"
            :style="`opacity:${
              pain.area === painArea ? 1 : 0.5
            };cursor: pointer;`"
          >
            <v-col
              cols="12"
              sm="3"
              xl="4"
              align-self="center"
              :class="$vuetify.breakpoint.xs ? 'mb-3' : ''"
            >
              <v-row no-gutters align="center">
                <v-col
                  v-if="showIcons"
                  cols="auto"
                  :class="showIcons ? 'mr-3' : ''"
                >
                  <v-img
                    :id="`painImage${pain.area}`"
                    class="rounded-circle circleShadow"
                    width="40"
                    height="40"
                    :src="`/images/${painAreaImage(pain.area)}`"
                  />
                </v-col>
                <v-col
                  :id="`painText${pain.area}`"
                  cols="auto"
                  sm="7"
                  md="12"
                  xl="8"
                  class="text-body-1"
                >
                  {{
                    $t(`reportText.pain.painAreaNames.${pain.area}.title`)
                  }}</v-col
                >
              </v-row>
            </v-col>
            <v-col cols="11" sm="9" xl="8" class="my-3">
              <v-slider
                :id="`painSlider${pain.area}`"
                :value="getPainLikelihoodPercentage(pain)"
                color="sliderRed"
                readonly
                track-color="vitrueMidGrey"
                dense
                hide-details
                thumb-label="always"
                thumb-color="transparent"
              >
                <template #thumb-label>
                  <div class="black--text sliderLabelParent">
                    <div class="sliderLabel" :id="`thumbLabel${pain.area}`">
                      {{ getLabel(pain) }}
                    </div>
                    <div>▼</div>
                  </div>
                </template>
              </v-slider>
              <v-row no-gutters justify="space-between" class="text-caption">
                <v-col cols="auto">{{
                  $t(
                    "deskAssessment.results.painContent.painDetails.labels.noEffect"
                  )
                }}</v-col>
                <v-col cols="auto">{{
                  $t(
                    "deskAssessment.results.painContent.painDetails.labels.highImpact"
                  )
                }}</v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>
      </v-col>
      <v-col v-if="moreThanTwoPainAreas && showPainDetails" align="end">
        <v-row justify="center" align-content="end" no-gutters
          ><v-col cols="auto"
            ><v-btn
              id="showMoreButton"
              text
              small
              color="primary"
              @click="showAllPainAreas = !showAllPainAreas"
              class="mt-2"
              >{{
                showAllPainAreas
                  ? $t(
                      "deskAssessment.results.painContent.painDetails.button.hideExtra"
                    )
                  : $t(
                      "deskAssessment.results.painContent.painDetails.button.showAll"
                    )
              }}</v-btn
            ></v-col
          ></v-row
        >
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import PainPerson from "@/components/painareas/PainPerson.vue";
import WellnessGoalImages from "@/assets/json/wellness/WellnessGoalImages.json";
import {
  getMappedPainLikelihoodPercentage,
  baseMappedPercentages
} from "@/services/deskassessment/desk-assessment-summary";

import { getRankedPainAreas } from "@/services/assessment/pain/pain-rating-service.js";

export default {
  name: "PainDetails",
  components: {
    PainPerson
  },
  props: {
    results: Object,
    painArea: String,
    showPainDetails: {
      type: Boolean,
      default: true
    },
    assessmentType: String
  },
  data() {
    return {
      showAllPainAreas: false
    };
  },
  computed: {
    moreThanTwoPainAreas() {
      return Object.keys(this.results.pain.areas).length > 2;
    },
    orderedPainAreas() {
      var orderedAreas = getRankedPainAreas(this.results, this.assessmentType);
      return this.showAllPainAreas ? orderedAreas : orderedAreas.slice(0, 2);
    },
    showIcons() {
      return this.$vuetify.breakpoint.smAndDown || this.$vuetify.breakpoint.xl;
    }
  },
  methods: {
    painAreaImage(area) {
      return WellnessGoalImages.ReducePain[area];
    },
    getPainLikelihoodPercentage(area) {
      return getMappedPainLikelihoodPercentage(
        area.group,
        area.likelihoodScore
      );
    },
    getLabel(area) {
      var base = "deskAssessment.results.painContent.painDetails.labels";
      var label = baseMappedPercentages[area.likelihoodRating].label;
      return this.$t(`${base}.${label}`);
    }
  }
};
</script>

<style scoped>
.circleShadow {
  box-shadow: 0px 0px 3px 1px var(--v-disabled-base);
}

/* scroll bar styles
/* Works on Firefox */
* {
  scrollbar-width: thin;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 6px;
}

*::-webkit-scrollbar-track {
  background: white;
}

*::-webkit-scrollbar-thumb {
  background-color: lightgray;
  border-radius: 20px;
  border: 5px solid lightgray;
}

.grow {
  transform: scale(1.05);
}

>>> .v-slider--horizontal .v-slider__track-container {
  height: 8px;
}

.scollContainer {
  max-height: 200px;
  overflow-y: auto;
}

.container {
  min-height: 80px;
  overflow-x: hidden;
}

.sliderLabel {
  position: absolute;
  top: 0;
  white-space: nowrap;
  transform: translate(-50%, -60%);
}

.sliderLabelParent {
  position: relative;
  transform: translateY(100%);
}
</style>
