<template>
  <menopause-report-section
    :section-number="sectionNumber"
    :background-color="backgroundColor"
  >
    <template #title>{{ sectionTitle }}</template>
    <template #content>
      <div class="vfl-card-light py-4 px-8 pb-12 align-center d-md-flex">
        <img
          src="/images/illustrations/muscle-strength-decrease.svg"
          alt=""
          aria-hidden="true"
        />
        <div class="content">
          <p v-for="p in content" :key="p">{{ p }}</p>
          <did-not-know-that-fact-button
            class="mt-8"
            fact="Preventing muscle loss"
          />
        </div>
      </div>
      <recommendation-container
        class="mt-10"
        :title="
          $t('menopauseAssessment.report.recommendations.muscleLoss.title')
        "
        :results="results"
        :assessmentId="assessmentId"
        :recommendations="muscleLossRecommendations"
      />
    </template>
  </menopause-report-section>
</template>

<script>
import MenopauseReportSection from "@/components/assessment/menopause/report/MenopauseReportSection.vue";
import { menopauseReportService } from "@/components/assessment/menopause/report/text-service.js";
import DidNotKnowThatFactButton from "@/components/assessment/menopause/report/DidNotKnowThatFactButton.vue";
import RecommendationContainer from "@/components/assessment/menopause/report/recommendations/RecommendationContainer.vue";
import {
  getRecommendationsByType,
  RecommendationSections
} from "@/components/assessment/menopause/report/recommendations/recommendation-service.js";

export default {
  name: "MenopauseReportMuscleLoss",
  components: {
    DidNotKnowThatFactButton,
    MenopauseReportSection,
    RecommendationContainer
  },
  data() {
    return { muscleLossRecommendations: {} };
  },
  props: {
    results: {
      type: Object,
      required: true
    },
    assessmentId: String,
    sectionNumber: {
      type: Number,
      required: true
    },
    backgroundColor: {
      type: String,
      required: true
    }
  },
  created() {
    this.muscleLossRecommendations = getRecommendationsByType(
      RecommendationSections.MUSCLE_LOSS,
      this.results
    );
  },
  computed: {
    sectionTitle() {
      return menopauseReportService.getMuscleLossSectionTitle();
    },
    content() {
      return menopauseReportService.getMuscleLossCardContent();
    }
  }
};
</script>
<style scoped lang="scss">
.vfl-card-light {
  background: white;
  gap: 1rem;
}
</style>
