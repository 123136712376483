import { LinkTypes } from "@/services/recommendations/link-types.js";
import * as ScoreService from "@/services/assessment/physical-labour/reba-scoring-service.js";
import Cards from "./RecommendationCards.json";
import sentry from "@/sentry";
import PainService from "@/services/assessment/pain/pain-assessment-service.js";
import answers from "@/services/assessment/physical-labour/answers.json";
import constants from "@/components/common/assessments/answer-constants";
import {
  rebaPainAreas,
  assessmentKeys
} from "@/components/assessment/physical-labour/utils/constants.js";

export default function getStrengthRecommendations(results) {
  const recommendations = getAllRecommendations();
  const cardsToShow = recommendations.filter(r => {
    try {
      return r.showAnswer(results);
    } catch (e) {
      sentry.captureException(e);
      return false;
    }
  });
  return cardsToShow.slice(0, 3);
}

function hasSomeAnswers(results, section, question, answers) {
  if (!results[section][question]) {
    sentry.captureMessage(`No result found for question ${question}`, "error");
    return false;
  }
  return answers.some(a => results[section][question].includes(a));
}

function hasAnswer(results, section, question, answer) {
  if (!results[section][question]) {
    sentry.captureMessage(`No result found for question ${question}`, "error");
    return false;
  }
  return results[section][question] === answer;
}

function showCoreStrengtheningCard(results) {
  const hasRelevantTask = hasSomeAnswers(
    results,
    assessmentKeys.jobNature,
    assessmentKeys.jobActivities,
    [
      answers.jobNature.jobActivities.carrying,
      answers.jobNature.jobActivities.heavyLifting,
      answers.jobNature.jobActivities.intensePhysicalEffort,
      answers.jobNature.jobActivities.pushOrPull
    ]
  );
  const hasRelevantPain = PainService.hasSomePainAreas(results, [
    ...rebaPainAreas.upperArm,
    ...rebaPainAreas.torso
  ]);
  const hasRelevantReba =
    ScoreService.parseTorsoScore(results) > 1 ||
    hasSomeAnswers(results, assessmentKeys.reba, assessmentKeys.legPosition, [
      answers.reba.legPosition.oneLegRaised
    ]);

  return hasRelevantTask && (hasRelevantPain || hasRelevantReba);
}

function showShoulderStrengtheningCard(results) {
  const hasRelevantTask = hasSomeAnswers(
    results,
    assessmentKeys.jobNature,
    assessmentKeys.jobActivities,
    [
      answers.jobNature.jobActivities.carrying,
      answers.jobNature.jobActivities.heavyLifting,
      answers.jobNature.jobActivities.intensePhysicalEffort,
      answers.jobNature.jobActivities.pushOrPull,
      answers.jobNature.jobActivities.overheadWork
    ]
  );
  const hasRelevantPain = PainService.hasSomePainAreas(
    results,
    rebaPainAreas.upperArm
  );

  const hasRelevantReba =
    hasAnswer(
      results,
      assessmentKeys.reba,
      assessmentKeys.upperArmPosition,
      answers.reba.upperArmPosition.raised
    ) &&
    (hasAnswer(
      results,
      assessmentKeys.reba,
      assessmentKeys.raisedUpperArmPosition,
      answers.reba.raisedUpperArmPosition.extendedFarBeyondBody
    ) ||
      hasAnswer(
        results,
        assessmentKeys.reba,
        assessmentKeys.raisedUpperArmPosition,
        answers.reba.raisedUpperArmPosition.aboveShoulder
      ));

  return hasRelevantTask && (hasRelevantPain || hasRelevantReba);
}

function showLegStrengtheningCard(results) {
  const hasRelevantTask = hasSomeAnswers(
    results,
    assessmentKeys.jobNature,
    assessmentKeys.jobActivities,
    [
      answers.jobNature.jobActivities.carrying,
      answers.jobNature.jobActivities.heavyLifting,
      answers.jobNature.jobActivities.intensePhysicalEffort,
      answers.jobNature.jobActivities.pushOrPull
    ]
  );
  const hasRelevantUpperBodyPain = PainService.hasSomePainAreas(results, [
    ...rebaPainAreas.torso,
    ...rebaPainAreas.upperArm
  ]);
  const hasRelevantLowerBodyPain = PainService.hasSomePainAreas(
    results,
    rebaPainAreas.leg
  );
  const hasRelevantPain = hasRelevantLowerBodyPain || hasRelevantUpperBodyPain;

  const hasRelevantReba = ScoreService.parseLegScore(results) > 1;
  return hasRelevantTask && (hasRelevantPain || hasRelevantReba);
}

function showGripStrengtheningCard(results) {
  const isGripping = hasSomeAnswers(
    results,
    assessmentKeys.jobNature,
    assessmentKeys.jobActivities,
    [answers.jobNature.jobActivities.gripping]
  );
  const inadequateCouplingScore =
    ScoreService.calculateCouplingScore(results) > 1;
  const hasWristPain = PainService.hasSomePainAreas(
    results,
    rebaPainAreas.wrist
  );
  return (isGripping || inadequateCouplingScore) && !hasWristPain;
}

function getAllRecommendations() {
  return [
    {
      id: "physical_labour_core_strengthening",
      showAnswer: results => showCoreStrengtheningCard(results),
      json: Cards.coreStrengthening,
      linkType: () => LinkTypes.REGULAR
    },
    {
      id: "physical_labour_shoulder_strengthening",
      showAnswer: results => showShoulderStrengtheningCard(results),
      json: Cards.shoulderStrengthening,
      linkType: () => LinkTypes.REGULAR
    },
    {
      id: "physical_labour_leg_strengthening",
      showAnswer: results => showLegStrengtheningCard(results),
      json: Cards.legStrengthening,
      linkType: () => LinkTypes.REGULAR
    },
    {
      id: "physical_labour_grip_strengthening",
      showAnswer: results => showGripStrengtheningCard(results),
      json: Cards.gripStrengthening,
      linkType: () => LinkTypes.REGULAR
    }
  ];
}
