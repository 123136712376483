<template>
  <div
    class="ai-assistant-window-scroller-container vfl-scrollbar-vertical"
    ref="scroller"
  >
    <slot></slot>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { throttle } from "lodash";

export default {
  name: "AiAssistantWindowScroller",
  data() {
    return {
      lastScrollHeight: 0,
      lastScrollTop: 0,
      scrollIntervalId: null,
      latestUserMessageElement: null,
      isUserScrolling: false,
      isScrollingProgrammatically: false,
      scrollContainerTimer: null,
      checkPositionTimer: null
    };
  },
  computed: {
    ...mapGetters("ai", [
      "isLoadingConversations",
      "conversations",
      "isAssistantTyping"
    ]),
    latestConversationMessages() {
      if (this.conversations.length > 0) {
        const lastConversation =
          this.conversations[this.conversations.length - 1];
        return lastConversation.messages || [];
      }
      return [];
    }
  },
  watch: {
    "conversations.length": function (newLength, oldLength) {
      if (newLength > oldLength) {
        this.onConversationAdded();
      }
    },
    "latestConversationMessages.length": function (newLength, oldLength) {
      if (newLength > oldLength) {
        this.onMessageAdded();
      }
    },
    isLoadingConversations(isLoading) {
      if (!isLoading) {
        this.onConversationHistoryLoaded();
      }
    },
    isAssistantTyping(isTyping) {
      if (isTyping) {
        this.startScrollInterval();
      } else {
        this.stopScrollInterval();
        this.updateScrollPositionState();
      }
    }
  },
  created() {
    this.throttledOnScroll = throttle(this.onScroll, 200);
  },
  mounted() {
    if (this.conversations?.length) {
      this.$nextTick(() => {
        this.setUserMessageStartElement();
        this.scrollToBottom(false);
      });
    }

    this.$refs.scroller.addEventListener("scroll", this.throttledOnScroll);
  },
  beforeDestroy() {
    this.$refs.scroller.removeEventListener("scroll", this.throttledOnScroll);

    this.throttledOnScroll.cancel();
    this.stopScrollInterval();
    if (this.scrollContainerTimer) {
      clearTimeout(this.scrollContainerTimer);
    }
    if (this.checkPositionTimer) {
      clearTimeout(this.checkPositionTimer);
    }
  },
  methods: {
    onConversationHistoryLoaded() {
      if (this.conversations?.length) {
        this.$nextTick(() => {
          this.scrollToBottom(false);
        });
      }
    },
    onConversationAdded() {
      this.$nextTick(() => {
        this.setUserMessageStartElement();
        this.scrollToBottom();
      });
    },
    onMessageAdded() {
      this.$nextTick(() => {
        this.setUserMessageStartElement();
        this.scrollToBottom();
      });
    },
    scrollToBottom(isSmooth = true) {
      const container = this.$refs.scroller;

      if (container) {
        this.$emit("is-auto-scrolling", true);

        container.scrollTo({
          top: container.scrollHeight,
          behavior: isSmooth ? "smooth" : "instant"
        });

        if (this.scrollContainerTimer) {
          clearTimeout(this.scrollContainerTimer);
        }

        this.scrollContainerTimer = setTimeout(
          () => {
            this.$emit("is-auto-scrolling", false);
            this.updateScrollPositionState();
          },
          isSmooth ? 300 : 0
        );
      }
    },
    scrollByContainerHeight(buffer = 40) {
      const container = this.$refs.scroller;
      if (!container) return;

      const scrollAmount = container.clientHeight - buffer;
      const currentScrollTop = container.scrollTop;
      let newScrollTop;

      newScrollTop = Math.min(
        currentScrollTop + scrollAmount,
        container.scrollHeight - container.clientHeight
      );

      this.isScrollingProgrammatically = true;

      container.scrollTo({
        top: newScrollTop,
        behavior: "smooth"
      });

      if (this.scrollContainerTimer) {
        clearTimeout(this.scrollContainerTimer);
      }

      this.scrollContainerTimer = setTimeout(() => {
        this.isScrollingProgrammatically = false;

        this.updateScrollPositionState();
      }, 300);
    },
    onScroll() {
      const container = this.$refs.scroller;
      if (!container) return;

      const currentScrollTop = container.scrollTop;

      this.handleScrollBehavior(currentScrollTop);
      this.updateScrollPositionState();
    },
    handleScrollBehavior(currentScrollTop) {
      if (this.isScrollingProgrammatically) return;

      if (this.isUserScrollingUp(currentScrollTop)) {
        this.handleUserScrollingUp();
      }

      this.lastScrollTop = currentScrollTop;
    },
    isUserScrollingUp(currentScrollTop) {
      return currentScrollTop < this.lastScrollTop;
    },
    handleUserScrollingUp() {
      if (this.isAssistantTyping) {
        this.isUserScrolling = true;
        this.stopScrollInterval();
      }
    },
    updateScrollPositionState() {
      const container = this.$refs.scroller;

      if (container) {
        const { scrollTop, scrollHeight, clientHeight } = container;
        const bufferFromBottom = 30;

        const distanceFromBottom = this.getDistanceFromBottom(
          scrollHeight,
          clientHeight,
          scrollTop
        );

        const isScrolledNearToBottom = distanceFromBottom < bufferFromBottom;
        const isScrolledToTop = scrollTop === 0;

        this.$emit("is-scrolled-to-bottom", isScrolledNearToBottom);
        this.$emit("is-scrolled-to-top", isScrolledToTop);
      }
    },
    getDistanceFromBottom(scrollHeight, clientHeight, scrollTop) {
      return Math.abs(scrollHeight - clientHeight - scrollTop);
    },
    scrollToBottomIfNeeded() {
      if (this.isUserScrolling) {
        this.stopScrollInterval();
        return;
      }

      const container = this.$refs.scroller;
      if (!container || !this.isAssistantTyping) return;

      const { scrollHeight, clientHeight, scrollTop } = container;
      const buffer = 80;

      if (this.hasContainerHeightIncreased(scrollHeight)) {
        this.handleContainerHeightIncrease(
          container,
          scrollHeight,
          clientHeight,
          scrollTop,
          buffer
        );
      }

      this.updateLastScrollState(scrollHeight, container.scrollTop);
    },
    hasContainerHeightIncreased(scrollHeight) {
      return scrollHeight > this.lastScrollHeight;
    },
    handleContainerHeightIncrease(
      container,
      scrollHeight,
      clientHeight,
      scrollTop,
      buffer
    ) {
      if (this.isLatestUserMessageWithinBuffer(container, buffer)) {
        this.stopScrollInterval();
        this.scrollLatestUserMessageIntoView();

        // Wait 1s before checking the container position to ensure new content streamed in has been rendered -
        // NextTick does not work here instead
        this.checkPositionTimer = setTimeout(() => {
          this.updateScrollPositionState();
        }, 1000);

        return;
      }

      const growth = scrollHeight - this.lastScrollHeight;
      this.isScrollingProgrammatically = true;

      if (
        this.isScrolledToBottom(scrollTop, clientHeight, scrollHeight, growth)
      ) {
        this.scrollToBottom(true);
      } else {
        container.scrollTop = this.lastScrollTop + growth;
      }

      this.isScrollingProgrammatically = false;
    },
    isLatestUserMessageWithinBuffer(container, buffer) {
      return (
        this.latestUserMessageElement?.getBoundingClientRect().top <=
        container.getBoundingClientRect().top + buffer
      );
    },
    isScrolledToBottom(scrollTop, clientHeight, scrollHeight, growth) {
      return scrollTop + clientHeight > scrollHeight - growth;
    },
    scrollLatestUserMessageIntoView() {
      this.latestUserMessageElement.scrollIntoView({
        block: "start"
      });
    },
    updateLastScrollState(scrollHeight, scrollTop) {
      this.lastScrollHeight = scrollHeight;
      this.lastScrollTop = scrollTop;
    },
    startScrollInterval() {
      this.stopScrollInterval();

      this.isUserScrolling = false;
      this.scrollIntervalId = setInterval(() => {
        this.scrollToBottomIfNeeded();
      }, 200);
    },
    stopScrollInterval() {
      if (this.scrollIntervalId) {
        this.$emit("is-auto-scrolling", false);

        clearInterval(this.scrollIntervalId);
        this.scrollIntervalId = null;
      }
    },
    setUserMessageStartElement() {
      const container = this.$refs.scroller;
      if (container) {
        const messageElements = container.querySelectorAll(
          ".ai-assistant-user-message-container"
        );
        if (messageElements.length > 0) {
          this.latestUserMessageElement =
            messageElements[messageElements.length - 1];
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.ai-assistant-window-scroller-container {
  flex-grow: 1;
  overflow: hidden auto;
  padding-top: 4rem;
  margin-right: 0.25rem;
  scrollbar-gutter: stable;
}
</style>
