<template>
  <div>
    <div class="d-flex align-center" v-if="loading" style="min-height: 27px">
      <v-progress-circular
        color="var(--v-vflPrimary-base)"
        indeterminate
        size="16"
        width="2"
        aria-hidden="true"
      />
    </div>
    <vfl-dropdown
      v-else
      :items="actions"
      @onSelect="handleActionSelect"
      @onOpen="handleDropdownOpen"
      :align-bottom="true"
      :align-left="true"
      :offset-bottom="24"
      :show-toggle-icon="false"
      activator-class-list="ml-auto vfl-button-primary-ghost py-1 px-2 mt-n2 mr-n2"
    >
      <template v-slot:activator>
        <v-icon size="16" color="vflPrimary">mdi-dots-vertical</v-icon>
        <span class="d-sr-only">{{
          $t("userProfile.assessments.actions.viewAssessmentActions")
        }}</span>
      </template>
    </vfl-dropdown>
    <user-profile-confirm-assessment-delete
      v-model="showDeleteConfirmation"
      @confirm="confirmDelete"
      @cancel="cancelDelete"
    />
  </div>
</template>

<script>
import VflDropdown from "@/components/vitrue-foundation-library/VflDropdown.vue";
import { mapActions, mapGetters } from "vuex";
import UserProfileConfirmAssessmentDelete from "@/components/user-profile/UserProfileConfirmAssessmentDelete.vue";
import PatientHistoryDialog from "@/components/common/assessment-history/PatientHistoryDialog.vue";
import { sendReminderToCompleteAssessment } from "@/customApi.js";
import { sendEvent } from "@/services/analytics/analytics.js";

const Actions = {
  VIEW: "View",
  DOWNLOAD: "Download",
  DELETE: "Delete",
  REMIND: "Remind"
};

export default {
  name: "UserProfileAssessmentActions",
  components: {
    PatientHistoryDialog,
    UserProfileConfirmAssessmentDelete,
    VflDropdown
  },
  props: {
    assessment: {
      type: Object,
      required: true
    },
    isCompleted: {
      type: Boolean,
      default: false
    },
    isLoadingReport: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      defaultActions: [
        {
          text: this.$t("userProfile.assessments.actions.delete"),
          value: Actions.DELETE,
          icon: "mdi-trash-can-outline",
          callback: this.showDeleteDialog
        }
      ],
      completedAssessmentActions: [
        {
          text: this.$t("userProfile.assessments.actions.view"),
          value: Actions.VIEW,
          icon: "mdi-file-document-outline",
          callback: this.view
        },
        {
          text: this.$t("userProfile.assessments.actions.download"),
          value: Actions.DOWNLOAD,
          icon: "mdi-download-outline",
          callback: this.download
        }
      ],
      incompleteAssessmentActions: [
        {
          text: this.$t("userProfile.assessments.actions.remind"),
          value: Actions.REMIND,
          icon: "mdi-bell-ring-outline",
          callback: this.remind
        }
      ],
      showDeleteConfirmation: false,
      assessmentToDelete: null,
      sendingReminder: false
    };
  },
  computed: {
    ...mapGetters("userProfile", ["deletingAssessmentId", "id"]),
    ...mapGetters({
      anonymousResults: "anonymousResults",
      adminId: "userId"
    }),
    actions() {
      const adminCanViewReport = !this.anonymousResults;

      return this.isCompleted && adminCanViewReport
        ? [...this.completedAssessmentActions, ...this.defaultActions]
        : [...this.incompleteAssessmentActions, ...this.defaultActions];
    },
    loading() {
      return (
        this.assessmentId === this.deletingAssessmentId ||
        this.isLoadingReport ||
        this.sendingReminder
      );
    },
    assessmentId() {
      return this.assessment?.id;
    },
    state() {
      return this.assessment?.assessmentState;
    },
    type() {
      return this.assessment?.assessmentType;
    }
  },
  methods: {
    ...mapActions("userProfile", [
      "deleteAssessment",
      "showSnackbar",
      "viewAssessment",
      "downloadReport"
    ]),

    handleActionSelect({ value: selectedValue }) {
      const action = this.actions.find(
        action => action.value === selectedValue
      );

      if (action && typeof action.callback === "function") {
        action.callback();
      }
    },
    showDeleteDialog() {
      this.assessmentToDelete = this.assessmentId;
      this.showDeleteConfirmation = true;

      sendEvent("Delete assessment dialog opened from user profile", {
        end_user: this.id,
        admin: this.adminId
      });
    },
    confirmDelete() {
      this.deleteAssessment(this.assessmentToDelete);
      this.showDeleteConfirmation = false;
      this.assessmentToDelete = null;

      sendEvent("Delete assessment confirmed from user profile", {
        end_user: this.id,
        admin: this.adminId
      });
    },
    cancelDelete() {
      this.showDeleteConfirmation = false;
      this.assessmentToDelete = null;

      sendEvent("Delete assessment cancelled from user profile", {
        end_user: this.id,
        admin: this.adminId
      });
    },
    view() {
      this.viewAssessment({
        id: this.assessment.id,
        assessmentType: this.assessment.assessmentType
      });
    },
    download() {
      this.downloadReport(this.assessment);
    },
    async remind() {
      try {
        this.sendingReminder = true;

        await sendReminderToCompleteAssessment(this._i18n.locale, {
          id: this.assessmentId,
          assessmentType: this.type,
          state: this.state
        });

        this.showSnackbar({
          message: this.$t("userProfile.assessments.snackbar.reminder.success"),
          type: "success"
        });

        sendEvent("Reminder sent from user profile", {
          end_user: this.id,
          admin: this.adminId
        });
      } catch (err) {
        this.$logger.captureException(err);
        this.showSnackbar({
          message: this.$t("userProfile.assessments.snackbar.reminder.error"),
          type: "error"
        });
      } finally {
        this.sendingReminder = false;
      }
    },
    handleDropdownOpen() {
      sendEvent("Assessment actions dropdown opened in user profile", {
        end_user: this.id,
        admin: this.adminId
      });
    }
  }
};
</script>
