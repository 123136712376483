const rebaPainAreas = {
  neck: ["neck"],
  leg: ["knee", "calf", "hamstring", "hip", "gluteals", "ankle"],
  upperArm: ["shoulder"],
  lowerArm: ["elbow"],
  wrist: ["wrist"],
  torso: ["midback", "lowerback"]
};

const assessmentKeys = {
  jobNature: "jobNature",
  jobActivities: "jobActivities",
  reba: "reba",
  legPosition: "legPosition",
  pain: "pain",
  activity: "activity",
  duration: "duration",
  upperArmPosition: "upperArmPosition",
  raisedUpperArmPosition: "raisedUpperArmPosition",
  neckPosition: "neckPosition",
  neckTwistedOrSideBent: "neckTwistedOrSideBent",
  legsBent: "legsBent",
  lowerArmPosition: "lowerArmPosition",
  wristPosition: "wristPosition",
  wristTwistedOrSideBent: "wristTwistedOrSideBent",
  torsoBentForwardPosition: "torsoBentForwardPosition",
  torsoBentBackwardPosition: "torsoBentBackwardPosition",
  torsoTwistedOrSideBent: "torsoTwistedOrSideBent",
  sideBent: "sideBent",
  sectors: "sectors",
  type: "type",
  repetitiveMovementBodyAreas: "repetitiveMovementBodyAreas",
  additionalUpperArmFactors: "additionalUpperArmFactors"
};

export { assessmentKeys, rebaPainAreas };
