export const IDS = {
  PAIN_COACH: "pain_coach",
  ADJUST_YOUR_CHAIR: "adjust_chair",
  SUPPORT_BACK: "back_support",
  BREAKS: "breaks",
  WRIST_REST: "wrist_rest",
  ERGONOMIC_MOUSE: "ergonomic_mouse",
  ERGONOMIC_KEYBOARD: "ergonomic_keyboard",
  HIGH_NOISE: "high_noise"
};
