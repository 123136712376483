<template>
  <div
    v-show="isActive"
    role="tabpanel"
    :aria-labelledby="`tab-${tabIndex}`"
    :id="`tab-panel-${tabIndex}`"
    tabindex="0"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "VflTab",
  props: {
    title: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isActive: false,
      tabIndex: 0
    };
  },
  mounted() {
    this.tabIndex = this.$parent.tabs.length - 1;
  }
};
</script>
