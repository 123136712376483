export function viewingOwnResults(route) {
  const restrictedPaths = [
    "/dashboard",
    "/certification",
    "/suggestions",
    "/analytics",
    "/people"
  ];

  return !restrictedPaths.some(path => route.path.startsWith(path));
}
