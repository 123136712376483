<template>
  <span class="ai-assistant-input-action-stop-icon-container">
    <span class="square"></span>
    <svg class="arc" viewBox="0 0 24 24">
      <path
        d="M12 2A10 10 0 0 1 22 12A10 10 0 0 1 12 22A10 10 0 0 1 2 12A10 10 0 0 1 12 2"
        fill="none"
        stroke="url(#gradient)"
        stroke-width="2"
        stroke-linecap="round"
        stroke-dasharray="56.55 18.85"
        stroke-dashoffset="9.42"
      />
      <defs>
        <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
          <stop
            offset="0%"
            style="stop-color: var(--v-vflPrimary-base); stop-opacity: 1"
          />
          <stop
            offset="50%"
            style="stop-color: var(--v-vflPurple-base); stop-opacity: 1"
          />
          <stop
            offset="100%"
            style="stop-color: var(--v-vflPrimary-base); stop-opacity: 1"
          />
        </linearGradient>
      </defs>
    </svg>
  </span>
</template>
<script>
export default {
  name: "AiAssistantInputActionStopIcon"
};
</script>

<style lang="scss" scoped>
.ai-assistant-input-action-stop-icon-container {
  align-items: center;
  display: flex;
  height: 24px;
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: 24px;
}

.square {
  background-image: linear-gradient(
    120deg,
    var(--v-vflPrimary-base),
    var(--v-vflPurple-base) 30%,
    var(--v-vflPrimary-base)
  );
  border-radius: 1px;
  height: 8px;
  width: 8px;
}

.arc {
  animation: spin 2s linear infinite;
  position: absolute;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
