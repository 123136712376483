<template>
  <div :style="{ width: `${width}px`, height: `${height}px` }">
    <Doughnut
      :chart-options="chartOptions"
      :chart-data="chartData"
      :chart-id="chartId"
      :dataset-id-key="datasetIdKey"
    />
  </div>
</template>

<script>
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "vue-chartjs";

ChartJS.register(ArcElement, Tooltip, Legend);

export default {
  name: "DoughnutChart",
  components: {
    Doughnut
  },
  props: {
    chartId: {
      type: String,
      default: "doughnut-chart"
    },
    datasetIdKey: {
      type: String,
      default: "label"
    },
    width: {
      type: Number,
      default: 200
    },
    height: {
      type: Number,
      default: 200
    },
    chartData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      chartOptions: {
        responsive: true,
        maintainAspectRatio: true,
        cutout: "80%",
        plugins: {
          legend: {
            display: false
          }
        },
        hover: {
          mode: null
        },
        events: [],
        elements: {
          arc: {
            borderWidth: 0,
            spacing: 0
          }
        }
      }
    };
  }
};
</script>
