import SSOLoginCustomisation from "@/assets/json/common/SSOLoginCustomisation.json";

const IDP_CONFIG = {
  azure: "genericAzure",
  azurebupamain: "bupa",
  googleoidc: "genericGoogle",
  jumpcloud: "jumpCloud",
  okta: "genericOkta",
  onelogin: "genericOneLogin"
};

export function getSSOCustomisation(idpName) {
  if (!idpName) return null;

  const sanitizedName = idpName.toLowerCase();

  // Look for exact match e.g. azurebupamain
  if (sanitizedName in IDP_CONFIG) {
    return SSOLoginCustomisation[IDP_CONFIG[sanitizedName]];
  }

  // fallback to partial match e.g azure
  const matchingIdp = Object.entries(IDP_CONFIG).find(([prefix]) =>
    sanitizedName.includes(prefix)
  );

  return matchingIdp ? SSOLoginCustomisation[matchingIdp[1]] : null;
}
