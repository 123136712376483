<template>
  <youtube
    :video-id="this.videoId"
    v-on:playing="forwardYouTubeEvent"
    :height="this.height"
    :width="this.width"
    @ready="debounceYouTubeEvent"
    @ended="debounceYouTubeEvent"
    @playing="debounceYouTubeEvent"
    @paused="debounceYouTubeEvent"
    @buffering="debounceYouTubeEvent"
    @cued="debounceYouTubeEvent"
    @error="debounceYouTubeEvent"
    :playerVars="this.playerVars"
    ref="youtube"
  ></youtube>
</template>

<script>
export default {
  props: {
    url: String,
    height: String | Number,
    width: String | Number,
    showControls: Boolean
  },
  data() {
    return {
      videoId: null,
      interval: null,
      playerState: -1,
      eventBuffer: [],
      playerVars: {
        rel: 0,
        modestbranding: 1,
        controls: this.showControls ? 1 : 0,
        cc_load_policy: this.ccLoadPolicy(),
        hl: this._i18n.locale
      }
    };
  },
  mounted() {
    this.videoId = this.$youtube.getIdFromUrl(this.url);
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  methods: {
    ccLoadPolicy() {
      // Do not turn on subtitles for these videos because they have built in text which clashes.
      if (
        this.url.includes("tAUf7aajBWE") ||
        this.url.includes("_poD7UcGqOk")
      ) {
        return 0;
      }
      return 1;
    },
    debounceYouTubeEvent(event) {
      this.eventBuffer.forEach(t => clearTimeout(t));

      let vm = this;
      this.eventBuffer.push(
        setTimeout(() => vm.forwardYouTubeEvent(event), 10)
      );
    },
    async forwardYouTubeEvent(player) {
      let state = await player.getPlayerState();
      let position = 0;

      switch (state) {
        /*UNSTARTED*/ case -1:
          break;
        /*ENDED*/ case 0:
          await this.manageInterval(state, "ended");
          this.$emit("VIDA_YOUTUBE_EVENT", { state: "VIDEO_ENDED" });
          break;
        /*PLAYING*/ case 1:
          position = await player.getCurrentTime();
          let videoLength = await player.getDuration();
          await this.manageInterval(state, "playing");
          this.$emit("VIDA_YOUTUBE_EVENT", {
            state: "VIDEO_PLAYING",
            position,
            videoLength
          });
          break;
        /*PAUSED*/ case 2:
          await this.manageInterval(state, "paused");
          position = await player.getCurrentTime();
          this.$emit("VIDA_YOUTUBE_EVENT", {
            state: "VIDEO_PAUSED",
            position
          });
          break;
        /*BUFFERING*/ case 3:
          await this.manageInterval(state, "buffering");
          position = await player.getCurrentTime();
          this.$emit("VIDA_YOUTUBE_EVENT", {
            state: "VIDEO_BUFFERING",
            position
          });
          break;
        /*CUED*/ case 5:
          this.$emit("VIDA_YOUTUBE_EVENT", { state: "VIDEO_READY" });
          break;
        default:
          return;
      }
    },
    async onRestartClicked() {
      let player = this.$refs.youtube.player;
      await player.seekTo(0);
    },
    async onStepClicked(stepData) {
      if (!stepData) throw "Invalid stepData";

      let player = this.$refs.youtube.player;
      let state = await player.getPlayerState();
      let position = await player.getCurrentTime();
      let clickedOnCurrentStep =
        position >= stepData.start && position < stepData.end;

      if (state === 1) {
        if (clickedOnCurrentStep) {
          await player.pauseVideo();
        } else {
          await player.seekTo(stepData.start);
          await player.playVideo();
        }
      } else if (state === 2 || state === 5) {
        if (!clickedOnCurrentStep) {
          await player.seekTo(stepData.start);
        }
        await player.playVideo();
      }

      //not interactive, return
      return;
    },
    async manageInterval(playerState, caller) {
      let isPlaying = playerState === 1;

      if (isPlaying && this.interval === null) {
        let vm = this;
        this.interval = setInterval(async () => {
          let nextState = await vm.$refs.youtube.player.getPlayerState();
          await vm.manageInterval(nextState, "interval");
        }, 100);
      } else if (!isPlaying && this.interval) {
        clearInterval(this.interval);
        this.interval = null;
      } else if (caller === "interval") {
        this.debounceYouTubeEvent(this.$refs.youtube.player);
      }

      return this.interval;
    }
  }
};
</script>
