import { DeskAssessmentRecommendations } from "@/services/recommendations/deskassessment/recommendations.js";
import { BurnoutRecommendations } from "@/services/recommendations/burnout/recommendations.js";

function GetDeskAssessmentRecommendationCards(featureFlags) {
  let allCards = getAvailableCards(featureFlags);
  let ids = getCardIdsBasedOnFeatures(featureFlags);
  ids = [...new Set(ids)];
  return getCardsFromIds(ids, allCards);
}

function GetDriverAssessmentRecommendationCards() {
  return DriverRecommendations;
}

function getAvailableCards(featureFlags) {
  return DeskAssessmentRecommendations.filter(x => {
    if (featureFlags.hideUpgradeRecommendations) {
      return !x.upgrade && !x.fixed;
    }
    return !x.fixed;
  });
}

function getCardsFromIds(ids, allCards) {
  let cards = [];

  ids.forEach(id => {
    if (id === "*") {
      let defaults = allCards.filter(x => !x.custom);
      cards = [...cards, ...defaults];
      return;
    }
    var cardToAdd = allCards.find(x => x.id === id);
    cards.push(cardToAdd);
  });

  return cards;
}

function GetBurnoutAssessmentRecommendationCards(featureFlags) {
  let allCards = BurnoutRecommendations;
  let recommendations = getBurnoutCardIds(featureFlags);
  let cards = {};
  Object.keys(recommendations).forEach(key => {
    cards[key] = [];
    let categoryCards = Object.values(allCards[key]);
    recommendations[key].forEach(id => {
      if (id === "*") {
        let defaults = categoryCards.filter(x => !x.custom);
        cards[key] = [...cards[key], ...defaults];
        return;
      }
      var cardToAdd = categoryCards.find(x => x.id === id);
      cards[key].push(cardToAdd);
    });
  });

  return cards;
}

const getCardIdsBasedOnFeatures = featureFlags => {
  if (featureFlags.customRecommendations_etKJa) {
    return [
      "pain_coach",
      "raise_screen_etKJa",
      "poor_setup_etKJa",
      "feet_supported_upgrade_etKJa",
      "feet_supported_budget_etKJa",
      "back_support_upgrade_etKJa",
      "back_support_budget_etKJa",
      "wrist_rest_budget_etKJa",
      "wrist_rest_upgrade_etKJa",
      "hours_upgrade_etKJa",
      "hours_budget_etKJa",
      "greenery_etKJa",
      "monitor_distance_etKJa",
      "beanBag_etKJa",
      "breaks_etKJa",
      "lunch_etKJa",
      "water_bottle_etKJa",
      "natural_light_etKJa",
      "fresh_air_etKJa",
      "meditation_etKJa",
      "clutter_etKJa",
      "smoking_etKJa",
      "breakIdeas_etKJa",
      "high_noise_etKJa",
      "low_noise_etKJa",
      "standingBreaks_etKJa",
      "headphones_budget_etKJa",
      "headphones_upgrade_etKJa",
      "regular_mouse_budget_etKJa",
      "regular_mouse_upgrade_etKJa",
      "regular_keyboard_budget_etKJa",
      "regular_keyboard_upgrade_etKJa",
      "ergonomic_mouse_etKJa",
      "ergonomic_keyboard_budget_etKJa",
      "ergonomic_keyboard_upgrade_etKJa",
      "seat_wedge_lower_back_etKJa",
      "seat_wedge_lower_back_hip_etKJa",
      "seat_wedge_hip_etKJa",
      "chair_etKJa",
      "low_temperature_etKJa",
      "high_temperature_etKJa"
    ];
  } else if (featureFlags.customRecommendations_ykavx) {
    return [
      "pain_coach",
      "feet_supported_upgrade_ykavx",
      "feet_supported_budget_ykavx",
      "back_support_upgrade_ykavx",
      "back_support_budget_ykavx",
      "wrist_rest_upgrade_ykavx",
      "raise_screen_budget",
      "raise_screen_upgrade_ykavx",
      "hours_upgrade_ykavx",
      "hours_budget_ykavx",
      "lunch_ykavx",
      "water_bottle_ykavx",
      "natural_light_ykavx",
      "fresh_air_ykavx",
      "meditation_ykavx",
      "clutter_ykavx",
      "smoking_ykavx",
      "poor_setup_ykavx",
      "custom_knee_pain_ykavx",
      "monitor_distance_ykavx",
      "beanBag",
      "wrist_rest_budget",
      "greenery",
      "breaks",
      "breakIdeas"
    ];
  } else if (featureFlags.customRecommendations_cMwjk) {
    return [
      "physical_activity_classes_cMwjk",
      "wellbeing_programme_cMwjk",
      "msk_programme_cMwjk",
      "self_care_pack_cMwjk",
      "your_support_cMwjk",
      "occupational_health_cMwjk",
      "react_cMwjk",
      "peer_support_cMwjk",
      "book_session_cMwjk",
      "*"
    ];
  } else if (featureFlags.customRecommendations_kItGM) {
    return ["cycle_to_work_kItGM", "*"];
  } else if (featureFlags.customRecommendations_wltNL) {
    return ["employee_services_wltNL", "*"];
  } else if (featureFlags.customRecommendations_eSRiAd) {
    return [
      "pain_coach",
      "ergonomic_keyboard_upgrade",
      "ergonomic_keyboard_budget",
      "regular_keyboard_upgrade",
      "regular_keyboard_budget",
      "regular_mouse_budget",
      "regular_mouse_upgrade",
      "ergonomic_mouse",
      "smoking",
      "raise_screen_upgrade",
      "raise_screen_budget",
      "beanBag",
      "chair",
      "feet_supported_upgrade",
      "feet_supported_budget",
      "back_support_upgrade",
      "back_support_budget",
      "back_support_understanding",
      "wrist_rest_upgrade",
      "wrist_rest_budget",
      "hours_upgrade",
      "hours_budget",
      "greenery",
      "breaks",
      "lunch",
      "water_bottle",
      "natural_light",
      "meditation",
      "clutter",
      "breakIdeas",
      "fresh_air",
      "adjust_chair",
      "raise_your_monitor_card",
      "raise_your_laptop_stand_card",
      "low_noise_budget",
      "low_noise_upgrade",
      "high_noise_budget",
      "high_noise_upgrade",
      "headphones_budget",
      "headphones_upgrade",
      "seat_wedge_lower_back_budget",
      "seat_wedge_lower_back_upgrade",
      "seat_wedge_lower_back_hip_budget",
      "seat_wedge_lower_back_hip_upgrade",
      "seat_wedge_hip_budget",
      "multiple_screen_setup_eSRiAd",
      "seat_wedge_hip_upgrade",
      "standingBreaks",
      "low_temperature",
      "high_temperature",
      "change_your_wrist_rest_card"
    ];
  } else if (featureFlags.customRecommendations_ttqWel) {
    // Replacing back support upgrade card, wrist rest card and feet supported card
    return [
      "pain_coach",
      "in_person_visit_ttqWel",
      "home_working_guidelines_ttqWel",
      "regular_keyboard_upgrade_ttqWel",
      "regular_keyboard_budget_ttqWel",
      "ergonomic_keyboard_upgrade_ttqWel",
      "ergonomic_keyboard_budget_ttqWel",
      "ergonomic_mouse_upgrade_ttqWel",
      "ergonomic_mouse_budget_ttqWel",
      "regular_mouse_budget_ttqWel",
      "regular_mouse_upgrade_ttqWel",
      "headphones_budget_ttqWel",
      "headphones_upgrade_ttqWel",
      "low_noise_budget",
      "low_noise_upgrade",
      "high_noise_budget_ttqWel",
      "high_noise_upgrade_ttqWel",
      "low_temperature",
      "high_temperature",
      "back_support_budget_ttqWel",
      "back_support_upgrade_ttqWel",
      "back_support_upgrade_pregnant_ttqWel",
      "change_your_wrist_rest_card",
      "adjust_chair_home_ttqWel",
      "adjust_chair_resident_office_ttqWel",
      "adjust_chair_hq_ttqWel",
      "chair_ttqWel",
      "beanBag",
      "seat_wedge_lower_back_budget",
      "seat_wedge_lower_back_upgrade",
      "seat_wedge_hip_budget",
      "seat_wedge_hip_upgrade",
      "seat_wedge_lower_back_hip_budget",
      "seat_wedge_lower_back_hip_upgrade",
      "raise_your_screen_card_upgrade_ttqWel",
      "raise_your_screen_card_budget_ttqWel",
      "raise_your_monitor_card",
      "raise_your_laptop_stand_card",
      "feet_supported_upgrade_ttqWel",
      "feet_supported_budget_ttqWel",
      "wrist_rest_upgrade_ttqWel",
      "wrist_rest_budget_ttqWel",
      "greenery",
      "breaks",
      "breakIdeas",
      "lunch",
      "water_bottle",
      "natural_light",
      "fresh_air",
      "meditation",
      "clutter",
      "smoking",
      "building_guide_ttqWel",
      "multiple_screen_setup"
    ];
  } else if (featureFlags.customRecommendations_beCsMA) {
    return [
      "pain_coach",
      "raise_screen_beCsMA",
      "raise_your_laptop_stand_beCsMA",
      "raise_your_monitor_beCsMA",
      "feet_supported_beCsMA",
      "back_support_beCsMA",
      "back_support_understanding_beCsMA",
      "change_your_wrist_rest_beCsMA",
      "wrist_rest_beCsMA",
      "hours_beCsMA",
      "greenery_beCsMA",
      "multiple_screen_setup_beCsMA",
      "chair_beCsMA",
      "seat_wedge_lower_back_beCsMA",
      "seat_wedge_lower_back_hip_beCsMA",
      "seat_wedge_hip_beCsMA",
      "breaks_beCsMA",
      "lunch_beCsMA",
      "water_bottle_beCsMA",
      "natural_light_beCsMA",
      "fresh_air_beCsMA",
      "meditation_beCsMA",
      "clutter_beCsMA",
      "smoking_beCsMA",
      "breakIdeas_beCsMA",
      "sleep_hygiene_beCsMA",
      "high_noise_beCsMA",
      "low_noise_beCsMA",
      "standingBreaks_beCsMA",
      "headphones_beCsMA",
      "regular_mouse_beCsMA",
      "regular_keyboard_beCsMA",
      "ergonomic_mouse_beCsMA",
      "ergonomic_keyboard_beCsMA",
      "high_temperature_beCsMA",
      "low_temperature_beCsMA",
      "work_zones_beCsMA",
      "adjust_chair_beCsMA",
      "beanBag_beCsMA"
    ];
  } else if (featureFlags.customRecommendations_iHRsdr) {
    return [
      "pain_coach",
      "chair_office_iHRsdr",
      "chair_home_iHRsdr",
      "adjust_chair_home_iHRsdr",
      "ergonomic_keyboard_upgrade",
      "ergonomic_keyboard_budget",
      "regular_keyboard_upgrade",
      "regular_keyboard_budget",
      "regular_mouse_budget",
      "regular_mouse_upgrade",
      "ergonomic_mouse",
      "smoking",
      "raise_screen_upgrade",
      "raise_screen_budget",
      "beanBag",
      "feet_supported_upgrade",
      "feet_supported_budget",
      "back_support_upgrade",
      "back_support_budget",
      "back_support_understanding",
      "wrist_rest_upgrade",
      "wrist_rest_budget",
      "hours_upgrade",
      "hours_budget",
      "greenery",
      "breaks",
      "lunch",
      "water_bottle",
      "natural_light",
      "meditation",
      "clutter",
      "breakIdeas",
      "fresh_air",
      "raise_your_monitor_card",
      "raise_your_laptop_stand_card",
      "low_noise_budget",
      "low_noise_upgrade",
      "high_noise_budget",
      "high_noise_upgrade",
      "headphones_budget",
      "headphones_upgrade",
      "seat_wedge_lower_back_budget",
      "seat_wedge_lower_back_upgrade",
      "seat_wedge_lower_back_hip_budget",
      "seat_wedge_lower_back_hip_upgrade",
      "seat_wedge_hip_budget",
      "seat_wedge_hip_upgrade",
      "multiple_screen_setup",
      "standingBreaks",
      "low_temperature",
      "high_temperature",
      "change_your_wrist_rest_card"
    ];
  } else if (featureFlags.customRecommendations_KcaRDa) {
    return ["occupational_health_KcaRDa", "*"];
  } else if (featureFlags.customRecommendations_BDemo) {
    return [
      "pain_coach",
      "regular_keyboard_budget",
      "regular_mouse_budget",
      "raise_screen_budget",
      "breakIdeas",
      "multiple_screen_setup",
      "breaks",
      "standingBreaks",
      "back_support_budget",
      "adjust_chair",
      "lunch",
      "natural_light",
      "beanBag",
      "fresh_air",
      "clutter",
      "feet_supported_budget",
      "wrist_rest_budget",
      "meditation",
      "low_noise_budget",
      "high_noise_budget",
      "smoking",
      "greenery"
    ];
  } else if (featureFlags.customRecommendations_thumiv) {
    return [
      "pain_coach",
      "ergonomic_keyboard_budget",
      "regular_keyboard_budget",
      "regular_mouse_budget",
      "ergonomic_mouse",
      "raise_screen_budget",
      "beanBag",
      "chair",
      "feet_supported_budget",
      "back_support_budget",
      "back_support_understanding",
      "wrist_rest_budget",
      "hours_upgrade",
      "hours_budget",
      "greenery",
      "breaks",
      "lunch",
      "water_bottle",
      "natural_light",
      "meditation",
      "clutter",
      "breakIdeas",
      "fresh_air",
      "adjust_chair",
      "raise_your_monitor_card",
      "raise_your_laptop_stand_card",
      "low_noise_budget",
      "high_noise_budget",
      "headphones_budget",
      "seat_wedge_lower_back_budget",
      "seat_wedge_lower_back_hip_budget",
      "seat_wedge_hip_budget",
      "multiple_screen_setup",
      "standingBreaks",
      "low_temperature",
      "high_temperature",
      "change_your_wrist_rest_card"
    ];
  } else if (featureFlags.customRecommendations_AwEATt) {
    return ["eye_test_AwEATt", "*"];
  } else if (featureFlags.customRecommendations_UjeUTz) {
    return ["ergonomicReferral_UjeUTz", "*"];
  }
  return ["*"];
};

const getBurnoutCardIds = featureFlags => {
  if (featureFlags.customRecommendations_ykavx) {
    return {
      exhaustion: [
        "health_and_wellbeing_hub_ykavx",
        "relaxing_activities",
        "engage_manager",
        "exercise",
        "muscle_relaxation",
        "sleep_hygeine",
        "fake_commute",
        "pomodoro",
        "regular_breaks",
        "deep_breathing",
        "morning_routine"
      ],
      mentaldistance: [
        "support_ykavx",
        "engage_manager",
        "reflection",
        "socialise",
        "morning_routine",
        "team_sport_ykavx"
      ],
      cognitiveimpairment: [
        "eat_healthy",
        "avoid_stimulants_ykavx",
        "exercise",
        "meditation",
        "sleep_hygeine",
        "regular_breaks",
        "fake_commute",
        "pomodoro"
      ],
      emotionalimpairment: ["*"],
      secondarysymptoms: ["*"]
    };
  }
  return {
    exhaustion: ["*"],
    mentaldistance: ["*"],
    cognitiveimpairment: ["*"],
    emotionalimpairment: ["*"],
    secondarysymptoms: ["*"]
  };
};

const getFixedDeskCards = featureFlags => {
  var ids = [];
  if (featureFlags.customRecommendations_ykavx) {
    ids = [
      "be_me_app_ykavx",
      "desk_stretches_ykavx",
      "aches_and_pains_ykavx",
      "back_tension_ykavx",
      "personal_energy_ykavx",
      "health_and_wellbeing_hub_ykavx",
      "anytime_health_line_ykavx"
    ];
  } else if (featureFlags.customRecommendations_etKJa) {
    ids = [
      "desk_stretches_etKJa",
      "aches_and_pains_etKJa",
      "back_tension_etKJa",
      "personal_energy_etKJa",
      "health_and_wellbeing_hub_etKJa",
      "anytime_health_line_etKJa"
    ];
  } else if (featureFlags.customRecommendations_eSRiAd) {
    ids = [
      "eyecare_vouchers_eSRiAd",
      "my_benefits_eSRiAd",
      "order_it_equipment_eSRiAd",
      "order_furniture_eSRiAd"
    ];
  }
  return DeskAssessmentRecommendations.filter(
    x => x.fixed && ids.includes(x.id)
  );
};

const getFixedBurnoutCards = featureFlags => {
  var ids = [];
  if (featureFlags.customRecommendations_ykavx) {
    ids = [
      "speak_to_manager_ykavx",
      "healthy_minds_helpline_ykavx",
      "general_burnout_ykavx",
      "manager_burnout_ykavx",
      "work_related_stress_ykavx",
      "working_from_home_mental_health_ykavx",
      "cbt_techniques_ykavx",
      "personal_energy_ykavx",
      "be_me_app_ykavx"
    ];
  } else if (featureFlags.customRecommendations_ttqWel) {
    ids = [
      "support_services_ttqWel",
      "wellbeing_resources_ttqWel",
      "mental_health_support_ttqWel",
      "medical_care_ttqWel",
      "learning_resources_ttqWel"
    ];
  }
  return BurnoutRecommendations.fixed.filter(x => ids.includes(x.id));
};

export {
  GetDeskAssessmentRecommendationCards,
  GetBurnoutAssessmentRecommendationCards,
  getCardIdsBasedOnFeatures,
  getBurnoutCardIds,
  getFixedDeskCards,
  getFixedBurnoutCards
};
