import i18n from "@/plugins/i18n.js";
import answers from "@/services/assessment/physical-labour/answers.json";
import sentry from "@/sentry";
import { assessmentKeys } from "@/components/assessment/physical-labour/utils/constants.js";

const connectorString = "form.generator.connector";
const text = {
  neckPosition: {
    [answers.reba.neckPosition.bentForwards]:
      "physicalLabourAssessment.schema.reba.questions.neck.position.answers.report.bentForwards",
    [answers.reba.neckPosition.bentBackwards]:
      "physicalLabourAssessment.schema.reba.questions.neck.position.answers.report.bentBackwards"
  },
  neckTwistedOrSideBent: {
    [answers.reba.neckTwistedOrSideBent.twisted]:
      "physicalLabourAssessment.schema.reba.questions.neck.twistedOrSideBent.answers.report.twisted",
    [answers.reba.neckTwistedOrSideBent.sideBent]:
      "physicalLabourAssessment.schema.reba.questions.neck.twistedOrSideBent.answers.report.sideBent"
  },
  additionalUpperArmFactors: {
    [answers.reba.additionalUpperArmFactors
      .atLeastOneShoulderRaised]: `physicalLabourAssessment.schema.reba.questions.arms.additionalUpperArmFactors.answers.report.atLeastOneShoulderRaised`,
    [answers.reba.additionalUpperArmFactors
      .armRaisedToSideOfBody]: `physicalLabourAssessment.schema.reba.questions.arms.additionalUpperArmFactors.answers.report.armRaisedToSideOfBody`
  },
  upperArmPosition: {
    [answers.reba.upperArmPosition
      .extended]: `physicalLabourAssessment.schema.reba.questions.arms.upperArmPosition.answers.report.extended`,
    [answers.reba.upperArmPosition
      .raised]: `physicalLabourAssessment.schema.reba.questions.arms.upperArmPosition.answers.report.raised`
  },
  wristTwistedOrSideBent: {
    [answers.reba.wristTwistedOrSideBent.twisted]:
      "physicalLabourAssessment.schema.reba.questions.wrists.twistedOrSideBent.answers.report.twisted",
    [answers.reba.wristTwistedOrSideBent.sideBent]:
      "physicalLabourAssessment.schema.reba.questions.wrists.twistedOrSideBent.answers.report.sideBent"
  },
  torsoTwistedOrSideBent: {
    [answers.reba.torsoTwistedOrSideBent.twisted]:
      "physicalLabourAssessment.schema.reba.questions.torso.twistedOrSideBent.answers.report.twisted",
    [answers.reba.torsoTwistedOrSideBent.sideBent]:
      "physicalLabourAssessment.schema.reba.questions.torso.twistedOrSideBent.answers.report.sideBent"
  }
};

function getTextToInject(results, keys) {
  try {
    const text = keys.map(k => [k, getText(results, k)]);
    return Object.fromEntries(text);
  } catch (e) {
    sentry.captureException(e);
    return {};
  }
}

function getText(results, root) {
  const questionAnswers = results.reba[root];
  if (!questionAnswers) {
    return "";
  }

  const usableAnswers = Object.values(questionAnswers).filter(
    a => a in text[root]
  );

  if (!usableAnswers.length) return "";

  if (usableAnswers.length === 1) {
    const answer = text[root][usableAnswers[0]];
    return i18n.t(answer);
  }

  const answerText = usableAnswers.map(a => i18n.t(text[root][a]));
  const endItem = answerText.pop();
  return `${answerText.join(", ")} ${i18n.t(connectorString)} ${endItem}`;
}

export default {
  getNeckTextToInject: results =>
    getTextToInject(results, [
      assessmentKeys.neckPosition,
      assessmentKeys.neckTwistedOrSideBent
    ]),
  getArmTextToInject: results =>
    getTextToInject(results, [
      assessmentKeys.upperArmPosition,
      assessmentKeys.additionalUpperArmFactors
    ]),
  getWristTextToInject: results =>
    getTextToInject(results, [assessmentKeys.wristTwistedOrSideBent]),
  getTorsoTextToInject: results =>
    getTextToInject(results, [assessmentKeys.torsoTwistedOrSideBent])
};
