<template>
  <svg
    width="641"
    height="637"
    viewBox="0 0 641 637"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M468.549 575.426H499.046V570.731H468.549V575.426Z"
      fill="#92141C"
    />
    <path
      d="M464.155 565.018V581.141L462.254 579.299V566.86L464.155 565.018Z"
      fill="#E23A44"
    />
    <path
      d="M464.156 565.018H474.842V581.141H464.156V565.018Z"
      fill="#E23A44"
    />
    <path
      d="M503.438 565.018V581.141L505.339 579.299V566.86L503.438 565.018Z"
      fill="#E23A44"
    />
    <path
      d="M503.437 565.018H492.752V581.141H503.437V565.018Z"
      fill="#E23A44"
    />
    <path
      d="M447.371 574.528L472.58 557.367L469.937 553.484L444.727 570.647L447.371 574.528Z"
      fill="#92141C"
    />
    <path
      d="M437.883 568.395L439.375 570.587L445.464 579.528L446.955 581.72L444.347 581.27L443.892 580.599L437.804 571.653L437.347 570.987L437.883 568.395Z"
      fill="#E23A44"
    />
    <path
      d="M437.885 568.395L446.717 562.384L455.789 575.708L446.956 581.72L437.885 568.395Z"
      fill="#E23A44"
    />
    <path
      d="M446.127 580.501L454.96 574.49L455.788 575.707L446.955 581.719L446.127 580.501Z"
      fill="#E23A44"
    />
    <path
      d="M470.355 546.291L471.847 548.482L477.936 557.426L479.427 559.616L479.963 557.025L479.507 556.356L473.419 547.412L472.963 546.742L470.355 546.291Z"
      fill="#E23A44"
    />
    <path
      d="M470.356 546.29L461.523 552.303L470.595 565.628L479.428 559.615L470.356 546.29Z"
      fill="#E23A44"
    />
    <path
      d="M478.598 558.399L469.766 564.412L470.594 565.629L479.427 559.616L478.598 558.399Z"
      fill="#E23A44"
    />
  </svg>
</template>

<script>
export default {
  name: "ReducedStrengthOverlay"
};
</script>
