<template>
  <div class="user-profile-risks-container">
    <user-profile-risks-note-dialog
      v-if="selectedRisk"
      v-model="showNoteDialog"
      :risk="selectedRisk"
      @close="closeRiskNoteDialog"
    />

    <p class="mb-1 text-body-2 text-body-bold d-flex align-items-center">
      <img :src="iconSrc" aria-hidden="true" class="mr-1 location-image" />
      {{ title }}
    </p>

    <ul>
      <li v-for="risk in risks" :key="risk.name">
        {{ getRiskText(risk.name) }}

        <span v-if="risk.individualNote" class="note-icon">
          <v-tooltip top :open-on-click="false">
            <template v-slot:activator="{ on, attrs }">
              <button
                class="vfl-button-link"
                @click="openRiskNoteDialog(risk)"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="vflPrimary" size="16">mdi-note-outline</v-icon>
                <span class="d-sr-only">{{
                  $t("userProfile.risks.viewNote")
                }}</span>
              </button></template
            >
            <span>{{ $t("userProfile.risks.readNote") }}</span>
          </v-tooltip>
        </span>
      </li>
    </ul>
  </div>
</template>

<script>
import UserProfileBlock from "@/components/user-profile/UserProfileBlock.vue";
import UserProfileRisksNoteDialog from "@/components/user-profile/UserProfileRisksNoteDialog.vue";
import { mapGetters } from "vuex";
import { sendEvent } from "@/services/analytics/analytics.js";

export default {
  name: "UserProfileRisks",
  components: { UserProfileRisksNoteDialog, UserProfileBlock },
  props: {
    iconSrc: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    risks: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      selectedRisk: null,
      showNoteDialog: false
    };
  },
  computed: {
    ...mapGetters({
      adminId: "userId"
    }),
    ...mapGetters("userProfile", ["id"])
  },
  methods: {
    openRiskNoteDialog(risk) {
      const riskText = this.getRiskText(risk.name);

      this.selectedRisk = {
        ...risk,
        riskText
      };

      sendEvent("Open risk note dialog", {
        end_user: this.id,
        admin: this.adminId,
        risk: riskText
      });

      this.showNoteDialog = true;
    },
    closeRiskNoteDialog() {
      this.selectedRisk = null;
      this.showNoteDialog = false;
    },
    getRiskText(risk) {
      return this.$t(`suggestionsPage.table.issues.${risk}`);
    }
  }
};
</script>

<style scoped lang="scss">
.user-profile-risks-container {
  background-color: #f4f4f4;
  border-radius: 1rem;
  padding: 1rem;
}

.location-image {
  display: block;
  height: auto;
  width: 16px;
}

.note-icon {
  position: relative;
  top: -2px;
}
</style>
