<template>
  <div id="pain-impact-container" class="d-flex flex-column">
    <pain-impact-header class="mb-4" :loading="loading" />

    <vfl-alert
      v-if="error && !loading"
      :message="$t('mskOverview.error')"
      type="error"
      is-light
    />

    <pain-impact-empty-state
      v-else-if="!meetsMinSampleSizeThreshold && !loading"
    />

    <div v-else class="d-flex pain-impact-cards">
      <template v-if="loading">
        <v-skeleton-loader
          v-for="(_, index) in cardCount"
          :key="index"
          type="image"
          class="pain-impact-card-loader"
        />
      </template>
      <template v-else>
        <pain-impact-stat-card
          v-for="stat in stats"
          :key="stat.key"
          :loading="loading"
          v-bind="stat"
        />
      </template>
    </div>
  </div>
</template>

<script>
import PainImpactHeader from "./PainImpactHeader.vue";
import PainImpactEmptyState from "./PainImpactEmptyState.vue";
import PainImpactStatCard from "./PainImpactStatCard.vue";
import VflAlert from "@/components/vitrue-foundation-library/VflAlert.vue";
import { getTeamPainImpact } from "@/api/teamApi.js";
import {
  mapPainImpactData,
  PAIN_IMPACT_CARD_COUNT
} from "./pain-impact-mapper.js";
import { mapGetters } from "vuex";
import { MINIMUM_SAMPLE_SIZE_FOR_ANALYTICS } from "@/components/dashboard/cards/constants.js";

export default {
  name: "PainImpact",
  components: {
    PainImpactHeader,
    VflAlert,
    PainImpactStatCard,
    PainImpactEmptyState
  },
  data() {
    return {
      loading: true,
      error: false,
      stats: [],
      painImpactData: null
    };
  },
  mounted() {
    this.getData();
  },
  computed: {
    ...mapGetters(["demoFeatures"]),
    ...mapGetters("adminDashboard", [
      "dashboardDemoMetricsState",
      "usePredefinedDashboardMetrics"
    ]),

    meetsMinSampleSizeThreshold() {
      if (this.usePredefinedMetrics) return true;

      return (
        this.painImpactData?.totalCount >= MINIMUM_SAMPLE_SIZE_FOR_ANALYTICS
      );
    },
    cardCount() {
      return PAIN_IMPACT_CARD_COUNT;
    },
    usePredefinedMetrics() {
      return this.demoFeatures && this.usePredefinedDashboardMetrics;
    }
  },
  methods: {
    async getData() {
      this.loading = true;

      try {
        const data = await getTeamPainImpact();
        this.painImpactData = this.usePredefinedMetrics
          ? this.getDemoMetric(data)
          : data;
        this.stats = mapPainImpactData(this.painImpactData);
      } catch (ex) {
        this.$logger.captureException(ex);
        this.error = true;
      } finally {
        this.loading = false;
      }
    },
    getDemoMetric(data) {
      const metrics = this.dashboardDemoMetricsState;

      data.totalCount = metrics.totalCount;
      data.absenteeismCount = this.getDemoCount(
        metrics.absenteeismPercentage,
        metrics.totalCount
      );
      data.referralCount = this.getDemoCount(
        metrics.referralPercentage,
        metrics.totalCount
      );
      data.productivityLossCount = this.getDemoCount(
        metrics.productivityLossPercentage,
        metrics.totalCount
      );
      return data;
    },
    getDemoCount(percentage, totalCount) {
      return Math.round((percentage / 100) * totalCount);
    }
  }
};
</script>

<style scoped lang="scss">
.pain-impact-cards {
  gap: 1rem;

  > * {
    flex: 1 0 calc(33.3333% - 2rem);
  }
}

.pain-impact-card-loader {
  ::v-deep {
    > div {
      height: 128px;
    }
  }
}
</style>
