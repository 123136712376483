import getHealthRecommendations from "@/components/assessment/physical-labour/report/recommendations/health-recommendation-generator";
import getPainReliefRecommendations from "@/components/assessment/physical-labour/report/recommendations/pain-relief-recommendation-generator";
import getStrengthRecommendations from "@/components/assessment/physical-labour/report/recommendations/strengthening-recommendation-generator";
import getStretchRecommendations from "@/components/assessment/physical-labour/report/recommendations/stretch-recommendation-generator";
import getWarmUpRecommendations from "@/components/assessment/physical-labour/report/recommendations/warmup-exercise-recommendation-generator";
import getHealthAndSafetyRecommendations from "@/components/assessment/physical-labour/report/recommendations/health-and-safety-recommendation-generator";
import Cards from "./RecommendationCards.json";
import { LinkTypes } from "@/services/recommendations/link-types.js";
import { routes } from "@/router/route-constants.js";
import router from "@/router/index.js";

export function getRecommendations(results) {
  return [
    ...getPainCoachRecommendation(),
    ...getStretchRecommendations(results),
    ...getStrengthRecommendations(results),
    ...getWarmUpRecommendations(results),
    ...getPainReliefRecommendations(results),
    ...getHealthAndSafetyRecommendations(results),
    ...getHealthRecommendations(results)
  ];
}

function getPainCoachRecommendation() {
  const onUserDashboard = router.currentRoute.name === routes.WELLNESS;
  if (onUserDashboard) return [];
  return [
    {
      id: "pain_coach",
      json: Cards.painCoach,
      linkType: () => LinkTypes.INTERNAL
    }
  ];
}
