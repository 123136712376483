import RestApiClient from "@/infrastructure/restApiClient.js";

export async function updateProductGoals(goals) {
  const path = `/api/product-goals`;
  const body = goals;
  return await RestApiClient.post(path, body);
}

export async function getProductGoals() {
  const path = `/api/product-goals`;
  return await RestApiClient.get(path);
}
