<template>
  <div class="ai-feature-introduction-form pa-10 d-flex flex-column">
    <vfl-stepper
      v-if="visibleSteps.length > 1"
      :steps="visibleSteps.length"
      class="mb-6 mr-4"
      size="small"
      :current-step="step"
    />

    <transition name="slide-fade" mode="out-in">
      <component
        :is="currentStepComponent"
        :key="step"
        v-bind="currentStepProps"
        @update:modelValue="updateStepData"
      ></component>
    </transition>

    <div class="button-container mt-auto pt-6">
      <button
        type="button"
        class="vflPrimary--text text-button-reset back-button"
        @click="handleBack"
      >
        <v-icon color="vflPrimary" class="mr-1">mdi-chevron-left</v-icon>
        <span>{{ $t("buttons.back") }}</span>
      </button>
      <button
        type="button"
        class="vfl-button-primary is-medium continue-button"
        :class="{ 'is-disabled': isSaving }"
        @click="handleSubmit"
      >
        {{
          step === visibleSteps.length
            ? $t("buttons.getStarted")
            : $t("buttons.continue")
        }}
      </button>
    </div>
  </div>
</template>

<script>
import AiFeatureIntroductionRoles from "@/components/common/ai/feature-introduction/AiFeatureIntroductionRoles.vue";
import AiFeatureIntroductionGoals from "@/components/common/ai/feature-introduction/AiFeatureIntroductionGoals.vue";
import VflStepper from "@/components/common/VflStepper.vue";
import { updateCompanyRole } from "@/api/companyRoleApi.js";
import { updateProductGoals } from "@/api/productGoalsApi.js";
import { mapMutations } from "vuex";

export default {
  name: "AiFeatureIntroductionForm",
  components: {
    VflStepper,
    AiFeatureIntroductionRoles,
    AiFeatureIntroductionGoals
  },
  data() {
    return {
      step: 1,
      steps: [
        {
          component: AiFeatureIntroductionRoles,
          props: {
            heading: this.$t("aiFeatureIntroductionDialog.form.role.heading"),
            subheading: this.$t(
              "aiFeatureIntroductionDialog.form.role.subheading"
            )
          }
        },
        {
          component: AiFeatureIntroductionGoals,
          props: {
            heading: this.$t("aiFeatureIntroductionDialog.form.goals.heading"),
            subheading: this.$t(
              "aiFeatureIntroductionDialog.form.goals.subheading"
            )
          }
        }
      ],
      formData: {
        role: null,
        goals: null
      },
      isSaving: false
    };
  },
  props: {
    hideRoleQuestion: Boolean
  },
  computed: {
    visibleSteps() {
      return this.hideRoleQuestion ? this.steps.slice(1) : this.steps;
    },
    currentStepComponent() {
      return this.visibleSteps[this.step - 1].component;
    },
    currentStepProps() {
      return {
        ...this.visibleSteps[this.step - 1].props,
        modelValue: this.formData[this.getCurrentStepKey()]
      };
    },
    isLastStep() {
      return this.step === this.visibleSteps.length;
    }
  },
  methods: {
    ...mapMutations(["setCompanyRole"]),
    getCurrentStepKey() {
      if (this.hideRoleQuestion) {
        return "goals";
      }
      return this.step === 1 ? "role" : "goals";
    },
    updateStepData(data) {
      this.formData[this.getCurrentStepKey()] = data;
    },
    handleBack() {
      if (this.step > 1) {
        this.step--;
      } else {
        this.$emit("go-back");
      }
    },
    async handleSubmit() {
      const currentStepKey = this.getCurrentStepKey();

      if (currentStepKey === "role") {
        await this.saveRole();
      } else {
        await this.saveGoals();
      }

      if (this.isLastStep) {
        this.$emit("close-dialog");
      } else {
        this.step++;
      }
    },
    async saveRole() {
      if (!this.formData.role) return;

      this.isSaving = true;

      try {
        const selectedRole =
          this.formData.role.otherRole || this.formData.role.selectedRole;
        await updateCompanyRole(selectedRole);
        this.setCompanyRole(selectedRole);
      } catch (err) {
        this.$logger.captureException(err);
      } finally {
        this.isSaving = false;
      }
    },
    async saveGoals() {
      if (!this.formData.goals) return;

      this.isSaving = true;

      try {
        const selectedGoals = [...this.formData.goals.selectedGoals];
        if (this.formData.goals.otherGoals) {
          selectedGoals.push(this.formData.goals.otherGoals);
        }
        await updateProductGoals(selectedGoals);
      } catch (err) {
        this.$logger.captureException(err);
      } finally {
        this.isSaving = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.ai-feature-introduction-form {
  height: 90vh;
  max-height: 600px;

  h1 {
    color: var(--v-vflText-base);
  }

  ::v-deep {
    label,
    .v-input--selection-controls__input .v-icon {
      color: var(--v-vflText-base);
    }

    label {
      font-size: 0.875rem;
      font-weight: 500;
    }

    .v-text-field input {
      font-size: 0.875rem;
      font-weight: 500;
    }
  }

  .button-container {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    gap: 1rem;
    align-items: center;
  }

  .back-button {
    display: flex;
    align-items: center;
  }

  .continue-button {
    min-width: 260px;
  }

  @media (max-width: 600px) {
    .button-container {
      grid-template-columns: 1fr;
    }
  }
}

.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.3s ease;
}
.slide-fade-enter-from {
  opacity: 0;
  transform: translateX(30px);
}
.slide-fade-leave-to {
  opacity: 0;
  transform: translateX(-30px);
}
</style>
