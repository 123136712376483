import store from "../../store";
import sentry from "@/sentry";
import { URL_REDIRECT_ACTION_PARAMETER } from "@/services/authorization/url-redirect/redirect-constants.js";

const STORE_ACTION = "setRedirectAction";
const SESSION_STORAGE_ID = "redirectAction";

export function setUpRedirectActions(queryString = null) {
  let action = getActionFromQueryOrSession(queryString);

  if (!action) {
    return;
  }

  store.commit(STORE_ACTION, action);
}

export function saveActionToSessionStorage() {
  if (!store.getters.redirectAction) {
    return;
  }
  // If saving to session storage the action will already have been saved from the URL query parameters.
  sessionStorage.setItem(
    SESSION_STORAGE_ID,
    JSON.stringify(store.getters.redirectAction)
  );
}

function getActionFromQueryOrSession(queryString) {
  if (queryString && queryString.has(URL_REDIRECT_ACTION_PARAMETER)) {
    return Object.fromEntries(queryString.entries());
  }

  // Following SSO login
  let sessionAction = sessionStorage.getItem(SESSION_STORAGE_ID);
  if (!sessionAction) {
    return null;
  }

  return tryParseFromSessionStorage(sessionAction);
}

function tryParseFromSessionStorage(sessionAction) {
  try {
    const action = JSON.parse(sessionAction);
    if (!action.redirectAction) {
      return null;
    }
    return action;
  } catch (e) {
    sentry.captureException(e);
    return null;
  }
}
