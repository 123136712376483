import PainFactorAreas from "@/assets/json/DeskAssessment/PainFactorAreas.json";
import criteriaChecker from "@/components/common/criteria-checker.js";
import PainFactorIcons from "@/assets/json/DeskAssessment/PainFactorIcons.json";
import { getRankedPainAreas } from "@/services/assessment/pain/pain-rating-service.js";
import PainAreas from "@/components/painareas/PainAreasMale.json";

let healthQuestionsToDetermineIfLifestyleActive = [
  "gender",
  "pregnancy",
  "smoker"
];

class DisplayedIcon {
  constructor(element) {
    (this.id = element.icon),
      (this.icon = PainFactorIcons[element.icon]),
      (this.text = element.text),
      (this.disabled = element.disabled);
  }
}

function flattenResults(results, area) {
  return { ...results.pain.areas[area], ...results.health };
}

function getLifestyleIcon(results, assessmentType) {
  let healthSectionAnswered = Object.keys(results.health).some(
    r => healthQuestionsToDetermineIfLifestyleActive.indexOf(r) >= 0
  );
  let iconId = healthSectionAnswered
    ? "lifestyleAnswered"
    : "lifestyleDisabled";

  return {
    id: iconId,
    icon: PainFactorIcons[iconId],
    text: healthSectionAnswered
      ? getPainFactorText(results, assessmentType)
      : undefined,
    disabled: !healthSectionAnswered
  };
}

function getPainFactorText(results, assessmentType) {
  var focusedArea = getRankedPainAreas(results, assessmentType)[0];
  let smoker = results.health.smoker === 1;
  let notEnoughExercise =
    results.health.exercise !== undefined &&
    results.health.exercise !== null &&
    results.health.exercise < 3;

  let lifestyleText = [];
  if (notEnoughExercise) {
    lifestyleText.push({
      mainText:
        "deskAssessment.results.painContent.importantFactors.hoverText.lifestyle.exercise"
    });
  }

  if (smoker) {
    let specificText =
      "deskAssessment.results.painContent.importantFactors.hoverText.lifestyle.lifestyleFactors.smoking";
    lifestyleText.push({
      mainText:
        "deskAssessment.results.painContent.importantFactors.hoverText.lifestyle.generic",
      extraText: [
        { id: "lifestyleFactor", text: specificText },
        {
          id: "painArea",
          text: `reportText.pain.painAreaNames.${focusedArea.area}.title`
        }
      ]
    });
  }
  return lifestyleText;
}

export function getPainFactors(results, area, assessmentType) {
  let factors = [];
  let flatResults = flattenResults(results, area);
  let group = PainAreas[area].group;
  PainFactorAreas[group].forEach(element => {
    let match = criteriaChecker.areCriteriaMet(flatResults, element.criteria);
    if (match) {
      factors.push(new DisplayedIcon(element));
    }
  });

  let lifeStyleIcon = getLifestyleIcon(results, assessmentType);
  if (lifeStyleIcon.disabled || lifeStyleIcon.text.length > 0) {
    factors.push(lifeStyleIcon);
  }
  return factors;
}
