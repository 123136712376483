<template>
  <v-dialog v-model="show" max-width="420" persistent>
    <v-card>
      <div class="pa-6 pa-md-8">
        <h1 class="text-h5 mb-4">{{ risk.riskText }}</h1>
        <p class="text-body-1 mb-6">
          {{ risk.individualNote }}
        </p>
        <div class="d-flex flex-column-reverse flex-md-row">
          <button class="vfl-button-primary is-small mr-2" @click="close">
            {{ $t("buttons.close") }}
          </button>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "UserProfileRisksNoteDialog",
  props: {
    value: {
      type: Boolean,
      required: true
    },
    risk: {
      type: Object,
      required: true
    }
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  },
  methods: {
    close() {
      this.$emit("close");
    }
  }
};
</script>
