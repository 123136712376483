<template>
  <v-card class="pa-12">
    <v-row justify="center">
      <v-img height="200px" contain src="/images/undraw_notify.svg"></v-img>
      <v-card-title class="mt-5 mb-2">{{ heading }}</v-card-title>
      <v-card-subtitle>{{ subheading }}</v-card-subtitle>
      <v-card-actions>
        <v-btn
          class="px-5 mx-5"
          color="primary"
          outlined
          @click="$emit('discard')"
          >{{ $t("buttons.discard") }}</v-btn
        >
        <v-btn class="px-5 mx-5" color="primary" @click="$emit('keep')">{{
          $t("buttons.keep")
        }}</v-btn>
      </v-card-actions>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: "notification-dialog",
  props: {
    heading: String,
    subheading: String
  }
};
</script>
