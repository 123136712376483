// eslint-disable-next-line no-unused-vars
const criteria = [
  {
    id: "featureId",
    check: function (user, feature) {
      return user.features.includes(feature);
    }
  }
];

export default criteria;
