<template>
  <div class="my-6">
    <span v-if="text" class="text-overline">{{ text }}</span>
  </div>
</template>
<script>
export default {
  name: "AiAssistantMessageDivider",
  props: {
    text: String
  }
};
</script>
<style scoped lang="scss">
div {
  &:not(:has(span)) {
    background: hsla(0, 0%, 0%, 0.1);
    height: 1px;
  }
}

span {
  align-items: center;
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr auto 1fr;
  user-select: none;

  &:before,
  &:after {
    display: block;
    background: hsla(0, 0%, 0%, 0.1);
    content: "";
    height: 1px;
    width: 100%;
  }
}
</style>
