import {
  ALL,
  PAIN_AREA_MAP,
  NON_PAIN_CONDITION_MAP
} from "@/components/assessment/pre-existing-conditions/report/pain-banner-text-map.js";
import PainService from "@/services/assessment/pain/pain-assessment-service.js";
import { groupBy } from "lodash";
import { joinItemsWithConjunction } from "@/components/assessment/pre-existing-conditions/conditions-text-service.js";
import i18n from "@/plugins/i18n";

const HEALTH_CONDITIONS = "healthConditions";

export function getBannerText(results) {
  const areas = results?.pain?.areas;
  if (!areas || !Object.keys(areas).length) return [];

  let conditionsToShow = [];

  PAIN_AREA_MAP.forEach(m => {
    const conditions = getConditions(m, areas);
    const match = conditions.includes(m.value);
    if (match) {
      conditionsToShow.push(m);
    }
  });

  const filteredConditions = filterMatchingConditions(conditionsToShow);
  const nonPainConditions = getNonPainConditions(results);

  return [...new Set(filteredConditions), ...nonPainConditions];
}

function getConditions(map, results) {
  return map.area === ALL
    ? getAllConditions(results)
    : getPainConditionsFromGroup(map.area, results);
}

function getAllConditions(painResults) {
  const allResults = Object.values(painResults).flatMap(
    r => r[HEALTH_CONDITIONS] || []
  );
  return [...new Set(allResults)];
}

function filterMatchingConditions(conditions) {
  const groupedByValue = groupBy(conditions, "value");
  return Object.values(groupedByValue).reduce((acc, v) => {
    return [...acc, ...getTextForCondition(v)];
  }, []);
}

function getTextForCondition(conditions) {
  const general = conditions.find(m => m.area === ALL);
  if (conditions.length === 2 && general) {
    // Only one specific area with condition therefore return area.
    return [conditions.find(m => m.area !== ALL)];
  }
  if (conditions.length > 2 && general) {
    // Several areas with condition, return general text
    return [general];
  }
  return conditions;
}

function getPainConditionsFromGroup(painGroup, painResults) {
  const matchingAreas = Object.keys(painResults).filter(
    a => PainService.getPainAreaGroup(a) === painGroup
  );
  const conditions = matchingAreas.flatMap(
    a => painResults[a][HEALTH_CONDITIONS] || []
  );
  return [...new Set(conditions)];
}

function getNonPainConditions(results) {
  const answers = results?.setup?.nonPainCondition;
  if (!answers || answers.length === 0) {
    return [];
  }

  let conditions = [];
  NON_PAIN_CONDITION_MAP.forEach(m => {
    if (answers.includes(m.value)) {
      conditions.push(i18n.t(m.text));
    }
  });

  if (!conditions.length) {
    return [];
  }

  const concatenatedText = joinItemsWithConjunction(
    conditions,
    i18n.t("assessment.form.healthConditions.common.and")
  );
  return [
    {
      text: i18n.t(
        "assessment.form.healthConditions.banners.report.pain.nonPainCondition.text",
        {
          nonPainConditions: concatenatedText
        }
      ),
      icon: "/images/icons/health-conditions/chronicFatigueSyndrome.svg"
    }
  ];
}
