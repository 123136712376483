export default {
  getResultIndex(options, resultValue) {
    return options.findIndex(val => val.value === resultValue);
  },
  getScoreFromResult(question, resultValue) {
    let resultIndex = this.getResultIndex(question.options, resultValue);
    return question.scores[resultIndex];
  },
  getScore(questions, results) {
    let totalScore = 0;

    for (const key in questions) {
      const question = questions[key];
      const answer = results[key];
      if (question.scores != undefined && answer != undefined) {
        totalScore += this.getScoreFromResult(question, answer);
      }
    }
    return totalScore;
  },
  getPageQuestions(page) {
    let questions = Array.isArray(page.questions)
      ? page.questions
      : Object.entries(page.questions).flatMap(([group, groupQuestions]) =>
          groupQuestions.map(question => ({ ...question, group }))
        );

    return questions.map(question => ({
      ...question,
      group: question.group || null,
      theme: page.theme
    }));
  }
};
