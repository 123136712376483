// previous route is currently only used in ExitAssessmentButton.vue
// When a user clicks 'save & exit' we send them back to the previous route if they are logged in.
// If a user has an assessment in progress they will see a dialog asking if they want to continue.
// Clicking 'continue' updates the route. For that reason we exclude assessment paths from being stored below

const excludedPaths = [
  "deskassessment",
  "physical-labour-assessment",
  "driver-assessment",
  "work-resilience-assessment"
];

export const routerStore = {
  namespaced: true,
  state: {
    previousRoute: null
  },
  mutations: {
    setPreviousRoute(state, route) {
      state.previousRoute = route;
    }
  },
  actions: {
    updatePreviousRoute({ commit }, route) {
      if (route && route.path) {
        if (!excludedPaths.some(path => route.path.includes(path))) {
          commit("setPreviousRoute", route.path);
        }
      }
    }
  },
  getters: {
    getPreviousRoute: state => state.previousRoute || "/"
  }
};
