import {
  getAssessmentData,
  getAssessmentHistoryData,
  getCurrentUserMostRecentPainAssessment,
  getCurrentUserMostRecentAssessment
} from "../../customApi";
import * as sentry from "@sentry/browser";
import * as Constants from "@/constants/constants.js";

import burnoutAssessmentSchema from "@/assets/json/BurnoutAssessment/BurnoutAssessmentQuestions.json";

import { getSchema } from "@/services/assessment/get-schema-service.js";
import { assessmentTypes } from "@/constants/constants.js";

const calculateDeskAssessmentResults = (
  results,
  assessmentDataPointValue,
  schema
) => {
  schema.forEach(schemaItem => {
    schemaItem.pages.forEach(page => {
      if (schemaItem.theme == "pain") {
        for (const value of Object.values(page.questions)) {
          value.forEach(question => {
            if (assessmentDataPointValue.name == question.name) {
              if (
                results[schemaItem.theme].areas[assessmentDataPointValue.group]
              ) {
                results[schemaItem.theme].areas[assessmentDataPointValue.group][
                  assessmentDataPointValue.name
                ] = assessmentDataPointValue.intValue;
              } else {
                let answerObject = {};
                answerObject[assessmentDataPointValue.name] =
                  assessmentDataPointValue.intValue;
                results[schemaItem.theme].areas[
                  assessmentDataPointValue.group
                ] = answerObject;
              }
            }
          });
        }
      } else if (
        schemaItem.theme == "health" ||
        schemaItem.theme == "setup" ||
        page.name == "webcamInstructions"
      ) {
        calculateGeneralSchemaResults(
          results,
          assessmentDataPointValue,
          page,
          schemaItem
        );
      }
    });
  });
};

function addNonSchemaDataToResults(assessment, results) {
  let focusArea = getDataPoint(assessment, "assessmentfocusarea");
  if (focusArea) {
    return { ...results, focusArea: focusArea.intValue };
  }
  return results;
}

function getDataPoint(assessment, name) {
  if (!assessment) return null;
  return assessment.assessmentDataPointValues.find(x => x.name === name);
}

const calculateBurnoutAssessmentResults = (
  results,
  assessmentDataPointValue
) => {
  burnoutAssessmentSchema.forEach(schemaItem => {
    schemaItem.pages.forEach(page => {
      if (schemaItem.theme !== "results") {
        calculateGeneralSchemaResults(
          results,
          assessmentDataPointValue,
          page,
          schemaItem
        );
      }
    });
  });
};

const calculateGeneralSchemaResults = (
  results,
  assessmentDataPointValue,
  page,
  schemaItem
) => {
  page.questions.forEach(question => {
    if (assessmentDataPointValue.name == question.name) {
      if (assessmentDataPointValue.floatValue) {
        results[schemaItem.theme][assessmentDataPointValue.name] =
          assessmentDataPointValue.floatValue;
      } else {
        results[schemaItem.theme][assessmentDataPointValue.name] =
          assessmentDataPointValue.intValue;
      }
    }
  });
};

const calculateCheckBoxListQuestionResults = (
  results,
  assessmentDataPointCheckBoxListQuestionValue,
  schema
) => {
  schema.forEach(schemaItem => {
    schemaItem.pages.forEach(page => {
      if (schemaItem.theme == "pain") {
        for (const value of Object.values(page.questions)) {
          value.forEach(question => {
            if (
              assessmentDataPointCheckBoxListQuestionValue.name ==
                question.name &&
              assessmentDataPointCheckBoxListQuestionValue.values
            ) {
              if (
                results[schemaItem.theme].areas[
                  assessmentDataPointCheckBoxListQuestionValue.group
                ]
              ) {
                results[schemaItem.theme].areas[
                  assessmentDataPointCheckBoxListQuestionValue.group
                ][assessmentDataPointCheckBoxListQuestionValue.name] =
                  assessmentDataPointCheckBoxListQuestionValue.values.map(
                    a => a.intValue
                  );
              } else {
                let answerObject = {};
                answerObject[
                  assessmentDataPointCheckBoxListQuestionValue.name
                ] = assessmentDataPointCheckBoxListQuestionValue.values.map(
                  a => a.intValue
                );
                results[schemaItem.theme].areas[
                  assessmentDataPointCheckBoxListQuestionValue.group
                ] = answerObject;
              }
            }
          });
        }
      } else if (schemaItem.theme == "health" || schemaItem.theme == "setup") {
        page.questions.forEach(question => {
          if (
            assessmentDataPointCheckBoxListQuestionValue.name ==
              question.name &&
            assessmentDataPointCheckBoxListQuestionValue.values
          ) {
            results[schemaItem.theme][
              assessmentDataPointCheckBoxListQuestionValue.name
            ] = assessmentDataPointCheckBoxListQuestionValue.values.map(
              a => a.intValue
            );
          }
        });
      }
    });
  });
};

export async function getAssessmentResult(id, type) {
  if (type === Constants.assessmentTypes.deskAssessment) {
    return await getDeskAssessmentResult(id);
  } else if (type === Constants.assessmentTypes.burnoutAssessment) {
    return await getBurnoutAssessmentResult(id);
  } else if (type === Constants.assessmentTypes.driverAssessment) {
    return {};
  }
}

export async function getLatestPainAssessment() {
  return await getCurrentUserMostRecentPainAssessment()
    .then(assessment => {
      var results = convertDeskAssessmentData(assessment);
      var fullResults = addNonSchemaDataToResults(assessment, results);
      return fullResults;
    })
    .catch(err => {
      sentry.captureException(err);
    });
}

export function convertDeskPlannerResults(visualDeskPlannerItems) {
  let ids = visualDeskPlannerItems.map(x => x.itemId);
  let idCounts = {};
  for (var i = 0; i < ids.length; i++) {
    var num = ids[i];
    idCounts[num] = idCounts[num] ? idCounts[num] + 1 : 1;
  }
  for (let i = visualDeskPlannerItems.length - 1; i >= 0; i--) {
    let item = visualDeskPlannerItems[i];
    item.id = item.itemId;
    item.key = item.id + "_" + idCounts[item.id];
    item.offsetX = item.width / 2;
    item.offsetY = item.height / 2;
    item.elevationWebcam = item.webcamScreen;
    idCounts[item.id] = idCounts[item.id] - 1;
    delete item.assessmentDataPointId;
    delete item.assessmentId;
    delete item.itemId;
  }
  return visualDeskPlannerItems;
}
export async function getAssessmentTableHistoryData(isSimpleUser) {
  try {
    return await getAssessmentHistoryData(isSimpleUser);
  } catch (err) {
    sentry.captureException(err);
  }
}

export async function getDeskAssessmentResult(assessmentId, getAssessmentFunc) {
  var getAssessment = getAssessmentFunc ?? getAssessmentData;

  return await getAssessment(assessmentId)
    .then(assessment => {
      var results = convertDeskAssessmentData(assessment);
      var fullResults = addNonSchemaDataToResults(assessment, results);
      return fullResults;
    })
    .catch(err => {
      sentry.captureException(err);
    });
}

export function convertDeskAssessmentData(response) {
  let results = {
    id: "",
    health: {},
    pain: { areas: {} },
    setup: {},
    webcam: {},
    results: {},
    report: {},
    savedScore: null
  };
  if (response) {
    const schema = getSchema(assessmentTypes.deskAssessment);
    results.id = response.assessmentId;
    results.userId = response.userId;
    response.assessmentDataPointValues.forEach(assessmentDataPointValue => {
      calculateDeskAssessmentResults(results, assessmentDataPointValue, schema);
    });
    response.assessmentDataPointCheckBoxListQuestionValues.forEach(
      assessmentDataPointCheckBoxListQuestionValue => {
        calculateCheckBoxListQuestionResults(
          results,
          assessmentDataPointCheckBoxListQuestionValue,
          schema
        );
      }
    );
    results.webcam = {
      ...results.webcam,
      ...response.assessmentDataPointVideoMeasurement
    };
    let items = convertDeskPlannerResults(response.visualDeskPlannerItems);
    results.setup.deskItems = items;
    if (results.webcam != null && results.webcam.distanceFromScreen != null) {
      results.webcam.pictureTaken = true;
    }
    results.completedAt = response.completedAt;
    results.isCompleted = response.completedAt; // todo remove in future release
    results.notStarted = response.notStarted;
    results.savedScore = response.savedScore;
    results.assessmentType = response.assessmentType;
    results.userGoal = response.userGoal;

    return results;
  } else {
    return response;
  }
}

export async function getBurnoutAssessmentResult(assessmentId) {
  try {
    let result = await getAssessmentData(assessmentId);
    return convertBurnoutAssessmentData(result);
  } catch (err) {
    sentry.captureException(err);
  }
}

function convertBurnoutAssessmentData(response) {
  let results = {
    exhaustion: {},
    mentaldistance: {},
    cognitiveimpairment: {},
    emotionalimpairment: {},
    secondarysymptoms: {},
    results: {}
  };
  if (response) {
    response.assessmentDataPointValues.forEach(assessmentDataPointValue => {
      calculateBurnoutAssessmentResults(results, assessmentDataPointValue);
    });
    results.completedAt = response.completedAt;
    results.isCompleted = response.completedAt; // todo remove in future release
    results.assessmentId = response.assessmentId;
    return results;
  } else {
    return response;
  }
}

export function GetLatestDeskAssessmentResult() {
  return getCurrentUserMostRecentAssessment(
    Constants.assessmentTypes.deskAssessment
  )
    .then(response => {
      if (!response) {
        return null;
      }
      return convertDeskAssessmentData(response);
    })
    .catch(err => {
      sentry.captureException(err);
    });
}

export function GetLatestBurnoutAssessmentResult() {
  return getCurrentUserMostRecentAssessment(
    Constants.assessmentTypes.burnoutAssessment
  )
    .then(response => {
      if (!response) {
        return null;
      }
      return convertBurnoutAssessmentData(response);
    })
    .catch(err => {
      sentry.captureException(err);
    });
}

export default {
  getDeskAssessmentResult: getDeskAssessmentResult,
  getBurnoutAssessmentResult: getBurnoutAssessmentResult,
  getAssessmentTableHistoryData: getAssessmentTableHistoryData,
  getLatestPainAssessment: getLatestPainAssessment
};
