<template>
  <div class="container pa-4">
    <img
      class="image mr-4"
      src="/images/illustrations/woman-sitting-man-walking.png"
      width="135"
      alt=""
    />
    <div class="text-container">
      <i18n
        class="text-body-1 black--text mb-4"
        path="painImpactOverview.emptyState.heading.main"
      >
        <template v-slot:boldText>
          <span class="text-body-bold">{{
            $t("painImpactOverview.emptyState.heading.bold")
          }}</span>
        </template>
      </i18n>
      <span class="text-body-1 vflNeutralDark2--text">{{
        $t("painImpactOverview.emptyState.requirement")
      }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "PainImpactEmptyState"
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  width: 100%;
  border: 1px solid var(--v-vflNeutralLight2-base);
  border-radius: 12px;
  overflow: hidden;
}

.image {
  object-fit: contain;
  flex-shrink: 0;
}

.text-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
}
</style>
