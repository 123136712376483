import { getRiskRatingColors } from "@/components/dashboard/cards/average-scores/colours.js";
import { getDescriptorForPhysicalLabour } from "@/components/dashboard/cards/average-scores/rating.js";

export const PhysicalLabourCard = {
  icon: "physical.svg",
  title: "physicalLabour",
  score: score => score,
  ratingObj: score => {
    const colors = getRiskRatingColors(score);
    return {
      backgroundColor: colors.background,
      text: getDescriptorForPhysicalLabour(score),
      textColor: colors.text
    };
  }
};
