<template>
  <svg
    width="641"
    height="638"
    viewBox="0 0 641 638"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M399.447 414.281C359.742 427.465 354.962 428.326 318.804 441.045L272.64 448.751C272.64 448.751 249.97 496.409 273.335 506.893C278.947 509.405 303.255 504.658 315.262 500.352C360.975 483.956 388.089 467.023 406.338 458.6C420.249 452.182 419.113 444.896 420.47 438.989C424.481 421.563 410.814 410.5 399.447 414.281Z"
      fill="#17282F"
    />
    <path
      d="M92.6082 538.678L91.5364 538.949C85.1696 540.835 74.3406 546.438 69.1147 548.504C65.2413 550.036 58.9435 554.259 59.8498 558.463C60.6226 561.996 64.4133 564.402 70.849 561.674C76.7972 559.149 81.5676 558.026 84.567 557.139C90.2161 555.478 92.3966 556.757 92.7968 557.056L92.6082 538.678Z"
      fill="#B28B67"
    />
    <path
      d="M219.818 519.489C219.818 519.489 234.999 520.533 236.912 534.426C238.973 549.418 228.287 554.271 223.815 555.026C177.468 562.851 93.9547 558.425 93.9547 558.425C93.9547 558.425 90.8587 556.999 89.5982 547.803C88.3148 538.432 90.9921 536.836 90.9921 536.836C126.248 531.021 196.43 522.976 219.818 519.489Z"
      fill="#223A43"
    />
    <path
      d="M255.406 445.72C232.828 455.422 227.483 496.449 203.052 528.214C203.052 528.214 196.022 540.966 204.915 551.252C213.899 561.621 227.64 554.196 232.723 550.382C241.247 543.988 248.691 536.14 260.693 523.204C291.289 490.266 308.025 476.116 318.803 449.614L255.406 445.72Z"
      fill="#223A43"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M299.976 282.961C307.193 287.311 314.284 289.413 317.612 288.555C325.957 286.404 326.724 255.875 320.267 243.84C313.81 231.804 280.616 226.559 278.942 250.405C278.362 258.681 281.837 266.067 286.971 272.094L277.764 315.01H304.506L299.976 282.961Z"
      fill="#B28B67"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M322.794 249.24C322.529 247.68 321.881 245.948 321.359 244.472C320.685 242.569 319.666 240.853 318.545 239.237C316.433 236.193 313.9 233.444 311.103 231.162C305.994 226.995 299.491 224.592 293.096 225.372C289.867 225.766 286.733 226.998 284.065 229.048C281.655 230.9 279.336 233.627 276.215 233.823C272.819 234.036 269.706 231.038 266.982 229.187C263.912 227.1 260.645 225.579 257.056 224.988C251.043 223.998 245.395 226.029 241.251 230.962C236.841 236.212 233.636 243.91 236.532 250.866C237.07 252.159 237.762 253.249 238.756 254.152C239.668 254.98 241.06 255.862 241.429 257.186C241.822 258.593 240.649 260.408 240.195 261.677C239.543 263.496 239.008 265.406 239.199 267.383C239.511 270.63 241.551 273.729 243.706 275.863C245.899 278.035 248.597 279.28 251.479 279.854C253.404 280.238 255.371 280.449 257.327 280.305C258.298 280.234 259.155 279.964 260.096 279.75C261.01 279.541 261.522 279.784 262.298 280.286C265.904 282.617 269.781 283.519 273.945 283.172C277.459 282.879 281.509 282.028 284.375 279.62C287.559 276.946 287.495 273.259 286.746 269.319C287.54 269.713 289.736 269.956 288.424 268.388C287.904 267.767 286.922 267.515 286.255 267.149C285.478 266.724 284.69 266.175 284.087 265.481C281.652 262.68 284.111 256.98 287.171 256.041C291.718 254.646 292.736 261.076 295.982 262.739C297.848 263.695 299.8 262.388 301.199 261.054C303.072 259.266 304.348 256.897 305.45 254.486C306.358 252.501 307.203 250.488 308.128 248.513C308.56 247.591 310.056 244.983 309.305 243.94C312.561 243.291 316.189 244.644 318.993 246.419C320.153 247.152 321.037 247.961 321.655 249.257C321.787 249.534 322.076 250.359 322.438 250.393C323.112 250.456 322.86 249.632 322.794 249.24Z"
      fill="#070707"
    />
    <path
      d="M382.516 349.07C387.379 350.691 392.609 348.156 394.23 343.292C395.842 338.429 393.161 333.473 388.285 331.848L329.278 310.178C324.415 308.566 319.158 311.194 317.537 316.053C315.929 320.92 318.553 326.176 323.416 327.793L382.516 349.07Z"
      fill="#B28B67"
    />
    <path
      d="M298.072 304.993C298.072 304.993 308.439 297.674 320.688 302.073C331.297 305.889 342.68 313.195 342.68 313.195L333.466 332.096C333.466 332.096 300.215 321.3 298.072 304.993Z"
      fill="#C86E01"
    />
    <path
      d="M453.322 355.775C458.402 356.438 460.398 346.959 455.306 346.292L388.334 332.202C383.246 331.526 378.595 334.909 377.932 339.993C377.252 345.073 380.848 349.534 385.918 350.197L453.322 355.775Z"
      fill="#B28B67"
    />
    <path
      d="M455.306 346.29C464.1 347.593 469.489 348.321 478.345 349.947C483.208 350.844 486.503 350.53 486.216 353.441C485.995 355.618 479.895 354.629 477.709 354.377C469.515 353.432 469.458 353.675 469.387 354.536C469.308 355.415 473.999 355.892 476.066 357.443C477.351 358.406 476.494 361.259 472.501 360.42C463.38 358.489 453.322 355.773 453.322 355.773L455.306 346.29Z"
      fill="#B28B67"
    />
    <path
      d="M198.75 352.153C193.891 353.774 188.657 351.247 187.04 346.38C185.419 341.517 188.114 336.556 192.981 334.94L251.993 313.27C256.856 311.649 262.108 314.286 263.72 319.149C265.346 324.012 262.717 329.268 257.845 330.885L198.75 352.153Z"
      fill="#B28B67"
    />
    <path
      d="M283.2 308.084C283.2 308.084 272.82 300.76 260.585 305.16C249.966 308.98 238.588 316.277 238.588 316.277L247.811 335.178C247.811 335.178 281.067 324.387 283.2 308.084Z"
      fill="#FEB760"
    />
    <path
      d="M127.94 358.845C122.864 359.512 120.872 350.038 125.948 349.371L192.936 335.272C198.016 334.605 202.676 337.984 203.338 343.068C204.005 348.147 200.423 352.604 195.344 353.275L127.94 358.845Z"
      fill="#B28B67"
    />
    <path
      d="M125.951 349.373C117.179 350.681 111.777 351.396 102.925 353.026C98.0532 353.927 94.7714 353.609 95.0673 356.515C95.2749 358.706 101.37 357.704 103.566 357.456C111.75 356.507 111.808 356.75 111.883 357.615C111.958 358.494 107.263 358.971 105.204 360.522C103.928 361.494 104.78 364.347 108.773 363.499C117.881 361.569 127.943 358.848 127.943 358.848L125.951 349.373Z"
      fill="#B28B67"
    />
    <path
      d="M328.625 340.305C327.548 328.679 323.334 323.97 320.392 317.672C315.485 307.159 318.762 302.685 315.061 300.927C313.749 300.295 307.114 299.792 305.074 299.8C301.416 299.823 288.386 299.929 284.746 299.867C281.133 299.8 268.359 300.803 261.239 307.415C256.654 311.682 263.108 356.696 264.808 368.459C272.772 423.416 248.182 451.589 248.182 451.589C248.182 451.589 270.448 454.17 291.094 451.589C309.309 449.312 326.164 441.924 326.781 440.748C328.508 437.449 322.733 380.977 323.983 367.836C324.844 358.781 329.946 354.523 328.625 340.305Z"
      fill="#FF9B21"
    />
    <path
      d="M385.713 560.681C385.713 560.681 386.026 566.98 394.861 569.277C401.022 570.88 412.705 571.635 418.032 572.532C421.963 573.19 429.256 572.841 430.594 568.932C431.707 565.646 429.79 561.785 423.138 560.836C416.981 559.97 412.511 558.521 409.605 557.766C403.478 556.176 402.754 553.676 402.754 553.676L403.531 543.335H386.879L386.327 552.275L385.713 560.681Z"
      fill="#B28B67"
    />
    <path
      d="M387.961 428.454C387.961 440.716 384.803 525.664 384.803 545.254C384.803 545.254 386.667 547.82 396.208 547.82C403.566 547.82 406.424 545.254 406.424 545.254C406.424 545.254 421.703 465.368 422.007 428.454C422.056 423.043 413.982 414.311 398.668 416.237C386.455 417.774 387.961 426.833 387.961 428.454Z"
      fill="#17282F"
    />
    <path
      opacity="0.5"
      d="M262.637 470.111C250.742 491.944 225.416 536.152 219.272 538.32C213.129 540.488 139.018 545.547 102.73 547.806"
      stroke="white"
      stroke-width="0.903424"
    />
  </svg>
</template>

<script>
export default {
  name: "ExerciseTwiceOverlay"
};
</script>
