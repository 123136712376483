import store from "@/store";
import { startNewAssessment } from "@/customApi.js";
import { Actions } from "@/services/authorization/url-redirect/redirect-constants.js";
import { assessmentRoutes } from "@/router/route-constants.js";
import * as sentry from "@sentry/browser";

export async function handleAssessmentRedirect(type) {
  switch (store.getters.redirectAction?.redirectAction) {
    case Actions.ASSESSMENT.NEW:
      return await redirectToNewAssessment(
        store.getters.userEmail,
        type,
        store.getters.teamId
      );
    default:
      throw new Error(
        `${type} action is not handled in handleAssessmentRedirect`
      );
  }
}

async function redirectToNewAssessment(email, type, teamId) {
  let routeObject = null;
  try {
    let invitation = {
      email: { value: email },
      assessmentType: type
    };
    let id = await startNewAssessment(invitation, teamId);
    routeObject = {
      name: assessmentRoutes[type],
      params: {
        assessmentId: id
      }
    };
  } catch (err) {
    sentry.captureException(err);
  } finally {
    store.commit("setRedirectAction", null);
    return routeObject;
  }
}
