import { assessmentTypes } from "@/constants/constants.js";
import DeskRiskFactorScoring from "@/services/deskassessment/desk-assessment-risk-factor-scoring.js";
import PainService from "@/services/assessment/pain/pain-assessment-service.js";

function getRankedPainAreas(results, assessmentType) {
  let painAreas = [];
  var areas = Object.keys(results.pain.areas);
  areas.forEach(area => {
    let painGroup = PainService.getPainAreaGroup(area);
    let objectToAdd = {
      area: area,
      likelihoodScore:
        assessmentType === assessmentTypes.deskAssessment
          ? DeskRiskFactorScoring.GetPainLikelihoodScore(results, painGroup)
          : 0,
      likelihoodRating:
        assessmentType === assessmentTypes.deskAssessment
          ? DeskRiskFactorScoring.GetPainLikelihoodRating(results, painGroup)
          : 0,
      level: results.pain.areas[area].level,
      group: painGroup
    };
    painAreas.push(objectToAdd);
  });

  painAreas = painAreas.sort((a, b) => {
    return assessmentType === assessmentTypes.deskAssessment
      ? sortByLikelihoodAndArea(a, b)
      : sortByLevelAndArea(a, b);
  });

  return painAreas;
}

function sortByLikelihoodAndArea(a, b) {
  if (a.likelihoodScore !== b.likelihoodScore) {
    return b.likelihoodScore - a.likelihoodScore;
  }
  return a.area.localeCompare(b.area);
}

function sortByLevelAndArea(a, b) {
  if (a.level !== b.level) {
    return b.level - a.level;
  }
  return a.area.localeCompare(b.area);
}

function getPriorityPainArea(results, assessmentType) {
  return getRankedPainAreas(results, assessmentType)[0].area;
}

function getRankedPainAreaDtosOrDefault(results, assessmentType) {
  if (PainService.hasPain(results)) {
    let priorityArea = getRankedPainAreas(results, assessmentType)[0];
    return [
      {
        Area: priorityArea.area,
        Level: priorityArea.level,
        IsPreventative: false
      }
    ];
  }
  return [];
}

export {
  getRankedPainAreas,
  getPriorityPainArea,
  getRankedPainAreaDtosOrDefault
};
