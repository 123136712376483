<template>
  <div :style="'max-height:' + maxHeight + 'px'" style="overflow-y: scroll">
    <v-card
      v-for="step in steps"
      :key="step.sequence"
      :id="'step' + step.sequence"
      :height="$vuetify.breakpoint.smAndDown ? '100px' : '200px'"
      rounded="0"
      @click="stepSelected(step)"
      style="position: relative"
    >
      <v-overlay
        :id="'bar' + step.sequence"
        absolute
        v-show="step.sequence <= currentStepId"
        :color="currentStepId === step.sequence ? 'primary' : 'vitruePaleGrey'"
        opacity="1"
        :class="getOverlayClass(step)"
        :style="
          'width:' +
          (currentStepId === step.sequence ? currentStepProgress : 100) +
          '%;--duration:' +
          getRemainingDuration(step)
        "
        data-style="smooth"
        :z-index="1"
      />
      <v-hover v-slot="{ hover }">
        <v-row
          class="fill-height unselectable text-h5"
          :class="getStepTypography(step, hover)"
          no-gutters
          style="z-index: 2; position: relative"
        >
          <v-col
            cols="auto"
            :md="showTick(step) ? 2 : 4"
            align-self="center"
            :class="$vuetify.breakpoint.smAndDown ? 'px-5' : 'pl-3'"
          >
            <span v-if="currentStepId === step.sequence">{{
              formattedCurrentTimeLeft
            }}</span>
            <span v-else>{{ getCurrentStepDuration(step) }}s</span>
          </v-col>
          <v-col
            cols="8"
            align-self="center"
            class="flex-grow-1 flex-shrink-0 px-2"
          >
            {{ $t(step.title) }}
          </v-col>

          <v-col
            id="tickContainer"
            v-if="showTick(step)"
            cols="1"
            align-self="center"
            class="flex-grow-0 flex-shrink-1"
          >
            <animated-tick :trigger="animateTick(step)" />
          </v-col>
        </v-row>
      </v-hover>
    </v-card>
  </div>
</template>

<script>
import AnimatedTick from "@/components/common/AnimatedTick.vue";

export default {
  name: "VideoStepper",
  props: {
    maxHeight: Number,
    totalDuration: Number,
    exerciseSteps: Array,
    stepSelected: Function
  },
  components: {
    AnimatedTick
  },
  data() {
    return {
      currentStepId: null,
      steps: this.exerciseSteps ?? [],
      currentStepProgress: 0,
      currentStepTimeLeft: null,
      timer: null,
      paused: false,
      finalStep: false,
      playerState: null,
      lastCompletedStepId: null
    };
  },
  computed: {
    formattedCurrentTimeLeft() {
      if (!this.currentStepTimeLeft || this.currentStepTimeLeft <= 0) {
        return "0s";
      }
      let seconds = Math.round(this.currentStepTimeLeft);
      return seconds + "s";
    },
    highZoom() {
      return window.devicePixelRatio > 1;
    },
    allComplete() {
      return this.finalStep && this.currentStepId === null;
    }
  },
  beforeDestroy() {
    this.reset();
  },
  methods: {
    onYouTubeEvent(position, youtubeState) {
      if (!this.steps) return;

      let gotoStep = position
        ? this.steps.find(x => position >= x.start && position < x.end)
        : this.steps[0];

      if (!gotoStep) {
        this.lastCompletedStepId =
          this.currentStepId || this.lastCompletedStepId;
        this.reset();
      } else if (
        gotoStep.sequence !== this.currentStepId ||
        (this.playerState !== "VIDEO_PLAYING" &&
          youtubeState === "VIDEO_PLAYING")
      ) {
        this.updateSteps(position);
      }

      if (youtubeState !== this.playerState) {
        if (youtubeState !== "VIDEO_PLAYING") {
          // Need to play on next tick to match update steps (else overriden)
          this.$nextTick(() => this.pause());
        }
      }

      this.playerState = youtubeState;
    },
    getCurrentStepDuration(step) {
      return step.end - step.start;
    },
    getRemainingDuration(step) {
      let duration =
        this.getCurrentStepDuration(step) *
        ((100 - this.currentStepProgress) / 100);
      return duration;
    },
    // Needs to update on next tick for for css animations to play nice with Vue reactivity
    updateSteps(time) {
      this.reset();
      this.$nextTick(() => {
        let currentStep = this.steps.find(x => time >= x.start && time < x.end);
        if (currentStep) {
          this.finalStep =
            this.steps.indexOf(currentStep) === this.steps.length - 1;
          this.currentStepProgress = this.getCurrentStepProgress(
            currentStep,
            time
          );
          this.currentStepId = currentStep.sequence;
          this.setTimer(currentStep);
          this.scrollStepToTop(currentStep);
        }
      });
    },
    reset() {
      this.paused = false;
      this.currentStepId = null;
      this.currentStepTimeLeft = null;
      window.clearInterval(this.timer);
    },
    setTimer(currentStep) {
      this.timer = window.setInterval(this.updateTimer, 1000);
      this.currentStepTimeLeft =
        (this.getCurrentStepDuration(currentStep) *
          (100 - this.currentStepProgress)) /
        100;
    },
    updateTimer() {
      if (this.currentStepTimeLeft > 0) {
        this.currentStepTimeLeft -= 1;
      }
    },
    getCurrentStepProgress(currentStep, time) {
      const stepLength = currentStep.end - currentStep.start;
      const percentRemaining = ((time - currentStep.start) / stepLength) * 100;
      return Math.round(percentRemaining);
    },
    scrollStepToTop(currentStep) {
      var step = document.getElementById("step" + currentStep.sequence);
      step.scrollIntoView({ behavior: "smooth", block: "center" });
    },
    pause() {
      this.paused = true;
      window.clearInterval(this.timer);
    },
    showLargeText(step) {
      if (this.highZoom) {
        return;
      }
      return (
        this.currentStepId === step.sequence && this.$vuetify.breakpoint.lgAndUp
      );
    },
    getOverlayClass(step) {
      let progressClass = "";
      if (this.currentStepId === step.sequence) {
        progressClass += "progressBar";
        progressClass += this.paused ? " paused" : "";
      }
      return progressClass;
    },
    showTick(step) {
      return this.currentStepId
        ? step.sequence < this.currentStepId || this.allComplete
        : step.sequence <= this.lastCompletedStepId;
    },
    animateTick(step) {
      var endTick =
        this.allComplete && this.steps.indexOf(step) === this.steps.length - 1;
      return step.sequence === this.currentStepId - 1 || endTick;
    },
    getStepTypography(step, hover) {
      let weight =
        this.currentStepId === step.sequence
          ? "font-weight-bold"
          : "font-weight-medium";
      let fontSize = this.$vuetify.breakpoint.smAndDown ? "text-h6" : "text-h5";
      let elevation = hover ? "elevation-2" : "";
      return [weight, fontSize, elevation].join(" ");
    }
  }
};
</script>

<style scoped>
.progressBar {
  animation: progressBar calc(var(--duration) * 1s) linear;
  animation-fill-mode: both;
  -webkit-animation: progressBar calc(var(--duration) * 1s) linear;
  -webkit-animation-fill-mode: both;
  -moz-animation: progressBar calc(var(--duration) * 1s) linear;
  -moz-animation-fill-mode: both;
}

@keyframes progressBar {
  100% {
    width: 100%;
  }
}

@-webkit-keyframes progressBar {
  100% {
    width: 100%;
  }
}

@-moz-keyframes progressBar {
  100% {
    width: 100%;
  }
}

.v-card--link:before {
  background: none;
}

.paused {
  animation-play-state: paused;
}

.unselectable {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}
</style>
