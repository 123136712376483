import sentry from "@/sentry";
import { PainHistoryItem } from "@/components/user-profile/history/item-models/painHistoryItem.js";

function groupByArea(painLevels) {
  return painLevels.reduce((groups, painLevel) => {
    const { area } = painLevel;
    if (!groups[area]) {
      groups[area] = [];
    }
    groups[area].push(painLevel);
    return groups;
  }, {});
}

function processPainLevels(area, levels) {
  if (!Array.isArray(levels)) {
    sentry.captureMessage(
      `Invalid input for area ${area}: levels is not an array`
    );
    return [];
  }

  if (levels.length === 0) {
    return [];
  }

  const sortedLevels = levels.sort((a, b) => {
    if (!a.date && !b.date) return 0;
    if (!a.date) return 1;
    if (!b.date) return -1;
    return new Date(a.date) - new Date(b.date);
  });

  return sortedLevels
    .map((currentLevel, index) => {
      try {
        const previousLevel = index > 0 ? sortedLevels[index - 1].level : null;
        return new PainHistoryItem(
          currentLevel.date,
          area,
          previousLevel,
          currentLevel.level
        );
      } catch (error) {
        sentry.captureException(error);
        return null;
      }
    })
    .filter(Boolean);
}

function processPainHistoryChanges(painLevels) {
  try {
    const groupedPainLevels = groupByArea(painLevels);

    const allEntries = [];
    for (const [area, levels] of Object.entries(groupedPainLevels)) {
      const areaEntries = processPainLevels(area, levels);
      allEntries.push(...areaEntries);
    }

    return allEntries.sort((a, b) => new Date(b.date) - new Date(a.date));
  } catch (error) {
    sentry.captureException(error);
    return [];
  }
}

export { processPainHistoryChanges };
