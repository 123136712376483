export default {
  windowScoringPriorityObject: {
    monitorFacingAwayFromWindow: 1,
    monitorFacingWindow: 2,
    monitorAdjacentToWindow: 3,
    unknown: 4
  },
  getVisualDeskPlannerWindowScore(deskItems, canvasHeight) {
    let windowState = this.classifyWindows(deskItems, canvasHeight);
    if (windowState === "monitorAdjacentToWindow") {
      return 2.5;
    } else if (windowState === "monitorFacingAwayFromWindow") {
      return 1.7;
    } else if (windowState === "monitorFacingWindow") {
      return 0.8;
    }
    return deskItems.filter(i => i.id.includes("window")) ? 0 : -1;
  },
  classifyWindows(deskItems, canvasHeight) {
    // monitorAdjacentToWindow to you ideal, followed by monitorFacingAwayFromWindow then in monitorFacingWindow
    let windows = deskItems.filter(i => i.id.includes("window"));
    if (windows.length === 0) {
      return "unknown";
    }

    let monitors = deskItems.filter(
      i =>
        i.id === "laptop" ||
        i.id.includes("computerScreen") ||
        i.id === "laptopWithStand"
    );

    // if there are no monitors, then base the score of window on the default room layout
    if (monitors.length === 0) {
      if (windows.find(i => i.y > (2 * canvasHeight) / 3)) {
        return "monitorFacingWindow";
      } else if (windows.find(i => i.y < canvasHeight / 3)) {
        return "monitorFacingAwayFromWindow";
      } else if (windows.find(i => i.rotation === 90)) {
        return "monitorAdjacentToWindow";
      }
    }

    return this.getWorstWindowClasification(windows, monitors);
  },
  getWorstWindowClasification(windows, monitors) {
    let worstPosition = "unknown";
    // loop through each window to find the score vs each monitor (use the least scoring one)
    windows.forEach(window => {
      monitors.forEach(monitor => {
        var currentClassification = this.calculateWindowToMonitorPosition(
          window,
          monitor
        );
        if (
          this.windowScoringPriorityObject[currentClassification] <
          this.windowScoringPriorityObject[worstPosition]
        ) {
          worstPosition = currentClassification;
        }
      });
    });
    return worstPosition;
  },
  calculateWindowToMonitorPosition(window, monitor) {
    let monitorAngle = monitor.rotation;
    let windowAngle = window.rotation;
    let monitorToWindowAngle = windowAngle - monitorAngle;
    let windowIsHorizontal = window.rotation !== 90;

    if (windowIsHorizontal) {
      let windowAboveMonitor = window.y < monitor.y; // 0 is top of screen
      let windowPosition = this.checkVerticalWindowToMonitorPosition(
        windowAboveMonitor,
        monitorToWindowAngle
      );
      return windowPosition;
    }

    let windowIsLeftOfMonitor = window.x < monitor.x;
    let windowPosition = this.checkHorizontalWindowToMonitorPosition(
      windowIsLeftOfMonitor,
      monitorToWindowAngle,
      monitorAngle
    );
    return windowPosition;
  },
  checkVerticalWindowToMonitorPosition(
    windowAboveMonitor,
    monitorToWindowAngle
  ) {
    if (
      (monitorToWindowAngle <= -60 && monitorToWindowAngle >= -120) ||
      (monitorToWindowAngle >= 60 && monitorToWindowAngle <= 120)
    ) {
      return "monitorAdjacentToWindow";
    } else if (monitorToWindowAngle > 120 || monitorToWindowAngle < -120) {
      return windowAboveMonitor
        ? "monitorFacingWindow"
        : "monitorFacingAwayFromWindow";
    } else if (monitorToWindowAngle > -60 || monitorToWindowAngle < 60) {
      return windowAboveMonitor
        ? "monitorFacingAwayFromWindow"
        : "monitorFacingWindow";
    }
  },
  checkHorizontalWindowToMonitorPosition(
    windowLeftOfMonitor,
    monitorToWindowAngle,
    monitorAngle
  ) {
    if (
      (monitorToWindowAngle <= -60 && monitorAngle >= 150) ||
      (monitorToWindowAngle >= 240 && monitorAngle <= -150) ||
      (monitorToWindowAngle >= 60 && monitorToWindowAngle <= 120)
    ) {
      return "monitorAdjacentToWindow";
    } else if (monitorToWindowAngle < -60 || monitorToWindowAngle < 60) {
      return windowLeftOfMonitor
        ? "monitorFacingWindow"
        : "monitorFacingAwayFromWindow";
    } else if (monitorToWindowAngle < 240 || monitorToWindowAngle > 120) {
      return windowLeftOfMonitor
        ? "monitorFacingAwayFromWindow"
        : "monitorFacingWindow";
    }
  }
};
