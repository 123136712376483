import { generateContextFromReport } from "@/services/ai/report-context-generator.js";

export const contextToFile = (
  assessment,
  results,
  healthConditions,
  companyRole
) => {
  const context = generateContextFromReport(
    assessment,
    results,
    healthConditions,
    companyRole
  );

  const blob = new Blob([context], { type: "text/plain" });
  const url = window.URL.createObjectURL(blob);
  const date = new Date().toISOString().split("T")[0];
  const fileName = `report_context_${assessment.id}_${date}.txt`;

  const a = document.createElement("a");
  a.style.display = "none";
  a.href = url;
  a.download = fileName;

  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
  document.body.removeChild(a);
};
