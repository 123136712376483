<template>
  <div>
    <user-profile-name-and-email :layout="layout" />
    <user-profile-needs-attention class="mt-4" v-if="canViewMskInformation" />
    <user-profile-msk-overview class="mt-4" v-if="canViewMskInformation" />
  </div>
</template>

<script>
import UserProfileNameAndEmail from "@/components/user-profile/UserProfileNameAndEmail.vue";
import UserProfileMskOverview from "@/components/user-profile/UserProfileMskOverview.vue";
import { mapGetters } from "vuex";
import UserProfileNeedsAttention from "@/components/user-profile/UserProfileNeedsAttention.vue";

export default {
  name: "UserProfileHeader",
  components: {
    UserProfileNeedsAttention,
    UserProfileMskOverview,
    UserProfileNameAndEmail
  },
  props: {
    layout: {
      type: String,
      default: "page",
      validator: value => ["sidepanel", "page"].includes(value)
    }
  },
  computed: {
    ...mapGetters(["anonymousResults"]),
    canViewMskInformation() {
      return !this.anonymousResults;
    }
  }
};
</script>
