<template>
  <div class="user-profile-messaging-container">
    <messaging-container />
  </div>
</template>

<script>
import MessagingContainer from "@/components/messaging/layout/MessagingContainer.vue";
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  name: "UserProfileMessaging",
  components: { MessagingContainer },
  computed: {
    ...mapGetters("userProfile", ["name", "email", "id", "loadingStates"]),
    isMessagingReady() {
      return !this.loadingStates.userDetails && (this.name || this.email);
    }
  },
  watch: {
    id(newName, oldName) {
      if (newName !== oldName) {
        this.clearMessages();
      }
    },
    "loadingStates.userDetails": function (value) {
      if (!value) {
        this.initialiseMessages({ name: this.name, email: this.email });
      }
    },
    isMessagingReady(ready) {
      this.setLoading(!ready);
    }
  },
  created() {
    this.setLoading(!this.isMessagingReady);
  },
  methods: {
    ...mapActions("messagingStore", [
      "initialiseMessages",
      "clearMessages",
      "setLoading"
    ])
  }
};
</script>

<style lang="scss" scoped>
.user-profile-messaging-container {
  // Your styles here
}
</style>
