<template>
  <div class="intro-container">
    <div class="image-section"></div>
    <div class="content-section">
      <div class="content-wrapper">
        <div class="header">
          <img
            width="24"
            height="24"
            src="/images/icons/gradient-sparkles.svg"
            alt=""
            class="mr-2"
          /><span class="text-h5">{{
            $t("aiFeatureIntroductionDialog.title")
          }}</span>
        </div>
        <div class="text-body-1 vflText--text">
          <p class="mb-6">
            {{ $t("aiFeatureIntroductionDialog.body") }}
          </p>
          <p class="text-body-bold mb-6" style="max-width: 26ch">
            {{ $t("aiFeatureIntroductionDialog.emphasis") }}
          </p>
        </div>
      </div>
      <div class="button-container">
        <button
          @click="$emit('next-step')"
          class="vfl-button-primary is-full-width"
        >
          {{ $t("buttons.continue") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AiFeatureIntroductionStart"
};
</script>

<style scoped>
.intro-container {
  height: 100%;
  display: grid;
  grid-template-columns: 380px 1fr;
  background-color: #ffffff;
}

.image-section {
  background-image: url("/images/illustrations/ai-feature-introduction.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 90vh;
  max-height: 600px;
}

.content-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 2.5rem 3rem;
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
}

.button-container {
  margin-top: auto;
}

@media (max-width: 800px) {
  .intro-container {
    grid-template-columns: 1fr;
  }

  .image-section {
    display: none;
  }

  .content-section {
    padding: 1.5rem;
  }

  .button-container {
    margin-top: 2rem;
  }
}
</style>
