import { LinkTypes } from "@/services/recommendations/link-types.js";
import constants from "@/components/common/assessments/answer-constants";
import Cards from "./RecommendationCards.json";
import { routes } from "@/router/route-constants.js";
import router from "@/router/index.js";

export function getRecommendations(results) {
  let recommendations = getAllRecommendations();
  return recommendations.filter(r => r.showAnswer(results));
}

const isNotUserDashboard = _ => {
  return router.currentRoute.name !== routes.WELLNESS;
};
function showTakeBreaks(results) {
  const journeyLength = constants.DRIVER.JOURNEY_LENGTH;
  const breakLength = constants.DRIVER.BREAK_LENGTH;
  const driveLength = constants.DRIVER.DRIVE_LENGTH;
  const journeyOverTwoHours =
    results.habits.journeyLength === journeyLength.MORE_THAN_2_HOURS;
  const takesBreaks =
    results.habits.takeBreaks === constants.YES_NO_QUESTION.YES ||
    results.habits.takeBreaks === constants.SOMETIMES;
  const breakUnder15Minutes =
    results.habits.breakLength === breakLength.LESS_THAN_FIFTEEN_MINS;
  const driveOver2HoursBetweenBreaks =
    results.habits.driveLength === driveLength.TWO_TO_FOUR_HOURS ||
    results.habits.driveLength === driveLength.MORE_THAN_4_HOURS;

  if (!journeyOverTwoHours) {
    return false;
  }
  if (!takesBreaks) {
    return true;
  }
  if (breakUnder15Minutes || driveOver2HoursBetweenBreaks) {
    return true;
  }
  return false;
}

const showActiveBreaks = results => {
  const takesActiveBreaks =
    results.habits.breaksExercise === constants.YES_NO_QUESTION.YES;
  const journeyOverTwoHours =
    results.habits.journeyLength ===
    constants.DRIVER.JOURNEY_LENGTH.MORE_THAN_2_HOURS;
  return journeyOverTwoHours && !takesActiveBreaks;
};

const isArmPositionBad = results => {
  const elbowLocked =
    results.drivingPosture.truckElbowsLocked === constants.YES_NO_QUESTION.YES;
  const elbowsClose =
    results.drivingPosture.truckElbowsClose === constants.YES_NO_QUESTION.YES;
  const armTension =
    results.drivingPosture.wheelGripTension === constants.YES_NO_QUESTION.YES;
  const correctBend =
    results.drivingPosture.wheelElbowBend ===
    constants.DRIVER.ELBOWS_STEERING.SLIGHTLY_BENT;
  return elbowLocked || !elbowsClose || armTension || !correctBend;
};

function getAllRecommendations() {
  return [
    {
      id: "pain_coach",
      showAnswer: isNotUserDashboard,
      json: Cards.painCoach,
      linkType: () => LinkTypes.INTERNAL
    },
    {
      id: "take_breaks",
      showAnswer: showTakeBreaks,
      json: Cards.takeBreaks,
      linkType: () => LinkTypes.REGULAR
    },
    {
      id: "active_breaks",
      showAnswer: showActiveBreaks,
      json: Cards.activeBreaks,
      linkType: () => LinkTypes.REGULAR
    },
    {
      id: "adjust_seat_car",
      showAnswer: results =>
        results.vehicle.type === constants.DRIVER.VEHICLE_TYPE.CAR,
      json: Cards.adjustSeatCar,
      linkType: () => LinkTypes.REGULAR
    },
    {
      id: "adjust_seat_van",
      showAnswer: results =>
        results.vehicle.type === constants.DRIVER.VEHICLE_TYPE.VAN,
      json: Cards.adjustSeatVan,
      linkType: () => LinkTypes.REGULAR
    },
    {
      id: "adjust_seat_truck",
      showAnswer: results =>
        results.vehicle.type === constants.DRIVER.VEHICLE_TYPE.TRUCK,
      json: Cards.adjustSeatTruck,
      linkType: () => LinkTypes.REGULAR
    },
    {
      id: "lower_back_support",
      showAnswer: results =>
        results.drivingPosture.backSupported === constants.YES_NO_QUESTION.NO,
      json: Cards.lowerBackSupport,
      linkType: () => LinkTypes.REGULAR
    },
    {
      id: "leg_position",
      showAnswer: results =>
        results.drivingPosture.horizontalUpperLegs ===
          constants.YES_NO_QUESTION.NO ||
        results.drivingPosture.kneeClearsSeat ===
          constants.YES_NO_QUESTION.NO ||
        results.drivingPosture.seatFlat === constants.YES_NO_QUESTION.NO,
      json: Cards.legPosition,
      linkType: () => LinkTypes.REGULAR
    },
    {
      id: "arm_stretch",
      showAnswer: isArmPositionBad,
      json: Cards.armStretch,
      linkType: () => LinkTypes.REGULAR
    },
    {
      id: "arm_position",
      showAnswer: isArmPositionBad,
      json: Cards.arm_position,
      linkType: () => LinkTypes.REGULAR
    },
    {
      id: "dead_pedal",
      showAnswer: results =>
        results.drivingPosture.deadPedalUsed === constants.YES_NO_QUESTION.NO,
      json: Cards.deadPedal,
      linkType: () => LinkTypes.REGULAR
    },
    {
      id: "steering_wheel_position",
      showAnswer: results =>
        results.drivingPosture.roadVisibleOverWheel ===
          constants.YES_NO_QUESTION.NO ||
        results.drivingPosture.wheelDashboardView ===
          constants.YES_NO_QUESTION.NO,
      json: Cards.steeringWheelPosition,
      linkType: () => LinkTypes.REGULAR
    },
    {
      id: "headrest_position",
      showAnswer: results =>
        results.drivingPosture.headrestSupportsHead ===
        constants.YES_NO_QUESTION.NO,
      json: Cards.headrestPosition,
      linkType: () => LinkTypes.REGULAR
    },
    {
      id: "severe_weather",
      showAnswer: results =>
        results.habits.severeWeather === constants.YES_NO_QUESTION.YES,
      json: Cards.severeWeather,
      linkType: () => LinkTypes.REGULAR
    },
    {
      id: "trunk_loading",
      showAnswer: results =>
        results.drivingPosture.trunkLoadWithoutBending ===
        constants.YES_NO_QUESTION.NO,
      json: Cards.trunkLoading,
      linkType: () => LinkTypes.REGULAR
    }
  ];
}
