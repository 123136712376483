<template>
  <div class="assessment-notification-setting-card" v-if="show">
    <button class="close-button" @click="handleDismiss">
      <span class="d-sr-only">{{
        $t("dashboardAssessmentCards.assessmentNotificationSetting.dismiss")
      }}</span>
      <v-icon color="vflNeutralDark2" size="28">mdi-close</v-icon>
    </button>
    <div class="content">
      <h3 class="content-heading text-subtitle-1 text-body-dark mb-4 pt-2">
        {{ $t("dashboardAssessmentCards.assessmentNotificationSetting.title") }}
      </h3>
      <v-switch
        v-model="notificationEnabled"
        @change="handleNotificationUpdate"
        hide-details
        class="content-toggle label-reverse mt-0 pt-0 text-body-2"
        color="var(--v-vflPrimary-base)"
        :ripple="false"
      >
        <template #label>{{
          $t("dashboardAssessmentCards.assessmentNotificationSetting.toggle")
        }}</template>
      </v-switch>
    </div>
  </div>
</template>

<script>
import {
  getNotificationsForEndUser,
  updateNotificationForEndUser
} from "@/customApi";
import { mapGetters } from "vuex";
import NewFeatureService from "@/services/new-feature-service.js";
import { sendEvent } from "@/services/analytics/analytics.js";

const ASSESSMENT_COMPLETE_NOTIFICATION = "assessmentCompleteNotification";

export default {
  name: "AssessmentNotificationSettingCard",
  data() {
    return {
      isLoading: false,
      notificationEnabled: false,
      showCard: false
    };
  },
  computed: {
    ...mapGetters(["userId", "demoFeatures"]),
    show() {
      if (this.demoFeatures) return false;

      return this.shouldShowFeature && !this.isLoading && this.showCard;
    },
    shouldShowFeature() {
      return NewFeatureService.showFeature(
        "DashboardAssessmentNotificationSetting"
      );
    }
  },
  async created() {
    if (this.demoFeatures) return;

    await this.fetchNotificationSettings();
  },
  methods: {
    async fetchNotificationSettings() {
      try {
        this.isLoading = true;

        const notifications = await getNotificationsForEndUser();

        const assessmentCompleteNotification = notifications.find(
          n => n.notificationName === ASSESSMENT_COMPLETE_NOTIFICATION
        );

        if (assessmentCompleteNotification) {
          this.notificationEnabled =
            assessmentCompleteNotification.email ||
            assessmentCompleteNotification.text;
        } else {
          // The endpoint will not return an object for this notification type if it has not been interacted with in settings.
          // It is off by default
          this.notificationEnabled = false;
        }

        this.showCard = !this.notificationEnabled;
      } catch (error) {
        this.$logger.captureException(error);
      } finally {
        this.isLoading = false;
      }
    },
    async handleNotificationUpdate(enabled) {
      try {
        await updateNotificationForEndUser({
          email: enabled,
          endUserId: this.userId,
          notificationName: ASSESSMENT_COMPLETE_NOTIFICATION,
          text: false,
          time: null
        });

        sendEvent("Assessment complete notification updated from dashboard", {
          enabled
        });
      } catch (error) {
        this.notificationEnabled = !enabled;

        this.$logger.captureException(error);
      }
    },
    handleDismiss() {
      this.showCard = false;
      this.markFeatureAsSeen();

      sendEvent(
        "Assessment complete notification card dismissed from dashboard"
      );
    },
    markFeatureAsSeen() {
      NewFeatureService.markFeatureSeen(
        "DashboardAssessmentNotificationSetting",
        true
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.assessment-notification-setting-card {
  background-color: var(--v-vflBeigeLight1-base);
  border-radius: 12px;
  display: grid;
  grid-template-rows: auto 1fr;
  padding: 1rem;
  position: relative;
  min-width: 320px;

  .close-button {
    align-self: start;
    grid-row: 1;
    grid-column: 1;
    justify-self: end;
    position: relative;
  }

  .content {
    grid-row: 1;
    grid-column: 1;

    &-heading {
      width: 20ch;
    }

    &-toggle {
      color: var(--v-vflNeutralDark1-base);
    }
  }
}

.label-reverse {
  ::v-deep {
    .v-input__slot {
      flex-direction: row-reverse;
      justify-content: flex-end;

      .v-application--is-ltr & {
        .v-input--selection-controls__input {
          margin-right: 0;
          margin-left: 8px;
        }
      }

      .v-application--is-rtl & {
        .v-input--selection-controls__input {
          margin-left: 0;
          margin-right: 8px;
        }
      }
    }

    .v-label {
      font: inherit;
    }
  }
}
</style>
