import VueGtag from "vue-gtag";
import Hotjar from "vue-hotjar";
import mixpanel from "mixpanel-browser";

export function setupAnalytics(Vue, router) {
  const enabledAnalytics =
    import.meta.env.VITE_APP_ANALYTICS_GATHERING === "true";

  // VueGtag configuration
  Vue.use(
    VueGtag,
    {
      config: { id: "UA-175692691-1" },
      enabled: enabledAnalytics,
      pageTrackerTemplate(to) {
        if (to.path.includes("deskassessment")) {
          return {
            page_title: "Desk Assessment",
            page_path: "/deskassessment/"
          };
        }

        return {
          page_title: to.name,
          page_path: to.path
        };
      },
      params: {
        send_page_view: true
      },
      includes: [
        {
          id: "UA-175692691-4",
          enabled: enabledAnalytics,
          pageTrackerTemplate(to) {
            if (to.path.includes("deskassessment")) {
              return {
                page_title: "Desk Assessment",
                page_path: "/deskassessment/"
              };
            }

            return {
              page_title: to.name,
              page_path: to.path
            };
          },
          params: {
            send_page_view: true
          }
        }
      ]
    },
    router
  );

  // Hotjar configuration
  const isProduction = import.meta.env.VITE_NODE_ENV === "production";
  Vue.use(Hotjar, {
    id: "3028553",
    isProduction: isProduction
  });

  // Mixpanel configuration
  mixpanel.init(import.meta.env.VITE_MIXPANEL_TOKEN, { debug: !isProduction });
  Vue.prototype.$mixpanel = mixpanel;
}
