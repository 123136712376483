import {
  STAGES,
  STAGE_MAPPING
} from "@/components/assessment/menopause/report/constants/index.js";

export const HEADER_CONTENT = {
  getTitleText: results => {
    return results?.stage?.stage >= 2 && results.stage.stage <= 3
      ? "Your personalised report"
      : "Your report";
  },
  getContentText: results => {
    const stageText = STAGE_MAPPING[results.stage.stage] || STAGES.MENOPAUSE;
    return `How to enhance your musculoskeletal health during ${stageText}`;
  }
};
