<template>
  <button class="vfl-button-primary-neutral is-medium" @click="exit">
    {{ $t("layout.topbar.exitAssessmentButton") }}
  </button>
</template>

<script>
import { track } from "@/customApi.js";
import { mapGetters } from "vuex";

export default {
  name: "ExitAssessmentButton",
  computed: {
    ...mapGetters("routerStore", ["getPreviousRoute"])
  },
  methods: {
    async exit() {
      track("Exit assessment from TopBar button", {
        route: this.$route.path
      });

      const previousRoute = this.getPreviousRoute;
      const targetRoute = previousRoute !== "/" ? previousRoute : "/wellness";

      await this.$router.push(targetRoute);
    }
  }
};
</script>
