import { getTeamUserpoolSettings } from "@/customApi";
import aws_exports from "@/aws-exports";
import Amplify from "aws-amplify";
import sentry from "@/sentry";

const localStorageName = "customUserpoolSettings";

export default {
  async setUserpoolSettingsFromId(teamId) {
    try {
      var userpoolSettings = await getTeamUserpoolSettings(teamId);
      if (!userpoolSettings) {
        localStorage.removeItem(localStorageName);
        return;
      }

      localStorage.setItem(localStorageName, JSON.stringify(userpoolSettings));
      setAwsExports(userpoolSettings);
    } catch (ex) {
      sentry.captureException(ex);
    }
  }
};

function setAwsExports(userpoolSettings) {
  // set custom userpool settings
  aws_exports.aws_user_pools_id = userpoolSettings.userpoolId;
  aws_exports.aws_user_pools_web_client_id = userpoolSettings.appClientId;
  aws_exports.oauth.domain = userpoolSettings.userpoolDomain;

  Amplify.configure(aws_exports);
}
