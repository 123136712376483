<template>
  <span class="ai-assistant-three-dots-loader-container"></span>
</template>

<script>
export default {
  name: "AiAssistantThreeDotsLoader"
};
</script>

<style lang="scss" scoped>
.ai-assistant-three-dots-loader-container {
  --r: 6px;

  animation: dot-flashing 0.9s infinite linear alternate 0.3s;
  border-radius: 999px;
  display: flex;
  height: var(--r);
  position: relative;
  transform: translateX(calc(var(--r) * 2));
  min-width: 6px;
  width: var(--r);

  &:before,
  &:after {
    animation: dot-flashing 0.9s infinite linear alternate;
    background-color: inherit;
    border-radius: inherit;
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
  }

  &:before {
    animation-delay: 0s;
    height: var(--r);
    left: calc(var(--r) * -2);
    width: var(--r);
  }

  &:after {
    animation-delay: 0.6s;
    height: var(--r);
    left: calc(var(--r) * 2);
    width: var(--r);
  }

  @keyframes dot-flashing {
    0% {
      background-color: var(--v-vflGreyDark-base);
    }
    50%,
    100% {
      background-color: #c2c5c8;
    }
  }
}
</style>
