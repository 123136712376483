<template>
  <v-scale-transition>
    <v-btn
      :x-small="!isSmallScreen"
      depressed
      :ripple="false"
      :color="isSmallScreen ? 'var(--v-vflPrimary-darken4)' : 'transparent'"
      :fixed="!absolute"
      :absolute="absolute"
      fab
      dark
      right
      :bottom="isSmallScreen"
      :style="buttonStyle"
      @click="$emit('input', true)"
      class="no-print"
      :class="{ mobile: isSmallScreen }"
    >
      <v-icon v-show="isSmallScreen">mdi-message-alert</v-icon>
      <v-chip
        v-show="!isSmallScreen"
        label
        color="primary"
        text-color="white"
        :style="chipStyle"
        @click="$emit('input', true)"
      >
        {{ $t("feedback.feedbackButton") }}
        <v-icon right>mdi-message-alert</v-icon>
      </v-chip>
    </v-btn>
  </v-scale-transition>
</template>

<script>
export default {
  name: "FeedbackButton",
  props: {
    absolute: Boolean
  },
  computed: {
    buttonStyle() {
      return "transform:rotate(" + [!this.isSmallScreen ? 90 : 0] + "deg);";
    },
    chipStyle() {
      return "transform:rotate(" + [!this.isSmallScreen ? 180 : 0] + "deg);";
    },
    isSmallScreen() {
      return this.$vuetify.breakpoint.smAndDown;
    }
  }
};
</script>
<style scoped>
button.mobile {
  box-shadow: 0px 2px 6px 3px #031c7b2b;
}
</style>
