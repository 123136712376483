<template>
  <v-dialog
    v-if="showSignUpDialog && !signedIn"
    v-model="showSignUpDialog"
    max-width="400px"
    :fullscreen="$vuetify.breakpoint.xs"
    persistent
  >
    <div class="pa-8 d-flex flex-column container">
      <img
        class="mb-2"
        src="/images/ViewReports.svg"
        alt=""
        width="80"
        height="80"
      />
      <h5 class="text-h4 mb-4">
        {{ $t("authentication.endOfReportSignup.title") }}
      </h5>
      <div class="text-body-1 mb-6">
        <p v-if="teamIdpName">
          {{ ssoText }}
        </p>

        <i18n
          v-else-if="userEmail"
          :path="
            userExists
              ? 'authentication.endOfReportSignup.emailText.alreadyExist'
              : 'authentication.endOfReportSignup.emailText.newUser'
          "
        >
          <template v-slot:email>
            <span class="font-weight-bold">{{ obfuscatedEmail }}</span>
          </template>
        </i18n>
      </div>

      <sign-up
        reportPageSignUp
        :errorMsg="errorMessage"
        :assessmentId="assessmentId"
        @regularSignInComplete="closeDialog"
        @user-exists="userExists = $event"
      />
    </div>
  </v-dialog>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import SignUp from "@/components/authentication/SignUp.vue";
import StringService from "@/services/string-service.js";
import { assessmentTypes } from "@/constants/constants.js";

export default {
  props: {
    assessmentType: String,
    assessmentId: String
  },
  components: {
    SignUp
  },
  data() {
    return {
      showSignUpDialog: true,
      errorMessage: null,
      userExists: false
    };
  },
  mounted() {
    if (this.$route.query.error) {
      this.errorMessage = this.$route.query.error;
    }

    if (!this.signedIn && this.teamId) {
      this.setUpSignupDialog();
    }
  },
  watch: {
    signedIn(val) {
      if (val) {
        this.resetSideBarAndScroll();
      }
    },
    teamId(val) {
      if (!this.signedIn && val) {
        this.setUpSignupDialog();
      }
    }
  },
  beforeDestroy() {
    this.resetSideBarAndScroll();
  },
  computed: {
    ...mapGetters(["signedIn", "userEmail", "teamId", "teamIdpName"]),
    obfuscatedEmail() {
      return StringService.obfuscateEmail(this.userEmail);
    },
    ssoText() {
      const textToShow =
        this.assessmentType === assessmentTypes.burnoutAssessment
          ? "workspaceResilience"
          : "generalWorkspace";
      return this.$t(`authentication.endOfReportSignup.ssoText.${textToShow}`);
    }
  },
  methods: {
    ...mapMutations(["setDummySideBar"]),
    setScroll(enabled) {
      document.body.style.overflow = enabled ? "" : "hidden";
      document.body.style.height = enabled ? "" : "100vh";
    },
    resetSideBarAndScroll() {
      this.setDummySideBar(false);
      this.setScroll(true);
    },
    setUpSignupDialog() {
      this.setDummySideBar(true);
      this.setScroll(false);
      let assessment = {
        id: this.assessmentId,
        assessmentType: this.assessmentType
      };
      sessionStorage.setItem("reportAssessment", JSON.stringify(assessment));
    },
    closeDialog() {
      this.resetSideBarAndScroll();
      sessionStorage.removeItem("reportAssessment");
      this.showSignUpDialog = false;
      if (this.assessmentType === "Desk assessment") {
        hj("stateChange", this.$router.currentRoute.path + `/deskreport`);
      }
    }
  }
};
</script>

<style scoped>
.container {
  background: white;
  width: 100%;
  height: 100%;
}
</style>
