<template>
  <svg
    width="641"
    height="637"
    viewBox="0 0 641 637"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M308.405 616.533H360.112V608.574H308.405V616.533Z"
      fill="#92141C"
    />
    <path
      d="M300.957 598.885V626.221L297.733 623.098V602.008L300.957 598.885Z"
      fill="#E23A44"
    />
    <path
      d="M300.958 598.885H319.075V626.221H300.958V598.885Z"
      fill="#E23A44"
    />
    <path
      d="M367.557 598.885V626.221L370.78 623.098V602.008L367.557 598.885Z"
      fill="#E23A44"
    />
    <path
      d="M367.558 598.885H349.441V626.221H367.558V598.885Z"
      fill="#E23A44"
    />
    <path
      d="M272.498 615.009L315.24 585.912L310.759 579.329L268.016 608.429L272.498 615.009Z"
      fill="#92141C"
    />
    <path
      d="M256.413 604.61L258.941 608.326L269.265 623.486L271.793 627.201L267.372 626.439L266.6 625.302L256.278 610.135L255.504 609.005L256.413 604.61Z"
      fill="#E23A44"
    />
    <path
      d="M256.415 604.611L271.391 594.419L286.771 617.01L271.795 627.203L256.415 604.611Z"
      fill="#E23A44"
    />
    <path
      d="M270.389 625.136L285.364 614.944L286.769 617.008L271.793 627.201L270.389 625.136Z"
      fill="#E23A44"
    />
    <path
      d="M311.466 567.132L313.995 570.846L324.318 586.011L326.847 589.724L327.755 585.331L326.983 584.197L316.66 569.032L315.887 567.897L311.466 567.132Z"
      fill="#E23A44"
    />
    <path
      d="M311.47 567.133L296.494 577.327L311.873 599.919L326.85 589.725L311.47 567.133Z"
      fill="#E23A44"
    />
    <path
      d="M325.442 587.666L310.466 597.859L311.87 599.923L326.847 589.729L325.442 587.666Z"
      fill="#E23A44"
    />
  </svg>
</template>
<script>
export default {
  name: "WeightliftingOverlay"
};
</script>
