<template>
  <Avatar
    variant="beam"
    :size="size"
    :name="text || fallbackText"
    :colors="colours"
  />
</template>

<script>
import Avatar from "vue2-boring-avatars";

export default {
  name: "AvatarIcon",
  components: {
    Avatar
  },
  data() {
    return {
      colours: [
        "#023839",
        "#3B9698",
        "#98DDDE",
        "#437CEE",
        "#B9DDFF",
        "#A3ADE6",
        "#E5C6F8",
        "#F7BE7D",
        "#F5E075",
        "#CEF6AC",
        "#005F61"
      ]
    };
  },
  props: {
    text: String,
    fallbackText: String,
    size: {
      type: Number,
      default: 40
    }
  }
};
</script>
