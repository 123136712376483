import sentry from "@/sentry.js";
import { RecommendationHistoryItem } from "@/components/user-profile/history/item-models/recommendationHistoryItem.js";

function processRecommendationHistory(history) {
  try {
    return history.map(
      item =>
        new RecommendationHistoryItem(
          item.date,
          item.recommendationName,
          item.type
        )
    );
  } catch (error) {
    sentry.captureException(error);
    return [];
  }
}

export { processRecommendationHistory };
