import i18n from "@/plugins/i18n.js";

export function mapAndOrderRisks(issues) {
  const issueMap = {
    // High risk issues
    NeckRisk: { text: i18n.t("risks.highRisk"), id: "HighRisk" },
    MidBackRisk: { text: i18n.t("risks.highRisk"), id: "HighRisk" },
    LowerBackRisk: { text: i18n.t("risks.highRisk"), id: "HighRisk" },
    ShoulderRisk: { text: i18n.t("risks.highRisk"), id: "HighRisk" },
    ElbowRisk: { text: i18n.t("risks.highRisk"), id: "HighRisk" },
    WristRisk: { text: i18n.t("risks.highRisk"), id: "HighRisk" },
    HipRisk: { text: i18n.t("risks.highRisk"), id: "HighRisk" },
    GluteRisk: { text: i18n.t("risks.highRisk"), id: "HighRisk" },
    KneeRisk: { text: i18n.t("risks.highRisk"), id: "HighRisk" },
    CalfRisk: { text: i18n.t("risks.highRisk"), id: "HighRisk" },
    HamstringRisk: { text: i18n.t("risks.highRisk"), id: "HighRisk" },
    AnkleRisk: { text: i18n.t("risks.highRisk"), id: "HighRisk" },
    NoPainRisk: { text: i18n.t("risks.highRisk"), id: "HighRisk" },
    // Intense pain
    IntensePainRisk: {
      text: i18n.t("risks.severePain"),
      id: "IntensePain"
    },
    // Health conditions
    MobilityIssues: {
      text: i18n.t("risks.mobilityIssues"),
      id: "HealthCondition"
    },
    VisionImpairment: {
      text: i18n.t("risks.visionImpairment"),
      id: "HealthCondition"
    },
    HearingImpairment: {
      text: i18n.t("risks.hearingImpairment"),
      id: "HealthCondition"
    },
    Fibromyalgia: {
      text: i18n.t("risks.fibromyalgia"),
      id: "HealthCondition"
    },
    ChronicFatigueSyndrome: {
      text: i18n.t("risks.chronicFatigueSyndrome"),
      id: "HealthCondition"
    },
    PosturalTachycardiaSyndrome: {
      text: i18n.t("risks.posturalTachycardiaSyndrome"),
      id: "HealthCondition"
    },
    MskCondition: {
      text: i18n.t("risks.mskCondition"),
      id: "HealthCondition"
    },
    MskInjury: {
      text: i18n.t("risks.mskInjury"),
      id: "HealthCondition"
    }
  };

  const uniqueRiskTags = new Map();
  issues.forEach(issue => {
    const mappedIssue = issueMap[issue] || { text: issue, id: "" };
    uniqueRiskTags.set(mappedIssue.text, mappedIssue);
  });

  const severePain = [];
  const highRisk = [];
  const healthConditions = [];

  for (const [, issue] of uniqueRiskTags) {
    switch (issue.id) {
      case "IntensePain":
        severePain.push(issue);
        break;
      case "HighRisk":
        highRisk.push(issue);
        break;
      case "HealthCondition":
        healthConditions.push(issue);
        break;
    }
  }

  healthConditions.sort((a, b) => a.text.localeCompare(b.text));

  return [...severePain, ...highRisk, ...healthConditions];
}
