<template>
  <div class="ai-assistant-loader-container">
    <v-progress-circular
      indeterminate
      size="64"
      color="var(--v-vflPrimary-base)"
    ></v-progress-circular>
  </div>
</template>

<script>
export default {
  name: "AiAssistantLoader"
};
</script>

<style lang="scss" scoped>
.ai-assistant-loader-container {
  display: grid;
  min-height: 100%;
  place-content: center;
}
</style>
