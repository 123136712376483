<template>
  <div v-show="show" class="buttons-question">
    <v-radio-group
      row
      v-model="selectedAnswer"
      @change="emitToParent(selected)"
      :rules="rules"
      class="mt-0"
    >
      <v-radio
        :label="$t(option.label)"
        :value="option.value"
        @mouseup="handleDeselect(option.value)"
        v-for="option in visibleOptions"
        v-bind:key="option.value"
        ><template v-slot:label>
          <div class="px-3 py-2">
            <span>{{ option.label }}</span>
          </div>
        </template>
      </v-radio>
    </v-radio-group>
  </div>
</template>

<script>
export default {
  name: "ButtonsQuestion",
  props: {
    errorMessage: String,
    name: String,
    question: String,
    optional: Boolean,
    options: Array,
    show: {
      default: true,
      type: Boolean
    },
    value: Number,
    fullQuestion: Object
  },
  data() {
    return { selected: null };
  },
  computed: {
    selectedAnswer: {
      get() {
        return this.value;
      },
      set(selectedAnswer) {
        this.selected = selectedAnswer;
      }
    },
    rules() {
      return this.optional
        ? []
        : [v => !!v || this.$t(this.fullQuestion.errorMessage)];
    },
    visibleOptions() {
      return this.processedOptions.filter(x => x.showAnswer);
    }
  },
  created() {
    this.processOptions();
    this.selected = this.value;
  },
  methods: {
    emitToParent(value) {
      this.$emit("input", value);
    },
    processOptions() {
      this.processedOptions = this.options.map(option => ({
        ...option,
        label: this.$t(option.label)
      }));
    },
    handleDeselect(value) {
      if (this.selectedAnswer === value) {
        // Call 'reset' after the @change event on the input
        window.setTimeout(() => {
          this.selectedAnswer = null;
          this.emitToParent(null);
        });
      }
    }
  }
};
</script>

<style scoped lang="scss">
$radio-text-color: rgba(0, 0, 0, 0.6);
$radio-border-color: var(--v-vflGreyVeryLight-base);
$radio-box-shadow-color: #1a2b3706;
$radio-box-shadow-color-hover: #1a2b3712;
$radio-border-color-active: #0f72ea;
$radio-error-color: var(--v-error-base);

.buttons-question {
  container-type: inline-size;

  ::v-deep .v-input--radio-group__input {
    gap: 0.75rem;
  }

  .v-radio {
    border: 1px solid $radio-border-color;
    border-radius: 8px;
    margin: 0;
    overflow: hidden;

    &:hover {
      background-color: var(--v-vflGreyVeryLight-lighten1);
    }

    &:has(:focus) {
      outline: 2px dashed $radio-border-color-active;
      outline-offset: -1px;
    }

    &:has(:checked) {
      outline: 2px solid $radio-border-color-active;
      outline-offset: -1px;
    }

    &:has(.error--text) {
      outline: 2px solid $radio-error-color;
      outline-offset: -1px;
    }

    ::v-deep .v-input--selection-controls__input {
      clip: rect(0 0 0 0);
      clip-path: inset(50%);
      height: 1px;
      overflow: hidden;
      position: absolute;
      white-space: nowrap;
      width: 1px;
    }

    ::v-deep label {
      color: var(--v-vflPrimary-base);
      cursor: pointer;
      font-weight: 500;

      &.error--text {
        color: $radio-text-color !important;
      }
    }
  }
}

.v-radio.v-item--active {
  ::v-deep {
    label {
      background-color: color-mix(in srgb, var(--v-vflPrimary-base), #fff 95%);
    }
  }
}
</style>
