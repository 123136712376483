import { BaseTextModule } from "./base-text-module";
import { HEADER_CONTENT } from "./content/header-content";

export class HeaderModule extends BaseTextModule {
  constructor() {
    super();
    this.content = HEADER_CONTENT;
  }

  getHeaderTitle(results) {
    return this.content.getTitleText(results);
  }

  getHeaderContent(results) {
    return this.content.getContentText(results);
  }
}
