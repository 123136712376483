export const HISTORY_FILTERS = {
  NO_HOME_ASSESSMENT: "noHomeAssessment",
  NO_OFFICE_ASSESSMENT: "noOfficeAssessment",
  HOME_AND_OFFICE_ASSESSMENT: "homeAndOfficeAssessment",
  NO_COMPLETED_DESK_ASSESSMENT: "noCompletedDeskAssessment",
  NO_SENT_DESK_ASSESSMENT: "noSentDeskAssessment",
  HAS_HEALTH_CONDITION: "hasHealthCondition",
  NEEDS_ATTENTION: "needsAttention",
  NEEDS_ATTENTION_LAST_30_DAYS: "needsAttention30Days"
};
