<template>
  <div v-if="!hideAiFeatures" class="no-print">
    <transition name="fade">
      <AiAssistantToggle v-show="!isAiWindowOpen && !isContextLoading" />
    </transition>
    <transition name="expand-window">
      <AiAssistantWindow v-if="isAiWindowOpen" />
    </transition>
  </div>
</template>

<script>
import AiAssistantToggle from "./AiAssistantToggle.vue";
import AiAssistantWindow from "./AiAssistantWindow.vue";
import { mapGetters } from "vuex";
import { AssistantTypes } from "./constants";

export default {
  name: "AiAssistantContainer",
  components: {
    AiAssistantToggle,
    AiAssistantWindow
  },
  props: {
    type: String
  },
  computed: {
    ...mapGetters("ai", ["isAiWindowOpen", "isContextLoading"]),
    ...mapGetters(["hideAiFeatures", "disableAiFeatures"])
  },
  created() {
    if (this.hideAiFeatures || this.disableAiFeatures) return;

    if (this.type === AssistantTypes.REPORT) {
      this.$store.dispatch("ai/fetchAssessmentConversations");
    }
  },
  beforeDestroy() {
    this.$store.dispatch("ai/closeAiWindow");
  }
};
</script>

<style scoped lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.expand-window-enter-active,
.expand-window-leave-active {
  transition: transform 0.5s cubic-bezier(0.34, 1.2, 0.64, 1), opacity 0.5s ease;
}

.expand-window-enter,
.expand-window-leave-to {
  transform: scale(0.1);
  opacity: 0;
  transform-origin: bottom right;
}

.expand-window-enter-to,
.expand-window-leave {
  transform: scale(1);
  opacity: 1;
  transform-origin: bottom right;
}
</style>
