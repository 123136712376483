<template>
  <div class="ai-message-feedback">
    <feedback-thumb-buttons @feedbackSubmitted="onFeedbackSubmitted" />
    <copy-to-clipboard-button class="ml-6" :textToCopy="message" />
  </div>
</template>

<script>
import FeedbackThumbButtons from "@/components/common/Feedback/FeedbackThumbButtons.vue";
import CopyToClipboardButton from "@/components/common/CopyToClipboardButton.vue";
import { sendAssistantFeedback } from "@/customApi";

export default {
  name: "AiAssistantMessageFooter",
  components: {
    FeedbackThumbButtons,
    CopyToClipboardButton
  },
  props: {
    interactionId: String,
    message: String
  },
  methods: {
    async onFeedbackSubmitted(value) {
      if (this.interactionId) {
        await sendAssistantFeedback(this.interactionId, value);
      }
    }
  }
};
</script>
