<template>
  <div
    class="vfl-callout rounded-lg d-flex"
    :class="calloutClass"
    :style="calloutStyle"
  >
    <component
      :is="iconComponent"
      v-if="icon"
      :style="{ minWidth: iconWidth ? `${iconWidth}px` : 'auto' }"
    />
    <slot />
  </div>
</template>

<script>
export default {
  name: "VflCallout",
  props: {
    icon: {
      type: String,
      default: ""
    },
    size: {
      type: String,
      default: "medium",
      validator: value => ["small", "medium", "large"].includes(value)
    },
    textClass: {
      String,
      default: "text-body-2"
    },
    backgroundColor: {
      type: String,
      default: ""
    },
    iconWidth: {
      type: Number
    }
  },
  data() {
    return {
      iconComponent: null
    };
  },
  mounted() {
    if (this.icon) {
      this.loadIconComponent(this.icon).then(component => {
        this.iconComponent = component;
      });
    }
  },
  computed: {
    calloutClass() {
      return {
        "px-7 py-6": this.size === "large",
        "px-3 py-4": this.size === "medium",
        "px-3 py-2": this.size === "small",
        [this.textClass]: true
      };
    },
    calloutStyle() {
      return this.backgroundColor
        ? { backgroundColor: `var(--v-${this.backgroundColor}-base)` }
        : {};
    }
  },
  methods: {
    async loadIconComponent(iconName) {
      try {
        const component = await import(
          `@/components/common/icons/${iconName}.vue`
        );
        return component.default;
      } catch (error) {
        this.$logger.captureException(
          error,
          `Failed to load component: ${iconName}`
        );
        return null;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.vfl-callout {
  background-color: rgba(237, 240, 242); // fallback
  background-color: color-mix(in srgb, var(--v-vflPrimary-base), #fff 95%);
  gap: 0.75rem;

  > .vfl-icon {
    padding-left: 0.25rem;
    position: relative;
    top: 0.3rem; // Optical vertical alignment with text
  }

  p {
    margin-bottom: 0;
  }
}
</style>
