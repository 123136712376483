<template>
  <div :style="'height:40px;width:40px;'">
    <svg
      version="1.1"
      id="tick"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 37 37"
      style="enable-background:new 0 0 37 37;"
      xml:space="preserve"
    >
      <polyline
        class="path"
        :class="trigger ? 'tick' : ''"
        style="fill:none;stroke:#3FB288;stroke-width:3;stroke-linejoin:round;stroke-miterlimit:10;"
        points="
	11.6,20 15.9,24.2 26.4,13.8 "
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: "AnimatedTick",
  props: {
    trigger: Boolean
  },
  computed: {
    size() {
      return this.trigger ? 0 : 50;
    }
  }
};
</script>

<style scoped>
.tick {
  stroke-dasharray: 50;
  stroke-dashoffset: 50;
  animation: dash 2s linear forwards;
}
@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}
</style>
