export const prefill = () => {
  return {
    sectors: {
      type: import.meta.env.DEV ? 1 : null
    },
    jobNature: {
      jobActivities: [1, 3]
    },
    reba: {
      chosenJobActivity: 1,
      neckPosition: [1, 2],
      neckTwistedOrSideBent: [1],
      torsoPosition: [3],
      torsoBentBackwardPosition: 1,
      torsoTwistedOrSideBent: [99],
      upperArmPosition: [2, 3],
      raisedUpperArmPosition: 1,
      additionalUpperArmFactors: [1],
      lowerArmPosition: [2],
      wristPosition: [1, 2],
      wristTwistedOrSideBent: [2],
      legPosition: [1],
      legsBent: [1],
      coupling: 2,
      loadWeight: 2,
      suddenMovementsOrHighForce: 2,
      changesBodyPosition: 1,
      changePositionFrequency: 1,
      changePositionStability: 1
    },
    pain: {
      areas: {}
    },
    health: {}
  };
};
