import DeskAssessmentRiskFactors from "@/assets/json/DeskAssessment/DeskAssessmentRiskFactors.json";
import * as Constants from "@/constants/riskFactorConstants.js";
import criteriaChecker from "@/components/common/criteria-checker.js";

function flattenResults(results) {
  return { ...results.webcam, ...results.setup };
}

function GetScore(results, riskFactors) {
  var score = 0;
  var flatResults = flattenResults(results);
  riskFactors.forEach(factor => {
    let match = criteriaChecker.areCriteriaMet(
      flatResults,
      DeskAssessmentRiskFactors[factor]
    );
    if (match) {
      score++;
    }
  });

  return score;
}

function GetRatingFromScore(score, brackets) {
  if (score <= brackets.low) {
    return "low";
  } else if (score <= brackets.medium) {
    return "medium";
  } else {
    return "high";
  }
}

export default {
  GetPainLikelihoodScore(results, area) {
    let riskFactors = Constants.painLikelihoodRiskFactors[area];
    let allFactors = [...riskFactors.setup, ...riskFactors.habits];
    return GetScore(results, allFactors);
  },
  GetSetupRating(results, area) {
    let riskFactors = Constants.setupRiskFactors;
    if (area) {
      riskFactors = Constants.painLikelihoodRiskFactors[area].setup;
    }
    let score = GetScore(results, riskFactors);
    if (score === 0) {
      return "zero";
    }
    return GetRatingFromScore(score, Constants.setupScoreRatings);
  },
  GetHabitsRating(results, area) {
    let riskFactors = Constants.habitsRiskFactors;
    if (area) {
      riskFactors = Constants.painLikelihoodRiskFactors[area].habits;
    }
    let score = GetScore(results, riskFactors);
    if (score === 0) {
      return "zero";
    }
    return GetRatingFromScore(score, Constants.habitsScoreRatings);
  },
  GetPainLikelihoodRating(results, area) {
    let score = this.GetPainLikelihoodScore(results, area);
    let boundaries = Constants.painLikelihoodRatings[area];
    return this.GetLikelihoodRatingFromScore(score, boundaries);
  },
  GetLikelihoodRatingFromScore(score, brackets) {
    if (score <= brackets.low) {
      return "low";
    } else if (score <= brackets.medium) {
      return "medium";
    } else if (score <= brackets.high) {
      return "high";
    } else {
      return "max";
    }
  }
};
