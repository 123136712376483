<template>
  <div class="emoji-container" ref="container" :style="containerStyle">
    <img
      v-for="(emoji, index) in emojis"
      :key="index"
      :src="`/images/emojis/${emoji.type}.png`"
      :style="getEmojiStyle(emoji)"
      :alt="`emoji of ${emoji.type}`"
      class="emoji"
    />
  </div>
</template>

<script>
const Animation = {
  TIMING_FUNCTION: "linear",
  FILL_MODE: "cubic-bezier(0.25, 0.1, 0.25, 1)"
};

export default {
  name: "ReportFooterAnimatedEmojis",
  data() {
    return {
      emojis: [],
      emojiTypes: [
        "pink-heart-with-stars",
        "blue-heart",
        "applause",
        "thumbs-up",
        "party",
        "hands-up"
      ],
      totalEmojis: 25,
      animationDuration: 5,
      emojiSize: 40,
      containerWidth: 0,
      containerHeight: 0,
      isInView: false
    };
  },
  computed: {
    containerStyle() {
      return {
        "--container-height": `${this.containerHeight}px`
      };
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.getContainerSize();
      this.generateEmojis();
      this.setupIntersectionObserver();
      window.addEventListener("resize", this.getContainerSize);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.getContainerSize);
  },
  methods: {
    getEmojiStyle(emoji) {
      return {
        left: `${emoji.x}%`,
        animationDelay: `${emoji.delay}s`,
        animationDuration: `${this.animationDuration}s`,
        animationTimingFunction: Animation.TIMING_FUNCTION,
        animationFillMode: Animation.FILL_MODE
      };
    },
    getContainerSize() {
      this.containerWidth =
        this.$refs?.container.offsetWidth || window.innerWidth;
      this.containerHeight =
        this.$refs?.container.offsetHeight || window.innerHeight;
    },
    generateEmojis() {
      this.emojis = [];

      for (let i = 0; i < this.totalEmojis; i++) {
        this.emojis.push({
          type: this.getRandomEmoji(),
          x: this.getRandomLeftOffset(),
          delay: this.getRandomDelay()
        });
      }
    },
    getRandomEmoji() {
      return this.emojiTypes[
        Math.floor(Math.random() * this.emojiTypes.length)
      ];
    },
    getRandomLeftOffset() {
      const availableWidth = this.containerWidth - this.emojiSize;

      return ((Math.random() * availableWidth) / this.containerWidth) * 100;
    },
    getRandomDelay() {
      return Math.random() * this.animationDuration;
    },
    setupIntersectionObserver() {
      const observer = new IntersectionObserver(
        entries => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              this.$refs.container.classList.add("animate");
              observer.unobserve(entry.target);
            }
          });
        },
        { threshold: 0.1 }
      );
      observer.observe(this.$refs.container);
    }
  }
};
</script>

<style scoped>
.emoji-container {
  bottom: 0;
  height: 100%;
  left: 0;
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  will-change: transform, opacity;
}

.emoji {
  bottom: -40px;
  height: 40px;
  opacity: 0;
  position: absolute;
  width: 40px;
}

.animate .emoji {
  animation-name: floatUp;
}

@keyframes floatUp {
  0% {
    transform: translate3d(0, 0, 0);
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  60% {
    opacity: 1;
    transform: translate3d(0, calc(var(--container-height) * -0.6), 0);
  }
  90% {
    opacity: 0;
    transform: translate3d(0, calc(var(--container-height) * -0.9), 0);
  }
  100% {
    transform: translate3d(0, calc(var(--container-height) * -1), 0);
    opacity: 0;
  }
}
</style>
