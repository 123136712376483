<template>
  <vfl-callout v-if="text" size="large">
    <p class="text-subtitle">
      {{ $t(text) }}
    </p>
  </vfl-callout>
</template>

<script>
import VflCallout from "@/components/vitrue-foundation-library/VflCallout.vue";
import { hasAnyHealthCondition } from "@/components/assessment/pre-existing-conditions/get-conditions-service.js";

export default {
  name: "HealthConditionRecommendationsBanner",
  components: { VflCallout },
  props: {
    results: Object
  },
  computed: {
    text() {
      return hasAnyHealthCondition(this.results)
        ? "assessment.form.healthConditions.banners.report.recommendations"
        : null;
    }
  }
};
</script>

<style lang="scss" scoped>
.vfl-callout {
  background: linear-gradient(
    to bottom right,
    #ffffff,
    rgba(217, 237, 255, 0.5),
    rgba(236, 226, 255, 0.5)
  );
  display: block !important;
  position: relative;
}
</style>
