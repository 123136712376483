<template>
  <section-header :title="$t('painImpactOverview.title')">
    <template #actionButton v-if="!loading">
      <dashboard-card-download-button
        class="ml-2"
        :screenReader="$t('painImpactOverview.download.screenReader')"
        downloadElement="pain-impact-container"
        :imageName="$t('painImpactOverview.download.imageName')"
        eventName="MSK pain impact downloaded"
      />
    </template>
  </section-header>
</template>

<script>
import SectionHeader from "@/components/dashboard/cards/common/DashboardSectionHeader.vue";
import DashboardCardDownloadButton from "@/components/dashboard/cards/common/DashboardCardDownloadButton.vue";

export default {
  name: "PainImpactHeader",
  components: {
    SectionHeader,
    DashboardCardDownloadButton
  },
  props: {
    loading: Boolean
  }
};
</script>
