import { HistoryItem } from "./historyItem";
import { HISTORY_TYPES } from "@/components/user-profile/history/constants.js";

export class NeedsAttentionHistoryItem extends HistoryItem {
  constructor(timestamp, action, adminName, adminEmail) {
    super(timestamp, HISTORY_TYPES.NEEDS_ATTENTION);
    this.action = action;
    this.adminName = adminName;
    this.adminEmail = adminEmail;
  }
}
