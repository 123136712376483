export const Path = {
  ICON_BASE: "/images/icons/assessments/"
};

export const Keys = {
  RATING: "assessment.scoreDescriptors",
  PRODUCT_NAMES: "productNames"
};

export const Locations = {
  HOME: "Home",
  OFFICE: "Office"
};
