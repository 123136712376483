import {
  getPainConditions,
  getNonPainConditions
} from "../get-conditions-service.js";
import {
  PAIN_RECOMMENDATION_MAPPING,
  NON_PAIN_RECOMMENDATION_MAPPING
} from "./recommendation-map.js";

export function getRecommendationConditions(results, recommendationId) {
  const painConditions = getPainConditions(results);
  const nonPainConditions = getNonPainConditions(results);
  return [
    ...getConditionsForRecommendation(
      painConditions,
      recommendationId,
      PAIN_RECOMMENDATION_MAPPING
    ),
    ...getConditionsForRecommendation(
      nonPainConditions,
      recommendationId,
      NON_PAIN_RECOMMENDATION_MAPPING
    )
  ];
}

function getConditionsForRecommendation(conditions, recommendationId, map) {
  const recommendationConditions = getConditions(recommendationId, map);
  if (!recommendationConditions) return [];

  return conditions.filter(c =>
    recommendationConditions.some(
      rc => rc.painGroup == c.painGroup && rc.value == c.value
    )
  );
}

function getConditions(recommendationId, map) {
  const keys = Object.keys(map);
  const matchingKey = keys.find(k => recommendationId.includes(k));
  if (!matchingKey) return null;

  return map[matchingKey];
}
