export function joinItemsWithConjunction(items, conjunction = "and") {
  if (items.length <= 1) {
    return items.join("");
  } else if (items.length === 2) {
    return items.join(` ${conjunction} `);
  } else {
    const lastItem = items.pop();
    return `${items.join(", ")} ${conjunction} ${lastItem}`;
  }
}
