const defaultPredefinedMetrics = {
  absenteeismPercentage: 10,
  referralPercentage: 19,
  productivityLossPercentage: 32,
  percentageThinkPostureAffectsPain: 10,
  percentageWithWater: 15,
  percentageExercisingMoreThanThreeTimesAWeek: 29,
  percentageLunchAwayFromDesk: 21,
  percentageActiveBreaks: 79,
  percentageRegularBreaks: 38,
  averageHoursSpentAtDesk: 9,
  totalCount: 1200
};

function getLocalStorageMetrics() {
  try {
    const localStorageMetrics = localStorage.getItem("demoDashboardMetrics");
    const parsedMetrics =
      localStorageMetrics == null ? null : JSON.parse(localStorageMetrics);
    parsedMetrics.totalCount = defaultPredefinedMetrics.totalCount;
    return parsedMetrics;
  } catch (e) {
    return null;
  }
}

export const adminDashboardStore = {
  namespaced: true,
  state: {
    usePredefinedDashboardMetrics:
      getLocalStorageMetrics() == null
        ? false
        : getLocalStorageMetrics()?.usePredefinedDashboardMetrics,
    preDefinedMetrics:
      getLocalStorageMetrics() == null
        ? defaultPredefinedMetrics
        : getLocalStorageMetrics()
  },
  mutations: {
    setStateOfPredefinedDashboardMetrics(state, active) {
      state.usePredefinedDashboardMetrics = active;
    },
    setPredefinedDashboardMetric(state, metricState) {
      state[metricState.metric] = metricState.value;
    }
  },
  actions: {
    refreshWhoNeedsAttention({ commit }) {
      commit("triggerRefresh");
    }
  },
  getters: {
    usePredefinedDashboardMetrics: state => state.usePredefinedDashboardMetrics,
    dashboardDemoMetricsState: state => state.preDefinedMetrics
  }
};
