import { getStandardRatingColors } from "@/components/dashboard/cards/average-scores/colours.js";
import { getDescriptorForDriver } from "@/components/dashboard/cards/average-scores/rating.js";

export const DriverCard = {
  icon: "car.svg",
  title: "driver",
  score: score => score,
  ratingObj: score => {
    const colors = getStandardRatingColors(score);
    return {
      backgroundColor: colors.background,
      text: getDescriptorForDriver(score),
      textColor: colors.text
    };
  }
};
