import { v4 as uuidv4 } from "uuid";
import { DUMMY_CONVERSATION, INITIAL_MESSAGE } from "./constants.js";

const messagingStore = {
  namespaced: true,
  state: {
    messages: [],
    isLoading: false,
    sendingMessageId: null
  },
  mutations: {
    ADD_MESSAGE(state, message) {
      state.messages.push(message);
    },
    SET_INITIAL_MESSAGES(state, messages) {
      state.messages = messages;
    },
    SET_LOADING(state, isLoading) {
      state.isLoading = isLoading;
    },
    SET_SENDING_MESSAGE_ID(state, id) {
      state.sendingMessageId = id;
    },
    CLEAR_MESSAGES(state) {
      state.messages = [];
    }
  },
  actions: {
    sendMessage({ commit }, { subject, content }) {
      const messageId = uuidv4();
      commit("SET_SENDING_MESSAGE_ID", messageId);
      const message = {
        id: messageId,
        subject,
        content,
        timestamp: new Date(),
        isAdmin: true
      };
      commit("ADD_MESSAGE", message);

      setTimeout(() => {
        commit("SET_SENDING_MESSAGE_ID", null);
      }, 1000);
    },
    initialiseMessages({ commit, rootGetters }, { name, email }) {
      const initialMessage = {
        id: uuidv4(),
        subject: INITIAL_MESSAGE.subject,
        content: INITIAL_MESSAGE.content.replace(
          /{employeeName}/g,
          name || "there"
        ),
        timestamp: INITIAL_MESSAGE.timestamp,
        isAdmin: INITIAL_MESSAGE.isAdmin
      };

      const dummyMessages = DUMMY_CONVERSATION.map(message => ({
        ...message,
        content: message.content.replace(/{employeeName}/g, name || "there")
      }));

      commit("SET_INITIAL_MESSAGES", [initialMessage, ...dummyMessages]);
    },
    clearMessages({ commit }) {
      commit("CLEAR_MESSAGES");
    },
    setLoading({ commit }, isLoading) {
      commit("SET_LOADING", isLoading);
    }
  },
  getters: {
    isLoading: state => state.isLoading,
    isSendingMessage: state => id => state.sendingMessageId === id
  }
};

export default messagingStore;
