<template>
  <vfl-icon-button
    @click="download"
    class="html2canvas-ignore"
    :loading="downloading"
    :disabled="downloading"
    isLight
    size="small"
    :screenReader="screenReader"
  >
    <v-icon small color="vflPrimary">mdi-download</v-icon>
  </vfl-icon-button>
</template>

<script>
import html2canvas from "html2canvas";
import VflIconButton from "@/components/vitrue-foundation-library/VflIconButton.vue";
import { sendEvent } from "@/services/analytics/analytics.js";

export default {
  name: "DashboardCardDownloadButton",
  components: {
    VflIconButton
  },
  data() {
    return {
      downloading: false,
      padding: 40
    };
  },
  props: {
    screenReader: {
      type: String,
      required: true
    },
    downloadElement: {
      type: String,
      required: true
    },
    imageName: {
      type: String,
      required: true
    },
    eventName: {
      type: String
    },
    onClone: {
      type: Function,
      default: () => {}
    }
  },
  methods: {
    download() {
      if (this.downloading) return;
      this.downloading = true;

      if (this.eventName) {
        sendEvent(this.eventName);
      }

      this.$nextTick(() => {
        setTimeout(async () => {
          try {
            const element = document.getElementById(this.downloadElement);

            const originalCanvas = await html2canvas(element, {
              scale: 2,
              ignoreElements: element => {
                return element.classList.contains("html2canvas-ignore");
              },
              onclone: document => {
                if (this.onClone) {
                  this.onClone(document);
                }
              }
            });

            const canvas = document.createElement("canvas");
            canvas.width = originalCanvas.width + this.padding * 2;
            canvas.height = originalCanvas.height + this.padding * 2;
            const ctx = canvas.getContext("2d");

            ctx.fillStyle = "white";
            ctx.fillRect(0, 0, canvas.width, canvas.height);

            ctx.drawImage(originalCanvas, this.padding, this.padding);

            const imageData = canvas.toDataURL("image/png");
            this.triggerDownload(imageData, `${this.imageName}.png`);
          } catch (error) {
            this.$logger.captureException(error);
          } finally {
            this.downloading = false;
          }
        }, 0);
      });
    },
    triggerDownload(imageData, filename) {
      const link = document.createElement("a");
      link.href = imageData;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};
</script>
