import { ASSESSMENT_HISTORY_STATES } from "@/components/user-profile/history/constants.js";
import { AssessmentTitlesMap } from "@/components/user-profile/constants.js";
import {AssessmentStateCodes, Locations} from "@/components/common/assessment-history/assessmentHistoryConstants.js";
import store from "@/store.js";
import i18n from "@/plugins/i18n.js";

function getFormattedLocation(location) {
  if (location === "None") return "";

  const locationObject = Locations[location.toLowerCase()];
  if (!locationObject) return "";

  const text = i18n.t(locationObject.text);
  return `[${text}]`;
}

export const getAssessmentEventText = item => {
  const typePath = AssessmentTitlesMap[item.assessmentType];
  const assessmentTypeText = i18n.t(typePath);
  switch (item.historyState) {
    case ASSESSMENT_HISTORY_STATES.COMPLETED:
      const canViewScore = !store.getters.anonymousResults;

      if (item.score !== null && item.location && canViewScore) {
        const location = getFormattedLocation(item.location);

        return i18n.t("userProfile.history.assessment.scored", {
          score: item.score,
          assessmentType: assessmentTypeText,
          location: location
        });
      } else {
        return i18n.t("userProfile.history.assessment.completed", {
          assessmentType: assessmentTypeText
        });
      }
    case ASSESSMENT_HISTORY_STATES.IN_PROGRESS:
      return i18n.t("userProfile.history.assessment.started", {
        assessmentType: assessmentTypeText
      });
    case ASSESSMENT_HISTORY_STATES.OPENED:
      return i18n.t("userProfile.history.assessment.opened", {
        assessmentType: assessmentTypeText
      });
    case ASSESSMENT_HISTORY_STATES.CREATED:
      return i18n.t("userProfile.history.assessment.created", {
        assessmentType: assessmentTypeText
      });
    case ASSESSMENT_HISTORY_STATES.SENDGRID_PROCESSED:
      return i18n.t("userProfile.history.assessment.sendGridProcessed", {
        assessmentType: assessmentTypeText
      });
    case ASSESSMENT_HISTORY_STATES.SENDGRID_BLOCKED:
      return i18n.t("userProfile.history.assessment.sendGridBlocked", {
        assessmentType: assessmentTypeText
      });
    case ASSESSMENT_HISTORY_STATES.SENDGRID_DEFERRED:
      return i18n.t("userProfile.history.assessment.sendGridDeferred", {
        assessmentType: assessmentTypeText
      });
    case ASSESSMENT_HISTORY_STATES.SENDGRID_DROPPED:
      return i18n.t("userProfile.history.assessment.sendGridDropped", {
        assessmentType: assessmentTypeText
      });
    case ASSESSMENT_HISTORY_STATES.SENDGRID_BOUNCED:
      return i18n.t("userProfile.history.assessment.sendGridBounced", {
        assessmentType: assessmentTypeText
      });
    case ASSESSMENT_HISTORY_STATES.SENDGRID_DELIVERED:
      return i18n.t("userProfile.history.assessment.sendGridDelivered", {
        assessmentType: assessmentTypeText
      });
    default:
      return i18n.t("userProfile.history.assessment.updated", {
        assessmentType: assessmentTypeText
      });
  }
};
