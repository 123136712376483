<template>
  <div>
    <div v-if="loading">
      <v-skeleton-loader type="text" width="200" height="20" class="mb-2" />
      <div v-for="i in 4" :key="i" class="assessment-item text-body-2 mb-1">
        <v-skeleton-loader type="text" width="150" height="26" class="mr-2" />
        <v-skeleton-loader type="text" width="40" height="26" />
      </div>
    </div>

    <div v-else class="people-assessed">
      <i18n
        class="text-body-2 mb-2"
        path="mskOverview.peopleAssessed.title"
        v-if="totalPeopleAssessed"
      >
        <template v-slot:number>
          <span class="font-weight-bold">{{ totalPeopleAssessed }}</span>
        </template>
      </i18n>
      <button
        v-for="(level, key) in painData"
        :key="key"
        class="assessment-item text-body-2 mb-1"
        :class="{ 'has-hover': level.percentage && canOpenPeopleList }"
        @click="itemSelected(key, level)"
      >
        <div class="label-container">
          <div
            class="dot mr-2"
            :style="{
              backgroundColor: $vuetify.theme.themes.light[level.color]
            }"
          />
          <span class="vflNeutralDark2--text text-body-2 label">
            {{ $t(`mskOverview.peopleAssessed.painText.${level.key}`) }}
          </span>
        </div>
        <span class="text-body-bold percentage-text">
          {{ Math.round(level.percentage) }}%
        </span>
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { sendEvent } from "@/services/analytics/analytics.js";
import { sortPeople } from "@/components/dashboard/cards/pain-overview/helpers.js";

export default {
  name: "PeopleAssessed",
  props: {
    loading: Boolean,
    painData: Object,
    totalPeopleAssessed: Number
  },
  computed: {
    ...mapGetters({
      adminId: "userId",
      isRestrictedAdmin: "isRestrictedAdminUser"
    }),
    canOpenPeopleList() {
      return !this.isRestrictedAdmin;
    }
  },
  methods: {
    ...mapActions("sidePanelStore", ["open"]),
    itemSelected(key, level) {
      if (!level.percentage || !this.canOpenPeopleList) return;

      if (level.users) {
        const sortedPeople = sortPeople(level.users);

        this.open({
          people: sortedPeople,
          heading: this.getSidePanelHeadingFromKey(key)
        });

        sendEvent("MSK User List opened", {
          from: "MSK Overview card",
          admin: this.adminId,
          key: key
        });
      }
    },
    getSidePanelHeadingFromKey(key) {
      return this.$t(`mskOverview.peopleAssessed.sidePanelTitle.${key}`);
    }
  }
};
</script>

<style lang="scss" scoped>
.people-assessed {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-width: 200px;
}

.assessment-item {
  display: flex;
  align-items: center;
  cursor: default;
  width: 100%;
  transition: background-color 0.2s ease;

  &.has-hover:hover {
    cursor: pointer;

    .label::after {
      transform: scaleX(1);
      opacity: 1;
    }
  }
}

.label-container {
  display: flex;
  align-items: center;
  padding-left: 4px;
}

.label {
  position: relative;
  color: var(--v-vflNeutralDark2-base);

  &::after {
    content: "";
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: currentColor;
    transform: scaleX(0);
    opacity: 0;
    transition: transform 0.3s ease, opacity 0.3s ease;
    transform-origin: left;
  }
}

.dot {
  width: 0.375rem;
  height: 0.375rem;
  border-radius: 50%;
  margin-top: 1px;
}

.percentage-text {
  margin-left: auto;
  padding: 1px 8px;
}
</style>
