<template>
  <div class="user-profile-msk-risks-container">
    <vfl-chip
      class="chip"
      v-for="risk in processedRisks"
      :key="risk"
      :label="risk"
    />
  </div>
</template>

<script>
import VflChip from "@/components/vitrue-foundation-library/VflChip.vue";
import { mapAndOrderRisks } from "@/components/dashboard/tables/mapUserRisks.js";
import { mapGetters } from "vuex";

export default {
  name: "UserProfileMskRisks",
  components: { VflChip },
  computed: {
    ...mapGetters("userProfile", ["mskRisks"]),
    processedRisks() {
      if (!this.mskRisks?.length) return [];

      const mappedMskRisks = mapAndOrderRisks(this.mskRisks);
      const hasHighRisk = mappedMskRisks.filter(
        risk => risk.id === "HighRisk"
      ).length;

      return hasHighRisk ? [this.$t("risks.highRisk")] : [];
    }
  }
};
</script>

<style lang="scss" scoped>
.user-profile-msk-risks-container {
  display: contents;
}

.chip {
  background-color: #f0e1f9;
  border-color: #ebd6f8;
}
</style>
