<template>
  <div>
    <template v-if="feedbackGiven">
      <transition name="feedback-animation">
        <p key="feedbackGiven">
          {{ $t("feedback.reportCard.thanks") }}
          <v-icon size="small" style="color: var(--v-vflSuccess-base)"
            >mdi-check-circle-outline</v-icon
          >
        </p>
      </transition>
    </template>
    <template v-else>
      <transition name="feedback-animation">
        <p key="feedbackNotGiven">
          {{ $t("feedback.reportCard.label") }}
          <span class="ml-1"
            ><button @click="onFeedback(true)">
              <span class="d-sr-only">{{
                $t("feedback.reportCard.buttons.yes")
              }}</span>
              <v-icon size="small">mdi-thumb-up-outline</v-icon></button
            ><button @click="onFeedback(false)">
              <span class="d-sr-only">{{
                $t("feedback.reportCard.buttons.no")
              }}</span
              ><v-icon size="small">mdi-thumb-down-outline</v-icon>
            </button></span
          >
        </p></transition
      ></template
    >
  </div>
</template>

<script>
export default {
  name: "InformationCardFeedback",
  props: {
    title: String,
    text: Array
  },
  data() {
    return {
      feedbackGiven: false
    };
  },
  methods: {
    onFeedback(isPositive) {
      this.feedbackGiven = true;

      this.$mixpanel.track("Report card feedback", {
        feedback: isPositive ? "Positive" : "Negative",
        title: this.title,
        text: this.joinAndClampText(this.text)
      });
    },
    joinAndClampText(textArray) {
      if (!Array.isArray(textArray)) return;

      const maxLength = 100;
      const text = textArray.join(" ");

      return text.length <= maxLength
        ? text
        : text.substring(0, maxLength - 3) + "...";
    }
  }
};
</script>

<style lang="scss" scoped>
p {
  align-items: center;
  display: inline-flex;
  gap: 0.325rem;
  line-height: 1;
  margin: 0;
}

span {
  align-items: center;
  display: inline-flex;
  gap: 0.5rem;
  line-height: 1;
}

button {
  position: relative;
  transition: transform 0.125s ease-out;

  &:after {
    bottom: -0.25rem;
    content: "";
    left: -0.25rem;
    position: absolute;
    right: -0.25rem;
    top: -0.25rem;
  }

  &:hover {
    transform: scale(1.1);

    i {
      color: var(--v-vflPrimary-base);
    }
  }
}

.feedback-animation-enter-active {
  transition: opacity 0.15s 0.1s;
}

.feedback-animation-leave-active {
  transition: opacity 0s;
}

.feedback-animation-enter,
.feedback-animation-leave-to {
  opacity: 0;
}
</style>
