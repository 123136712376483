<template>
  <v-alert
    :color="color"
    :elevation="elevation"
    :type="type"
    :text="isLight"
    :dismissible="isDismissible"
  >
    <span v-if="message" v-html="message" />
    <slot v-else />
  </v-alert>
</template>

<script>
export default {
  name: "VflAlert",
  props: {
    color: String,
    elevation: [String, Number],
    isLight: Boolean,
    isDismissible: Boolean,
    message: String,
    type: {
      type: String,
      validator: function (value) {
        return ["success", "warning", "info", "error"].includes(value);
      }
    }
  }
};
</script>
