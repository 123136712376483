const painLevels = [
  {
    key: "severePain",
    apiKey: "highPain",
    color: "vflPurple"
  },
  {
    key: "moderatePain",
    apiKey: "mediumPain",
    color: "vflOrange"
  },
  {
    key: "mildPain",
    apiKey: "lowPain",
    color: "vflYellow"
  },
  {
    key: "noPain",
    apiKey: "noPain",
    color: "vflBrightGreen"
  }
];

export const mapPainData = apiData => {
  return painLevels.reduce((acc, level) => {
    acc[level.key] = {
      ...level,
      percentage: apiData[level.apiKey].percentage,
      users: apiData[level.apiKey].users,
      count: apiData[level.apiKey].users.length
    };
    return acc;
  }, {});
};
