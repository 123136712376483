<template>
  <div v-if="title || subtitle" class="py-6">
    <h3 v-if="title" class="text-body-1 text-body-bold mb-4">
      {{ title }}
    </h3>
    <h4 v-if="subtitle" class="text-body-1">
      {{ subtitle }}
    </h4>
  </div>
</template>
<script>
export default {
  name: "QuestionGroupPreface",
  props: {
    title: String,
    subtitle: String
  }
};
</script>
<style scoped lang="scss">
div {
  border-top: 1px solid hsla(0, 0%, 0%, 0.2);
}
</style>
