<template>
  <div class="container pa-8 rounded-xl">
    <button class="close-button ma-5" @click="$emit('close')">
      <v-icon color="black">close</v-icon>
    </button>
    <h2 class="mb-2 text-h6">
      {{ title }}
    </h2>
    <p class="mb-8">
      {{ $t("assessment.form.healthConditions.admin.supportDialog.preText") }}
    </p>
    <div
      class="conditions-container pr-3"
      :class="{ 'fixed-height': !isMobile, 'full-height': isMobile }"
    >
      <div
        v-for="section in visibleSections"
        :key="section.value"
        class="mb-10"
      >
        <div class="d-flex mb-3 align-center">
          <v-icon v-if="section.icon" color="black" size="20">{{
            section.icon
          }}</v-icon>
          <img
            v-else
            :src="`images/icons/health-conditions/${section.image}`"
            width="20"
            height="20"
          />
          <h1 class="ml-3 text-body-1 text-body-bold">
            {{ $t(section.textPath + "title") }}
          </h1>
        </div>
        <span class="text-body-1">{{ $t(section.textPath + "text") }}</span>
        <ul
          class="mt-2 text-body-1"
          v-if="$t(section.textPath + 'bulletPoints')"
        >
          <li
            v-for="(point, index) in $t(section.textPath + 'bulletPoints')"
            class="mt-1"
            :key="index"
          >
            {{ point }}
          </li>
        </ul>

        <div
          v-if="
            section.subsection && conditions.includes(section.subsection.value)
          "
          class="d-flex mt-3"
        >
          <v-icon v-if="section.subsection.icon" color="black" size="25">{{
            section.subsection.icon
          }}</v-icon>
          <img
            v-else
            width="20"
            height="20"
            :src="`images/icons/health-conditions/${section.subsection.image}`"
          />
          <span class="ml-3 text-body-1">{{
            $t(section.textPath + section.subsection.text)
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "user-conditions",
  props: {
    conditions: Array,
    name: String,
    email: String
  },
  data() {
    return {
      sections: [
        {
          value: "IntensePain",
          image: "severePain.svg",
          textPath:
            "assessment.form.healthConditions.admin.supportDialog.severePain."
        },
        {
          value: "HighRisk",
          image: "highRisk.svg",
          textPath:
            "assessment.form.healthConditions.admin.supportDialog.highRisk."
        },
        {
          value: "mobilityImpairment",
          image: "mobility.svg",
          textPath:
            "assessment.form.healthConditions.admin.supportDialog.mobilityImpairment.",
          subsection: {
            value: "mobilityAssistance",
            icon: "mdi-wheelchair",
            text: "mobilityAssistance"
          }
        },
        {
          value: "mskCondition",
          image: "msk.svg",
          textPath:
            "assessment.form.healthConditions.admin.supportDialog.mskCondition."
        },
        {
          value: "injury",
          image: "msk.svg",
          textPath:
            "assessment.form.healthConditions.admin.supportDialog.mskInjury."
        },
        {
          value: "hearingLoss",
          image: "hearing.svg",
          textPath:
            "assessment.form.healthConditions.admin.supportDialog.hearingLoss.",
          subsection: {
            value: "hearingAid",
            image: "hearingAid.svg",
            text: "hearingAid"
          }
        },
        {
          value: "visionImpairment",
          image: "vision.svg",
          textPath:
            "assessment.form.healthConditions.admin.supportDialog.visionImpairment."
        },
        {
          value: "chronicFatigueSyndrome",
          image: "chronicFatigueSyndrome.svg",
          textPath:
            "assessment.form.healthConditions.admin.supportDialog.chronicFatigueSyndrome."
        },
        {
          value: "fibromyalgia",
          image: "fibromyalgia.svg",
          textPath:
            "assessment.form.healthConditions.admin.supportDialog.fibromyalgia."
        },
        {
          value: "posturalTachycardiaSyndrome",
          image: "posturalTachycardiaSyndrome.svg",
          textPath:
            "assessment.form.healthConditions.admin.supportDialog.posturalTachycardiaSyndrome."
        },
        {
          value: "neurodiversity",
          image: "dyslexia.svg",
          textPath:
            "assessment.form.healthConditions.admin.supportDialog.neurodiversity."
        }
      ]
    };
  },
  computed: {
    visibleSections() {
      return this.sections.filter(s => this.conditions.includes(s.value));
    },
    isMobile() {
      return this.$vuetify.breakpoint.xs;
    },
    title() {
      let addedText = this.$t(
        "assessment.form.healthConditions.admin.supportDialog.title.generic"
      );

      if (this.name) {
        addedText = this.name;
      } else if (this.email) {
        addedText = this.email;
      }
      return this.$t(
        "assessment.form.healthConditions.admin.supportDialog.title.base",
        {
          0: addedText
        }
      );
    }
  }
};
</script>

<style scoped>
.container {
  background: linear-gradient(to bottom right, #ffffff, #d9edff, #ece2ff);
  position: relative;
  overflow-y: auto;
  height: 100%;
}
.conditions-container {
  overflow-y: auto;
}
.full-height {
  max-height: 80%;
}
.fixed-height {
  max-height: 60vh;
}
/* scroll bar styles
/* Works on Firefox */
* {
  scrollbar-width: thin;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 6px;
}

*::-webkit-scrollbar-track {
  background: white;
}

*::-webkit-scrollbar-thumb {
  background-color: lightgray;
  border-radius: 20px;
  border: 5px solid lightgray;
}
</style>
