import { getStandardRatingColors } from "@/components/dashboard/cards/average-scores/colours.js";
import { getDescriptorForDeskScore } from "@/components/dashboard/cards/average-scores/rating.js";

export const DeskCard = {
  icon: location => (location === "Home" ? "home.svg" : "office.svg"),
  title: location => (location === "Home" ? "deskHome" : "deskOffice"),
  score: score => score,
  ratingObj: score => {
    const colors = getStandardRatingColors(score);
    return {
      backgroundColor: colors.background,
      text: getDescriptorForDeskScore(score),
      textColor: colors.text
    };
  }
};
