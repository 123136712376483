import { LinkTypes } from "@/services/recommendations/link-types.js";
import * as ScoreService from "@/services/assessment/physical-labour/reba-scoring-service.js";
import Cards from "./RecommendationCards.json";
import sentry from "@/sentry";
import PainService from "@/services/assessment/pain/pain-assessment-service.js";
import { rebaPainAreas } from "@/components/assessment/physical-labour/utils/constants.js";

export default function getRecommendations(results, path) {
  const recommendations = getAllRecommendations();
  const cardsToShow = recommendations.filter(r => {
    try {
      return r.showAnswer(results, path);
    } catch (e) {
      sentry.captureException(e);
      return false;
    }
  });
  return getStretchRecommendations(cardsToShow, results);
}

function getStretchRecommendations(recommendations, results) {
  recommendations.sort((a, b) => {
    const relevanceDiff =
      b.highestRelevantPainLevel(results) - a.highestRelevantPainLevel(results);
    if (relevanceDiff !== 0) {
      return relevanceDiff;
    }
    return b.rebaScore(results) - a.rebaScore(results);
  });

  return recommendations.slice(0, 1);
}

function getAllRecommendations() {
  return [
    {
      id: "stretch_during_breaks",
      showAnswer: () => true,
      json: Cards.stretchDuringBreaks,
      linkType: () => LinkTypes.REGULAR,
      rebaScore: () => 0,
      highestRelevantPainLevel: () => 0
    },
    {
      id: "physical_labour_neck_stretch",
      showAnswer: results =>
        ScoreService.parseNeckScore(results) > 1 ||
        PainService.hasSomePainAreas(results, rebaPainAreas.neck),
      json: Cards.neckStretch,
      linkType: () => LinkTypes.REGULAR,
      rebaScore: results => ScoreService.parseNeckScore(results),
      highestRelevantPainLevel: results =>
        PainService.getHighestRelevantPainLevel(results, rebaPainAreas.neck)
    },
    {
      id: "physical_labour_back_stretch",
      showAnswer: results =>
        ScoreService.parseTorsoScore(results) > 2 ||
        PainService.hasSomePainAreas(results, rebaPainAreas.torso),
      json: Cards.torsoStretch,
      linkType: () => LinkTypes.REGULAR,
      rebaScore: results => ScoreService.parseTorsoScore(results),
      highestRelevantPainLevel: results =>
        PainService.getHighestRelevantPainLevel(results, rebaPainAreas.torso)
    },
    {
      id: "physical_labour_leg_stretch",
      showAnswer: results =>
        ScoreService.parseLegScore(results) > 2 ||
        PainService.hasSomePainAreas(results, rebaPainAreas.leg),
      json: Cards.legStretch,
      linkType: () => LinkTypes.REGULAR,
      rebaScore: results => ScoreService.parseLegScore(results),
      highestRelevantPainLevel: results =>
        PainService.getHighestRelevantPainLevel(results, rebaPainAreas.leg)
    },
    {
      id: "physical_labour_elbow_stretch",
      showAnswer: results =>
        ScoreService.parseLowerArmScore(results) > 1 ||
        PainService.hasSomePainAreas(results, rebaPainAreas.lowerArm),
      json: Cards.elbowStretch,
      linkType: () => LinkTypes.REGULAR,
      rebaScore: results => ScoreService.parseLowerArmScore(results),
      highestRelevantPainLevel: results =>
        PainService.getHighestRelevantPainLevel(results, rebaPainAreas.lowerArm)
    },
    {
      id: "physical_labour_wrist_stretch",
      showAnswer: results =>
        ScoreService.parseWristScore(results) > 1 ||
        PainService.hasSomePainAreas(results, rebaPainAreas.wrist),
      json: Cards.wristStretch,
      linkType: () => LinkTypes.REGULAR,
      rebaScore: results => ScoreService.parseWristScore(results),
      highestRelevantPainLevel: results =>
        PainService.getHighestRelevantPainLevel(results, rebaPainAreas.wrist)
    },
    {
      id: "physical_labour_shoulder_stretch",
      showAnswer: results =>
        ScoreService.parseUpperArmScore(results) > 1 ||
        PainService.hasSomePainAreas(results, rebaPainAreas.upperArm),
      json: Cards.shoulderStretch,
      linkType: () => LinkTypes.REGULAR,
      rebaScore: results => ScoreService.parseUpperArmScore(results),
      highestRelevantPainLevel: results =>
        PainService.getHighestRelevantPainLevel(results, rebaPainAreas.upperArm)
    }
  ];
}
