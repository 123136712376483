export const DriverAssessmentSteps = {
  Welcome: {
    INDEX: -1,
    THEME: "welcome",
    STEPPER: false,
    NAVIGATION: false,
    FORM: false,
    VALIDATION: false
  },
  Vehicle: {
    INDEX: 0,
    THEME: "vehicle",
    STEPPER: false,
    NAVIGATION: true,
    FORM: true,
    VALIDATION: true
  },
  Posture: {
    INDEX: 1,
    THEME: "drivingPosture",
    STEPPER: true,
    NAVIGATION: true,
    FORM: true,
    VALIDATION: true
  },
  Habits: {
    INDEX: 2,
    THEME: "habits",
    STEPPER: true,
    NAVIGATION: true,
    FORM: true,
    VALIDATION: true
  },
  Pain: {
    INDEX: 3,
    THEME: "pain",
    STEPPER: true,
    NAVIGATION: true,
    FORM: true,
    VALIDATION: true
  },
  Health: {
    INDEX: 4,
    THEME: "health",
    STEPPER: true,
    NAVIGATION: true,
    FORM: true,
    VALIDATION: true
  },
  Report: {
    INDEX: 5,
    THEME: "report",
    STEPPER: false,
    NAVIGATION: false,
    FORM: false,
    VALIDATION: false
  }
};

export const getStepByIndex = index => {
  return (
    Object.entries(DriverAssessmentSteps).find(
      ([key, value]) => value.INDEX === index
    )?.[1] || null
  );
};
