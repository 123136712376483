<template>
  <v-combobox
    ref="comboBox"
    v-model="comboBoxModel"
    @keyup="processEmails"
    @paste="processEmails"
    :label="$t('invite.multipleEmailInput.label')"
    placeholder="leela@planetexpress.com, shane@vitruehealth.com"
    multiple
    clearable
    chips
    :hint="$t('invite.multipleEmailInput.hint')"
    :rules="[rules.emailRequired]"
    outlined
    :color="color"
    data-cy="emails"
    :dense="true"
  >
    <template v-slot:selection="{ attrs, item, parent, selected }">
      <v-chip
        class="data-hj-suppress"
        :key="JSON.stringify(item)"
        v-bind="attrs"
        :input-value="selected"
        :color="validEmail(item) ? 'gray lighten-2' : 'error'"
        :text-color="validEmail(item) ? 'black' : 'error'"
        :outlined="!validEmail(item)"
        close
        @click:close="removeEmail(item)"
      >
        <v-avatar
          v-if="validEmail(item)"
          class="primary white--text"
          left
          v-text="item.slice(0, 1).toUpperCase()"
        ></v-avatar>
        {{ item }}
      </v-chip>
    </template>
  </v-combobox>
</template>

<script>
export default {
  name: "MultipleEmailsInputField",
  props: {
    value: Array,
    color: String
  },
  data() {
    return {
      userInput: "",
      rules: {
        emailRequired: v => v.length > 0 || this.$t("inputRules.emailExists")
      }
    };
  },
  methods: {
    removeEmail(item) {
      this.value.splice(this.value.indexOf(item), 1);
      this.$emit("input", this.value);
    },
    validEmail(item) {
      return /.+@.+\..+/.test(item);
    },
    processEmails() {
      const vm = this;
      let comboBoxValue = vm.$refs.comboBox.internalSearch;
      if (!comboBoxValue) {
        return;
      }

      const invalidCharacters = /[^a-z0-9@\-_.'+]+/gi;
      const includesInvalidCharacter = comboBoxValue.match(invalidCharacters);

      if (!includesInvalidCharacter) {
        return;
      }

      const newEmails = comboBoxValue
        .split(invalidCharacters)
        .filter(str => !!str);
      const allEmails = this.value.concat(newEmails);
      const uniqueEmails = [...new Set(allEmails)];
      vm.$refs.comboBox.internalSearch = "";
      this.$emit("input", uniqueEmails);
    }
  },
  computed: {
    comboBoxModel: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  }
};
</script>

<style scoped lang="scss">
::v-deep .v-select__selections {
  gap: 4px;
  padding: 8px 0 !important;
}
</style>
