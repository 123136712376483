const SCORE_BOUNDARY_VALUES = {
  AVERAGE: 28,
  GOOD: 56,
  GREAT: 78
};

const SCORE_BOUNDARY_NAMES = {
  BAD: "bad",
  AVERAGE: "average",
  GOOD: "good",
  GREAT: "great"
};

function OverallScoreColor(score) {
  switch (true) {
    case score >= SCORE_BOUNDARY_VALUES.GREAT:
      return { color: "vflBrightGreenDark1", textColor: "vflBrightGreenDark2" };
    case score >= SCORE_BOUNDARY_VALUES.GOOD:
      return { color: "vflBrightGreen", textColor: "vflBrightGreenDark2" };
    case score >= SCORE_BOUNDARY_VALUES.AVERAGE:
      return { color: "vflOrange", textColor: "vflOrangeDark1" };
    default:
      return { color: "vflMagenta", textColor: "vflMagentaDark2" };
  }
}

function GetScoreRatingName(score) {
  switch (true) {
    case score >= SCORE_BOUNDARY_VALUES.GREAT:
      return SCORE_BOUNDARY_NAMES.GREAT;
    case score >= SCORE_BOUNDARY_VALUES.GOOD:
      return SCORE_BOUNDARY_NAMES.GOOD;
    case score >= SCORE_BOUNDARY_VALUES.AVERAGE:
      return SCORE_BOUNDARY_NAMES.AVERAGE;
    default:
      return SCORE_BOUNDARY_NAMES.BAD;
  }
}

const SCORE_SLIDER_SECTIONS = [
  { color: "vflMagenta", percent: 0 },
  {
    color: "vflOrange",
    percent: SCORE_BOUNDARY_VALUES.AVERAGE
  },
  {
    color: "vflBrightGreen",
    percent: SCORE_BOUNDARY_VALUES.GOOD
  },
  {
    color: "vflBrightGreenDark1",
    percent: SCORE_BOUNDARY_VALUES.GREAT
  }
];

export {
  SCORE_BOUNDARY_VALUES,
  SCORE_SLIDER_SECTIONS,
  OverallScoreColor,
  GetScoreRatingName
};
