<template>
  <v-card :max-width="maxWidth" color="vflGreenDark1">
    <v-card-text class="py-2 px-4">
      <div class="text-caption text-start white--text">
        <slot v-if="$slots.default"> </slot>
        <div v-else v-html="message"></div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "vitrue-tooltip",
  props: {
    message: String,
    maxWidth: {
      type: [Number, String],
      default: "400px"
    }
  }
};
</script>

<style lang="scss" scoped>
.text-caption {
  line-height: 1.4 !important;
}
</style>
