<template>
  <div v-show="show" class="pb-1">
    <v-col cols="10" class="pt-0">
      <div class="text-body-2" v-for="n in options.length" :key="n">
        <v-checkbox
          v-model="selected"
          :label="$t(options[n - 1].label)"
          :value="options[n - 1].value"
          @change="emitToParent(selected)"
          :hide-details="n !== options.length"
          :rules="validationRules"
        ></v-checkbox>
      </div>
      <p v-if="showNonePrompt" class="caption red--text">
        {{
          $t("form.checkboxList.deselectInstruction", {
            "0": $t(options[options.length - 1].label)
          })
        }}
      </p>
    </v-col>
  </div>
</template>

<script>
export default {
  name: "CheckBoxListQuestion",
  props: {
    name: String,
    options: Array,
    value: Array,
    index: Number,
    show: {
      default: true,
      type: Boolean
    },
    optional: Boolean
  },
  data() {
    return {
      selected: this.value ?? [],
      showNonePrompt: false
    };
  },
  computed: {
    validationRules() {
      if (this.optional) {
        return [];
      } else {
        return [
          () =>
            this.selected.length > 0 ||
            this.$t("form.checkboxList.selectOption")
        ];
      }
    }
  },
  methods: {
    emitToParent(value) {
      this.showNonePrompt = false;

      // If value contains 99, user has selected None of these options,
      // freezing other options from being selected
      if (value.includes(99) && this.selected.length > 1) {
        if (this.selected[0] === 99) {
          this.showNonePrompt = true;
        }
        this.selected = [99];
      }

      this.$emit("input", this.selected);
    }
  }
};
</script>
