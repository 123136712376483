<template>
  <div>
    <v-img
      v-if="showImage"
      :src="imageSource"
      height="200px"
      max-height="200px"
      gradient="to top, rgb(51, 51, 51,1.0) 10%, rgb(51, 51, 51,0.1) 40%, rgb(51, 51, 51,0) 100%"
      style="position: relative"
      class="rounded-lg rounded-b-0"
      @load="imageLoaded"
      eager
      ><div v-if="coveredBy" class="banner-overlay text-center">
        <v-img contain :src="`/images/recommendations/banners/${coveredBy}`">
        </v-img>
      </div>
      <div
        v-if="showPlayButton"
        class="video-button"
        id="videoButton"
        @click="$emit('showVideo')"
      >
        <PlayButton />
      </div>
      <div class="white--text px-4 imageTitle">
        <p
          id="heading"
          class="mb-1 text-h6"
          style="line-height: 20px"
          data-recommendation-card-heading
        >
          {{ heading }}
        </p>
        <p
          id="subheading"
          class="mb-3 text-body-2 subheading"
          style="opacity: 0.85"
          data-recommendation-card-subheading
        >
          {{ subheading }}
        </p>
      </div>
    </v-img>
    <div v-else class="black--text px-4 mt-6">
      <p id="heading" class="mb-0 text-h6" data-recommendation-card-heading>
        {{ heading }}
      </p>
      <p
        id="subheading"
        class="mb-2 text-body-2"
        data-recommendation-card-subheading
      >
        {{ subheading }}
      </p>
    </div>
  </div>
</template>

<script>
import PlayButton from "@/components/common/animations/PlayButton.vue";

export default {
  name: "RecommendationCardTitle",
  components: {
    PlayButton
  },
  props: {
    heading: String,
    subheading: String,
    imageSource: String,
    hideImageOnMobile: Boolean,
    coveredBy: String,
    showPlayButton: Boolean
  },
  computed: {
    showImage() {
      return (
        this.imageSource &&
        (this.$vuetify.breakpoint.smAndUp || !this.hideImageOnMobile)
      );
    }
  },
  methods: {
    imageLoaded() {
      setTimeout(() => {
        this.$emit("onImageLoaded");
      }, 10);
    }
  }
};
</script>

<style lang="scss" scoped>
.imageTitle {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.banner-overlay {
  position: absolute;
  top: 5%;
  left: -15%;
  z-index: 10;
  width: 60%;
  background: white;
  transform: rotate(315deg);
}

.video-button {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
</style>
