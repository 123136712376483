<template>
  <div class="feedback-thanks">
    <button v-if="isDismissable" class="close-button" @click="$emit('dismiss')">
      <v-icon color="black" :size="28">mdi-close</v-icon
      ><span class="d-sr-only">Close</span>
    </button>

    <div
      class="content d-flex flex-column pb-0"
      :class="[
        isPopup ? 'pt-8 px-8' : 'pt-14 px-8',
        hasTextCenter ? 'align-center' : ''
      ]"
    >
      <component
        v-if="showTitle"
        :is="headingLevel"
        class="mb-6 font-weight-medium text-center"
        :class="headingClass"
        >{{ $t("feedback.thanks.title") }}</component
      >
      <p :class="contentClass" v-html="text"></p>
      <img
        src="/images/illustrations/waving-hands.svg"
        alt=""
        class="mt-auto"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "FeedbackThanksLink",
  props: {
    showTitle: {
      type: Boolean,
      default: true
    },
    isPopup: {
      type: Boolean,
      default: true
    },
    hasConfetti: {
      type: Boolean,
      default: false
    },
    isDismissable: {
      type: Boolean,
      default: false
    },
    hasTextCenter: {
      type: Boolean,
      default: true
    },
    adminOverride: Boolean
  },
  computed: {
    ...mapGetters(["isAdminUser"]),
    headingLevel() {
      return this.isPopup ? "h3" : "h1";
    },
    headingClass() {
      let classList = "";

      if (this.headingLevel === "h3") {
        classList += "text-h5 ";
      } else {
        classList += "text-h3 ";
      }

      if (!this.isPopup) {
        classList += "mt-auto ";
      }

      return classList;
    },
    contentClass() {
      return this.hasTextCenter ? "text-center" : "";
    },
    text() {
      return this.isAdminUser || this.adminOverride
        ? this.$t("feedback.thanks.referral")
        : this.$t("feedback.thanks.trustpilot");
    }
  }
};
</script>

<style lang="scss" scoped>
.feedback-thanks {
  background: linear-gradient(
      310deg,
      rgba(255, 255, 255, 0.05) -18.08%,
      rgba(100, 166, 255, 0.1) 49.77%,
      rgba(134, 111, 255, 0.1) 95.41%
    ),
    #fff;
  display: grid;
  position: relative;
}

.close-button {
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.content {
  p {
    color: black;
    font-size: 1rem;
    letter-spacing: normal;
    line-height: 1.5;
    max-width: 48ch;
  }

  ::v-deep a {
    color: var(--v-vflPrimary-base);
    font-weight: 700;
  }
}

img {
  display: block;
  height: auto;
  max-width: 100%;
}
</style>
