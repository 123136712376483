import BodyAreas from "@/components/painareas/body-areas.json";
import answers from "@/components/assessment/pre-existing-conditions/answers.json";

const painAnswers = answers.pain.areas;
const nonPainAnswers = answers.setup.nonPainCondition;

export const ALL = "ALL";

export const NON_PAIN_CONDITION_MAP = [
  {
    value: nonPainAnswers.chronicFatigueSyndrome,
    text: "assessment.form.healthConditions.banners.report.pain.nonPainCondition.conditions.chronicFatigueSyndrome"
  },
  {
    value: nonPainAnswers.fibromyalgia,
    text: "assessment.form.healthConditions.banners.report.pain.nonPainCondition.conditions.fibromyalgia"
  },
  {
    value: nonPainAnswers.posturalTachycardiaSyndrome,
    text: "assessment.form.healthConditions.banners.report.pain.nonPainCondition.conditions.posturalTachycardiaSyndrome"
  }
];

export const PAIN_AREA_MAP = [
  {
    value: painAnswers.neck.healthConditions.muscleStrainOrSprain,
    text: "assessment.form.healthConditions.banners.report.pain.strain.neck",
    area: BodyAreas.neck.group
  },
  {
    value: painAnswers.leftshoulder.healthConditions.sprain,
    text: "assessment.form.healthConditions.banners.report.pain.strain.shoulder",
    area: BodyAreas.leftshoulder.group
  },
  {
    value: painAnswers.leftshoulder.healthConditions.osteoarthritis,
    text: "assessment.form.healthConditions.banners.report.pain.degeneration.osteoarthritis.shoulder",
    area: BodyAreas.leftshoulder.group
  },
  {
    value: painAnswers.lowerback.healthConditions.muscleStrainOrSprain,
    text: "assessment.form.healthConditions.banners.report.pain.strain.back",
    area: BodyAreas.lowerback.group
  },
  {
    value: painAnswers.midback.healthConditions.muscleStrainOrSprain,
    text: "assessment.form.healthConditions.banners.report.pain.strain.back",
    area: BodyAreas.midback.group
  },
  {
    value: painAnswers.leftelbow.healthConditions.osteoarthritis,
    text: "assessment.form.healthConditions.banners.report.pain.degeneration.osteoarthritis.elbow",
    area: BodyAreas.leftelbow.group
  },
  {
    value: painAnswers.leftelbow.healthConditions.cubitalTunnelSyndrome,
    text: "assessment.form.healthConditions.banners.report.pain.nerve.cubitalTunnelSyndrome",
    area: BodyAreas.leftelbow.group
  },
  {
    value: painAnswers.leftwrist.healthConditions.sprainOrStrain,
    text: "assessment.form.healthConditions.banners.report.pain.strain.wrist",
    area: BodyAreas.leftwrist.group
  },
  {
    value: painAnswers.leftwrist.healthConditions.osteoarthritis,
    text: "assessment.form.healthConditions.banners.report.pain.degeneration.osteoarthritis.wrist",
    area: BodyAreas.leftwrist.group
  },
  {
    value: painAnswers.leftwrist.healthConditions.carpalTunnelSyndrome,
    text: "assessment.form.healthConditions.banners.report.pain.nerve.carpalTunnelSyndrome",
    area: BodyAreas.leftwrist.group
  },
  {
    value: painAnswers.lefthip.healthConditions.osteoarthritis,
    text: "assessment.form.healthConditions.banners.report.pain.degeneration.osteoarthritis.hip",
    area: BodyAreas.lefthip.group
  },
  {
    value: painAnswers.gluteals.healthConditions.piriformisSyndrome,
    text: "assessment.form.healthConditions.banners.report.pain.strain.piriformisSyndrome",
    area: BodyAreas.gluteals.group
  },
  {
    value: painAnswers.gluteals.healthConditions.hipOsteoarthritis,
    text: "assessment.form.healthConditions.banners.report.pain.degeneration.osteoarthritis.hip",
    area: BodyAreas.gluteals.group
  },
  {
    value: painAnswers.lefthamstring.healthConditions.strain,
    text: "assessment.form.healthConditions.banners.report.pain.strain.hamstring",
    area: BodyAreas.lefthamstring.group
  },
  {
    value: painAnswers.leftknee.healthConditions.osteoarthritis,
    text: "assessment.form.healthConditions.banners.report.pain.degeneration.osteoarthritis.knee",
    area: BodyAreas.leftknee.group
  },
  {
    value: painAnswers.leftcalf.healthConditions.strain,
    text: "assessment.form.healthConditions.banners.report.pain.strain.calf",
    area: BodyAreas.leftcalf.group
  },
  {
    value: painAnswers.neck.healthConditions.spondylosis,
    text: "assessment.form.healthConditions.banners.report.pain.degeneration.spondylosis",
    area: ALL
  },
  {
    value: painAnswers.neck.healthConditions.facetJointSyndrome,
    text: "assessment.form.healthConditions.banners.report.pain.degeneration.facetJointSyndrome",
    area: ALL
  },
  {
    value: painAnswers.lowerback.healthConditions.sciatica,
    text: "assessment.form.healthConditions.banners.report.pain.nerve.sciatica",
    area: ALL
  },
  {
    value: painAnswers.lowerback.healthConditions.pinchedNerve,
    text: "assessment.form.healthConditions.banners.report.pain.nerve.pinchedNerve",
    area: ALL
  },
  {
    value: painAnswers.lowerback.healthConditions.spinalStenosis,
    text: "assessment.form.healthConditions.banners.report.pain.nerve.spinalStenosis",
    area: ALL
  },
  {
    value: painAnswers.neck.healthConditions.cervicalMyelopathy,
    text: "assessment.form.healthConditions.banners.report.pain.nerve.cervicalMyelopathy",
    area: ALL
  },
  {
    value: painAnswers.leftwrist.healthConditions.carpalTunnelSyndrome,
    text: "assessment.form.healthConditions.banners.report.pain.nerve.carpalTunnelSyndrome",
    area: ALL
  },
  {
    value: painAnswers.neck.healthConditions.muscleStrainOrSprain,
    text: "assessment.form.healthConditions.banners.report.pain.strain.general",
    area: ALL
  },
  {
    value: painAnswers.leftknee.healthConditions.osteoarthritis,
    text: "assessment.form.healthConditions.banners.report.pain.degeneration.osteoarthritis.general",
    area: ALL
  }
];
