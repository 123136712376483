export const INITIAL_MESSAGE = {
  subject: "You've been invited to VIDA!",
  content: `Hi {employeeName},

As part of our commitment to your health and safety, we've invited you to complete a quick VIDA assessment.

Over 60% of desk workers experience musculoskeletal pain—but it doesn't have to be that way. VIDA helps identify ergonomic risks and offers personalised recommendations to improve your setup and reduce any existing pain with a simple 10-day program.

It only takes 5 minutes to get started. Let me know how you get on!`,
  isAdmin: true,
  timestamp: new Date(Date.now() - 15 * 24 * 60 * 60 * 1000) // 15 days ago
};

export const DUMMY_CONVERSATION = [
  {
    id: "1",
    subject: "Checking in",
    content:
      "Hi , I noticed your assessment flagged some existing neck and shoulder pain. How are you? I just wanted to check in and see if you've tried VIDA's offers a personalised pain coaching program to help with that. Have you had a chance to try it yet?",
    timestamp: new Date(Date.now() - 3 * 24 * 60 * 60 * 1000), // 3 days ago
    isAdmin: true
  },
  {
    id: "2",
    content:
      "Yes, I started the program a few days ago, and I'm already noticing an improvement! The guided exercises are really helping.",
    timestamp: new Date(Date.now() - 2 * 24 * 60 * 60 * 1000), // 2 days ago
    isAdmin: false
  },
  {
    id: "3",
    content:
      "That's great to hear! I'm glad it's making a difference. Is there anything else you need support with right now?",
    timestamp: new Date(Date.now() - 1 * 24 * 60 * 60 * 1000), // 1 day ago
    isAdmin: true
  },
  {
    id: "4",
    content:
      "No, thanks! The program's been really helpful so far. I appreciate you checking in.",
    timestamp: new Date(),
    isAdmin: false
  }
];
