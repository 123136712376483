export const teamStore = {
  namespaced: true,
  state: {
    logoLastFetchTime: 0
  },
  mutations: {
    updateLogoLastFetchTime(state) {
      state.logoLastFetchTime = Date.now();
    }
  },
  getters: {
    isLogoUrlExpired: state => () => {
      const FIFTY_FIVE_MINUTES = 55 * 60 * 1000;
      const currentTime = Date.now();
      const timeSinceLastFetch = currentTime - state.logoLastFetchTime;

      return timeSinceLastFetch > FIFTY_FIVE_MINUTES;
    }
  }
};
