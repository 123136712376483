<template>
  <svg
    width="641"
    height="637"
    viewBox="0 0 641 637"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="239.779"
      y="145.678"
      width="19.9062"
      height="20.8111"
      fill="#92F53C"
    />
    <rect
      x="263.305"
      y="145.678"
      width="19.9062"
      height="20.8111"
      fill="#92F53C"
    />
    <rect
      x="285.926"
      y="148.393"
      width="19.9062"
      height="20.8111"
      fill="#F3DE70"
    />
    <rect
      x="308.547"
      y="145.678"
      width="19.9062"
      height="20.8111"
      fill="#92F53C"
    />
  </svg>
</template>
<script>
export default {
  name: "MemoryChangesOverlay"
};
</script>
