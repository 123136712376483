<template>
  <div class="calendly-container">
    <p class="text-body-1 mb-0 px-8 py-5 text-center">
      <a
        class="link"
        href="https://calendly.com/alisa-h1vr/25min"
        target="_blank"
        >{{ $t("calendly.link") }}</a
      >
      {{ $t("calendly.text") }}
    </p>
  </div>
</template>

<script>
export default {};
</script>

<style>
.calendly-container {
  background: #eaf1f9;
}
.link {
  color: var(--v-vflPrimary-base) !important;
}
</style>
