<template>
  <div class="table-container">
    <VflSimpleTable
      :columns="columns"
      :rows="processedRows"
      :maxRows="3"
      :showHeaders="false"
      :isLoading="isLoading"
      @row-click="handleRowClick"
    >
      <template v-slot:name="{ row }">
        <div class="icon-name-container">
          <avatar-icon :text="row.name" :fallback-text="row.email" :size="24" />

          <span
            class="text-body-2 text-body-bold vflTextDark--text"
            style="white-space: nowrap"
            >{{ row.name || row.email }}</span
          >
        </div>
      </template>

      <template v-slot:tags="{ row }" v-if="showTags">
        <div class="d-flex flex-wrap align-center tag-gap">
          <VflTableTag
            v-for="(tag, index) in row.visibleTags"
            :key="index"
            :label="tag"
          />
          <v-tooltip v-if="row.tags.length > 1" bottom color="transparent">
            <template v-slot:activator="{ on, attrs }">
              <div v-on="on" v-bind="attrs" class="overflow-indicator">
                <span class="overflow-indicator text-body-2 text-body-bold">
                  +{{ row.tags.length - row.visibleTags.length }}
                </span>
              </div>
            </template>
            <vitrue-tooltip
              :message="getTooltipContent('tags', row.additionalTags)"
            />
          </v-tooltip>
        </div>
      </template>

      <template v-slot:risks="{ row }">
        <div class="d-flex flex-wrap tag-gap">
          <VflTableTag v-for="(tag, index) in row.visibleRisks" :key="index">
            <span class="d-flex align-center">
              <span class="risk-tag-indicator" :class="getClass(tag)"></span>
              <span class="risk-text">{{ tag.text }}</span>
            </span>
          </VflTableTag>
          <v-tooltip v-if="row.risks.length > 2" bottom color="transparent">
            <template v-slot:activator="{ on, attrs }">
              <div v-on="on" v-bind="attrs" class="overflow-indicator">
                <span class="overflow-indicator text-body-2 text-body-bold">
                  +{{ row.risks.length - row.visibleRisks.length }}
                </span>
              </div>
            </template>
            <vitrue-tooltip
              :message="getTooltipContent('risks', row.additionalRisks)"
            />
          </v-tooltip>
        </div>
      </template>

      <!-- Empty state slot -->
      <template v-slot:empty>
        <WhoNeedsAttentionEmptyState />
      </template>
    </VflSimpleTable>
  </div>
</template>

<script>
import VflSimpleTable from "@/components/vitrue-foundation-library/table/VflSimpleTable.vue";
import VitrueTooltip from "@/components/common/VitrueTooltip.vue";
import WhoNeedsAttentionEmptyState from "./WhoNeedsAttentionEmptyState.vue";
import VflTableTag from "@/components/vitrue-foundation-library/table/VflTableTag.vue";
import AvatarIcon from "@/components/common/AvatarIcon.vue";
import { mapAndOrderRisks } from "@/components/dashboard/tables/mapUserRisks.js";

const MAX_VISIBLE_TAGS = 1;
const MAX_VISIBLE_RISKS = 2;

export default {
  components: {
    AvatarIcon,
    VflSimpleTable,
    VitrueTooltip,
    WhoNeedsAttentionEmptyState,
    VflTableTag
  },
  props: {
    isLoading: false,
    rows: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      columns: [{ key: "name", width: "1px" }, { key: "risks" }],
      showTags: false
    };
  },
  created() {
    if (this.showTags) {
      this.columns.splice(1, 0, { key: "tags", width: "1px" });
    }
  },
  computed: {
    processedRows() {
      return this.rows?.map(row => ({
        ...row,

        visibleTags: row.tags.slice(0, MAX_VISIBLE_TAGS),
        additionalTags: row.tags.slice(MAX_VISIBLE_TAGS),
        visibleRisks: mapAndOrderRisks(row.risks.slice(0, MAX_VISIBLE_RISKS)),
        additionalRisks: mapAndOrderRisks(row.risks.slice(MAX_VISIBLE_RISKS))
      }));
    }
  },
  methods: {
    getTooltipContent(type, items) {
      const itemToListItem = item => {
        const text = type === "tags" ? item.label ?? item : item.text ?? item;
        return `<li>${text}</li>`;
      };

      const listItems = items.map(itemToListItem).join("");

      return `
        <ul style="padding-left: 12px">
          ${listItems}
        </ul>
      `;
    },
    handleRowClick(data) {
      this.$emit("row-click", data);
    },
    getClass(risk) {
      switch (risk.id) {
        case "HighRisk":
          return "at-risk";
        case "IntensePain":
          return "pain";
        case "HealthCondition":
          return "health-condition";
      }
    }
  }
};
</script>

<style scoped>
.table-container {
  overflow-x: auto;
}

.icon-name-container {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.tag-gap {
  gap: 0.5rem 0.75rem;
}

.risk-tag-indicator {
  border-radius: 9999px;
  height: 6px;
  margin-right: 0.25rem;
  transform: translateY(0.5px);
  width: 6px;

  &.at-risk {
    background-color: var(--v-vflBeigeDark1-base);
  }

  &.pain {
    background-color: var(--v-vflPurple-base);
  }

  &.health-condition {
    background-color: var(--v-vflGreyVeryLight-base);
  }
}

.risk-text {
  color: var(--v-vflText-base);
}

.overflow-indicator {
  display: inline-block;
  color: var(--v-vflPrimary-base);
  cursor: default;
}
</style>
