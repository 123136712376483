<template>
  <div class="other-divider mb-2">
    <span class="text-body-2 text-body-bold">{{ $t("other.or") }}</span>
  </div>
</template>
<script>
export default {
  name: "AiFeatureIntroductionFormDivider"
};
</script>
<style scoped>
.other-divider span {
  padding-left: 3px;
  text-transform: uppercase;
}
</style>
