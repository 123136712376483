import RestApiClient from "./infrastructure/restApiClient";

export async function getUserAccessTier(userId) {
  const path = `/api/stripe/${userId}/user-access-tier`;
  return await RestApiClient.get(path);
}
export async function getSubscriptionPlans(userId) {
  const path = `/api/stripe/${userId}/prices`;
  return await RestApiClient.get(path);
}
export async function getSubscriptionsHistory(userId) {
  const path = `/api/stripe/current-user-subscriptions/${userId}`;

  return await RestApiClient.get(path);
}
export async function getSubscriptionInvoice(userId, invoiceId) {
  const path = `/api/stripe/${userId}/subscription-invoice/${invoiceId}`;
  const result = await RestApiClient.get(path);
  return result.link;
}
export async function createCustomerAndSubscription(userId, paymentDetails) {
  const path = `/api/stripe/${userId}/create-customer`;
  let body = paymentDetails;
  return await RestApiClient.post(path, body);
}
export async function upgradeSubscription(userId, paymentDetails) {
  const path = `/api/stripe/${userId}/upgrade-subscription`;
  let body = paymentDetails;
  return await RestApiClient.post(path, body);
}
export async function checkHasSubscriptionAndSetCustomerId() {
  const path = `/api/stripe/check-has-subscription`;
  return await RestApiClient.post(path);
}

export async function inviteUser(locale, invitation) {
  const path = `/api/assessments/invite/${locale}`;
  let body = invitation;
  return await RestApiClient.post(path, body);
}
export async function startNewAssessment(invitation, teamId) {
  const path = `/api/assessments/start-new/${teamId}`;
  let body = invitation;
  return await RestApiClient.post(path, body);
}
export async function startHassleFreeAssessment(invitation, teamId) {
  const path = `/api/v1/start-hassle-free-assessment/${teamId}`;
  let body = invitation;
  return await RestApiClient.post(path, body);
}
export async function startNewShareableLinkAssessment(invitation, teamId) {
  const path = `/api/v1/share/${teamId}`;
  let body = invitation;
  return await RestApiClient.post(path, body);
}
export async function getTeam(teamMemberId) {
  const path = `/api/v1/get-team/${teamMemberId}`;
  return await RestApiClient.post(path);
}

export async function storeAssessmentData(assessmentDataPointDto) {
  const path = `/api/v1/individual-assessment`;
  let body = assessmentDataPointDto;
  return await RestApiClient.post(path, body);
}

export async function storeDeskAssessmentScore(scoreDto) {
  const path = `/api/v1/individual-assessment/desk-score`;
  let body = scoreDto;
  return await RestApiClient.post(path, body);
}
export async function storeBurnoutAssessmentScore(scoreDto) {
  const path = `/api/v1/individual-assessment/burnout-score`;
  let body = scoreDto;
  return await RestApiClient.post(path, body);
}
export async function getUserAssessmentState(assessmentId) {
  const path = `/api/v1/individual-assessment/get-user-assessment-state/${assessmentId}`;
  return await RestApiClient.get(path);
}
export async function getAssessmentData(assessmentId) {
  const path = `/api/assessments/${assessmentId}`;
  return await RestApiClient.get(path);
}
export async function getAssessmentAnswers(assessmentId, assessmentType) {
  const path = `/api/assessments/${encodeURIComponent(
    assessmentType
  )}/${assessmentId}`;
  return await RestApiClient.get(path);
}
export async function getAssessmentHistoryData(isSimpleUser) {
  const path = isSimpleUser
    ? `/api/clinic/simpleUser/assessments`
    : `/api/clinic/assessments`;
  return await RestApiClient.get(path);
}

export async function getLatestSummaryData(filter) {
  const path = `/api/usage/summary/latest/${encodeURIComponent(
    JSON.stringify(filter)
  )}`;
  return await RestApiClient.get(path);
}
export async function getScreenHeightData(filter) {
  const path = `/api/usage/screenHeight/${encodeURIComponent(
    JSON.stringify(filter)
  )}`;
  return await RestApiClient.get(path);
}
export async function getEquipmentData(filter) {
  const path = `/api/usage/equipment/${encodeURIComponent(
    JSON.stringify(filter)
  )}`;
  return await RestApiClient.get(path);
}
export async function getBurnoutData(filter) {
  const path = `/api/usage/burnout/${encodeURIComponent(
    JSON.stringify(filter)
  )}`;
  return await RestApiClient.get(path);
}
export async function getPainData(filter) {
  const path = `/api/usage/pain/${encodeURIComponent(JSON.stringify(filter))}`;
  return await RestApiClient.get(path);
}
export async function userTeamMemberInformation() {
  const path = `/api/teams/user/teamMemberInformation`;
  return await RestApiClient.get(path);
}
export async function createTeam(teamDto) {
  const path = `/api/teams`;
  let body = teamDto;
  return await RestApiClient.post(path, body);
}
export async function createTeamMembers(teamMemberDto) {
  const path = `/api/teams/createTeamMembers`;
  let body = teamMemberDto;
  return await RestApiClient.post(path, body);
}
export async function createTeamMembersWithTeamId(teamId, teamMemberDto) {
  const path = `/api/teams/createTeamMembers/${teamId}`;
  let body = teamMemberDto;
  return await RestApiClient.post(path, body);
}
export async function updateUserPermissions(teamMemberDto) {
  const path = `/api/teams/updateUserPermissions`;
  let body = teamMemberDto;
  return await RestApiClient.post(path, body);
}
export async function getTeamMember(userId) {
  const path = `/api/teams/user/${userId}/getTeamMember`;
  return await RestApiClient.get(path);
}
export async function getTeamMembers() {
  const path = `/api/teams/user/getTeamMembers`;
  return await RestApiClient.get(path);
}
export async function markTeamMemberAsClosed(memberToDeleteId) {
  const path = `/api/teams/user/markTeamMemberAsClosed/${memberToDeleteId}`;
  return await RestApiClient.post(path);
}
export async function getMemberTeamId(userId) {
  const path = `/api/teams/user/${userId}/getMemberTeamId`;
  return await RestApiClient.get(path);
}
export async function createTeamMemberUser(userId, userToCreateDto) {
  const path = `/api/teams/${userId}/create-user`;
  let body = userToCreateDto;
  return await RestApiClient.post(path, body);
}
export async function createFeedback(feedbackDto) {
  const path = `/api/feedback`;
  let body = feedbackDto;
  return await RestApiClient.post(path, body);
}

export async function ensureAssessmentFeedback(feedbackDto) {
  const path = `/api/feedback/assessment`;
  let body = feedbackDto;
  return await RestApiClient.post(path, body);
}

export async function updateFeedback(feedbackDto) {
  const path = `/api/feedback`;
  let body = feedbackDto;
  return await RestApiClient.put(path, body);
}

export async function sendReport(assessmentDto) {
  const path = `/api/v2/individual-assessment/report`;
  return await RestApiClient.post(path, assessmentDto);
}
export async function sendReminderToCompleteAssessment(locale, assessmentDto) {
  const path = `/api/reminder/${locale}`;
  let body = assessmentDto;
  return await RestApiClient.post(path, body);
}

export async function sendBulkRemindersToCompleteAssessments() {
  const path = `/api/reminder/all`;
  return await RestApiClient.post(path);
}

export async function changeCurrentUserMemberStatus() {
  const path = `/api/teams/user/changeCurrentUserMemberStatus`;
  return await RestApiClient.post(path);
}
export async function getUserTeamAndSubscriptionBaseInformation(userId) {
  const path = `/api/stripe/${userId}/get-user-team-subscription-basic-information`;
  return await RestApiClient.get(path);
}
export async function getSimpleUserDetails() {
  const path = `/api/stripe/get-simple-user-details`;
  return await RestApiClient.get(path);
}
export async function getUserDetails(assessmentId) {
  const path = `/api/v1/user-details/${assessmentId}`;
  return await RestApiClient.get(path);
}
export async function updateUserGoal(userGoalDto) {
  const path = "/api/user-goal";
  await RestApiClient.post(path, userGoalDto);
}

export async function markTeamMemberAsNotNewAsync(locale, userId) {
  const path = `/api/teams/user/${userId}/markTeamMemberAsNotNew/${locale}`;
  return await RestApiClient.post(path);
}
export async function joinToTheTeam(userId, teamId) {
  const path = `/api/teams/user/${userId}/joinToTheTeam/${teamId}`;
  return await RestApiClient.post(path);
}

export async function rejectTeamInvitations(userId) {
  const path = `/api/teams/${userId}/rejectTeamInvitations`;
  return await RestApiClient.post(path);
}
export async function getLikedItemsForAssessment(assessmentId) {
  const path = `/api/likeditems/${assessmentId}`;
  return await RestApiClient.get(path);
}
export async function createLikedItems(likedItemsDto) {
  const path = `/api/likeditems/create`;
  let body = likedItemsDto;
  return await RestApiClient.post(path, body);
}
export async function getCouponFromId(userId, couponId) {
  const path = `/api/stripe/${userId}/get-coupon/${couponId}`;
  return await RestApiClient.get(path);
}

export async function deleteAssessmentTags(assessmentDto) {
  const path = `/api/assessments/tags/delete`;
  let body = assessmentDto;
  return await RestApiClient.delete(path, body);
}
export async function addEndUserTags(assessmentDto) {
  const path = `/api/end-user/tags/create`;
  let body = assessmentDto;
  return await RestApiClient.post(path, body);
}
export async function deleteEndUserTags(assessmentDto) {
  const path = `/api/end-user/tags/delete`;
  let body = assessmentDto;
  return await RestApiClient.delete(path, body);
}
export async function recordEndUserLogin() {
  const path = `/api/end-user/login`;
  return await RestApiClient.post(path);
}
export async function recordTeamMemberLogin() {
  const path = `/api/teams/login`;
  return await RestApiClient.post(path);
}
export async function expireTags(tagDto) {
  const path = `/api/tags/expire`;
  let body = tagDto;
  return await RestApiClient.delete(path, body);
}
export async function addTags(tagDto) {
  const path = `/api/tags/create`;
  let body = tagDto;
  return await RestApiClient.post(path, body);
}
export async function getTeamTags(dto) {
  const path = `/api/tags/team`;
  let body = dto;
  return await RestApiClient.post(path, body);
}
export async function editTags(tagDto) {
  const path = `/api/tags/edit`;
  let body = tagDto;
  return await RestApiClient.post(path, body);
}
export async function getAllAssessmentsForUpdating(count) {
  const path = `/api/assessments/all/desk-assessments/${count}`;
  return await RestApiClient.get(path);
}
export async function updateAssessmentScores(count, scores) {
  const path = `/api/assessments/all/scores/update/${count}`;
  let body = scores;
  return await RestApiClient.post(path, body);
}
export async function getTeamEndUsersEmails() {
  const path = `/api/end-user/email/all`;
  return await RestApiClient.get(path);
}
export async function getEndUsers() {
  const path = `/api/end-user/logged-in-team-member`;
  return await RestApiClient.get(path);
}
export async function getTeamSuggestions(filter) {
  const path = `/api/insights/team/${encodeURIComponent(
    JSON.stringify(filter)
  )}`;
  return await RestApiClient.get(path);
}
export async function getIndividualSuggestions(filter) {
  const path = `/api/insights/individual/${encodeURIComponent(
    JSON.stringify(filter)
  )}`;
  return await RestApiClient.get(path);
}
export async function getAssessmentActions(id) {
  const path = `/api/insights/actions/${id}`;
  return await RestApiClient.get(path);
}
export async function updateSuggestionsCompletedAt(insights, complete) {
  const path = `/api/insights/update/complete/${complete}`;
  let body = insights;
  return await RestApiClient.post(path, body);
}
export async function updateNotes(notesToUpdate) {
  const path = `/api/insights/update/notes`;
  let body = notesToUpdate;
  return await RestApiClient.post(path, body);
}
export async function currentUserIsEndUser() {
  const path = `/api/end-user`;
  return await RestApiClient.get(path);
}
export async function updateSSOTeamMember() {
  const path = `/api/teams/update-sso-team-member`;
  return await RestApiClient.post(path);
}
export async function cancelTeamMemberInvite(memberToCancelId) {
  const path = `/api/teams/cancel-team-member-invite/${memberToCancelId}`;
  return await RestApiClient.post(path);
}
export async function addEndUsersAndUpdateTags(endUsers) {
  const path = `/api/end-user/add`;
  let body = endUsers;
  return await RestApiClient.post(path, body);
}
export async function getCurrentUserMostRecentAssessment(assessmentType) {
  const path = `/api/assessments/most-recent/${assessmentType}`;
  return await RestApiClient.get(path);
}

export async function getCurrentUserMostRecentAssessmentAnswers(
  assessmentType
) {
  const path = `/api/assessments/most-recent-answers/${assessmentType}`;
  return await RestApiClient.get(path);
}
export async function deleteEndUser(userToDelete) {
  const path = `/api/end-user/delete`;
  let body = userToDelete;
  return await RestApiClient.delete(path, body);
}
export async function updateEndUserSignUps(count) {
  const path = `/api/teams/update-end-user-sign-ups/${count}`;
  return await RestApiClient.post(path);
}

export async function getActiveTeamForEndUserEmail() {
  const path = `/api/teams/available-end-user-team`;
  return await RestApiClient.get(path);
}
export async function joinEndUserToTeam(teamId) {
  const path = `/api/teams/join-logged-in-end-user-to-team/${teamId}`;
  return await RestApiClient.post(path);
}

export async function joinEndUserToTeamJoinTeamPage(teamId) {
  const path = `/api/teams/sign-up-user/join-page/${teamId}`;
  return await RestApiClient.post(path);
}

export async function joinEndUserToTeamViaSignupLink(teamId) {
  const path = `/api/teams/sign-up-user/shareable-link/${teamId}`;
  return await RestApiClient.post(path);
}

export async function confirmEndUserSignUp(endUserDto) {
  const path = `/api/teams/confirm-end-user-report-sign-up`;
  let body = endUserDto;
  return await RestApiClient.post(path, body);
}

export async function updateWellnessProgramme(assessmentId) {
  const path = `/api/v1/wellness/programme/update/${assessmentId}`;
  return await RestApiClient.post(path);
}
export async function updateWellnessProgrammeWithPain(assessmentId, painAreas) {
  const path = `/api/v1/wellness/programme/update/with-pain/${assessmentId}`;
  let body = painAreas;
  return await RestApiClient.post(path, body);
}

export async function markWellnessTasksAsSeen(tasks) {
  const path = `/api/wellness/programme/tasks/seen`;
  let body = tasks;
  return await RestApiClient.post(path, body);
}
export async function markWellnessTaskAsComplete(tasks) {
  const path = `/api/wellness/programme/tasks/complete`;
  let body = tasks;
  return await RestApiClient.post(path, body);
}
export async function markUserVideoAsComplete(video) {
  const path = `/api/wellness/user/interaction/complete`;
  let body = video;
  return await RestApiClient.post(path, body);
}
export async function deleteAssessment(assessmentId) {
  const path = `/api/v1/individual-assessment/delete-assessment/${assessmentId}`;
  return await RestApiClient.post(path);
}
export async function shiftWellnessProgramme(userEmail) {
  const path = `/api/wellness/programme/dev/shift/${encodeURIComponent(
    userEmail
  )}`;
  return await RestApiClient.post(path);
}
export async function extendTeamTrial() {
  const path = `/api/teams/extend-trial`;
  return await RestApiClient.post(path);
}
export async function getPercentageCompleteOfUserProgrammes(endTasks) {
  const path = `/api/wellness/programme/percentage-complete`;
  let body = endTasks;
  return await RestApiClient.post(path, body);
}
export async function getEndUserPersonalDetails() {
  const path = `/api/end-user/account/details`;
  return await RestApiClient.get(path);
}
export async function setEndUserPersonalDetails(userDto) {
  const path = `/api/end-user/account/details`;
  let body = userDto;
  return await RestApiClient.post(path, body);
}

export async function updateEmailAddress(emailUpdateDto) {
  const path = `/api/end-user/account/email`;
  let body = emailUpdateDto;
  return await RestApiClient.post(path, body);
}

export async function updateAssessmentState(id, state) {
  const path = `/api/v1/individual-assessment/status/${state}/${id}`;
  return await RestApiClient.post(path);
}
export async function updateNotificationForEndUser(notificationDto) {
  const path = `/api/end-user/notification`;
  let body = notificationDto;
  return await RestApiClient.post(path, body);
}
export async function getNotificationsForEndUser() {
  const path = `/api/end-user/notification`;
  return await RestApiClient.get(path);
}
export async function setPhoneNumberAndNotificationForEndUser(userDto) {
  const path = `/api/end-user/notification/phone`;
  let body = userDto;
  return await RestApiClient.post(path, body);
}
export async function checkFeatureIntroComplete(introId) {
  const path = `/api/feature-introductions/${introId}/check-complete`;
  return await RestApiClient.post(path);
}
export async function adminCheckListCompleted() {
  const path = `/api/feature-introductions/admin-checklist/completed`;
  return await RestApiClient.get(path);
}
export async function setFeatureIntroItemComplete(introId, itemId) {
  const path = `/api/feature-introductions/${introId}/items/${itemId}/mark-complete`;
  return await RestApiClient.post(path);
}
export async function getActiveFeatureIntroductions() {
  const path = "/api/feature-introductions";
  return await RestApiClient.get(path);
}
export async function getTeamSettings() {
  const path = "/api/teams/team-settings";
  return await RestApiClient.get(path);
}
export async function updateTeamSettings(newSettings) {
  const path = "/api/teams/team-settings";
  let body = newSettings;
  return await RestApiClient.post(path, body);
}

export async function setQuickStart(settings) {
  const path = "/api/end-user/settings/quick-start";
  let body = settings;
  return await RestApiClient.post(path, body);
}

export async function isSubdomainValid(domainName) {
  const path = `/api/teams/team-settings/subdomain/valid/${domainName}`;
  return await RestApiClient.get(path);
}

export async function getPainCoachDayStreaks() {
  const path = `/api/wellness/programme/streak/day`;
  return await RestApiClient.get(path);
}

export async function getPainCoachStats() {
  const path = `/api/wellness/programme/stats`;
  return await RestApiClient.get(path);
}

export async function getPainCoachGoal() {
  const path = `/api/wellness/programme/goal`;
  return await RestApiClient.get(path);
}

export async function getVideoMetadataByYoutubeId(youtubeId) {
  const path = `/api/wellness/content/video-metadata/youtube/${youtubeId}`;
  return await RestApiClient.get(path);
}

export async function getTeamCompletedAssessmentCount() {
  const path = "/api/assessments/team-complete-counts";
  return await RestApiClient.get(path);
}

export async function getActiveEndUsersForCurrentTeamMember() {
  const path = "/api/teams/logged-in-member/end-users/active";
  return await RestApiClient.get(path);
}

export async function sendAssessmentsToEndUser(locale, inviteDto) {
  const path = `/api/assessments/invite/end-user/${locale}`;
  let body = inviteDto;
  return await RestApiClient.post(path, body);
}

export async function moveUsersFromOneTeamToAnother(transferDto) {
  const path = `/api/teams/transfer-users`;
  let body = transferDto;
  return await RestApiClient.post(path, body);
}

export async function getUserAuthState(emailDto) {
  const path = "/api/auth/user-state";
  let body = emailDto;
  return await RestApiClient.post(path, body);
}

export async function sendNewTempPasswordEmailToUser(emailDto) {
  const path = "/api/auth/admin-created-user/reset-password";
  let body = emailDto;
  return await RestApiClient.post(path, body);
}

export async function authUserExists(emailDto) {
  const path = "/api/auth/user-exists";
  let body = emailDto;
  return await RestApiClient.post(path, body);
}

export async function teamIsSlackAuthorized() {
  const path = "/api/slack/authorize";
  return await RestApiClient.get(path);
}

export async function userAssessmentIsSlackAuthorized(assessmentId) {
  const path = `/api/slack/authorize/${assessmentId}`;
  return await RestApiClient.get(path);
}

export async function authorizeSlackTeam(code) {
  const path = `/api/slack/authorize`;
  let body = code;
  return await RestApiClient.post(path, body);
}

export async function authorizeSlackUserFromAssessment(assessmentId, code) {
  const path = `/api/slack/authorize/${assessmentId}`;
  let body = code;
  return await RestApiClient.post(path, body);
}

export async function revokeUserSlackTokens() {
  const path = `/api/slack/revoke`;
  return await RestApiClient.post(path);
}

export async function postSlackImage(dto) {
  const path = `/api/slack/image`;
  let body = dto;
  return await RestApiClient.post(path, body);
}

export async function updateAssesssmentDataPoint(dto) {
  const path = `/api/dev/assessments/data-points/type`;
  let body = dto;
  return await RestApiClient.post(path, body);
}

export async function updateLearningManagementSystemsOnAssessmentCompletion(
  assessmentId
) {
  const path = `/api/v1/individual-assessment/learning-management/assessment-complete/${assessmentId}`;
  return await RestApiClient.post(path);
}

export async function storePainCoachDailyFeedback(dto) {
  const path = `/api/assessments/pain-coach-feedback`;
  let body = dto;
  return await RestApiClient.post(path, body);
}

export async function storePainCoachDailyFeedbackWithTaskCompletion(
  dto,
  isCompleted
) {
  const path = `/api/assessments/pain-coach-feedback/${isCompleted}`;
  let body = dto;
  return await RestApiClient.post(path, body);
}

export async function associatePainTaskCompletionToAssessment(assessmentId) {
  const path = `/api/assessments/pain-coach-feedback/associate-to-assessment/${assessmentId}`;
  return await RestApiClient.post(path);
}

export async function getCustomerSeatInfo(userId) {
  const path = `/api/stripe/${userId}/customer-seat-information`;
  return await RestApiClient.get(path);
}

export async function sendSeatWarningInternalEmail(teamMemberId) {
  const path = `/api/internal/seatLimit/email/${teamMemberId}`;
  return await RestApiClient.post(path);
}

export async function getPainCoachCycle() {
  const path = `/api/wellness/programme/cycle`;
  return await RestApiClient.get(path);
}

export async function storeLatestPainInfo(dto) {
  const path = `/api/assessments/pain/latest`;
  let body = dto;
  return await RestApiClient.post(path, body);
}

export async function updateTeamMemberForTeamDevMethod(teamId, dto) {
  const path = `/api/teams/${teamId}/update-member`;
  let body = dto;
  return await RestApiClient.post(path, body);
}

export async function getTeamsProvisioningToken(teamId) {
  const path = `/api/teams/${teamId}/generate-provisioning-token`;
  return await RestApiClient.get(path);
}

export async function removeUsersNotInListDevMethod(teamId, users) {
  const path = `/api/teams/${teamId}/remove-users-not-in-list`;
  let body = users;
  return await RestApiClient.post(path, body);
}

export async function getInactiveTeamMembers() {
  const path = "/api/teams/user/team-members/inactive";
  return await RestApiClient.get(path);
}

export async function getInactiveEndUsers() {
  const path = "/api/teams/user/end-users/inactive";
  return await RestApiClient.get(path);
}

export async function getCurrentUserMostRecentPainAssessment() {
  const path = `/api/assessments/most-recent/pain-assessment`;
  return await RestApiClient.get(path);
}

export async function submitEmailPainCheckInValues(dto) {
  const path = `/api/v1/individual-assessment/email/pain-check-in`;
  let body = dto;
  return await RestApiClient.post(path, body);
}

export async function submitVerifiedEmailPainCheckInValues(dto) {
  const path = `/api/v1/individual-assessment/email/verified-pain-check-in`;
  let body = dto;
  return await RestApiClient.post(path, body);
}

export async function injectPainImpactQuestionsToPainFollowUp(
  assessmentId,
  dto
) {
  const path = `/api/v1/individual-assessment/pain-impact-follow-up/${assessmentId}`;
  const body = dto;
  return await RestApiClient.post(path, body);
}

export async function getJoiningTeamsInfo(teamId) {
  const path = `/api/auth/team-info/${teamId}`;
  return await RestApiClient.get(path);
}

export async function getTeamFeatures(teamId) {
  const path = `/api/auth/team-features/${teamId}`;
  return await RestApiClient.get(path);
}

export async function getIndustryAverageDeskScore() {
  const path = `/api/v1/individual-assessment/desk-assessment/industry-average`;
  return await RestApiClient.get(path);
}

export async function getGlobalPainLevelCounts(painArea) {
  const path = `/api/pain/get-global-pain-counts/${painArea}`;
  return await RestApiClient.get(path);
}

export async function getPainHistory() {
  const path = `/api/pain/priority-area/history`;
  return await RestApiClient.get(path);
}

export async function getInProgressAssessment(type) {
  const path = `/api/assessments/most-recent/in-progress/${encodeURIComponent(
    type
  )}`;
  return await RestApiClient.get(path);
}

export async function setLocaleDbValue(locale) {
  const path = `/api/end-user/locale/${locale}`;
  return await RestApiClient.post(path);
}

export async function getLocaleDbValue() {
  const path = `/api/end-user/locale`;
  return await RestApiClient.get(path);
}

export async function sendPainCoachRemindersTest() {
  const path = `/api/dev/notifications/paincoachreminder`;
  return await RestApiClient.post(path);
}
export async function sendSignUpRemindersTest() {
  const path = `/api/dev/notifications/signupreminder`;
  return await RestApiClient.post(path);
}
export async function sendReassessmentEmailsTest() {
  const path = `/api/dev/notifications/reassessmentemails`;
  return await RestApiClient.post(path);
}

export async function sendAssessmentCompleteNotification(assessmentId) {
  const path = `/api/v1/individual-assessment/send-complete-notification/${assessmentId}`;
  return await RestApiClient.post(path);
}

export async function makeEquipmentRequest(equipmentId, assessmentId) {
  const path = `/api/equipment-request`;
  let body = {
    equipmentId: equipmentId,
    assessmentId: assessmentId
  };
  return await RestApiClient.post(path, body);
}

export async function getEquipmentRequests(assessmentId) {
  const path = `/api/equipment-request/${assessmentId}`;
  return await RestApiClient.get(path);
}

export async function getRandomExerciseVideo(area) {
  const path = `/api/wellness/exercise/${area}`;
  return await RestApiClient.get(path);
}

export async function isFromExternalProvider(emailDto) {
  const path = "/api/auth/external-provider";
  let body = emailDto;
  return await RestApiClient.post(path, body);
}

export async function domainMatchesSSOProvider(emailDto) {
  const path = "/api/auth/sso-domain";
  let body = emailDto;
  return await RestApiClient.post(path, body);
}

export async function getFeatureFlagOptions() {
  const path = `/api/dev/feature-flags`;
  return await RestApiClient.get(path);
}

export async function selectFeatureFlag(id) {
  const path = `/api/dev/feature-flags/${id}`;
  return await RestApiClient.post(path);
}
export async function userCanBookAppointment(assessmentId) {
  const path = `/api/appointments/can-book/${assessmentId}`;
  return await RestApiClient.get(path);
}

export async function addAppointment(assessmentId) {
  const path = `/api/appointments/${assessmentId}`;
  return await RestApiClient.post(path);
}

export async function getAppointments() {
  const path = `/api/appointments`;
  return await RestApiClient.get(path);
}

export async function updateAppointmentComplete(dto) {
  const path = `/api/appointments/complete`;
  let body = dto;
  return await RestApiClient.post(path, body);
}

export async function getAppointmentAssessment(id) {
  const path = `/api/appointments/assessments/${id}`;
  return await RestApiClient.get(path);
}

export async function addAssessmentRecommendations(dto) {
  const path = `/api/v1/individual-assessment/recommendations`;
  let body = dto;
  return await RestApiClient.post(path, body);
}

export async function backPopulateAssessmentRecommendations(dto) {
  const path = `/api/dev/backpopoulate-recommendations`;
  let body = dto;
  return await RestApiClient.post(path, body);
}

export async function getTeamUserpoolSettings(teamId) {
  const path = `/api/auth/team-userpool-settings/${teamId}`;
  return await RestApiClient.get(path);
}

export async function getCompletedRecommendationsForAssessment(assessmentId) {
  const path = `/api/v1/assessment/recommendation/complete/${assessmentId}`;
  return await RestApiClient.get(path);
}
export async function markRecommendationAsComplete(dto) {
  const path = "/api/v1/assessment/recommendation/complete";
  return await RestApiClient.post(path, dto);
}

export async function markRecommendationAsIncomplete(dto) {
  const path = "/api/v1/assessment/recommendation/incomplete";
  return await RestApiClient.post(path, dto);
}

export async function getUserAssessmentHistory() {
  const path = "/api/assessment-history";
  return await RestApiClient.get(path);
}

export async function sendAssessmentReminders(userIds, provider) {
  const path = "/api/assessment/reminder/v2";
  return await RestApiClient.post(path, {userIds: userIds, externalNotificationProvider: provider});
}

export async function getTeamDetails() {
  const path = "/api/teams/details";
  return await RestApiClient.get(path);
}

export async function saveTeamLogo(logo) {
  const path = "/api/teams/logo";
  let body = logo;
  return await RestApiClient.post(path, body);
}

export async function deleteTeamLogo() {
  const path = "/api/teams/logo/delete";
  return await RestApiClient.post(path);
}

export async function saveTeamName(name) {
  const path = "/api/teams/name";
  let body = name;
  return await RestApiClient.post(path, body);
}

export async function getTeamRecommendations() {
  const path = `/api/recommendations/team`;
  return await RestApiClient.get(path);
}

export async function saveTeamRecommendation(rec) {
  const path = `/api/recommendations/team`;
  let body = rec;
  return await RestApiClient.post(path, body);
}

export async function deleteTeamRecommendation(id) {
  const path = `/api/recommendations/team/${id}`;
  return await RestApiClient.delete(path);
}

export async function getUnsplashImages(searchTerm) {
  const path = searchTerm
    ? `/api/unsplash/search/${searchTerm}`
    : "/api/unsplash";
  return await RestApiClient.get(path);
}
export async function downloadUnsplashImage(image) {
  const path = `/api/unsplash/download`;
  let body = image;
  return await RestApiClient.post(path, body);
}

export async function duplicateTeam(team) {
  const path = `/api/dev/duplicate-team`;
  let body = team;
  return await RestApiClient.post(path, body);
}

export async function getUserNotifications() {
  const path = `/api/notifications/user`;
  return await RestApiClient.get(path);
}

export async function markNotificationsAsSeen(notifications) {
  const path = `/api/notifications/user/seen`;
  let body = notifications;
  return await RestApiClient.post(path, body);
}

export async function markNotificationsAsSelected(notifications) {
  const path = `/api/notifications/user/selected`;
  let body = notifications;
  return await RestApiClient.post(path, body);
}

export async function markNotificationsAsArchived(notifications) {
  const path = `/api/notifications/user/archived`;
  let body = notifications;
  return await RestApiClient.post(path, body);
}

export async function getSuggestionsForLatestAssessmentAtLocation(
  location,
  userId
) {
  const path = `/api/insights/latest/${location}/${userId}`;
  return await RestApiClient.get(path);
}

export async function updateSuggestionsIgnoredAt(ids, ignore) {
  const path = `/api/insights/update/ignore/${ignore}`;
  let body = ids;
  return await RestApiClient.post(path, body);
}

export async function getSuggestionsMissingEquipment(filter) {
  const path = `/api/insights/equipment/missing/${encodeURIComponent(
    JSON.stringify(filter)
  )}`;
  return await RestApiClient.get(path);
}

export async function loadPreviousDeskPlannerForLocation(location) {
  const path = `/api/assessments/previous-for-location/${location}`;
  return await RestApiClient.get(path);
}

export async function addAssessmentConsent(
  assessmentId,
  shareDataWithEmployer
) {
  const path = `/api/assessment/consent`;
  const body = {
    assessmentId,
    shareDataWithEmployer
  };
  return await RestApiClient.post(path, body);
}

export async function hasConsented(assessmentId) {
  const path = `/api/assessment/consent/${assessmentId}`;
  return await RestApiClient.get(path);
}

export async function getAssessmentInviteTemplate(type) {
  const path = `/api/assessment/invite/template?type=${type}`;
  return await RestApiClient.get(path);
}

export async function postAssessmentInviteTemplate(type, content) {
  const path = "/api/assessment/invite/template";
  const body = {
    content: content,
    type: type
  };
  return await RestApiClient.post(path, body);
}

export async function putAssessmentInviteTemplate(id, type, content) {
  const path = `/api/assessment/invite/template/${id}`;
  const body = {
    content: content,
    type: type
  };
  return await RestApiClient.put(path, body);
}

export async function deleteAssessmentInviteTemplate(id) {
  const path = `/api/assessment/invite/template/${id}`;
  return await RestApiClient.delete(path);
}
export async function getLatestRecommendationInteractions(assessmentId) {
  const path = `/api/v1/assessments/${assessmentId}/recommendations/interactions/latest`;
  return await RestApiClient.get(path);
}

export async function updateTeamFeatureFlag(feature, enabled) {
  const path = "/api/feature/team";
  const body = {
    featureName: feature,
    enabled: enabled
  };
  return await RestApiClient.post(path, body);
}

export async function addRecommendationInteraction(
  assessmentId,
  recommendationName,
  interactionType
) {
  const path = `/api/v1/assessments/${assessmentId}/recommendations/${recommendationName}/interactions/${interactionType}`;
  return await RestApiClient.post(path);
}

export async function getFilteredUserAssessmentHistory(
  query,
  settings,
  { signal } = {}
) {
  const path = "/api/assessment-history/v1";
  return await RestApiClient.postWithSignal(
    path,
    { query: query, searchSettings: settings },
    signal
  );
}

export async function giveAssistantInteractionFeedback(
  interactionId,
  feedback
) {
  const path = `/api/assistant/feedback/${interactionId}`;
  const body = feedback;
  return await RestApiClient.post(path, body);
}

export async function sendAssistantFeedback(interactionId, liked) {
  const path = `/api/assistant/feedback/${interactionId}/${liked}`;
  return await RestApiClient.post(path);
}

export async function track(name, metadata) {
  const path = `/api/user/event`;
  const body = {
    name,
    metadata
  };
  return await RestApiClient.post(path, body);
}

export async function getSimsFromImage(assessmentId, formData, signal) {
  const path = `/api/assessments/${assessmentId}/sims-classification`;
  return await RestApiClient.postWithSignal(path, formData, signal);
}

export async function getUploadedSimsImage(assessmentId) {
  const path = `/api/assessments/${assessmentId}/sims-classification-image`;
  return await RestApiClient.get(path);
}

export async function uploadSimsImage(assessmentId, image) {
  const path = `/api/assessments/${assessmentId}/sims-classification-image`;
  return await RestApiClient.post(path, image);
}

export async function sendMagicLink() {
  const path = `/api/user/magic-link`;
  return await RestApiClient.post(path);
}

export async function sendMagicLinkInBulk(emails) {
  const path = `/api/user/magic-link/bulk`;
  let body = emails;
  return await RestApiClient.post(path, body);
}

export async function getAssessmentDetails(assessmentId) {
  const path = `/api/assessment/${assessmentId}/details`;
  return await RestApiClient.get(path);
}

export async function sendMagicLinkLogin(email) {
  const path = `/api/user/magic-link/login`;
  let body = email;
  return await RestApiClient.post(path, body);
}

export async function getExternalNotificationProvider() {
  const path = `/api/team/external-notification-provider`;
  return await RestApiClient.get(path);
}

export async function registerUsersForExternalNotificationProvider(body) {
  const path = `/api/dev/register-users-for-external-notification-provider`;
  return await RestApiClient.post(path, body);
}

export async function forceSendMessageToSelf(body) {
  const path = `/api/dev/force-send-message-self`
  return await RestApiClient.post(path, body);
}