import BurnoutScores from "@/services/burnout/burnout-scores.js";
import { getResilienceRatingColors } from "@/components/dashboard/cards/average-scores/colours.js";
import { getAdminResilienceDescriptor } from "@/components/dashboard/cards/average-scores/rating.js";

export const ResilienceCard = {
  icon: "resilience.svg",
  title: "resilience",
  score: score => BurnoutScores.getResilienceAsPercentage(score),
  ratingObj: score => {
    const resilience = BurnoutScores.getResilienceFromScore(score, "overall");
    const colors = getResilienceRatingColors(resilience);

    return {
      backgroundColor: colors.background,
      text: getAdminResilienceDescriptor(score),
      textColor: colors.text
    };
  }
};
