import { getPainRecommendations } from "./pain-recommendations.js";
import { getSymptomsRecommendations } from "./symptoms-recommendations.js";
import { getMuscleLossRecommendations } from "./muscle-loss-recommendations.js";

const recommendationMap = {
  pain: getPainRecommendations,
  symptoms: getSymptomsRecommendations,
  muscleLoss: getMuscleLossRecommendations
};

export const RecommendationSections = {
  PAIN: "pain",
  SYMPTOMS: "symptoms",
  MUSCLE_LOSS: "muscleLoss"
};

export const getRecommendationsByType = (type, results) => {
  const getRecommendations = recommendationMap[type];

  if (!getRecommendations) {
    throw new Error(`Unknown recommendation type: ${type}`);
  }

  return getRecommendations(results);
};

export const getAllRecommendations = results => ({
  pain: getPainRecommendations(results),
  symptoms: getSymptomsRecommendations(results),
  muscleLoss: getMuscleLossRecommendations(results)
});
