<template>
  <svg
    width="641"
    height="637"
    viewBox="0 0 641 637"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.5">
      <g filter="url(#filter0_f_3275_80044)">
        <ellipse
          cx="257.719"
          cy="254.869"
          rx="4.63229"
          ry="2.55723"
          transform="rotate(24.1801 257.719 254.869)"
          fill="#E71E1E"
        />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_f_3275_80044"
        x="249.746"
        y="248.242"
        width="15.9476"
        height="13.2543"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="1.80966"
          result="effect1_foregroundBlur_3275_80044"
        />
      </filter>
    </defs>
  </svg>
</template>
<script>
export default {
  name: "HotFlashesNightSweatingOverlay"
};
</script>
