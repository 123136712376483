import Components from "@/assets/json/DeskAssessment/ElevationPlannerComponents.json";
import Desks from "@/components/deskassessment/desk-planner-desk-types.js";
const DefaultWidth = 700;
const DefaultHeight = 600;
function getDimensionsForWorkArea(setupResults) {
  const { deskType, workarea } = setupResults;
  if (workarea === 4) {
    return PhoneBoothWorkspaceDimensions;
  }
  const desk =
    Object.values(Desks).find(d => !!d.value && d.value === deskType) ||
    Desks.REGULAR;
  return { ...DeskWorkspaceDimensions, perimeter: desk.perimeter };
}

const DeskWorkspaceDimensions = {
  tableSurfaceLevel: 300,
  floorLevel: 500
};

const PhoneBoothWorkspaceDimensions = {
  tableSurfaceLevel: 330,
  floorLevel: 550,
  perimeter: Desks.PHONE_BOOTH.perimeter
};

class ElevationGeometryMapper {
  stemThickness = 10;
  stemHeight = 25;
  chamfer = 10;
  baseWidth = 70;
  baseThickness = 15;
  assumedScreenHeightcm = 33;
  eyeLevelBoundary = 20;
  screenIds = [
    "laptop",
    "laptopWithStand",
    "computerScreen",
    "computerScreenUltraWide",
    "computerScreenVertical"
  ];

  get tableSurfaceLevel() {
    return this._tableSurfaceLevel;
  }

  get floorLevel() {
    return this._floorLevel;
  }

  get defaultEyeLevel() {
    var laptop = Components.find(x => x.id === "laptop");
    return this.tableSurfaceLevel - laptop.height * 1.5;
  }

  get cmToPx() {
    var screenForScale = Components.find(x => x.id === "computerScreen");
    return screenForScale.height / this.assumedScreenHeightcm;
  }

  constructor(workspaceDimensions) {
    this.perimeter = workspaceDimensions?.perimeter;
    this._tableSurfaceLevel = workspaceDimensions?.tableSurfaceLevel;
    this._floorLevel = workspaceDimensions.floorLevel;
  }

  mapDeskItemToElevation(deskItem, suffix) {
    var image = Components.find(x => x.id === deskItem.id);
    var mappedItem = { ...image };
    mappedItem.key = `${deskItem.key}${suffix}`;
    mappedItem.version = deskItem.version;
    mappedItem.offsetX = mappedItem.width / 2;
    mappedItem.offsetY = mappedItem.height / 2;
    mappedItem.x = deskItem.x;
    mappedItem.y = this.getYCoordinate(mappedItem, deskItem);
    mappedItem.planY = deskItem.y;
    return mappedItem;
  }

  getYCoordinate(image, deskItem) {
    // Saved as z coordinate here as saving in 3D space
    if (deskItem.z) {
      return deskItem.z;
    }
    var y = this.tableSurfaceLevel - image.offsetY;
    if (deskItem.id.includes("computerScreen")) {
      y -= this.baseThickness + this.stemHeight;
    } else if (deskItem.id === "window") {
      y -= 100;
    } else if (this.putOnFloor(deskItem, image)) {
      y = this.floorLevel - image.offsetY;
    }
    return y;
  }

  putOnFloor(deskItem, image) {
    var seating = [
      "chairNoArmrest",
      "chairWithArmrest",
      "stool",
      "gymBall",
      "beanBag",
      "wheelchair",
      "saddleChair",
      "kneelingChair"
    ];
    var excludedIds = ["laptop", "laptopWithStand", "window", "computerScreen"];
    if (excludedIds.includes(deskItem.id)) {
      return false;
    }
    if (seating.includes(deskItem.id)) {
      return true;
    }

    var x = deskItem.x;
    if (image.baseOffsetX) {
      x -= image.baseOffsetX;
    }
    const onTable = this.isPointInPolygon({ x, y: deskItem.y }, this.perimeter);
    return !onTable;
  }

  // Implements ray-casting algorithm, i.e. counting how many times a horizontal
  // line from the right intersects the edges of a polygon, defined by a series of points. If the
  // count is odd, the point is inside the polygon, if even it must be outside.
  isPointInPolygon(point, polygon) {
    const { x, y } = point;
    let isInside = false;

    for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
      const p1 = polygon[i];
      const p2 = polygon[j];

      // If y coordinate within edge y bounds then a horizontal line would intersect the edge.
      // If the x coordinate is less than the x intercept point, the horizontal ray intersects the edge.
      const intersect =
        p1.y > y !== p2.y > y &&
        x < ((p2.x - p1.x) * (y - p1.y)) / (p2.y - p1.y) + p1.x;
      if (intersect) isInside = !isInside;
    }

    return isInside;
  }

  mapStandToScreen(screen) {
    return {
      key: `${screen.key}_stand`,
      x: screen.x - this.baseWidth / 2,
      y: this.tableSurfaceLevel,
      width: screen.width,
      planY: screen.planY - 1
    };
  }

  mapStandToLaptop(laptop) {
    return {
      key: `${laptop.key}_stand`,
      x: laptop.x - laptop.width / 2,
      y: this.tableSurfaceLevel,
      width: laptop.width,
      planY: laptop.planY - 1
    };
  }

  drawScreenStand(context, shape, screen) {
    const { startX, startY } = shape.attrs;
    const { y, offsetY } = screen.attrs;

    context.beginPath();
    context.moveTo(startX, startY);
    var topOfStand = y + offsetY;
    var coordinates = this.getStandCoordinates(startX, startY, topOfStand);
    coordinates.forEach(c => {
      context.lineTo(c.x, c.y);
    });
    context.closePath();
    context.fillStrokeShape(shape);
  }

  getStandCoordinates(x, y, topOfStand) {
    var upperBaseWidth = (this.baseWidth - this.baseThickness) / 2;
    return [
      { x: x + this.baseWidth, y: y },
      { x: x + this.baseWidth - this.chamfer, y: y - this.stemThickness },
      {
        x: x + this.baseWidth - upperBaseWidth,
        y: y - this.stemThickness
      },
      {
        x: x + this.baseWidth - upperBaseWidth,
        y: topOfStand
      },
      {
        x: x + upperBaseWidth,
        y: topOfStand
      },
      {
        x: x + upperBaseWidth,
        y: y - this.stemThickness
      },
      {
        x: x + this.chamfer,
        y: y - this.stemThickness
      }
    ];
  }

  drawLaptopStand(context, shape, laptop) {
    const { startX, startY } = shape.attrs;
    const { y, width, height } = laptop.attrs;
    const laptopY = y + height / 2;
    context.beginPath();
    context.moveTo(startX, startY);
    context.lineTo(startX + this.stemThickness, startY);
    context.lineTo(startX + this.stemThickness, laptopY);
    context.lineTo(startX + width - this.stemThickness, laptopY);
    context.lineTo(startX + width - this.stemThickness, startY);
    context.lineTo(startX + width, startY);
    context.lineTo(startX + width, laptopY);
    context.lineTo(startX, laptopY);
    context.closePath();
    context.fillStrokeShape(shape);
  }

  getWebcamCoordinates(screen) {
    return {
      x: screen.x,
      y: screen.y - screen.height / 2
    };
  }

  heightFromWebcam(results) {
    var angle = results.screenHeightAngle * (Math.PI / 180);
    return this.cmToPx * results.distanceFromScreen * Math.tan(angle);
  }

  getEyeLevel(results, screen) {
    var heightToWebcam = this.heightFromWebcam(results);
    var topOfScreen = screen.y - screen.offsetY;
    var eyeLevel = Math.round(topOfScreen - heightToWebcam);
    return this.getLimitedEyeLevel(eyeLevel);
  }

  getLimitedEyeLevel(level) {
    if (level <= this.eyeLevelBoundary) {
      return this.eyeLevelBoundary;
    } else if (level >= this.tableSurfaceLevel - this.eyeLevelBoundary) {
      return this.tableSurfaceLevel - this.eyeLevelBoundary;
    }
    return level;
  }

  eyeLevelAtUpperLimit(results, screen) {
    var eyeLine = this.getEyeLevel(results, screen.attrs);
    var height = screen.attrs.y - screen.attrs.offsetY - eyeLine;
    return height < Math.round(this.heightFromWebcam(results));
  }

  getDraggedEyeLevel(boundingRect) {
    var y = boundingRect.attrs.y + boundingRect.attrs.height / 2;
    if (y > this.tableSurfaceLevel) {
      y = this.tableSurfaceLevel;
    } else if (y < 50) {
      y = 50;
    }
    return y;
  }

  getHeightDifferences(deskItems, eyeLevel) {
    var screens = deskItems.filter(
      x => this.screenIds.includes(x.id) && !x.elevationWebcam
    );
    return screens.map(x => {
      var image = Components.find(c => c.id === x.id);
      return eyeLevel - (x.z - image.height / 2);
    });
  }

  getMaxCmHeightDifference(deskItems, eyeLevel) {
    if (eyeLevel === 0) {
      return null;
    }
    var diffs = this.getHeightDifferences(deskItems, eyeLevel);
    var absoluteDiffs = diffs.map(x => Math.abs(x));
    var maxIndex = absoluteDiffs.indexOf(Math.max(...absoluteDiffs));
    return Math.round(diffs[maxIndex] * (1 / this.cmToPx));
  }

  isUsersScreenHeightOptimal(deskItems, eyeLevel) {
    var screens = deskItems.filter(
      x => this.screenIds.includes(x.id) && !x.elevationWebcam
    );
    return screens.every(x => {
      var distance = this.getScreenHeightFromOptimalRange(x, eyeLevel);
      return distance === 0;
    });
  }

  // negative value = eye level is above screens optimal range
  // positive value = eye level is below screens optimal range
  // 0 = eye level is in the optimal range
  getScreenHeightFromOptimalRange(deskItem, eyeLevel) {
    var image = Components.find(component => component.id === deskItem.id);
    var topOfScreen = deskItem.z - image.height / 2;

    // We give a tolerance of 20% of the screen height below the screen
    // and 10% above the screen as the ideal is 5-10cm below the top of screen
    var lowerBound = topOfScreen + image.eyeLevelToleranceInPx;
    var upperBound = topOfScreen - image.eyeLevelToleranceInPx / 2;
    if (eyeLevel < upperBound) {
      return eyeLevel - upperBound;
    } else if (eyeLevel > lowerBound) {
      return eyeLevel - lowerBound;
    }
    return 0;
  }

  mapItemsToDifferenceFromAcceptableRange(deskItems, eyeLevel) {
    var screens = deskItems.filter(x => this.screenIds.includes(x.id));
    return screens.map(x => {
      return {
        id: x.id,
        distanceFromRange: this.getScreenHeightFromOptimalRange(x, eyeLevel),
        elevationWebcam: x.elevationWebcam
      };
    });
  }
}

export {
  ElevationGeometryMapper,
  DefaultWidth,
  DefaultHeight,
  DeskWorkspaceDimensions,
  PhoneBoothWorkspaceDimensions,
  getDimensionsForWorkArea
};
