<template>
  <v-dialog persistent max-width="600px" v-model="value">
    <v-card>
      <div class="pa-6 pa-md-8">
        <h1 class="text-h5 mb-4">
          {{
            $t("incompleteDialog.title", {
              type: this.assessmentTypeText
            })
          }}
        </h1>
        <p class="text-body-1 mb-6">
          {{ $t("incompleteDialog.body") }}
        </p>

        <div class="d-flex flex-column-reverse flex-md-row" style="gap: 1rem">
          <button
            class="vfl-button-primary-outline"
            @click.once="startNew"
            data-test-id="incomplete-assessment-dialog-start-new"
          >
            {{ $t("incompleteDialog.buttons.startNew") }}
          </button>
          <button
            class="vfl-button-primary mr-2"
            @click.once="resume"
            data-test-id="incomplete-assessment-dialog-resume"
          >
            {{ $t("incompleteDialog.buttons.resume") }}
          </button>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { assessmentRoutes } from "@/router/route-constants.js";

export default {
  name: "IncompleteAssessmentDialog",
  props: {
    assessmentId: String,
    assessmentType: String,
    value: Boolean
  },
  data() {
    return {
      isOpen: false,
      startingExisting: false
    };
  },
  computed: {
    assessmentTypeText() {
      const root = this.$t("assessmentTypes");
      const type = this.assessmentType
        ? root[this.assessmentType]
        : root.assessment;

      return type.toLowerCase();
    }
  },
  methods: {
    startNew() {
      if (this.startingExisting) {
        return;
      }
      this.$emit("startNew");
      this.$emit("input", false);
    },
    async resume() {
      if (this.startingExisting) {
        return;
      }
      this.startingExisting = true;
      try {
        await this.$router.push({
          name: assessmentRoutes[this.assessmentType],
          params: {
            assessmentId: this.assessmentId
          },
          replace: true
        });
      } finally {
        this.startingExisting = false;
      }
    }
  }
};
</script>
