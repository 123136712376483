<template>
  <section-header
    :title="$t('mskHealthCulture.title')"
    :tooltipMessage="$t('mskHealthCulture.tooltip')"
  >
    <template #actionButton v-if="!loading">
      <dashboard-card-download-button
        class="ml-2"
        :screenReader="$t('mskHealthCulture.download.screenReader')"
        downloadElement="msk-health-culture-container"
        :imageName="$t('mskHealthCulture.download.imageName')"
        eventName="MSK health culture dashboard section downloaded"
      />
    </template>
  </section-header>
</template>

<script>
import SectionHeader from "@/components/dashboard/cards/common/DashboardSectionHeader.vue";
import DashboardCardDownloadButton from "@/components/dashboard/cards/common/DashboardCardDownloadButton.vue";

export default {
  name: "MskHealthCultureHeader",
  components: {
    DashboardCardDownloadButton,
    SectionHeader
  },
  props: {
    loading: Boolean
  }
};
</script>
