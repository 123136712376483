﻿import { LinkTypes } from "@/services/recommendations/link-types.js";
import sentry from "@/sentry.js";
import answers from "@/services/assessment/physical-labour/answers.json";
import BodyAreas from "@/components/painareas/body-areas.json";
import PainService from "@/services/assessment/pain/pain-assessment-service.js";
import Cards from "./RecommendationCards.json";
import { injectHighestPainAreaIntoLocale } from "./recommendation-text-helper.js";

export default function getHealthAndSafetyRecommendations(results) {
  const recommendations = getAllRecommendations();
  const cardsToShow = recommendations.filter(r => {
    try {
      return r.showAnswer(results);
    } catch (e) {
      sentry.captureException(e);
      return false;
    }
  });
  return cardsToShow.slice(0, 7);
}

function selectedAsJobActivity(results, activity) {
  return results.reba.chosenJobActivity === activity;
}

function getBodyAreaValues(areas) {
  return Object.entries(BodyAreas)
    .filter(([_, value]) => areas.some(a => a === value.value))
    .map(([area, _]) => area);
}

function showContactPadCard(results) {
  if (!results.jobNature.contactStressBodyAreas) {
    return false;
  }
  const contactAreas = getBodyAreaValues(
    results.jobNature.contactStressBodyAreas
  );
  return contactAreas.some(contactArea =>
    PainService.hasPainArea(results, contactArea)
  );
}

function contactStressText(results, localePath) {
  if (!results.jobNature.contactStressBodyAreas) {
    sentry.captureMessage(
      `Tried to get contact stress text when no body area selected`,
      "error"
    );
  }
  const contactAreas = getBodyAreaValues(
    results.jobNature.contactStressBodyAreas
  );
  const painAreasWithContact = contactAreas.filter(contactArea =>
    PainService.hasPainArea(results, contactArea)
  );
  return injectHighestPainAreaIntoLocale(
    results,
    localePath,
    painAreasWithContact
  );
}

function showDistributeWeightEvenlyCard(results) {
  const matchesRiskyTask =
    results.reba.chosenJobActivity ===
      answers.jobNature.jobActivities.heavyLifting ||
    results.reba.chosenJobActivity === answers.jobNature.jobActivities.carrying;
  const isOffBalance =
    results.reba.legPosition === answers.reba.legPosition.oneLegRaised ||
    results.reba.suddenMovementsOrHighForce ===
      answers.reba.suddenMovementsOrHighForce.yes;
  return matchesRiskyTask && isOffBalance;
}

function showGripAidsCard(results) {
  const highCoupling =
    results.reba.coupling === answers.reba.coupling.difficult ||
    results.reba.coupling === answers.reba.coupling.veryDifficult;
  const notShowingAntiVibrationGlovesCard =
    !showAntiVibrationGlovesCard(results);
  return highCoupling && notShowingAntiVibrationGlovesCard;
}

function showUseAssistiveDeviceCard(results) {
  const hasRiskyTask = results.jobNature.jobActivities.some(
    activity =>
      activity === answers.jobNature.jobActivities.heavyLifting ||
      activity === answers.jobNature.jobActivities.carrying
  );
  const isDealingWithHeavyWeight =
    results.reba.loadWeight === answers.reba.loadWeight.heavy;

  const hasPain =
    PainService.hasPainArea(results, "back") ||
    PainService.hasPainArea(results, "shoulder");

  return hasRiskyTask && isDealingWithHeavyWeight && hasPain;
}

function showAntiVibrationGlovesCard(results) {
  const hasVibrationRisk =
    results.reba.chosenJobActivity ===
    answers.jobNature.jobActivities.vibration;
  const hasWristPain = PainService.hasPainArea(results, "wrist");
  return hasVibrationRisk && hasWristPain;
}

function showSeekHelpIfTooHeavyCard(results) {
  const choseRiskyTask =
    results.reba.chosenJobActivity ===
      answers.reba.chosenJobActivity.heavyLifting ||
    results.reba.chosenJobActivity ===
      answers.reba.chosenJobActivity.carrying ||
    results.reba.chosenJobActivity ===
      answers.reba.chosenJobActivity.intensePhysicalEffort ||
    results.reba.chosenJobActivity ===
      answers.reba.chosenJobActivity.pushOrPull;
  const isDealingWithHeavyWeight =
    results.reba.loadWeight === answers.reba.loadWeight.heavy;

  const hasPain =
    PainService.hasPainArea(results, "back") ||
    PainService.hasPainArea(results, "shoulder");

  return choseRiskyTask && isDealingWithHeavyWeight && hasPain;
}

function getAllRecommendations() {
  return [
    {
      id: "physical_labour_equipment_contact_pad",
      showAnswer: results => showContactPadCard(results),
      json: Cards.contactStress,
      linkType: () => LinkTypes.REGULAR,
      heading: results =>
        contactStressText(results, Cards.contactStress.heading),
      explanation: results =>
        contactStressText(results, Cards.contactStress.explanation)
    },
    {
      id: "physical_labour_equipment_anti_vibration_gloves",
      showAnswer: results => showAntiVibrationGlovesCard(results),
      json: Cards.vibrationGloves,
      linkType: () => LinkTypes.REGULAR
    },
    {
      id: "physical_labour_equipment_grip_aids",
      showAnswer: results => showGripAidsCard(results),
      json: Cards.gripAids,
      linkType: () => LinkTypes.REGULAR
    },
    {
      id: "physical_labour_equipment_supportive_footwear",
      showAnswer: results => true,
      json: Cards.supportiveFootwear,
      linkType: () => LinkTypes.REGULAR
    },
    {
      id: "physical_labour_equipment_use_assistive_device",
      showAnswer: results => showUseAssistiveDeviceCard(results),
      json: Cards.assistiveDevice,
      linkType: () => LinkTypes.REGULAR
    },
    {
      id: "physical_labour_seek_help_if_too_heavy",
      showAnswer: results => showSeekHelpIfTooHeavyCard(results),
      json: Cards.seekHelpCarrying,
      linkType: () => LinkTypes.REGULAR
    },
    {
      id: "physical_labour_best_practice_lifting",
      showAnswer: results =>
        selectedAsJobActivity(
          results,
          answers.reba.chosenJobActivity.heavyLifting
        ),
      json: Cards.lifting,
      linkType: () => LinkTypes.REGULAR
    },
    {
      id: "physical_labour_best_practice_carrying",
      showAnswer: results =>
        selectedAsJobActivity(results, answers.reba.chosenJobActivity.carrying),
      json: Cards.carrying,
      linkType: () => LinkTypes.REGULAR
    },
    {
      id: "physical_labour_best_practice_pushing_pulling",
      showAnswer: results =>
        selectedAsJobActivity(
          results,
          answers.reba.chosenJobActivity.pushOrPull
        ),
      json: Cards.pushOrPull,
      linkType: () => LinkTypes.REGULAR
    },
    {
      id: "physical_labour_intense_physical_effort",
      showAnswer: results =>
        selectedAsJobActivity(
          results,
          answers.reba.chosenJobActivity.intensePhysicalEffort
        ),
      json: Cards.intensePhysicalEffort,
      linkType: () => LinkTypes.REGULAR
    },
    {
      id: "physical_labour_overhead_work",
      showAnswer: results =>
        selectedAsJobActivity(
          results,
          answers.reba.chosenJobActivity.overheadWork
        ),
      json: Cards.overheadWork,
      linkType: () => LinkTypes.REGULAR
    },
    {
      id: "physical_labour_gripping",
      showAnswer: results =>
        selectedAsJobActivity(results, answers.reba.chosenJobActivity.gripping),
      json: Cards.gripping,
      linkType: () => LinkTypes.REGULAR
    },
    {
      id: "physical_labour_vibration",
      showAnswer: results =>
        selectedAsJobActivity(
          results,
          answers.reba.chosenJobActivity.vibration
        ),
      json: Cards.vibration,
      linkType: () => LinkTypes.REGULAR
    },
    {
      id: "physical_labour_distribute_weight_evenly",
      showAnswer: results => showDistributeWeightEvenlyCard(results),
      json: Cards.distributeWeight,
      linkType: () => LinkTypes.REGULAR
    },
    {
      id: "physical_labour_task_rotation",
      showAnswer: results => true,
      json: Cards.rotateTask,
      linkType: () => LinkTypes.REGULAR
    },
    {
      id: "physical_labour_maintain_your_tool",
      showAnswer: results =>
        selectedAsJobActivity(
          results,
          answers.reba.chosenJobActivity.vibration
        ),
      json: Cards.maintainTool,
      linkType: () => LinkTypes.REGULAR
    }
  ];
}
