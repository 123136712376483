export const IDS = {
  LAPTOP: "laptopCard",
  STRAIN: "strainCard",
  KEYBOARD: "keyboardCard",
  CHAIR: "chairCard",
  SCREEN_HEIGHT: "screenHeightCard",
  SCREEN_DISTANCE: "screenDistanceCard",
  STANDING_BREAKS: "noStandingDeskCard",
  SHOULDER_TO_EAR: "shoulderToEarCard",
  SHOULDER_HEIGHT: "shoulderHeightCard",
  WORK_FROM_HOME: "workFromHomeCard",
  HOURS: "hoursCard",
  BREAKS: "breaksCard",
  STANDING_DESK_CARD: "standingDeskCard",
  TEMPERATURE: "temperatureCard",
  NOISE: "noiseCard",
  DESK_HEIGHT: "deskHeightCard",
  ALTERNATIVE_ERGONOMIC_SEAT: "alternativeErgonomicSeatCard",
  PETS: "petsCard"
};
