import {
  STAGE_MAPPING,
  STAGES
} from "@/components/assessment/menopause/report/constants/index.js";
import { capitaliseFirstLetter } from "@/components/assessment/menopause/report/helpers.js";
import i18n from "@/plugins/i18n.js";

const formatSymptomsList = symptoms => {
  const connector = i18n.t("form.generator.connector");

  if (!Array.isArray(symptoms) || symptoms.length === 0) return "";
  if (symptoms.length === 1) return symptoms[0];
  if (symptoms.length === 2) {
    return `${symptoms[0]} ${connector} ${symptoms[1]}`;
  }

  return `${symptoms.slice(0, -1).join(", ")}, ${connector} ${
    symptoms[symptoms.length - 1]
  }`;
};

const symptomToText = symptomId => {
  const symptomMap = {
    1: "hot flashes",
    2: "fatigue",
    3: "mood changes",
    4: "difficulty concentrating",
    5: "memory problems",
    6: "waking up at night",
    7: "reduced strength"
  };
  return symptomMap[symptomId] || "";
};

export const SYMPTOMS_CONTENT = {
  title: "Dealing with your other symptoms",
  getContent: results => {
    const stageText = STAGE_MAPPING[results.stage?.stage] || STAGES.MENOPAUSE;
    const capitalizedStage = capitaliseFirstLetter(stageText);

    const symptoms = results.symptoms?.symptoms || [];

    const symptomMap = {
      1: "hotFlashes",
      2: "fatigue",
      3: "moodChanges",
      4: "concentration",
      5: "memory",
      6: "wakingUp",
      7: "reducedStrength",
      98: "other"
    };

    const filteredSymptoms = symptoms
      .filter(symptomId => symptomId !== 98 && symptomMap[symptomId])
      .slice(0, 3)
      .map(symptomId => symptomToText(symptomId));

    const formattedSymptoms = formatSymptomsList(filteredSymptoms);

    return [
      `${capitalizedStage} can bring frustrating symptoms like ${formattedSymptoms}, but try to see them as signs your body is recalibrating, finding a new balance. Each symptom, while uncomfortable, is part of this transition to a next phase of life. Many women discover that embracing this shift brings greater self-awareness and confidence in caring for their health.`,
      "There are quick ways to make this phase smoother and more positive."
    ];
  }
};
