<template>
  <button @click="handleCopy">
    <v-icon size="18px">{{
      isCopied ? "mdi-check" : "mdi-content-copy"
    }}</v-icon>
  </button>
</template>

<script>
export default {
  name: "CopyButton",
  props: {
    textToCopy: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isCopied: false,
      isDisabled: false
    };
  },
  methods: {
    handleCopy() {
      if (this.isDisabled) return;
      let vm = this;
      navigator.clipboard
        .writeText(this.textToCopy)
        .then(() => {
          this.isCopied = true;
          this.isDisabled = true;

          setTimeout(() => {
            this.isCopied = false;
            this.isDisabled = false;
          }, 3000);
        })
        .catch(err => {
          vm.$logger.captureException(err);
        });
    }
  }
};
</script>

<style lang="scss" scoped>
button {
  transition: transform 0.125s ease-out;
  &:hover {
    transform: scale(1.1);
  }
}
</style>
