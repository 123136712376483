<template>
  <div class="message-avatar d-flex">
    <avatar-icon
      :text="isAdmin ? 'admin' : 'employee'"
      :fallback-text="isAdmin ? 'admin' : 'end-user'"
      :size="21"
    />
  </div>
</template>

<script>
import AvatarIcon from "@/components/common/AvatarIcon.vue";

export default {
  name: "MessagingMessageAvatar",
  components: { AvatarIcon },
  props: {
    isAdmin: Boolean
  }
};
</script>

<style scoped>
.message-avatar {
}
</style>
