<template>
  <v-navigation-drawer
    :value="isOpen"
    @input="handleInput"
    @transitionend="onTransitionEnd"
    fixed
    right
    :width="componentWidth"
    :hide-overlay="!hasOverlay"
    stateless
    style="z-index: 22"
  >
    <div class="navigation-drawer-container">
      <div class="navigation-drawer-left"><slot name="left"></slot></div>

      <div class="navigation-drawer-right">
        <div class="navigation-drawer-header d-flex align-end px-1">
          <button
            v-if="showBackButton"
            @click="goBack"
            class="vfl-button-primary-ghost is-icon-only back-button mr-2"
          >
            <v-icon>mdi-arrow-left</v-icon>
          </button>
          <h1 v-if="title" class="text-body-1 text-body-bold pl-3">
            {{ title }}
          </h1>
          <button
            @click="close"
            class="vfl-button-primary-ghost is-icon-only ml-auto"
          >
            <v-icon>mdi-close</v-icon>
          </button>
        </div>

        <div
          class="pa-4"
          :class="{ 'pt-0': !title }"
          style="height: calc(-24px + 100%)"
        >
          <slot name="right"></slot>
        </div>
      </div>
    </div>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: "VflSidepanel",
  props: {
    isOpen: Boolean,
    width: {
      type: String,
      default: "420px"
    },
    hasOverlay: {
      type: Boolean,
      default: false
    },
    showBackButton: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ""
    }
  },
  computed: {
    componentWidth() {
      if (this.$vuetify.breakpoint.xs) {
        return "100%";
      }

      return this.width;
    }
  },
  methods: {
    handleInput(value) {
      if (this.isOpen !== value) {
        this.$emit("update:is-open", value);
      }
    },
    close() {
      this.$emit("update:is-open", false);
    },
    onTransitionEnd() {
      if (!this.isOpen) {
        this.$emit("onCloseComplete");
      }
    },
    goBack() {
      this.$emit("onBackClick");
    }
  }
};
</script>
<style lang="scss" scoped>
.v-navigation-drawer {
  overflow: visible;
}

.navigation-drawer-container {
  height: 100%;

  &:has(.navigation-drawer-left:not(:empty)) {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  }
}

.navigation-drawer-left {
  &:not(:empty) {
    border-right: 1px solid var(--vfl-border-color);
  }
}

.navigation-drawer-right {
  height: 100%;
  overflow: hidden;
}
</style>
