<template>
  <div class="mr-4 score-cards-container">
    <div
      class="score-cards-scroller vfl-scrollbar-hidden"
      :class="{
        'has-gradient-left': !isScrolledFarLeft,
        'has-gradient-right': !isScrolledFarRight
      }"
      ref="scrollContentAverageScores"
    >
      <average-score-card v-for="card in cards" :key="card.key" v-bind="card" />
    </div>
    <average-score-cards-nav
      @onPrev="scrollLeft"
      @onNext="scrollRight"
      class="scores-nav"
      :disablePrev="isScrolledFarLeft"
      :disableNext="isScrolledFarRight"
    />
  </div>
</template>

<script>
import AverageScoreCard from "./AverageScoreCard.vue";
import AverageScoreCardsNav from "./AverageScoreCardsNav.vue";
import debounce from "lodash/debounce";

export default {
  name: "AverageScoreCards",
  components: {
    AverageScoreCard,
    AverageScoreCardsNav
  },
  props: {
    cards: Array
  },
  data() {
    return { isScrolledFarLeft: true, isScrolledFarRight: false };
  },
  mounted() {
    if (this.$refs?.scrollContentAverageScores) {
      this.$refs.scrollContentAverageScores.addEventListener(
        "scroll",
        this.handleScroll
      );
    }

    window.addEventListener("resize", this.debouncedResizeListener);

    this.updateNavigationButtonVisibility();
  },
  beforeDestroy() {
    if (this.$refs?.scrollContentAverageScores) {
      this.$refs.scrollContentAverageScores.removeEventListener(
        "scroll",
        this.handleScroll
      );
    }
    window.removeEventListener("resize", this.debouncedResizeListener);
  },
  methods: {
    handleScroll() {
      this.updateNavigationButtonVisibility();
    },
    updateNavigationButtonVisibility() {
      const container = this.$refs.scrollContentAverageScores;
      if (!container) return;

      this.isScrolledFarRight =
        container.scrollLeft === container.scrollWidth - container.clientWidth;

      this.isScrolledFarLeft = container.scrollLeft === 0;
    },
    scrollLeft() {
      const container = this.$refs.scrollContentAverageScores;

      if (container) {
        container.scrollLeft -= window.innerWidth;

        this.updateNavigationButtonVisibility();
      }
    },
    scrollRight() {
      const container = this.$refs.scrollContentAverageScores;

      if (container) {
        container.scrollLeft += window.innerWidth;

        this.updateNavigationButtonVisibility();
      }
    },
    debouncedResizeListener: debounce(function () {
      this.handleResize();
    }, 200),
    handleResize() {
      this.updateNavigationButtonVisibility();
    }
  }
};
</script>

<style scoped lang="scss">
.score-cards-container {
  position: relative;

  &:hover {
    .scores-nav {
      opacity: 1;
    }
  }

  &.is-downloading-score-cards-image {
    // Prevents gradient in pseudoelements from rendering in captured canvas
    position: static;

    .score-cards-scroller {
      flex-wrap: wrap;
    }
  }
}

.score-cards-scroller {
  display: flex;
  gap: 1rem;

  overflow-x: auto;
  scroll-behavior: smooth;
  white-space: nowrap;

  > div {
    flex: 0 0 278px;
  }

  &:before,
  &:after {
    bottom: 0;
    content: "";
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 50px;
    z-index: 1;
  }

  &.has-gradient-left:before {
    background-image: linear-gradient(to left, rgba(0, 0, 0, 0), white);
    opacity: 1;
    left: -1px;
  }

  &.has-gradient-right:after {
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0), white);
    opacity: 1;
    right: -1px;
  }
}
</style>
