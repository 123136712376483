<template>
  <div class="tooltip-container">
    <v-tooltip
      bottom
      content-class="text-center"
      max-width="300"
      :disabled="!disabled"
      color="transparent"
    >
      <template v-slot:activator="{ on, attrs }">
        <button
          v-bind="attrs"
          v-on="on"
          class="text-body-1 text-left align-start"
          :class="{
            'is-disabled': disabled
          }"
          id="invite"
          @click.stop="onClick"
          style="font-weight: bold !important"
        >
          {{ $t("dashboardAssessmentCards.assessYourself") }}
        </button>
      </template>

      <vitrue-tooltip :message="$t(tooltipText)" />
    </v-tooltip>
    <vitrue-onboarding-tooltip
      v-if="showOnboardingTooltip"
      position="right"
      :text="$t('onboarding.adminCheckList.tooltips.selfAssessment')"
    />
  </div>
</template>

<script>
import VitrueTooltip from "@/components/common/VitrueTooltip.vue";
import VitrueOnboardingTooltip from "@/components/common/VitrueOnboardingTooltip.vue";

export default {
  name: "DashboardAssessmentCardTryButton",
  components: { VitrueTooltip, VitrueOnboardingTooltip },
  props: {
    disabled: Boolean,
    tooltipText: String,
    showOnboardingTooltip: Boolean
  },
  methods: {
    onClick() {
      if (this.disabled) return;

      this.$emit("onClick");
    }
  }
};
</script>
<style lang="scss" scoped>
button {
  white-space: nowrap;
  color: var(--v-vflPrimary-base);
}

.is-disabled {
  cursor: default;
  opacity: 0.6;
}

.tooltip-container {
  position: relative;

  ::v-deep .tooltiptext {
    margin-left: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    transform: none;
    width: max-content !important;
  }
}
</style>
