<template>
  <div class="notification-banner">
    <div class="content">
      <div class="top-row">
        <img src="/images/icons/share-icon.svg" width="24px" height="24px" />
        <p class="text-body-1 text-body-bold mb-0">
          {{ $t("employerShareDataCallout.heading") }}
        </p>
      </div>
      <div class="bottom-row">
        <p class="text-body-1 vflNeutralDark2--text mb-0">
          {{ $t("employerShareDataCallout.subHeading") }}
        </p>
        <button class="skip-button vfl-button-link" @click="onClick">
          {{ $t("buttons.skip") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import VflButton from "@/components/vitrue-foundation-library/VflButton.vue";
export default {
  name: "EmployerSharedDataCallout",
  components: { VflButton },
  methods: {
    onClick() {
      this.$emit("click");
    }
  }
};
</script>

<style scoped lang="scss">
.notification-banner {
  background-color: var(--v-vflBeigeLight1-base);
  border-radius: 1rem;
  padding: 1rem 1.5rem;
  border: 1px solid var(--v-vflOrange-base);
  container-type: inline-size;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.top-row {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.bottom-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
}

@container (max-width: 450px) {
  img {
    display: none;
  }

  .bottom-row {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
  }

  .skip-button {
    justify-self: end;
    align-self: flex-end;
  }
}
</style>
