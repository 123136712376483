<template>
  <user-profile-risks-template
    iconSrc="/images/officeIcon.svg"
    :title="$t('userProfile.risks.office')"
    :risks="officeRisks"
  />
</template>

<script>
import UserProfileRisksTemplate from "@/components/user-profile/UserProfileRisksTemplate.vue";
import { mapGetters } from "vuex";

export default {
  name: "UserProfileOfficeRisks",
  components: { UserProfileRisksTemplate },
  computed: {
    ...mapGetters("userProfile", ["officeRisks"])
  }
};
</script>
