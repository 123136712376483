<template>
  <div class="mx-auto">
    <svg ref="svgElement" width="50" height="50" viewBox="0 0 16 16">
      <path
        d="M0 9.12369C0.0403671 9.14771 0.0494413 9.18609 0.0538216 9.22945C0.1114 9.79885 0.2785 10.3389 0.480646 10.8718C0.759771 11.6088 1.14685 12.2867 1.62562 12.9085C1.96733 13.3519 2.35848 13.7525 2.78875 14.1175C3.39143 14.6289 4.05889 15.0311 4.77705 15.3524C5.20731 15.5449 5.65698 15.6807 6.11353 15.8048C7.06481 16.0629 8.03142 16.0058 8.99146 15.9624C9.58163 15.9359 10.1602 15.7605 10.7213 15.5537C11.5108 15.2623 12.2443 14.8667 12.9099 14.3578C13.3586 14.0146 13.7673 13.623 14.1343 13.1878C14.5073 12.7457 14.8271 12.2708 15.0987 11.7635C15.3757 11.2468 15.5928 10.7065 15.7524 10.1405C15.8535 9.78232 15.9036 9.41572 15.9846 9.05474C15.9937 9.0148 15.994 8.97206 15.994 8.93087C15.9943 8.30313 16.0115 7.67445 15.9862 7.04765C15.974 6.74813 15.8954 6.44986 15.8253 6.15377C15.7321 5.76003 15.6185 5.37471 15.4627 5.00218C15.2001 4.37444 14.8788 3.78101 14.4682 3.23314C14.0329 2.65189 13.536 2.13335 12.9646 1.68844C12.6714 1.46037 12.3576 1.25975 12.0396 1.06319C11.4901 0.723111 10.8931 0.507832 10.2907 0.300977C9.95964 0.187097 9.60792 0.145601 9.2637 0.0769614C8.68981 -0.0375423 8.11403 0.0158095 7.5392 0.000833534C7.15368 -0.00915044 6.77943 0.0725933 6.40142 0.136865C5.61004 0.271649 4.87249 0.555568 4.17405 0.931527C3.18115 1.46567 2.33063 2.18108 1.63939 3.07371C1.13246 3.72797 0.731608 4.44058 0.4456 5.22214C0.28507 5.66081 0.177114 6.10946 0.0644617 6.55937C0.0400543 6.65609 0.0991945 6.7709 0.000311852 6.85109C-1.90735e-06 7.60831 0 8.36615 0 9.12369ZM7.9898 1.02013C9.92334 1.03761 11.59 1.71995 12.9487 3.08307C14.2895 4.4281 14.9786 6.06859 14.9833 7.97772C14.988 9.92241 14.2851 11.5904 12.9133 12.9522C11.5687 14.2873 9.91708 14.9624 8.01296 14.9665C6.09601 14.9706 4.43283 14.2948 3.07757 12.9535C1.71168 11.6016 1.01105 9.94237 1.00604 8.00923C1.00103 6.09262 1.68758 4.44464 3.03064 3.09274C4.38621 1.72806 6.05157 1.03979 7.9898 1.02013Z"
        :fill="currentColor"
      />

      <path
        v-for="(pathData, index) in pathsData"
        :key="index"
        :d="pathData"
        :fill="currentColor"
        :fill-opacity="value === null && index === 0 ? 0 : 1"
      />
    </svg>
  </div>
</template>

<script>
import { interpolate } from "flubber";
import PainLevelColours from "@/assets/json/common/PainLevelColours.json";

const NEUTRAL_COLOR = "#eeeeee";
const NEUTRAL_PATH = "/images/animated-pain-emojis/painLevelNeutral.svg";
const ANIMATION_DURATION = 200;
const BASE_PATH = "/images/animated-pain-emojis/painLevel";
const NULL_VALUE = "null";

let SVG_PATHS = {
  0: {
    location: `${BASE_PATH}${1}.svg`,
    color: PainLevelColours["1"]
  }
};

for (let i = 1; i <= 10; i++) {
  SVG_PATHS[i] = {
    location: `${BASE_PATH}${i}.svg`,
    color: PainLevelColours[i.toString()]
  };
}

export default {
  name: "AnimatedEmoji",
  props: {
    value: Number
  },
  data() {
    return {
      pathsData: [],
      currentColor: NEUTRAL_COLOR,
      preloadedPaths: {},
      lastValue: null,
      isLoaded: false
    };
  },
  async mounted() {
    await this.preloadSvgPaths();
    this.isLoaded = true;
    this.lastValue = this.value;
    this.setSvg(null, this.value);
  },
  watch: {
    async value(newVal) {
      if (this.isLoaded) {
        this.setSvg(this.lastValue, newVal);
        this.lastValue = newVal;
      }
    }
  },
  methods: {
    async preloadSvgPaths() {
      for (const key in SVG_PATHS) {
        this.preloadedPaths[key] = await this.loadSvgPaths(
          SVG_PATHS[key].location
        );
      }

      this.preloadedPaths[NULL_VALUE] = await this.loadSvgPaths(NEUTRAL_PATH);
    },

    setSvg(oldVal, newVal) {
      if (!this.isLoaded) return;

      let oldPaths = this.getPathsForValue(oldVal);
      let newPaths = this.getPathsForValue(newVal);

      const oldPathsCopy = [...oldPaths];
      const newPathsCopy = [...newPaths];

      this.pathsData = oldPathsCopy;

      const animationFunc = this.morphPaths(oldPathsCopy, newPathsCopy);

      const fromColour = this.getColorForValue(oldVal);
      const toColour = this.getColorForValue(newVal);

      this.animateMorph(animationFunc, fromColour, toColour);
    },
    getPathsForValue(val) {
      if (val !== null && val !== undefined && this.preloadedPaths[val]) {
        return this.preloadedPaths[val];
      }
      return this.preloadedPaths[NULL_VALUE] || [];
    },
    getColorForValue(val) {
      if (val !== null && val !== undefined && SVG_PATHS[val]) {
        return SVG_PATHS[val].color;
      }
      return NEUTRAL_COLOR;
    },
    interpolateColor(color1, color2, fraction) {
      let color1RGB = parseInt(color1.slice(1), 16);
      let color2RGB = parseInt(color2.slice(1), 16);

      let r1 = (color1RGB >> 16) & 0xff;
      let g1 = (color1RGB >> 8) & 0xff;
      let b1 = color1RGB & 0xff;

      let r2 = (color2RGB >> 16) & 0xff;
      let g2 = (color2RGB >> 8) & 0xff;
      let b2 = color2RGB & 0xff;

      let r = Math.round(r1 + (r2 - r1) * fraction);
      let g = Math.round(g1 + (g2 - g1) * fraction);
      let b = Math.round(b1 + (b2 - b1) * fraction);

      return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`;
    },
    async loadSvgPaths(svgFile) {
      const response = await fetch(svgFile);
      const svgText = await response.text();
      const parser = new DOMParser();
      const svgDoc = parser.parseFromString(svgText, "image/svg+xml");

      return Array.from(svgDoc.querySelectorAll("path")).map(path =>
        path.getAttribute("d")
      );
    },
    morphPaths(fromPaths, toPaths) {
      const animations = fromPaths.map((fromPath, index) => {
        const toPath = toPaths[index];

        if (!fromPath || !toPath) {
          this.$logger.captureMessage("Path mismatch or missing path data");

          return;
        }

        const interpolator = interpolate(fromPath, toPath, {
          maxSegmentLength: 1
        });

        return t => interpolator(t);
      });

      const animate = timeFraction => {
        animations.forEach((animatePath, index) => {
          if (animatePath) {
            const d = animatePath(timeFraction);
            this.pathsData[index] = d;
          }
        });
      };

      return animate;
    },
    animateMorph(animationFunc, colorFrom, colorTo) {
      let start = null;
      const self = this;

      function frame(time) {
        if (!start) start = time;
        let timeFraction = (time - start) / ANIMATION_DURATION;
        if (timeFraction > 1) timeFraction = 1;

        animationFunc(timeFraction);

        self.currentColor = self.interpolateColor(
          colorFrom,
          colorTo,
          timeFraction
        );

        if (timeFraction < 1) {
          requestAnimationFrame(frame);
        }
      }

      requestAnimationFrame(frame);
    }
  }
};
</script>
