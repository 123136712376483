<template>
  <div class="dialog-container">
    <button class="close-button" @click="$emit('closeDialog')">
      <span class="d-sr-only">{{ $t("buttons.close") }}</span>
      <v-icon color="vflNeutralDark2" size="28">mdi-close</v-icon>
    </button>
    <div class="dialog-content">
      <div class="image-column" />

      <div class="content-column">
        <h5 class="title text-h5">
          {{
            $t(
              "dashboardAssessmentCards.externalNotificationIntroduction.dialog.title"
            )
          }}
        </h5>

        <ul class="feature-list text-body-1 text-body-dark">
          <li
            v-for="(point, index) in $t(
              'dashboardAssessmentCards.externalNotificationIntroduction.dialog.functionality'
            )"
            :key="index"
          >
            {{ point }}
          </li>
        </ul>

        <div class="share-section">
          <div class="share-content">
            <img
              class="share-icon"
              src="/images/icons/alertTriangle.svg"
              width="24px"
              height="24px"
            />
            <div class="share-container">
              <span class="share-text">{{
                $t(
                  "dashboardAssessmentCards.externalNotificationIntroduction.dialog.shareWithAdmin"
                )
              }}</span>
              <button class="vfl-button-link" @click="copyLink">
                {{
                  $t(
                    "dashboardAssessmentCards.externalNotificationIntroduction.dialog.copyLink"
                  )
                }}
              </button>
            </div>
          </div>
        </div>

        <div class="button-container">
          <vfl-button
            @click="connect('teams')"
            width="160px"
            height="28px"
            size="small"
            :disabled="connectClicked"
          >
            {{
              $t(
                "dashboardAssessmentCards.externalNotificationIntroduction.dialog.connectTeams"
              )
            }}
          </vfl-button>

          <vfl-button
            @click="connect('slack')"
            width="160px"
            height="28px"
            size="small"
            :disabled="connectClicked"
          >
            {{
              $t(
                "dashboardAssessmentCards.externalNotificationIntroduction.dialog.connectSlack"
              )
            }}
          </vfl-button>
        </div>
      </div>
    </div>
    <v-snackbar v-model="showCopySuccess" timeout="3000" color="vflGreen">
      {{ $t("account.team.userSettings.signUpLink.success") }}
    </v-snackbar>
    <v-snackbar v-model="showCopyFail" timeout="3000" color="vflRed">
      {{ $t("account.team.userSettings.signUpLink.failed") }}
    </v-snackbar>
  </div>
</template>

<script>
import { sendEvent } from "@/services/analytics/analytics.js";
import VflButton from "@/components/vitrue-foundation-library/VflButton.vue";
import { routes } from "@/router/route-constants.js";

export default {
  name: "ExternalNotificationIntroductionDialog",
  components: {
    VflButton
  },
  data() {
    return {
      connectClicked: false,
      showCopySuccess: false,
      showCopyFail: false
    };
  },
  methods: {
    async connect(integration) {
      try {
        this.connectClicked = true;

        await sendEvent("External notification introduction dialog", {
          action: `Connect ${integration} button selected`
        });
        this.$emit("input", false);
        await this.$router.push({ name: routes.SETTINGS });
      } catch (ex) {
        this.$logger.captureException(ex);
      } finally {
        this.connectClicked = false;
      }
    },
    copyLink() {
      const component = this;
      const instructionLink =
        "https://vitruehealth.notion.site/VIDA-Integrations-152c6c4548d98086b109d38472428fa7";
      navigator.clipboard.writeText(instructionLink).then(
        function () {
          component.showCopySuccess = true;
        },
        function () {
          component.showCopyFail = true;
        }
      );
    }
  }
};
</script>

<style lang="scss" scoped>
$breakpoint: 710px;

.dialog-container {
  background: white;
  position: relative;
}

.close-button {
  position: absolute;
  right: 4px;
  top: 4px;
}

.dialog-content {
  display: grid;
  grid-template-columns: 1fr;

  @media (min-width: $breakpoint) {
    grid-template-columns: 1fr 1fr;
  }
}

.image-column {
  background: #153738
    url("/images/notification-introduction-dialog-background.png") no-repeat;
  background-size: contain;
}

.content-column {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 3rem 2rem;
}

.title {
  margin-bottom: 24px;
}

.feature-list {
  list-style-type: none;
  padding-left: 0px;

  li {
    padding-left: 24px;
    position: relative;

    &:before {
      content: "•";
      position: absolute;
      left: 0;
      color: var(--v-primary-base);
    }
  }
}

.share-section {
  background-color: var(--v-grey-lighten5);
  margin: 1.5rem 0 3rem 0;
}

.share-content {
  display: flex;
  gap: 16px;
  align-items: flex-start;
}

.share-icon {
  margin-top: 4px;
}

.share-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.vfl-button-link {
  align-self: start;
}

.button-container {
  display: flex;
  justify-content: start;
  gap: 16px;
  margin-top: auto;

  @media (min-width: $breakpoint) {
    justify-content: space-between;
  }

  .action-button {
    flex: 1;
  }
}
</style>
