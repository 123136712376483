<template>
  <div class="vfl-card-light pa-3 d-flex flex-column">
    <div class="d-flex flex-column" style="position: relative">
      <h3
        class="text-overline vflNeutralDark2--text d-flex align-center mb-3"
        style="line-height: 1.2"
      >
        {{ $t(`painImpactOverview.tile.${statKey}.title`) }}
        <v-tooltip bottom color="transparent" maxWidth="250">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-on="on"
              v-bind="attrs"
              color="vflPrimary"
              size="16"
              class="ml-1"
              style="padding-bottom: 2px"
              >mdi-help-circle-outline</v-icon
            >
          </template>
          <vitrue-tooltip
            ><p>{{ $t(`painImpactOverview.tile.${statKey}.info`) }}</p>
            <span class="vflNeutralLight3--text">{{
              $t("painImpactOverview.tile.dateIntroduced")
            }}</span></vitrue-tooltip
          >
        </v-tooltip>
      </h3>

      <div class="d-flex flex-column justify-center">
        <div v-if="currentPercentage > 0" class="d-flex align-start">
          <span
            class="text-h4 font-weight-bold mr-1"
            style="line-height: 2rem !important"
            >{{ currentPercentage }}</span
          >
          <span class="percentage">%</span>
        </div>
        <p
          v-else
          class="text-subtitle-1 mb-0 d-flex align-center"
          style="min-height: 32px"
        >
          {{ $t("painImpactOverview.tile.none") }}
        </p>
        <p class="text-caption vflNeutralDark2--text mb-1">
          {{
            $t("painImpactOverview.tile.numberOfUsers", {
              count: value,
              total: totalUsers
            })
          }}
        </p>
      </div>

      <div v-if="currentPercentage">
        <i18n
          class="text-caption vflNeutralDark2--text mb-0 d-inline-block"
          style="line-height: 1.4"
          :path="industryTextPath"
        >
          <template v-slot:percentage>
            <span class="text-body-bold vflNeutralDark1--text"
              >{{ Math.abs(industryDifference) }}%</span
            >
          </template>
        </i18n>
      </div>
    </div>
    <pain-impact-vida-helps
      v-if="showVidaHelps"
      :stat-key="statKey"
      :count="value"
    />
  </div>
</template>

<script>
import VitrueTooltip from "@/components/common/VitrueTooltip.vue";
import PainImpactVidaHelps from "@/components/dashboard/cards/pain-impact/PainImpactVidaHelps.vue";
import { mapGetters } from "vuex";

export default {
  name: "PainImpactStatCard",
  components: {
    VitrueTooltip,
    PainImpactVidaHelps
  },
  props: {
    statKey: {
      type: String,
      required: true
    },
    value: {
      type: Number,
      required: true
    },
    industryDifference: {
      type: Number,
      required: true
    },
    totalUsers: {
      type: Number,
      required: true
    },
    currentPercentage: {
      type: Number,
      required: true
    }
  },
  computed: {
    ...mapGetters(["demoFeatures"]),
    industryTextPath() {
      if (this.industryDifference == 0) {
        return "painImpactOverview.tile.industryComparison.same";
      }

      const comparison = this.industryDifference > 0 ? "worse" : "better";
      return `painImpactOverview.tile.industryComparison.${comparison}`;
    },
    showVidaHelps() {
      return this.demoFeatures && this.value > 0;
    }
  }
};
</script>

<style scoped lang="scss">
.percentage {
  font-family: Poppins;
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1rem;
  text-align: left;
}
</style>
