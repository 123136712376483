<template>
  <div>
    <p class="text-overline title mb-2">VIDA is helping</p>
    <p class="text-body-2 text-body-bold stat mb-1">
      {{ stat }} {{ stat === 1 ? "person" : "people" }}
    </p>
    <p class="body mb-0 text-caption">
      {{ copy }}
    </p>
  </div>
</template>
<script>
const Copy = {
  ABSENTEEISM: "who took time off said they don’t plan further leave",
  REFERRALS: "who visited a doctor said they don’t plan more visits",
  PRODUCTIVITYLOSS: "who felt less productive said they’re more productive now"
};

export default {
  name: "PainImpactVidaHelps",
  props: {
    statKey: {
      type: String,
      required: true
    },
    count: Number
  },
  computed: {
    copy() {
      return Copy[this.statKey.toUpperCase()];
    },
    stat() {
      return Math.max(1, Math.round(this.count * 0.15));
    }
  }
};
</script>
<style lang="scss" scoped>
div {
  background: #effaef;
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
  flex: 1;
  margin: -0.5rem -0.75rem -0.75rem;
  padding: 0.75rem;

  &:before {
    background: white;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    content: "";
    display: block;
    height: 1rem;
    margin: -0.75rem -0.75rem 1rem;
  }
}

.title,
.body {
  color: var(--v-vflNeutralDark2-base);
}

.title {
  line-height: 1;
}

.stat {
  color: var(--v-vflTextDark-base);
}

.body {
  line-height: 1.17;
}
</style>
