<template>
  <v-card
    min-width="250"
    min-height="30"
    color="red"
    class="white--text px-4 py-2"
  >
    <v-row align="center" justify="center">
      <v-col cols="12"
        ><v-btn
          class="mr-2"
          @click="$emit('input', false)"
          icon
          small
          color="white"
        >
          <v-icon>clear</v-icon>
        </v-btn>
        <span class="text-center" v-if="messageToDisplay != ''">{{
          messageToDisplay
        }}</span>
        <v-btn
          text
          small
          class="white--text"
          @click="
            $router.push({
              name: 'Subscription',
              params: { tabToShow: 'SUBSCRIPTION', showDialogOnLoad: true }
            })
          "
          >{{
            this.subscriptionDue
              ? $t("buttons.payNow")
              : $t("buttons.upgradeSimple")
          }}</v-btn
        ></v-col
      >
    </v-row>
  </v-card>
</template>

<script>
export default {
  props: {
    value: Boolean,
    numberOfInvitesRemaining: Number,
    numberOfEmailsToSend: Number,
    status: String
  },
  computed: {
    trialExpired() {
      return this.status == "TrialExpired";
    },
    subscriptionDue() {
      return this.status == "SubscriptionExpired";
    },
    plural() {
      return this.numberOfInvitesRemaining > 1 ? "s" : "";
    },
    messageToDisplay() {
      if (this.subscriptionDue) {
        return this.$t("invite.upgradeSnackbar.subscriptionExpired");
      }
      if (this.trialExpired) {
        return this.$t("invite.upgradeSnackbar.trailExpired");
      }
      if (this.numberOfInvitesRemaining <= 0) {
        return this.$t("invite.upgradeSnackbar.outOfTrialInvites");
      }

      if (this.numberOfEmailsToSend > this.numberOfInvitesRemaining) {
        return this.$t("invite.upgradeSnackbar.insufficientInvites", {
          0: this.numberOfEmailsToSend,
          1: this.numberOfInvitesRemaining
        });
      }

      if (
        this.numberOfInvitesRemaining > 0 &&
        this.numberOfInvitesRemaining <= 3
      ) {
        return this.$tc(
          "invite.upgradeSnackbar.lowInvites",
          this.numberOfInvitesRemaining,
          { n: this.numberOfInvitesRemaining }
        );
      }

      return "";
    }
  }
};
</script>
