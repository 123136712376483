import { format, parseISO, isThisYear } from "date-fns";
import sentry from "@/sentry";
import i18n from "@/plugins/i18n.js";

import enGB from "date-fns/locale/en-GB/index.js";
import enUS from "date-fns/locale/en-US/index.js";
import es from "date-fns/locale/es/index.js";

const locales = {
  "en-GB": enGB,
  "en-US": enUS,
  es: es
};

const localeMap = {
  "en-gb": "en-GB",
  "en-us": "en-US",
  es: "es",
  "es-mx": "es",
  latam: "es"
};

const mapLocale = locale => localeMap[locale.toLowerCase()] || locale;

const isSpanishLocale = locale =>
  ["es", "es-mx"].includes(locale.toLowerCase());

export function formatWithLocale(date, formatStr) {
  const currentLocale = mapLocale(i18n.locale);

  try {
    const dateFnsLocale = locales[currentLocale] || enGB;
    return format(date, formatStr, { locale: dateFnsLocale });
  } catch (error) {
    sentry.captureException(error);
    return "";
  }
}

function getFormatString(date, includeOrdinal) {
  const formatBase = includeOrdinal ? "do MMM" : "d MMM";
  return isThisYear(date) ? formatBase : `${formatBase} yy`;
}

export function formatDate(dateString, { useOrdinal = false } = {}) {
  const date = dateString instanceof Date ? dateString : parseISO(dateString);
  const currentLocale = mapLocale(i18n.locale);
  const includeOrdinal = useOrdinal && !isSpanishLocale(currentLocale);

  const formatStr = getFormatString(date, includeOrdinal);
  return formatWithLocale(date, formatStr);
}

export const formatDateWithOrdinal = dateString =>
  formatDate(dateString, { useOrdinal: true });
export const formatShortDate = formatDate;
