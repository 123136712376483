<template>
  <v-img
    v-if="!error"
    class="avatar"
    :src="url"
    :height="size"
    :width="size"
    contain
    @error="error = true"
  />
  <v-icon v-else x-large :color="defaultColor">{{ defaultIcon }}</v-icon>
</template>

<script>
export default {
  name: "InitialsIcon",
  props: {
    text: String,
    fallbackText: String,
    defaultColor: String,
    defaultIcon: {
      type: String,
      default: "mdi-account-circle"
    },
    color: String,
    TextColor: String,
    size: {
      type: String,
      default: "40px"
    }
  },
  data() {
    return { error: false };
  },
  computed: {
    initials() {
      if (!this.text || this.text.trim() === "") {
        return this.fallbackText;
      }

      // Handle commas which indicate reverse naming.
      const normalizedUserName = this.text.includes(",")
        ? this.text.split(",").reverse().join(" ")
        : this.text;

      // Split name on space.
      const names = normalizedUserName.trim().split(/\s+/);
      // Always take the initial from the first name.
      let initials = names[0][0].toUpperCase();

      // Handle initial from last name if there is one
      if (names.length > 1) {
        const lastName = names[names.length - 1]; // Consider the last element as the last name
        initials += lastName[0].toUpperCase();
      }

      return initials;
    },
    url() {
      const url = new URL(
        `https://api.dicebear.com/6.x/initials/svg?seed=${this.initials}`
      );
      if (this.color) {
        url.searchParams.append("backgroundColor", this.color);
      }
      if (this.TextColor) {
        url.searchParams.append("textColor", this.TextColor);
      }
      return url.toString();
    }
  }
};
</script>

<style scoped>
.avatar {
  border-radius: 50%;
}
</style>
