<template>
  <v-row justify="center" class="mt-4">
    <v-col cols="12" v-if="healthConditions">
      <health-condition-summary-banner
        :healthConditions="healthConditions"
        :assessmentType="assessmentType"
      />
    </v-col>
    <v-col md="6" sm="12" cols="12">
      <overall-score
        :score="score"
        :rating="rating"
        :scoreColour="scoreColour"
        :descriptionLocale="`${$options.localePath}.explanation`"
        :sections="$options.scoreSliderSections"
      />
    </v-col>
    <v-col md="6" sm="12" cols="12">
      <what-this-means :results="results" />
    </v-col>
  </v-row>
</template>

<script>
import WhatThisMeans from "@/components/driver-assessment/report/summary/WhatThisMeans.vue";
import OverallScore from "@/components/common/report/OverallScore.vue";
import ScoreService from "@/services/driver-assessment/driver-score-service.js";
import * as AssessmentScores from "@/services/driver-assessment/driver-assessment-scores.js";
const localePath = "driverAssessment.report.summary.overallScore";
import HealthConditionSummaryBanner from "@/components/common/report/HealthConditionSummaryBanner.vue";
import { viewingOwnResults } from "@/services/privacy-service.js";
import { mapGetters } from "vuex";
import { getConditionsByCategory } from "@/components/assessment/pre-existing-conditions/get-conditions-service.js";
import { assessmentTypes } from "@/constants/constants.js";

export default {
  name: "DriverSummaryContent",
  components: {
    OverallScore,
    WhatThisMeans,
    HealthConditionSummaryBanner
  },
  data() {
    return {
      assessmentType: assessmentTypes.driverAssessment
    };
  },
  props: {
    results: {
      type: Object
    }
  },
  localePath: localePath,
  scoreSliderSections: AssessmentScores.SCORE_SLIDER_SECTIONS,
  computed: {
    ...mapGetters(["enablePreExistingConditions"]),
    score() {
      return ScoreService.getOverallScore(this.results);
    },
    rating() {
      const rating = AssessmentScores.GetScoreRatingName(this.score);
      return this.$t(`${localePath}.ratings.${rating}`);
    },
    scoreColour() {
      return AssessmentScores.OverallScoreColor(this.score);
    },
    healthConditions() {
      if (!viewingOwnResults(this.$route)) return null;
      if (!this.enablePreExistingConditions) return null;

      return getConditionsByCategory(this.results);
    }
  }
};
</script>
