export const STAGES = {
  PERIMENOPAUSE: "perimenopause",
  MENOPAUSE: "menopause",
  POSTMENOPAUSE: "postmenopause"
};

export const TREATMENT_TYPES = {
  INJECTABLE_CONTRACEPTIVES: "injectable_contraceptives",
  HRT: "hrt"
};

export const STAGE_MAPPING = {
  2: STAGES.PERIMENOPAUSE,
  3: STAGES.POSTMENOPAUSE
};

export const REPORT_SECTION_CONFIG = [
  {
    id: "header",
    component: "menopause-report-header",
    isVisible: () => true
  },
  {
    id: "mskHealth",
    component: "menopause-report-msk-health",
    isVisible: () => true
  },
  {
    id: "pain",
    component: "menopause-report-pain",
    isVisible: vm => vm.hasPain
  },
  {
    id: "muscleLoss",
    component: "menopause-report-muscle-loss",
    isVisible: () => true
  },
  {
    id: "symptoms",
    component: "menopause-report-symptoms",
    isVisible: vm => vm.hasSymptoms
  }
];
