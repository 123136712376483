<template>
  <div class="d-flex align-center justify-center">
    <div style="position: relative">
      <button
        v-if="!success"
        @click="sendReport"
        data-test-id="emailReportButton"
        :class="['vfl-button-link', { 'is-disabled': busy }]"
        :style="{ textDecoration: success ? 'none' : '' }"
      >
        <v-progress-circular
          v-if="busy"
          indeterminate
          size="16"
          color="primary"
          class="mr-2"
          width="2"
        />
        {{ $t("assessment.footer.emailReport.action") }}
      </button>
      <span data-test-id="emailReportButtonSuccess" v-else>
        <v-icon size="16" color="success" class="mr-1">mdi-check</v-icon>
        {{ $t("assessment.footer.emailReport.success") }}
      </span>
    </div>

    <v-snackbar v-model="showErrorSnackbar" color="error" :timeout="5000">
      <span v-html="$t('assessment.footer.emailReport.error')" />
    </v-snackbar>
  </div>
</template>
<script>
import { sendReport } from "@/customApi.js";

export default {
  name: "EmailReportButton",
  props: {
    assessmentId: String,
    assessmentType: String
  },
  data() {
    return {
      busy: false,
      error: false,
      success: false,
      timer: null,
      showErrorSnackbar: false
    };
  },
  computed: {
    assessmentDto() {
      return {
        id: this.assessmentId,
        assessmentType: this.assessmentType
      };
    }
  },
  methods: {
    async sendReport() {
      if (this.busy) return;

      this.busy = true;
      this.success = false;

      await sendReport(this.assessmentDto)
        .then(() => {
          this.error = false;
          this.success = true;

          this.clearTimer();

          this.timer = setTimeout(() => {
            this.success = false;
            this.timer = null;
          }, 3000);
        })
        .catch(err => {
          this.error = true;
          this.success = false;
          this.showErrorSnackbar = true;
        })
        .finally(() => {
          this.busy = false;
        });
    },
    clearTimer() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
    }
  },
  beforeDestroy() {
    this.clearTimer();
  }
};
</script>

<style scoped lang="scss">
.v-progress-circular {
  bottom: 0;
  left: -1.5rem;
  margin: auto;
  position: absolute;
  top: 0;
}
</style>
