import sentry from "@/sentry";
import * as Constants from "@/constants/constants.js";
import i18n from "@/plugins/i18n.js";
import { assessmentTypes } from "@/constants/constants.js";

import { DeskCard } from "@/components/dashboard/cards/average-scores/cards/desk.js";
import { ResilienceCard } from "@/components/dashboard/cards/average-scores/cards/resilience.js";
import { DriverCard } from "@/components/dashboard/cards/average-scores/cards/driver.js";
import { PhysicalLabourCard } from "@/components/dashboard/cards/average-scores/cards/physical-labour.js";
import { Path } from "@/components/dashboard/cards/average-scores/constants.js";

const Cards = {
  [Constants.assessmentTypes.deskAssessment]: DeskCard,
  [Constants.assessmentTypes.burnoutAssessment]: ResilienceCard,
  [Constants.assessmentTypes.driverAssessment]: DriverCard,
  [Constants.assessmentTypes.physicalLabourAssessment]: PhysicalLabourCard
};

export const getCard = data => {
  const {
    assessmentType,
    assessmentLocation,
    averageScore,
    industryAverage,
    peopleAssessed
  } = data;

  const card = Cards[assessmentType];
  if (!card) {
    sentry.captureException(`Unknown assessment type: ${assessmentType}`);
    return;
  }

  const icon = `${Path.ICON_BASE}${
    assessmentType === assessmentTypes.deskAssessment
      ? card.icon(assessmentLocation)
      : card.icon
  }`;

  const title = i18n.t(
    `productNames.${
      assessmentType === assessmentTypes.deskAssessment
        ? card.title(assessmentLocation)
        : card.title
    }`
  );

  const hasData = averageScore !== null && industryAverage !== null;

  return {
    icon,
    title,
    score: hasData ? Math.round(card.score(averageScore)) : null,
    ratingObj: hasData ? card.ratingObj(averageScore) : null,
    industryAverage: hasData ? Math.round(industryAverage) : null,
    peopleAssessed,
    hasData
  };
};
