<template>
  <v-expansion-panels
    v-model="internalValue"
    :flat="flat"
    :multiple="multiple"
    :hover="hover"
    style="width: 100%"
  >
    <slot name="panels"></slot>
  </v-expansion-panels>
</template>

<script>
export default {
  name: "vfl-panels",
  props: {
    value: {
      type: [Array, Number],
      default: () => []
    },
    flat: Boolean,
    multiple: Boolean,
    hover: Boolean
  },
  computed: {
    internalValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("update:value", newValue);
      }
    }
  }
};
</script>
