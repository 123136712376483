import i18n from "@/plugins/i18n";

const ACCOUNT_EVENTS = {
  0: "userProfile.history.account.added"
};

export const getAccountEventText = item => {
  const eventPath =
    ACCOUNT_EVENTS[item.accountEvent] || "userProfile.history.account.updated";
  const eventText = i18n.t(eventPath);
  return i18n.t("userProfile.history.account.event", { event: eventText });
};
