export { standardTheme };

const standardTheme = {
  accent: "#DA68E4",
  secondary: "#3fb288",
  tertiary: "#6C63FF",
  tertiaryLight: "#E1DFFF",
  success: "#4CAF50",
  successDark: "#0D865A",
  info: "#2196F3",
  warning: "#ff0c3e",
  disabled: "#A9A8A8",
  error: "#A10000",
  brightError: "#FF0000",
  selectedPrimary: "#DFEDFF",
  poorOutcome: "#F3883B",
  fairOutcome: "#FBC63E",
  wayfindingPurple: "#693DEC",
  darkBackground: "#13171a",
  labelColors: [
    "#2117BE",
    "#FD80F8",
    "#3FB288",
    "#B66C00",
    "#AD00FF",
    "#FFAE36",
    "#CD0707",
    "#C5BD01",
    "#00452C",
    "#24CAFF"
  ],
  vitruePurple: "#AD00FF",
  vitruePalePurple: "#ECEAFF",
  vitruePalePink: "#F8EAFB",
  vitrueLightGreen: "#DCFBF0",
  vitruePaleGreen: "#95d3a5",
  vitrueYellow: "#fecb7d",
  vitrueOrange: "#Fbac85",
  vitrueRed: "#f8696b",
  vitrueDarkGreen: "#3FB288",
  vitrueBrightGreen: "#90C819",
  vitruePaleBlue: "#1fcbe7",
  vitruePaleGrey: "#F2F2F2",
  vitrueGrey: "#6E6E6E",
  vitrueDarkGrey: "#505050",
  vitrueMidGrey: "#E8E8E8",
  vitrueLightOrange: "#F2EADE",
  sliderRed: "#ed474a",
  sliderOrange: "#eeaf78",
  sliderLightGreen: "#83c403",
  sliderGreen: "#3fb288",
  vidaPainGreen: "#756E0A",
  vidaPainDarkGreen: "#09593C",
  vidaPainOrange: "#BB5A00",
  vidaPainRed: "#CA1010",
  vflPrimaryLight: "#D6F1F3",
  vflPrimaryLight2: "#3F7070",
  vflSuccess: "#0D865A",
  vflViolet: "#585FA1",
  vflGreyLight: "#626E7C",
  vflDarkRed: "#621708",
  vflBrightGreen: "#C1D37F",
  vflGreyVeryLight: "#DDE0E4",
  vflGreyDark: "#343D48",
  vflBlueVeryLight: "#eaf1f9",
  vflText: "#343D48",
  vflTextDark: "#050F19",
  vflPurple: "#A320DA",
  vflPurpleDark: "#0B26B4",
  vflYellowLight: "#FFF8BB",
  vflRedLight: "#FFEDED",
  vflVitruvian: "#023839",
  vflTurquoiseLight: "#7BFBFC",
  vflTurquoise: "#98DDDE",
  vflBeigeDark2: "#4A4741",
  vflBeigeDark1: "#BE8450",
  vflBeige: "#F6EBDA",
  vflBeigeLight1: "#F8F5F0",
  vflBeigeLight2: "#FBF9F6",
  vflGreenDark1: "#023839",
  vflGreen: "#005F61",
  vflAquaDark1: "#414949",
  vflAqua: "#00CED1",
  vflAquaLight1: "#8FE6E7",
  vflAquaLight2: "#D8F2F3",
  vflBrightGreenDark2: "#404845",
  vflBrightGreenDark1: "#048343",
  vflBrightGreen: "#34C37B",
  vflBrightGreenLight1: "#C2EDD7",
  vflBrightGreenLight2: "#E0F6E0",
  vflYellowDark2: "#4C493E",
  vflYellowDark1: "#7C6B13",
  vflYellow: "#F5E075",
  vflYellowLight1: "#FCF4CD",
  vflOrangeDark2: "#4C4741",
  vflOrangeDark1: "#B15E00",
  vflOrange: "#F7BE7D",
  vflOrangeLight1: "#FDEBD8",
  vflPurpleDark2: "#49454B",
  vflPurpleDark1: "#871A9C",
  vflPurple: "#B82EB8",
  vflPurpleLight1: "#F3E6F9",
  vflRed: "#B41541",
  vflNeutralDark2: "#5F7B7C",
  vflNeutralDark1: "#255556",
  vflNeutralLight1: "#F4FAFA",
  vflNeutralLight2: "#E5F0F0",
  vflNeutralLight3: "#C6DDDD",
  vflMagentaDark2: "#473F45",
  vflMagentaDark1: "#AA0874",
  vflMagenta: "#C90D8A",
  vflMagentaLight1: "#EED3E5"
};
