import { BaseTextModule } from "@/components/assessment/menopause/report/text-modules/base-text-module.js";
import { SYMPTOMS_CONTENT } from "@/components/assessment/menopause/report/text-modules/content/symptoms-content.js";

export class SymptomsModule extends BaseTextModule {
  constructor() {
    super(SYMPTOMS_CONTENT);
  }

  getSectionTitle() {
    return this.getText("title");
  }

  getCardContent(results) {
    return this.content.getContent(results);
  }
}
