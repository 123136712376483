export default {
  cmToFeetAndInches(distance) {
    var realFeet = (distance * 0.393701) / 12;
    var feet = Math.floor(realFeet);
    var inches = Math.round((realFeet - feet) * 12);
    return { feet: feet, inches: inches };
  },
  cmToInches(distance) {
    let value = distance * 0.393701;
    return Math.round((value + Number.EPSILON) * 100) / 100;
  }
};
