<template>
  <div class="d-flex align-center">
    <h5 class="text-subtitle-1" style="color: var(--v-vflTextDark-base)">
      {{ title }}
    </h5>
    <v-tooltip v-if="tooltipMessage" bottom color="transparent" maxWidth="250">
      <template v-slot:activator="{ on, attrs }">
        <v-icon
          v-on="on"
          v-bind="attrs"
          color="vflPrimary"
          size="20"
          class="mx-1"
          >mdi-help-circle-outline</v-icon
        >
      </template>
      <vitrue-tooltip :message="tooltipMessage" />
    </v-tooltip>
    <slot name="actionButton"></slot>
  </div>
</template>

<script>
import VitrueTooltip from "@/components/common/VitrueTooltip.vue";

export default {
  name: "GenericCardHeader",
  components: { VitrueTooltip },
  props: {
    title: {
      type: String,
      required: true
    },
    tooltipMessage: {
      type: String
    }
  }
};
</script>
