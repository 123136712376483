﻿<template>
  <div>
    <button
      data-test-id="physical-labour-assessment-navigation-previous"
      @click="previous"
      class="vfl-button-primary-outline"
    >
      {{ $t("physicalLabourAssessment.navigation.back") }}
    </button>
    <button
      data-test-id="physical-labour-assessment-navigation-next"
      @click="next"
      class="vfl-button-primary"
      :class="{ 'is-loading': loading }"
    >
      {{ $t("physicalLabourAssessment.navigation.next") }}
    </button>
  </div>
</template>

<script>
export default {
  name: "Navigation",
  props: { loading: Boolean },
  methods: {
    previous() {
      this.$emit("previous");
    },
    next() {
      this.$emit("next");
    }
  }
};
</script>

<style scoped lang="scss">
div {
  display: flex;
  gap: 0.5rem;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 100%;
  width: var(--assessment-container-width);
}

button {
  min-width: 10rem;
}
</style>
