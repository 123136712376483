<template>
  <div class="card-container">
    <v-skeleton-loader
      v-if="loading"
      type="image"
      max-width="100%"
      height="170"
      min-height="170"
      :min-width="first ? 366 : 268"
      style="border-radius: 1rem"
    />

    <div
      class="card d-flex flex-column"
      :style="[{ backgroundColor: color }, css]"
      @click="$emit('startAssessment')"
      v-else
    >
      <div class="d-flex">
        <div>
          <vfl-chip
            v-if="categoryChip"
            class="mb-1"
            :label="categoryChip.label"
            :borderColor="categoryChip.color"
            size="sm"
            variant="bold"
            backgroundColor="transparent"
            :fontColor="categoryChip.color"
          />
          <h5
            :class="[
              first ? 'text-h6' : 'text-subtitle-1',
              !categoryChip ? 'pt-2' : '',
              'mb-1'
            ]"
            v-html="$t(title)"
            style="white-space: nowrap"
          ></h5>

          <p class="text-body-2" v-if="showDescription" style="min-width: 28ch">
            {{ $t(description) }}
          </p>
        </div>

        <div
          v-if="image"
          class="flex-grow-1 d-flex justify-end pl-4 align-self-start"
          :class="first ? 'mt-n12' : ''"
        >
          <img :src="image" alt="" />
        </div>
      </div>

      <div class="card-actions d-flex mt-auto flex-wrap flex-md-nowrap">
        <dashboard-assessment-card-send-button
          :buttonClasslist="sendButtonClasslist"
          :disabled="disableInvite"
          :tooltipText="disableInviteTooltipText"
          @onClick="sendInvite"
          :showOnboardingTooltip="showOnboardingInviteAssessmentToolTip"
        />
        <div class="d-flex align-center mr-6">
          <dashboard-assessment-card-try-button
            :disabled="disableTryNow"
            :tooltipText="disableTryNowTooltipText"
            :showOnboardingTooltip="showOnboardingSelfAssessmentToolTip"
            @onClick="tryNow"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import DashboardAssessmentCardSendButton from "@/components/dashboard/assessment-cards/DashboardAssessmentCardSendButton.vue";
import DashboardAssessmentCardTryButton from "@/components/dashboard/assessment-cards/DashboardAssessmentCardTryButton.vue";
import VflChip from "@/components/vitrue-foundation-library/VflChip.vue";

export default {
  name: "DashboardAssessmentCard",
  components: {
    DashboardAssessmentCardSendButton,
    DashboardAssessmentCardTryButton,
    VflChip
  },
  props: {
    color: String,
    css: Object,
    title: String,
    description: String,
    loading: Boolean,
    image: String,
    disableInvite: Boolean,
    disableInviteTooltipText: String,
    disableTryNow: Boolean,
    disableTryNowTooltipText: String,
    onboardingTooltips: Boolean,
    first: Boolean,
    categoryChip: Object
  },
  data() {
    return {
      showDescription: false
    };
  },
  computed: {
    ...mapGetters(["onboardingChecklist"]),
    showOnboardingInviteAssessmentToolTip() {
      return (
        this.onboardingTooltips &&
        this.onboardingChecklist.hoveredItem === "inviteUser"
      );
    },
    showOnboardingSelfAssessmentToolTip() {
      return (
        this.onboardingTooltips &&
        this.onboardingChecklist.hoveredItem === "selfAssessment"
      );
    },
    sendButtonClasslist() {
      return `vfl-button-primary${this.first ? "" : "-outline"}`;
    }
  },
  methods: {
    sendInvite() {
      this.$emit("invite");
    },
    tryNow() {
      this.$emit("tryNow");
    }
  }
};
</script>

<style lang="scss" scoped>
.card-container {
  height: auto;
}

.card {
  border: 1px solid #e6e6e6;
  border-radius: 1rem;
  gap: 1rem;
  height: 100%;
  min-height: 170px;
  min-width: 268px;
  padding: 1rem;
  user-select: none;
  width: 100%;

  > div {
    width: 100%;
  }
}

.card-actions {
  gap: 1rem;
}
</style>
