<template>
  <v-card
    rounded="lg"
    class="px-5 py-3 fill-height d-flex flex-column justify-center report-information-card"
  >
    <div class="d-flex justify-center">
      <img
        :src="`/images/physical-labour-assessment/sectors/${sectorName}.svg`"
        width="100"
        height="100"
        alt=""
      />
    </div>

    <p class="text-h6 vitrueDarkGrey--text font-weight-medium my-4 text-center">
      {{ $t(`physicalLabourAssessment.report.summary.whyItMatters.title`) }}
    </p>
    <p class="text-body-2 vitrueDarkGrey--text" id="summaryText">
      {{
        $t(
          `physicalLabourAssessment.report.summary.whyItMatters.content.${sectorName}`
        )
      }}
    </p>
  </v-card>
</template>

<script>
import { getSectorNameFromValue } from "@/components/assessment/physical-labour/utils/sector.js";

export default {
  props: {
    results: {
      type: Object
    }
  },
  computed: {
    sectorName() {
      return getSectorNameFromValue(this.results.sectors.type);
    }
  }
};
</script>
