import { WorkSpacePriority } from "../../constants/constants";
import CustomRecommendationOrders from "@/services/recommendations/custom-recommendation-orders.js";
import {
  InteractionPriorityMap,
  InteractionStates
} from "@/components/common/report/cards/Recommendation/recommendation-interaction-service.js";
import store from "@/store.js";

export function sortDeskRecommendations(cards, featureFlags) {
  var sortingFunction = getSortingFunction(featureFlags);
  cards.sort(sortingFunction);
}

function getSortingFunction(featureFlags) {
  if (featureFlags.customRecommendationOrder_iHRsdr) {
    return sortRecommendations_prioritisedList(
      CustomRecommendationOrders.prioritisedDeskRecommendations_iHRsdr
    );
  } else if (
    store.getters?.prioritiseHabitRecommendations ||
    featureFlags.customRecommendationOrder_eSRiAd ||
    featureFlags.customRecommendationOrder_thumiv ||
    featureFlags.customRecommendationOrder_xarkut
  ) {
    return sortRecommendations_prioritisedList(
      CustomRecommendationOrders.prioritisedHabitRecommendations
    );
  } else if (featureFlags.customRecommendationOrder_wltNL) {
    return sortRecommendations_prioritisedList(
      CustomRecommendationOrders.prioritisedDeskRecommendations_wltNL
    );
  } else if (featureFlags.customRecommendationOrder_beCsMA) {
    return sortRecommendations_prioritisedList(
      CustomRecommendationOrders.prioritisedDeskRecommendations_beCsMA
    );
  } else if (featureFlags.customRecommendationOrder_KcaRDa) {
    return sortRecommendations_prioritisedList(
      CustomRecommendationOrders.prioritisedDeskRecommendations_KcaRDa
    );
  } else if (featureFlags.customRecommendationOrder_AwEATt) {
    return sortRecommendations_prioritisedList(
      CustomRecommendationOrders.prioritisedDeskRecommendations_AwEATt
    );
  } else if (featureFlags.customRecommendationOrder_UjeUTz) {
    return sortRecommendations_prioritisedList(
      CustomRecommendationOrders.prioritisedDeskRecommendations_UjeUTz
    );
  }
  return sortByImpactAndPriority;
}

function sortRecommendations_prioritisedList(list) {
  return function (a, b) {
    // if one item is in prioritised list, bump it up - else sort by impact and priority
    if (list.includes(a.id) && !list.includes(b.id)) {
      return -1;
    } else if (!list.includes(a.id) && list.includes(b.id)) {
      return 1;
    }

    return sortByImpactAndPriority(a, b);
  };
}

function sortByImpactAndPriority(a, b) {
  // If either object has a workspacepriority of MAX, it should come first
  if (
    a.workSpacePriority === WorkSpacePriority.MAX ||
    b.workSpacePriority === WorkSpacePriority.MAX
  ) {
    var aPriority = a.workSpacePriority ?? WorkSpacePriority.LOW;
    var bPriority = b.workSpacePriority ?? WorkSpacePriority.LOW;
    return aPriority - bPriority;
  }

  // If either object has no scores or workSpacePriority property, it should not move
  var workspaceValues = Object.values(WorkSpacePriority);
  var aHasScore = a.scores && workspaceValues.includes(a.workSpacePriority);
  var bHasScore = b.scores && workspaceValues.includes(b.workSpacePriority);

  if (!aHasScore || !bHasScore) {
    return 0;
  }

  const aAverage = averageOfValues(a.scores);
  const bAverage = averageOfValues(b.scores);

  // Sort based on average scores
  if (aAverage !== bAverage) {
    return bAverage - aAverage;
  }

  // If the average scores are the same, sort based on workSpacePriority
  return a.workSpacePriority - b.workSpacePriority;
}

function averageOfValues(obj) {
  var values = Object.values(obj);
  var sum = values.reduce((a, b) => a + b, 0);
  return Math.round((sum / values.length) * 100) / 100;
}

export function sortRecommendationsByInteraction(
  recommendations,
  interactions
) {
  // If there is no id match then fallback priority ensures these cards don't go to the end
  const fallbackPriority = InteractionStates.NONE;

  const recommendationPriorityMap = interactions.reduce((map, item) => {
    map[item.recommendation] =
      InteractionPriorityMap[item.type] ||
      InteractionPriorityMap[fallbackPriority];
    return map;
  }, {});

  const sorted = [...recommendations].sort((a, b) => {
    return (
      (recommendationPriorityMap[a.id] ||
        InteractionPriorityMap[fallbackPriority]) -
      (recommendationPriorityMap[b.id] ||
        InteractionPriorityMap[fallbackPriority])
    );
  });

  return sorted;
}
