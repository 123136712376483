import * as Constants from "@/constants/constants.js";

export const RedirectPages = {
  DESK_ASSESSMENT: "desk-assessment",
  WORKSPACE_RESILIENCE_ASSESSMENT: "workspace-resilience-assessment",
  DRIVER_ASSESSMENT: "driver-assessment",
  WELLNESS: "wellness",
  PHYSICAL_LABOUR_ASSESSMENT: "physical-labour-assessment",
  ANALYTICS: "analytics",
  PEOPLE: "people",
  MS_TEAMS_DASHBOARD: "ms-teams-dashboard"
};

export const Actions = {
  ASSESSMENT: { NEW: "new" },
  WELLNESS: {
    ALREADY_COMPLETED_DIALOG: "alreadyCompletedDialog",
    RECOMMENDATION_SECTION: "recommendationSection",
    GAME_SECTION: "gameSection",
    SHOW_EXERCISE_VIDEO: "showExerciseVideo"
  },
  MS_TEAMS: {
    AUTH: "submitAuth"
  }
};

export const URL_REDIRECT_PAGE_PARAMETER = "redirectPage";
export const URL_REDIRECT_ACTION_PARAMETER = "redirectAction";

export const AssessmentRedirectPages = {
  [Constants.assessmentTypes.deskAssessment]: RedirectPages.DESK_ASSESSMENT,
  [Constants.assessmentTypes.burnoutAssessment]:
    RedirectPages.WORKSPACE_RESILIENCE_ASSESSMENT,
  [Constants.assessmentTypes.driverAssessment]: RedirectPages.DRIVER_ASSESSMENT,
  [Constants.assessmentTypes.physicalLabourAssessment]:
    RedirectPages.PHYSICAL_LABOUR_ASSESSMENT
};
