export const StoreAction = {
  ADD_MESSAGE: "ADD_MESSAGE",
  ADD_CONVERSATION: "ADD_CONVERSATION",
  ADD_CONVERSATION_SUGGESTIONS: "ADD_CONVERSATION_SUGGESTIONS",
  CLEAR_ALL_CONVERSATION_SUGGESTIONS: "CLEAR_ALL_CONVERSATION_SUGGESTIONS",
  REMOVE_CONVERSATION_SUGGESTIONS: "REMOVE_CONVERSATION_SUGGESTIONS",
  SET_ABORT_CONTROLLER: "SET_ABORT_CONTROLLER",
  SET_AI_WINDOW_OPEN: "SET_AI_WINDOW_OPEN",
  SET_ASSISTANT_TYPING: "SET_ASSISTANT_TYPING",
  SET_AWAITING_ASSISTANT_RESPONSE: "SET_AWAITING_ASSISTANT_RESPONSE",
  SET_CONTEXT_LOADING: "SET_CONTEXT_LOADING",
  SET_CONVERSATIONS: "SET_CONVERSATIONS",
  SET_CURRENT_CONVERSATION: "SET_CURRENT_CONVERSATION",
  SET_CURRENT_ASSESSMENT_INFO: "SET_CURRENT_ASSESSMENT_INFO",
  SET_ERROR: "SET_ERROR",
  SET_LOADING_CONVERSATIONS: "SET_LOADING_CONVERSATIONS",
  UPDATE_MESSAGE: "UPDATE_MESSAGE",
  REMOVE_MESSAGE: "REMOVE_MESSAGE",
  UPDATE_CONVERSATION: "UPDATE_CONVERSATION",
  SET_PROCESSING_REQUEST: "SET_PROCESSING_REQUEST",
  SET_ASSISTANT_TYPE: "SET_ASSISTANT_TYPE"
};

export const StoreDispatch = {
  ADD_TEMPORARY_MESSAGE: "addTemporaryMessage",
  HANDLE_ERROR_RESPONSE: "handleErrorResponse",
  HANDLE_SUCCESSFUL_RESPONSE: "handleSuccessfulResponse",
  RESET_REQUEST_STATE: "resetRequestState",
  SEND_MESSAGE: "sendMessage",
  CREATE_NEW_CONVERSATION: "createNewConversation",
  SHOW_FEATURE_DISABLED_MESSAGE: "showFeatureDisabledMessage",
  ABORT_CURRENT_REQUEST: "abortCurrentRequest",
  PREPARE_FOR_NEW_MESSAGE: "prepareForNewMessage",
  SETUP_CONVERSATION: "setupConversation",
  CREATE_TEMP_MESSAGES: "createTempMessages",
  PROCESS_ASSISTANT_RESPONSE: "processAssistantResponse",
  FINALISE_REQUEST: "finaliseRequest",
  HANDLE_ABORTED_REQUEST: "handleAbortedRequest",
  HANDLE_NON_ABORT_ERROR: "handleNonAbortError",
  CREATE_STREAM_UPDATER: "createStreamUpdater",
  UPDATE_MESSAGES: "updateMessages",
  UPDATE_CONVERSATION: "updateConversation"
};

export const Role = {
  ASSISTANT: "assistant",
  SYSTEM: "system",
  USER: "user"
};

export const ErrorMessage = {
  REQUEST_CANCELLED: "Request cancelled by user"
};

export const AssistantTypes = {
  REPORT: "REPORT",
  STRATEGY: "STRATEGY"
};
