<template>
  <div class="pain-overview-no-data-container pa-4">
    <h3 class="text-body-1 text-body-bold mb-2">
      {{ text }}
    </h3>
    <p>{{ $t("mskOverview.noDataExplanation") }}</p>
  </div>
</template>

<script>
export default {
  name: "PainOverviewNoData",
  props: {
    count: Number
  },
  computed: {
    text() {
      return this.count
        ? this.$t("mskOverview.notEnoughData")
        : this.$t("mskOverview.noData");
    }
  }
};
</script>
