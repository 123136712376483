<template>
  <div ref="radioButtonContainer" v-show="show">
    <v-col cols="10" class="pt-0">
      <v-radio-group
        row
        v-model="selectedAnswer"
        @change="emitToParent(selected)"
        :rules="rules"
      >
        <v-row align="center">
          <v-col
            class="pt-0"
            cols="12"
            :md="visibleOptions.length > 3 ? '12' : 'auto'"
            v-for="i in visibleOptions.length"
            :key="i"
          >
            <v-radio
              :class="grey ? 'text-body-2 greyText' : 'text-body-2'"
              :key="visibleOptions[i - 1].value"
              :label="$t(visibleOptions[i - 1].label)"
              :value="visibleOptions[i - 1].value"
            ></v-radio>
          </v-col>
        </v-row>
      </v-radio-group>
    </v-col>
  </div>
</template>

<script>
export default {
  name: "RadioButtonQuestion",
  props: {
    name: String,
    options: Array,
    value: Number,
    show: {
      default: true,
      type: Boolean
    },
    optional: Boolean,
    grey: {
      default: false,
      type: Boolean
    },
    forceVerticalOptions: {
      default: false,
      type: Boolean
    }
  },
  methods: {
    emitToParent(value) {
      this.$emit("input", value);
    }
  },
  data() {
    return { selected: null };
  },
  computed: {
    selectedAnswer: {
      get() {
        return this.value;
      },
      set(selectedAnswer) {
        this.selected = selectedAnswer;
      }
    },
    rules() {
      return this.optional
        ? []
        : [v => !!v || this.$t("form.radioButton.mustAnswer")];
    },
    visibleOptions() {
      return this.options.filter(x => x.showAnswer);
    }
  }
};
</script>

<style scoped>
.greyText /deep/ label {
  color: lightgrey;
}
</style>
