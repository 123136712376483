import locale from "@/plugins/i18n.js";

export default {
  emailRequiredSignup: v =>
    !!v || locale.t("textInputRules.emailRequiredSignup"),
  emailRequired: v => !!v || locale.t("textInputRules.emailRequired"),
  emailValid: v => /.+@.+\..+/.test(v) || locale.t("textInputRules.emailValid"),
  workEmail: v =>
    isUncommonDomain(v) || locale.t("textInputRules.workEmailRequired"),
  passwordRequired: v =>
    v !== "" || locale.t("textInputRules.passwordRequired"),
  passwordAtLeast8Characters: v =>
    v.length > 7 || locale.t("textInputRules.passwordAtLeast8Characters"),
  passwordContainsNumber: v =>
    /\d/.test(v) || locale.t("textInputRules.passwordContainsNumber"),
  passwordContainsSpecialCharacter: v =>
    //eslint-disable-next-line
    /[~`!#$£€¬%\^&*()+=_\-\[\]\\'@;,./{}|\\":<>\?]/g.test(v) ||
    locale.t("textInputRules.passwordContainsSpecialCharacter"),
  passwordContainsBothCases: v =>
    (v.toUpperCase() !== v && v.toLowerCase() !== v) ||
    locale.t("textInputRules.passwordContainsBothCases"),
  codeRequired: v => !!v || locale.t("textInputRules.codeRequired"),
  nameRequired: v => !!v || locale.t("textInputRules.nameRequired"),
  roleRequired: v => !!v || locale.t("textInputRules.roleRequired"),
  companyNameRequired: v =>
    !!v || locale.t("textInputRules.companyNameRequired"),
  genericRequired: v => !!v || locale.t("textInputRules.genericRequired"),
  phoneNumberRequired: v =>
    !!v || locale.t("textInputRules.phoneNumberRequired"),
  phoneNumberValid: v =>
    v.length == 0 ||
    /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/.test(v) ||
    locale.t("textInputRules.phoneNumberValid"),
  phoneNumberMinLength: v =>
    v.length == 0 ||
    v.length >= 7 ||
    locale.t("textInputRules.phoneNumberMinLength")
};

function isUncommonDomain(email) {
  const commonDomains = [
    "gmail",
    "googlemail",
    "yahoo",
    "hotmail",
    "outlook",
    "msn",
    "icloud",
    "aol",
    "live"
  ];

  if (!email) {
    return true;
  }

  const atIndex = email.lastIndexOf("@");
  const domainTyped = email.substring(atIndex + 1);
  if (atIndex === -1 || !domainTyped) {
    return true;
  }

  const domain = email.split("@")[1].split(".")[0]; // gets first part of domain after @ symbol
  return !commonDomains.includes(domain.toLowerCase());
}
