<template>
  <VflButton
    v-bind="$props"
    :class="['vfl-icon-button', `is-${size}`]"
    @click="handleClick"
    :style="buttonStyle"
    ><span class="d-sr-only">{{ screenReader }}</span>
    <slot></slot>
  </VflButton>
</template>

<script>
import VflButton from "./VflButton.vue";

export default {
  name: "VflIconButton",
  components: {
    VflButton
  },
  props: {
    ...VflButton.props,
    screenReader: {
      type: String,
      required: true
    }
  },
  computed: {
    buttonStyle() {
      if (this.width !== null && this.width !== undefined) {
        const size =
          typeof this.width === "number" ? `${this.width}px` : this.width;
        return {
          width: size,
          height: size,
          minWidth: size,
          aspectRatio: "1/1"
        };
      }
      return {};
    }
  },
  methods: {
    handleClick(event) {
      if (!this.disabled) {
        this.$emit("click", event);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.vfl-icon-button {
  padding: 0 !important;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &.is-small {
    width: 24px;
    height: 24px;
    min-width: 24px;
  }

  &.is-medium {
    width: 40px;
    height: 40px;
    min-width: 40px;
  }

  &.is-large {
    width: 56px;
    height: 56px;
    min-width: 56px;
  }
}
</style>
