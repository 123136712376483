<template>
  <div class="recommendation-container">
    <h6 class="text-h6 mb-4">{{ title }}</h6>
    <template v-if="useTabs">
      <vfl-tabs>
        <vfl-tab
          v-for="(group, groupId) in recommendations?.groups"
          :key="groupId"
          :title="$t(group.titleKey)"
        >
          <vfl-carousel :scroll-amount="containerScrollAmount">
            <recommendations-card
              v-for="card in group.cards"
              :key="card.id"
              class="recommendation-card"
              :ref="card.id"
              v-bind="card"
              :assessmentId="assessmentId"
              :deskAssessment="results"
              @update="cardUpdated($event)"
              :loadedState="getCardState(card.id)"
              :heading="$t(card.json.heading)"
              :subheading="$t(card.json.subheading)"
              :explanation="$t(card.json.explanation)"
            />
          </vfl-carousel>
        </vfl-tab>
      </vfl-tabs>
    </template>
    <template v-else>
      <div
        v-for="(group, index) in recommendations?.groups"
        :key="index"
        class="mt-2"
      >
        <vfl-carousel :scroll-amount="containerScrollAmount">
          <recommendations-card
            v-for="card in group.cards"
            :key="card.id"
            class="recommendation-card"
            :ref="card.id"
            v-bind="card"
            :assessmentId="assessmentId"
            :deskAssessment="results"
            @update="cardUpdated($event)"
            :loadedState="getCardState(card.id)"
            :heading="$t(card.json.heading)"
            :subheading="$t(card.json.subheading)"
            :explanation="$t(card.json.explanation)"
          />
        </vfl-carousel>
      </div>
    </template>
  </div>
</template>

<script>
import VflTabs from "@/components/vitrue-foundation-library/tabs/VflTabs.vue";
import VflTab from "@/components/vitrue-foundation-library/tabs/VflTab.vue";
import RecommendationsCard from "@/components/common/report/cards/Recommendation/RecommendationsCard.vue";
import RecommendationInteractionMixin from "@/components/common/report/cards/Recommendation/RecommendationInteractionMixin.js";
import VflCarousel from "@/components/vitrue-foundation-library/VflCarousel.vue";

export default {
  name: "RecommendationContainer",
  components: {
    VflCarousel,
    VflTabs,
    VflTab,
    RecommendationsCard
  },
  mixins: [RecommendationInteractionMixin],
  props: {
    results: {
      type: Object,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    assessmentId: {
      type: String,
      required: true
    },
    recommendations: {
      type: Object,
      required: true
    }
  },
  async created() {
    await this.getRecommendationInteractions(this.assessmentId);
  },
  methods: {
    cardUpdated(event) {
      this.$emit("cardUpdate", event);
    }
  },
  computed: {
    useTabs() {
      return Object.values(this.recommendations?.groups || {}).some(
        group => !group.hideTab
      );
    },
    containerScrollAmount() {
      const cardWidth = 315;
      const containerGap = 24;

      return cardWidth + containerGap / 2;
    }
  }
};
</script>

<style lang="scss" scoped>
.recommendations-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-top: 1rem;
}

.recommendation-card {
  flex: 1 1 100%;
  max-width: 315px;

  @media (min-width: 600px) {
    flex: 0 1 calc(50% - 0.5rem);
  }

  @media (min-width: 960px) {
    flex: 0 1 calc(33.333% - 0.667rem);
  }
}
</style>
