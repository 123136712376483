<template>
  <div class="vfl-tabs-container">
    <ul role="tablist" class="vfl-tabs-list mb-4 d-flex flex-wrap">
      <li v-for="(tab, index) in tabs" :key="tab.title">
        <button
          role="tab"
          :aria-selected="selectedIndex === index"
          :aria-controls="`tab-panel-${index}`"
          :id="`tab-${index}`"
          :tabindex="selectedIndex === index ? 0 : -1"
          class="vfl-tab-button text-body-2 text-body-bold"
          @click="selectTab(index)"
          @keydown="handleKeydown"
        >
          {{ tab.title }}
        </button>
      </li>
    </ul>
    <div class="vfl-tab-panels">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "VflTabs",
  props: {
    initialTab: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      tabs: [],
      selectedIndex: this.initialTab
    };
  },
  methods: {
    selectTab(index) {
      this.selectedIndex = index;
      this.tabs.forEach((tab, i) => {
        tab.isActive = i === index;
      });
    },
    handleKeydown(event) {
      const tabCount = this.tabs.length;
      let newIndex = this.selectedIndex;

      switch (event.key) {
        case "ArrowRight":
          newIndex = (this.selectedIndex + 1) % tabCount;
          break;
        case "ArrowLeft":
          newIndex = (this.selectedIndex - 1 + tabCount) % tabCount;
          break;
        case "Home":
          newIndex = 0;
          break;
        case "End":
          newIndex = tabCount - 1;
          break;
        default:
          return;
      }

      event.preventDefault();
      this.selectTab(newIndex);
      document.getElementById(`tab-${newIndex}`).focus();
    }
  },
  mounted() {
    this.tabs = this.$children;
    this.selectTab(this.initialTab);
  }
};
</script>

<style scoped lang="scss">
.vfl-tabs-list {
  display: flex;
  gap: 0.75rem;
  padding: 0;
  margin: 0;

  li {
    display: flex;
  }
}

.vfl-tab-button {
  background-color: white;
  border: 1px solid var(--v-vflNeutralLight3-base);
  border-radius: 4px;
  cursor: pointer;
  padding: 0.25rem 0.75rem;

  &:hover {
    background-color: hsla(0, 0%, 0%, 0.025);
  }

  &[aria-selected="true"] {
    background-color: var(--v-vflNeutralLight3-base);
    border-color: var(--v-vflPrimary-base);
    color: var(--v-vflPrimary-base);
  }
}
</style>
