<template>
  <div>
    <v-dialog
      v-if="completedChecklist"
      v-model="completedChecklist"
      max-width="1200"
    >
      <admin-check-list-complete
        v-model="completedChecklist"
        @closed="completeAdminChecklist"
      />
    </v-dialog>
    <v-card
      v-else-if="onboardingChecklist.series === 'AdminOnboardingTour'"
      class="mx-auto white--text"
      :width="componentWidth"
      color="vitrueGrey"
      rounded="0"
    >
      <v-card-actions
        id="card-header"
        @click="toggleVisibility"
        class="clickable"
      >
        <v-btn id="showContentButton" icon @click.prevent color="white">
          <v-icon v-if="showContent" large id="chevronDownIcon"
            >mdi-chevron-down</v-icon
          >
          <v-icon v-else large id="chevronUpIcon">mdi-chevron-up</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <p class="text-h5 py-0 my-0 mr-2" id="checklistTitle">
          {{ $t("onboarding.adminCheckList.title") }}
        </p>
      </v-card-actions>
      <v-row no-gutters align="center" class="px-4 pb-2">
        <v-col cols="12"
          ><v-progress-linear
            id="percentageBar"
            v-model="percentageComplete"
            background-color="white"
            color="primary"
            height="15"
        /></v-col>
        <v-col
          cols="12"
          v-if="showContent"
          class="text-body-2"
          id="percentageText"
        >
          {{
            percentageComplete +
            "% " +
            $t("onboarding.adminCheckList.percentageComplete")
          }}
        </v-col>
      </v-row>

      <v-expand-transition>
        <div v-if="showContent" id="checklistContent" class="pb-3">
          <div
            v-for="item in checklistItems"
            :key="item.id"
            :id="'item' + item.id"
            class="mx-4 mt-3 pl-6 pt-4 pb-3"
            :class="itemStyle(item)"
            @mouseover="setHoveredTask(item.id)"
            @mouseleave="setHoveredTask(undefined)"
          >
            <span :class="textColor(item)" class="text-subtitle-1">
              <v-icon :color="iconColor(item)" class="mb-1 mr-2">{{
                iconToShow(item)
              }}</v-icon>
              {{ item.text }}</span
            >
          </div>
        </div>
      </v-expand-transition>
    </v-card>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import AdminCheckListComplete from "./AdminCheckListComplete.vue";
import {
  setFeatureIntroItemComplete,
  getTeamCompletedAssessmentCount
} from "@/customApi";
import {
  updateAdminChecklist,
  checkListCompleted
} from "@/services/onboarding/adminOnboarding";

export default {
  name: "AdminChecklist",
  components: {
    AdminCheckListComplete
  },
  data() {
    return {
      showContent: false,
      completedChecklist: false,
      checklistItems: [
        {
          id: "completeTeamProfile",
          complete: false,
          text: this.$t("onboarding.adminCheckList.items.completeTeamProfile")
        },
        {
          id: "selfAssessment",
          complete: false,
          text: this.$t("onboarding.adminCheckList.items.selfAssessment")
        },
        {
          id: "viewPainCoach",
          complete: false,
          text: this.$t("onboarding.adminCheckList.items.viewPainCoach")
        },
        {
          id: "inviteUser",
          complete: false,
          text: this.$t("onboarding.adminCheckList.items.inviteUser")
        },
        {
          id: "viewTeamData",
          complete: false,
          text: this.$t("onboarding.adminCheckList.items.viewTeamData")
        }
      ],
      markedAsCompleted: false
    };
  },
  mounted() {
    updateAdminChecklist();
    this.updateChecklist(this.onboardingChecklist);
  },
  computed: {
    ...mapGetters(["onboardingChecklist", "isNewUser", "featureIntros"]),
    ...mapGetters("ai", ["isAiWindowOpen"]),
    componentWidth() {
      return this.$vuetify.breakpoint.xs ? "100%" : "360px";
    },
    percentageComplete() {
      let completedItems = this.checklistItems.filter(item => {
        return item.complete;
      });
      return Math.abs(
        (completedItems.length / this.checklistItems.length) * 100
      );
    }
  },
  watch: {
    onboardingChecklist: function (current) {
      this.updateChecklist(current);
    },
    isNewUser(newValue, oldValue) {
      if (oldValue === true && newValue === false) {
        this.showContent = true;
      } else if (oldValue === false && newValue === true) {
        this.showContent = false;
      }
    }
  },
  methods: {
    ...mapMutations([
      "setHoveredOverOnboardingItem",
      "removeOnboardingChecklist",
      "setFeatureIntros"
    ]),
    toggleVisibility() {
      this.showContent = !this.showContent;

      if (this.showContent && this.isAiWindowOpen) {
        this.$store.dispatch("ai/closeAiWindow");
      }
    },
    async isItemActive(item) {
      let firstIncompleteIndex = this.checklistItems.findIndex(
        x => !x.complete
      );
      let currentItemIndex = this.checklistItems.indexOf(item);
      switch (item.id) {
        case "inviteUser":
          return true;
        case "viewTeamData":
          let completedCounts = await getTeamCompletedAssessmentCount();
          let completedAssessmentCount =
            completedCounts["Desk assessment"] || 0;
          return completedAssessmentCount > 0;
        default:
          return currentItemIndex === firstIncompleteIndex;
      }
    },
    iconToShow(item) {
      if (item.complete) {
        return "mdi-checkbox-marked-circle";
      }
      return item.locked
        ? "mdi-lock-outline"
        : "mdi-checkbox-blank-circle-outline";
    },
    iconColor(item) {
      if (item.complete) {
        return "primary";
      }
      return item.locked ? "disabled" : "white";
    },
    textColor(item) {
      return item.complete || !item.locked ? "white--text" : "disabled--text";
    },
    itemStyle(item) {
      if (item.complete) {
        return "item-complete";
      }
      return item.locked ? "item-locked" : "item-active";
    },
    setHoveredTask(task) {
      if (this.transitionOngoing() && task) {
        return;
      }
      this.setHoveredOverOnboardingItem(task);
    },
    transitionOngoing() {
      var transitionLeaveClass = document.getElementsByClassName(
        "expand-transition-leave-active expand-transition-leave-to"
      );
      var transitionEnterClass = document.getElementsByClassName(
        "expand-transition-enter-active expand-transition-enter-to"
      );
      return transitionLeaveClass[0] || transitionEnterClass[0];
    },
    async updateChecklist(newVersion) {
      if (newVersion.series === "AdminOnboardingTourCompletion") {
        this.completedChecklist = true;
        return;
      }

      this.checklistItems.forEach(async i => {
        i.complete = newVersion.items[i.id]?.completed || false;
        let active = await this.isItemActive(i);
        i.locked = !active;
      });

      var allComplete = this.checklistItems.every(x => x.complete);
      if (allComplete && !this.markedAsCompleted) {
        this.markedAsCompleted = true;
        checkListCompleted();
      }
    },
    async completeAdminChecklist() {
      try {
        let featureIntros = await setFeatureIntroItemComplete(
          this.onboardingChecklist.id,
          this.onboardingChecklist.items.completeChecklist.id
        );
        this.setFeatureIntros(featureIntros);
      } catch (err) {
      } finally {
        this.removeOnboardingChecklist();
      }
    }
  }
};
</script>

<style scoped>
.item-complete {
  background-color: var(--v-vitrueDarkGrey-base) !important;
  border-radius: 10px;
  height: 56px;
  cursor: default;
}
.item-active {
  background-color: var(--v-primary-base) !important;
  border-radius: 10px;
  height: 56px;
  cursor: pointer;
}
.item-locked {
  border-style: solid;
  border-width: 1px;
  border-color: var(--v-disabled-base) !important;
  border-radius: 10px;
  padding: 10px;
  height: 56px;
  cursor: pointer;
}
.clickable {
  cursor: pointer;
  user-select: none;
}
</style>
