﻿import answers from "@/services/assessment/physical-labour/answers.json";

// A table of neck x torso x leg scoring outcomes.
export const neckTorsoLegPostureScoringTable = [
  // Neck 1
  [
    [1, 2, 3, 4], // Torso 1
    [2, 3, 4, 5], // Torso 2
    [2, 4, 5, 6], // Torso 3
    [3, 5, 6, 7], // Torso 4
    [4, 6, 7, 8] // Torso 5
  ],
  // Neck 2
  [
    [1, 2, 3, 4], // Torso 1
    [3, 4, 5, 6], // Torso 2
    [4, 5, 6, 7], // Torso 3
    [5, 6, 7, 8], // Torso 4
    [6, 7, 8, 9] // Torso 5
  ],
  // Neck 3
  [
    [3, 3, 5, 6], // Torso 1
    [4, 5, 6, 7], // Torso 2
    [5, 6, 7, 8], // Torso 3
    [6, 7, 8, 9], // Torso 4
    [7, 8, 9, 9] // Torso 5
  ]
];

// A table of upper arm x lower arm x wrist scoring outcomes
export const upperArmLowerArmWristPostureScoringTable = [
  // Upper arm 1
  [
    [1, 2, 2], // Lower arm 1
    [1, 2, 3] // Lower arm 2
  ],
  // Upper arm 2
  [
    [1, 2, 3], // Lower arm 1
    [2, 3, 4] // Lower arm 2
  ],
  // Upper arm 3
  [
    [3, 4, 5], // Lower arm 1
    [4, 5, 5] // Lower arm 2
  ],
  // Upper arm 4
  [
    [4, 5, 5], // Lower arm 1
    [5, 6, 7] // Lower arm 2
  ],
  // Upper arm 5
  [
    [6, 7, 8], // Lower arm 1
    [7, 8, 8] // Lower arm 2
  ],
  // Upper arm 6
  [
    [7, 8, 8], // Lower arm 1
    [8, 9, 9] // Lower arm 2
  ]
];

// A table of score a x score b outcomes.
export const overallScoringTable = [
  [1, 1, 1, 2, 3, 3, 4, 5, 6, 7, 7, 7],
  [1, 2, 2, 3, 4, 4, 5, 6, 6, 7, 7, 8],
  [2, 3, 3, 3, 4, 5, 6, 7, 7, 8, 8, 8],
  [3, 4, 4, 4, 5, 6, 7, 8, 8, 9, 9, 9],
  [4, 4, 4, 5, 6, 7, 8, 8, 9, 9, 9, 9],
  [6, 6, 6, 7, 8, 8, 9, 9, 10, 10, 10, 10],
  [7, 7, 7, 8, 9, 9, 9, 10, 10, 11, 11, 11],
  [8, 8, 8, 9, 10, 10, 10, 10, 10, 11, 11, 11],
  [9, 9, 9, 10, 10, 10, 11, 11, 11, 12, 12, 12],
  [10, 10, 10, 11, 11, 11, 11, 12, 12, 12, 12, 12],
  [11, 11, 11, 11, 12, 12, 12, 12, 12, 12, 12, 12],
  [12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12]
];

export const combinedAnswers = {
  torsoPosition: {
    backwardsOver20Degrees: 1,
    backwardsUpTo20Degrees: 2,
    vertical: 3,
    forwardsUpTo20Degrees: 4,
    forwardsUpTo60Degrees: 5,
    forwardsOver60Degrees: 6
  },
  upperArmRotation: {
    backwardsOver20Degrees: 1,
    vertical: 2,
    forwardUpTo45Degrees: 3,
    forwardUpTo90Degrees: 4,
    aboveShoulder: 5
  }
};

export const rebaScores = {
  neckPosition: {
    [answers.reba.neckPosition.bentBackwards]: 2, // < 0°
    [answers.reba.neckPosition.straight]: 1, // 0° - 20°
    [answers.reba.neckPosition.bentForwards]: 2 // > 20°
  },
  neckTwistedOrSideBent: {
    [answers.reba.neckTwistedOrSideBent.twisted]: 1,
    [answers.reba.neckTwistedOrSideBent.sideBent]: 1
  },

  torsoPosition: {
    [combinedAnswers.torsoPosition.backwardsOver20Degrees]: 3,
    [combinedAnswers.torsoPosition.backwardsUpTo20Degrees]: 2,
    [combinedAnswers.torsoPosition.vertical]: 1,
    [combinedAnswers.torsoPosition.forwardsUpTo20Degrees]: 2,
    [combinedAnswers.torsoPosition.forwardsUpTo60Degrees]: 3,
    [combinedAnswers.torsoPosition.forwardsOver60Degrees]: 4
  },
  torsoTwistedOrSideBent: {
    [answers.reba.torsoTwistedOrSideBent.twisted]: 1,
    [answers.reba.torsoTwistedOrSideBent.sideBent]: 1
  },

  legPosition: {
    [answers.reba.legPosition.straight]: 1,
    [answers.reba.legPosition.oneLegRaised]: 2
  },
  legsBent: {
    [answers.reba.legsBent.none]: 0,
    [answers.reba.legsBent.slightly]: 1, // 30° - 60°
    [answers.reba.legsBent.significantly]: 2 // > 60°
  },
  loadWeight: {
    [answers.reba.loadWeight.notApplicable]: 0,
    [answers.reba.loadWeight.light]: 0,
    [answers.reba.loadWeight.medium]: 1,
    [answers.reba.loadWeight.heavy]: 2
  },
  suddenMovementsOrHighForce: {
    [answers.reba.suddenMovementsOrHighForce.no]: 0,
    [answers.reba.suddenMovementsOrHighForce.yes]: 1
  },

  upperArmRotation: {
    [combinedAnswers.upperArmRotation.backwardsOver20Degrees]: 2,
    [combinedAnswers.upperArmRotation.vertical]: 1,
    [combinedAnswers.upperArmRotation.forwardUpTo45Degrees]: 2,
    [combinedAnswers.upperArmRotation.forwardUpTo90Degrees]: 3,
    [combinedAnswers.upperArmRotation.aboveShoulder]: 4
  },
  additionalUpperArmFactors: {
    [answers.reba.additionalUpperArmFactors.atLeastOneShoulderRaised]: 1,
    [answers.reba.additionalUpperArmFactors.armRaisedToSideOfBody]: 1,
    [answers.reba.additionalUpperArmFactors.armSupported]: -1,
    [answers.reba.additionalUpperArmFactors.none]: 0
  },

  lowerArmPosition: {
    [answers.reba.lowerArmPosition.slightlyBent]: 2, // 0° - 60°
    [answers.reba.lowerArmPosition.rightAngleAtElbows]: 1, // 60° - 100°
    [answers.reba.lowerArmPosition.bentMoreThanARightAngle]: 2 // > 100°
  },

  wristPosition: {
    [answers.reba.wristPosition.bentUp]: 2, // < -15°
    [answers.reba.wristPosition.straight]: 1, // -15° - 15°
    [answers.reba.wristPosition.bentDown]: 2 // > 15°
  },
  wristTwistedOrSideBent: {
    [answers.reba.wristTwistedOrSideBent.twisted]: 1,
    [answers.reba.wristTwistedOrSideBent.sideBent]: 1
  },

  coupling: {
    [answers.reba.coupling.notApplicable]: 0,
    [answers.reba.coupling.easy]: 0,
    [answers.reba.coupling.moderate]: 1,
    [answers.reba.coupling.difficult]: 2,
    [answers.reba.coupling.veryDifficult]: 3
  },

  changesBodyPosition: {
    [answers.reba.changesBodyPosition.changePosition]: 0,
    [answers.reba.changesBodyPosition.holdPosition]: 1
  },
  changePositionFrequency: {
    [answers.reba.changePositionFrequency.occasionally]: 0,
    [answers.reba.changePositionFrequency.frequently]: 1
  },
  changePositionStability: {
    [answers.reba.changePositionStability.no]: 0,
    [answers.reba.changePositionStability.yes]: 1
  }
};
