<template>
  <div class="overview-card pa-6 d-flex flex-column">
    <div
      class="d-flex overview-card-inner mt-auto mb-auto pb-4 justify-space-around"
    >
      <pain-overview-donut-chart
        class="px-4"
        :loading="loading"
        :painData="painData"
        :meetsMinSampleSizeThreshold="meetsMinSampleSizeThreshold"
        :industryAverage="industryAverage"
      />
      <div
        class="d-flex flex-column"
        v-if="meetsMinSampleSizeThreshold || loading"
      >
        <pain-overview-assessed-people
          v-if="meetsMinSampleSizeThreshold || loading"
          :loading="loading"
          :painData="painData"
          :totalPeopleAssessed="totalPeopleAssessed"
        />
        <pain-overview-non-typical-people
          v-if="!loading"
          :nonTypicalWorkers="nonTypicalWorkers"
          class="mt-auto"
        />
      </div>

      <pain-overview-no-data :count="totalPeopleAssessed" v-else />
    </div>
    <pain-overview-callout class="mt-auto" v-if="!loading" />
  </div>
</template>

<script>
import PainOverviewDonutChart from "@/components/dashboard/cards/pain-overview/PainOverviewDonutChart.vue";
import PainOverviewCallout from "@/components/dashboard/cards/pain-overview/PainOverviewCallout.vue";
import PainOverviewAssessedPeople from "@/components/dashboard/cards/pain-overview/PainOverviewAssessedPeople.vue";
import PainOverviewNoData from "@/components/dashboard/cards/pain-overview/PainOverviewNoData.vue";
import { MINIMUM_SAMPLE_SIZE_FOR_ANALYTICS } from "@/components/dashboard/cards/constants.js";
import PainOverviewNonTypicalPeople from "@/components/dashboard/cards/pain-overview/PainOverviewNonTypicalPeople.vue";
import { mapGetters } from "vuex";

export default {
  name: "PainOverviewCard",
  components: {
    PainOverviewNonTypicalPeople,
    PainOverviewNoData,
    PainOverviewDonutChart,
    PainOverviewCallout,
    PainOverviewAssessedPeople
  },
  props: {
    loading: Boolean,
    nonTypicalWorkers: Array,
    painData: Object,
    industryAverage: Number
  },
  computed: {
    ...mapGetters(["isRestrictedAdminUser", "demoFeatures"]),
    ...mapGetters("adminDashboard", [
      "dashboardDemoMetricsState",
      "usePredefinedDashboardMetrics"
    ]),
    meetsMinSampleSizeThreshold() {
      if (this.isRestrictedAdminUser) return true;

      return this.totalPeopleAssessed >= MINIMUM_SAMPLE_SIZE_FOR_ANALYTICS;
    },
    totalPeopleAssessed() {
      if (this.usePredefinedMetrics) {
        return this.getDemoMetric();
      }

      return Object.values(this.painData).reduce(
        (sum, { count }) => sum + count,
        0
      );
    },
    usePredefinedMetrics() {
      return this.demoFeatures && this.usePredefinedDashboardMetrics;
    }
  },
  methods: {
    getDemoMetric() {
      const metrics = this.dashboardDemoMetricsState;

      return metrics.totalCount;
    }
  }
};
</script>

<style scoped lang="scss">
.overview-card {
  border: 1px solid var(--v-vflNeutralLight2-base);
  border-radius: 12px;
  gap: 1rem;
}
</style>
