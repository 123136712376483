<template>
  <v-select
    v-if="show"
    v-model="selected"
    :items="options"
    item-value="feature"
    item-text="text"
    :style="'max-width: 250px; margin-right:50px'"
    @change="updateFeatureFlag"
    placeholder="Choose customer"
    hide-details
  />
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { getFeatureFlagOptions, selectFeatureFlag } from "@/customApi";

export default {
  name: "FeatureFlagSelector",
  data() {
    return {
      options: [],
      selected: null
    };
  },
  watch: {
    showFeatureSelector(val) {
      if (val) {
        this.getFlags();
      }
    }
  },
  mounted() {
    if (this.show) {
      this.getFlags();
    }
  },
  computed: {
    ...mapGetters(["showFeatureSelector"]),
    show() {
      return (
        (this.showFeatureSelector || this.isDevelopment) &&
        this.$vuetify.breakpoint.mdAndUp
      );
    },
    isDevelopment() {
      var env = import.meta.env.VITE_NODE_ENV;
      return env === "Dev03" || env === "development";
    }
  },
  methods: {
    ...mapActions(["setFeatureFlags"]),
    async updateFeatureFlag() {
      if (!this.show) {
        return;
      }
      var flags = await selectFeatureFlag(this.selected);
      await this.setFeatureFlags(flags);
    },
    async getFlags() {
      var options = await getFeatureFlagOptions();
      this.options = Object.entries(options).map(x => {
        return { feature: x[0], text: x[1] };
      });
    }
  }
};
</script>
