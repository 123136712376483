<template>
  <svg
    width="641"
    height="637"
    viewBox="0 0 641 637"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M413.095 333.672C413.33 331.007 415.647 329.006 418.323 329.006H424.968C424.968 327.382 423.652 326.066 422.028 326.066H417.959C413.501 326.066 409.887 329.68 409.887 334.138C409.887 338.597 413.501 342.211 417.959 342.211H422.028C423.652 342.211 424.968 340.894 424.968 339.271H418.206C415.218 339.271 412.826 336.716 413.095 333.672Z"
      fill="#F5764A"
    />
    <path
      d="M422.028 326.067H417.959C417.626 326.067 417.299 326.09 416.977 326.129V329.184C417.409 329.071 417.86 329.007 418.322 329.007H424.968C424.968 327.383 423.652 326.067 422.028 326.067Z"
      fill="#E46C3D"
    />
    <path
      d="M418.205 339.271C417.864 339.271 417.531 339.236 417.209 339.172C417.399 340.229 417.7 341.247 418.106 342.211H422.026C423.65 342.211 424.966 340.894 424.966 339.271H418.205Z"
      fill="#E46C3D"
    />
    <path
      d="M434.107 322.116H419.629V336.594C419.629 344.59 426.111 351.072 434.107 351.072V322.116Z"
      fill="#F5764A"
    />
    <path
      d="M434.107 322.116H448.585V336.594C448.585 344.59 442.103 351.072 434.107 351.072V322.116Z"
      fill="#E46C3D"
    />
    <path
      d="M442.121 351.072H426.096V348.655H442.121V351.072Z"
      fill="#BA5535"
    />
    <path
      d="M461.339 321.82H469.582L466.248 293.19H455.707L461.339 321.82Z"
      fill="#8DB3E3"
    />
    <path
      d="M484.493 321.82H476.25L479.584 293.19H490.125L484.493 321.82Z"
      fill="#8DB3E3"
    />
    <path
      d="M476.251 326.679H469.582L466.854 346.748H478.979L476.251 326.679Z"
      fill="#7796D4"
    />
    <path
      d="M461.338 326.679H469.581L466.853 346.748H456.729L461.338 326.679Z"
      fill="#8DB3E3"
    />
    <path
      d="M484.493 326.679H476.25L478.978 346.748H489.102L484.493 326.679Z"
      fill="#8DB3E3"
    />
    <path d="M482.928 324.791H462.59V321.818H482.928V324.791Z" fill="#222223" />
    <path
      d="M481.733 324.792H463.783V326.678H481.733V324.792Z"
      fill="#6081CA"
    />
    <path
      d="M471.164 286.755L455.707 293.189H466.248L471.164 286.755Z"
      fill="#7796D4"
    />
    <path
      d="M479.584 293.189L474.668 286.755L490.125 293.189H479.584Z"
      fill="#7796D4"
    />
    <path
      d="M474.669 282.769H471.164V286.756H474.669V282.769Z"
      fill="#222223"
    />
    <path
      d="M466.248 293.189H479.585L474.669 286.755H471.164L466.248 293.189Z"
      fill="#6681C9"
    />
    <path
      d="M466.248 293.19L469.582 321.82H476.251L479.585 293.19H466.248Z"
      fill="#7796D4"
    />
    <path
      d="M494.132 296.984H489.379L488.686 300.506H494.132V313.089C492.714 313.089 491.564 314.239 491.564 315.657V316.723C491.564 318.142 492.714 319.291 494.132 319.291H497.654V313.089V300.506V296.984H494.132Z"
      fill="#222223"
    />
    <path
      d="M471.164 282.77H474.669L472.916 281.401L471.164 282.77Z"
      fill="#2D2E30"
    />
    <path
      d="M455.707 293.189H449.391V295.599C451.954 295.599 454.298 297.044 455.449 299.334L457.838 304.021L455.707 293.189Z"
      fill="#222223"
    />
    <path
      d="M456.729 346.748L458.956 351.203H466.247L466.853 346.748H456.729Z"
      fill="#7796D4"
    />
    <path
      d="M466.854 346.748L466.248 351.203H479.585L478.979 346.748H466.854Z"
      fill="#6681C9"
    />
    <path
      d="M479.584 351.203H486.875L489.103 346.748H478.979L479.584 351.203Z"
      fill="#7796D4"
    />
  </svg>
</template>

<script>
export default {
  name: "NightWakingOverlay"
};
</script>
