import BurnoutCategoryValues from "@/assets/json/BurnoutAssessment/BurnoutAssessmentCategoryValues";

import router from "@/router";
import sentry from "@/sentry";

import {
  BurnoutRisk,
  BURNOUT_CATEGORIES,
  BurnoutToResilienceCategoriesMap,
  BurnoutLevel,
  BurnoutToResilienceMap
} from "@/constants/burnout";

export default {
  getScores(results) {
    let scores = {};
    let totalSum = 0;
    let totalCount = 0;
    let categories = BURNOUT_CATEGORIES;

    Object.keys(results).forEach(category => {
      if (categories.includes(category)) {
        let result = Object.values(results[category]);
        let sum =
          result.length > 0 ? result.reduce((acc, cur) => acc + cur) : 0;
        // Add number of question to sum to convert 0-4 slider valye to a 1-5 score
        scores[category] = Math.round((sum / result.length + 1) * 100) / 100;

        // secondary symptoms should not contribute to overall score
        if (category !== "secondarysymptoms") {
          totalSum += sum;
          totalCount += result.length;
        }
      }
    });
    scores.overall = Math.round((totalSum / totalCount + 1) * 100) / 100;
    return scores;
  },
  getResilienceAsPercentage(score) {
    const burnoutScoreAsPercentage = Math.round(((score - 1) / 4) * 100);
    const resilienceScoreAsPercentage = 100 - burnoutScoreAsPercentage;

    return resilienceScoreAsPercentage;
  },
  getBurnoutRisk(score, category) {
    if (score < BurnoutCategoryValues[category].average) {
      return BurnoutRisk.LOW;
    } else if (score < BurnoutCategoryValues[category].high) {
      return BurnoutRisk.AVERAGE;
    } else if (score < BurnoutCategoryValues[category].veryhigh) {
      return BurnoutRisk.HIGH;
    }
    return BurnoutRisk.VERY_HIGH;
  },
  getBurnoutLevel(score, category) {
    if (score < BurnoutCategoryValues[category].average) {
      return BurnoutLevel.LOW;
    } else if (score < BurnoutCategoryValues[category].high) {
      return BurnoutLevel.AVERAGE;
    } else if (score < BurnoutCategoryValues[category].veryhigh) {
      return BurnoutLevel.HIGH;
    }
    return BurnoutLevel.VERY_HIGH;
  },
  getRiskTextFromScore(score, category) {
    const resilience = this.getResilienceFromScore(score, category);

    if (category === "overall") {
      return router.app.$t("resilienceLevels." + resilience.level);
    }
    return router.app.$t(
      "resilienceCategoryLevels." + resilience.categoryLevel
    );
  },
  getResilienceFromScore(score, category) {
    const burnoutLevel = this.getBurnoutLevel(score, category);

    return this.getResilienceFromBurnoutLevel(burnoutLevel);
  },
  getColorFromScore(score, category) {
    const categories = [...BURNOUT_CATEGORIES, "overall"];

    if (!category || !categories.includes(category)) {
      sentry.captureException(`Unknown burnout category: ${category}`);
    }

    const level = this.getBurnoutLevel(score, category);
    return this.getResilienceFromBurnoutLevel(level);
  },
  mapBurnoutCategoryTitleToResilience(text) {
    return BurnoutToResilienceCategoriesMap[text] || text;
  },
  getResilienceFromBurnoutLevel(burnoutLevel) {
    if (!Object.values(BurnoutLevel).includes(burnoutLevel)) {
      sentry.captureException(`Unknown burnout level: ${burnoutLevel}`);
      return null;
    }

    return BurnoutToResilienceMap[burnoutLevel];
  }
};
