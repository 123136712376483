<template>
  <div v-show="show" class="number-input-question" ref="numberInputQuestion">
    <v-text-field
      type="text"
      inputmode="numeric"
      v-model.number="selectedValue"
      label=""
      @change="emitToParent(selected)"
      :rules="rules"
      :suffix="translatedSuffix"
    />
  </div>
</template>

<script>
export default {
  name: "NumberInputQuestion",
  props: {
    errorMessage: String,
    index: Number,
    name: String,
    optional: Boolean,
    show: {
      default: true,
      type: Boolean
    },
    suffix: String,
    value: Number,
    width: Number
  },
  data() {
    return { selected: null, errorText: "" };
  },
  computed: {
    selectedValue: {
      get() {
        return this.value;
      },
      set(selectedValue) {
        if (Number.isInteger(selectedValue) && selectedValue >= 0) {
          this.selected = selectedValue;
        } else {
          this.selected = undefined;
        }
      }
    },
    rules() {
      return this.optional
        ? []
        : [v => !!v || this.$t("form.numberInput.mustAnswer")];
    }
  },
  created() {
    this.errorText = this.$t(this.errorMessage);
    this.translatedSuffix = this.$t(this.suffix);
  },
  mounted() {
    this.setInputWidth();
  },
  methods: {
    emitToParent(value) {
      this.$emit("input", value);
    },
    setInputWidth() {
      const width = this.width + (this.translatedSuffix?.length || 0) + "ch";
      this.$refs.numberInputQuestion.style.setProperty("--width", width);
    }
  }
};
</script>

<style scoped lang="scss">
::v-deep {
  .v-input__slot {
    width: var(--width);
  }

  .v-text-field__slot {
    input {
      text-align: right;
    }
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      margin: 0;
    }
  }
}
</style>
