<template>
  <v-card :class="$vuetify.breakpoint.smAndDown ? 'pa-4' : 'pa-8'">
    <v-btn class="closeButton" icon @click="$emit('input', false)" x-large>
      <v-icon>mdi-close</v-icon>
    </v-btn>
    <v-row justify="center" no-gutters class="my-8">
      <v-col cols="6" sm="4" lg="3">
        <v-img
          src="/images/onboardingTour/completedChecklist.jpg"
          contain
          max-width="300px"
        />
      </v-col>
    </v-row>
    <p
      class="text-center mb-10"
      :class="$vuetify.breakpoint.smAndDown ? 'text-h4' : 'text-h3'"
    >
      {{ $t("onboarding.adminCheckList.allCompleteTitle") }}
    </p>
    <p
      class="text-center mb-12"
      :class="$vuetify.breakpoint.smAndDown ? 'text-h5' : 'text-h4'"
    >
      {{ $t("onboarding.adminCheckList.allCompleteSubTitle") }}
    </p>
  </v-card>
</template>

<script>
export default {
  name: "AdminCheckListComplete",
  beforeDestroy() {
    this.$emit("closed");
  }
};
</script>

<style scoped>
.closeButton {
  position: absolute;
  right: 0;
  top: 0;
}
</style>
