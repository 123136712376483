const calculatePercentage = (value, total) => {
  return total > 0 ? (value / total) * 100 : 0;
};

const calculateIndustryDifference = (currentPercentage, industryAverage) => {
  return Number((currentPercentage - industryAverage).toFixed(1));
};

export const PAIN_IMPACT_CARD_COUNT = 3;

export const mapPainImpactData = data => {
  const statKeys = ["absenteeism", "referrals", "productivityLoss"];
  const mapping = {
    absenteeism: {
      value: "absenteeismCount",
      industryAverage: "absenteeismIndustryAverage"
    },
    referrals: {
      value: "referralCount",
      industryAverage: "referralsIndustryAverage"
    },
    productivityLoss: {
      value: "productivityLossCount",
      industryAverage: "productivityLossIndustryAverage"
    }
  };

  return statKeys.map(statKey => {
    const value = data[mapping[statKey].value];
    const totalUsers = data.totalCount;
    const industryAverage = data[mapping[statKey].industryAverage];
    const currentPercentage = calculatePercentage(value, totalUsers);

    return {
      statKey,
      value,
      totalUsers,
      industryAverage,
      currentPercentage: Math.round(currentPercentage),
      industryDifference: calculateIndustryDifference(
        currentPercentage,
        industryAverage
      )
    };
  });
};
