<template>
  <div
    class="ai-assistant-window"
    :class="{
      'bump-up': bumpUpAssistant
    }"
  >
    <ai-assistant-window-header class="window-header" />

    <div
      class="separator"
      :class="{ 'is-visible': !windowScrolledToTop }"
    ></div>

    <ai-assistant-window-scroller
      @is-scrolled-to-bottom="value => (windowScrolledToBottom = value)"
      @is-scrolled-to-top="value => (windowScrolledToTop = value)"
      @is-auto-scrolling="setAutoScrolling"
      ref="scroller"
    >
      <ai-assistant-window-body class="window-body" />
    </ai-assistant-window-scroller>

    <ai-assistant-scroll-down-button
      :visible="showScrollDownButton"
      @scroll-down="$refs.scroller.scrollByContainerHeight()"
    />
    <ai-assistant-window-footer class="window-footer" />
  </div>
</template>

<script>
import AiAssistantWindowHeader from "./WindowComponents/AiAssistantWindowHeader.vue";
import AiAssistantWindowBody from "./WindowComponents/AiAssistantWindowBody.vue";
import AiAssistantWindowFooter from "./WindowComponents/AiAssistantWindowFooter.vue";
import AiAssistantScrollDownButton from "@/components/AiAssistant/WindowComponents/AiAssistantScrollDownButton.vue";
import AiAssistantWindowScroller from "@/components/AiAssistant/WindowComponents/AiAssistantWindowScroller.vue";
import { mapGetters } from "vuex";

export default {
  name: "AiAssistantWindow",
  components: {
    AiAssistantWindowScroller,
    AiAssistantScrollDownButton,
    AiAssistantWindowHeader,
    AiAssistantWindowBody,
    AiAssistantWindowFooter
  },
  data() {
    return {
      isAutoScrolling: false,
      windowScrolledToBottom: true,
      windowScrolledToTop: true
    };
  },
  computed: {
    ...mapGetters("ai", ["bumpUpAssistant"]),
    showScrollDownButton() {
      // Only show this button if the container is not scrolled to the bottom,
      // And the container is not scrolling automatically, e.g. during streaming
      return !this.windowScrolledToBottom && !this.isAutoScrolling;
    }
  },

  methods: {
    setAutoScrolling(value) {
      this.$nextTick(() => (this.isAutoScrolling = value));
    }
  }
};
</script>

<style scoped>
.ai-assistant-window {
  background-color: white;
  border-radius: 12px;
  border: 1px solid #dde0e4;
  bottom: 20px;
  box-shadow: 0 6px 12px #2929291a !important;
  color: var(--v-vflText-base);
  display: flex;
  flex-direction: column;
  height: auto;
  max-height: max(-120px + 100vh, 350px);
  max-width: 400px;
  min-height: min(60vh, 350px);
  overflow: hidden;
  position: fixed;
  right: 20px;
  width: calc(100% - 40px);
  z-index: 2;

  &.bump-up {
    max-height: max(-200px + 100vh, 350px);
    bottom: 80px;
  }
}

.separator {
  background: hsla(0, 0%, 0%, 0.1);
  flex: 0 0 1px;
  margin: 0 1rem;
  opacity: 0;
  pointer-events: none;

  &.is-visible {
    opacity: 1;
  }
}
</style>
