﻿import {
  rebaScores,
  neckTorsoLegPostureScoringTable,
  upperArmLowerArmWristPostureScoringTable,
  overallScoringTable,
  combinedAnswers
} from "@/services/assessment/physical-labour/reba-scores.js";
import answers from "@/services/assessment/physical-labour/answers.json";
import { _ } from "lodash";
import { clamp } from "lodash/number.js";

export const MAX_REBA_SCORES = {
  neck: 3,
  torso: 5,
  leg: 4,
  upperArm: 6,
  lowerArm: 2,
  wrist: 3
};

/*
 * Calculate a reba score from the assessment results. The output will be in the range 1 - 15.
 */
export function calculateRebaScore(results) {
  const scoreA = calculateNeckTorsoLegPostureScore(results);
  const scoreB = calculateArmPostureScore(results);

  const overallPostureScore = calculateOverallPostureScore(scoreA, scoreB);
  const activityScore = calculateActivityScore(results);

  return overallPostureScore + activityScore;
}

/*
 * Takes an answer value from assessment results, and uses it to extract the score for this answer from the supplied scoring object.
 */
function parseAnswerToScore(answer, scoring) {
  if (!answer) {
    throw new Error("No answer found");
  }

  if (!scoring) {
    throw new Error("Need to supply scoring");
  }

  const score = scoring[answer];

  if (score === undefined) {
    throw new Error(
      `Failed to find a score within ${scoring} for answer ${answer}`
    );
  }

  return score;
}

/*
 * Takes an answers array for a multi-choice question from assessment results, and returns the supplied scoring if there are any matches, otherwise 0.
 */
function parseAnswersToScore(answers, scoring) {
  if (!answers || !Array.isArray(answers)) {
    throw new Error("No answers found");
  }

  if (!scoring) {
    throw new Error("Need to supply scoring");
  }

  // Filter answers that are present in the scoring object
  const validAnswers = _.filter(answers, answer =>
    scoring.hasOwnProperty(answer)
  );

  // Map these answers to their corresponding scores
  const answerScores = _.reduce(
    validAnswers,
    (result, answer) => {
      result[answer] = scoring[answer];
      return result;
    },
    {}
  );

  return answerScores;
}

/*
 * Outputs a trunk posture value between 1 and 12 representing the left hand side of the REBA worksheet.
 */
export function calculateNeckTorsoLegPostureScore(results) {
  const neckScore = parseNeckScore(results);
  const torsoScore = parseTorsoScore(results);
  const legScore = parseLegScore(results);

  const postureScore =
    neckTorsoLegPostureScoringTable[neckScore - 1][torsoScore - 1][
      legScore - 1
    ];
  const forceLoadScore = calculateForceLoadScore(results);

  return postureScore + forceLoadScore;
}

/*
 * Extracts the force load score surrounding weight lifted, and method of lifting.
 */
function calculateForceLoadScore(results) {
  const weightScore = parseAnswerToScore(
    results.reba.loadWeight,
    rebaScores.loadWeight
  );
  const suddenForceScore = results.reba.suddenMovementsOrHighForce
    ? parseAnswerToScore(
        results.reba.suddenMovementsOrHighForce,
        rebaScores.suddenMovementsOrHighForce
      )
    : 0;
  return weightScore + suddenForceScore;
}

/*
 * Outputs a score between 1 and 4 based on 3-axis neck rotation
 */
export function parseNeckScore(results) {
  const positionScores = parseAnswersToScore(
    results.reba.neckPosition,
    rebaScores.neckPosition
  );
  const positionScore = Math.max(...Object.values(positionScores));

  const twistedOrSideBentScores = parseAnswersToScore(
    results.reba.neckTwistedOrSideBent,
    rebaScores.neckTwistedOrSideBent
  );
  const twistedScore =
    twistedOrSideBentScores[answers.reba.neckTwistedOrSideBent.twisted] ?? 0;
  const sideBentScore =
    twistedOrSideBentScores[answers.reba.neckTwistedOrSideBent.sideBent] ?? 0;
  const twistedOrSideBentScore = clamp(twistedScore + sideBentScore, 0, 1);

  return positionScore + twistedOrSideBentScore;
}

/*
 * Combines torso pitch questions into a single value.
 * These questions are presented to the user in 2-parts (backwards,straight,forwards -> slightly,moderately,significantly).
 * By combining them, the calculation logic can be made easier.
 */
function combineTorsoPitchAnswers(results) {
  const torsoPosition = answers.reba.torsoPosition;
  const torsoCombinedPosition = combinedAnswers.torsoPosition;
  const torsoBentBackwardPosition = answers.reba.torsoBentBackwardPosition;
  const torsoBentForwardPosition = answers.reba.torsoBentForwardPosition;

  let pitchAnswers = [];

  switch (results.reba.torsoBentBackwardPosition) {
    case torsoBentBackwardPosition.slightly:
      pitchAnswers.push(torsoCombinedPosition.backwardsUpTo20Degrees);
      break;
    case torsoBentBackwardPosition.moderately:
      pitchAnswers.push(torsoCombinedPosition.backwardsOver20Degrees);
      break;
    default:
      break;
  }

  switch (results.reba.torsoBentForwardPosition) {
    case torsoBentForwardPosition.slightly:
      pitchAnswers.push(torsoCombinedPosition.forwardsUpTo20Degrees);
      break;
    case torsoBentForwardPosition.moderately:
      pitchAnswers.push(torsoCombinedPosition.forwardsUpTo60Degrees);
      break;
    case torsoBentForwardPosition.significantly:
      pitchAnswers.push(torsoCombinedPosition.forwardsOver60Degrees);
      break;
    default:
      break;
  }

  if (results.reba.torsoPosition.includes(torsoPosition.straight)) {
    pitchAnswers.push(torsoCombinedPosition.vertical);
  }

  if (pitchAnswers.length === 0) {
    throw new Error("Unknown torso position");
  }

  return pitchAnswers;
}

/*
 * Outputs a score between 1 and 5 based on 3-axis torso rotation
 */
export function parseTorsoScore(results) {
  const torsoPositionAnswer = combineTorsoPitchAnswers(results);
  const positionScores = parseAnswersToScore(
    torsoPositionAnswer,
    rebaScores.torsoPosition
  );
  const positionScore = Math.max(...Object.values(positionScores));
  const twistedOrSideBentScores = parseAnswersToScore(
    results.reba.torsoTwistedOrSideBent,
    rebaScores.torsoTwistedOrSideBent
  );

  const twistedScore =
    twistedOrSideBentScores[answers.reba.torsoTwistedOrSideBent.twisted] ?? 0;
  const sideBentScore =
    twistedOrSideBentScores[answers.reba.torsoTwistedOrSideBent.sideBent] ?? 0;
  const twistedOrSideBentScore = clamp(twistedScore + sideBentScore, 0, 1);

  return positionScore + twistedOrSideBentScore;
}

/*
 * Outputs a score between 1 and 4 based on leg anchoring and bending.
 */
export function parseLegScore(results) {
  const anchoringScores = parseAnswersToScore(
    results.reba.legPosition,
    rebaScores.legPosition
  );
  const anchoringScore = Math.max(...Object.values(anchoringScores));

  const bendingScores = parseAnswersToScore(
    results.reba.legsBent,
    rebaScores.legsBent
  );
  const bendingScore = Math.max(...Object.values(bendingScores));

  return anchoringScore + bendingScore;
}

/*
 * Combines arm rotation questions into a single value.
 * These questions are presented to the user in 2-parts (backwards,straight,forwards -> slightly,moderately,significantly).
 * By combining them, the calculation logic can be made easier.
 */
function combineUpperArmRotationAnswers(results) {
  const upperArmPosition = answers.reba.upperArmPosition;
  const upperArmRotation = combinedAnswers.upperArmRotation;
  const raisedUpperArmPosition = answers.reba.raisedUpperArmPosition;

  let positionAnswers = [];

  switch (results.reba.raisedUpperArmPosition) {
    case raisedUpperArmPosition.slightly:
      positionAnswers.push(upperArmRotation.forwardUpTo45Degrees);
      break;
    case raisedUpperArmPosition.extendedFarBeyondBody:
      positionAnswers.push(upperArmRotation.forwardUpTo90Degrees);
      break;
    case raisedUpperArmPosition.aboveShoulder:
      positionAnswers.push(upperArmRotation.aboveShoulder);
      break;
    default:
      break;
  }

  if (results.reba.upperArmPosition.includes(upperArmPosition.extended)) {
    positionAnswers.push(upperArmRotation.backwardsOver20Degrees);
  }

  if (results.reba.upperArmPosition.includes(upperArmPosition.closeToMyBody)) {
    positionAnswers.push(upperArmRotation.vertical);
  }

  if (positionAnswers.length === 0) {
    throw new Error("Unknown arm position");
  }

  return positionAnswers;
}

/*
 * Outputs a score between 1 and 6 based on arm pitch, roll, and shoulder position
 */
export function parseUpperArmScore(results) {
  const upperArmRotationAnswer = combineUpperArmRotationAnswers(results);
  const rotationScores = parseAnswersToScore(
    upperArmRotationAnswer,
    rebaScores.upperArmRotation
  );
  const rotationScore = Math.max(...Object.values(rotationScores));
  const upperArmExtraScores = parseAnswersToScore(
    results.reba.additionalUpperArmFactors,
    rebaScores.additionalUpperArmFactors
  );
  const factors = answers.reba.additionalUpperArmFactors;
  const raisedShoulderScore =
    upperArmExtraScores[factors.atLeastOneShoulderRaised] ?? 0;
  const abductedScore = upperArmExtraScores[factors.armRaisedToSideOfBody] ?? 0;
  const supportedScore = upperArmExtraScores[factors.armSupported] ?? 0;

  const additionalUpperArmFactorScore =
    raisedShoulderScore + abductedScore + supportedScore;
  return clamp(rotationScore + additionalUpperArmFactorScore, 1, 6);
}

/*
 * Outputs a score between 1 and 2 based on lower arm pitch.
 */
export function parseLowerArmScore(results) {
  const scores = parseAnswersToScore(
    results.reba.lowerArmPosition,
    rebaScores.lowerArmPosition
  );
  const score = Math.max(...Object.values(scores));
  return score;
}

/*
 * Outputs a score between 1 and 3 based on wrist pitch, yaw, and roll.
 */
export function parseWristScore(results) {
  const wristPositionScores = parseAnswersToScore(
    results.reba.wristPosition,
    rebaScores.wristPosition
  );
  const wristPositionScore = Math.max(...Object.values(wristPositionScores));

  const twistedOrSideBentScores = parseAnswersToScore(
    results.reba.wristTwistedOrSideBent,
    rebaScores.wristTwistedOrSideBent
  );
  const twistedScore =
    twistedOrSideBentScores[answers.reba.wristTwistedOrSideBent.twisted] ?? 0;
  const sideBentScore =
    twistedOrSideBentScores[answers.reba.wristTwistedOrSideBent.sideBent] ?? 0;
  const twistedOrSideBentScore = clamp(twistedScore + sideBentScore, 0, 1);

  return wristPositionScore + twistedOrSideBentScore;
}

/*
 * Outputs an arm posture value between 1 and 12 representing the right hand side of the REBA worksheet.
 */
export function calculateArmPostureScore(results) {
  const upperArmScore = parseUpperArmScore(results);
  const lowerArmScore = parseLowerArmScore(results);
  const wristScore = parseWristScore(results);

  const postureScore =
    upperArmLowerArmWristPostureScoringTable[upperArmScore - 1][
      lowerArmScore - 1
    ][wristScore - 1];
  const couplingScore = calculateCouplingScore(results);
  return postureScore + couplingScore;
}

/*
 * Looks at the score for the question about how securely the coupling is to carried objects.
 */
export function calculateCouplingScore(results) {
  return parseAnswerToScore(results.reba.coupling, rebaScores.coupling);
}

function calculateOverallPostureScore(scoreA, scoreB) {
  return overallScoringTable[scoreA - 1][scoreB - 1];
}

/*
 * Calculates the activity score from the 3 relevant questions.
 */
function calculateActivityScore(results) {
  const changingBodyPositionScore = parseAnswerToScore(
    results.reba.changesBodyPosition,
    rebaScores.changesBodyPosition
  );

  const repeatedActionScore = results.reba.changePositionFrequency
    ? parseAnswerToScore(
        results.reba.changePositionFrequency,
        rebaScores.changePositionFrequency
      )
    : 0;

  const rapidPostureChangeScore = results.reba.changePositionStability
    ? parseAnswerToScore(
        results.reba.changePositionStability,
        rebaScores.changePositionStability
      )
    : 0;

  return (
    changingBodyPositionScore + repeatedActionScore + rapidPostureChangeScore
  );
}
