<template>
  <v-snackbar
    v-model="snackbar.show"
    :color="color"
    :timeout="displayTime"
    right
  >
    <div class="d-flex justify-space-between align-center">
      {{ snackbar.message }}

      <button
        v-if="snackbar.undoAction"
        class="vfl-button-primary-outline is-small"
        @click="handleUndo"
      >
        {{ $t("userProfile.snackbar.undo") }}
      </button>
    </div>
  </v-snackbar>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "UserProfileSnackbar",
  computed: {
    ...mapGetters("userProfile", ["snackbar"]),
    color() {
      return this.snackbar.type === "success"
        ? "var(--v-vflGreyDark-base)"
        : "vflDarkRed";
    },
    displayTime() {
      if (this.undoAction) return 8000;
      return 3000;
    }
  },
  methods: {
    ...mapActions("userProfile", ["hideSnackbar"]),
    handleUndo() {
      if (this.snackbar.undoAction) {
        this.snackbar.undoAction();
      }
      this.hideSnackbar();
    }
  }
};
</script>
<style scoped lang="scss">
button {
  border-color: var(--v-vflTurquoise-base);
  color: var(--v-vflTurquoise-base);
  font-weight: 400;
  padding: 0.25rem 0.5rem !important;
}
</style>
