import RiskFactorScoring from "@/services/deskassessment/desk-assessment-risk-factor-scoring.js";
import i18n from "@/plugins/i18n.js";
import { camelCase } from "lodash";
import * as Constants from "@/constants/riskFactorConstants.js";
import { FocusArea } from "@/services/deskassessment/focus-areas";
import sentry from "@/sentry";
import { getPriorityPainArea } from "@/services/assessment/pain/pain-rating-service";
import { assessmentTypes } from "@/constants/constants.js";
import PainService from "@/services/assessment/pain/pain-assessment-service.js";
import { getTextBasedOnUserMobility } from "@/components/assessment/pre-existing-conditions/report/report-text-condition-service.js";
import answers from "@/components/assessment/pre-existing-conditions/answers.json";

const specialCaseAreas = ["abs", "ankle", "chest", "calf"];

function getOverallRating(results) {
  let setupRisk = RiskFactorScoring.GetSetupRating(results);
  let habitRisk = RiskFactorScoring.GetHabitsRating(results);
  return setupRisk === "zero" && habitRisk === "zero"
    ? "excellent"
    : "canImprove";
}

function isPainChronic(results, area) {
  let painResults = results.pain.areas[area];
  return painResults.rate === 1;
}

function isHighVAS(results, area) {
  let painResults = results.pain.areas[area];
  return painResults.level >= 8;
}

function getNumberOfPainAreas(results) {
  return Object.keys(results.pain.areas).length > 1
    ? "multiplePainAreas"
    : "onePainArea";
}

function getWhyItMattersTextForPainUser(results, goal) {
  let area = getPriorityPainArea(results, assessmentTypes.deskAssessment);
  let painGroup = PainService.getPainAreaGroup(area);
  let root = `deskAssessment.results.report.summary.whyThisMatters.${painGroup}.${goal}`;

  if (goal === "reducePain") {
    if (painGroup === "abs" || painGroup === "chest") {
      return getWhyItMattersSpecialCasePainGoal(results, area, root);
    } else {
      return getWhyItMattersPainGoal(results, area, root);
    }
  }

  return i18n.t(root);
}

function getWhyItMattersTextForPainFreeUser(results, goal) {
  let overallRating = getOverallRating(results);
  let root = `deskAssessment.results.report.summary.whyThisMatters.noPain.${overallRating}.${goal}`;
  let text = i18n.t(root);
  if (overallRating === "excellent") {
    return text;
  }

  let area = PainService.hasPain(results)
    ? getPriorityPainArea(results, assessmentTypes.deskAssessment)
    : Constants.defaultPriorityPainArea;
  let localeArea = i18n.t(`wellness.v2.userProgrammeNames.${area}`);
  let areaToAdd = { 0: localeArea };
  return {
    goal: i18n.t(`${root}.goal`, areaToAdd),
    risk: i18n.t(`${root}.risk`, areaToAdd),
    impact: i18n.t(`${root}.impact`, areaToAdd),
    commonality: i18n.t(
      `deskAssessment.results.report.summary.whyThisMatters.${area}.noGoal.commonality`
    )
  };
}

function getWhyItMattersPainGoal(results, area, root) {
  let painDuration = isPainChronic(results, area) ? "chronic" : "acute";
  let highVAS = isHighVAS(results, area) ? "highVAS" : "notHighVAS";
  let numberOfPainAreas = getNumberOfPainAreas(results);
  return i18n.t(`${root}.${painDuration}.${highVAS}.${numberOfPainAreas}`);
}

function getWhyItMattersSpecialCasePainGoal(results, area, root) {
  let highVAS = isHighVAS(results, area) ? "highVAS" : "notHighVAS";
  let numberOfPainAreas = getNumberOfPainAreas(results);
  let hasVASAndRating = i18n.te(`${root}.${highVAS}.${numberOfPainAreas}`);

  if (hasVASAndRating) {
    return i18n.t(`${root}.${highVAS}.${numberOfPainAreas}`);
  }
  return i18n.t(`${root}.${highVAS}`);
}

function getWhatItMeansSpecialCasePainGoal(results, area, painGroup) {
  let setupRating = RiskFactorScoring.GetSetupRating(results, painGroup);
  let habitsRating = RiskFactorScoring.GetHabitsRating(results);
  let highVAS = isHighVAS(results, area) ? "highVAS" : "notHighVAS";
  let rating = RiskFactorScoring.GetPainLikelihoodRating(results, painGroup);
  let root = `deskAssessment.results.report.summary.whatThisMeans.bottom.${painGroup}.setup${setupRating}.habits${habitsRating}`;
  let hasVASAndRating = i18n.te(`${root}.${highVAS}.${rating}`);
  let hasVAS = i18n.te(`${root}.${highVAS}`);
  let hasRating = i18n.te(`${root}.${rating}`);

  if (hasVASAndRating) {
    return i18n.t(`${root}.${highVAS}.${rating}`);
  } else if (hasVAS) {
    return i18n.t(`${root}.${highVAS}`);
  } else if (hasRating) {
    return i18n.t(`${root}.${rating}`);
  }
  return i18n.t(root);
}

function GetScoreBoundaries(ratings, score) {
  if (score <= ratings.medium) {
    return { floor: ratings.low, ceiling: ratings.medium };
  } else if (score <= ratings.high) {
    return { floor: ratings.medium, ceiling: ratings.high };
  }
  return { floor: ratings.high, ceiling: ratings.max };
}

export function getMappedPainLikelihoodPercentage(group, score) {
  if (score === 0) {
    return 0;
  }
  var ratings = Constants.painLikelihoodRatings[group];
  var boundaries = GetScoreBoundaries(ratings, score);
  var rating = RiskFactorScoring.GetLikelihoodRatingFromScore(score, ratings);
  var base = baseMappedPercentages[rating];
  var boundarySize = boundaries.ceiling - boundaries.floor;

  if (boundarySize === 0) {
    sentry.captureMessage(
      `Zero boundary size for group = ${group}, score = ${score}`,
      "error"
    );
    return 0;
  }

  let proportion = (score - boundaries.floor) / boundarySize;
  return base.percentage + proportion * 25;
}

export const baseMappedPercentages = {
  low: { percentage: 0, label: "notAtAll" },
  medium: { percentage: 25, label: "some" },
  high: { percentage: 50, label: "quiteStrongly" },
  max: { percentage: 75, label: "veryStrongly" }
};

export default {
  GetSuggestedUserGoal(results) {
    if (!results.userGoal) {
      let hasPain = Object.keys(results.pain.areas).length > 0;
      return hasPain ? FocusArea.ReducePain : FocusArea.ImproveWellbeing;
    }
    return FocusArea[results.userGoal];
  },
  GetPriorityPainAreaOrDefault(results) {
    return PainService.hasPain(results)
      ? getPriorityPainArea(results, assessmentTypes.deskAssessment)
      : Constants.defaultPriorityPainArea;
  },
  GetWhatItMeansTopText(results) {
    let area = this.GetPriorityPainAreaOrDefault(results);
    let painGroup = PainService.getPainAreaGroup(area);
    let rating = RiskFactorScoring.GetPainLikelihoodRating(results, painGroup);
    let section = PainService.hasPain(results) ? painGroup : "noPain";
    return i18n.t(
      `deskAssessment.results.report.summary.whatThisMeans.top.${section}.${rating}`
    );
  },
  GetWhatItMeansBottomText(results) {
    let area = this.GetPriorityPainAreaOrDefault(results);
    let painGroup = PainService.getPainAreaGroup(area);
    if (specialCaseAreas.includes(painGroup)) {
      return getWhatItMeansSpecialCasePainGoal(results, area, painGroup);
    }
    let setupRating = RiskFactorScoring.GetSetupRating(results, painGroup);
    let habitsRating = RiskFactorScoring.GetHabitsRating(results, painGroup);
    let section = PainService.hasPain(results) ? painGroup : "noPain";
    return i18n.t(
      `deskAssessment.results.report.summary.whatThisMeans.bottom.${section}.setup${setupRating}.habits${habitsRating}`
    );
  },
  GetWhyItMattersText(results, userGoal) {
    let goal = camelCase(userGoal);
    let text = !PainService.hasPain(results)
      ? getWhyItMattersTextForPainFreeUser(results, goal)
      : getWhyItMattersTextForPainUser(results, goal);
    const filteredText = filterTextBasedOnUserConditions(text, results);
    return filteredText;
  }
};

function filterTextBasedOnUserConditions(text, results) {
  if (typeof text !== "object") return text;
  const mobilityCondition = answers.setup.nonPainCondition.mobilityImpairment;
  return Object.keys(text).reduce((acc, k) => {
    acc[k] = getTextBasedOnUserMobility(text[k], results, mobilityCondition);
    return acc;
  }, {});
}
