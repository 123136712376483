<template>
  <div class="image-container">
    <div class="text-container">
      <h6 class="text-h6 mb-6">
        {{ $t("physicalLabourAssessment.report.perfectRebaScore.title") }}
      </h6>
      <i18n
        :path="`${$options.cardPath}.text.${painAreas ? 'pain' : 'noPain'}`"
      >
        <template v-slot:task>
          <span class="font-weight-bold">{{ taskText }}</span>
        </template>
        <template v-slot:rating>
          <span class="font-weight-bold">{{ rating }}</span>
        </template>
        <template v-slot:painAreas>
          <span class="font-weight-bold">{{ painAreas }}</span>
        </template>
      </i18n>
    </div>
    <img src="/images/illustrations/waving-hands.svg" class="hands-image" />
  </div>
</template>

<script>
import Answers from "@/services/assessment/physical-labour/answers.json";
import PainService from "@/services/assessment/pain/pain-assessment-service.js";
const taskPath = "physicalLabourAssessment.schema.jobNature.question.answers";
const cardPath = "physicalLabourAssessment.report.perfectRebaScore";
export default {
  name: "RebaGoodScoreMessage",
  props: {
    results: Object
  },
  cardPath: cardPath,
  computed: {
    taskText() {
      const taskKeys = Object.keys(Answers.reba.chosenJobActivity);
      const chosenJobActivity =
        taskKeys[this.results.reba.chosenJobActivity - 1];
      const activity =
        chosenJobActivity ??
        taskKeys[this.results.jobNature.jobActivities[0] - 1];

      if (!activity) {
        return this.$t(`${cardPath}.text.genericTask`);
      }
      return this.$t(`${taskPath}.${activity}.title`).toLocaleLowerCase();
    },
    painAreas() {
      return PainService.getAllPainAreasText(this.results);
    }
  }
};
</script>

<style lang="scss" scoped>
.image-container {
  position: relative;
  width: 100%;
  min-height: 340px;
  background: url("/images/illustrations/confetti.svg") center / cover no-repeat;
  margin: 0 auto;
  border: 1px solid #dde0e4;
  border-radius: 2rem;
  overflow: hidden;
}

.text-container {
  position: absolute;
  top: 40%;
  left: 50%;
  width: 45%;
  transform: translate(-50%, -50%);
  text-align: center;
  z-index: 2;
}

.hands-image {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 800px;
  height: auto;
  z-index: 1;
}

@media (max-width: 768px) {
  .text-container {
    width: 80%;
  }
  .hands-image {
    width: 600px;
  }
}
</style>
