import seedQuestions from "@/assets/json/ai-seed-questions-report-card.json";
import { ReportCardSeedQuestionId, SimsItem } from "@/services/ai/constants.js";
import {
  hasMultipleScreens,
  isItemMissing,
  isItemPresent,
  findItems,
  isMonitorAtEyeLevel
} from "@/services/deskassessment/results-utils.js";
import { IDS as InfoCard } from "@/components/common/report/cards/report-card-ids.js";

class QuestionHandler {
  shouldShowQuestion(questionId, results) {
    return false;
  }
}

export class HomeQuestionHandler extends QuestionHandler {
  shouldShowQuestion(questionId, results) {
    return results.setup.workingfromhome === 1;
  }
}

export class BreakQuestionHandler extends QuestionHandler {
  constructor() {
    super();
    this.rules = new Map([
      [ReportCardSeedQuestionId.BREAK_PAIN_1, () => true],
      [ReportCardSeedQuestionId.BREAK_BEHAVIOUR_1, () => true],
      [
        ReportCardSeedQuestionId.BREAK_BEHAVIOUR_2,
        results => results.setup.breaks === 2
      ],
      [
        ReportCardSeedQuestionId.BREAK_BEHAVIOUR_3,
        results => results.setup.lunch === 2
      ],
      [
        ReportCardSeedQuestionId.BREAK_BEHAVIOUR_4,
        results => results.setup.lunch !== 2
      ]
    ]);
  }

  shouldShowQuestion(questionId, results) {
    const rule = this.rules.get(questionId);
    return rule ? rule(results) : false;
  }
}

export class ChairQuestionHandler extends QuestionHandler {
  constructor() {
    super();
    this.rules = new Map([
      [
        ReportCardSeedQuestionId.CHAIR_EQUIPMENT_1,
        this.hasAnySeatExcludingWheelchair
      ],
      [
        ReportCardSeedQuestionId.CHAIR_EQUIPMENT_2,
        this.hasAnySeatExcludingWheelchair
      ],
      [ReportCardSeedQuestionId.CHAIR_BEHAVIOUR_1, this.hasAdjustableChair],
      [ReportCardSeedQuestionId.CHAIR_PAIN_1, this.hasAdjustableChair],
      [
        ReportCardSeedQuestionId.CHAIR_EQUIPMENT_3,
        this.hasChairWithoutBackSupport
      ],
      [ReportCardSeedQuestionId.CHAIR_PAIN_2, this.hasChairWithoutBackSupport],
      [ReportCardSeedQuestionId.CHAIR_EQUIPMENT_4, this.hasGymBall],
      [ReportCardSeedQuestionId.CHAIR_PAIN_3, this.hasGymBall],
      [ReportCardSeedQuestionId.CHAIR_EQUIPMENT_5, this.hasBeanBag],
      [ReportCardSeedQuestionId.CHAIR_PAIN_4, this.hasBeanBag]
    ]);
  }

  shouldShowQuestion(questionId, results) {
    const rule = this.rules.get(questionId);
    return rule ? rule.call(this, results) : false;
  }

  hasAnySeatExcludingWheelchair(results) {
    const deskItems = results.setup?.deskItems || [];
    return isItemPresent(deskItems, [
      SimsItem.CHAIR_NO_ARMREST,
      SimsItem.CHAIR_WITH_ARMREST,
      SimsItem.STOOL,
      SimsItem.GYM_BALL,
      SimsItem.BEAN_BAG
    ]);
  }

  hasGymBall(results) {
    const deskItems = results.setup?.deskItems || [];
    return isItemPresent(deskItems, [SimsItem.GYM_BALL]);
  }

  hasBeanBag(results) {
    const deskItems = results.setup?.deskItems || [];
    return isItemPresent(deskItems, [SimsItem.BEAN_BAG]);
  }

  hasAdjustableChair(results) {
    return results.setup.seatadjustable === 1;
  }

  hasChairWithoutBackSupport(results) {
    return results.setup.backsupport === 2;
  }
}

export class HoursQuestionHandler extends QuestionHandler {
  constructor() {
    super();
    this.rules = new Map([
      [ReportCardSeedQuestionId.HOURS_PAIN_1, this.hasNoBreaks],
      [ReportCardSeedQuestionId.HOURS_BEHAVIOUR_1, this.hasNoBreaks],
      [ReportCardSeedQuestionId.HOURS_PAIN_2, this.hasNoBreaksAndLongHours]
    ]);
  }

  shouldShowQuestion(questionId, results) {
    const rule = this.rules.get(questionId);
    return rule ? rule.call(this, results) : false;
  }

  hasNoBreaks(results) {
    return results.setup.breaks === 2;
  }

  hasLongWorkingHours(results) {
    return results.setup.hours > 8;
  }

  hasNoBreaksAndLongHours(results) {
    return this.hasNoBreaks(results) && this.hasLongWorkingHours(results);
  }
}

export class KeyboardQuestionHandler extends QuestionHandler {
  constructor() {
    super();
    this.rules = new Map([
      [
        ReportCardSeedQuestionId.KEYBOARD_EQUIPMENT_1,
        this.hasWristsNotStraight
      ],
      [
        ReportCardSeedQuestionId.KEYBOARD_BEHAVIOUR_1,
        this.hasWristsNotStraight
      ],
      [ReportCardSeedQuestionId.KEYBOARD_BEHAVIOUR_2, this.hasNoWristSpace],
      [ReportCardSeedQuestionId.KEYBOARD_EQUIPMENT_2, this.hasNoWristSpace],
      [ReportCardSeedQuestionId.KEYBOARD_EQUIPMENT_3, this.hasNoWristSpace],
      [
        ReportCardSeedQuestionId.KEYBOARD_EQUIPMENT_4,
        this.hasItemsNotEasilyReached
      ],
      [
        ReportCardSeedQuestionId.KEYBOARD_BEHAVIOUR_3,
        this.hasItemsNotEasilyReached
      ]
    ]);
  }

  shouldShowQuestion(questionId, results) {
    const rule = this.rules.get(questionId);
    return rule ? rule.call(this, results) : false;
  }

  hasWristsNotStraight(results) {
    return results.setup.relaxedarms === 2;
  }

  hasNoWristSpace(results) {
    return results.setup.wristspace === 2;
  }

  hasItemsNotEasilyReached(results) {
    return results.setup.easytoreach === 2;
  }
}

export class LaptopQuestionHandler extends QuestionHandler {
  constructor() {
    super();
    this.rules = new Map([
      [ReportCardSeedQuestionId.LAPTOP_EQUIPMENT_1, this.hasNoLaptopStand],
      [ReportCardSeedQuestionId.LAPTOP_EQUIPMENT_2, this.hasNoLaptopStand],
      [
        ReportCardSeedQuestionId.LAPTOP_EQUIPMENT_3,
        this.hasNoExternalMouseOrKeyboard
      ],
      [
        ReportCardSeedQuestionId.LAPTOP_EQUIPMENT_4,
        this.hasNoExternalMouseOrKeyboard
      ]
    ]);
  }

  shouldShowQuestion(questionId, results) {
    const rule = this.rules.get(questionId);
    return rule ? rule.call(this, results) : false;
  }

  hasNoLaptopStand(results) {
    const deskItems = results.setup?.deskItems || [];
    return isItemMissing(deskItems, [SimsItem.LAPTOP_STAND]);
  }

  hasNoExternalMouseOrKeyboard(results) {
    const deskItems = results.setup?.deskItems || [];
    return (
      isItemMissing(deskItems, [SimsItem.MOUSE, SimsItem.ERGONOMIC_MOUSE]) ||
      isItemMissing(deskItems, [SimsItem.KEYBOARD, SimsItem.ERGONOMIC_KEYBOARD])
    );
  }
}

export class MuscleStrainQuestionHandler extends QuestionHandler {
  constructor() {
    super();
    this.rules = new Map([
      [ReportCardSeedQuestionId.MUSCLE_STRAIN_EQUIPMENT_1, this.hasMicroDesk],
      [ReportCardSeedQuestionId.MUSCLE_STRAIN_PAIN_1, this.hasMicroDesk],
      [
        ReportCardSeedQuestionId.MUSCLE_STRAIN_EQUIPMENT_2,
        this.hasFrequentCallsNoHeadset
      ],
      [
        ReportCardSeedQuestionId.MUSCLE_STRAIN_EQUIPMENT_3,
        this.hasFrequentCallsNoHeadset
      ],
      [
        ReportCardSeedQuestionId.MUSCLE_STRAIN_EQUIPMENT_4,
        this.hasMultipleScreens
      ],
      [
        ReportCardSeedQuestionId.MUSCLE_STRAIN_EQUIPMENT_5,
        this.hasMonitorNotAtEyeLevel
      ],
      [
        ReportCardSeedQuestionId.MUSCLE_STRAIN_EQUIPMENT_6,
        this.hasMonitorNotAtEyeLevel
      ],
      [
        ReportCardSeedQuestionId.MUSCLE_STRAIN_EQUIPMENT_7,
        this.hasMultipleStacksOfPaper
      ],
      [
        ReportCardSeedQuestionId.MUSCLE_STRAIN_BEHAVIOUR_1,
        this.hasMultipleStacksOfPaperOrClutter
      ]
    ]);
  }

  shouldShowQuestion(questionId, results) {
    const rule = this.rules.get(questionId);
    return rule ? rule.call(this, results) : false;
  }

  hasMicroDesk(results) {
    const deskItems = results.setup?.deskItems || [];
    return isItemPresent(deskItems, [SimsItem.MICRO_DESK]);
  }

  hasFrequentCallsNoHeadset(results) {
    return results.setup.phonecalls === 1 && results.setup.headset !== 1;
  }

  hasMultipleScreens(results) {
    return hasMultipleScreens(results);
  }

  hasMonitorNotAtEyeLevel(results) {
    return !isMonitorAtEyeLevel(results);
  }

  hasMultipleStacksOfPaper(results) {
    const deskItems = results.setup?.deskItems || [];
    return findItems(deskItems, [SimsItem.STACK_OF_PAPER]).length > 1;
  }

  hasMultipleStacksOfPaperOrClutter(results) {
    const deskItems = results.setup?.deskItems || [];
    const stacksOfPaper = findItems(deskItems, [SimsItem.STACK_OF_PAPER]);
    const clutter = findItems(deskItems, [SimsItem.CLUTTER]);
    return stacksOfPaper.length > 1 || clutter.length > 1;
  }
}

export class AlternativeChairQuestionHandler extends QuestionHandler {
  constructor() {
    super();
    this.rules = new Map([
      [
        ReportCardSeedQuestionId.ALTERNATE_CHAIR_PAIN_1,
        this.hasKneelingOrSaddleChair
      ],
      [
        ReportCardSeedQuestionId.ALTERNATE_CHAIR_EQUIPMENT_1,
        this.hasKneelingChair
      ],
      [
        ReportCardSeedQuestionId.ALTERNATE_CHAIR_EQUIPMENT_2,
        this.hasSaddleChair
      ]
    ]);
  }

  shouldShowQuestion(questionId, results) {
    const rule = this.rules.get(questionId);
    return rule ? rule.call(this, results) : false;
  }

  hasKneelingOrSaddleChair(results) {
    const deskItems = results.setup?.deskItems || [];

    return (
      isItemPresent(deskItems, [SimsItem.KNEELING_CHAIR]) ||
      isItemPresent(deskItems, [SimsItem.SADDLE_CHAIR])
    );
  }

  hasKneelingChair(results) {
    const deskItems = results.setup?.deskItems || [];

    return isItemPresent(deskItems, [SimsItem.KNEELING_CHAIR]);
  }

  hasSaddleChair(results) {
    const deskItems = results.setup?.deskItems || [];

    return isItemPresent(deskItems, [SimsItem.SADDLE_CHAIR]);
  }
}

export class StandingDeskQuestionHandler extends QuestionHandler {
  constructor() {
    super();
    this.rules = new Map([
      [
        ReportCardSeedQuestionId.STANDING_DESK_BEHAVIOUR_1,
        this.hasNoStandingDeskAndNoBreakActivity
      ],
      [
        ReportCardSeedQuestionId.STANDING_DESK_BEHAVIOUR_2,
        this.hasNoStandingDeskAndNoBreakActivity
      ],
      [
        ReportCardSeedQuestionId.STANDING_DESK_BEHAVIOUR_3,
        this.hasNoStandingDeskAndNoBreakActivity
      ],
      [
        ReportCardSeedQuestionId.STANDING_DESK_EQUIPMENT_1,
        this.hasStandingDesk
      ],
      [ReportCardSeedQuestionId.STANDING_DESK_EQUIPMENT_2, this.hasStandingDesk]
    ]);
  }

  shouldShowQuestion(questionId, results) {
    const rule = this.rules.get(questionId);
    return rule ? rule.call(this, results) : false;
  }

  hasNoStandingDeskAndNoBreakActivity(results) {
    return (
      results.setup.standingdesk === 2 && results.setup.breakActivity === 2
    );
  }

  hasStandingDesk(results) {
    return results.setup.standingdesk === 1;
  }
}

export default {
  handlers: new Map([
    [InfoCard.WORK_FROM_HOME, new HomeQuestionHandler()],
    [InfoCard.BREAKS, new BreakQuestionHandler()],
    [InfoCard.CHAIR, new ChairQuestionHandler()],
    [InfoCard.HOURS, new HoursQuestionHandler()],
    [InfoCard.KEYBOARD, new KeyboardQuestionHandler()],
    [InfoCard.LAPTOP, new LaptopQuestionHandler()],
    [InfoCard.STRAIN, new MuscleStrainQuestionHandler()],
    [InfoCard.STANDING_DESK_CARD, new StandingDeskQuestionHandler()],
    [InfoCard.ALTERNATIVE_ERGONOMIC_SEAT, new AlternativeChairQuestionHandler()]
  ]),

  getSeedQuestionsForSetup(results, infoCardType) {
    return seedQuestions
      .filter(question => question.infoCard === infoCardType)
      .filter(question => this.shouldShowQuestion(question, results));
  },

  shouldShowQuestion(question, results) {
    const handler = this.handlers.get(question.infoCard);
    return handler ? handler.shouldShowQuestion(question.id, results) : false;
  }
};
