import Vue from "vue";
import Vuex from "vuex";
import { Auth } from "aws-amplify";
import { getUserTeamAndSubscriptionBaseInformation } from "./customApi";
import criteria from "@/featureflags/criteria.js";
import features from "@/featureflags/features.json";
import i18n from "@/plugins/i18n.js";
import sentry from "@/sentry";
import { VIDARoles, SSORoles } from "@/services/authorization/user-types.js";
import { aiAssistantStore } from "./components/AiAssistant/store.js";
import { layoutStore } from "./components/layout/layout-store.js";
import { userProfileStore } from "@/components/user-profile/store.js";
import { adminDashboardStore } from "@/components/dashboard/dashboard-store.js";
import { routerStore } from "@/router/store.js";
import { sidePanelStore } from "@/components/dashboard/side-panel-store.js";
import { peopleTableNotificationStore } from "@/components/common/assessment-history/send-assessment-notification-dialog/store.js";
import messagingStore from "@/components/messaging/store.js";
import { teamStore } from "./components/layout/team-store.js";

const fflip = require("fflip");

Vue.use(Vuex);
let ensureBasicUserDataLoadedPromise;

const getDefaultState = () => {
  return {
    user: null,
    teamMemberId: null,
    teamMember: null,
    appLoading: false,
    teamId: null,
    teamName: null,
    teamLogoURL: null,
    deactivatedUser: false,
    userId: "",
    userName: "",
    userEmail: "",
    userJoinedVidaDate: null,
    userAccessTier: "",
    tierInterval: "",
    signedIn: false,
    activeAsyncRequestsCount: 0,
    currentUserTeamAndSubscriptionBasicInformation: null,
    featureFlags: {},
    scoreSaved: false,
    ssoSignIn: false,
    teamIdpName: "",
    provisionedTeam: false,
    showIncompleteAssessmentDialog: true,
    showRefreshBar: false,
    metricSystem: true,
    onboardingChecklist: {
      hoveredItem: undefined,
      hoverTimeouts: [],
      items: {
        completeTeamProfile: true,
        selfAssessment: false,
        viewPainCoach: false,
        inviteUser: false,
        viewTeamData: false
      }
    },
    modalOpen: false,
    featureIntros: null,
    teamMemberInfo: null,
    showDummySidebar: false,
    redirectReportAssessment: null,
    slackRedirectCode: null,
    redirectToWellness: false,
    role: null,
    seatInfo: null,
    featureIntrosShowing: [],
    adminAssessmentToView: null,
    recommendationsToViewInReport: null,
    redirectPage: null,
    redirectAction: null,
    usePredefinedDashboardMetrics: false,
    originSource: null,
    ssoUrl: null,
    jumpIntoPainAssessment: false,
    painCoachAutoPlay: null
  };
};

const state = getDefaultState();

const actions = {
  resetLoginState({ commit }) {
    commit("resetState");
  },
  async setCurrentUserData({ commit }, teamMberInfo, user) {
    if (!user) {
      user = await Auth.currentAuthenticatedUser();
    }
    if (user?.attributes.identities && state.teamIdpName) {
      commit("setSSOUserData", { ...user, ...teamMberInfo });
    } else {
      commit("setUserData", teamMberInfo);
    }
  },
  async setSimpleUserDetails({ commit, dispatch }, details) {
    commit("setuserId", details.userId);
    commit("setUserName", details.userName);
    commit("setUserEmail", details.email.value);
    commit("setTeamIdpName", details.teamIdpName);
    commit("setTeamId", details.teamId);
    commit("setDeactivatedUser", details.deactivatedUser);
    commit("setCurrentUserTeamAndSubscriptionBasicInformation", {
      priceMetaData: details.priceMetaData,
      accessTier: details.accessTier
    });
    commit("setUserAccessTier", details.accessTier);
    commit("setUserJoinedVidaDate", details.createdAt);
  },
  async setFeatureFlags({ commit, dispatch }, details) {
    if (!details) {
      return;
    }
    return dispatch("getUserFeatureFlags", details).then(featureFlags => {
      commit("setUserFeatureFlags", featureFlags);
    });
  },
  async getUserTeamAndSubscriptionBaseInformation({ commit, dispatch }, info) {
    if (
      ensureBasicUserDataLoadedPromise &&
      !info.forceToUpdateExistedInformation
    )
      return await ensureBasicUserDataLoadedPromise;
    // eslint-disable-next-line no-async-promise-executor
    ensureBasicUserDataLoadedPromise = new Promise(async resolve => {
      try {
        const result = await getUserTeamAndSubscriptionBaseInformation(
          info.memberId
        );

        commit("setCurrentUserTeamAndSubscriptionBasicInformation", result);
        commit("setUserAccessTier", result.accessTier);
      } catch (err) {
        sentry.captureException(err);
      } finally {
        resolve(state);
      }
    });
    return await ensureBasicUserDataLoadedPromise;
  },
  getUserFeatureFlags(_context, details) {
    fflip.config({
      criteria: criteria,
      features: features
    });
    const user = {
      features: details.features ?? []
    };
    return fflip.getFeaturesForUser(user);
  }
};
const mutations = {
  resetState(state) {
    // Merge rather than replace so we don't lose observers
    // https://github.com/vuejs/vuex/issues/1118
    Object.assign(state, getDefaultState());
  },
  setCurrentUserTeamAndSubscriptionBasicInformation(state, result) {
    state.currentUserTeamAndSubscriptionBasicInformation = result;
  },
  setuserId(state, userId) {
    state.userId = userId;
  },
  setUserName(state, name) {
    state.userName = name;
  },
  setUserEmail(state, email) {
    state.userEmail = email ? email.toLowerCase() : null;
  },
  setUserJoinedVidaDate(state, date) {
    state.userJoinedVidaDate = date;
  },
  setTeamIdpName(state, idpName) {
    state.teamIdpName = idpName;
  },
  setProvisionedTeam(state, usesProvisioning) {
    state.provisionedTeam = usesProvisioning;
  },
  setTeamId(state, teamId) {
    state.teamId = teamId;
  },
  setTeamName(state, name) {
    state.teamName = name;
  },
  setTeamLogoURL(state, url) {
    state.teamLogoURL = url;
  },
  setDeactivatedUser(state, deactivated) {
    state.deactivatedUser = deactivated;
  },
  setTeamMemberId(state, id) {
    state.teamMemberId = id;
    state.teamMember = null;
  },
  setTeamMember(state, teamMember) {
    state.teamMember = teamMember;
    state.teamMemberId = teamMember.teamMemberId;
  },
  setAppLoading(state, loading) {
    state.appLoading = loading;
  },
  setShowIncompleteAssessmentDialog(state, show) {
    state.modalOpen = show;
    state.showIncompleteAssessmentDialog = show;
  },
  setUserData(state, teamInfo) {
    state.userId = teamInfo?.endUserId;
    state.userName = teamInfo?.userName;
    state.userEmail = teamInfo?.email.value;
    state.signedIn = !!teamInfo?.email.value;
    state.role = teamInfo?.role ?? VIDARoles.EndUser;
  },
  setSSOUserData(state, userInfo) {
    let email = userInfo ? userInfo.attributes.email.toLowerCase() : "";
    state.userId = userInfo?.endUserId;
    state.userName = userInfo?.userName;
    state.userEmail = email;
    state.signedIn = !!email;
    let userType = userInfo?.attributes["custom:userType"];

    if (SSORoles[userType] >= 0) {
      state.role = SSORoles[userType];
    } else if (userInfo?.role != null && userInfo?.role >= 0) {
      state.role = userInfo.role;
    } else {
      state.role = VIDARoles.EndUser;
    }

    state.ssoSignIn = !!userInfo?.attributes.identities;
  },
  setUserAccessTier(state, tier) {
    state.userAccessTier = tier;
  },
  setUserAccessTierInterval(state, interval) {
    state.tierInterval = interval;
  },
  setUserFeatureFlags(state, flags) {
    state.featureFlags = flags;
  },
  markUserAsNotNew(state) {
    state.currentUserTeamAndSubscriptionBasicInformation.isNew = false;
  },
  addAsyncRequest: state => state.activeAsyncRequestsCount++,
  removeAsyncRequest: state => state.activeAsyncRequestsCount--,
  scoreSaved: state => (state.scoreSaved = true),
  setShowRefreshBar(state, showBar) {
    state.showRefreshBar = showBar;
  },
  setHoveredOverOnboardingItem(state, hoveredItem) {
    if (hoveredItem) {
      state.onboardingChecklist.hoverTimeouts.forEach(timeout =>
        clearTimeout(timeout)
      );
      state.onboardingChecklist.hoveredItem = hoveredItem;
    } else {
      state.onboardingChecklist.hoverTimeouts.push(
        setTimeout(() => {
          state.onboardingChecklist.hoveredItem = undefined;
        }, 4000)
      );
    }
  },
  removeOnboardingChecklist(state) {
    state.onboardingChecklist = {};
  },
  setOnboardingChecklist(state, checklist) {
    //merge objects don't replace otherwise computed prop refs are dropped
    state.onboardingChecklist = { ...state.onboardingChecklist, ...checklist };
  },
  openModal(state) {
    state.modalOpen = true;
  },
  closeModal(state) {
    state.modalOpen = false;
  },
  setFeatureIntros(state, intros) {
    state.featureIntros = intros;
  },
  setTeamMemberInfo(state, teamMemberInfo) {
    state.teamMemberInfo = teamMemberInfo;
  },
  setCompanyRole(state, companyRole) {
    state.teamMemberInfo.companyRole = companyRole;
  },
  setDummySideBar(state, show) {
    state.showDummySidebar = show;
  },
  setRedirectAssessment(state, assessment) {
    state.redirectReportAssessment = assessment;
  },
  setRedirectCode(state, code) {
    state.slackRedirectCode = code;
  },
  setWellnessRedirect(state, redirect) {
    state.redirectToWellness = redirect;
  },
  setSeatInfo(state, seatInfo) {
    state.seatInfo = seatInfo;
  },
  setFeatureFlag(state, { feature, value }) {
    Vue.set(state.featureFlags, feature, value);
  },
  addVisibleFeatureIntro(state, value) {
    if (!state.featureIntrosShowing.includes(value)) {
      state.featureIntrosShowing.push(value);
    }
  },
  removeVisibleFeatureIntro(state, value) {
    var index = state.featureIntrosShowing.indexOf(value);
    if (index > -1) {
      state.featureIntrosShowing.splice(index, 1);
    }
  },
  setAdminAssessmentToView(state, value) {
    state.adminAssessmentToView = value;
  },
  setRecommendationsToViewInReport(state, value) {
    state.recommendationsToViewInReport = value;
  },
  setRedirectPage(state, page) {
    state.redirectPage = page;
  },
  setRedirectAction(state, action) {
    state.redirectAction = action;
  },
  setPainCoachAutoPlay(state, videoType) {
    state.painCoachAutoPlay = videoType;
  },
  setOriginSource(state, originSource) {
    state.originSource = originSource;
  },
  setJumpIntoPainAssessment(state, jumpIntoPainAssessment) {
    state.jumpIntoPainAssessment = jumpIntoPainAssessment;
  },
  setSSOUrl(state, ssoUrl) {
    state.ssoUrl = ssoUrl;
  }
};
const getters = {
  ssoSignIn: state => state.ssoSignIn,
  teamMemberId: state => state.teamMemberId,
  teamMember: state => state.teamMember,
  appLoading: state => state.appLoading,
  teamId: state => state.teamId,
  teamName: state => state.teamName,
  teamLogoURL: state => state.teamLogoURL,
  isSimpleUser: state => state.role === VIDARoles.EndUser,
  isAdminUser: state => {
    return (
      state.role === VIDARoles.SuperAdmin ||
      state.role === VIDARoles.Admin ||
      state.role === VIDARoles.RestrictedAdmin
    );
  },
  isRestrictedAdminUser: state => state.role === VIDARoles.RestrictedAdmin,
  isSuperAdmin: state => state.role === VIDARoles.SuperAdmin,
  isPrimaryUser: state =>
    state.currentUserTeamAndSubscriptionBasicInformation?.primaryAccountEmail
      ?.value == state.userEmail,
  deactivatedUser: state => state.deactivatedUser,
  userId: state => state.userId,
  userName: state => state.userName,
  userEmail: state => state.userEmail,
  userJoinedVidaDate: state => state.userJoinedVidaDate,
  userAccessTier: state => state.userAccessTier,
  tierInterval: state => state.tierInterval,
  signedIn: state => state.signedIn,
  teamIdpName: state => state.teamIdpName,
  provisionedTeam: state => state.provisionedTeam,
  activeAsyncRequestsCount: state => state.activeAsyncRequestsCount,
  currentUserTeamAndSubscriptionBasicInformation: state =>
    state.currentUserTeamAndSubscriptionBasicInformation,
  customRecommendations: state =>
    Object.fromEntries(
      Object.entries(state.featureFlags).filter(([key]) =>
        key.includes("customRecommendations")
      )
    ),
  customRecommendationOrder: state =>
    Object.fromEntries(
      Object.entries(state.featureFlags).filter(([key]) =>
        key.includes("customRecommendationOrder")
      )
    ),
  burnout: state =>
    state.featureFlags.burnoutFeatureOverride ||
    (!!state.currentUserTeamAndSubscriptionBasicInformation &&
      (state.currentUserTeamAndSubscriptionBasicInformation.accessTier ==
        "Free" ||
        (state.currentUserTeamAndSubscriptionBasicInformation.priceMetaData
          .Burnout === "Enabled" &&
          (state.role !== VIDARoles.EndUser ||
            (state.role === VIDARoles.EndUser &&
              !state.featureFlags.preventSimpleUsersBurnoutAssessments))))),
  showPrepopulatedBurnoutResults: state =>
    state.featureFlags.prepopulatedBurnoutResults,
  anonymousResults: state =>
    state.currentUserTeamAndSubscriptionBasicInformation?.name ===
      "Speedinvest GmbH" || state.role === VIDARoles.RestrictedAdmin,
  showIncompleteAssessmentDialog: state => state.showIncompleteAssessmentDialog,
  disableEmails: state => state.featureFlags.disableVitrueEmails,
  disableManualAddUser: state => state.featureFlags.disableManualAddUser,
  showRefreshBar: state => state.showRefreshBar,
  featureFlags: state => state.featureFlags,
  metricSystem: () => (i18n.locale === "en-US" ? false : true),
  showTextNotifications: state => state.featureFlags.showTextNotifications,
  showOnboardingChecklist: state =>
    !state.modalOpen &&
    !!state.onboardingChecklist.id &&
    state.onboardingChecklist.dismissed === false,
  onboardingChecklist: state => state.onboardingChecklist,
  vitrueDeveloper: state => state.featureFlags.vitrueDeveloper,
  trialAssessmentsExceeded: state =>
    state.currentUserTeamAndSubscriptionBasicInformation
      ?.usedAssessmentInvitationsCount >=
    state.currentUserTeamAndSubscriptionBasicInformation
      ?.freeTrialNumberOfAssessments,
  trialExpiredOrAssessmentsExceeded: (state, getters) =>
    state.currentUserTeamAndSubscriptionBasicInformation?.accessTier ===
      "TrialExpired" ||
    (state.currentUserTeamAndSubscriptionBasicInformation?.accessTier ===
      "Free" &&
      getters.trialAssessmentsExceeded),
  disableAssessments: (state, getters) =>
    getters.trialExpiredOrAssessmentsExceeded || getters.subscriptionExpired,
  subscriptionExpired: state =>
    state.currentUserTeamAndSubscriptionBasicInformation?.accessTier ===
    "SubscriptionExpired",
  isTrialUser: state =>
    state.currentUserTeamAndSubscriptionBasicInformation?.accessTier ===
      "TrialExpired" ||
    state.currentUserTeamAndSubscriptionBasicInformation?.accessTier === "Free",
  isActiveTrialUser: state =>
    state.currentUserTeamAndSubscriptionBasicInformation?.accessTier === "Free",
  subscriptionHasExpired: state =>
    state.currentUserTeamAndSubscriptionBasicInformation?.accessTier ===
    "SubscriptionExpired",
  modalOpen: state => state.modalOpen,
  isNewUser: state =>
    state.currentUserTeamAndSubscriptionBasicInformation?.isNew,
  featureIntros: state => state.featureIntros,
  teamMemberInfo: state => state.teamMemberInfo,
  showDummySidebar: state => state.showDummySidebar,
  redirectReportAssessment: state => state.redirectReportAssessment,
  hideBurnoutCompletely: state =>
    state.featureFlags.hideBurnoutCompletely &&
    !state.featureFlags.burnoutFeatureOverride,
  userIsInTeam: state =>
    (state.role === VIDARoles.EndUser &&
      state.teamMemberInfo?.endUserSignedUpToTeam) ||
    (state.role !== VIDARoles.EndUser && state.teamMemberInfo?.teamMemberId),
  slackRedirectCode: state => state.slackRedirectCode,
  showTeamSeats: state => state.featureFlags.showTeamSeats,
  allowAdminEditingUserTags: state =>
    state.featureFlags.allowAdminEditingUserTags,
  hidePainCoach: state => state.featureFlags.hidePainCoach,
  disablePainCoach: state => state.featureFlags.disablePainCoach,
  alternativeWebcamErrorMessage: state =>
    state.featureFlags.alternativeWebcamErrorMessage,
  redirectToWellness: state => state.redirectToWellness,
  showNextDayButton: state => state.featureFlags.showNextDayButton,
  team_ttqWel: state => state.featureFlags.team_ttqWel,
  team_xarkut: state => state.featureFlags.team_xarkut,
  team_hoiha: state => state.featureFlags.team_hoiha,
  team_xrppst: state => state.featureFlags.team_xrppst,
  hideHealthQuestions: state => state.featureFlags.hideHealthQuestions,
  testFeature: state => state.featureFlags.testFeature,
  seatInfo: state => state.seatInfo,
  hideSeatInfoWarning: state => state.featureFlags.hideSeatInfoWarning,
  showFeatureSelector: state => state.featureFlags.showFeatureSelector,
  clinicalTeam: state => state.featureFlags.clinicalTeam,
  showElevationPlannerPage: state =>
    state.featureFlags.showElevationPlannerPage,
  cognitiveAssessment: state => state.featureFlags.cognitiveAssessment,
  demoFeatures: state => state.featureFlags.demoFeatures,
  salesDemoFeatures: state => state.featureFlags.salesDemoFeatures,
  hideUpgradeRecommendations: state =>
    state.featureFlags.hideUpgradeRecommendations,
  featureIntrosShowing: state => state.featureIntrosShowing,
  adminAssessmentToView: state => state.adminAssessmentToView,
  blockCustomRecommendationsCreation: state =>
    state.featureFlags.blockCustomRecommendationsCreation,
  isLowerTierSubscription: state =>
    state.userAccessTier && state.userAccessTier === "Pro",
  isHigherTierSubscription: state =>
    state.userAccessTier &&
    (state.userAccessTier === "Pro+" || state.userAccessTier === "Enterprise"),
  recommendationsToViewInReport: state => state.recommendationsToViewInReport,
  redirectPage: state => state.redirectPage,
  redirectAction: state => state.redirectAction,
  askConsent: state => state.featureFlags.askConsent,
  driverAssessmentEnabled: state => state.featureFlags.driverAssessmentEnabled,
  disableStandingDesk: state => state.featureFlags.disableStandingDesk,
  generousStandingDeskLogic: state =>
    state.featureFlags.generousStandingDeskLogic,
  officeOnlyStandingDeskLogic: state =>
    state.featureFlags.officeOnlyStandingDeskLogic,
  physicalLabourAssessmentEnabled: state =>
    state.featureFlags.physicalLabourAssessmentEnabled,
  showDemoPainCoachStats: state => state.featureFlags.showDemoPainCoachStats,
  KcaRDa: state => state.featureFlags.KcaRDa,
  AwEATt: state => state.featureFlags.AwEATt,
  prefillPhysicalLabourAssessmentResponses: state =>
    state.featureFlags.prefillPhysicalLabourAssessmentResponses,
  prefillDriverAssessmentResponses: state =>
    state.featureFlags.prefillDriverAssessmentResponses,
  BDemo: state => state.featureFlags.BDemo,
  hidePhysicalLabourFromAdminDashboard: state =>
    state.featureFlags.hidePhysicalLabourFromAdminDashboard,
  burnoutFeatureOverride: state => state.featureFlags.burnoutFeatureOverride,
  enablePreExistingConditions: state =>
    state.featureFlags.enablePreExistingConditions,
  enableUserPassport: state => state.featureFlags.enableUserPassport,
  driversPreAssessmentChecklist: state =>
    state.featureFlags.driversPreAssessmentChecklist,
  hideAiFeatures: state => state.featureFlags.hideAiFeatures,
  disableAiFeatures: (state, getters) =>
    !getters.hideAiFeatures &&
    (state.featureFlags.disableAiFeatures ||
      getters.isTrialUser ||
      getters.subscriptionExpired),
  lmsReportVariant: state => state.featureFlags.lmsReportVariant,
  enableDeskAssessmentIntroV2: state =>
    state.featureFlags.enableDeskAssessmentIntroV2,
  showDemoAssessmentsInDashboardCards: state =>
    state.featureFlags.showDemoAssessmentsInDashboardCards,
  companyRole: state => state.teamMemberInfo?.companyRole,
  canExportAssessmentData: state => state.featureFlags.canExportAssessmentData,
  enableMskPassport: state => state.featureFlags.enableMskPassport,
  showAiSearchSettings: state => state.featureFlags.showAiSearchSettings,
  thumiv: state => state.featureFlags.thumiv,
  hideWhoNeedsAttentionTable: state =>
    state.featureFlags.hideWhoNeedsAttentionTable ||
    state.role === VIDARoles.RestrictedAdmin,
  provisioningAutomaticallyUpgradeManager: state =>
    state.featureFlags.provisioningAutomaticallyUpgradeManager,
  skipWebcam: state => state.featureFlags.skipWebcam,
  showAdditionalVehiclesInDriverAssessment: state =>
    state.featureFlags.showAdditionalVehiclesInDriverAssessment,
  enablePainImpact: state => state.featureFlags.enablePainImpact,
  enableMessagingForDemo: state => state.featureFlags.enableMessagingForDemo,
  enableDemoFeaturesInMskPassport: state =>
    state.featureFlags.enableDemoFeaturesInMskPassport,
  prioritiseHabitRecommendations: state =>
    state.featureFlags.prioritiseHabitRecommendations,
  enableRebaWithWebcam: state => state.featureFlags.enableRebaWithWebcam,
  showLinkToGameOnDashboard: state =>
    state.featureFlags.showLinkToGameOnDashboard,
  originSource: state => state.originSource,
  showAlternateDashboardCards: state =>
    state.featureFlags.showAlternateDashboardCards,
  jumpIntoPainAssessment: state => state.jumpIntoPainAssessment,
  enableMenopauseAssessment: state =>
    state.featureFlags.enableMenopauseAssessment,
  ssoUrl: state => state.ssoUrl,
  painCoachAutoPlay: state => state.painCoachAutoPlay
};
export default new Vuex.Store({
  modules: {
    ai: aiAssistantStore,
    layout: layoutStore,
    userProfile: userProfileStore,
    adminDashboard: adminDashboardStore,
    routerStore: routerStore,
    sidePanelStore: sidePanelStore,
    messagingStore: messagingStore,
    peopleTableNotification: peopleTableNotificationStore,
    teamStore
  },
  state,
  mutations,
  getters,
  actions
});
