<template>
  <onboarding-tour
    :pages="pages"
    @closed="$emit('input', false)"
    @tourComplete="$emit('input', false)"
  />
</template>

<script>
import OnboardingTour from "@/components/account/onboarding/OnboardingTour.vue";
import { markTeamMemberAsNotNewAsync } from "@/customApi";
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "AdminTour",
  components: {
    OnboardingTour
  },
  data() {
    return {
      pages: [
        {
          title: this.$t("onboarding.adminTour.1.title"),
          subText: this.$t("onboarding.adminTour.1.subtext"),
          imageUrl: "analytics.jpg"
        },
        {
          title: this.$t("onboarding.adminTour.2.title"),
          subText: this.$t("onboarding.adminTour.2.subtext"),
          imageUrl: "invites.gif"
        },
        {
          title: this.$t("onboarding.adminTour.3.title"),
          subText: this.$t("onboarding.adminTour.3.subtext"),
          imageUrl: "webcam.gif"
        },
        {
          title: this.$t("onboarding.adminTour.4.title"),
          subText: this.$t("onboarding.adminTour.4.subtext"),
          imageUrl: "painCoach.gif"
        }
      ]
    };
  },
  mounted() {
    this.openModal();
  },
  async beforeDestroy() {
    await this.closeDialog();
  },
  methods: {
    ...mapMutations(["openModal", "closeModal", "markUserAsNotNew"]),
    async closeDialog() {
      try {
        markTeamMemberAsNotNewAsync(this._i18n.locale, this.teamMemberId);
      } catch (err) {
      } finally {
        this.closeModal();
        setTimeout(() => {
          this.markUserAsNotNew();
        }, 500);
        this.$emit("input", false);
      }
    }
  },
  computed: {
    ...mapGetters(["teamMemberId"])
  }
};
</script>

<style scoped>
.small-space {
  letter-spacing: 0.001em !important;
}
</style>
