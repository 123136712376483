<template>
  <div class="mb-6">
    <v-text-field
      :name="name"
      v-model="text"
      @input="$emit('input', $event)"
      :placeholder="$t(fullQuestion.placeholder)"
      :rules="rules"
      outlined
    />
  </div>
</template>

<script>
export default {
  name: "TextQuestion",
  props: {
    name: String,
    value: String,
    index: Number,
    fullQuestion: Object,
    totalQuestionsOnPage: Number,
    optional: Boolean
  },
  data() {
    return {
      text: this.value ?? ""
    };
  },
  computed: {
    rules() {
      return this.optional
        ? []
        : [v => v.length > 0 || this.$t(this.fullQuestion.errorMessage)];
    }
  }
};
</script>
