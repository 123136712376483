<template>
  <vfl-callout backgroundColor="vflBeigeLight2"
    ><div class="d-flex align-center">
      <img
        src="/images/brand/vida-logo-dark-transparent.png"
        class="mr-4"
        height="32"
      />
      <i18n
        class="text-body-2 vflNeutralDark1--text my-0"
        path="mskOverview.callout.text"
        style="line-height: 18px"
      >
        <template v-slot:painCoach>
          <span class="font-weight-bold">{{
            $t("mskOverview.callout.vidaPainCoach")
          }}</span>
        </template>
      </i18n>
    </div></vfl-callout
  >
</template>

<script>
import VflCallout from "@/components/vitrue-foundation-library/VflCallout.vue";
export default {
  name: "PainOverviewCallout",
  components: {
    VflCallout
  }
};
</script>
