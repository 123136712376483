<template>
  <div class="step-introduction-container d-flex flex-column mb-8">
    <p class="text-overline pt-8 mb-6">
      {{ $t("assessment.stepIntroduction.didYouKnow") }}
    </p>
    <h1 class="text-h4 mb-10">{{ title }}</h1>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "StepIntroduction",
  props: {
    localeRoot: String,
    title: String
  }
};
</script>

<style scoped lang="scss">
.step-introduction-container {
  // container padding = 2 * 16px = 32px
  //min-height: calc(var(--app-content-height) - 32px);
}
</style>
