<template>
  <v-card color="secondary" class="speech-bubble ml-0" max-width="125px">
    <v-card-text class="pa-1">
      <div class="text-caption white--text text-wrap text-center">
        {{ message }}
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "feature-flag-note",
  props: {
    message: String
  }
};
</script>

<style scoped>
.speech-bubble {
  position: relative;
  background: #3fb288;
}

.speech-bubble:after {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  width: 0;
  height: 0;

  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 18px solid #3fb288;
  margin-top: -10px;
  margin-left: -17px;
}
</style>
