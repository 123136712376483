import { calculateRebaScore } from "@/services/assessment/physical-labour/reba-scoring-service.js";
import expectedResultsStructure from "@/services/assessment/physical-labour/answers.json";
import sentry from "@/sentry";

const MAX_REBA_SCORE = 15;

const isValidResultsStructure = results => {
  try {
    if (typeof results !== "object" || results === null) {
      throw new Error("Results must be a non-null object");
    }

    // TODO - these can be removed once https://bitbucket.org/vitrueremote/vitrueremote/pull-requests/3253 is merged
    const optionalKeys = ["pain", "health"];

    const expectedKeys = Object.keys(expectedResultsStructure).filter(
      key => !optionalKeys.includes(key)
    );
    const resultKeys = Object.keys(results);
    const isValid = expectedKeys.every(key => resultKeys.includes(key));

    if (!isValid) {
      throw new Error("Invalid results structure");
    }

    return true;
  } catch (error) {
    sentry.captureException(error);
    return false;
  }
};

const convertRebaScoreToPercentage = results => {
  const rebaScore = calculateRebaScore(results);

  const normalizedPercentage = 100 - (rebaScore / MAX_REBA_SCORE) * 100;
  const roundedPercentage = Math.round(normalizedPercentage);

  return roundedPercentage;
};

export const getOverallScore = results => {
  if (isValidResultsStructure(results)) {
    return convertRebaScoreToPercentage(results);
  }
};
