<template>
  <div class="d-flex flex-column mb-8">
    <h1 class="text-h4 mb-10 mt-12">
      {{ $t(content.title, content.titleParams) }}
    </h1>
    <div class="d-flex flex-wrap" style="gap: 1.5rem">
      <div class="image">
        <img :src="imagePath" />
      </div>
      <div style="flex: 1 1 300px">
        <p
          v-for="(paragraph, index) in content.paragraphs"
          :key="index"
          v-html="$t(paragraph)"
        ></p>
        <vfl-alert v-if="showMenopauseAssessmentPromotion" :color="'vflBeige'">
          <span class="d-flex align-items-center" style="grid-gap: 0.75rem">
            <v-icon color="black">mdi-calendar-blank-outline</v-icon>
            <span
              style="line-height: 1.25"
              class="vflNeutralDark1--text text-body-2"
              >{{
                $t(
                  "menopauseAssessment.outroPage.stage.menstruationAssessmentPlug"
                )
              }}</span
            ></span
          >
        </vfl-alert>
      </div>
    </div>
  </div>
</template>

<script>
import { OutroService } from "./outro-service";
import { Theme } from "./../steps.js";
import VflAlert from "@/components/vitrue-foundation-library/VflAlert.vue";

export default {
  name: "OutroStep",
  components: { VflAlert },
  props: {
    step: {
      type: String,
      required: true,
      validator: value =>
        [Theme.STAGE_OUTRO, Theme.PAIN_OUTRO, Theme.SYMPTOMS_OUTRO].includes(
          value
        )
    },
    results: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      outroService: new OutroService()
    };
  },
  computed: {
    content() {
      switch (this.step) {
        case Theme.STAGE_OUTRO:
          return this.outroService.getStageContent(this.results.stage);
        case Theme.PAIN_OUTRO:
          return this.outroService.getPainContent(this.results);
        case Theme.SYMPTOMS_OUTRO:
          return this.outroService.getSymptomsContent(
            this.results.symptoms.symptoms
          );
        default:
          return { title: "", titleParams: {}, paragraphs: [] };
      }
    },
    imagePath() {
      const imageMap = {
        [Theme.STAGE_OUTRO]:
          "/images/menopause-assessment/outro-images/stage-outro.png",
        [Theme.PAIN_OUTRO]:
          "/images/menopause-assessment/outro-images/pain-outro.png",
        [Theme.SYMPTOMS_OUTRO]:
          "/images/menopause-assessment/outro-images/symptoms-outro.png"
      };
      return imageMap[this.step];
    },
    showMenopauseAssessmentPromotion() {
      return this.step === Theme.STAGE_OUTRO && this.results.stage.stage === 1;
    }
  }
};
</script>

<style lang="scss" scoped>
.image {
  border-radius: 8px;
  position: relative;
}
</style>
