import i18n from "@/plugins/i18n.js";

function getPainChangeText(previous, current) {
  if (previous === null) {
    return { arrow: "", changeDescription: "" };
  }

  const difference = Math.abs(current - previous);
  const changeText = i18n.tc("userProfile.history.pain.points", difference);

  if (current === previous) {
    return {
      arrow: "→",
      changeDescription: i18n.t("userProfile.history.pain.description.same")
    };
  } else if (current < previous) {
    return {
      arrow: "↓",
      changeDescription: i18n.t(
        "userProfile.history.pain.description.improved",
        {
          difference: difference,
          points: changeText
        }
      )
    };
  } else {
    return {
      arrow: "↑",
      changeDescription: i18n.t(
        "userProfile.history.pain.description.worsened",
        {
          difference: difference,
          points: changeText
        }
      )
    };
  }
}

export const getPainEventText = item => {
  const { arrow, changeDescription } = getPainChangeText(
    item.previousLevel,
    item.newLevel
  );
  const areaText = i18n.t(`reportText.pain.painAreaNames.${item.area}.title`);
  return i18n.t("userProfile.history.pain.text", {
    arrow: arrow,
    areaText: areaText,
    level: item.newLevel,
    description: changeDescription
  });
};
