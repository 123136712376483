<template>
  <div>
    <v-row justify="center" align="center">
      <v-img src="/images/mailbox.svg" max-height="25%" max-width="25%"></v-img>
    </v-row>
    <div class="d-flex flex-column align-center">
      <p class="pt-6 text-h4 primary--text" data-cy="title">
        {{ text.title }}
      </p>
      <p class="pt-2 text-body1">
        {{ text.message }}
      </p>
    </div>
    <v-row class="d-flex" justify="center">
      <v-list>
        <v-list-item v-for="email in sent" :key="email">
          <v-list-item-icon>
            <v-icon color="success">check_circle</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ email }}</v-list-item-title>
        </v-list-item>
        <v-list-item v-for="email in failed" :key="email">
          <v-list-item-icon>
            <v-icon color="error">close</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ email }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "InviteSentDesign",
  props: {
    sent: Array,
    failed: Array,
    assessmentType: String
  },
  computed: {
    messageType() {
      if (this.sent.length > 0 && this.failed.length === 0) {
        return "success";
      }

      if (this.sent.length === 0 && this.failed.length > 0) {
        return "error";
      }

      return "unsent";
    },
    text() {
      const assessmentType = this.$t(
        `assessmentTypes.${this.assessmentType ?? "assessment"}`
      );
      return {
        title: this.$t(`invite.sent.${this.messageType}Title`, {
          assessmentType
        }),
        message: this.$t(`invite.sent.${this.messageType}Message`, {
          assessmentType
        })
      };
    }
  }
};
</script>
