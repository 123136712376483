<template>
  <div class="notification-introduction-card d-flex flex-column" v-if="show">
    <v-dialog
      v-if="showDialog"
      v-model="showDialog"
      :max-width="$vuetify.breakpoint.smAndDown ? '100%' : '720px'"
    >
      <external-notification-introduction-dialog
        @closeDialog="showDialog = false"
      />
    </v-dialog>

    <button class="close-button" @click="handleDismiss">
      <span class="d-sr-only">{{
        $t("dashboardAssessmentCards.assessmentNotificationSetting.dismiss")
      }}</span>
      <v-icon color="vflNeutralDark2" size="28">mdi-close</v-icon>
    </button>
    <h5 class="text-subtitle-1 white--text mt-2" style="max-width: 175px">
      {{
        $t(
          "dashboardAssessmentCards.externalNotificationIntroduction.card.title"
        )
      }}
    </h5>

    <div class="mt-auto">
      <button
        class="vfl-link mb-1"
        style="color: white !important; cursor: pointer"
        @click="handleButtonClick"
      >
        {{
          $t(
            "dashboardAssessmentCards.externalNotificationIntroduction.card.button"
          )
        }}
      </button>
    </div>
  </div>
</template>

<script>
import NewFeatureService from "@/services/new-feature-service.js";
import { mapGetters } from "vuex";
import { sendEvent } from "@/services/analytics/analytics.js";
import { getExternalNotificationProvider } from "@/customApi";
import { ExternalNotificationProviders } from "@/constants/constants";
import ExternalNotificationIntroductionDialog from "./ExternalNotificationIntroductionDialog.vue";

export default {
  name: "ExternalNotificationIntroductionCard",
  components: {
    ExternalNotificationIntroductionDialog
  },
  data() {
    return {
      isLoading: false,
      externalNotificationProviderEnabled: false,
      showCard: true,
      showDialog: false
    };
  },
  computed: {
    ...mapGetters(["userId", "demoFeatures"]),
    show() {
      if (this.demoFeatures) return false;

      return this.shouldShowFeature && !this.isLoading && this.showCard;
    },
    shouldShowFeature() {
      return NewFeatureService.showFeature(
        "ExternalNotificationIntroductionCard"
      );
    }
  },
  async created() {
    if (this.demoFeatures) return;

    await this.fetchNotificationProviderSettings();
  },
  methods: {
    async fetchNotificationProviderSettings() {
      try {
        this.isLoading = true;

        const providers = await getExternalNotificationProvider();

        this.showCard = !providers.some(
          x =>
            x.provider === ExternalNotificationProviders.SLACK ||
            x.provider === ExternalNotificationProviders.MSTEAMS
        );
      } catch (error) {
        this.$logger.captureException(error);
      } finally {
        this.isLoading = false;
      }
    },
    handleDismiss() {
      this.showCard = false;
      this.markFeatureAsSeen();

      sendEvent("External notification introduction card", {
        action: "Dismissed"
      });
    },
    handleButtonClick() {
      this.showDialog = true;
      sendEvent("External notification introduction card", {
        action: "Open dialog"
      });
    },
    markFeatureAsSeen() {
      NewFeatureService.markFeatureSeen(
        "ExternalNotificationIntroductionCard",
        true
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.notification-introduction-card {
  background-image: url("/images/notification-introduction-card-background.png");
  background-size: cover;
  border-radius: 1rem;
  height: 100%;
  min-height: 170px;
  min-width: 350px;
  padding: 1rem;
  position: relative;
  overflow: hidden;
}

.close-button {
  position: absolute;
  right: 4px;
  top: 4px;
}
</style>
