import { getPainConditions } from "../get-conditions-service.js";
import { PAIN_REPORT_CARD_MAPPING } from "./report-card-map.js";

export function getReportCardConditions(results, cardId) {
  const reportCardConditions = PAIN_REPORT_CARD_MAPPING[cardId];
  if (!reportCardConditions) return [];

  const conditions = getPainConditions(results);
  return conditions.filter(c =>
    reportCardConditions.some(
      rc => rc.painGroup == c.painGroup && rc.value == c.value
    )
  );
}
