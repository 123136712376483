<template>
  <p
    class="ai-assistant-user-message-container text-body-2 mb-6 py-2 px-4 ml-auto"
  >
    {{ content }}
  </p>
</template>
<script>
export default {
  name: "AiAssistantUserMessage",
  props: {
    content: String,
    required: true
  }
};
</script>

<style lang="scss" scoped>
.ai-assistant-user-message-container {
  background-color: var(--v-vflBlueVeryLight-base);
  border-radius: 16px;
  max-width: 290px;
  scroll-margin-top: 1rem;
  width: fit-content;
}
</style>
