import { HeaderModule } from "@/components/assessment/menopause/report/text-modules/header-module.js";
import { MskHealthModule } from "@/components/assessment/menopause/report/text-modules/msk-health-module.js";
import { PainModule } from "@/components/assessment/menopause/report/text-modules/pain-module.js";
import { MuscleLossModule } from "@/components/assessment/menopause/report/text-modules/muscle-loss-module.js";
import { SymptomsModule } from "@/components/assessment/menopause/report/text-modules/symptoms-module.js";

class MenopauseReportTextService {
  constructor() {
    this.header = new HeaderModule();
    this.mskHealth = new MskHealthModule();
    this.pain = new PainModule();
    this.muscleLoss = new MuscleLossModule();
    this.symptoms = new SymptomsModule();
  }

  getHeaderTitle(results) {
    return this.header.getHeaderTitle(results);
  }

  getHeaderContent(results) {
    return this.header.getHeaderContent(results);
  }

  getMskHealthTitle() {
    return this.mskHealth.getMskHealthTitle();
  }

  getMskHealthIntroText() {
    return this.mskHealth.getMskHealthIntroText();
  }

  getGraphTitle(type) {
    return this.mskHealth.getGraphTitle(type);
  }

  getAccordionContent(type) {
    return this.mskHealth.getAccordionContent(type);
  }

  getPainSectionTitle(results) {
    return this.pain.getPainSectionTitle(results);
  }

  getPainAreasCardTitle() {
    return this.pain.getPainAreasCardTitle();
  }

  getPainContentCardTitle() {
    return this.pain.getPainContentCardTitle();
  }

  getPainContentCardBody(results) {
    return this.pain.getPainContentCardBody(results);
  }

  getMuscleLossSectionTitle() {
    return this.muscleLoss.getSectionTitle();
  }

  getMuscleLossCardContent() {
    return this.muscleLoss.getCardContent();
  }

  getSymptomsSectionTitle() {
    return this.symptoms.getSectionTitle();
  }

  getSymptomsCardContent(results) {
    return this.symptoms.getCardContent(results);
  }
}

export const menopauseReportService = new MenopauseReportTextService();
