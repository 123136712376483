<template>
  <user-profile-block>
    <template #title>History</template>
    <template #content>
      <div v-if="processedHistory?.length">
        <user-profile-history-event
          v-for="(event, i) in processedHistory"
          :event="event"
          :key="event.timestamp + event.displayText + i"
        />
      </div>
      <div v-else-if="isLoading">
        <div class="loader">
          <v-skeleton-loader type="avatar" />
          <v-skeleton-loader type="list-item" class="flex-grow-1" />
        </div>
        <div class="loader mt-6">
          <v-skeleton-loader type="avatar" />
          <v-skeleton-loader type="list-item" class="flex-grow-1" />
        </div>
      </div>
    </template>
  </user-profile-block>
</template>

<script>
import { mapGetters } from "vuex";
import { processUserHistory } from "@/components/user-profile/history/events/event-service.js";
import UserProfileBlock from "@/components/user-profile/UserProfileBlock.vue";
import UserProfileHistoryEvent from "@/components/user-profile/UserProfileHistoryEvent.vue";

export default {
  name: "UserProfileHistory",
  components: { UserProfileHistoryEvent, UserProfileBlock },
  computed: {
    ...mapGetters("userProfile", ["loadingStates", "history"]),
    processedHistory() {
      return processUserHistory(this.history || []);
    },
    isLoading() {
      return this.loadingStates.history;
    }
  }
};
</script>

<style scoped lang="scss">
.loader {
  display: flex;
  align-items: center;
}
</style>
