import criteriaChecker from "@/components/common/criteria-checker.js";

export function scrollToInvalidQuestion(vue, container) {
  vue.$nextTick(() => {
    const invalidElement = container.$el.querySelector(".error--text");
    if (invalidElement) {
      invalidElement.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center"
      });
    }
  });
}

export function getNextSchemaIndex(schema, results, currentIndex) {
  for (let i = currentIndex + 1; i < schema.length; i++) {
    const show = showSection(schema[i], results);
    if (show) return i;
  }

  return currentIndex + 1 > schema.length - 1
    ? schema.length - 1
    : currentIndex + 1;
}

export function getPreviousSchemaIndex(schema, results, currentIndex) {
  if (currentIndex === 0) return -1;
  for (let i = currentIndex - 1; i >= 0; i--) {
    const show = showSection(schema[i], results);
    if (show) return i;
  }

  return currentIndex - 1 < 0 ? 0 : currentIndex - 1;
}

function showSection(section, results) {
  return (
    section.alwaysShowSection || sectionHasVisibleQuestions(section, results)
  );
}

function sectionHasVisibleQuestions(section, results) {
  return section.questions.some(
    q =>
      !q.showOn ||
      criteriaChecker.areCriteriaMet(
        getResultsForCondition(q.showOn, results, section),
        q.showOn
      )
  );
}

function getResultsForCondition(condition, results, section) {
  const theme = Object.values(condition).find(v => v.theme)?.theme;
  return theme ? results[theme] : results[section.theme];
}
