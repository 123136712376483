import Vue from "vue";
import Router from "vue-router";
import Login from "@/components/authentication/Login.vue";
import Dashboard from "@/components/dashboard/Dashboard.vue";
import Store from "../store";
import sentry from "@/sentry";
import TeamService from "@/services/teamService.js";
import { VIDARoles } from "../services/authorization/user-types";
import { routes } from "@/router/route-constants.js";
import DriverAssessment from "@/components/driver-assessment/DriverAssessment.vue";
import PhysicalLabourAssessment from "@/components/assessment/physical-labour/PhysicalLabourAssessment.vue";
import MenopauseAssessment from "@/components/assessment/menopause/MenopauseAssessment.vue";
import UserProfile from "@/components/user-profile/UserProfile.vue";
import AssessmentSimsUpload from "@/components/deskassessment/AssessmentSimsUpload.vue";
const MsTeamsDashboardPage = () =>
  import("@/components/ms-teams/dashboard/MsTeamsDashboardPage.vue");
const MsTeamsLandingPage = () => import("@/components/ms-teams/MsTeams.vue");
const MsTeamsSendAssessmentsDialog = () =>
  import("@/components/ms-teams/dashboard/MsTeamsSendAssessmentsDialog.vue");

const DevPage = () => import("@/components/internal/DevPage.vue");
const SlackDevPage = () => import("@/components/internal/SlackDevPage.vue");
const ClinicalPage = () => import("@/components/internal/ClinicalPage.vue");
const SignUp = () => import("@/components/authentication/SignUp.vue");
  const ResetPassword = () =>
    import("@/components/authentication/ResetPassword.vue");
const MagicLinkLogin = () => import("@/components/authentication/MagicLinkLogin.vue");
const InvitationSignUp = () =>
  import("@/components/authentication/InvitationSignUp.vue");
const Certification = () =>
  import("@/components/deskassessment/Certification.vue");
const DeskAssessment = () =>
  import("@/components/deskassessment/DeskAssessment.vue");
const BurnoutAssessment = () =>
  import("@/components/burnout-assessment/BurnoutAssessment.vue");
const Account = () => import("@/components/account/Account.vue");
const Subscription = () => import("@/components/account/Subscription.vue");
const SettingsPage = () => import("@/components/account/SettingsPage.vue");
const PersonalSettings = () =>
  import("@/components/account/PersonalSettings.vue");
const WorkspaceSettings = () =>
  import("@/components/account/WorkspaceSettings.vue");
const Users = () => import("@/components/account/Users.vue");
const ErrorPage = () => import("@/components/layout/ErrorPage.vue");
const PageNotFound = () => import("@/components/layout/PageNotFound.vue");
const CreateTeam = () => import("@/components/authentication/CreateTeam.vue");
const ChooseTeam = () => import("@/components/authentication/ChooseTeam.vue");
const JoinTeam = () => import("@/components/authentication/JoinTeam.vue");
const ShareableLink = () =>
  import("@/components/deskassessment/ShareableLink.vue");
const VisualDeskPlanner = () =>
  import("@/components/deskassessment/preview/VisualDeskPlannerPreview.vue");
const Analytics = () =>
  import("@/components/dashboard/analytics/Analytics.vue");
const Wellness = () =>
  import("@/components/dashboard/wellness/WellnessDashboard.vue");
const FeedbackPage = () => import("@/components/layout/FeedbackPage.vue");
const CognitiveAssessment = () =>
  import("@/components/cognitive-assessment/CognitiveAssessment.vue");
const DemoSetupPage = () => import("@/components/internal/DemoSetupPage.vue");
const Suggestions = () =>
  import("@/components/dashboard/analytics/Suggestions.vue");
const ReportsArchive = () =>
  import("@/components/dashboard/reportsArchive/ReportsArchive.vue");
const EmployeesDemo = () =>
  import("@/components/employees-demo/EmployeesDemo.vue");
const Scorm = () => import("@/components/lms/Scorm.vue");
const People = () => import("@/components/people/People.vue");
const EmailPainCheckIn = () =>
  import("@/components/assessment/email-pain-check-in/EmailPainCheckIn.vue");
const MotionHero = () => import("@/components/game/MotionHero.vue");
const SquatTracking = () => import("@/components/game/SquatTracking.vue");

Vue.use(Router);

let router = new Router({
  base: import.meta.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: routes.LOGIN,
      component: Login,
      meta: {
        noTeamRequiredIfSignedIn: true,
        hideSideBar: true,
        showTopBar: true,
        hideActionMenuInTopBar: true
      },
      props: true
    },
    {
      path: "/VitrueLogin",
      name: "VitrueLogin",
      component: Login,
      meta: {
        noTeamRequiredIfSignedIn: true,
        hideSideBar: true,
        showTopBar: true,
        hideActionMenuInTopBar: true
      }
    },
    {
      path: "/signUp",
      name: routes.SIGN_UP,
      component: SignUp,
      meta: {
        noTeamRequiredIfSignedIn: true,
        hideSideBar: true,
        showTopBar: true,
        hideActionMenuInTopBar: true
      },
      props: true
    },
    {
      path: "/sign-up",
      name: routes.SIGN_UP,
      component: SignUp,
      meta: {
        noTeamRequiredIfSignedIn: true,
        hideSideBar: true,
        showTopBar: true,
        hideActionMenuInTopBar: true
      },
      props: true
    },
    {
      path: "/ResetPassword",
      name: routes.RESET_PASSWORD,
      component: ResetPassword,
      meta: {
        noTeamRequiredIfSignedIn: true,
        hideSideBar: true,
        showTopBar: true,
        hideActionMenuInTopBar: true
      }
    },
        {
      path: "/magic-link-login",
      name: routes.MAGIC_LINK_LOGIN,
      component: MagicLinkLogin,
      meta: {
        noTeamRequiredIfSignedIn: true,
        hideSideBar: true,
        showTopBar: true,
        hideActionMenuInTopBar: true
      }
    },
    {
      path: "/invitation-signup",
      name: routes.INVITATION_SIGN_UP,
      component: InvitationSignUp,
      meta: {
        noTeamRequiredIfSignedIn: true,
        hideSideBar: true,
        showTopBar: true,
        hideActionMenuInTopBar: true
      },
      props: true
    },
    {
      path: "/dashboard",
      name: routes.DASHBOARD,
      component: Dashboard,
      meta: { requiresAuth: true, adminPage: true, showDemoLanguages: true }
    },
    {
      path: "/deskassessment/:assessmentId",
      name: routes.DESK_ASSESSMENT,
      component: DeskAssessment,
      meta: {
        assessmentRoute: true
      },
      props: true
    },
    {
      path: "/deskassessment/:origin/:assessmentId",
      name: routes.DESK_ASSESSMENT,
      component: DeskAssessment,
      meta: {
        assessmentRoute: true
      },
      props: true
    },
    {
      path: "/burnoutassessment/:assessmentId",
      redirect: "/work-resilience-assessment/:assessmentId",
      name: routes.BURNOUT_ASSESSMENT,
      component: BurnoutAssessment,
      meta: {
        assessmentRoute: true
      },
      props: true
    },
    {
      path: "/work-resilience-assessment/:assessmentId",
      name: routes.BURNOUT_ASSESSMENT,
      component: BurnoutAssessment,
      meta: {
        assessmentRoute: true
      },
      props: true
    },
    {
      // shareable link
      path: "/share/:parentCompanyId",
      name: routes.SHAREABLE_LINK,
      component: ShareableLink,
      meta: { requiresAuth: false, noTeamRequiredIfSignedIn: true },
      props: { default: true, companyName: "", name: "", emailAddress: "" }
    },
    {
      // embedded shareable link (name optional)
      path: "/share/:parentCompanyId/:companyName/:emailAddress",
      name: routes.SHAREABLE_LINK_SOME_DETAILS,
      component: ShareableLink,
      meta: { requiresAuth: false, noTeamRequiredIfSignedIn: true }
    },
    {
      // embedded shareable link full details
      path: "/share/:parentCompanyId/:companyName/:emailAddress/:name",
      name: routes.SHAREABLE_LINK_ALL_DETAILS,
      component: ShareableLink,
      meta: { requiresAuth: false, noTeamRequiredIfSignedIn: true }
    },
    {
      path: "/visualdeskplanner-preview",
      name: routes.VISUAL_DESK_PLANNER,
      component: VisualDeskPlanner,
      meta: { requiresAuth: false, noTeamRequiredIfSignedIn: true }
    },
    {
      path: "/error",
      name: routes.ERROR_PAGE,
      component: ErrorPage,
      meta: { noTeamRequiredIfSignedIn: true },
      props: true
    },
    {
      path: "/certification",
      name: routes.CERTIFICATION,
      meta: { requiresAuth: true, adminPage: true },
      component: Certification,
      props: true
    },
    {
      path: "/vitruedev",
      name: routes.DEV_PAGE,
      meta: { requiresAuth: true },
      component: DevPage,
      props: true
    },
    {
      path: "/dev-slack",
      name: routes.SLACK_DEV_PAGE,
      meta: { requiresAuth: true },
      component: SlackDevPage,
      props: true
    },
    {
      path: "/vitrueclinical",
      name: routes.CLINICAL_PAGE,
      meta: { requiresAuth: true },
      component: ClinicalPage,
      props: true
    },
    {
      path: "/analytics",
      name: routes.ANALYTICS,
      component: Analytics,
      meta: { requiresAuth: true, adminPage: true }
    },
    {
      path: "/account",
      name: routes.ACCOUNT,
      component: Account,
      props: true,
      meta: { requiresAuth: true },
      children: [
        {
          path: "/account/subscription",
          name: routes.SUBSCRIPTION,
          component: Subscription,
          meta: { requiresAuth: true, adminPage: true },
          props: true
        },
        {
          path: "/account/team",
          name: routes.USERS,
          component: Users,
          meta: { requiresAuth: true, adminPage: true }
        },
        {
          path: "/account/personal-settings",
          name: routes.PERSONAL_SETTINGS,
          component: PersonalSettings,
          meta: { requiresAuth: true }
        }
      ]
    },
    {
      path: "/suggestions",
      name: routes.SUGGESTIONS,
      component: Suggestions,
      meta: { requiresAuth: true, adminPage: true },
      props: true
    },
    {
      path: "/users",
      name: routes.USERS,
      component: Users,
      meta: { requiresAuth: true, adminPage: true }
    },
    {
      path: "/subscription",
      name: routes.SUBSCRIPTION,
      component: Subscription,
      meta: { requiresAuth: true, adminPage: true },
      props: true
    },
    {
      path: "/settings",
      name: routes.SETTINGS,
      component: SettingsPage,
      props: true,
      meta: { requiresAuth: true }
    },
    {
      path: "/create-team",
      name: routes.CREATE_TEAM,
      component: CreateTeam,
      props: true,
      meta: {
        requiresAuth: true,
        noTeamRequiredIfSignedIn: true,
        hideSideBar: true,
        showTopBar: true,
        hideActionMenuInTopBar: true
      }
    },
    {
      path: "/choose-team",
      name: routes.CHOOSE_TEAM,
      component: ChooseTeam,
      props: true,
      meta: {
        requiresAuth: true,
        noTeamRequiredIfSignedIn: true,
        hideSideBar: true,
        showTopBar: true,
        hideActionMenuInTopBar: true
      }
    },
    {
      path: "/join-team",
      name: routes.JOIN_TEAM,
      component: JoinTeam,
      props: true,
      meta: {
        requiresAuth: true,
        noTeamRequiredIfSignedIn: true,
        hideSideBar: true,
        showTopBar: true,
        hideActionMenuInTopBar: true
      }
    },
    {
      path: "/wellness",
      name: routes.WELLNESS,
      component: Wellness,
      props: true,
      meta: { requiresAuth: true }
    },
    {
      path: "/reports-archive",
      name: routes.REPORTS_ARCHIVE,
      component: ReportsArchive,
      meta: { requiresAuth: true }
    },
    {
      path: "/demosetuppage",
      name: "DemoSetupPage",
      component: DemoSetupPage,
      meta: { requiresAuth: true }
    },
    {
      path: "/feedback",
      name: routes.FEEDBACK,
      component: FeedbackPage,
      props: true,
      meta: {
        hideSideBar: true,
        showTopBar: true,
        hideActionMenuInTopBar: true
      }
    },
    {
      path: "/pain-check-in",
      name: routes.EMAIL_PAIN_CHECK_IN,
      component: EmailPainCheckIn,
      props: true,
      meta: {
        hideSideBar: true,
        showTopBar: true,
        hideActionMenuInTopBar: true
      }
    },
    {
      path: "/cognitiveassessment",
      name: routes.COGNITIVE_ASSESSMENT,
      component: CognitiveAssessment,
      props: true,
      meta: { requiresAuth: true }
    },
    {
      path: "/driver-assessment/:assessmentId",
      name: routes.DRIVER_ASSESSMENT,
      component: DriverAssessment,
      meta: {
        assessmentRoute: true
      },
      props: true
    },
    {
      path: "/physical-labour-assessment/:assessmentId",
      name: routes.PHYSICAL_LABOUR_ASSESSMENT,
      component: PhysicalLabourAssessment,
      meta: {
        assessmentRoute: true
      },
      props: true
    },
    {
      path: "/menopause-assessment/:assessmentId",
      name: routes.MENOPAUSE_ASSESSMENT,
      component: MenopauseAssessment,
      meta: {
        assessmentRoute: true
      },
      props: true
    },
    {
      path: "*",
      name: routes.NOT_FOUND,
      component: PageNotFound,
      meta: {
        noTeamRequiredIfSignedIn: true,
        showTopBar: true,
        hideSideBar: true,
        hideActionMenuInTopBar: true
      },
      props: true
    },
    {
      path: "/scorm", // currently this is just another path to login screen - implemented for SCORM so we can make use of this link down the line to deal with a better flow
      name: routes.SCORM,
      component: Scorm,
      meta: { noTeamRequiredIfSignedIn: true, hideSideBar: true },
      props: true,
      alias: "/new-assessment" // Requires alias and can't be removed as old scorm files use this
    },
    {
      path: "/employees-d", // Vitrue sales team demo page for DSE passport
      name: "EmployeesDemo",
      component: EmployeesDemo,
      meta: { requiresAuth: true }
    },
    {
      path: "/people",
      name: routes.PEOPLE,
      component: People,
      props: true,
      meta: { requiresAuth: true, adminPage: true }
    },
    {
      path: "/people/:userId",
      name: routes.PEOPLE,
      component: UserProfile,
      props: true,
      meta: { requiresAuth: true, adminPage: true }
    },
    {
      path: "/assessment-sims-upload",
      name: "AssessmentSimsUpload",
      component: AssessmentSimsUpload,
      meta: { requiresAuth: false }
    },
    {
      path: "/ms-teams-dashboard",
      name: "MsTeamsDashboardPage",
      component: MsTeamsDashboardPage,
      meta: { requiresAuth: false, hideSideBar: true }
    },
    {
      path: "/ms-teams",
      name: "MsTeams",
      component: MsTeamsLandingPage,
      meta: { requiresAuth: false, hideSideBar: true }
    },
    {
      path: "/ms-teams-assessments-send",
      name: "MsTeamsSendAssessmentsDialog",
      component: MsTeamsSendAssessmentsDialog,
      meta: { requiresAuth: false, hideSideBar: true }
    },
    {
      path: "/motion-hero",
      name: routes.MOTION_HERO,
      component: MotionHero,
      meta: {
        gameRoute: true,
        requiresAuth: true,
        hideSideBar: true,
        showTopBar: true
      }
    },
    {
      path: "/squat-tracking",
      name: routes.SQUAT_TRACKING,
      component: SquatTracking,
      meta: {
        gameRoute: true,
        requiresAuth: true,
        hideSideBar: true,
        showTopBar: true
      }
    }
  ]
});

router.beforeEach((to, from, next) => {
  if (from.name !== null) {
    Store.dispatch("routerStore/updatePreviousRoute", from);
  }
  next();
});

router.beforeResolve(async (to, from, next) => {
  hj("stateChange", to.path);

  if (to.name === "Login" || to.name === "AssessmentSimsUpload") {
    next();
    return;
  }
  Vue.prototype.$Amplify.Auth.currentAuthenticatedUser()
    .then(async user => {
      let teamMberInfo = await TeamService.getTeamMemberInfo();
      if (!Store.getters.userId) {
        if (teamMberInfo.role !== VIDARoles.EndUser) {
          await Store.dispatch("getUserTeamAndSubscriptionBaseInformation", {
            memberId: teamMberInfo.teamMemberId,
            forceToUpdateExistedInformation: true
          });
        }
        if (!to.matched.some(record => record.meta.noTeamRequiredIfSignedIn)) {
          await TeamService.updateEndUserInfo();
        }

        // For HotJar / Sentry
        // if user returned and we are signing in - set user data
        if (user && user.signInUserSession) {
          await Store.dispatch("setCurrentUserData", teamMberInfo, user);
          let role = "Unknown";
          switch (teamMberInfo.role) {
            case 0:
              role = "Admin";
              break;
            case 10:
              role = "SuperAdmin";
              break;
            case 20:
              role = "RestrictedAdmin";
              break;
            case 40:
              role = "EndUser";
              break;
          }
          window.hj("identify", teamMberInfo.endUserId, {
            teamId: teamMberInfo.teamId,
            role: teamMberInfo.role
          });
          sentry.setUser({ id: teamMberInfo.endUserId });
        }
      }

      // if user has a team to join, then send them to choose team page.
      // can end up in endless loop if not checking for != chooseteam
      if (
        teamMberInfo &&
        teamMberInfo.teamsToJoinRequested.length > 0 &&
        to.name != routes.CHOOSE_TEAM
      ) {
        next({
          name: routes.CHOOSE_TEAM
        });
        return;
      }
      // if user signed in, but not part of team - redirect to login if page requires team when signed in
      else if (user && Store.getters.signedIn && !Store.getters.userIsInTeam) {
        if (!to.matched.some(record => record.meta.noTeamRequiredIfSignedIn)) {
          next({ path: "/" });
          return;
        }
      }

      // Handle end user trying to navigate to an admin only page
      // if first entry -> navigate to wellness otherwise don't re-route
      if (
        Store.getters.signedIn &&
        !Store.getters.isAdminUser &&
        to.matched.some(record => record.meta.adminPage)
      ) {
        if (!from?.name) {
          next({ name: routes.WELLNESS });
        }
        return;
      }

      next();
    })
    .catch(err => {
      if (!to.matched.some(record => record.meta.requiresAuth)) {
        next();
        return;
      } else if (!Store.state.signedIn) {
        next({ path: "/" });
      }
      sentry.captureException(err);
    });
});

router.onError(error => {
  if (
    error.message.includes("Failed to fetch dynamically imported module") ||
    error.message.includes("Importing a module script failed")
  ) {
    if (import.meta.env.DEV) {
      console.error("error: ", error);
    }
    sentry.captureException(error);
    window.location.reload();
  }
});

export default router;
