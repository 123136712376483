<template>
  <div v-show="show" class="select-body-area-question">
    <pain-person
      :class="{ errorBorder: showError }"
      v-model="painPerson"
      hideChips
      :selectedColor="$vuetify.theme.currentTheme.brightError"
      @selectionChanged="emitToParent()"
      allowDeselect
    />
    <v-text-field :rules="rules" :hide-details="!showError" />
  </div>
</template>

<script>
import PainPerson from "@/components/painareas/PainPerson.vue";
import BodyAreas from "@/components/painareas/body-areas.json";

export default {
  name: "SelectBodyAreaQuestion",
  components: {
    PainPerson
  },
  props: {
    name: String,
    question: String,
    optional: Boolean,
    options: Array,
    show: {
      default: true,
      type: Boolean
    },
    value: Array,
    fullQuestion: Object
  },
  data() {
    return {
      painPerson: { areas: this.arrayToBodyAreas() },
      showError: false,
      mounted: false
    };
  },
  mounted() {
    this.mounted = true;
  },
  computed: {
    rules() {
      return this.optional
        ? []
        : [
            () => {
              const valid = !this.mounted || this.bodyAreasArray.length > 0;
              this.showError = !valid;
              return valid || this.$t(this.fullQuestion.errorMessage);
            }
          ];
    },
    showQuestion() {
      return (
        this.fullQuestion.showQuestion === undefined ||
        this.fullQuestion.showQuestion === true
      );
    },
    bodyAreasArray() {
      return Object.keys(this.painPerson.areas).map(a => BodyAreas[a].value);
    }
  },
  methods: {
    emitToParent() {
      this.showError = false;
      this.$emit("input", this.bodyAreasArray);
    },
    arrayToBodyAreas() {
      if (!this.value) return {};
      const areas = this.value.map(v =>
        Object.keys(BodyAreas).find(area => BodyAreas[area].value === v)
      );
      return areas.reduce((acc, area) => {
        acc[area] = {};
        return acc;
      }, {});
    }
  }
};
</script>

<style scoped lang="scss">
.select-body-area-question {
  .v-text-field {
    ::v-deep .v-input__slot {
      display: none;
    }
  }

  .errorBorder {
    border: 1px solid red;
    border-radius: 10px;
  }
}
</style>
