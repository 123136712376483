import { API, Auth } from "aws-amplify";
const websocketName = "ApiGatewayWebSocket";

export default {
  async websocket(route, path, query) {
    let url = await API.endpoint(websocketName);
    const session = await Auth.currentSession();
    const token = session.getIdToken().getJwtToken();
    url += import.meta.env.VITE_NODE_ENV === "development" ? path : "";
    query.webSocketToken = token;
    query.route = route;
    query.path = path;
    return new WebSocket(`${url}?websocket_token=${token}`);
  }
};
