import {
  HealthConditionsCategories,
  HealthConditionsPriorityMap,
  UNKNOWN_MSK_CONDITIONS,
  getConditionNameFromLabel,
  isUnknownCondition
} from "@/components/assessment/pre-existing-conditions/conditions-categories.js";
import { joinItemsWithConjunction } from "@/components/assessment/pre-existing-conditions/conditions-text-service.js";
import i18n from "@/plugins/i18n";
import { uniqBy } from "lodash";
import { assessmentLocalePaths } from "@/constants/constants.js";

const ICON_BASE_PATH = "/images/icons/health-conditions";
const TranslationKeys = {
  COMMON: {
    AND: `assessment.form.healthConditions.common.and`,
    ISSUE: `assessment.form.healthConditions.common.issue`
  },
  REPORT_SUMMARY: {
    TITLE: `assessment.form.healthConditions.banners.report.summary.title`,
    INFO: `assessment.form.healthConditions.banners.report.summary.info`,
    INSTRUCTION: `assessment.form.healthConditions.banners.report.summary.instruction`
  },
  MSK: "msk",
  MULTIPLE: "multiple",
  OTHER: "msk_other",
  UNKNOWN: "msk_unknown"
};

export function getUserConditions(healthConditions, assessmentType) {
  const activeCategories = Object.keys(healthConditions).filter(
    key => key !== "other"
  );
  const infoRoot = TranslationKeys.REPORT_SUMMARY.INFO;
  const list = activeCategories.map(key => {
    const text =
      key === HealthConditionsCategories.MSK
        ? getMskConditionText(infoRoot, healthConditions, assessmentType)
        : i18n.t(getTranslationKey(infoRoot, key));

    return {
      key,
      text,
      priority: HealthConditionsPriorityMap[key],
      iconSrc: `${ICON_BASE_PATH}/${key}.svg`
    };
  });

  return list.sort((a, b) => a.priority - b.priority);
}

export function getSummaryText(healthConditions, assessmentType) {
  const activeCategories = Object.keys(healthConditions);
  if (activeCategories.length > 1) {
    const path = getTranslationKey(
      TranslationKeys.REPORT_SUMMARY.INSTRUCTION,
      TranslationKeys.MULTIPLE
    );

    const categoriesText = formatCategoryText(activeCategories);

    return i18n.t(path, { conditions: categoriesText });
  }

  if (activeCategories.length === 1) {
    return i18n.t(
      getGeneralCopyForSingleCategory(
        activeCategories[0],
        healthConditions,
        assessmentType
      )
    );
  }
}

function formatConditionText(conditions) {
  const uniqueConditions = uniqBy(conditions, "value");
  let text = uniqueConditions.map(condition =>
    i18n.t(condition.label).toLowerCase()
  );
  return joinItemsWithConjunction(text, i18n.t(TranslationKeys.COMMON.AND));
}

function formatCategoryText(categories) {
  // Filter out MSK as it's mentioned already in other parts of the text
  const filteredCategories = categories.filter(
    category => category !== HealthConditionsCategories.MSK
  );
  let text = filteredCategories.map(category =>
    i18n
      .t(`assessment.form.healthConditions.categories.${category}`)
      .toLowerCase()
  );

  const concatenatedText = joinItemsWithConjunction(
    text,
    i18n.t(TranslationKeys.COMMON.AND)
  );

  return i18n.tc(TranslationKeys.COMMON.ISSUE, text.length, {
    conditions: concatenatedText
  });
}

function getTranslationKey(path, key) {
  return `${path}.${key}`;
}

function getGeneralCopyForSingleCategory(
  condition,
  healthConditions,
  assessmentType
) {
  if (condition === HealthConditionsCategories.MSK) {
    return getMskConditionText(
      TranslationKeys.REPORT_SUMMARY.INSTRUCTION,
      healthConditions,
      assessmentType
    );
  }

  return i18n.t(
    getTranslationKey(TranslationKeys.REPORT_SUMMARY.INSTRUCTION, condition)
  );
}

function getMskConditionText(localeRoot, healthConditions, assessmentType) {
  const userDoesNotKnowConditions = getUnknownMSKConditions(
    healthConditions
  ).every(
    c => getConditionNameFromLabel(c.label) === UNKNOWN_MSK_CONDITIONS.DONT_KNOW
  );

  // One or more known MSK conditions
  const knownMSKConditions = getKnownMSKConditions(healthConditions);
  if (knownMSKConditions.length > 0) {
    const conditionText = formatConditionText(knownMSKConditions);
    const capitalisedText = capitalizeFirstLetter(conditionText);

    return i18n.tc(
      getTranslationKey(localeRoot, TranslationKeys.MSK),
      knownMSKConditions.length,
      {
        conditions: conditionText,
        conditionsCapitalised: capitalisedText
      }
    );
  }

  const category = userDoesNotKnowConditions
    ? TranslationKeys.UNKNOWN
    : TranslationKeys.OTHER;

  // Try to get assessment type specific text first
  const assessmentTypePath = assessmentLocalePaths[assessmentType];
  const translationKey = `${localeRoot}.${category}.${assessmentTypePath}`;
  const hasAssessmentSpecificText = i18n.te(translationKey);

  // If assessment specific text exists, use it, otherwise fall back to generic text
  return i18n.t(
    hasAssessmentSpecificText
      ? translationKey
      : getTranslationKey(localeRoot, category)
  );
}

function getKnownMSKConditions(healthConditions) {
  return healthConditions.msk.filter(
    condition => !isUnknownCondition(condition.label)
  );
}

function getUnknownMSKConditions(healthConditions) {
  return healthConditions.msk.filter(condition =>
    isUnknownCondition(condition.label)
  );
}

function capitalizeFirstLetter(str) {
  if (str.length === 0) return str;
  return str.charAt(0).toUpperCase() + str.slice(1);
}
