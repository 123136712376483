<template>
  <div class="accordion-container">
    <template v-for="(section, index) in currentSections">
      <details :key="index" :open="index === 0">
        <summary class="text-h6">
          {{ section.title }}

          <v-icon color="black">mdi-chevron-down</v-icon>
        </summary>
        <div>
          <template v-if="section.paragraphs">
            <p
              v-for="(paragraph, pIndex) in section.paragraphs"
              :key="pIndex"
              class="text-body"
            >
              {{ paragraph }}
            </p>
          </template>

          <template v-if="section.type === 'list'">
            <ul class="content-list">
              <li
                v-for="(item, iIndex) in section.items"
                :key="iIndex"
                v-html="item.text"
                class="list-item"
              ></li>
            </ul>
          </template>

          <template v-if="section.sections">
            <div
              v-for="(subsection, sIndex) in section.sections"
              :key="sIndex"
              class="subsection"
            >
              <h5
                v-if="subsection.subtitle"
                class="subsection-title"
                v-html="subsection.subtitle"
              ></h5>

              <ul v-if="subsection.type === 'list'" class="content-list">
                <li
                  v-for="(item, iIndex) in subsection.items"
                  :key="iIndex"
                  v-html="item.text"
                  class="list-item"
                ></li>
              </ul>
            </div>
          </template>

          <did-not-know-that-fact-button :fact="section.title" class="mt-8" />
        </div>
      </details>
    </template>
  </div>
</template>

<script>
import DidNotKnowThatFactButton from "@/components/assessment/menopause/report/DidNotKnowThatFactButton.vue";
import { GRAPH_TYPES } from "@/components/assessment/menopause/report/constants/graphs.js";
import { menopauseReportService } from "@/components/assessment/menopause/report/text-service.js";

export default {
  name: "MenopauseReportInfoAccordion",
  components: {
    DidNotKnowThatFactButton
  },
  props: {
    activeTab: {
      type: String,
      required: true,
      validator: function (value) {
        return Object.values(GRAPH_TYPES).includes(value);
      }
    }
  },
  computed: {
    currentSections() {
      return menopauseReportService.getAccordionContent(this.activeTab);
    }
  }
};
</script>

<style scoped lang="scss">
details {
  &:not(:last-child) {
    border-bottom: 1px solid var(--v-vflNeutralDark2-base);
  }

  &[open] {
    summary {
      i {
        transform: rotate(180deg);
      }
    }
  }
}

summary {
  align-content: center;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 1rem 0;

  &::marker {
    content: none;
  }

  i {
    transition: transform 0.125s ease-out !important;
  }

  + div {
    padding-bottom: 1rem;
  }
}

ul {
  margin-bottom: 1rem;
}
</style>
