import VueKonva from "vue-konva";
import VueTour from "vue-tour";
import VStripeElements from "v-stripe-elements/lib";
import VueYouTube from "@/components/vue-youtube/vue-youtube.esm";

export function setupPlugins(Vue) {
  // Use Vue plugins
  Vue.use(VueKonva, { prefix: "Konva" });
  Vue.use(VueTour);
  Vue.use(VStripeElements);
  Vue.use(VueYouTube);
}
