import { ReportSeedQuestionId } from "@/services/ai/constants.js";
import seedQuestions from "@/assets/json/ai-seed-questions-report-overview.json";
import { hasPain } from "@/services/deskassessment/results-utils.js";
import { AdminRoles } from "@/constants/constants.js";
import store from "@/store";

class SeedQuestionDisplayHandler {
  static instance = null;

  static getInstance() {
    if (!SeedQuestionDisplayHandler.instance) {
      SeedQuestionDisplayHandler.instance = new SeedQuestionDisplayHandler();
    }
    return SeedQuestionDisplayHandler.instance;
  }

  constructor() {
    this.rules = new Map([
      [
        ReportSeedQuestionId.BE_PROACTIVE_PAIN,
        ({ results }) => hasPain(results)
      ],
      [
        ReportSeedQuestionId.BE_PROACTIVE_NO_PAIN,
        ({ results }) => !hasPain(results)
      ],
      [
        ReportSeedQuestionId.REPORT_SUMMARY_BAD_SCORE,
        ({ score }) => score < 50
      ],
      [
        ReportSeedQuestionId.COMPLICATING_FACTORS,
        ({ existingConditions }) =>
          existingConditions && existingConditions.length > 0
      ],
      [
        ReportSeedQuestionId.HEALTH_CLAIMS,
        () => store.getters.featureFlags?.providesHealthInsurance
      ]
    ]);
  }

  shouldShowCard(cardId, params) {
    const rule = this.rules.get(cardId);
    return rule ? rule(params) : true;
  }

  hasRelevantRole(targetRoles, companyRole) {
    const isKnownRole = Object.values(AdminRoles).includes(companyRole);

    if (companyRole && isKnownRole) {
      return targetRoles.includes(companyRole);
    }

    // If the company role is unknown, return seed questions marked as 'other' only
    return targetRoles.includes(AdminRoles.OTHER);
  }
}

export default function getReportSeedQuestions(
  results,
  score,
  existingConditions,
  role
) {
  const handler = SeedQuestionDisplayHandler.getInstance();
  return seedQuestions.filter(
    question =>
      handler.shouldShowCard(question.id, {
        results,
        score,
        existingConditions
      }) && handler.hasRelevantRole(question.targetRoles, role)
  );
}
