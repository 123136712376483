import { GRAPH_TYPES } from "@/components/assessment/menopause/report/constants/graphs.js";

export const MSK_HEALTH_CONTENT = {
  title: "About menopause and musculoskeletal health",
  accordion: {
    [GRAPH_TYPES.PERIMENOPAUSE]: [
      {
        title: "What is perimenopause?",
        paragraphs: [
          "Perimenopause marks an important natural transition in life, typically beginning in your 40s (though sometimes in your mid-30s). During this time, your body gradually shifts its hormonal patterns, moving away from the reproductive phase into a new stage of life.",
          "This transition is unique to each person - it might last a few months or extend over several years, with menopause officially beginning when you've gone 12 months without a period. Think of it as your body's wisdom in action, orchestrating complex changes that will carry you into your next chapter."
        ]
      },
      {
        title: "What happens during perimenopause?",
        type: "list",
        items: [
          {
            text: "The ovaries gradually produce less oestrogen, the primary female hormone, although hormone levels can fluctuate wildly during this time."
          },
          {
            text: "The menstrual cycle becomes irregular, but periods do not stop completely until menopause."
          }
        ]
      },
      {
        title: "Oestrogen fluctuations",
        paragraphs: [
          "Oestrogen plays a key role in maintaining joint lubrication and muscle strength. When oestrogen drops, the body produces less synovial fluid, which lubricates joints, leading to stiffness and discomfort.",
          "These fluctuations can also affect collagen production, which is vital for the health of connective tissues like tendons and ligaments.",
          "Oestrogen also supports muscle strength and recovery. As levels drop, muscles may take longer to recover from strain, which may lead to fatigue."
        ]
      },
      {
        title: "Impact on musculoskeletal health",
        paragraphs: [
          "Joints can become more prone to wear and tear, leading to aches, especially in weight-bearing areas like the knees, hips, and lower back.",
          "Fatigue increases the risk of muscle overuse and repetitive strain injuries, particularly in areas already under stress (like the neck and shoulders from carrying heavy bags or spending long hours working at the desk)."
        ]
      }
    ],
    [GRAPH_TYPES.MENOPAUSE]: [
      {
        title: "What is menopause?",
        paragraphs: [
          "Menopause is a natural and meaningful transition in a woman's life. It's marked by going 12 months without a period, signaling an important shift in your body's rhythm. This change is a normal part of life, and your experience with it is uniquely yours. Embrace this new chapter with care and patience—it's an important step in your health journey."
        ]
      },
      {
        title: "What happens during menopause?",
        type: "list",
        items: [
          {
            text: "<strong>Hormonal changes</strong>: The main change is the significant drop in oestrogen and progesterone, two key reproductive hormones."
          },
          {
            text: "<strong>End of menstruation</strong>: Periods stop completely."
          }
        ]
      },
      {
        title: "Oestrogen drop",
        paragraphs: [
          "Oestrogen plays a key role in maintaining joint lubrication and muscle strength. When oestrogen drops, the body produces less synovial fluid, which lubricates joints, leading to stiffness and discomfort.",
          "The drop can also affect collagen production, which is vital for the health of connective tissues like tendons and ligaments.",
          "Oestrogen also supports muscle strength and recovery. As levels drop, muscles may take longer to recover from strain, leading to feelings of fatigue."
        ]
      },
      {
        title: "Impact on musculoskeletal health",
        paragraphs: [
          "Joints can become more prone to wear and tear, leading to aches, especially in weight-bearing areas like the knees, hips, and lower back.",
          "The drop in oestrogen leads to a loss of muscle mass and strength, which can increase the risk of falls and make daily activities more difficult."
        ]
      }
    ],
    [GRAPH_TYPES.POSTMENOPAUSE]: [
      {
        title: "What is postmenopause?",
        paragraphs: [
          "Postmenopause marks the phase of life that begins a year after your last period. During this time, the ovaries have naturally stopped releasing eggs, and oestrogen levels remain low.",
          "Although the intense hormonal shifts of menopause have eased, lower oestrogen can still have effects on your health. This stage offers a chance to focus on your wellbeing in ways that support your body's needs in this new chapter."
        ]
      },
      {
        title: "What happens during postmenopause?",
        type: "list",
        items: [
          {
            text: "<strong>Hormone levels stabilise</strong>: Oestrogen and progesterone levels stay consistently low, no longer fluctuating as they did during perimenopause and menopause."
          },
          {
            text: "<strong>Increased risk of certain health conditions</strong>: With the permanent decline in oestrogen, women in postmenopause are at higher risk for conditions like osteoporosis (weaker bones), heart disease, and joint pain."
          }
        ]
      },
      {
        title: "Low oestrogen",
        paragraphs: [
          "While the intense symptoms of menopause (like hot flashes or mood swings) often lessen during postmenopause, some issues can persist or develop due to the lasting effects of low oestrogen, especially osteoporosis."
        ]
      },
      {
        title: "Impact on musculoskeletal health",
        paragraphs: [
          "Bone loss and osteoporosis: Low oestrogen can decrease bone density, increasing the risk of fractures and osteoporosis.",
          "Joint pain and stiffness: Continued reduction in oestrogen affects joint lubrication and cartilage health, often resulting in stiffness or pain, especially in the hips, knees, and hands.",
          "The drop in oestrogen leads to a loss of muscle mass and strength, increasing the risk of falls and fractures."
        ]
      }
    ],
    [GRAPH_TYPES.INJECTABLE_CONTRACEPTIVES]: [
      {
        title: "Hormone profile",
        paragraphs: [
          "The injection contains the hormone progesterone, which is gradually absorbed into the bloodstream from the injection site. This slow release keeps the hormone at effective levels, providing contraception for about 13 weeks."
        ]
      },
      {
        title: "Musculoskeletal insights",
        paragraphs: [
          "Contraceptive injectables have distinct effects on musculoskeletal health compared to other forms of hormonal contraception. Its impact on bone health is well-studied. However, the impact on muscles, tendons, or ligaments requires more research."
        ]
      },
      {
        title: "Impact on musculoskeletal health",
        type: "list",
        items: [
          {
            text: "<strong>Bone Health</strong>: The use of the injectable contraceptive has been linked to a decrease in bone density, especially with long-term use. This effect is more noticeable in adolescents and young women, as their bones are still developing. The good news is that bone density usually recovers after stopping it, but the time it takes to return to normal can differ for each person."
          },
          {
            text: "<strong>Fracture Risk</strong>: Some studies show that using injectables for a long time may increase the risk of bone fractures because of its effect on bone density."
          },
          {
            text: "<strong>Muscle, tendons and performance</strong>: There isn't much evidence directly connecting injectable contraceptives to changes in muscle strength or performance. Most research has focused on bone health rather than muscles and tendons."
          }
        ]
      }
    ],
    [GRAPH_TYPES.HRT]: [
      {
        title: "What is Hormone Replacement Therapy (HRT)?",
        paragraphs: [
          "Hormone Replacement Therapy (HRT) is a treatment designed to restore hormones like oestrogen and progesterone that naturally decline during menopause. It can help ease symptoms such as hot flashes and night sweats while supporting bone, muscle, heart, and brain health.",
          "Like any treatment, HRT carries potential risks, including a small increase in the chance of blood clots, stroke, or certain cancers, which vary depending on individual health."
        ]
      },
      {
        title: "Your musculoskeletal health and HRT",
        sections: [
          {
            subtitle:
              "<strong>How HRT Supports Musculoskeletal Health</strong>",
            type: "list",
            items: [
              {
                text: "Muscles: Maintains or increases muscle strength and size, improves walking speed, and slows down age-related muscle loss (sarcopenia)."
              },
              {
                text: "Bones: Protects bone health, especially after menopause, reducing the risk of fractures and osteoporosis. A study showed people who used HRT had an average increase in bone density of 3.7% after 5 years."
              },
              {
                text: "Joints: Eases joint pain and inflammation, improving symptoms of osteoarthritis."
              },
              {
                text: "Overall: Balances muscle proteins, reduces inflammation, and prevents fat buildup in muscles, enhancing their overall function."
              }
            ]
          },
          {
            subtitle: "<strong>Potential musculoskeletal risks of HRT</strong>",
            type: "list",
            items: [
              {
                text: "Tendons and ligaments: May increase looseness (laxity), raising the risk of injuries, especially in high-stress areas like the knee (e.g., ACL tears)."
              },
              {
                text: "Individual responses: The effects of HRT can vary from person to person."
              }
            ]
          }
        ]
      }
    ]
  }
};
