import Vue from "vue";
import Vuetify from "vuetify/lib";
import { standardTheme } from "@/styles/themes.js";
import Ripple from "vuetify/lib/directives/ripple";
import Scroll from "vuetify/lib/directives/scroll";

Vue.use(Vuetify, {
  directives: {
    Ripple,
    Scroll
  }
});

const setTheme = {
  ...standardTheme,
  primary: standardTheme.vflGreen,
  vflPrimary: standardTheme.vflGreen,
  vflPrimaryDark: standardTheme.vflGreenDark1
};

const vuetify = new Vuetify({
  theme: {
    options: { customProperties: true },
    themes: {
      light: setTheme
    }
  }
});

export default vuetify;
