import { getRecommendationTextFromId } from "@/components/user-profile/history/get-recommendation-text.js";
import i18n from "@/plugins/i18n.js";

const RECOMMENDATION_INTERACTIONS = {
  0: "completed",
  1: "unresolved",
  2: "alreadyDoing",
  3: "wantsToTry",
  4: "notInterested"
};

export const getRecommendationEventText = item => {
  const interactionPath =
    RECOMMENDATION_INTERACTIONS[item.interactionType] || "default";

  const interaction = i18n.t(
    `userProfile.history.recommendation.interactions.${interactionPath}`
  );

  let recommendationText = getRecommendationTextFromId(item.name);
  recommendationText = recommendationText ? i18n.t(recommendationText) : "";

  if (recommendationText) {
    return i18n.t("userProfile.history.recommendation.text.withName", {
      interaction: interaction,
      recommendationText: recommendationText
    });
  } else {
    return i18n.t("userProfile.history.recommendation.text.noName", {
      interaction: interaction
    });
  }
};
