import MeasuringSystemConverter from "@/services/converters/measuring-system-converter";
import store from "@/store";

export {
  MeasurementTypes,
  WebcamStates,
  RangeConstants,
  DistanceFromScreenValues,
  ShoulderHeightValues,
  EarToShoulderDifferenceValues,
  ScreenHeightValues,
  getSanitisedScreenDistance,
  getSanitisedShoulderHeight,
  getScreenDistanceRange,
  getScreenHeightRange,
  getShoulderToEarRange,
  getShoulderHeightRange,
  screenHeightColour,
  screenDistanceColour,
  shoulderToEarColour,
  shoulderHeightColour
};

const MeasurementTypes = {
  screenDistance: "screenDistance",
  screenHeight: "screenHeight",
  shoulderToEar: "shoulderToEar",
  shoulderHeight: "shoulderHeight"
};

const WebcamStates = {
  loading: "loading",
  measurementUnavailable: "measurementUnavailable",
  measuringLive: "measuringLive",
  report: "report"
};

const RangeConstants = {
  screenDistance: {
    tooFar: "tooFar",
    far: "far",
    good: "good",
    close: "close",
    tooClose: "tooClose"
  },
  screenHeight: {
    tooHigh: "tooHigh",
    high: "high",
    good: "good",
    low: "low",
    tooLow: "tooLow"
  },
  generic: {
    good: "good",
    average: "average",
    bad: "bad"
  }
};

const DistanceFromScreenValues = {
  tooClose: 40,
  close: 50,
  far: 65,
  tooFar: 75,
  perfectDistance: 58
};

const ShoulderHeightValues = {
  average: 2,
  bad: 4
};

const EarToShoulderDifferenceValues = {
  average: 10,
  bad: 15
};

const ScreenHeightValues = {
  average: 5,
  bad: 10
};

function getSanitisedScreenDistance(results) {
  let value;
  if (results.distanceFromScreen >= DistanceFromScreenValues.far) {
    value = Math.abs(DistanceFromScreenValues.far - results.distanceFromScreen);
  } else if (results.distanceFromScreen <= DistanceFromScreenValues.close) {
    value = Math.abs(
      DistanceFromScreenValues.close - results.distanceFromScreen
    );
  }

  // needed for rounding error (can round down to 0cm so keep it at 1)
  value = value == 0 ? 1 : value;

  return store.getters.metricSystem
    ? value
    : MeasuringSystemConverter.cmToInches(value);
}

function getSanitisedShoulderHeight(results) {
  return store.getters.metricSystem
    ? results.shoulderHeightDifference
    : MeasuringSystemConverter.cmToInches(results.shoulderHeightDifference);
}

function getScreenDistanceRange(distance) {
  if (distance >= DistanceFromScreenValues.tooFar) {
    return RangeConstants.screenDistance.tooFar;
  } else if (distance >= DistanceFromScreenValues.far) {
    return RangeConstants.screenDistance.far;
  } else if (distance <= DistanceFromScreenValues.tooClose) {
    return RangeConstants.screenDistance.tooClose;
  } else if (distance <= DistanceFromScreenValues.close) {
    return RangeConstants.screenDistance.close;
  }
  return RangeConstants.screenDistance.good;
}

function getScreenHeightRange(heightAngle) {
  if (heightAngle > ScreenHeightValues.bad) {
    return RangeConstants.screenHeight.tooLow;
  } else if (heightAngle > ScreenHeightValues.average) {
    return RangeConstants.screenHeight.low;
  } else if (heightAngle >= -ScreenHeightValues.average) {
    return RangeConstants.screenHeight.good;
  } else if (heightAngle >= -ScreenHeightValues.bad) {
    return RangeConstants.screenHeight.high;
  }
  return RangeConstants.screenHeight.tooHigh;
}

function getShoulderToEarRange(differenceValue) {
  if (
    differenceValue > EarToShoulderDifferenceValues.bad ||
    differenceValue < -EarToShoulderDifferenceValues.bad
  ) {
    return RangeConstants.generic.bad;
  } else if (
    differenceValue > EarToShoulderDifferenceValues.average ||
    differenceValue < -EarToShoulderDifferenceValues.average
  ) {
    return RangeConstants.generic.average;
  }
  return RangeConstants.generic.good;
}

function getShoulderHeightRange(differenceValue) {
  if (differenceValue > ShoulderHeightValues.bad) {
    return RangeConstants.generic.bad;
  } else if (differenceValue > ShoulderHeightValues.average) {
    return RangeConstants.generic.average;
  }
  return RangeConstants.generic.good;
}

function screenHeightColour(range) {
  switch (range) {
    case RangeConstants.screenHeight.tooHigh:
    case RangeConstants.screenHeight.tooLow: {
      return "red";
    }
    case RangeConstants.screenHeight.high:
    case RangeConstants.screenHeight.low: {
      return "amber";
    }
    case RangeConstants.screenHeight.good: {
      return "green";
    }
    default:
      return "red";
  }
}

function screenDistanceColour(range) {
  switch (range) {
    case RangeConstants.screenDistance.tooFar:
    case RangeConstants.screenDistance.tooClose: {
      return "red";
    }
    case RangeConstants.screenDistance.far:
    case RangeConstants.screenDistance.close: {
      return "amber";
    }
    case RangeConstants.screenDistance.good: {
      return "green";
    }
    default:
      return "red";
  }
}

function shoulderToEarColour(range) {
  switch (range) {
    case RangeConstants.generic.bad:
      return "red";
    case RangeConstants.generic.average:
      return "amber";
    case RangeConstants.generic.good: {
      return "green";
    }
    default:
      return "red";
  }
}

function shoulderHeightColour(range) {
  switch (range) {
    case RangeConstants.generic.bad:
      return "red";
    case RangeConstants.generic.average:
      return "amber";
    case RangeConstants.generic.good: {
      return "green";
    }
    default:
      return "red";
  }
}
