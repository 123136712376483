<template>
  <vfl-callout size="large">
    <div class="d-sm-flex" style="gap: 2rem">
      <div class="mb-4 mb-sm-0 text-center">
        <img
          src="/images/illustrations/preExistingConditionsIllustration.svg"
        />
      </div>

      <div>
        <h3 class="text-h6 mb-5">
          {{ title }}
        </h3>

        <div class="health-conditions vitrueDarkGrey--text">
          <div
            v-for="condition in userConditions"
            :key="condition.key"
            class="d-flex align-start mb-4"
          >
            <img
              :src="condition.iconSrc"
              alt=""
              class="mx-4 pt-1"
              height="30"
              width="30"
            />
            <p class="mb-2">
              {{ condition.text }}
            </p>
          </div>
        </div>

        <p class="mb-2 vitrueDarkGrey--text" style="max-width: 80%">
          {{ summary }}
        </p>
      </div>
    </div>
  </vfl-callout>
</template>

<script>
import VflCallout from "@/components/vitrue-foundation-library/VflCallout.vue";
import {
  getUserConditions,
  getSummaryText
} from "@/components/assessment/pre-existing-conditions/report/summary-banner-text-service.js";

export default {
  name: "HealthConditionSummaryBanner",
  components: { VflCallout },
  props: {
    healthConditions: Object,
    assessmentType: String
  },
  created() {
    if (!this.healthConditions || typeof this.healthConditions !== "object") {
      this.$logger.captureException("invalid type for health conditions");
    }
  },
  computed: {
    title() {
      const root =
        "assessment.form.healthConditions.banners.report.summary.title";
      return this.$t(root);
    },
    userConditions() {
      return getUserConditions(this.healthConditions, this.assessmentType);
    },
    summary() {
      return getSummaryText(this.healthConditions, this.assessmentType);
    }
  }
};
</script>

<style lang="scss" scoped>
.health-conditions {
  div {
    max-width: 80%;
    position: relative;

    &:before {
      background-color: #d2d5e9;
      border-radius: 20px;
      bottom: 0;
      content: "";
      position: absolute;
      top: 0;
      width: 4px;
    }
  }
}

.vfl-callout {
  background: linear-gradient(
    to bottom right,
    #ffffff,
    rgba(217, 237, 255, 0.5),
    rgba(236, 226, 255, 0.5)
  );
}
</style>
