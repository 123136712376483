export default {
  REGULAR: {
    value: 1,
    perimeter: [
      { x: 130, y: 180 },
      { x: 570, y: 180 },
      { x: 570, y: 420 },
      { x: 130, y: 420 }
    ],
    textPosition: {
      x: 150,
      y: 250
    },
    text: "deskAssessment.setup.sims.deskTypes.regular",
    image: "desk.png"
  },
  CURVED: {
    value: 2,
    perimeter: [
      { x: 130, y: 180 },
      { x: 570, y: 180 },
      { x: 570, y: 420 },
      { x: 484, y: 420 },
      { x: 422, y: 396 },
      { x: 350, y: 388 },
      { x: 274, y: 396 },
      { x: 211, y: 420 },
      { x: 130, y: 420 }
    ],
    textPosition: {
      x: 150,
      y: 250
    },
    text: "deskAssessment.setup.sims.deskTypes.curved",
    image: "deskCurved.png"
  },
  L_SHAPE_LEFT: {
    value: 3,
    perimeter: [
      { x: 130, y: 120 },
      { x: 570, y: 120 },
      { x: 570, y: 300 },
      { x: 385, y: 300 },
      { x: 326, y: 325 },
      { x: 300, y: 354 },
      { x: 287, y: 395 },
      { x: 287, y: 495 },
      { x: 130, y: 495 }
    ],
    textPosition: {
      x: 150,
      y: 175
    },
    text: "deskAssessment.setup.sims.deskTypes.LShapeLeft",
    image: "deskLShapeLeft.png"
  },
  L_SHAPE_RIGHT: {
    value: 4,
    perimeter: [
      { x: 130, y: 120 },
      { x: 570, y: 120 },
      { x: 570, y: 495 },
      { x: 413, y: 495 },
      { x: 413, y: 392 },
      { x: 392, y: 342 },
      { x: 364, y: 316 },
      { x: 320, y: 300 },
      { x: 130, y: 300 }
    ],
    textPosition: {
      x: 150,
      y: 175
    },
    text: "deskAssessment.setup.sims.deskTypes.LShapeRight",
    image: "deskLShapeRight.png"
  },
  PHONE_BOOTH: {
    perimeter: [
      { x: 130, y: 117 },
      { x: 570, y: 117 },
      { x: 570, y: 222 },
      { x: 130, y: 222 }
    ],
    textPosition: {
      x: 150,
      y: 135
    },
    isWorkArea: true
  }
};
