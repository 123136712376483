<template>
  <div v-if="isLoading">
    <template v-for="(_, index) in maxRows">
      <v-skeleton-loader
        :key="index"
        :type="index === maxRows - 1 ? 'table-row' : 'table-row-divider'"
      />
    </template>
  </div>
  <table v-else-if="visibleRows.length" class="table">
    <thead v-if="showHeaders">
      <tr>
        <th
          v-for="column in columns"
          :key="column.key"
          :style="{ width: column.width }"
        >
          {{ column.label }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="(row, index) in visibleRows"
        :key="index"
        @click="handleRowClick(row)"
      >
        <td
          v-for="column in columns"
          :key="column.key"
          :style="{ width: column.width }"
        >
          <slot
            :name="column.key"
            :row="row"
            v-if="$scopedSlots[column.key]"
          ></slot>
          <template v-else-if="row[column.key]">
            {{ row[column.key] }}
          </template>
        </td>
      </tr>
    </tbody>
  </table>
  <div v-else class="table">
    <slot name="empty">No data available</slot>
  </div>
</template>

<script>
export default {
  name: "VflSimpleTable",
  props: {
    isLoading: false,
    columns: {
      type: Array,
      required: true
    },
    rows: {
      type: Array,
      default: () => []
    },
    maxRows: Number,
    showHeaders: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    visibleRows() {
      if (this.maxRows) {
        return this.rows.slice(0, this.maxRows);
      }
      return this.rows;
    }
  },
  methods: {
    handleRowClick(row) {
      this.$emit("row-click", row);
    }
  }
};
</script>

<style scoped lang="scss">
.table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  border: 1px solid var(--v-vflNeutralLight2-base);
  border-radius: 12px;
  overflow: hidden;
}

th,
td {
  padding: 0.675rem 1.25rem;
  text-align: left;
  border-bottom: 1px solid #f1f0ed;
}
tr {
  &:hover {
    background: #fcfcfc;
    cursor: pointer;
  }

  &:last-child td {
    border-bottom: none;
  }
}
</style>
