import { HistoryItem } from "./historyItem";
import { HISTORY_TYPES } from "@/components/user-profile/history/constants.js";

export class RecommendationHistoryItem extends HistoryItem {
  constructor(timestamp, name, interactionType) {
    super(timestamp, HISTORY_TYPES.RECOMMENDATION);
    this.name = name;
    this.interactionType = interactionType;
  }
}
