<template>
  <v-row justify="center" no-gutters>
    <div
      v-for="(step, index) in totalSteps"
      :key="index"
      :id="'step' + index"
      class="dot mx-3"
      :style="stepperColor(index)"
    />
  </v-row>
</template>

<script>
export default {
  name: "SimpleStepper",
  props: {
    currentStep: Number,
    totalSteps: Number
  },
  methods: {
    stepperColor(index) {
      let colour =
        index <= this.currentStep
          ? this.$vuetify.theme.currentTheme.primary
          : this.$vuetify.theme.currentTheme.disabled;
      return {
        "--color": colour
      };
    }
  }
};
</script>

<style scoped>
.dot {
  height: 8px;
  width: 8px;
  background-color: var(--color);
  border-radius: 50%;
  display: inline-block;
  position: relative;
  top: 6px;
}
</style>
