<template>
  <section style="width: 100%">
    <div class="report-container">
      <short-feedback
        v-model="quickFeedbackSubmitted"
        v-show="displayResultsFeedback"
        :assessmentId="assessmentId"
        @scrolledToBottom="readReport"
        urlSuffix="report"
        bottom-offset="5rem"
        trigger-on-scroll
      />
      <end-of-report-sign-up
        :assessmentType="assessmentType"
        :assessmentId="assessmentId"
      />
      <vfl-panels flat v-model="defaultPanelExpansion" multiple hover>
        <template v-slot:panels
          ><vfl-panel id="summary-panel">
            <template v-slot:header>{{
              $t("driverAssessment.report.titles.summary")
            }}</template>
            <template v-slot:content
              ><driver-summary-content :results="results"
            /></template>
          </vfl-panel>
          <vfl-panel v-if="hasPain" id="pain-panel">
            <template v-slot:header>{{ painTitle }}</template>
            <template v-slot:content>
              <pain-content
                :results="results"
                class="mt-4"
                :showPainDetails="false"
                :assessmentType="assessmentType"
              />
            </template>
          </vfl-panel>
          <vfl-panel id="driver-panel">
            <template v-slot:header>{{ drivingTitle }}</template>
            <template v-slot:content>
              <driving-content
                :results="results"
                :getReportCards="$options.getReportCards"
                :assessmentType="assessmentType"
              />
            </template>
          </vfl-panel>

          <vfl-panel id="recommendations-panel">
            <template v-slot:header>{{
              $t("driverAssessment.report.titles.recommendations")
            }}</template>
            <template v-slot:content>
              <recommendation-content
                :results="results"
                :assessmentId="assessmentId"
              />
            </template>
          </vfl-panel>
        </template>
      </vfl-panels>
    </div>
    <report-footer
      :assessmentId="assessmentId"
      :assessmentType="assessmentType"
      :reportContext="reportContext"
    />
  </section>
</template>

<script>
import VflPanel from "@/components/vitrue-foundation-library/expansion-panel/VflPanel.vue";
import VflPanels from "@/components/vitrue-foundation-library/expansion-panel/VflPanels.vue";
import DriverSummaryContent from "@/components/driver-assessment/report/summary/DriverSummaryContent.vue";
import DrivingContent from "@/components/common/assessments/ReportBodyContent.vue";
import RecommendationContent from "@/components/driver-assessment/report/recommendations/RecommendationContent.vue";
import ReportFooter from "@/components/common/report/ReportFooter.vue";
import PainContent from "@/components/deskassessment/report/content/PainContent.vue";
import { GetReportCards } from "./driving/report-body-generator.js";
import { assessmentTypes } from "@/constants/constants.js";
import EndOfReportSignUp from "@/components/authentication/EndOfReportSignUp.vue";
import ShortFeedback from "@/components/common/Feedback/ShortFeedback.vue";

export default {
  name: "DriverReport",
  props: {
    results: Object,
    assessmentId: String,
    reportContext: {
      type: String,
      required: true,
      validator: value => ["assessment", "archive"].includes(value)
    }
  },
  components: {
    VflPanel,
    VflPanels,
    DriverSummaryContent,
    DrivingContent,
    RecommendationContent,
    ReportFooter,
    PainContent,
    EndOfReportSignUp,
    ShortFeedback
  },
  data() {
    return {
      defaultPanelExpansion: [0, 1, 2, 3],
      quickFeedbackSubmitted: false
    };
  },
  getReportCards: GetReportCards,
  computed: {
    hasPain() {
      if (!this.results?.pain?.areas) {
        return false;
      }
      return Object.keys(this.results.pain.areas).length > 0;
    },
    assessmentType() {
      return assessmentTypes.driverAssessment;
    },
    displayResultsFeedback() {
      return this.$route.path.includes("driver-assessment");
    },
    painTitle() {
      return `${this.$t("driverAssessment.report.titles.part")} 1: ${this.$t(
        "driverAssessment.report.titles.pain"
      )}`;
    },
    drivingTitle() {
      const partNumber = this.hasPain ? 2 : 1;
      return `${this.$t(
        "driverAssessment.report.titles.part"
      )} ${partNumber}: ${this.$t("driverAssessment.report.titles.driving")}`;
    }
  },
  methods: {
    readReport() {
      this.$mixpanel.track(assessmentTypes.driverAssessment, {
        step: "Scrolled to end of report"
      });
    }
  }
};
</script>
