<template>
  <div>
    <v-slider
      :class="increaseThumbText ? 'pt-16' : 'pt-14'"
      :value="`${score}`"
      :color="scoreColour?.color"
      readonly
      hide-details
      min="0"
      max="100"
      track-color="transparent"
      track-fill-color="transparent"
      thumb-label="always"
      thumb-size="15"
    >
      <template v-slot:thumb-label>
        <p
          style="position: absolute; width: 65px; left: -30px"
          :style="increaseThumbText ? 'top: -75px;' : 'top: -55px;'"
          class="black--text text-center"
        >
          <span class="text-caption grey--text">{{
            $t("deskAssessment.results.report.summary.score.yourScore")
          }}</span>
          <br />
          <span
            class="text-body-1 score"
            :class="`${scoreColour?.textColor}--text`"
            ><strong>{{ score }}%</strong></span
          >
        </p>
      </template>
    </v-slider>
    <div class="d-flex">
      <span>0%</span>
      <v-spacer />
      <span>100%</span>
    </div>
    <div id="industryAverageParent" v-show="industryAverage">
      <div class="arrow industryAverage"></div>
      <div class="industryAverage text-body-2 vitrueDarkGrey--text text-center">
        {{ industryAverage }}%
      </div>
      <div
        class="industryAverage text-caption vitrueDarkGrey--text text-center"
      >
        {{ $t("deskAssessment.results.report.summary.score.industryAverage") }}
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
export default {
  name: "ScoreSlider",
  props: {
    score: Number,
    industryAverage: Number,
    scoreColour: Object,
    sections: {
      type: Array,
      default: () => []
    }
  },
  async created() {
    window.addEventListener("resize", this.positionElements);
  },
  mounted() {
    Vue.nextTick(() => {
      this.positionElements();
    });
  },
  destroyed() {
    window.removeEventListener("resize", this.positionElements);
  },
  watch: {
    industryAverage() {
      this.positionElements();
    }
  },
  computed: {
    increaseThumbText() {
      return this._i18n.locale.includes("es");
    },
    backgroundStyle() {
      const gradients = this.sections.map((section, index) => {
        const nextSection = this.sections[index + 1] || { percent: 100 };
        return `var(--v-${section.color}-base) ${section.percent}% ${nextSection.percent}%`;
      });

      return `linear-gradient(90deg, ${gradients.join(", ")})`;
    }
  },
  methods: {
    positionElements() {
      if (this.industryAverage) {
        for (const element of document.getElementsByClassName(
          "industryAverage"
        )) {
          this.positionElement(element);
        }
      }
    },
    positionElement(element) {
      let parent = document.getElementById("industryAverageParent");
      const parentWidth = parent.offsetWidth;
      const width = element.offsetWidth;
      const rawPosition = (parentWidth * this.industryAverage) / 100;

      const xPosition = Math.min(
        Math.max(rawPosition - width / 2, 0),
        parentWidth - width
      );
      element.style.transform = `translateX(${xPosition}px)`;
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .v-input__slot {
  border-radius: 10px;
  height: 16px;
  width: 100%;
  background: v-bind(backgroundStyle) !important;
}
/*noinspection CssUnusedSymbol*/
::v-deep .v-slider__thumb {
  border-radius: 50% 50% 0;
  box-shadow: 0px 0px 2px 0px white inset !important;
  transform: rotate(225deg) translateY(8px) translateX(8px);
}
/*noinspection CssUnusedSymbol*/
::v-deep .v-slider__track-container {
  height: 16px !important;
}

.arrow {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid var(--v-vitrueDarkGrey-base);
}

.industryAverage {
  width: -webkit-fit-content;
  height: -webkit-fit-content;
  width: -moz-fit-content;
  height: -moz-fit-content;
  width: fit-content;
  height: fit-content;
}
</style>
