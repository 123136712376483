import store from "@/store";
import { userTeamMemberInformation, getSimpleUserDetails } from "@/customApi";
import mixpanel from "mixpanel-browser";
import { VIDARoles } from "@/services/authorization/user-types.js";

export default {
  async getTeamMemberInfo() {
    let teamMemberId = store.getters.teamMemberId;
    let teamMemberInfo = store.getters.teamMemberInfo;

    if (
      !teamMemberId ||
      !teamMemberInfo ||
      teamMemberInfo?.teamMemberId !== teamMemberId
    ) {
      teamMemberInfo = await userTeamMemberInformation();
      if (!teamMemberInfo) {
        throw new Error("Failed to fetch team member information");
      }
      store.commit("setTeamMemberId", teamMemberInfo.teamMemberId);
      store.commit("setTeamMemberInfo", teamMemberInfo);
      store.commit("setTeamIdpName", teamMemberInfo.teamIDP);
      store.commit("setProvisionedTeam", teamMemberInfo.provisionedTeam);

      const isEndUser = teamMemberInfo.role === VIDARoles.EndUser;
      const identityString = isEndUser
        ? `enduser_${teamMemberInfo.endUserId}`
        : `admin_${teamMemberInfo.teamMemberId}`;
      mixpanel.identify(identityString);
      mixpanel.register({ stripeCustomerId: teamMemberInfo.customerId });

      await store.dispatch("setFeatureFlags", teamMemberInfo);
    }

    return teamMemberInfo;
  },
  async updateEndUserInfo() {
    let userEmail = store.getters.userEmail;
    let teamInfo = store.getters.currentUserTeamAndSubscriptionBasicInformation;
    if (!teamInfo || !teamInfo.accessTier || !userEmail) {
      let userDetails = await getSimpleUserDetails();
      await store.dispatch("setSimpleUserDetails", userDetails);
    }
  }
};
