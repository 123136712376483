<template>
  <div class="container">
    <h1>{{ $t("simsUploadPage.title") }}</h1>
    <p v-if="!uploadSuccess">
      {{ $t("simsUploadPage.instruction") }}
    </p>
    <p v-if="uploadSuccess" class="success-message">
      {{ $t("simsUploadPage.success") }}
    </p>

    <div v-if="!uploadSuccess" class="camera">
      <video
        v-show="!imageData"
        ref="video"
        class="video"
        autoplay
        playsinline
      ></video>
      <canvas v-show="imageData" ref="canvas" class="canvas"></canvas>
      <button
        v-if="!uploadSuccess && !imageData"
        @click="switchCamera"
        class="switch-camera-button"
      >
        <v-icon color="white">mdi-camera-flip-outline</v-icon>
      </button>
      <button
        v-if="imageData"
        @click="rotateImage"
        class="rotate-button"
      >
        <v-icon color="white">mdi-rotate-right</v-icon>
      </button>
    </div>

    <div v-if="!uploadSuccess" class="instructions">
      <button
        v-if="!imageData"
        @click="takePicture"
        :disabled="loading"
        class="vfl-button-primary"
      >
        {{ $t("simsUploadPage.takePicture") }}
      </button>
      <button
        v-else
        @click="retakePicture"
        :disabled="loading"
        class="vfl-button-primary"
      >
        {{ $t("simsUploadPage.retakePicture") }}
      </button>
      <button
        v-if="imageData && !uploadSuccess"
        @click="submitPicture"
        :disabled="loading"
        class="vfl-button-primary"
      >
        <span v-if="loading"> {{ $t("simsUploadPage.uploading") }}</span>
        <span v-else> {{ $t("simsUploadPage.submit") }}</span>
      </button>
    </div>
  </div>
</template>

<script>
import { uploadSimsImage } from "@/customApi.js";

export default {
  data() {
    return {
      assessmentId: null,
      imageData: null,
      loading: false,
      uploadSuccess: false,
      stream: null,
      currentDeviceId: null,
      videoDevices: []
    };
  },
  methods: {
    async getVideoStream(deviceId = null) {
      // Stop any existing stream
      if (this.stream) {
        this.stream.getTracks().forEach(track => track.stop());
      }

      try {
        // Get user media with the selected deviceId (or default if null)
        this.stream = await navigator.mediaDevices.getUserMedia({
          video: deviceId ? { deviceId: { exact: deviceId } } : true,
          audio: false
        });

        this.$refs.video.srcObject = this.stream;

        // Enumerate devices to find available cameras
        const devices = await navigator.mediaDevices.enumerateDevices();
        this.videoDevices = devices.filter(
          device => device.kind === "videoinput"
        );
        if (deviceId === null && this.videoDevices.length > 0) {
          this.currentDeviceId = this.videoDevices[0].deviceId; // Default to the first camera
        }
      } catch (err) {
        console.error("Error accessing camera:", err);
      }
    },
    takePicture() {
      const canvas = this.$refs.canvas;
      const video = this.$refs.video;
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      canvas.getContext("2d").drawImage(video, 0, 0);
      this.imageData = canvas.toDataURL("image/png").split(",")[1]; // Get base64 data
    },
    retakePicture() {
      this.imageData = null;
    },
    rotateImage() {
      const canvas = this.$refs.canvas;
      const context = canvas.getContext("2d");
      const img = new Image();
      img.src = `data:image/png;base64,${this.imageData}`;
      img.onload = () => {
        // Set canvas dimensions based on rotation
        canvas.width = this.rotationAngle % 180 === 0 ? img.width : img.height;
        canvas.height = this.rotationAngle % 180 === 0 ? img.height : img.width;

        context.clearRect(0, 0, canvas.width, canvas.height);
        context.save();
        context.translate(canvas.width / 2, canvas.height / 2);
        context.rotate((90 * Math.PI) / 180); // Convert degrees to radians
        context.drawImage(img, -img.width / 2, -img.height / 2);
        context.restore();
        this.imageData = canvas.toDataURL("image/png").split(",")[1]; // Update imageData with rotated image
      };
    },
    async submitPicture() {
      this.loading = true;
      try {
        await uploadSimsImage(this.assessmentId, this.imageData);
        this.uploadSuccess = true;
      } catch (err) {
        console.error("Error uploading image:", err);
        alert("There was an error uploading the image. Please try again.");
      } finally {
        this.loading = false;
      }
    },
    switchCamera() {
      // Find the index of the current device
      const currentDeviceIndex = this.videoDevices.findIndex(
        device => device.deviceId === this.currentDeviceId
      );
      // Switch to the next device in the list, or loop back to the first
      const nextDeviceIndex =
        (currentDeviceIndex + 1) % this.videoDevices.length;
      this.currentDeviceId = this.videoDevices[nextDeviceIndex].deviceId;
      this.getVideoStream(this.currentDeviceId);
    }
  },
  mounted() {
    this.assessmentId = this.$route.query.assessmentId;
    this.getVideoStream();
  }
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  text-align: center;
}

.camera {
  position: relative;
  width: 100%;
  max-width: 400px;
}

.video,
.canvas {
  width: 100%;
  height: auto;
  border: 1px solid #ccc;
  border-radius: 10px;
}

.instructions {
  margin-top: 20px;
}

button {
  margin: 10px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.success-message {
  color: green;
  font-size: 18px;
  font-weight: bold;
}

.switch-camera-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  color: white;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
}

.switch-camera-button:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.rotate-button {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  cursor: pointer;
  padding: 10px;
}
</style>