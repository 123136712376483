﻿export class FocusArea {
  static ReducePain = new FocusArea("ReducePain", 0);
  static ImproveWellbeing = new FocusArea("ImproveWellbeing", 1);
  static FixPosture = new FocusArea("FixPosture", 2);

  constructor(stringValue, intValue) {
    this.stringValue = stringValue;
    this.intValue = intValue;
  }

  static fromStringValue(value) {
    switch (value) {
      case FocusArea.ReducePain.stringValue:
        return FocusArea.ReducePain;
      case FocusArea.ImproveWellbeing.stringValue:
        return FocusArea.ImproveWellbeing;
      case FocusArea.FixPosture.stringValue:
        return FocusArea.FixPosture;
      default:
        return FocusArea.ImproveWellbeing;
    }
  }

  static fromIntValue(value) {
    switch (value) {
      case FocusArea.ReducePain.intValue:
        return FocusArea.ReducePain;
      case FocusArea.ImproveWellbeing.intValue:
        return FocusArea.ImproveWellbeing;
      case FocusArea.FixPosture.intValue:
        return FocusArea.FixPosture;
      default:
        return FocusArea.ImproveWellbeing;
    }
  }
}
