<template>
  <svg
    width="641"
    height="637"
    viewBox="0 0 641 637"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M563.054 63.2582V268.618H399.778V61.737C398.823 61.9725 397.869 62.2143 396.92 62.4657C395.972 62.714 395.033 62.9717 394.098 63.239V279.043H568.738V68.3658C566.886 66.6219 564.989 64.9162 563.054 63.2582Z"
      fill="#002822"
    />
    <g clip-path="url(#clip0_3275_79940)">
      <path
        d="M563.054 268.617H407.17H399.777V255.615V192.378V63.8656H563.054V135.863V201.358V268.617Z"
        fill="#A8C9F3"
      />
      <path
        d="M471.284 109.294C471.284 105.543 468.242 102.503 464.491 102.503C463.813 102.503 463.159 102.605 462.54 102.79C462.641 102.109 462.694 101.413 462.694 100.705C462.694 92.7618 456.255 86.3231 448.312 86.3231C447.407 86.3231 446.521 86.41 445.662 86.5704C443.452 82.9523 439.478 80.531 434.929 80.531C428.042 80.531 422.455 86.0637 422.354 92.9247L422.144 92.9151C415.746 92.9151 410.559 98.1015 410.559 104.5C410.559 110.898 415.746 116.086 422.144 116.086H464.692L464.682 116.076C468.343 115.974 471.284 112.981 471.284 109.294Z"
        fill="white"
      />
      <path
        d="M439.748 201.717C439.748 199.383 441.64 197.491 443.974 197.491C444.395 197.491 444.803 197.555 445.188 197.67C445.125 197.246 445.093 196.813 445.093 196.373C445.093 191.43 449.099 187.423 454.042 187.423C454.604 187.423 455.156 187.478 455.69 187.578C457.065 185.327 459.537 183.82 462.369 183.82C466.654 183.82 470.13 187.262 470.193 191.531L470.323 191.525C474.304 191.525 477.533 194.752 477.533 198.734C477.533 202.715 474.304 205.943 470.323 205.943H443.85L443.855 205.937C441.577 205.873 439.748 204.011 439.748 201.717Z"
        fill="white"
      />
      <path
        d="M536.818 172.346H535.345L544.352 161.965L535.895 161.969L528.506 174.32H531.316H533.633H535.637L525.705 190.92L541.82 172.346H538.398H536.818Z"
        fill="#E87613"
      />
    </g>
    <path
      d="M568.737 68.3652H394.094V59.7188H568.737V68.3652Z"
      fill="#002822"
    />
    <path
      d="M584.198 279.039H377.314C377.314 273.352 381.926 268.744 387.612 268.744H573.9C579.587 268.744 584.198 273.352 584.198 279.039Z"
      fill="#002822"
    />
    <path
      d="M527.482 159.789C527.482 159.789 527.319 159.218 526.958 158.367H527.991C527.993 158.484 527.996 158.6 528.002 158.717C527.667 159.38 527.482 159.789 527.482 159.789ZM530.008 151.426H520.536C520.083 151.262 519.603 151.136 519.096 151.056C518.622 150.981 518.148 150.947 517.682 150.947C516.606 150.947 515.559 151.13 514.566 151.426L505.328 157.409L507.694 151.426C507.902 148.585 507.951 145.104 507.208 143.217C506.647 141.797 504.416 140.443 501.945 139.329L501.435 132.879C502.477 132.136 504.015 130.627 504.16 129.764C504.827 125.807 503.57 125.092 502.214 122.367C500.335 118.591 496.373 114.212 496.373 114.212C497.24 114.259 503.766 114.61 504.738 114.61C506.442 114.61 508.2 114.51 509.645 114.212C517.587 112.569 516.765 101.342 516.765 101.342C516.765 101.342 522.045 109.01 527.482 110.104C527.872 110.183 528.271 110.219 528.679 110.219C533.486 110.219 539.373 105.166 540.424 104.229C540.461 104.248 540.496 104.268 540.533 104.287C540.159 105.716 538.085 114.126 540.588 116.129C541.501 116.859 542.961 117.102 544.503 117.102C547.587 117.102 550.993 116.129 550.993 116.129C550.993 116.129 543.601 122.439 544.148 125.309C544.696 128.177 556.47 132.048 556.47 132.048C556.47 132.048 545.517 134.75 544.422 138.309C544.035 139.566 544.277 141.525 544.751 143.533C543.931 144.254 543.221 145.098 542.648 146.036C541.908 145.898 541.147 145.823 540.369 145.823C536.033 145.823 532.218 148.051 530.008 151.426ZM501.945 139.329C501.339 139.151 494.037 136.545 494.037 136.545C494.037 136.545 500.838 133.077 501.435 132.879L501.945 139.329Z"
      fill="#F5E075"
    />
    <path
      d="M541.698 131.458C541.698 140.982 533.977 148.703 524.454 148.703C514.93 148.703 507.209 140.982 507.209 131.458C507.209 121.934 514.93 114.213 524.454 114.213C533.977 114.213 541.698 121.934 541.698 131.458Z"
      fill="#FCF4CD"
    />
    <path
      d="M486.672 67.8058V273.359H480.756V66.186C482.732 66.692 484.708 67.233 486.672 67.8058Z"
      fill="#002822"
    />
    <mask
      id="mask0_3275_79940"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="507"
      y="126"
      width="56"
      height="52"
    >
      <rect
        x="507.609"
        y="126.676"
        width="55.1946"
        height="50.6705"
        fill="#D9D9D9"
      />
    </mask>
    <g mask="url(#mask0_3275_79940)">
      <path
        d="M511.195 157.247C511.195 153.496 514.236 150.456 517.986 150.456C518.664 150.456 519.318 150.558 519.937 150.743C519.838 150.062 519.784 149.366 519.784 148.658C519.784 140.715 526.224 134.276 534.167 134.276C535.072 134.276 535.956 134.363 536.815 134.524C539.025 130.907 542.999 128.483 547.549 128.483C554.435 128.483 560.022 134.017 560.125 140.878L560.334 140.868C566.733 140.868 571.919 146.055 571.919 152.454C571.919 158.851 566.733 164.039 560.334 164.039H517.787L517.796 164.029C514.134 163.927 511.195 160.934 511.195 157.247Z"
        fill="#85A1C4"
      />
    </g>
    <mask
      id="mask1_3275_79940"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="377"
      y="255"
      width="71"
      height="32"
    >
      <rect
        x="377.312"
        y="255.162"
        width="70.5767"
        height="31.669"
        fill="#D9D9D9"
      />
    </mask>
    <g mask="url(#mask1_3275_79940)">
      <path
        d="M437.58 337.471H324.363L331.943 259.634H445.16L437.58 337.471Z"
        fill="#45391D"
      />
    </g>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M574.609 288.465H591.711V278.586H574.609V288.465Z"
      fill="#B6D4FF"
    />
    <g clip-path="url(#clip1_3275_79940)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M404.716 329.672C404.716 237.677 371.848 150.412 371.848 150.412H360.605V329.672H404.716Z"
        fill="#F7BE7D"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M404.716 41.623C404.716 97.453 371.848 150.412 371.848 150.412H360.605V41.623H404.716Z"
        fill="#F7BE7D"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M376.637 158.255L356.506 159.518V143.992L376.637 145.256V158.255Z"
        fill="#A8C5C1"
      />
      <path
        d="M380.906 227.906C376.237 196.923 372.109 170.185 366.244 154.906C370.056 171.883 370.811 197.528 373.282 227.906C376.227 264.13 375.139 301.638 374.748 328.801C374.748 328.801 378.108 329.54 380.276 329.559C382.592 329.579 386.184 328.602 386.184 328.602C385.597 307.098 386.292 263.652 380.906 227.906Z"
        fill="#E29946"
      />
    </g>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M558.482 329.707C558.482 237.713 590.047 150.447 590.047 150.447H600.844V329.707C600.844 329.707 587.952 337.612 579.268 337.499C570.883 337.391 558.482 329.707 558.482 329.707Z"
      fill="#F7BE7D"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M558.482 41.6582C558.482 97.4882 590.047 150.447 590.047 150.447H600.844V41.6582H558.482Z"
      fill="#F7BE7D"
    />
    <path
      d="M585.03 49.5293H573.594C571.483 88.9154 584.834 124.651 592.946 139.506C579.458 99.6108 582.293 64.2425 585.03 49.5293Z"
      fill="#E29946"
    />
    <path
      d="M374.962 41.623H386.398C388.509 81.0091 375.159 116.745 367.046 131.6C380.534 91.7045 377.699 56.3362 374.962 41.623Z"
      fill="#E29946"
    />
    <path
      d="M578.874 235.848C583.542 204.865 587.671 178.127 593.535 162.848C589.723 179.824 588.968 205.47 586.498 235.848C583.552 272.071 584.641 309.579 585.032 336.742C585.032 336.742 581.672 337.482 579.503 337.501C577.187 337.521 573.596 336.543 573.596 336.543C574.182 315.039 573.488 271.593 578.874 235.848Z"
      fill="#E29946"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M585.115 158.287L603.928 159.55V144.023L585.115 145.287V158.287Z"
      fill="#A8C5C1"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M585.113 158.286L603.926 159.549V151.662L585.113 152.926V158.286Z"
      fill="#658C7D"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M376.412 157.944L356.506 159.249V151.105L376.412 152.411V157.944Z"
      fill="#658C7D"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M342.289 49.806H617.739V41.6582H342.289V49.806Z"
      fill="#A8C5C1"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M348.887 45.7328L342.29 55.2728L335.693 45.7328L342.29 36.1934L348.887 45.7328Z"
      fill="#A8C5C1"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M624.334 46.7178L617.737 56.2578L611.141 46.7178L617.737 37.1777L624.334 46.7178Z"
      fill="#A8C5C1"
    />
    <defs>
      <clipPath id="clip0_3275_79940">
        <rect
          width="163.276"
          height="204.752"
          fill="white"
          transform="translate(399.777 63.8652)"
        />
      </clipPath>
      <clipPath id="clip1_3275_79940">
        <rect
          width="48.8608"
          height="218.064"
          fill="white"
          transform="translate(356.504 41.623)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
<script>
export default {
  name: "MoodChangesOverlay"
};
</script>
