<template>
  <section style="width: 100%">
    <div class="report-container">
      <short-feedback
        v-model="quickFeedbackSubmitted"
        v-show="displayResultsFeedback"
        :assessmentId="assessmentId"
        @scrolledToBottom="readReport"
        urlSuffix="report"
        bottom-offset="5rem"
        trigger-on-scroll
      />
      <end-of-report-sign-up
        :assessmentType="assessmentType"
        :assessmentId="assessmentId"
      />
      <vfl-panels flat v-model="defaultPanelExpansion" multiple hover>
        <template v-slot:panels
          ><vfl-panel id="summary-panel">
            <template v-slot:header>{{
              $t("physicalLabourAssessment.report.titles.summary")
            }}</template>
            <template v-slot:content>
              <physical-labour-summary-content :results="results" />
            </template>
          </vfl-panel>
          <vfl-panel v-if="hasPain" id="pain-panel">
            <template v-slot:header>{{
              $t("physicalLabourAssessment.report.titles.pain")
            }}</template>
            <template v-slot:content>
              <pain-content
                :results="results"
                class="mt-4"
                :showPainDetails="false"
                :assessmentType="assessmentType"
              />
            </template>
          </vfl-panel>
          <vfl-panel id="tasks-panel">
            <template v-slot:header>{{
              $t("physicalLabourAssessment.report.titles.riskyActivities")
            }}</template>
            <template v-slot:content>
              <report-body-content
                :results="results"
                :getReportCards="$options.getActivityCards"
                :assessmentType="assessmentType"
              />
            </template>
          </vfl-panel>
          <vfl-panel id="reba-panel">
            <template v-slot:header>{{
              $t(
                "physicalLabourAssessment.report.titles.rapidEntireBodyAssessment"
              )
            }}</template>
            <template v-slot:content>
              <report-body-content
                :results="results"
                :getReportCards="$options.getBodyAssessmentCards"
                :assessmentType="assessmentType"
              >
                <template v-slot:noCards
                  ><reba-good-score-message :results="results" /> </template
              ></report-body-content>
            </template>
          </vfl-panel>

          <vfl-panel id="recommendations-panel">
            <template v-slot:header>{{
              $t("physicalLabourAssessment.report.titles.recommendations")
            }}</template>
            <template v-slot:content>
              <recommendation-content
                :results="results"
                :assessmentId="assessmentId"
              ></recommendation-content>
            </template>
          </vfl-panel>
        </template>
      </vfl-panels>
    </div>
    <report-footer
      :assessmentId="assessmentId"
      :assessmentType="assessmentType"
      :reportContext="reportContext"
    />
  </section>
</template>

<script>
import VflPanel from "@/components/vitrue-foundation-library/expansion-panel/VflPanel.vue";
import VflPanels from "@/components/vitrue-foundation-library/expansion-panel/VflPanels.vue";
import ReportFooter from "@/components/common/report/ReportFooter.vue";
import { routes } from "@/router/route-constants.js";
import { getActivityCards } from "./posture/report-activity-body-generator.js";
import { getBodyAssessmentCards } from "./posture/report-reba-body-generator.js";
import { assessmentTypes } from "@/constants/constants.js";
import EndOfReportSignUp from "@/components/authentication/EndOfReportSignUp.vue";
import ShortFeedback from "@/components/common/Feedback/ShortFeedback.vue";
import PainContent from "@/components/deskassessment/report/content/PainContent.vue";
import PhysicalLabourSummaryContent from "./summary/PhysicalLabourSummaryContent.vue";
import ReportBodyContent from "@/components/common/assessments/ReportBodyContent.vue";
import RecommendationContent from "@/components/assessment/physical-labour/report/recommendations/RecommendationContent.vue";
import RebaGoodScoreMessage from "@/components/assessment/physical-labour/report/RebaGoodScoreMessage.vue";

export default {
  name: "PhysicalLabourReport",
  props: {
    results: Object,
    assessmentId: String,
    reportContext: {
      type: String,
      required: true,
      validator: value => ["assessment", "archive"].includes(value)
    }
  },
  components: {
    RecommendationContent,
    VflPanel,
    VflPanels,
    ReportFooter,
    PainContent,
    EndOfReportSignUp,
    ShortFeedback,
    PhysicalLabourSummaryContent,
    ReportBodyContent,
    RebaGoodScoreMessage
  },
  data() {
    return {
      defaultPanelExpansion: [0, 1, 2, 3, 4],
      quickFeedbackSubmitted: false
    };
  },
  getActivityCards: getActivityCards,
  getBodyAssessmentCards: getBodyAssessmentCards,
  computed: {
    displayResultsFeedback() {
      return this.$route.name === routes.PHYSICAL_LABOUR_ASSESSMENT;
    },
    hasPain() {
      if (!this.results?.pain?.areas) {
        return false;
      }
      return Object.keys(this.results.pain.areas).length > 0;
    },
    assessmentType() {
      return assessmentTypes.physicalLabourAssessment;
    }
  },
  methods: {
    readReport() {
      this.$mixpanel.track(assessmentTypes.physicalLabourAssessment, {
        step: "Scrolled to end of report"
      });
    }
  }
};
</script>

<style lang="scss" scoped>
// hides the tasks panel if no cards exist
#tasks-panel:has(.v-expansion-panel-content__wrap > div:empty) {
  display: none;
}
</style>
