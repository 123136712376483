<template>
  <div v-if="isLoading" class="d-flex align-center">
    <v-skeleton-loader type="avatar" />

    <v-skeleton-loader
      type="chip"
      height="32"
      width="200"
      class="loader-skeleton ml-4"
    />
  </div>
  <div class="d-flex align-center" v-else-if="name || email">
    <user-profile-avatar class="avatar" />
    <div class="pl-4 user-name-and-email" :style="{ maxWidth: width }">
      <p class="ma-0 text-h6 user-name">
        {{ name || email }}

        <router-link
          v-if="layout === 'sidepanel'"
          :to="`people/${id}`"
          target="_blank"
          @click.native="onViewFullPage"
          ><v-icon size="16" color="vflPrimary">mdi-open-in-new</v-icon>
          <span class="d-sr-only">Open full page</span></router-link
        >
      </p>
      <p v-if="name" class="mb-0 text-body-2">
        {{ email }}
      </p>
    </div>
  </div>
</template>
<script>
import UserProfileAvatar from "@/components/user-profile/UserProfileAvatar.vue";
import { mapGetters } from "vuex";
import { sendEvent } from "@/services/analytics/analytics.js";

export default {
  name: "UserProfileNameAndEmail",
  components: { UserProfileAvatar },
  props: {
    width: {
      type: String,
      default: "320px"
    },
    layout: {
      type: String,
      default: "page",
      validator: value => ["sidepanel", "page"].includes(value)
    }
  },
  computed: {
    ...mapGetters("userProfile", ["loadingStates", "name", "email", "id"]),
    ...mapGetters({ adminId: "userId" }),
    isLoading() {
      return this.loadingStates.userDetails;
    }
  },
  methods: {
    onViewFullPage() {
      sendEvent("MSK Passport full page opened", {
        from: "MSK Passport",
        end_user: this.id,
        admin: this.adminId
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.avatar {
  flex: 0 0 50px;
}

.user-name-and-email {
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.user-name {
  line-height: 1;
  display: inline;
}

.loader-skeleton ::v-deep .v-skeleton-loader__chip {
  height: 100%;
  width: 100%;
}
</style>
