<template>
  <v-row justify="center" align="center" no-gutters>
    <v-col :cols="$vuetify.breakpoint.smAndDown ? '8' : '4'"
      ><v-img max-height="350px" contain src="/images/dashboard_locked.svg"
    /></v-col>

    <v-col :cols="$vuetify.breakpoint.smAndDown ? '8' : '6'"
      ><p class="text-h4">
        {{ $t("accountBanner.hasNoActiveTeam.title") }}
      </p>
      <p>{{ $t("accountBanner.hasNoActiveTeam.text") }}</p></v-col
    >
  </v-row>
</template>
<script>
export default {
  name: "DashboardDeactivatedUserBanner"
};
</script>
