<template>
  <v-dialog
    :value="dialogVisible"
    :width="dialogWidth"
    persistent
    max-width="1020"
    @input="updateVisibility"
  >
    <invite-user-to-assessment-dialog
      :assessmentType="assessmentType"
      :preloadedUser="user"
      v-model="dialogVisible"
    />
  </v-dialog>
</template>

<script>
import InviteUserToAssessmentDialog from "@/components/dashboard/InviteDialog/InviteUserToAssessmentDialog.vue";
import { assessmentTypes } from "@/constants/constants.js";
import { mapGetters } from "vuex";

export default {
  name: "UserProfileAssessmentInviteDialog",
  components: { InviteUserToAssessmentDialog },
  props: {
    assessmentType: {
      type: String,
      default: assessmentTypes.deskAssessment
    },
    show: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    ...mapGetters("userProfile", ["id", "name", "email", "tags"]),
    dialogVisible: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      }
    },
    dialogWidth() {
      return this.$vuetify.breakpoint.mdAndDown ? "90%" : "70%";
    },
    user() {
      return {
        id: this.id,
        name: this.name,
        email: this.email,
        tags: this.tags
      };
    }
  },
  methods: {
    updateVisibility: function (value) {
      this.dialogVisible = value;
    }
  }
};
</script>
