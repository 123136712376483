<template>
  <vfl-callout backgroundColor="vflBeigeLight2" style="width: fit-content">{{
    $t("mskHealthCulture.notEnoughData")
  }}</vfl-callout>
</template>

<script>
import VflCallout from "@/components/vitrue-foundation-library/VflCallout.vue";

export default {
  name: "MskHealthCultureNotEnoughData",
  components: {
    VflCallout
  }
};
</script>
