<template>
  <button
    class="ai-assistant-action-button"
    :class="[classList, { 'is-disabled': disabled }]"
    @click="$emit('click')"
    type="button"
    :style="{ zIndex: 1 }"
  >
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: "AiAssistantInputActionButton",
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    classList: {
      type: String
    }
  }
};
</script>

<style lang="scss" scoped>
.ai-assistant-action-button {
  &.is-disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}
</style>
