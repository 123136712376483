import { LinkTypes } from "@/services/recommendations/link-types.js";
import * as ScoreService from "@/services/assessment/physical-labour/reba-scoring-service.js";
import Cards from "./RecommendationCards.json";
import sentry from "@/sentry";
import PainService from "@/services/assessment/pain/pain-assessment-service.js";
import answers from "@/services/assessment/physical-labour/answers.json";
import {
  rebaPainAreas,
  assessmentKeys
} from "@/components/assessment/physical-labour/utils/constants.js";

export default function getWarmUpRecommendations(results) {
  const recommendations = getAllRecommendations();
  const cardsToShow = recommendations.filter(r => {
    try {
      return r.showAnswer(results);
    } catch (e) {
      sentry.captureException(e);
      return false;
    }
  });
  return cardsToShow.slice(0, 1);
}

function hasSomeAnswers(results, section, question, answers) {
  if (!results[section][question]) {
    sentry.captureMessage(`No result found for question ${question}`, "error");
    return false;
  }
  return answers.some(a => results[section][question].includes(a));
}

function showWristWarmUpCard(results) {
  const grippingOrVibration = hasSomeAnswers(
    results,
    assessmentKeys.jobNature,
    assessmentKeys.jobActivities,
    [
      answers.jobNature.jobActivities.gripping,
      answers.jobNature.jobActivities.vibration
    ]
  );
  const movesWristRepeatedly = PainService.hasPainAreaFromValues(
    results.jobNature.repetitiveMovementBodyAreas,
    rebaPainAreas.wrist
  );
  const hasGreaterThanOneReba = ScoreService.parseWristScore(results) > 1;
  const hasWristPain = PainService.hasSomePainAreas(
    results,
    rebaPainAreas.wrist
  );

  return (
    (grippingOrVibration || movesWristRepeatedly) &&
    hasGreaterThanOneReba &&
    hasWristPain
  );
}

function showShoulderWarmUpCard(results) {
  const relevantRiskyTask = hasSomeAnswers(
    results,
    assessmentKeys.jobNature,
    assessmentKeys.jobActivities,
    [
      answers.jobNature.jobActivities.heavyLifting,
      answers.jobNature.jobActivities.pushOrPull,
      answers.jobNature.jobActivities.carrying,
      answers.jobNature.jobActivities.intensePhysicalEffort,
      answers.jobNature.jobActivities.overheadWork
    ]
  );
  const movesShoulderRepeatedly = PainService.hasPainAreaFromValues(
    results.jobNature.repetitiveMovementBodyAreas,
    rebaPainAreas.upperArm
  );
  const hasGreaterThanTwoReba = ScoreService.parseUpperArmScore(results) > 2;
  const hasShoulderPain = PainService.hasSomePainAreas(
    results,
    rebaPainAreas.upperArm
  );

  return (
    (relevantRiskyTask || movesShoulderRepeatedly) &&
    hasGreaterThanTwoReba &&
    hasShoulderPain
  );
}

function showBackWarmUpCard(results) {
  const relevantRiskyTask = hasSomeAnswers(
    results,
    assessmentKeys.jobNature,
    assessmentKeys.jobActivities,
    [
      answers.jobNature.jobActivities.heavyLifting,
      answers.jobNature.jobActivities.pushOrPull,
      answers.jobNature.jobActivities.carrying,
      answers.jobNature.jobActivities.intensePhysicalEffort
    ]
  );
  const movesBackRepeatedly = PainService.hasPainAreaFromValues(
    results.jobNature.repetitiveMovementBodyAreas,
    rebaPainAreas.torso
  );
  const hasGreaterThanTwoReba = ScoreService.parseTorsoScore(results) > 2;
  const hasBackPain = PainService.hasSomePainAreas(
    results,
    rebaPainAreas.torso
  );

  return (
    (relevantRiskyTask || movesBackRepeatedly) &&
    hasGreaterThanTwoReba &&
    hasBackPain
  );
}

function showNeckWarmUpCard(results) {
  const overheadWork = hasSomeAnswers(
    results,
    assessmentKeys.jobNature,
    assessmentKeys.jobActivities,
    [answers.jobNature.jobActivities.overheadWork]
  );
  const movesNeckRepeatedly = PainService.hasPainAreaFromValues(
    results.jobNature.repetitiveMovementBodyAreas,
    rebaPainAreas.neck
  );
  const hasGreaterThanOneReba = ScoreService.parseNeckScore(results) > 1;
  const hasNeckPain = PainService.hasSomePainAreas(results, rebaPainAreas.neck);

  return (
    (overheadWork || movesNeckRepeatedly) &&
    hasGreaterThanOneReba &&
    hasNeckPain
  );
}

function showLegWarmUpCard(results) {
  const relevantRiskyTask = hasSomeAnswers(
    results,
    assessmentKeys.jobNature,
    assessmentKeys.jobActivities,
    [
      answers.jobNature.jobActivities.heavyLifting,
      answers.jobNature.jobActivities.carrying,
      answers.jobNature.jobActivities.pushOrPull
    ]
  );
  const movesLegRepeatedly = PainService.hasPainAreaFromValues(
    results.jobNature.repetitiveMovementBodyAreas,
    rebaPainAreas.leg
  );
  const hasGreaterThanTwoReba = ScoreService.parseLegScore(results) > 2;
  const hasLegPain = PainService.hasSomePainAreas(results, rebaPainAreas.leg);

  return (
    (relevantRiskyTask || movesLegRepeatedly) &&
    hasGreaterThanTwoReba &&
    hasLegPain
  );
}

function getAllRecommendations() {
  return [
    {
      id: "physical_labour_wrist_warm_up_exercise",
      showAnswer: results => showWristWarmUpCard(results),
      json: Cards.wristWarmUpExercise,
      linkType: () => LinkTypes.REGULAR
    },
    {
      id: "physical_labour_shoulder_warm_up_exercise",
      showAnswer: results => showShoulderWarmUpCard(results),
      json: Cards.shoulderWarmUpExercise,
      linkType: () => LinkTypes.REGULAR
    },
    {
      id: "physical_labour_back_warm_up_exercise",
      showAnswer: results => showBackWarmUpCard(results),
      json: Cards.backWarmUpExercise,
      linkType: () => LinkTypes.REGULAR
    },
    {
      id: "physical_labour_neck_warm_up_exercise",
      showAnswer: results => showNeckWarmUpCard(results),
      json: Cards.neckWarmUpExercise,
      linkType: () => LinkTypes.REGULAR
    },
    {
      id: "physical_labour_leg_warm_up_exercise",
      showAnswer: results => showLegWarmUpCard(results),
      json: Cards.legWarmUpExercise,
      linkType: () => LinkTypes.REGULAR
    }
  ];
}
