export const setupRiskFactors = [
    "noKeyboard",
    "noMouse",
    "noScreen",
    "screenTooLow",
    "screenTooHigh",
    "screenTooClose",
    "screenTooFar",
    "tableTooLow",
    "tableTooHigh",
    "noAdjustableChair",
    "noBackSupport",
    "noArmRests",
    "noChair",
    "coldEnvironment",
    "noLaptopStand",
    "noStandingDesk",
    "feetUnsupported",
    "legsNotParallel",
    "noHeadset",
    "itemsNotEasyToReach",
    "noWristSpace",
    "armsNotRelaxed",
    "noDesk"
  ],
  habitsRiskFactors = [
    "noBreaks",
    "noActivityDuringBreaks",
    "lunchAtDesk",
    "longHours",
    "standingDeskHours"
  ],
  painLikelihoodRiskFactors = {
    neck: {
      setup: [
        "noKeyboard",
        "noMouse",
        "noScreen",
        "screenTooLow",
        "screenTooHigh",
        "screenTooClose",
        "screenTooFar",
        "tableTooLow",
        "tableTooHigh",
        "noAdjustableChair",
        "noBackSupport",
        "noArmRests",
        "noChair",
        "coldEnvironment",
        "noLaptopStand",
        "noHeadset",
        "itemsNotEasyToReach",
        "noWristSpace",
        "armsNotRelaxed",
        "noDesk"
      ],
      habits: ["noBreaks", "noActivityDuringBreaks", "lunchAtDesk", "longHours"]
    },
    shoulder: {
      setup: [
        "noKeyboard",
        "noMouse",
        "noScreen",
        "screenTooLow",
        "screenTooHigh",
        "screenTooClose",
        "screenTooFar",
        "tableTooLow",
        "tableTooHigh",
        "noAdjustableChair",
        "noBackSupport",
        "noArmRests",
        "noChair",
        "coldEnvironment",
        "noLaptopStand",
        "noHeadset",
        "itemsNotEasyToReach",
        "noWristSpace",
        "armsNotRelaxed",
        "noDesk"
      ],
      habits: ["noBreaks", "noActivityDuringBreaks", "lunchAtDesk", "longHours"]
    },
    elbow: {
      setup: [
        "noKeyboard",
        "noMouse",
        "tableTooLow",
        "tableTooHigh",
        "noAdjustableChair",
        "noArmRests",
        "armsNotRelaxed",
        "noDesk"
      ],
      habits: ["noBreaks", "noActivityDuringBreaks", "lunchAtDesk", "longHours"]
    },
    wrist: {
      setup: [
        "noKeyboard",
        "noMouse",
        "tableTooLow",
        "tableTooHigh",
        "coldEnvironment",
        "noHeadset",
        "noWristSpace",
        "armsNotRelaxed",
        "noDesk"
      ],
      habits: ["noBreaks", "noActivityDuringBreaks", "lunchAtDesk", "longHours"]
    },
    midback: {
      setup: [
        "noKeyboard",
        "noMouse",
        "noScreen",
        "screenTooLow",
        "screenTooFar",
        "tableTooLow",
        "noBackSupport",
        "noArmRests",
        "noChair",
        "coldEnvironment",
        "noLaptopStand",
        "noDesk"
      ],
      habits: ["noBreaks", "noActivityDuringBreaks", "lunchAtDesk", "longHours"]
    },
    lowerback: {
      setup: [
        "noKeyboard",
        "noMouse",
        "screenTooLow",
        "screenTooFar",
        "tableTooLow",
        "noAdjustableChair",
        "noBackSupport",
        "noChair",
        "coldEnvironment",
        "noLaptopStand",
        "noStandingDesk",
        "legsNotParallel",
        "itemsNotEasyToReach",
        "noDesk"
      ],
      habits: [
        "noBreaks",
        "noActivityDuringBreaks",
        "lunchAtDesk",
        "longHours",
        "standingDeskHours"
      ]
    },
    gluteals: {
      setup: [
        "tableTooLow",
        "noAdjustableChair",
        "noBackSupport",
        "coldEnvironment",
        "noLaptopStand",
        "noStandingDesk",
        "noDesk"
      ],
      habits: [
        "noBreaks",
        "noActivityDuringBreaks",
        "lunchAtDesk",
        "longHours",
        "standingDeskHours"
      ]
    },
    hip: {
      setup: ["tableTooLow", "coldEnvironment", "noStandingDesk"],
      habits: [
        "noBreaks",
        "noActivityDuringBreaks",
        "lunchAtDesk",
        "longHours",
        "standingDeskHours"
      ]
    },
    hamstring: {
      setup: [
        "noAdjustableChair",
        "noChair",
        "feetUnsupported",
        "legsNotParallel"
      ],
      habits: [
        "noBreaks",
        "noActivityDuringBreaks",
        "lunchAtDesk",
        "longHours",
        "standingDeskHours"
      ]
    },
    knee: {
      setup: [
        "noAdjustableChair",
        "noChair",
        "coldEnvironment",
        "noStandingDesk",
        "feetUnsupported",
        "legsNotParallel"
      ],
      habits: [
        "noBreaks",
        "noActivityDuringBreaks",
        "lunchAtDesk",
        "longHours",
        "standingDeskHours"
      ]
    },
    calf: {
      setup: ["feetUnsupported", "legsNotParallel"],
      habits: [
        "noBreaks",
        "noActivityDuringBreaks",
        "lunchAtDesk",
        "longHours",
        "standingDeskHours"
      ]
    },
    ankle: {
      setup: [
        "noAdjustableChair",
        "noStandingDesk",
        "feetUnsupported",
        "noBreaks"
      ],
      habits: [
        "noActivityDuringBreaks",
        "lunchAtDesk",
        "longHours",
        "standingDeskHours"
      ]
    },
    chest: {
      setup: [
        "noKeyboard",
        "noMouse",
        "screenTooFar",
        "tableTooLow",
        "noAdjustableChair",
        "coldEnvironment"
      ],
      habits: ["noBreaks", "noActivityDuringBreaks", "lunchAtDesk", "longHours"]
    },
    abs: {
      setup: ["tableTooLow", "noStandingDesk"],
      habits: ["noBreaks", "noActivityDuringBreaks", "lunchAtDesk", "longHours"]
    }
  },
  setupScoreRatings = { low: 3, medium: 10, high: 20 },
  habitsScoreRatings = { low: 2, medium: 5, high: 5 },
  maxLikelihoodRating = 21,
  painLikelihoodRatings = {
    neck: { low: 0, medium: 2, high: 6, max: 21 },
    shoulder: { low: 0, medium: 2, high: 6, max: 21 },
    elbow: { low: 0, medium: 4, high: 8, max: 12 },
    wrist: { low: 0, medium: 3, high: 6, max: 13 },
    midback: { low: 0, medium: 3, high: 7, max: 16 },
    lowerback: { low: 0, medium: 3, high: 7, max: 20 },
    gluteals: { low: 0, medium: 3, high: 7, max: 12 },
    hip: { low: 0, medium: 4, high: 7, max: 8 },
    hamstring: { low: 0, medium: 4, high: 7, max: 9 },
    knee: { low: 0, medium: 4, high: 9, max: 11 },
    calf: { low: 0, medium: 4, high: 7, max: 7 },
    ankle: { low: 0, medium: 3, high: 8, max: 8 },
    chest: { low: 0, medium: 5, high: 10, max: 10 },
    abs: { low: 0, medium: 4, high: 6, max: 6 }
  },
  defaultPriorityPainArea = "lowerback";
