<template>
  <div
    v-if="userId"
    class="user-profile-container d-flex flex-column"
    :class="`is-${layout}`"
  >
    <v-dialog
      v-if="userAssessmentReportVisible && canViewReport"
      v-model="userAssessmentReportVisible"
      fullscreen
      transition="dialog-bottom-transition"
      scrollable
    >
      <PatientHistoryDialog
        v-model="userAssessmentReportVisible"
        :assessment="currentViewedAssessment"
        :results="currentAssessmentResults"
        :health-conditions="healthConditions"
        :downloadOnMount="isDownloadingReport"
        @onClose="closeUserAssessmentReport"
      />
    </v-dialog>
    <div class="sticky-header" :class="`is-${layout}`">
      <user-profile-header :layout="layout" />
      <vfl-divider class="mt-4" />
    </div>
    <div
      class="scrolling-content d-flex flex-column pt-4"
      ref="scrollingContent"
      :class="`is-${layout}`"
    >
      <user-profile-assessments :layout="layout" />

      <vfl-divider v-if="enableDemoFeaturesInMskPassport" />
      <user-profile-equipment v-if="enableDemoFeaturesInMskPassport" />

      <vfl-divider v-if="enableDemoFeaturesInMskPassport" />
      <user-profile-adjustments v-if="enableDemoFeaturesInMskPassport" />

      <vfl-divider v-if="canViewNotes" />
      <user-profile-notes v-if="canViewNotes" />

      <vfl-divider v-if="canViewRisks" />
      <user-profile-risks v-if="canViewRisks" />

      <vfl-divider />
      <user-profile-history />
    </div>
  </div>
</template>

<script>
import UserProfileNotes from "@/components/user-profile/UserProfileNotes.vue";
import UserProfileHeader from "@/components/user-profile/UserProfileHeader.vue";
import UserProfileHistory from "@/components/user-profile/UserProfileHistory.vue";
import UserProfileAssessments from "@/components/user-profile/UserProfileAssessments.vue";
import VflDivider from "@/components/vitrue-foundation-library/VflDivider.vue";
import { mapActions, mapGetters } from "vuex";
import UserProfileOfficeRisks from "@/components/user-profile/UserProfileOfficeRisks.vue";
import UserProfileHomeRisks from "@/components/user-profile/UserProfileHomeRisks.vue";
import UserProfileRisks from "@/components/user-profile/UserProfileRisks.vue";
import UserProfileEquipment from "@/components/user-profile/UserProfileEquipment.vue";
import UserProfileAdjustments from "@/components/user-profile/UserProfileAdjustments.vue";
import PatientHistoryDialog from "@/components/common/assessment-history/PatientHistoryDialog.vue";

export default {
  name: "UserProfile",
  components: {
    UserProfileAdjustments,
    UserProfileEquipment,
    UserProfileRisks,
    UserProfileOfficeRisks,
    UserProfileHomeRisks,
    VflDivider,
    UserProfileAssessments,
    UserProfileHistory,
    UserProfileHeader,
    UserProfileNotes,
    PatientHistoryDialog
  },
  props: {
    userId: {
      type: String
    },
    layout: {
      type: String,
      default: "page",
      validator: value => ["sidepanel", "page"].includes(value)
    }
  },
  computed: {
    ...mapGetters(["anonymousResults", "enableDemoFeaturesInMskPassport"]),
    ...mapGetters("userProfile", [
      "currentViewedAssessment",
      "currentAssessmentResults",
      "healthConditions",
      "showUserAssessmentReport",
      "isDownloadingReport"
    ]),
    userAssessmentReportVisible: {
      get() {
        return this.showUserAssessmentReport;
      },
      set(value) {
        if (!value) {
          this.closeUserAssessmentReport();
        }
      }
    },
    canViewRisks() {
      return !this.anonymousResults;
    },
    canViewNotes() {
      return !this.anonymousResults;
    },
    canViewReport() {
      return !this.anonymousResults;
    }
  },
  watch: {
    userId: {
      handler: function (newUserId, oldUserId) {
        if (newUserId && (!oldUserId || newUserId !== oldUserId)) {
          this.handleUser();
          this.$nextTick(() => {
            this.scrollToTop();
          });
        } else if (!newUserId) {
          this.reset();
        }
      },
      immediate: true,
      deep: true
    }
  },
  beforeDestroy() {
    this.reset();
  },
  mounted() {
    if (this.layout === "page") {
      this.setPageTitle(this.$t("pageTitle.mskPassport"));
      this.handleUser();
    }
  },
  methods: {
    ...mapActions("userProfile", [
      "setUserId",
      "getUserData",
      "reset",
      "closeUserAssessmentReport"
    ]),
    ...mapActions("layout", ["setPageTitle"]),
    handleUser() {
      if (!this.userId) return;
      this.setUserId(this.userId);
      this.getUserData(this.userId);
    },
    scrollToTop() {
      if (this.$refs.scrollingContent) {
        this.$refs.scrollingContent.scrollTop = 0;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.user-profile-container {
  height: 100%;
  position: relative;

  &.is-page {
    // top nav height = 64px
    max-width: 800px;
    margin: 0 auto;
    padding: 0 2rem 2rem !important;
  }
}

.sticky-header {
  background: white;
  position: sticky;
  top: 0;
  z-index: 1;

  &.is-page {
    padding-top: 2rem;
    top: 64px; // top nav height
  }
}

.scrolling-content {
  gap: 1rem;
  flex: 1 0 auto;

  // Align the scrollbar with the right edge and add space between content and scrollbar
  margin-right: -1rem;
  padding-right: 1rem;

  overflow-y: auto;
  scrollbar-gutter: stable;

  &.is-sidepanel {
    height: 100%;
    flex: auto;
  }
}
</style>
