<template>
  <div style="position:relative;" :style="containerStyle">
    <v-row justify="center">
      <div class="fulfilling-bouncing-circle-spinner" :style="spinnerStyle">
        <div class="circle" :style="circleStyle"></div>
        <div class="orbit" :style="orbitStyle"></div>
      </div>
    </v-row>
    <div class="text-h5 primary--text title text-center">{{ text }}</div>
  </div>
</template>

<script>
export default {
  name: "LoadingSymbol",
  props: {
    animationDuration: {
      type: Number,
      default: 4000
    },
    size: {
      type: Number,
      default: 60
    },
    color: {
      type: String,
      default: "#fff"
    },
    text: String
  },
  computed: {
    containerStyle() {
      return {
        height: `${this.text ? this.size * 1.5 : this.size}px`
      };
    },
    spinnerStyle() {
      return {
        height: `${this.size}px`,
        width: `${this.size}px`,
        animationDuration: `${this.animationDuration}ms`
      };
    },
    orbitStyle() {
      return {
        height: `${this.size}px`,
        width: `${this.size}px`,
        borderColor: this.color,
        borderWidth: `${this.size * 0.03}px`,
        animationDuration: `${this.animationDuration}ms`
      };
    },
    circleStyle() {
      return {
        height: `${this.size}px`,
        width: `${this.size}px`,
        borderColor: this.color,
        color: this.color,
        borderWidth: `${this.size * 0.1}px`,
        animationDuration: `${this.animationDuration}ms`
      };
    }
  }
};
</script>

<style scoped>
.title {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}

.fulfilling-bouncing-circle-spinner,
.fulfilling-bouncing-circle-spinner * {
  box-sizing: border-box;
}
.fulfilling-bouncing-circle-spinner {
  height: 60px;
  width: 60px;
  position: relative;
  animation: fulfilling-bouncing-circle-spinner-animation infinite 4000ms ease;
}
.fulfilling-bouncing-circle-spinner .orbit {
  height: 60px;
  width: 60px;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  border: calc(60px * 0.03) solid #ff1d5e;
  animation: fulfilling-bouncing-circle-spinner-orbit-animation infinite 4000ms
    ease;
}
.fulfilling-bouncing-circle-spinner .circle {
  height: 60px;
  width: 60px;
  color: #ff1d5e;
  display: block;
  border-radius: 50%;
  position: relative;
  border: calc(60px * 0.1) solid #ff1d5e;
  animation: fulfilling-bouncing-circle-spinner-circle-animation infinite 4000ms
    ease;
  transform: rotate(0deg) scale(1);
}
@keyframes fulfilling-bouncing-circle-spinner-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes fulfilling-bouncing-circle-spinner-orbit-animation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1);
  }
  62.5% {
    transform: scale(0.8);
  }
  75% {
    transform: scale(1);
  }
  87.5% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes fulfilling-bouncing-circle-spinner-circle-animation {
  0% {
    transform: scale(1);
    border-color: transparent;
    border-top-color: inherit;
  }
  16.7% {
    border-color: transparent;
    border-top-color: initial;
    border-right-color: initial;
  }
  33.4% {
    border-color: transparent;
    border-top-color: inherit;
    border-right-color: inherit;
    border-bottom-color: inherit;
  }
  50% {
    border-color: inherit;
    transform: scale(1);
  }
  62.5% {
    border-color: inherit;
    transform: scale(1.4);
  }
  75% {
    border-color: inherit;
    transform: scale(1);
    opacity: 1;
  }
  87.5% {
    border-color: inherit;
    transform: scale(1.4);
  }
  100% {
    border-color: transparent;
    border-top-color: inherit;
    transform: scale(1);
  }
}
</style>
