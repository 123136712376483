<template>
  <div v-show="show">
    <v-col cols="10" class="my-3">
      <v-row :justify="$vuetify.breakpoint.smAndDown ? 'center' : 'start'">
        <v-col
          v-for="option in options"
          :key="option.value"
          class="pt-0"
          cols="12"
          sm="5"
          md="4"
          :class="$vuetify.breakpoint.smAndDown ? 'ma-3' : ''"
        >
          <v-hover v-slot="{ hover }">
            <v-card
              @click="$emit('input', option.value)"
              :elevation="hover || option.value === value ? 10 : 2"
              class="pa-3"
              :class="option.value === value ? 'zoom' : ''"
              rounded="lg"
              height="100%"
            >
              <v-overlay
                v-if="option.value === value"
                absolute
                color="primary"
                :opacity="0.2"
                class="rounded-lg"
              />
              <v-img
                :src="`/images/QuestionIcons/${option.image}`"
                height="75px"
                contain
              />
              <p class="text-body-2 text-center mb-0 mt-2">
                {{ $t(option.label) }}
              </p>
            </v-card>
          </v-hover>
        </v-col>
        <v-text-field v-model="value" :rules="rules" v-show="false" />
        <v-scroll-y-transition>
          <p v-if="showErrorMessage" class="error--text ml-3">
            {{ errorMessage }}
          </p>
        </v-scroll-y-transition>
      </v-row>
    </v-col>
  </div>
</template>

<script>
import { EventBus } from "@/services/events/event-bus";

export default {
  name: "CardSelectQuestion",
  props: {
    options: Array,
    value: Number,
    totalQuestionsOnPage: Number,
    show: {
      default: true,
      type: Boolean
    },
    optional: Boolean
  },
  mounted() {
    EventBus.$on("validateCustomFormComponents", this.validateQuestion);
  },
  data() {
    return {
      errorMessage: this.$t("form.radioButton.mustAnswer"),
      showErrorMessage: false
    };
  },
  watch: {
    value() {
      this.validateQuestion();
    }
  },
  beforeDestroy() {
    EventBus.$off("validateCustomFormComponents");
  },
  methods: {
    validateQuestion() {
      this.showErrorMessage = this.value === null || this.value === undefined;
    }
  },
  computed: {
    rules() {
      return this.optional ? [] : [v => !!v || this.errorMessage];
    }
  }
};
</script>

<style scoped>
.v-card--link:before {
  background: none;
}
.zoom {
  transition: all 0.2s ease-in-out;
  transform: scale(1.1);
}
</style>
