<template>
  <header class="messaging-header py-2 px-4">
    <h3 class="text-overline text-body-dark">Messaging</h3>
  </header>
</template>

<script>
export default {
  name: "MessagingHeader"
};
</script>

<style scoped lang="scss">
.messaging-header {
  border-bottom: 1px solid var(--vfl-border-color);
}
</style>
