import store from "@/store";
import { Actions } from "@/services/authorization/url-redirect/redirect-constants.js";
import { routes } from "@/router/route-constants.js";
import sentry from "@/sentry";

export function handleWellnessRedirect() {
  let redirectObject;
  const action = store.getters.redirectAction?.redirectAction;

  if (action === Actions.WELLNESS.ALREADY_COMPLETED_DIALOG) {
    redirectObject = {
      name: routes.WELLNESS,
      params: {
        alreadyCompletedDialog: true
      }
    };
  } else if (action === Actions.WELLNESS.RECOMMENDATION_SECTION || action === Actions.WELLNESS.GAME_SECTION) {
    redirectObject = {
      name: routes.WELLNESS,
      params: {
        goToSection: action
      }
    };
  } else if (action && action.includes(Actions.WELLNESS.SHOW_EXERCISE_VIDEO)) {
    const videoType = action.includes(":") ? action.split(":")[1] : null;

    if (!videoType) { 
      sentry.captureException("No video type found in wellness redirect");
    }

    store.commit("setPainCoachAutoPlay", videoType);
    redirectObject = {
      name: routes.WELLNESS
    };
  } else {
    redirectObject = {
      name: routes.WELLNESS
    };
  }
  store.commit("setRedirectAction", null);
  return redirectObject;
}
