export const HealthConditionsCategories = {
  CHRONIC_SYSTEMIC_ILLNESS: "chronicSystemicIllness",
  HEARING: "hearing",
  MOBILITY: "mobility",
  MSK: "msk",
  NEURODIVERSITY: "neurodiversity",
  VISION: "vision",
  OTHER: "other"
};

export const HealthConditionsPriorityMap = {
  [HealthConditionsCategories.CHRONIC_SYSTEMIC_ILLNESS]: 3,
  [HealthConditionsCategories.HEARING]: 5,
  [HealthConditionsCategories.MOBILITY]: 2,
  [HealthConditionsCategories.MSK]: 1,
  [HealthConditionsCategories.NEURODIVERSITY]: 6,
  [HealthConditionsCategories.VISION]: 4
};

export const UNKNOWN_MSK_CONDITIONS = {
  DONT_KNOW: "dontKnow",
  OTHER: "other",
  UNDISCLOSED: "undisclosed"
};

export function isUnknownCondition(condition) {
  return Object.values(UNKNOWN_MSK_CONDITIONS).some(unknownKey =>
    condition.endsWith(unknownKey)
  );
}

export function getConditionNameFromLabel(label) {
  if (label === UNKNOWN_MSK_CONDITIONS.UNDISCLOSED) return label;

  return label.split(".").pop();
}
