<template>
  <v-card
    rounded="lg"
    class="px-5 py-3 fill-height d-flex flex-column justify-center report-information-card"
  >
    <h4
      class="text-h6 vitrueDarkGrey--text font-weight-medium text-center mb-4"
    >
      {{ $t("assessment.report.summary.score.title") }}
    </h4>
    <p class="text-body-2 vitrueDarkGrey--text text-center">
      <i18n :path="descriptionLocale">
        <template v-slot:score>
          <span
            class="font-weight-bold"
            :class="`${scoreColour?.textColor}--text`"
            >{{ score }}%</span
          >
        </template>
        <template v-slot:rating>
          <span class="font-weight-bold">{{ rating }}</span>
        </template>
        <template v-slot:painArea>
          <span class="font-weight-bold">{{ painArea }}</span>
        </template>
      </i18n>
    </p>
    <div class="mt-2">
      <score-slider
        :score="score"
        :sections="sections"
        :scoreColour="scoreColour"
      />
    </div>
  </v-card>
</template>

<script>
import ScoreSlider from "@/components/common/assessments/ScoreSlider.vue";

export default {
  name: "OverallScore",
  components: {
    ScoreSlider
  },
  props: {
    score: Number,
    scoreColour: Object,
    rating: String,
    sections: Array,
    descriptionLocale: String,
    painArea: String
  }
};
</script>
