<template>
  <div class="ai-feature-introduction-roles">
    <h1 class="text-h5 mb-3">{{ heading }}</h1>
    <p class="text-body-2 mb-3">{{ subheading }}</p>

    <v-radio-group v-model="selectedRole" hide-details @change="emitUpdate">
      <v-radio
        v-for="option in roleOptions"
        :key="option.value"
        :label="option.label"
        :value="option.value"
        color="var(--v-vflPrimary-base)"
      ></v-radio>

      <ai-feature-introduction-form-divider />

      <v-radio
        :label="$t('other.other')"
        value="other"
        color="var(--v-vflPrimary-base)"
      ></v-radio>
    </v-radio-group>

    <v-text-field
      v-if="selectedRole === 'other'"
      v-model="otherRole"
      :label="$t('aiFeatureIntroductionDialog.form.role.otherLabel')"
      outlined
      dense
      maxlength="255"
      class="mt-4"
      hide-details
      color="var(--v-vflPrimary-base)"
      @input="emitUpdate"
      ref="otherRoleInput"
    ></v-text-field>
  </div>
</template>

<script>
import AiFeatureIntroductionFormDivider from "./AiFeatureIntroductionFormDivider.vue";

export default {
  name: "AiFeatureIntroductionRoles",
  components: {
    AiFeatureIntroductionFormDivider
  },
  props: {
    heading: {
      type: String,
      required: true
    },
    subheading: {
      type: String,
      required: true
    },
    modelValue: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      selectedRole: this.modelValue?.role || "",
      otherRole: this.modelValue?.otherRole || "",
      roleOptions: [
        {
          value: "human_resources",
          label: this.$t("productRoles.humanResources")
        },
        {
          value: "health_and_safety",
          label: this.$t("productRoles.healthAndSafety")
        },
        { value: "wellbeing", label: this.$t("productRoles.wellbeing") },
        {
          value: "operations_management",
          label: this.$t("productRoles.operationsManagement")
        },
        {
          value: "office_management",
          label: this.$t("productRoles.officeManagement")
        },
        {
          value: "reward_and_benefits",
          label: this.$t("productRoles.rewardAndBenefits")
        },
        {
          value: "line_management",
          label: this.$t("productRoles.lineManagement")
        }
      ]
    };
  },

  watch: {
    selectedRole(newValue) {
      if (newValue === "other") {
        this.$nextTick(() => {
          this.$refs.otherRoleInput.focus();
        });
      }
    },
    otherRole() {
      this.emitUpdate();
    }
  },
  methods: {
    emitUpdate() {
      this.$emit("update:modelValue", {
        selectedRole: this.selectedRole,
        otherRole: this.selectedRole === "other" ? this.otherRole : ""
      });
    }
  }
};
</script>
