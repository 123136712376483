<template>
  <div
    v-if="needsAttention"
    class="user-profile-needs-attention-container d-flex align-center"
  >
    <p class="mb-0 text-body-2">{{ $t("userProfile.needsAttention.text") }}</p>
    <button @click="resolve" class="vfl-button-link text-small ml-auto">
      {{ $t("userProfile.needsAttention.resolve") }}
    </button>
    <v-tooltip bottom color="transparent" maxWidth="250" style="z-index: 100">
      <template v-slot:activator="{ on, attrs }">
        <v-icon v-on="on" v-bind="attrs" color="black" size="20" class="ml-3"
          >mdi-information-outline</v-icon
        >
      </template>
      <vitrue-tooltip :message="tooltipMessage" />
    </v-tooltip>
  </div>
</template>

<script>
import { updateUserNeedsAttentionState } from "@/api/userApi.js";
import { mapActions, mapGetters } from "vuex";
import { sendEvent } from "@/services/analytics/analytics.js";
import VitrueTooltip from "@/components/common/VitrueTooltip.vue";

export default {
  name: "UserProfileNeedsAttention",
  components: { VitrueTooltip },
  computed: {
    ...mapGetters("userProfile", ["id", "needsAttention", "name"]),
    ...mapGetters({ adminId: "userId" }),
    tooltipMessage() {
      const tooltipKey = this.name
        ? "userProfile.needsAttention.tooltip.withName"
        : "userProfile.needsAttention.tooltip.default";
      return this.name
        ? this.$t(tooltipKey, { name: this.name })
        : this.$t(tooltipKey);
    }
  },
  methods: {
    ...mapActions("userProfile", ["showSnackbar", "setNeedsAttention"]),
    async resolve() {
      await this.updateNeedsAttentionStatus(false, {
        message: this.$t(
          "userProfile.needsAttention.snackbar.markedAsResolved"
        ),
        type: "success",
        undoAction: this.unresolve
      });
    },
    async unresolve() {
      await this.updateNeedsAttentionStatus(true, {
        message: this.$t("userProfile.snackbar.actionUndone"),
        type: "success"
      });
    },
    async updateNeedsAttentionStatus(status, successMessage) {
      try {
        const resolvedState = status ? "Unresolve" : "Resolve";
        await updateUserNeedsAttentionState(this.id, resolvedState);
        await this.$store.dispatch("adminDashboard/refreshWhoNeedsAttention");
        await this.setNeedsAttention(status);
        sendEvent(`User might need attention - mark ${resolvedState}`, {
          end_user: this.id,
          admin: this.adminId,
          status
        });
        await this.showSnackbar(successMessage);
      } catch (error) {
        this.$logger.captureException(error);

        await this.showSnackbar({
          message: this.$t("userProfile.needsAttention.snackbar.error"),
          type: "error"
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.user-profile-needs-attention-container {
  background-color: #fbf8e6;
  border-radius: 8px;
  padding: 0.5rem 0.75rem;

  p {
    color: var(--v-vflGreyDark-base);
    line-height: 1;
  }

  button {
    text-decoration: none;
  }
}
</style>
