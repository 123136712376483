<template>
  <div class="vfl-table-tag text-body-2">
    <slot>
      {{ label }}
    </slot>
  </div>
</template>

<script>
export default {
  name: "VflTableTag",
  props: {
    label: {
      type: String,
      default: ""
    }
  }
};
</script>

<style scoped>
.vfl-table-tag {
  padding: 0.3125rem 0.75rem;
  border: 1px solid #f1f0ed;
  border-radius: 6px;
  display: inline-flex;
  align-items: center;
  cursor: default;
  line-height: 1;
  white-space: nowrap;
}
</style>
