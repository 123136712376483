<template>
  <div class="user-list-container">
    <ul class="pl-0 pb-4 mr-n4 ml-n4 pt-2">
      <li
        v-for="person in peopleList"
        :key="person.id"
        @click="selectPerson(person.id)"
        class="d-flex py-4 pl-4 flex-column"
      >
        <div class="d-flex align-center">
          <avatar-icon
            :text="person.name"
            :fallback-text="person.email"
            :size="32"
          />
          <div class="pl-4 user-name-and-email">
            <p class="ma-0 text-body-1 user-name">
              {{ person.name || person.email }}
            </p>
            <p v-if="person.name" class="mb-0 text-body-2">
              {{ person.email }}
            </p>
          </div>
        </div>
        <div v-if="person.level" class="mt-2">
          <vfl-chip
            :label="getPainText({ area: person.area, level: person.level })"
            v-bind="getChipStyles(person.level)"
          />
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { sendEvent } from "@/services/analytics/analytics.js";
import AvatarIcon from "@/components/common/AvatarIcon.vue";
import UserProfileAvatar from "@/components/user-profile/UserProfileAvatar.vue";
import VflChip from "@/components/vitrue-foundation-library/VflChip.vue";
import { PainColors, PainLevel } from "@/components/user-profile/constants.js";

export default {
  name: "UserList",
  components: { VflChip, UserProfileAvatar, AvatarIcon },
  computed: {
    ...mapGetters({ adminId: "userId" }),
    ...mapGetters("sidePanelStore", ["peopleList"])
  },
  methods: {
    ...mapActions("sidePanelStore", ["showSinglePerson"]),
    selectPerson(id) {
      this.showSinglePerson(id);

      sendEvent("MSK passport opened from list", {
        from: "User list",
        end_user: id,
        admin: this.adminId
      });
    },
    getPainLevel(level) {
      if (level >= PainLevel.SEVERE.min) return "SEVERE";
      if (level >= PainLevel.MODERATE.min) return "MODERATE";

      return "MILD";
    },
    getChipStyles(level) {
      const painLevel = this.getPainLevel(level);

      return {
        backgroundColor: PainColors[painLevel].background,
        borderColor: PainColors[painLevel].border
      };
    },
    getPainText({ area }) {
      return this.$t(`reportText.pain.painAreaNames.${area}.title`);
    }
  }
};
</script>

<style scoped lang="scss">
.user-list-container {
  border-top: 1px solid var(--v-vflNeutralLight2-base);
  height: 100%;
}

ul {
  height: 100%;
  overflow-y: auto;
}

li {
  cursor: pointer;
  position: relative;

  &:not(:first-child) {
    &:before {
      background-color: var(--v-vflNeutralLight2-base);
      content: "";
      height: 1px;
      left: 1rem;
      right: 1rem;
      top: 0;
      position: absolute;
      transition: opacity 0.125s ease-out;
    }
  }

  &:after {
    border-radius: 12px;
    bottom: 0;
    background-color: var(--v-vflNeutralLight2-base);
    content: "";
    left: 0.5rem;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    right: 0.5rem;
    top: 0;
    transition: opacity 0.125s ease-out;
    z-index: -1;
  }

  &:hover {
    &:before {
      opacity: 0;
    }

    &:after {
      opacity: 1;
    }

    + li {
      &:before {
        opacity: 0;
      }
    }
  }
}

.user-name-and-email {
  // sidepanel width - sidepanel horizontal padding (x2) - space between name and avatar - avatar width
  max-width: calc(420px - 2rem - 1rem - 32px);
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.user-name {
  line-height: 1;
  display: inline;
}

p {
  color: var(--v-vflTextDark-base);
}
</style>
