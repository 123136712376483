import i18n from "@/plugins/i18n.js";
import answers from "@/services/assessment/physical-labour/answers.json";
import sentry from "@/sentry";
import * as ScoringService from "@/services/assessment/physical-labour/reba-scoring-service.js";
import InjectionService from "./report-body-text-injection-service.js";
import { HealthImpact } from "@/constants/healthImpact.js";
import PainService from "@/services/assessment/pain/pain-assessment-service.js";
import { assessmentKeys } from "@/components/assessment/physical-labour/utils/constants.js";
const root = "physicalLabourAssessment.report.content";

export function getBodyAssessmentCards(results) {
  return GetReportCards(getAllBodyAssessmentCards, results);
}

function GetReportCards(getCards, results) {
  const cards = getCards(results);
  const visibleCards = cards.filter(c => c.show(results));
  visibleCards.forEach(c => {
    // Filter available text and take highest priority one
    const availableText = c.text.filter(t => t.show);
    availableText.sort((a, b) => a.priority - b.priority);
    const text = availableText.map(t => t.text(results))[0];

    if (!text) {
      sentry.captureMessage(`No text found for card ${c.id}`, "error");
    }
    c.text = text ? [text] : [];
    c.title = i18n.t(c.title);
  });
  return visibleCards.filter(c => c.text.length > 0);
}

function checkAnswers(results, answers, func) {
  try {
    return func(results, answers);
  } catch (e) {
    sentry.captureMessage(
      `Error validating answers for results ${results} with answers ${answers}`
    );
    sentry.captureException(e);
    return false;
  }
}

function resultsHaveSome(results, question, answers) {
  const func = (results, answers) => answers.some(a => results.includes(a));
  return checkAnswers(results.reba[question], answers, func);
}

function resultsHaveAll(results, question, answers) {
  const func = (results, answers) => answers.every(a => results.includes(a));
  return checkAnswers(results.reba[question], answers, func);
}

function hasAnswer(results, question, answer) {
  const func = (results, answer) => results === answer;
  return checkAnswers(results.reba[question], answer, func);
}

function resultsHaveOnlyOne(results, question, answers) {
  const func = (results, answers) =>
    answers.filter(a => results.includes(a)).length === 1;
  return checkAnswers(results.reba[question], answers, func);
}

function torsoBentOver(results) {
  const forwardModerate = answers.reba.torsoBentForwardPosition.moderately;
  const forwardHigh = answers.reba.torsoBentForwardPosition.significantly;
  const backwardModerate = answers.reba.torsoBentBackwardPosition.moderately;
  return (
    hasAnswer(
      results,
      assessmentKeys.torsoBentForwardPosition,
      forwardModerate
    ) ||
    hasAnswer(
      results,
      assessmentKeys.torsoBentBackwardPosition,
      backwardModerate
    ) ||
    hasAnswer(results, assessmentKeys.torsoBentForwardPosition, forwardHigh)
  );
}

function armsExtendedOrSlightlyRaised(results) {
  return (
    resultsHaveSome(results, assessmentKeys.upperArmPosition, [
      answers.reba.upperArmPosition.extended
    ]) ||
    hasAnswer(
      results,
      assessmentKeys.raisedUpperArmPosition,
      answers.reba.raisedUpperArmPosition.slightly
    )
  );
}

function armsStraightShouldersRaisedAndAbducted(results) {
  return resultsHaveAll(results, assessmentKeys.additionalUpperArmFactors, [
    answers.reba.additionalUpperArmFactors.atLeastOneShoulderRaised,
    answers.reba.additionalUpperArmFactors.armRaisedToSideOfBody
  ]);
}

function armsStraightExtendedOrSlightlyRaisedShouldersRaisedOrAbducted(
  results
) {
  return (
    !hasAnswer(
      results,
      assessmentKeys.raisedUpperArmPosition,
      answers.reba.raisedUpperArmPosition.extendedFarBeyondBody
    ) &&
    !hasAnswer(
      results,
      assessmentKeys.raisedUpperArmPosition,
      answers.reba.raisedUpperArmPosition.aboveShoulder
    ) &&
    resultsHaveOnlyOne(results, assessmentKeys.additionalUpperArmFactors, [
      answers.reba.additionalUpperArmFactors.atLeastOneShoulderRaised,
      answers.reba.additionalUpperArmFactors.armRaisedToSideOfBody
    ])
  );
}

function armsRaisedHighShouldersAbductedOrRaised(results) {
  return (
    hasAnswer(
      results,
      assessmentKeys.raisedUpperArmPosition,
      answers.reba.raisedUpperArmPosition.aboveShoulder
    ) &&
    resultsHaveSome(results, assessmentKeys.additionalUpperArmFactors, [
      answers.reba.additionalUpperArmFactors.atLeastOneShoulderRaised,
      answers.reba.additionalUpperArmFactors.armRaisedToSideOfBody
    ])
  );
}

function armsRaisedInFrontShouldersAbductedAndRaised(results) {
  return (
    hasAnswer(
      results,
      assessmentKeys.raisedUpperArmPosition,
      answers.reba.raisedUpperArmPosition.extendedFarBeyondBody
    ) &&
    resultsHaveAll(results, assessmentKeys.additionalUpperArmFactors, [
      answers.reba.additionalUpperArmFactors.atLeastOneShoulderRaised,
      answers.reba.additionalUpperArmFactors.armRaisedToSideOfBody
    ])
  );
}

function getAllBodyAssessmentCards(results) {
  const hasPain = PainService.hasPain(results);
  const neckScore = ScoringService.parseNeckScore(results);
  const legScore = ScoringService.parseLegScore(results);
  const torsoScore = ScoringService.parseTorsoScore(results);
  const upperArmScore = ScoringService.parseUpperArmScore(results);
  const lowerArmScore = ScoringService.parseLowerArmScore(results);
  const wristScore = ScoringService.parseWristScore(results);

  return [
    {
      id: "neckREBA",
      title: `${root}.reba.neck.title`,
      imageUrl: "physical-labour-assessment/questions/Neck-forward.svg",
      healthImpact: HealthImpact.NEUTRAL,
      show: () => neckScore > 1,
      text: [
        {
          priority: 3,
          show: resultsHaveSome(results, assessmentKeys.neckPosition, [
            answers.reba.neckPosition.bentForwards,
            answers.reba.neckPosition.bentBackwards
          ]),
          text: () =>
            i18n.t(
              `${root}.reba.neck.text.considerableRiskTilting`,
              InjectionService.getNeckTextToInject(results)
            )
        },
        {
          priority: 2,
          show: resultsHaveSome(results, assessmentKeys.neckTwistedOrSideBent, [
            answers.reba.neckTwistedOrSideBent.twisted,
            answers.reba.neckTwistedOrSideBent.sideBent
          ]),
          text: () =>
            i18n.t(
              `${root}.reba.neck.text.considerableRiskTwistSideBent`,
              InjectionService.getNeckTextToInject(results)
            )
        },
        {
          priority: 1,
          show:
            resultsHaveSome(results, assessmentKeys.neckPosition, [
              answers.reba.neckPosition.bentForwards,
              answers.reba.neckPosition.bentBackwards
            ]) &&
            resultsHaveSome(results, assessmentKeys.neckTwistedOrSideBent, [
              answers.reba.neckTwistedOrSideBent.twisted,
              answers.reba.neckTwistedOrSideBent.sideBent
            ]),
          text: () =>
            i18n.t(
              `${root}.reba.neck.text.significantRisk`,
              InjectionService.getNeckTextToInject(results)
            )
        }
      ]
    },
    {
      id: "torsoREBA",
      title: `${root}.reba.torso.title`,
      imageUrl: "physical-labour-assessment/questions/Trunk-beyond-forward.svg",
      healthImpact: HealthImpact.NEUTRAL,
      show: () => (hasPain && torsoScore > 1) || (!hasPain && torsoScore > 2),
      text: [
        {
          priority: 5,
          show:
            hasAnswer(
              results,
              assessmentKeys.torsoBentForwardPosition,
              answers.reba.torsoBentForwardPosition.slightly
            ) ||
            hasAnswer(
              results,
              assessmentKeys.torsoBentBackwardPosition,
              answers.reba.torsoBentBackwardPosition.slightly
            ),
          text: () => i18n.t(`${root}.reba.torso.text.lowRiskBending`)
        },
        {
          priority: 4,
          show: resultsHaveSome(
            results,
            assessmentKeys.torsoTwistedOrSideBent,
            [
              answers.reba.torsoTwistedOrSideBent.twisted,
              answers.reba.torsoTwistedOrSideBent.sideBent
            ]
          ),
          text: () =>
            i18n.t(
              `${root}.reba.torso.text.lowRiskTwistSideBent`,
              InjectionService.getTorsoTextToInject(results)
            )
        },
        {
          priority: 3,
          show: hasAnswer(
            results,
            assessmentKeys.torsoBentBackwardPosition,
            answers.reba.torsoBentBackwardPosition.moderately
          ),
          text: () =>
            i18n.t(`${root}.reba.torso.text.mediumRiskBendingBackward`)
        },
        {
          priority: 2,
          show:
            hasAnswer(
              results,
              assessmentKeys.torsoBentForwardPosition,
              answers.reba.torsoBentForwardPosition.moderately
            ) ||
            hasAnswer(
              results,
              assessmentKeys.torsoBentForwardPosition,
              answers.reba.torsoBentForwardPosition.significantly
            ),
          text: () => i18n.t(`${root}.reba.torso.text.mediumRiskBendingForward`)
        },
        {
          priority: 1,
          show:
            torsoBentOver(results) &&
            resultsHaveSome(results, assessmentKeys.torsoTwistedOrSideBent, [
              answers.reba.torsoTwistedOrSideBent.twisted,
              answers.reba.torsoTwistedOrSideBent.sideBent
            ]),
          text: () =>
            i18n.t(
              `${root}.reba.torso.text.highRisk`,
              InjectionService.getTorsoTextToInject(results)
            )
        }
      ]
    },
    {
      id: "legsREBA",
      title: `${root}.reba.legs.title`,
      imageUrl: "physical-labour-assessment/questions/knees-bent-slightly.svg",
      healthImpact: HealthImpact.NEUTRAL,
      show: () => (hasPain && legScore > 1) || (!hasPain && legScore > 2),
      text: [
        {
          priority: 5,
          show: resultsHaveSome(results, assessmentKeys.legsBent, [
            answers.reba.legsBent.slightly
          ]),
          text: () => i18n.t(`${root}.reba.legs.text.lowRiskLegsDown`)
        },
        {
          priority: 4,
          show: resultsHaveSome(results, assessmentKeys.legPosition, [
            answers.reba.legPosition.oneLegRaised
          ]),
          text: () => i18n.t(`${root}.reba.legs.text.lowRiskOneLegRaised`)
        },
        {
          priority: 3,
          show: resultsHaveSome(results, assessmentKeys.legsBent, [
            answers.reba.legsBent.significantly
          ]),
          text: () => i18n.t(`${root}.reba.legs.text.mediumRiskLegsDown`)
        },
        {
          priority: 2,
          show:
            resultsHaveSome(results, assessmentKeys.legPosition, [
              answers.reba.legPosition.oneLegRaised
            ]) &&
            resultsHaveSome(results, assessmentKeys.legsBent, [
              answers.reba.legsBent.slightly
            ]),
          text: () => i18n.t(`${root}.reba.legs.text.mediumRiskOneLegRaised`)
        },
        {
          priority: 1,
          show:
            resultsHaveSome(results, assessmentKeys.legPosition, [
              answers.reba.legPosition.oneLegRaised
            ]) &&
            resultsHaveSome(results, assessmentKeys.legsBent, [
              answers.reba.legsBent.significantly
            ]),
          text: () => i18n.t(`${root}.reba.legs.text.significantRisk`)
        }
      ]
    },
    {
      id: "upperArmREBA",
      title: `${root}.reba.arms.upper.title`,
      imageUrl: "physical-labour-assessment/questions/Arms-raised.svg",
      healthImpact: HealthImpact.NEUTRAL,
      show: () =>
        (hasPain && upperArmScore > 1) || (!hasPain && upperArmScore > 3),
      text: [
        {
          priority: 7,
          show:
            hasAnswer(
              results,
              assessmentKeys.raisedUpperArmPosition,
              answers.reba.raisedUpperArmPosition.slightly
            ) ||
            resultsHaveSome(results, assessmentKeys.upperArmPosition, [
              answers.reba.upperArmPosition.extended
            ]),
          text: () =>
            i18n.t(
              `${root}.reba.arms.upper.text.lowRiskArmsRaisedSlightlyOrExtended`,
              InjectionService.getArmTextToInject(results)
            )
        },
        {
          priority: 6,
          show:
            armsStraightShouldersRaisedAndAbducted(results) ||
            armsStraightExtendedOrSlightlyRaisedShouldersRaisedOrAbducted(
              results
            ),
          text: () =>
            i18n.t(
              `${root}.reba.arms.upper.text.lowRiskShoulderAbductedOrRaised`,
              InjectionService.getArmTextToInject(results)
            )
        },
        {
          priority: 5,
          show: hasAnswer(
            results,
            assessmentKeys.raisedUpperArmPosition,
            answers.reba.raisedUpperArmPosition.extendedFarBeyondBody
          ),
          text: () =>
            i18n.t(`${root}.reba.arms.upper.text.lowRiskArmsMediumRaised`)
        },
        {
          priority: 4,
          show:
            armsExtendedOrSlightlyRaised(results) &&
            resultsHaveAll(results, assessmentKeys.additionalUpperArmFactors, [
              answers.reba.additionalUpperArmFactors.atLeastOneShoulderRaised,
              answers.reba.additionalUpperArmFactors.armRaisedToSideOfBody
            ]),
          text: () =>
            i18n.t(
              `${root}.reba.arms.upper.text.moderateRiskShouldersRaisedAndAbducted`
            )
        },
        {
          priority: 3,
          show:
            hasAnswer(
              results,
              assessmentKeys.raisedUpperArmPosition,
              answers.reba.raisedUpperArmPosition.extendedFarBeyondBody
            ) &&
            resultsHaveOnlyOne(
              results,
              assessmentKeys.additionalUpperArmFactors,
              [
                answers.reba.additionalUpperArmFactors.atLeastOneShoulderRaised,
                answers.reba.additionalUpperArmFactors.armRaisedToSideOfBody
              ]
            ),
          text: () =>
            i18n.t(
              `${root}.reba.arms.upper.text.moderateRiskShouldersAbductedOrRaised`,
              InjectionService.getArmTextToInject(results)
            )
        },
        {
          priority: 2,
          show: hasAnswer(
            results,
            assessmentKeys.raisedUpperArmPosition,
            answers.reba.raisedUpperArmPosition.aboveShoulder
          ),
          text: () =>
            i18n.t(`${root}.reba.arms.upper.text.moderateRiskArmsRaisedHigh`)
        },
        {
          priority: 1,
          show:
            armsRaisedHighShouldersAbductedOrRaised(results) ||
            armsRaisedInFrontShouldersAbductedAndRaised(results),
          text: () =>
            i18n.t(
              `${root}.reba.arms.upper.text.highRisk`,
              InjectionService.getArmTextToInject(results)
            )
        }
      ]
    },
    {
      id: "lowerArmREBA",
      title: `${root}.reba.arms.lower.title`,
      imageUrl: "physical-labour-assessment/questions/lower-arms-very-bent.svg",
      healthImpact: HealthImpact.NEUTRAL,
      show: () => lowerArmScore > 1,
      text: [
        {
          priority: 2,
          show: resultsHaveSome(results, assessmentKeys.lowerArmPosition, [
            answers.reba.lowerArmPosition.slightlyBent
          ]),
          text: () => i18n.t(`${root}.reba.arms.lower.text.slightlyBent`)
        },
        {
          priority: 1,
          show: resultsHaveSome(results, assessmentKeys.lowerArmPosition, [
            answers.reba.lowerArmPosition.bentMoreThanARightAngle
          ]),
          text: () =>
            i18n.t(`${root}.reba.arms.lower.text.bentMoreThanARightAngle`)
        }
      ]
    },
    {
      id: "wristREBA",
      title: `${root}.reba.wrists.title`,
      imageUrl: "physical-labour-assessment/questions/wrist-neutral.svg",
      healthImpact: HealthImpact.NEUTRAL,
      show: () => wristScore > 1,
      text: [
        {
          priority: 5,
          show: resultsHaveSome(results, assessmentKeys.wristPosition, [
            answers.reba.wristPosition.bentDown,
            answers.reba.wristPosition.bentUp
          ]),
          text: () => i18n.t(`${root}.reba.wrists.text.wristBent`)
        },
        {
          priority: 4,
          show:
            PainService.hasPainArea(results, "elbow") &&
            resultsHaveSome(results, assessmentKeys.wristPosition, [
              answers.reba.wristPosition.bentDown
            ]),
          text: () => i18n.t(`${root}.reba.wrists.text.wristBentDownElbowPain`)
        },
        {
          priority: 3,
          show: resultsHaveSome(
            results,
            assessmentKeys.wristTwistedOrSideBent,
            [
              answers.reba.wristTwistedOrSideBent.twisted,
              answers.reba.wristTwistedOrSideBent.sideBent
            ]
          ),
          text: () =>
            i18n.t(
              `${root}.reba.wrists.text.wristBentAtMidlineOrTwisted`,
              InjectionService.getWristTextToInject(results)
            )
        },
        {
          priority: 2,
          show:
            resultsHaveSome(results, assessmentKeys.wristPosition, [
              answers.reba.wristPosition.bentDown,
              answers.reba.wristPosition.bentUp
            ]) &&
            resultsHaveSome(results, assessmentKeys.wristTwistedOrSideBent, [
              answers.reba.wristTwistedOrSideBent.twisted,
              answers.reba.wristTwistedOrSideBent.sideBent
            ]),
          text: () =>
            i18n.t(
              `${root}.reba.wrists.text.wristNotStraightBentAtMidlineOrTwisted`,
              InjectionService.getWristTextToInject(results)
            )
        },
        {
          priority: 1,
          show:
            PainService.hasPainArea(results, "elbow") &&
            resultsHaveSome(results, assessmentKeys.wristPosition, [
              answers.reba.wristPosition.bentDown
            ]) &&
            resultsHaveSome(results, assessmentKeys.wristTwistedOrSideBent, [
              answers.reba.wristTwistedOrSideBent.twisted,
              answers.reba.wristTwistedOrSideBent.sideBent
            ]),
          text: () =>
            i18n.t(
              `${root}.reba.wrists.text.wristBentDownBentAtMidlineOrTwistedElbowPain`
            )
        }
      ]
    }
  ];
}
