<template>
  <button @click="handleClick" :class="classList" :style="buttonStyle">
    <span class="button-content">
      <slot name="startDecorator"></slot>
      <slot></slot>
      <slot name="endDecorator"></slot>
    </span>
  </button>
</template>
<script>
export default {
  name: "VflButton",
  props: {
    variant: {
      type: String,
      validator: value => ["primary", "secondary", "ghost"].includes(value),
      default: "primary"
    },
    isLight: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      validator: value => ["small", "medium", "large"].includes(value),
      default: "medium"
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    width: {
      type: [Number, String],
      default: null
    },
    height: {
      type: [Number, String],
      default: null
    },
    isSquare: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    classList() {
      return [
        `is-${this.variant}`,
        `is-${this.size}`,
        { "is-light": this.isLight },
        { "is-loading": this.loading },
        { "is-disabled": this.disabled },
        { "is-square": this.isSquare }
      ];
    },
    buttonStyle() {
      const style = {};

      if (this.width !== null) {
        style.width =
          typeof this.width === "number" ? `${this.width}px` : this.width;
      }
      if (this.height !== null) {
        style.height =
          typeof this.height === "number" ? `${this.height}px` : this.height;
      }

      return style;
    }
  },
  methods: {
    handleClick(event) {
      if (!this.disabled) {
        this.$emit("click", event);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
button {
  background-color: white;
  border: 1px solid transparent;
  border-radius: 24px;
  color: var(--v-vflTextDark-base);
  font: inherit;
  font-weight: 700;
  padding: 0.5rem 1rem;
  transition: box-shadow 0.2s cubic-bezier(0.2, 0, 0, 1),
    transform 0.1s cubic-bezier(0.2, 0, 0, 1);

  &:active {
    transform: scale(0.98);
  }

  &:hover {
    filter: brightness(102%);
  }

  &.is-primary:not(.is-light) {
    background-color: var(--v-vflPrimary-base);
    color: white;

    &:hover {
      background-color: color-mix(in srgb, var(--v-vflPrimary-base), black 20%);
    }
  }

  &.is-ghost {
    &:hover {
      background-color: hsla(0, 0%, 0%, 0.025);
    }
  }

  &.is-secondary {
    &:hover {
      background-color: hsla(0, 0%, 0%, 0.025);
    }

    &:not(.is-light) {
      border-color: var(--vflPrimary-base);
    }
  }

  &.is-light {
    &.is-primary {
      background-color: var(--v-vflPrimaryLight-base);
      color: var(--v-vflPrimary-base);
    }

    &.is-secondary {
      border-color: var(--v-vflNeutralLight3-base);
    }
  }

  &.is-small {
    font-size: 0.875rem;
    line-height: 1.285715;
    padding: 0.125rem 0.75rem;

    .button-content {
      transform: translateY(1px);
    }
  }

  &.is-large {
    padding: 0.75rem 1rem;
  }

  &.is-square {
    border-radius: 6px !important;
  }

  &.is-disabled {
    opacity: 0.6;
    pointer-events: none;
  }

  &.is-loading {
    pointer-events: none;

    .button-content {
      opacity: 0;
    }
    position: relative;
  }

  &.is-loading::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 16px;
    height: 16px;
    margin-top: -8px;
    margin-left: -8px;
    border: 2px solid currentColor;
    border-top-color: transparent;
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

.button-content {
  align-items: center;
  display: inline-flex;
  gap: 0.25rem;
}
</style>
