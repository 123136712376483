<template>
  <v-dialog
    v-model="dialog"
    max-width="1120px"
    :fullscreen="$vuetify.breakpoint.smAndDown"
    transition="dialog-bottom-transition"
    persistent
  >
    <v-card
      :class="[
        { 'container-padding': !$vuetify.breakpoint.smAndDown },
        { 'container-padding-small': $vuetify.breakpoint.smAndDown }
      ]"
    >
      <div class="d-flex justify-space-between align-center mb-6">
        <img
          src="/images/powered-by-vida.svg"
          alt="Powered by Vitrue VIDA"
          style="width: 120px"
        />
        <LanguageSelection minimal />
      </div>
      <div class="content-container">
        <div>
          <h1
            class="mb-4"
            :class="[
              { 'text-h2': !$vuetify.breakpoint.smAndDown },
              { 'text-h4': $vuetify.breakpoint.smAndDown }
            ]"
          >
            {{ $t(`${$options.localePath}.title`) }}
          </h1>
          <p class="mb-4 black--text text-body-1">
            {{
              $t(
                `${$options.localePath}.subtitle.${
                  team_hoiha ? "hoiha" : "generic"
                }`
              )
            }}
          </p>

          <div
            class="ml-4"
            v-for="(checkbox, index) in checkBoxes"
            v-bind:key="index"
          >
            <v-checkbox
              v-model="checkbox.value"
              hide-details
              color="vflPrimary"
              class="d-flex justify-center"
            >
              <template v-slot:label>
                <p class="mb-0 text-body-1 text-body-dark">
                  {{ $t(checkbox.text) }}
                </p>
              </template>
            </v-checkbox>
          </div>

          <p class="mt-4 mb-8 text-body-2 black--text text-body-bold">
            {{ $t(`${$options.localePath}.footer`) }}
          </p>

          <div class="mb-3">
            <div
              :style="{
                width: $vuetify.breakpoint.smAndDown ? '100%' : '280px'
              }"
              class="d-flex mb-3"
            >
              <button
                class="vfl-button-primary flex-grow-1"
                :class="[{ 'is-disabled': !canContinue }]"
                @click="dialog = false"
              >
                {{ $t(`buttons.continue`) }}
              </button>
            </div>
          </div>
        </div>
        <div
          v-if="!$vuetify.breakpoint.smAndDown"
          class="d-flex justify-center side-image"
        >
          <img src="/images/illustrations/womanWalkingWithClipboard.svg" />
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import LanguageSelection from "@/components/common/LanguageSelection.vue";
import { mapGetters } from "vuex";
const localePath = "driverAssessment.preAssessmentChecklist";

export default {
  name: "DriverPreAssessmentChecklist",
  components: {
    LanguageSelection
  },
  localePath: localePath,
  data() {
    return {
      dialog: true,
      checkBoxes: [
        { value: false, text: `${localePath}.checkList.drivingLicense` },
        { value: false, text: `${localePath}.checkList.carInsurance` },
        { value: false, text: `${localePath}.checkList.alcohol` },
        { value: false, text: `${localePath}.checkList.mobilePhone` },
        { value: false, text: `${localePath}.checkList.reportIncidents` }
      ]
    };
  },
  computed: {
    ...mapGetters(["team_hoiha"]),
    canContinue() {
      return this.checkBoxes.every(checkBox => checkBox.value === true);
    }
  }
};
</script>

<style lang="scss" scoped>
.content-container {
  display: flex;
  padding: 0px 20px !important;
}

.side-image {
  min-width: 33%;
  user-select: none;
  img {
    max-width: 300px;
  }
}

.container-padding {
  padding: 15px 30px !important;
}

.container-padding-small {
  padding: 15px 20px !important;
}
</style>
