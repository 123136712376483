import Vue from "vue";
import VueI18n from "vue-i18n";
import sentry from "@/sentry";
import SupportedLocales from "./locales/i18n-supported-locales.js";

Vue.use(VueI18n);

export default new VueI18n({
  locale: "en-gb",
  fallbackLocale: "en-gb",
  messages: SupportedLocales.messages,
  silentTranslationWarn: true,
  missing: (locale, path) => {
    if (path.includes(" ") || !path.includes(".")) {
      return;
    }
    let message = `${locale} locale missing path ${path}`;
    sentry.captureMessage(message, "error");
  }
});
