<template>
  <ul class="tab-headers">
    <li
      v-for="tab in tabs"
      :key="tab.id"
      :class="{ active: currentTab === tab.id }"
      :disabled="tab.disabled"
      :data-cy="tab.id"
      role="tab"
    >
      <v-tooltip v-if="tab.tooltip" right color="transparent">
        <feature-flag-note :message="tab.tooltip" />
        <template v-slot:activator="{ on }">
          <div v-on="on">
            <button @click="updateTab(tab.id)" class="text-button-reset">
              {{ tab.text }}
            </button>
          </div>
        </template>
      </v-tooltip>

      <button v-else @click="updateTab(tab.id)" class="text-button-reset">
        {{ tab.text }}
      </button>
    </li>
  </ul>
</template>

<script>
import FeatureFlagNote from "@/components/common/FeatureFlagNote.vue";

export default {
  name: "vfl-tab-headers",
  components: { FeatureFlagNote },
  props: {
    tabs: {
      type: Array,
      required: true
    },
    value: String
  },
  computed: {
    currentTab: {
      get() {
        return this.value;
      },
      set(tab) {
        this.$emit("input", tab);
      }
    }
  },
  methods: {
    updateTab(tab) {
      this.currentTab = tab;
    }
  }
};
</script>

<style lang="scss" scoped>
ul {
  display: flex;
  gap: 1.75rem;
  flex-wrap: wrap;
  padding: 0;
}

li {
  list-style-type: none;

  &.active {
    button {
      border-color: var(--v-info-darken1);
      color: var(--v-info-darken1);
    }
  }

  &[disabled] {
    cursor: default;
    opacity: 0.5;

    button {
      pointer-events: none;
    }
  }
}

button {
  border-bottom: 4px solid rgba(0, 0, 0, 0);
  padding: 0.5rem 0rem;
}
</style>
