const VIDARoles = {
  Admin: 0,
  SuperAdmin: 10,
  RestrictedAdmin: 20,
  EndUser: 40
};

const SSORoles = {
  VIDATeamAdmin: 0,
  VIDACompanyAdmin: 10,
  VIDARestrictedAdmin: 20,
  VIDAUser: 40
};

function getRoleNameFromValue(roleValue) {
  for (const role in VIDARoles) {
    if (VIDARoles.hasOwnProperty(role) && VIDARoles[role] === roleValue) {
      return role;
    }
  }
  return null;
}

export { VIDARoles, SSORoles, getRoleNameFromValue };
