<template>
  <p
    v-if="!hasPain && $vuetify.breakpoint.mdAndUp"
    class="mt-12 d-flex align-center vflNeutralDark1--text text-body-bold"
  >
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="mr-2"
    >
      <path
        d="M7.82843 11.0009H20V13.0009H7.82843L13.1924 18.3648L11.7782 19.779L4 12.0009L11.7782 4.22266L13.1924 5.63687L7.82843 11.0009Z"
        fill="black"
      />
    </svg>
    Select areas where you currently or recently experienced pain or discomfort
  </p>
</template>
<script>
export default {
  name: "PainPersonInstruction",
  props: {
    results: Object
  },
  computed: {
    hasPain() {
      return (
        this.results?.pain?.areas &&
        Object.keys(this.results.pain.areas).length > 0
      );
    }
  }
};
</script>
