<template>
  <div class="footer-controls px-4 py-2" :class="{ 'is-disabled': isLoading }">
    <div class="input-wrapper mb-2">
      <input
        v-model="subject"
        placeholder="Subject"
        :disabled="isLoading"
        type="text"
      />
    </div>
    <div class="input-wrapper">
      <message-input
        v-model="content"
        :disabled="isLoading"
        auto-grow
        @enter="handleSendMessage"
        :autofocus="!isLoading"
      >
        <template v-slot:icon>
          <vfl-icon-button
            :disabled="!canSubmit"
            @click="handleSendMessage"
            screen-reader="Send message"
            :width="28"
          >
            <slot
              ><v-icon small color="white"> mdi-arrow-right-thin </v-icon></slot
            >
          </vfl-icon-button>
        </template>
      </message-input>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import MessageInput from "@/components/messaging/input/MessageInput.vue";
import VflIconButton from "@/components/vitrue-foundation-library/VflIconButton.vue";

export default {
  name: "FooterControls",
  components: {
    VflIconButton,
    MessageInput
  },
  data() {
    return {
      subject: "",
      content: ""
    };
  },
  computed: {
    ...mapState("messagingStore", ["isLoading", "isSendingMessage"]),
    canSubmit() {
      return (
        this.subject.trim() &&
        this.content.trim() &&
        !this.isLoading &&
        !this.isSendingMessage
      );
    }
  },
  methods: {
    ...mapActions("messagingStore", ["sendMessage"]),
    handleSendMessage() {
      if (this.canSubmit) {
        this.sendMessage({
          subject: this.subject,
          content: this.content
        });

        this.resetForm();
      }
    },
    resetForm() {
      this.subject = "";
      this.content = "";
    }
  }
};
</script>

<style scoped lang="scss">
.footer-controls {
  border: 1px solid var(--vfl-border-color);
  border-radius: 12px;
  box-shadow: 0 2px 3px 0 hsla(0, 0%, 0%, 0.05);
  position: relative;
  transition: border 0.125s ease-out;

  &:focus-within {
    border: 1px solid var(--v-vflPrimary-base);
  }

  &.is-disabled {
    &:after {
      border-radius: inherit;
      background-color: hsla(0, 0%, 100%, 60%);
      bottom: 0;
      content: "";
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 1;
    }
  }
}

.input-wrapper {
  display: flex;
  overflow: hidden;
  transition: border-color 0.3s, box-shadow 0.3s;
}

input[type="text"] {
  background-color: transparent;
  border-bottom: 1px solid var(--vfl-border-color);
  flex-grow: 1;
  font-size: 0.875rem;
  line-height: 1.3;
  min-height: 34px;
  max-height: 200px;
  overflow: auto;
  padding: 0.5rem 0;
  resize: none;

  &:focus {
    outline: none;
  }

  &:disabled {
    cursor: not-allowed;
  }
}
</style>
