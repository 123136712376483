import { hasNonPainCondition } from "@/components/assessment/pre-existing-conditions/get-conditions-service.js";
import answers from "@/components/assessment/pre-existing-conditions/answers.json";
import sentry from "@/sentry";

const MOBILITY_ISSUE_KEY = "MobilityIssue";

export function getTextBasedOnUserMobility(text, results) {
  const hasMultipleTextOptions = typeof text === "object";
  if (!hasMultipleTextOptions) {
    return text;
  }

  const unknownKeys = Object.keys(text).some(
    x => !x.includes(MOBILITY_ISSUE_KEY)
  );
  if (unknownKeys) {
    sentry.captureMessage(
      `Multiple text options found unrelated to mobility, text = ${JSON.stringify(
        text
      )}`,
      "error"
    );
    // If unknown key return empty instead of picking unknown text from options
    return "";
  }
  const mobilityIssueValue = answers.setup.nonPainCondition.mobilityImpairment;
  const hasMobilityIssue = hasNonPainCondition(results, mobilityIssueValue);
  return hasMobilityIssue ? text.hasMobilityIssue : text.noMobilityIssue;
}
