<template>
  <div class="vit-play-button">
    <svg
      width="17"
      height="22"
      viewBox="0 0 17 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.9264 11.1744L1.08829 21.0665C0.766627 21.281 0.33201 21.1941 0.117558 20.8723C0.0409081 20.7574 0 20.6223 0 20.4841V0.7C0 0.313404 0.313404 0 0.7 0C0.838194 0 0.973309 0.0409081 1.08829 0.117572L15.9264 10.0096C16.248 10.2241 16.3349 10.6586 16.1204 10.9804C16.0692 11.0572 16.0033 11.1232 15.9264 11.1744Z"
        fill="white"
      />
    </svg>
  </div>
</template>
  
<script>
export default {};
</script>
  
<style lang="scss" scoped>
.vit-play-button {
  align-items: center;
  display: inline-flex;
  justify-content: center;
  position: relative;

  &:before {
    background: hsla(0, 0%, 0%, 0.4);
    border-radius: 9999px;
    content: "";
    height: 4rem;
    position: absolute;
    width: 4rem;
  }

  svg {
    left: 2px;
    position: relative;
    z-index: 1;
  }
}
</style>
  