<template>
  <div class="mb-4 ai-assistant-response" :class="{ 'is-last': isLast }">
    <div class="ai-assistant-message-container ml-n2">
      <ai-assistant-bubble-icon />
      <ai-assistant-three-dots-loader
        v-if="showLoadingDots"
        class="ml-2 mt-2"
      />
      <div
        v-else
        v-html="parseMarkdown(content)"
        class="content text-body-2 mb-1 mt-0"
      ></div>
    </div>
    <ai-assistant-message-footer
      v-if="showFooter"
      class="ml-6 ai-assistant-response-footer"
      :interactionId="interactionId"
      :message="content"
    />
  </div>
</template>
<script>
import { marked } from "marked";
import DOMPurify from "dompurify";
import { mapGetters } from "vuex";
import AiAssistantBubbleIcon from "@/components/AiAssistant/WindowComponents/AiAssistantBubbleIcon.vue";
import AiAssistantMessageFooter from "@/components/AiAssistant/WindowComponents/AiAssistantMessageFooter.vue";
import AiAssistantThreeDotsLoader from "@/components/AiAssistant/WindowComponents/AiAssistantThreeDotsLoader.vue";

export default {
  name: "AiAssistantMessage",
  components: {
    AiAssistantBubbleIcon,
    AiAssistantMessageFooter,
    AiAssistantThreeDotsLoader
  },
  data() {
    return {
      parsedContentCache: new Map()
    };
  },
  props: {
    interactionId: String,
    content: String,
    isTemp: Boolean,
    isLast: Boolean,
    isSystem: Boolean
  },
  computed: {
    ...mapGetters("ai", ["awaitingAssistantResponse"]),
    showLoadingDots() {
      return this.isTemp && this.awaitingAssistantResponse;
    },
    showFooter() {
      const isAbortedMessage = this.interactionId?.includes("temp");

      return (
        !this.isSystem &&
        !this.isTemp &&
        !isAbortedMessage &&
        this.interactionId
      );
    }
  },
  methods: {
    parseMarkdown(content) {
      if (!content) return "";

      if (!this.parsedContentCache.has(content)) {
        let parsedContent;

        try {
          const markedContent = marked(content);
          parsedContent = DOMPurify.sanitize(markedContent);
        } catch (error) {
          parsedContent = content;
        }

        this.parsedContentCache.set(content, parsedContent);
      }
      return this.parsedContentCache.get(content);
    }
  }
};
</script>

<style lang="scss" scoped>
.ai-assistant-response {
  &:not(.is-last) {
    .ai-assistant-response-footer {
      opacity: 0;
      transition: opacity 0.125s ease-out;
    }
  }

  &:hover {
    .ai-assistant-response-footer {
      opacity: 1;
    }
  }
}

.ai-assistant-message-container {
  display: flex;
  gap: 0.5rem;

  .content {
    flex: 1;
    word-break: break-word;

    ::v-deep {
      * + * {
        margin-top: 1rem;
      }

      > :last-child {
        margin-bottom: 0.5rem;
      }

      ol,
      ul {
        padding-left: 0;
      }

      ol ol,
      ol ul,
      ul ul,
      ul ol {
        padding-left: 1rem;
      }

      ul li {
        list-style-type: disc;
      }

      li {
        margin-bottom: 0.5rem;
      }

      a {
        color: var(--v-vflPrimary-base);
      }

      pre,
      code {
        white-space: pre-wrap;
        word-break: break-word;
      }

      hr {
        border: none;
        background: hsla(0, 0%, 0%, 0.2);
        height: 1px;
      }
    }
  }
}
</style>
