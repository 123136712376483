<template>
  <svg
    width="641"
    height="638"
    viewBox="0 0 641 638"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M301.752 273.75C301.714 268.744 297.734 264.704 292.732 264.739C287.726 264.773 283.992 268.822 284.018 273.824L282.557 335.175C282.595 340.181 286.674 344.204 291.676 344.17C296.686 344.135 299.89 340.13 299.851 335.128L301.752 273.75Z"
      fill="#B28B67"
    />
    <path
      d="M288.104 369.568C277.644 369.573 275.566 360.919 277.303 341.796C278.299 330.823 279.821 321.734 279.821 321.734L301.82 323.334C301.82 323.334 304.148 369.555 288.104 369.568Z"
      fill="#C86E01"
    />
    <path
      d="M296.497 206.888C296.333 201.886 286.877 201.49 287.05 206.491L284.165 273.241C284.333 278.243 288.317 282.188 293.336 282.024C298.329 281.856 302.05 277.691 301.891 272.689L296.497 206.888Z"
      fill="#B28B67"
    />
    <path
      d="M287.044 206.498C286.902 197.831 286.734 192.515 286.889 183.732C286.98 178.894 286.152 175.785 289.006 175.596C291.145 175.458 291.162 181.49 291.257 183.637C291.654 191.674 291.904 191.696 292.74 191.627C293.607 191.562 293.314 186.966 294.473 184.732C295.206 183.344 298.087 183.71 297.914 187.69C297.505 196.784 296.491 206.895 296.491 206.895L287.044 206.498Z"
      fill="#B28B67"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M271.77 331.851C279.933 333.939 287.328 333.909 290.268 332.129C297.64 327.666 289.583 298.209 279.933 288.543C270.283 278.877 236.984 283.413 242.25 306.731C244.077 314.824 249.532 320.896 256.185 325.189L259.727 368.937L285.336 361.235L271.77 331.851Z"
      fill="#BA9879"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M283.905 292.978C283.202 291.561 282.084 290.088 281.158 288.825C279.965 287.197 278.495 285.848 276.956 284.622C274.057 282.315 270.839 280.413 267.504 279.033C261.412 276.514 254.492 276.085 248.592 278.674C245.614 279.981 242.967 282.063 241.003 284.795C239.228 287.263 237.793 290.542 234.86 291.628C231.669 292.81 227.825 290.836 224.684 289.848C221.142 288.733 217.575 288.218 213.968 288.685C207.925 289.469 203.101 293.041 200.554 298.958C197.843 305.255 196.99 313.551 201.766 319.377C202.654 320.461 203.631 321.305 204.843 321.884C205.954 322.415 207.541 322.858 208.276 324.019C209.058 325.254 208.457 327.329 208.388 328.676C208.288 330.606 208.325 332.588 209.077 334.427C210.311 337.446 213.158 339.827 215.836 341.25C218.561 342.698 221.504 343.114 224.429 342.833C226.383 342.646 228.327 342.282 230.159 341.581C231.068 341.233 231.811 340.728 232.65 340.252C233.466 339.788 234.026 339.874 234.914 340.131C239.038 341.325 243.011 341.072 246.898 339.541C250.179 338.248 253.812 336.266 255.864 333.135C258.142 329.658 257.02 326.145 255.168 322.588C256.041 322.736 258.214 322.337 256.506 321.213C255.829 320.769 254.816 320.809 254.072 320.652C253.206 320.468 252.293 320.169 251.516 319.678C248.377 317.697 249.091 311.531 251.751 309.75C255.703 307.104 258.53 312.969 262.117 313.627C264.18 314.005 265.673 312.191 266.628 310.51C267.906 308.259 268.446 305.623 268.808 302.997C269.105 300.834 269.335 298.663 269.652 296.505C269.8 295.498 270.481 292.57 269.462 291.787C272.393 290.228 276.257 290.479 279.454 291.371C280.775 291.74 281.855 292.26 282.82 293.322C283.026 293.55 283.54 294.257 283.897 294.185C284.56 294.051 284.082 293.334 283.905 292.978Z"
      fill="#070707"
    />
    <path
      d="M208.278 389.898C210.241 383.299 216.209 374.227 219.231 368.302C221.18 364.453 224.636 358.805 228.996 359.75C232.663 360.552 235.16 364.492 232.328 371.167C229.707 377.35 227.463 386.002 226.408 389.062C222.483 400.483 220.057 411.475 220.057 411.475L206.65 409.445C206.65 409.445 205.471 399.352 208.278 389.898Z"
      fill="#B28B67"
    />
    <path
      d="M321.66 482.895L272.877 482.709C272.877 482.709 262.483 519.049 279.17 530.737C290.568 538.74 341.762 546.89 352.834 547.855C374.788 549.779 398.929 554.258 398.929 554.258C398.929 554.258 427.243 554.554 426.432 533.87C425.61 513.654 355.723 491.819 321.66 482.895Z"
      fill="#17282F"
    />
    <path
      d="M181.322 556.045C181.322 556.045 170.842 575.158 157.043 570.532C142.155 565.542 145.063 547.88 146.438 543.373C160.538 496.667 205.702 395.467 205.702 395.467C205.702 395.467 208.447 393.238 217.591 396.298C226.887 399.421 227.14 402.653 227.14 402.653C216.297 438.115 188.866 532.683 181.322 556.045Z"
      fill="#223A43"
    />
    <path
      d="M251.147 483.417C218.865 499.943 183.838 517.074 150.893 543.913C146.242 547.704 143.134 557.573 152.831 567.825C158.618 573.946 174.208 572.657 185.213 568.88C195.197 565.452 225.388 560.467 258.233 538.479C288.671 518.091 299.209 512.701 324.295 484.306L251.147 483.417Z"
      fill="#223A43"
    />
    <path
      d="M307.807 389.286C306.642 376.723 302.087 371.634 298.902 364.82C293.612 353.456 290.842 350.816 286.851 348.925C285.414 348.242 278.242 347.693 276.05 347.703C272.083 347.727 256.054 350.568 252.287 351.728C243.549 354.411 237.757 357.811 236.387 362.151C233.374 371.624 240.555 395.483 245.827 410.543C262.801 459.015 247.97 472.981 251.146 483.424C256.259 500.269 322.876 487.024 324.294 484.312C326.156 480.75 301.428 433.251 302.789 419.051C303.72 409.263 309.23 404.655 307.807 389.286Z"
      fill="#FF9B21"
    />
    <path
      d="M277.093 545.211C277.093 545.211 269.659 543.912 267.873 543.917C260.897 543.946 244.716 545.464 238.084 547.044C233.18 548.219 224.337 555.768 224.7 560.963C225.03 565.341 231.433 568.941 239.55 566.468C256.285 561.359 266.149 560.595 266.149 560.595L277.093 545.211Z"
      fill="#B28B67"
    />
    <path
      d="M409.639 555.942C419.613 553.803 427.31 550.159 426.489 534.689C425.63 518.541 403.962 519.243 398.261 519.549C356.191 521.798 265.539 537.139 265.539 537.139C265.539 537.139 262.163 539.603 262.894 550.947C263.653 562.493 267.262 563.758 267.262 563.758C310.96 562.115 374.769 563.404 409.639 555.942Z"
      fill="#17282F"
    />
    <path
      d="M255.189 274.913C254.659 269.393 249.865 265.339 244.34 265.879C238.821 266.414 235.106 271.25 235.641 276.77L240.148 344.571C240.693 350.085 245.591 354.12 251.111 353.585C256.635 353.046 259.767 348.309 259.228 342.794L255.189 274.913Z"
      fill="#B99879"
    />
    <path
      d="M239.868 385.21C236.483 385.062 234.392 373.631 234.396 352.364C234.406 340.17 234.425 330.148 234.425 330.148L260.209 329.584C260.209 329.584 267.566 386.418 239.868 385.21Z"
      fill="#C86E01"
    />
    <path
      d="M242.714 201.709C242.021 196.213 231.579 196.715 232.257 202.21L235.733 276.118C236.406 281.618 241.215 285.567 246.715 284.889C252.215 284.202 255.906 279.241 255.228 273.74L242.714 201.709Z"
      fill="#B99879"
    />
    <path
      d="M232.258 202.205C231.232 192.665 230.525 186.816 229.814 177.119C229.422 171.781 228.209 168.424 231.327 167.932C233.667 167.565 234.292 174.216 234.617 176.575C235.858 185.398 236.131 185.398 237.057 185.241C237.993 185.078 237.214 180.041 238.274 177.458C238.943 175.863 242.161 175.968 242.366 180.385C242.825 190.445 242.715 201.704 242.715 201.704L232.258 202.205Z"
      fill="#B99879"
    />
    <path
      d="M258.19 329.628L256.383 298.008"
      stroke="#B28B67"
      stroke-width="0.903424"
    />
    <path
      opacity="0.5"
      d="M256.383 502.182C234.701 516.335 186.278 545.185 166.041 547.353L209.857 414.098"
      stroke="white"
      stroke-width="0.903424"
    />
  </svg>
</template>

<script>
export default {
  name: "ExerciseThreeMoreOverlay"
};
</script>
