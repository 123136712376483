<template>
  <div v-if="loading" class="d-flex flex-column align-center">
    <v-progress-circular
      color="vflGreyVeryLight"
      indeterminate
      :size="175"
      :width="15"
    />
  </div>
  <div v-else class="d-flex flex-column align-center">
    <div class="donut-container mb-2">
      <vfl-donut-chart :chartData="chartData" :height="175" :width="175" />
      <div class="donut-content text-center">
        <p v-if="noPainInTeam" class="text-body-2 people-text mb-0">
          {{ $t("mskOverview.donut.noPainInTeam") }}
        </p>
        <template v-else>
          <div v-if="meetsMinSampleSizeThreshold" class="percentage-container">
            <h3 class="text-h2 ml-2">
              {{ percentageOfUsersWithPain }}
            </h3>
            <span
              v-if="meetsMinSampleSizeThreshold"
              class="percentage-symbol text-h6"
              >%</span
            >
          </div>
          <h3 class="text-h2" v-else>?</h3>
          <p class="text-body-2 people-text mb-0">
            {{ $t("mskOverview.donut.peopleWithPain") }}
          </p>
        </template>
      </div>
    </div>
    <span
      v-if="meetsMinSampleSizeThreshold"
      class="text-caption vflNeutralDark2--text"
    >
      {{ industryComparisonText }}
    </span>
  </div>
</template>

<script>
import VflDonutChart from "@/components/vitrue-foundation-library/graphs/VflDonutChart.vue";

export default {
  name: "PainOverviewDonutChart",
  components: {
    VflDonutChart
  },
  props: {
    loading: Boolean,
    painData: {
      type: Object,
      required: true
    },
    meetsMinSampleSizeThreshold: Boolean,
    industryAverage: {
      type: Number,
      default: 61
    }
  },
  computed: {
    chartData() {
      const data = [];
      const backgroundColor = [];

      for (const [key, value] of Object.entries(this.painData)) {
        data.push(value.percentage);
        backgroundColor.push(this.$vuetify.theme.themes.light[value.color]);
      }

      return {
        datasets: [
          {
            data,
            backgroundColor
          }
        ]
      };
    },
    noPainInTeam() {
      return this.painData.noPain?.percentage === 100;
    },
    percentageOfUsersWithPain() {
      return Math.round(100 - (this.painData.noPain?.percentage || 0));
    },
    industryAverageDifference() {
      return Math.round(this.percentageOfUsersWithPain - this.industryAverage);
    },
    industryComparisonText() {
      if (this.industryAverageDifference === 0) {
        return this.$t("mskOverview.donut.equalToIndustry");
      }

      const relative = this.industryAverageDifference < 0 ? "better" : "worse";
      return this.$t("mskOverview.donut.industryAverageComparison", {
        percentage: Math.abs(this.industryAverageDifference),
        relative: this.$t(`mskOverview.donut.relative.${relative}`)
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.donut-container {
  position: relative;
  max-width: 175px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.donut-content {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.percentage-container {
  display: flex;
  align-items: flex-start;
}

.percentage-symbol {
  margin-left: 2px;
  margin-top: 4px;
}

.people-text {
  color: var(--v-vflNeutralDark2-base);
  max-width: 85px;
  font-size: 0.8rem;
  line-height: 1.2;
}
</style>
