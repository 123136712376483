<template>
  <p class="mb-0 d-flex align-center" style="line-height: 1">
    <img :src="icon" aria-hidden="true" />
    <span class="ml-1 text-caption text-body-dark">{{ text }}</span>
  </p>
</template>

<script>
import { Locations } from "@/components/common/assessment-history/assessmentHistoryConstants.js";

export default {
  name: "UserProfileAssessmentLocation",
  props: {
    location: {
      type: String,
      required: true
    }
  },
  computed: {
    isHome() {
      return this.location === Locations.home.value;
    },
    text() {
      if (this.location === Locations.notatusualworkspace.value) {
        return this.$t(
          "dashboardHistoryComponents.assessmentHistoryTable.assessmentLocations.other"
        );
      }

      const localeString = Locations[this.location.toLowerCase()].text;
      return this.$t(localeString);
    },
    icon() {
      return this.isHome ? "/images/homeIcon.svg" : "/images/officeIcon.svg";
    }
  }
};
</script>

<style lang="scss" scoped>
img {
  display: inline-block;
  height: auto;
  margin-bottom: 2px; // optical alignment with text
  width: 16px;
}
</style>
