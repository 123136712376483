<template>
  <div class="dynamic-image-container">
    <div class="dynamic-images">
      <component :is="baseComponent" v-if="baseComponent" />
      <div v-if="hasOverlays" class="dynamic-image-overlays">
        <component
          v-for="(overlay, index) in overlayComponents"
          :key="overlay"
          :is="overlay"
          :style="{ zIndex: index + 1 }"
        />
      </div>
    </div>
  </div>
</template>

<script>
import StageBase from "@/components/assessment/menopause/dynamic-images/svgs/stage/StageBase.vue";
import StageUnanswered from "@/components/assessment/menopause/dynamic-images/svgs/stage/overlays/Unanswered.vue";
import StageNotStartedMenopause from "@/components/assessment/menopause/dynamic-images/svgs/stage/overlays/NotStartedMenopause.vue";
import StagePerimenopause from "@/components/assessment/menopause/dynamic-images/svgs/stage/overlays/Perimenopause.vue";
import StagePostmenopause from "@/components/assessment/menopause/dynamic-images/svgs/stage/overlays/Postmenopause.vue";
import StageNotSure from "@/components/assessment/menopause/dynamic-images/svgs/stage/overlays/NotSure.vue";
import StagePerimenopauseHormones from "@/components/assessment/menopause/dynamic-images/svgs/stage/overlays/PerimenopauseHormones.vue";
import StagePostmenopauseHormones from "@/components/assessment/menopause/dynamic-images/svgs/stage/overlays/PostmenopauseHormones.vue";
import SymptomsBase from "@/components/assessment/menopause/dynamic-images/svgs/symptoms/SymptomsBase.vue";
import SymptomsDifficultyConcentrating from "@/components/assessment/menopause/dynamic-images/svgs/symptoms/overlays/DifficultyConcentrating.vue";
import SymptomsHotFlashesNightSweating from "@/components/assessment/menopause/dynamic-images/svgs/symptoms/overlays/HotFlashesNightSweating.vue";
import SymptomsMemoryChanges from "@/components/assessment/menopause/dynamic-images/svgs/symptoms/overlays/MemoryChanges.vue";
import SymptomsMoodChanges from "@/components/assessment/menopause/dynamic-images/svgs/symptoms/overlays/MoodChanges.vue";
import SymptomsNightWaking from "@/components/assessment/menopause/dynamic-images/svgs/symptoms/overlays/NightWaking.vue";
import SymptomsReducedStrength from "@/components/assessment/menopause/dynamic-images/svgs/symptoms/overlays/ReducedStrength.vue";
import SymptomsTiredness from "@/components/assessment/menopause/dynamic-images/svgs/symptoms/overlays/Tiredness.vue";
import LifestyleBase from "@/components/assessment/menopause/dynamic-images/svgs/lifestyle/LifestyleBase.vue";
import LifestyleUnanswered from "@/components/assessment/menopause/dynamic-images/svgs/lifestyle/overlays/Unanswered.vue";
import LifestyleExerciseOnce from "@/components/assessment/menopause/dynamic-images/svgs/lifestyle/overlays/ExerciseOnce.vue";
import LifestyleExerciseTwice from "@/components/assessment/menopause/dynamic-images/svgs/lifestyle/overlays/ExerciseTwice.vue";
import LifestyleExerciseThreeMore from "@/components/assessment/menopause/dynamic-images/svgs/lifestyle/overlays/ExerciseThreeMore.vue";
import LifestyleExerciseNever from "@/components/assessment/menopause/dynamic-images/svgs/lifestyle/overlays/ExerciseNever.vue";
import LifestyleWeightlifting from "@/components/assessment/menopause/dynamic-images/svgs/lifestyle/overlays/Weightlifting.vue";
import { Theme } from "@/components/assessment/menopause/steps.js";

export default {
  name: "DynamicImage",
  components: {
    StageBase,
    StageUnanswered,
    StageNotStartedMenopause,
    StagePerimenopause,
    StagePostmenopause,
    StageNotSure,
    StagePerimenopauseHormones,
    StagePostmenopauseHormones,
    SymptomsBase,
    SymptomsDifficultyConcentrating,
    SymptomsHotFlashesNightSweating,
    SymptomsMemoryChanges,
    SymptomsMoodChanges,
    SymptomsNightWaking,
    SymptomsReducedStrength,
    SymptomsTiredness,
    LifestyleBase,
    LifestyleUnanswered,
    LifestyleExerciseOnce,
    LifestyleExerciseTwice,
    LifestyleExerciseThreeMore,
    LifestyleExerciseNever,
    LifestyleWeightlifting
  },
  props: {
    currentTheme: String,
    results: Object,
    schema: Object
  },
  computed: {
    baseComponent() {
      return this.schema?.illustration?.base;
    },
    overlayComponents() {
      if (!this.currentTheme || !this.results || !this.schema?.questions) {
        return [];
      }

      switch (this.currentTheme) {
        case Theme.STAGE:
          return this.getStageOverlays();
        case Theme.SYMPTOMS:
          return this.getSymptomsOverlays();
        case Theme.LIFESTYLE:
          return this.getLifestyleOverlays();
        default:
          return [];
      }
    },
    hasOverlays() {
      return this.overlayComponents?.length > 0;
    }
  },
  methods: {
    addUniqueOverlays(overlays, newOverlays) {
      if (!Array.isArray(newOverlays)) return;

      newOverlays.forEach(overlay => {
        if (!overlays.includes(overlay)) {
          overlays.push(overlay);
        }
      });
    },
    getStageOverlays() {
      const results = this.results.stage;
      const overlays = [];

      if (!results.stage && this.schema?.illustration?.overlays) {
        // Return the default overlay if no answers yet
        return this.schema.illustration.overlays;
      }

      const stageQuestion = this.schema.questions[0];
      const selectedStage = results.stage;

      if (selectedStage) {
        const stageOption = stageQuestion.options.find(
          opt => opt.value === selectedStage
        );

        if (stageOption?.illustration?.overlays) {
          this.addUniqueOverlays(overlays, stageOption.illustration.overlays);
        }

        // 2 - perimenopause
        // 3 - postmenopause
        if (selectedStage === 2 || selectedStage === 3) {
          const injectablesQuestion = this.schema.questions.find(
            q => q.name === "contraceptiveInjectables"
          );

          // 1 - yes
          if (results.contraceptiveInjectables === 1) {
            const injectablesOption = injectablesQuestion?.options?.find(
              opt => opt.value === 1
            );
            const stageSpecificOverlays =
              injectablesOption?.illustration?.overlays?.[selectedStage];

            this.addUniqueOverlays(overlays, stageSpecificOverlays);
          }

          const hrtQuestion = this.schema.questions.find(
            q => q.name === "hormoneReplacementTherapy"
          );

          if (results.hormoneReplacementTherapy === 1) {
            const hrtOption = hrtQuestion?.options?.find(
              opt => opt.value === 1
            );
            const stageSpecificOverlays =
              hrtOption?.illustration?.overlays?.[selectedStage];

            this.addUniqueOverlays(overlays, stageSpecificOverlays);
          }
        }
      }

      return overlays;
    },
    getSymptomsOverlays() {
      const selectedSymptoms = this.results?.symptoms?.symptoms || [];
      const symptomsQuestion = this.schema.questions[0];
      const overlays = [];

      selectedSymptoms.forEach(symptomValue => {
        const option = symptomsQuestion.options?.find(
          opt => opt.value === symptomValue
        );
        if (option?.illustration?.overlays) {
          this.addUniqueOverlays(overlays, option.illustration.overlays);
        }
      });

      return overlays;
    },
    getLifestyleOverlays() {
      const results = this.results.lifestyle;
      const overlays = [];

      if (!results.physicalActivity && this.schema?.illustration?.overlays) {
        // Return the default overlay if no answers yet
        return this.schema.illustration.overlays;
      }

      const physicalActivityQuestion = this.schema.questions[0];
      const selectedPhysicalActivity = results.physicalActivity;

      if (selectedPhysicalActivity) {
        const physicalActivityOption = physicalActivityQuestion.options.find(
          opt => opt.value === selectedPhysicalActivity
        );

        if (physicalActivityOption?.illustration?.overlays) {
          this.addUniqueOverlays(
            overlays,
            physicalActivityOption.illustration.overlays
          );
        }
      }

      const strengthQuestion = this.schema.questions.find(
        q => q.name === "strengthExercise"
      );
      const selectedStrength = results.strengthExercise;

      // Does weightlifting once or sometimes
      if (selectedStrength === 1 || selectedStrength === 3) {
        const option = strengthQuestion?.options?.find(
          opt => opt.value === 1 || opt.value === 3
        );

        this.addUniqueOverlays(overlays, option.illustration.overlays);
      }

      return overlays;
    }
  }
};
</script>

<style scoped lang="scss">
.dynamic-image-container {
  // Above 1280px there is no offset
  // The maximum negative offset is -300
  left: clamp(-300px, calc((100vw - 1280px) / 2), 0px);
  position: fixed;

  svg {
    display: block;
    height: auto;
    width: 100%;
  }
}

.dynamic-images {
  position: relative;
}

.dynamic-image-overlays {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;

  > svg:not(:first-child) {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
}
</style>
