<template>
  <div>
    <v-row class="mt-2" v-if="cards.length">
      <v-col
        v-for="card in cards"
        :key="card.id"
        cols="12"
        md="6"
        lg="4"
        class="my-1"
      >
        <information-card
          class="fill-height"
          :id="card.id"
          :title="card.title"
          :color="cardColour(card)"
          :imageUrl="card.imageUrl"
          :text="card.text"
          :assessmentType="assessmentType"
        />
      </v-col>
    </v-row>
    <slot slot name="noCards" v-else></slot>
  </div>
</template>
<script>
import InformationCard from "@/components/common/report/cards/InformationReportCard.vue";
import { HealthImpact } from "@/constants/healthImpact.js";

export default {
  name: "ReportBodyContent",
  components: {
    InformationCard
  },
  props: {
    results: Object,
    getReportCards: Function,
    assessmentType: String
  },
  created() {
    this.cards = this.getReportCards(this.results);
  },
  watch: {
    results() {
      this.cards = this.getReportCards(this.results);
    }
  },
  methods: {
    cardColour(card) {
      switch (card.healthImpact) {
        case HealthImpact.POSITIVE:
          return this.$vuetify.theme.currentTheme.success;
        case HealthImpact.NEGATIVE:
          return this.$vuetify.theme.currentTheme.warning;
        case HealthImpact.NEUTRAL:
          return this.$vuetify.theme.currentTheme.info;
      }

      if (card.hasOwnProperty("isGood")) {
        if (card.isGood) {
          return this.$vuetify.theme.currentTheme.success;
        } else {
          return this.$vuetify.theme.currentTheme.warning;
        }
      } else {
        return this.$vuetify.theme.currentTheme.info;
      }
    }
  },
  data() {
    return {
      cards: []
    };
  }
};
</script>
