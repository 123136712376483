<template>
  <div class="msk-health-culture-card vfl-card-light pa-3">
    <img :src="imageUrl" aria-hidden="true" alt="" />
    <p class="stat mb-0 text-h6 text-body-darkest">
      <span class="value">{{ roundedStat }}</span>
      <span class="suffix">{{ suffix }}</span>
    </p>

    <p class="label mb-0 text-body-2">
      <span class="mr-1">{{ label }}</span>
      <v-tooltip
        v-if="tooltipText"
        bottom
        color="transparent"
        maxWidth="250"
        style="z-index: 100"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-on="on" v-bind="attrs" color="vflAquaDark1" size="14"
            >mdi-information-outline</v-icon
          >
        </template>
        <vitrue-tooltip :message="tooltipText" />
      </v-tooltip>
    </p>
  </div>
</template>
<script>
import VitrueTooltip from "@/components/common/VitrueTooltip.vue";

export default {
  name: "MskHealthCultureCard",
  components: { VitrueTooltip },
  props: {
    imageUrl: {
      type: String,
      required: true
    },
    stat: {
      type: Number,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    tooltipText: {
      type: String
    },
    cardKey: String
  },
  computed: {
    suffix() {
      return this.cardKey === "averageHoursSpentAtDesk"
        ? ` ${this.$t("mskHealthCulture.hours")}`
        : "%";
    },
    roundedStat() {
      return Math.min(Math.ceil(this.stat), 100);
    }
  }
};
</script>
<style scoped lang="scss">
.msk-health-culture-card {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto auto;
  grid-gap: 0.25rem 1rem;

  img {
    align-self: center;
    grid-row: 1 / -1;
  }

  .stat {
    align-self: end;
    display: flex;
    line-height: 1;
  }

  .label {
    color: var(--v-vflAquaDark1-base);
    gap: 0.5rem;
    line-height: 1.25;
  }

  .value {
    margin-right: 2px;
  }

  .suffix {
    align-self: start;
    font-family: Poppins;
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 1;
    position: relative;
    top: 2px;
  }
}
</style>
