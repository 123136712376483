export default {
  cleanEmail(email) {
    if (typeof email !== "string") {
      return email;
    }

    // This will remove all whitespaces (spaces, tabs, newlines) and semicolons from the email string
    return email.replace(/[\s;]+/g, "");
  },
  obfuscateEmail(email) {
    if (!email) {
      return "";
    }
    const splitEmail = email.split("@");

    // Check if the email format is correct
    if (splitEmail.length !== 2) {
      return null;
    }

    const name = splitEmail[0];
    const domain = splitEmail[1];

    let obfuscatedName = "";

    // Either keep the first character and replace the rest with asterisks
    // or keep the first two and the last character and replace the rest with asterisks
    if (name.length < 5) {
      obfuscatedName = name.charAt(0) + "*".repeat(name.length - 1);
    } else {
      obfuscatedName =
        name.substring(0, 2) +
        "*".repeat(name.length - 3) +
        name.substring(name.length - 1);
    }

    return obfuscatedName + "@" + domain;
  }
};
