import {
  STAGES,
  TREATMENT_TYPES
} from "@/components/assessment/menopause/report/constants/index.js";

export const TAB_INDICES = {
  PERIMENOPAUSE: 0,
  MENOPAUSE: 1,
  POSTMENOPAUSE: 2,
  INJECTABLE_CONTRACEPTIVES: 3,
  HRT: 4
};

export const TABS = [
  {
    type: STAGES.PERIMENOPAUSE,
    title: "Perimenopause",
    index: TAB_INDICES.PERIMENOPAUSE
  },
  {
    type: STAGES.MENOPAUSE,
    title: "Menopause",
    index: TAB_INDICES.MENOPAUSE
  },
  {
    type: STAGES.POSTMENOPAUSE,
    title: "Postmenopause",
    index: TAB_INDICES.POSTMENOPAUSE
  },
  {
    type: TREATMENT_TYPES.INJECTABLE_CONTRACEPTIVES,
    title: "Injectable Contraceptives",
    index: TAB_INDICES.INJECTABLE_CONTRACEPTIVES
  },
  {
    type: TREATMENT_TYPES.HRT,
    title: "Hormonal Replacement Therapy",
    index: TAB_INDICES.HRT
  }
];
