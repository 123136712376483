<template>
  <div class="user-profile-health-conditions-container">
    <vfl-chip
      v-for="condition in processedConditions"
      :key="condition"
      :label="condition"
    />
  </div>
</template>

<script>
import VflChip from "@/components/vitrue-foundation-library/VflChip.vue";
import { mapGetters } from "vuex";
import {
  Aids,
  Conditions
} from "@/components/common/pre-existing-conditions/conditionsAndAids.js";

export default {
  name: "UserProfileHealthConditions",
  components: { VflChip },
  computed: {
    ...mapGetters("userProfile", ["healthConditions"]),
    processedConditions() {
      if (!this.healthConditions?.length) return [];

      const conditions = Conditions.filter(c =>
        this.healthConditions.includes(c.id)
      );

      const aids = Aids.filter(c => this.healthConditions.includes(c.id));

      return [...conditions, ...aids].map(c => this.$t(c.text));
    }
  }
};
</script>

<style lang="scss" scoped>
.user-profile-health-conditions-container {
  display: contents;
}
</style>
