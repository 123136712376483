<template>
  <div
    v-show="show"
    class="checkbox-card-question"
    :style="'--grid-columns:' + gridColumns"
  >
    <div class="checkboxes">
      <div
        v-for="option in visibleOptions"
        :key="option.value"
        :class="[
          { divider: option.isDivider },
          option.isNoneOption ? 'none-option' : ''
        ]"
      >
        <v-checkbox
          v-if="!option.isDivider"
          class="pt-0"
          v-model="selectedAnswers"
          @change="emitToParent(option.value)"
          :label="$t(option.label)"
          :value="option.value"
          :error="showError"
          hide-details
          ><template v-slot:label>
            <div v-if="option.image" class="image">
              <img :src="option.image" aria-hidden="true" />
            </div>
            <div class="label-content d-flex pa-4 align-center">
              <div class="d-flex flex-column">
                <strong v-if="option.title">{{ option.title }}</strong>
                <span v-if="option.label" :class="{ 'mt-2': option.title }">{{
                  option.label
                }}</span>
              </div>
            </div>
          </template>
        </v-checkbox>
        <div v-if="option.isDivider">
          <span class="text-small font-weight-bold">{{
            $t(`physicalLabourAssessment.schema.common.noneOfThese.divider`)
          }}</span>
        </div>
      </div>
    </div>
    <v-text-field :rules="rules" :hide-details="!showError" />
  </div>
</template>

<script>
import Answer from "@/components/common/assessments/answer-constants";

export default {
  name: "CheckBoxCardsQuestion",
  props: {
    name: String,
    optional: Boolean,
    options: Array,
    show: {
      default: true,
      type: Boolean
    },
    totalQuestionsOnPage: Number,
    value: Array,
    fullQuestion: Object,
    hasNoneOption: {
      default: false,
      type: Boolean
    },
    customNoneOptionText: String,
    gridColumns: {
      type: Number,
      default: 2
    }
  },
  data() {
    return {
      selected: [],
      showError: false,
      mounted: false
    };
  },
  mounted() {
    this.mounted = true;
  },
  computed: {
    selectedAnswers: {
      get() {
        return this.value || [];
      },
      set(selectedAnswers) {
        if (!selectedAnswers.length) {
          this.selected = [];
          return;
        }

        // Check if the last selection is 'none of these'
        const isNoneSelected =
          selectedAnswers[selectedAnswers.length - 1] ===
          Answer.NONE_ANSWER_VALUE;

        if (isNoneSelected) {
          // If 'none of these' is selected, reset the selection to just that
          this.selected = [Answer.NONE_ANSWER_VALUE];
        } else {
          // Otherwise, filter out 'none of these' from the selections, if it was previously selected
          this.selected = selectedAnswers.filter(
            answer => answer !== Answer.NONE_ANSWER_VALUE
          );
        }
      }
    },
    rules() {
      return this.optional
        ? []
        : [
            () => {
              const valid = !this.mounted || this.answeredQuestion;
              this.showError = !valid;
              return valid || this.$t(this.fullQuestion.errorMessage);
            }
          ];
    },
    visibleOptions() {
      return this.processedOptions.filter(x => x.showAnswer);
    },
    answeredQuestion() {
      return !!this.selectedAnswers && this.selectedAnswers.length > 0;
    }
  },
  created() {
    this.processOptions();
  },
  methods: {
    emitToParent() {
      this.showError = false;
      this.$emit("input", this.selected);
    },
    processOptions() {
      this.processedOptions = this.options.map(option => ({
        ...option,
        title: this.$t(option.title),
        label: this.$t(option.label)
      }));

      if (this.hasNoneOption) {
        this.processedOptions.push({
          isDivider: true,
          showAnswer: true
        });

        const label =
          this.customNoneOptionText ??
          `physicalLabourAssessment.schema.common.${Answer.NONE_ANSWER_KEY}.option`;
        this.processedOptions.push({
          isNoneOption: true,
          value: Answer.NONE_ANSWER_VALUE,
          label: this.$t(label),
          showAnswer: true
        });
      }
    }
  }
};
</script>

<style scoped lang="scss">
$checkbox-text-color: rgba(0, 0, 0, 0.6);
$checkbox-border-color: var(--v-vflGreyVeryLight-base);
$checkbox-box-shadow-color: #1a2b3706;
$checkbox-box-shadow-color-hover: #1a2b3712;
$checkbox-border-color-active: var(--v-vflPrimary-base);
$checkbox-image-bg-color: #edf0f2;
$checkbox-error-color: var(--v-error-base);

.checkbox-card-question {
  container-type: inline-size;

  .checkboxes {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(var(--grid-columns), 1fr);

    @container (max-width: 600px) {
      grid-template-columns: 1fr;
    }

    > div:not(.divider, .none-option) {
      display: contents;
    }
  }

  .v-input--checkbox {
    margin: 0;
  }

  .v-input--checkbox {
    border: 1px solid $checkbox-border-color;
    border-radius: 8px;
    box-shadow: 0 2px 8px 0 $checkbox-box-shadow-color;
    min-width: 200px;
    overflow: hidden;

    &:hover {
      box-shadow: 0 4px 12px 0 $checkbox-box-shadow-color-hover;
    }

    &:has(:focus) {
      outline: 2px dashed $checkbox-border-color-active;
      outline-offset: -1px;
    }

    &:has(:checked) {
      outline: 2px solid $checkbox-border-color-active;
      outline-offset: -1px;
    }

    &:has(.image) {
      ::v-deep {
        label {
          justify-content: start;
        }
      }
    }

    &:has(.error--text) {
      outline: 2px solid $checkbox-error-color;
      outline-offset: -1px;
    }

    ::v-deep {
      .v-input__control,
      .v-input__slot {
        height: 100%;
      }

      .v-input--selection-controls__input {
        display: none;
      }

      label {
        align-items: initial;
        background: white;
        color: rgba(0, 0, 0, 0.87);
        cursor: pointer;
        display: flex;
        flex-direction: column;
        font-weight: 500;
        height: 100%;
        justify-content: center;
        min-height: 44px;

        &.error--text {
          color: $checkbox-text-color !important;
        }
      }

      .image {
        background-color: $checkbox-image-bg-color;
        display: flex;
        justify-content: center;

        img {
          max-height: 138px;
          width: 100%;
        }
      }
    }
  }
}

.v-text-field {
  ::v-deep .v-input__slot {
    display: none;
  }
}

.label-content {
  display: flex;
  gap: 0.5rem;
  justify-content: space-between;
}

.divider {
  align-items: center;
  display: flex;
  gap: 0.75rem;
  grid-column: 1/-1;

  &:before,
  &:after {
    background-image: linear-gradient(
      to right,
      var(--v-vflGreyVeryLight-base) 50%,
      rgba(255, 255, 255, 0) 0%
    );
    background-position: bottom;
    background-size: 12px 1px;
    background-repeat: repeat-x;
    content: "";
    display: block;
    flex: 1;
    height: 2px;
  }

  span {
    text-transform: uppercase;
  }

  + div {
    display: block;
    grid-column: 1/-1;
  }
}

.v-input--checkbox.v-input--is-label-active {
  ::v-deep {
    label {
      background-color: color-mix(in srgb, var(--v-vflPrimary-base), #fff 95%);
    }
  }
}
</style>
