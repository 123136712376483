<template>
  <v-container id="container" class="report-container">
    <end-of-report-sign-up
      v-if="!signedIn"
      assessmentType="Burnout assessment"
      :assessmentId="assessmentId"
      :errorMessage="signUpErrorMessage"
    />
    <div
      style="
        position: absolute;
        top: 0;
        height: 100%;
        width: 100%;
        pointer-events: none;
      "
    >
      <canvas
        id="confetti-canvas"
        class="sticky"
        style="z-index: 5; pointer-events: none"
        :width="reportWidth"
        :height="reportHeight"
      >
      </canvas>
    </div>
    <v-btn
      class="no-print"
      v-scroll="onScroll"
      v-show="toTopVisible"
      fab
      fixed
      bottom
      right
      color="tertiary"
      @click="toPageTop"
      dark
    >
      <v-icon>mdi-arrow-up</v-icon>
    </v-btn>
    <v-dialog
      v-if="emailReportDialog"
      v-model="emailReportDialog"
      :max-width="sendReportDialogSize"
    >
      <SendReportDialog
        @inputClose="$emit('closeEmailReportDialog')"
        :assessmentID="assessmentId"
        assessmentType="Burnout assessment"
      />
    </v-dialog>
    <p
      id="pdfHeader"
      class="text-subtitle-2 pdf-header mb-1 justify-space-between"
      v-show="false"
    >
      <span>{{ assessmentEmail || userEmail }}</span>
      <span>
        {{
          $t("deskAssessment.results.report.pdfDownloadText", {
            completedAt: completedAtDate
          })
        }}</span
      >
    </p>
    <v-row
      justify="center"
      :class="$vuetify.breakpoint.smAndDown ? 'px-4' : ''"
    >
      <vfl-panels
        flat
        v-model="panelExpansion"
        multiple
        hover
        :style="$vuetify.breakpoint.mdAndUp ? 'width:90%' : ''"
      >
        <template v-slot:panels
          ><assessment-report-panel
            :title="$t('burnoutReportPanelTitles.summary')"
          >
            <template v-slot:content>
              <BurnoutSummaryContent :results="results"
            /></template>
          </assessment-report-panel>

          <assessment-report-panel
            :title="$t('burnoutReportDetailsCards.energyLevel.title')"
          >
            <template v-slot:content>
              <BurnoutDetailsContent
                :results="fullResults"
                :assessmentId="assessmentId"
                category="exhaustion"
                :cards="burnoutRecommendations.exhaustion"
              />
            </template>
          </assessment-report-panel>

          <assessment-report-panel
            :title="$t('burnoutReportDetailsCards.mentalClarity.title')"
          >
            <template v-slot:content
              ><BurnoutDetailsContent
                :results="fullResults"
                :assessmentId="assessmentId"
                category="cognitiveimpairment"
                :cards="burnoutRecommendations.cognitiveimpairment"
            /></template>
          </assessment-report-panel>

          <assessment-report-panel
            :title="$t('burnoutReportDetailsCards.emotionalWellbeing.title')"
          >
            <template v-slot:content
              ><BurnoutDetailsContent
                :results="fullResults"
                :assessmentId="assessmentId"
                category="emotionalimpairment"
                :cards="burnoutRecommendations.emotionalimpairment"
            /></template>
          </assessment-report-panel>

          <assessment-report-panel
            :title="$t('burnoutReportDetailsCards.mentalEngagement.title')"
          >
            <template v-slot:content
              ><BurnoutDetailsContent
                :results="fullResults"
                :assessmentId="assessmentId"
                category="mentaldistance"
                :cards="burnoutRecommendations.mentaldistance"
            /></template>
          </assessment-report-panel>

          <assessment-report-panel
            :title="$t('burnoutReportDetailsCards.physicalWellbeing.title')"
          >
            <template v-slot:content
              ><BurnoutDetailsContent
                :results="fullResults"
                :assessmentId="assessmentId"
                category="secondarysymptoms"
                :cards="burnoutRecommendations.secondarysymptoms"
            /></template>
          </assessment-report-panel>

          <assessment-report-panel
            v-if="hasFixedRecommendations"
            :title="$t('burnoutReportPanelTitles.extraContent')"
          >
            <template v-slot:content>
              <RecommendationsContent
                :results="results"
                :assessmentId="assessmentId"
                :cards="fixedRecommendationCards"
            /></template>
          </assessment-report-panel>

          <assessment-report-panel
            :title="$t('burnoutReportPanelTitles.knowledgeBase')"
            class="no-print"
          >
            <template v-slot:content>
              <BurnoutKnowledgeBaseContent :results="results"
            /></template>
          </assessment-report-panel>
        </template>
      </vfl-panels>

      <report-footer
        v-if="viewingOwnResults"
        class="no-print"
        :assessmentId="assessmentId"
        assessmentType="Burnout assessment"
        @closeReport="$emit('closeReport')"
        :reportContext="reportContext"
      />
    </v-row>
  </v-container>
</template>

<script>
import Vue from "vue";
import VueConfetti from "vue-confetti";
Vue.use(VueConfetti);

import SendReportDialog from "@/components/deskassessment/report/SendReportDialog.vue";
import {
  getBurnoutRecommendations,
  getGeneratedBurnoutRecommendations,
  getBurnoutFixedRecommendations
} from "@/services/recommendations/recommendations-service.js";
import BurnoutScores from "@/services/burnout/burnout-scores.js";
import BurnoutValues from "@/assets/json/BurnoutAssessment/BurnoutAssessmentCategoryValues";
import { mapGetters } from "vuex";
import { GetLatestDeskAssessmentResult } from "@/services/modules/api-queries.js";
import EndOfReportSignUp from "@/components/authentication/EndOfReportSignUp.vue";
import { viewingOwnResults } from "@/services/privacy-service.js";
import { addAssessmentRecommendations } from "@/customApi";

import VflPanels from "@/components/vitrue-foundation-library/expansion-panel/VflPanels.vue";
import AssessmentReportPanel from "@/components/common/report/AssessmentReportPanel.vue";
import ReportFooter from "@/components/common/report/ReportFooter.vue";

const BurnoutSummaryContent = () =>
  import("@/components/burnout-assessment/report/content/SummaryContent.vue");
const BurnoutDetailsContent = () =>
  import("@/components/burnout-assessment/report/content/DetailsContent.vue");
const RecommendationsContent = () =>
  import(
    "@/components/burnout-assessment/report/content/RecommendationsContent.vue"
  );
const BurnoutKnowledgeBaseContent = () =>
  import(
    "@/components/burnout-assessment/report/content/KnowledgeBaseContent.vue"
  );

export default {
  props: {
    results: Object,
    assessmentId: String,
    emailReportDialog: Boolean,
    signUpErrorMessage: String,
    assessmentEmail: String,
    reportContext: {
      type: String,
      required: true,
      validator: value => ["assessment", "archive"].includes(value)
    }
  },
  components: {
    ReportFooter,
    SendReportDialog,
    EndOfReportSignUp,
    VflPanels,
    AssessmentReportPanel,
    BurnoutSummaryContent,
    BurnoutDetailsContent,
    RecommendationsContent,
    BurnoutKnowledgeBaseContent
  },
  data() {
    return {
      panelExpansion: [0, 1, 2, 3, 4],
      toTopVisible: false,
      fullResults: this.results,
      scores: BurnoutScores.getScores(this.results),
      reportHeight: 0,
      reportWidth: 0,
      deskAssessmentResults: null
    };
  },
  watch: {
    results() {
      this.scores = BurnoutScores.getScores(this.results);
    },
    signedIn() {
      this.updateLatestDeskAssessment();
    }
  },
  mounted() {
    this.panelExpansion = [0, 1, 2, 3, 4, 5, 6];
    if (this.hasFixedRecommendations) {
      this.panelExpansion.push(7);
    }

    this.updateLatestDeskAssessment();
    this.saveRecommendations();
    this.$nextTick(() => {
      this.setupConfetti();
    });
  },
  computed: {
    ...mapGetters([
      "customRecommendations",
      "redirectReportAssessment",
      "signedIn",
      "userEmail"
    ]),
    viewingOwnResults() {
      return viewingOwnResults(this.$route);
    },
    sendReportDialogSize() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return "100%";
      }

      if (this.$vuetify.breakpoint.mdAndDown) {
        return "60%";
      }

      return "30%";
    },
    fixedRecommendationCards() {
      return getBurnoutFixedRecommendations(this.customRecommendations);
    },
    burnoutRecommendations() {
      let recommendations = getGeneratedBurnoutRecommendations(
        this.scores,
        this.fullResults,
        this.customRecommendations
      );

      return recommendations;
    },
    hasFixedRecommendations() {
      return (
        this.fixedRecommendationCards &&
        this.fixedRecommendationCards.length > 0
      );
    },
    completedAtDate() {
      let date = new Date(this.results.completedAt);
      if (isNaN(date)) {
        date = new Date();
      }
      return date.toLocaleDateString();
    }
  },
  methods: {
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.toTopVisible = top > 20;
    },
    toPageTop() {
      this.$vuetify.goTo(0);
    },
    setupConfetti() {
      let selfAssessment = this.signedIn && !this.results.completedAt;
      let justCompletedReport = selfAssessment || this.redirectReportAssessment;
      if (
        this.scores.overall < BurnoutValues.overall.average &&
        justCompletedReport
      ) {
        this.reportHeight = window.innerHeight;
        this.reportWidth = window.innerWidth;

        var particlesPerFrame = 2;
        if (this.$vuetify.breakpoint.smAndDown) {
          particlesPerFrame = 0.5;
        } else if (this.$vuetify.breakpoint.mdAndDown) {
          particlesPerFrame = 1;
        }

        this.$confetti.start({
          canvasElement: document.getElementById("confetti-canvas"),
          particlesPerFrame: particlesPerFrame,
          particles: [
            {
              type: "rect"
            }
          ]
        });

        setTimeout(() => {
          this.$confetti.stop();
        }, 3000);
      }
    },
    async updateLatestDeskAssessment() {
      if (this.signedIn) {
        let deskResults = GetLatestDeskAssessmentResult();
        this.fullResults = {
          ...this.results,
          ...deskResults
        };
      }
    },
    async saveRecommendations() {
      if (this.results.completedAt) {
        return;
      }

      var allRecommendations = getBurnoutRecommendations(
        this.scores,
        this.fullResults,
        this.customRecommendations
      );

      let recommendationsDto = {
        assessmentId: this.assessmentId,
        recommendations: allRecommendations.map(x => x.id)
      };

      await addAssessmentRecommendations(recommendationsDto);
    }
  }
};
</script>

<style scoped>
.sticky {
  position: -webkit-sticky; /* Required for Safari */
  position: sticky;
  top: 0px;
}

@media print {
  .pdf-header {
    display: flex !important;
  }
}
</style>
