<template>
  <user-profile-block>
    <template #title
      ><span>Adjustments</span>
      <button
        v-if="!isLoading"
        @click="addAdjustment"
        class="vfl-button-link ml-auto"
        style="text-decoration: none"
      >
        <v-icon size="14" color="vflPrimary">mdi-plus</v-icon>Add adjustment
      </button></template
    >
    <template #content>
      <div v-if="isLoading">
        <v-skeleton-loader type="text@3" />
      </div>
      <div v-else>
        <div
          class="mb-1"
          v-for="adjustment in adjustments"
          :key="adjustment.note + adjustment.date"
        >
          <p class="mb-4 hover-show-button">
            {{ adjustment.note }}
            <button
              class="sp-button ml-2"
              @click="removeAdjustment(adjustment)"
            >
              Remove
            </button>
          </p>
        </div>
      </div>
      <v-dialog v-model="isAddingAdjustment" width="480">
        <v-card>
          <v-card-title class="mb-4">Add adjustment</v-card-title>
          <v-card-text>
            <h3 class="mb-2">Name</h3>
            <v-text-field
              label=""
              v-model="newAdjustmentName"
              clearable
              outlined
              class="sp-text-area"
              hide-details
            ></v-text-field>

            <div class="d-flex align-center justify-end">
              <button
                class="sp-cancel-button mr-2"
                @click="cancelAddAdjustment"
              >
                Cancel
              </button>
              <button
                class="sp-add-button"
                @click="addNewAdjustment"
                :disabled="!newAdjustmentName.trim()"
              >
                Save
              </button>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </template>
  </user-profile-block>
</template>

<script>
import UserProfileBlock from "@/components/user-profile/UserProfileBlock.vue";
import { mapGetters } from "vuex";
import dummyData from "@/components/user-profile/dummy-data.json";

export default {
  name: "UserProfileAdjustment",
  components: { UserProfileBlock },
  data() {
    return {
      adjustments: dummyData.adjustments,
      isAddingAdjustment: false,
      newAdjustmentName: ""
    };
  },
  computed: {
    ...mapGetters("userProfile", ["loadingStates"]),
    isLoading() {
      return this.loadingStates.notes;
    },

    todayDate() {
      const date = new Date();
      let day = date.getDate().toString().padStart(2, "0");
      let month = (date.getMonth() + 1).toString().padStart(2, "0"); // getMonth() is zero-based
      let year = date.getFullYear().toString().slice(2); // Get last two digits of the year
      return `${day}/${month}/${year}`; // Formats date as 'DD/MM/YY'
    }
  },
  methods: {
    removeAdjustment(adjustment) {
      this.adjustments = this.adjustments.filter(
        a => a.note !== adjustment.note
      );
    },
    addAdjustment() {
      this.isAddingAdjustment = true;
    },
    cancelAddAdjustment() {
      this.isAddingAdjustment = false;
      this.newAdjustmentName = "";
    },
    addNewAdjustment() {
      if (this.newAdjustmentName.trim()) {
        const newAdjustment = {
          note: this.newAdjustmentName,
          date: this.todayDate
        };
        this.adjustments.push(newAdjustment);
        this.cancelAddAdjustment();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.sp-button {
  color: var(--v-vflPrimary-base);
  font-weight: 600;
  font-size: 14px;
}

.hover-show-button {
  .sp-button {
    opacity: 0;
    transition: opacity 0.125s ease-out;
  }

  &:hover .sp-button {
    opacity: 1;
  }
}

.sp-text-area {
  border-radius: 8px;
  margin-bottom: 0.5rem;
  line-height: 1.3;
}

.sp-cancel-button {
  font-size: 0.875rem;
  border: 1px solid #ddd;
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
}

.sp-add-button {
  background: var(--v-vflPrimary-base);
  color: white;
  font-size: 0.875rem;
  border: 1px solid var(--v-vflPrimary-base);
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  cursor: pointer;

  &[disabled] {
    cursor: default;
    opacity: 0.5;
  }
}
</style>
