import SymptomsRecommendations from "@/components/assessment/menopause/report/recommendations/SymptomsRecommendations.json";
import { LinkTypes } from "@/services/recommendations/link-types";
import { hasSymptoms } from "@/components/assessment/menopause/report/helpers.js";

const SYMPTOM_TYPES = {
  HOT_FLUSHES: 1,
  LACK_OF_ENERGY: 2,
  MOOD_SWINGS: 3,
  CONCENTRATION: 4,
  MEMORY_ISSUES: 5,
  TROUBLE_SLEEPING: 6
};

const cardGroups = {
  hotFlushes: {
    titleKey:
      "menopauseAssessment.report.recommendations.symptoms.groups.hotFlushes.title",
    cards: {
      menopause_dress_in_layers: SYMPTOM_TYPES.HOT_FLUSHES,
      menopause_portable_fan: SYMPTOM_TYPES.HOT_FLUSHES,
      menopause_avoid_spicy_food: SYMPTOM_TYPES.HOT_FLUSHES,
      menopause_cool_water_wrists: SYMPTOM_TYPES.HOT_FLUSHES
    }
  },
  lackOfEnergy: {
    titleKey:
      "menopauseAssessment.report.recommendations.symptoms.groups.lackOfEnergy.title",
    cards: {
      menopause_screen_time: SYMPTOM_TYPES.LACK_OF_ENERGY,
      menopause_wind_down_stretch: SYMPTOM_TYPES.LACK_OF_ENERGY,
      menopause_processed_food: SYMPTOM_TYPES.LACK_OF_ENERGY,
      menopause_dehydration: SYMPTOM_TYPES.LACK_OF_ENERGY,
      menopause_caffeine: SYMPTOM_TYPES.LACK_OF_ENERGY
    }
  },
  moodSwings: {
    titleKey:
      "menopauseAssessment.report.recommendations.symptoms.groups.moodSwings.title",
    cards: {
      menopause_warm_shower: SYMPTOM_TYPES.MOOD_SWINGS,
      menopause_group_connection: SYMPTOM_TYPES.MOOD_SWINGS,
      menopause_herbal_tea: SYMPTOM_TYPES.MOOD_SWINGS,
      menopause_low_sugar_food: SYMPTOM_TYPES.MOOD_SWINGS
    }
  },
  concentration: {
    titleKey:
      "menopauseAssessment.report.recommendations.symptoms.groups.concentration.title",
    cards: {
      menopause_smaller_tasks: SYMPTOM_TYPES.CONCENTRATION,
      menopause_visual_reminders_and_lists: SYMPTOM_TYPES.CONCENTRATION,
      menopause_brain_teasers: SYMPTOM_TYPES.CONCENTRATION
    }
  },
  memoryIssues: {
    titleKey:
      "menopauseAssessment.report.recommendations.symptoms.groups.memoryIssues.title",
    cards: {
      menopause_daily_journal: SYMPTOM_TYPES.MEMORY_ISSUES,
      menopause_memory_triggers: SYMPTOM_TYPES.MEMORY_ISSUES,
      menopause_digital_reminders: SYMPTOM_TYPES.MEMORY_ISSUES
    }
  },
  troubleSleeping: {
    titleKey:
      "menopauseAssessment.report.recommendations.symptoms.groups.troubleSleeping.title",
    cards: {
      menopause_limit_fluid_evening: SYMPTOM_TYPES.TROUBLE_SLEEPING,
      menopause_avoid_heavy_meals: SYMPTOM_TYPES.TROUBLE_SLEEPING,
      menopause_aromatherapy: SYMPTOM_TYPES.TROUBLE_SLEEPING,
      menopause_reduce_light_noise: SYMPTOM_TYPES.TROUBLE_SLEEPING
    }
  }
};

const mapCardToRecommendation = (cardId, cardData) => ({
  id: cardId,
  json: {
    imageName: cardData.imageName,
    heading: cardData.headingKey,
    subheading: cardData.subheadingKey,
    explanation: cardData.descriptionKey
  },
  linkType: LinkTypes.REGULAR
});

const getVisibleCardsForGroup = (groupCards, results) => {
  const symptomsList = results?.symptoms?.symptoms || [];

  return Object.entries(groupCards)
    .filter(([_, requiredSymptom]) => symptomsList.includes(requiredSymptom))
    .map(([cardId]) =>
      mapCardToRecommendation(cardId, SymptomsRecommendations[cardId])
    );
};

const hasVisibleCards = (group, results) => {
  const symptomsList = results?.symptoms?.symptoms || [];
  return Object.values(group.cards).some(requiredSymptom =>
    symptomsList.includes(requiredSymptom)
  );
};

export const getSymptomsRecommendations = results => {
  if (!hasSymptoms(results)) {
    return {
      groups: {}
    };
  }

  const safeResults = {
    symptoms: {
      symptoms: results?.symptoms?.symptoms || []
    }
  };

  const visibleGroups = Object.entries(cardGroups).reduce(
    (acc, [groupId, group]) => {
      if (hasVisibleCards(group, safeResults)) {
        acc[groupId] = {
          titleKey: group.titleKey,
          cards: getVisibleCardsForGroup(group.cards, safeResults)
        };
      }
      return acc;
    },
    {}
  );

  return {
    groups: visibleGroups
  };
};
