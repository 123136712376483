<template>
  <user-profile-block>
    <template #title
      ><span>{{ $t("userProfile.notes.title") }}</span>
      <button
        v-if="!isLoading"
        @click="toggleAddNote"
        class="vfl-button-link ml-auto"
        style="text-decoration: none"
      >
        <template v-if="!isAddingNote">
          <v-icon size="14" color="vflPrimary">mdi-plus</v-icon
          >{{ $t("userProfile.notes.addNotes") }}
        </template>
        <template v-else> {{ $t("userProfile.notes.cancel") }} </template>
      </button></template
    >
    <template #content
      ><div v-if="notes?.length">
        <div v-for="note in notes" :key="note.id" class="note mb-2">
          <p class="text-body-dark mb-0">{{ note.text }}</p>

          <div class="note-footer d-flex align-center mt-n2">
            <p class="note-details mb-0 text-caption d-flex align-center">
              <span>{{ getAuthor(note) }}</span>
              <span class="separator"></span>
              <span>{{ getFormattedDate(note) }}</span>
            </p>

            <v-tooltip
              :disabled="canDeleteNote(note)"
              top
              min-width="200"
              nudge-bottom="10"
            >
              <template v-slot:activator="{ on, attrs }">
                <button
                  v-on="on"
                  v-bind="attrs"
                  class="ml-auto delete-note-button vfl-button-primary-ghost is-icon-only"
                  :class="{
                    'is-disabled': isDeletingNote || !canDeleteNote(note)
                  }"
                  @click="handleDeleteNote(note)"
                >
                  <v-icon>mdi-trash-can-outline</v-icon>
                  <span class="d-sr-only">{{
                    $t("userProfile.notes.deleteNote")
                  }}</span>
                </button>
              </template>
              {{ $t("userProfile.notes.authorTooltip") }}
            </v-tooltip>
          </div>
        </div>
      </div>
      <div v-else-if="isLoading">
        <v-skeleton-loader type="text@3" />
      </div>
      <user-profile-add-note
        v-if="allDataLoaded && isAddingNote"
        @note-added="handleNoteAdded"
    /></template>
  </user-profile-block>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import UserProfileBlock from "@/components/user-profile/UserProfileBlock.vue";
import UserProfileAddNote from "@/components/user-profile/UserProfileAddNote.vue";
import { formatDateWithOrdinal } from "@/services/date-formatting-service.js";
import VflTooltip from "@/components/vitrue-foundation-library/VflTooltip.vue";
import { sendEvent } from "@/services/analytics/analytics.js";

export default {
  name: "UserProfileNotes",
  components: { VflTooltip, UserProfileAddNote, UserProfileBlock },
  data() {
    return {
      isAddingNote: false
    };
  },
  computed: {
    ...mapGetters({
      userName: "userName",
      userEmail: "userEmail",
      adminId: "userId"
    }),
    ...mapGetters("userProfile", [
      "id",
      "notes",
      "loadingStates",
      "allDataLoaded",
      "isSavingNote",
      "isDeletingNote"
    ]),
    isLoading() {
      return this.loadingStates.notes;
    }
  },
  methods: {
    ...mapActions("userProfile", ["deleteNote"]),
    async handleDeleteNote(note) {
      if (!this.canDeleteNote(note)) return;

      try {
        await this.deleteNote({ userId: this.id, noteId: note.id });

        sendEvent("Delete note clicked in user profile", {
          end_user: this.id,
          admin: this.adminId
        });
      } catch (error) {
        this.$logger.captureException(error);
      }
    },
    toggleAddNote() {
      sendEvent("Add notes clicked in user profile", {
        end_user: this.id,
        admin: this.adminId
      });

      this.isAddingNote = !this.isAddingNote;
    },
    handleNoteAdded() {
      sendEvent("Create new note in user profile", {
        end_user: this.id,
        admin: this.adminId
      });
      this.isAddingNote = false;
    },
    getFormattedDate({ createdAt }) {
      return createdAt ? formatDateWithOrdinal(createdAt) : "";
    },
    getAuthor({ authorName, authorEmail }) {
      if (this.userEmail === authorEmail) {
        return this.$t("userProfile.notes.you");
      }

      return authorName || authorEmail;
    },
    canDeleteNote({ authorEmail }) {
      return this.userEmail === authorEmail;
    }
  }
};
</script>

<style scoped lang="scss">
.note {
  &:hover {
    .delete-note-button {
      opacity: 1;
      pointer-events: auto;
    }
  }
}
.note-details {
  color: var(--v-vflGreyLight-base);

  .separator {
    background: currentColor;
    border-radius: 999px;
    display: inline-block;
    height: 2px;
    margin: 0 0.25rem;
    width: 2px;
  }
}

.delete-note-button {
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.125s ease-out;

  i {
    color: var(--v-vflPrimary-base);
  }

  &.is-disabled {
    cursor: default;
    opacity: 0.6 !important;
    pointer-events: auto;

    &:hover {
      background: none;
    }
  }
}
</style>
