export const preFillResultsForTesting = () => {
  return {
    vehicle: {
      type: 2,
      transmission: 1
    },
    drivingPosture: {
      horizontalUpperLegs: 2,
      seatFlat: 2,
      kneeClearsSeat: 1,
      upperLegsSupported: 1,

      backSupported: 2,
      leaningForwards: 2,
      pedalsWithinReach: 1,

      deadPedalUsed: 2,
      feetRelaxed: 1,

      mirrorsEasilyVisible: 1,
      roadVisibleOverWheel: 1,
      dashboardReadable: 1,
      headrestSupportsHead: 2,
      sufficientHeadroom: 1,
      wheelDashboardView: 1,

      wheelElbowBend: 1,
      wheelGripTension: 1,

      trunkLoadWithoutBending: 1,
      vanFixingLoads: 1
    },
    habits: {
      journeyLength: 1,
      takeBreaks: 1,
      planRoute: 1,
      breaksExercise: 1,
      severeWeather: 1
    },
    pain: {
      areas: {}
    },
    health: {}
  };
};
