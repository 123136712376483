import sentry from "@/sentry";

// targetOrigin as '*' removes any restriction on origin and sends to all windows
// Do not put sensitive data in the message
export default {
  sendMessage(message, targetOrigin = '*') {
    const targetWindow = window.opener ? window.opener : window.parent;

    if (targetWindow) {
      targetWindow.postMessage(message, targetOrigin);
    } else {
      sentry.captureException("Target window is not available");
    }
  },
};