<template>
  <step-introduction
    :title="title"
    localeRoot="physicalLabourAssessment"
    @next="next"
  >
    <div class="d-flex flex-wrap" style="gap: 1.5rem">
      <div class="image">
        <img
          :src="`/images/physical-labour-assessment/sectors/${sectorName}.svg`"
        />
      </div>
      <div style="flex: 1 1 300px">
        <p v-html="fact"></p>
        <p
          v-html="
            $t(`physicalLabourAssessment.stepIntroduction.pain.promotion`)
          "
        ></p>
      </div>
    </div>
  </step-introduction>
</template>

<script>
import StepIntroduction from "@/components/assessment/StepIntroduction.vue";
import { getSectorNameFromValue } from "@/components/assessment/physical-labour/utils/sector.js";

export default {
  components: { StepIntroduction },
  props: {
    results: Object
  },
  computed: {
    sectorName() {
      return getSectorNameFromValue(this.results.sectors.type);
    },
    title() {
      return this.$t(
        `physicalLabourAssessment.stepIntroduction.pain.sector.title.${this.sectorName}`
      );
    },
    fact() {
      return this.$t(
        `physicalLabourAssessment.stepIntroduction.pain.sector.fact.${this.sectorName}`
      );
    }
  },
  methods: {
    next() {
      this.$emit("next");
    }
  }
};
</script>

<style lang="scss" scoped>
.image {
  border-radius: 8px;
  display: grid;
  height: 160px;
  place-content: center;
  position: relative;
  width: 200px;

  &:before {
    background: var(--v-vflBlueVeryLight-base);
    border: 1px solid var(--v-vflGreyVeryLight-base);
    border-radius: inherit;
    bottom: 0;
    content: "";
    left: 0;
    opacity: 0.4;
    position: absolute;
    right: 0;
    top: 0;
  }

  > img {
    max-width: 100px;
    position: relative;
  }
}
</style>
