<template>
  <span v-if="isSending" class="text-caption vflGreyLight--text"
    >sending...</span
  >
  <time
    v-else
    class="message-timestamp text-caption vflGreyLight--text"
    :datetime="timestamp"
  >
    {{ formattedTimestamp }}
  </time>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "MessagingMessageTimestamp",
  props: {
    timestamp: {
      type: Date,
      required: true
    },
    messageId: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters("messagingStore", ["isSendingMessage"]),
    isSending() {
      return this.isSendingMessage(this.messageId);
    },
    formattedTimestamp() {
      const now = new Date();
      const diffTime = Math.abs(now - this.timestamp);
      const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

      if (diffDays === 0) {
        return "Today";
      } else if (diffDays === 1) {
        return "Yesterday";
      } else {
        return `${diffDays} days ago`;
      }
    }
  }
};
</script>

<style scoped>
.message-timestamp {
}
</style>
