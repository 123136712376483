export const mskHealthCultureCardMapping = [
  {
    key: "percentageThinkPostureAffectsPain",
    label: "mskHealthCulture.cards.percentageThinkPostureAffectsPain.label",
    imageUrl: "/images/illustrations/msk-health-culture/posture-and-pain.svg",
    dummyValue: 12
  },
  {
    key: "averageHoursSpentAtDesk",
    label: "mskHealthCulture.cards.averageHoursSpentAtDesk.label",
    imageUrl: "/images/illustrations/msk-health-culture/long-hours.svg",
    dummyValue: 8
  },
  {
    key: "percentageWithWater",
    label: "mskHealthCulture.cards.percentageWithWater.label",
    tooltipText: "mskHealthCulture.cards.percentageWithWater.tooltip",
    imageUrl: "/images/illustrations/msk-health-culture/water-intake.svg",
    dummyValue: 46
  },
  {
    key: "percentageLunchAwayFromDesk",
    label: "mskHealthCulture.cards.percentageLunchAwayFromDesk.label",
    imageUrl: "/images/illustrations/msk-health-culture/lunch.svg",
    dummyValue: 67
  },
  {
    key: "percentageRegularBreaks",
    label: "mskHealthCulture.cards.percentageRegularBreaks.label",
    imageUrl: "/images/illustrations/msk-health-culture/breaks.svg",
    dummyValue: 56
  },
  {
    key: "percentageActiveBreaks",
    label: "mskHealthCulture.cards.percentageActiveBreaks.label",
    imageUrl: "/images/illustrations/msk-health-culture/activity.svg",
    dummyValue: 30
  },
  {
    key: "percentageExercisingMoreThanThreeTimesAWeek",
    label:
      "mskHealthCulture.cards.percentageExercisingMoreThanThreeTimesAWeek.label",
    imageUrl: "/images/illustrations/msk-health-culture/exercise.svg",
    dummyValue: 12
  }
];
