<template>
  <div>
    <v-tooltip
      bottom
      v-for="button in buttons"
      :key="button.id"
      color="transparent"
    >
      <template v-slot:activator="{ on, attrs }">
        <button
          @click="onClick(button.id)"
          v-bind="attrs"
          v-on="on"
          :aria-label="button.description"
        >
          <span class="d-sr-only">{{ button.description }}</span>
          <v-icon size="18">
            {{ button.id === "positive" ? positiveIcon : negativeIcon }}
          </v-icon>
        </button>
      </template>
      <vitrue-tooltip maxWidth="350px" :message="button.description" />
    </v-tooltip>
  </div>
</template>

<script>
import VitrueTooltip from "@/components/common/VitrueTooltip.vue";
const BUTTON_IDS = {
  POSITIVE: "positive",
  NEGATIVE: "negative"
};

export default {
  name: "FeedbackThumbButtons",
  components: {
    VitrueTooltip
  },
  data() {
    return {
      positiveResponse: false,
      negativeResponse: false,
      buttons: [
        {
          id: BUTTON_IDS.POSITIVE,
          value: true,
          description: this.$t("aiAssistant.feedback.goodResponse"),
          icon: {
            default: "mdi-thumb-up-outline",
            submitted: "mdi-thumb-up"
          }
        },
        {
          id: BUTTON_IDS.NEGATIVE,
          value: false,
          description: this.$t("aiAssistant.feedback.badResponse"),
          icon: {
            default: "mdi-thumb-down-outline",
            submitted: "mdi-thumb-down"
          }
        }
      ]
    };
  },
  computed: {
    positiveIcon() {
      return this.positiveResponse
        ? this.getButtonById(BUTTON_IDS.POSITIVE).icon.submitted
        : this.getButtonById(BUTTON_IDS.POSITIVE).icon.default;
    },
    negativeIcon() {
      return this.negativeResponse
        ? this.getButtonById(BUTTON_IDS.NEGATIVE).icon.submitted
        : this.getButtonById(BUTTON_IDS.NEGATIVE).icon.default;
    }
  },
  methods: {
    getButtonById(id) {
      return this.buttons.find(button => button.id === id);
    },
    onClick(value) {
      if (
        (value === BUTTON_IDS.POSITIVE && this.positiveResponse) ||
        (value === BUTTON_IDS.NEGATIVE && this.negativeResponse)
      ) {
        // Button already selected
        return;
      }

      if (value === BUTTON_IDS.POSITIVE) {
        this.positiveResponse = true;
        this.negativeResponse = false;
      } else {
        this.positiveResponse = false;
        this.negativeResponse = true;
      }

      this.$emit("feedbackSubmitted", value === BUTTON_IDS.POSITIVE);
    }
  }
};
</script>

<style lang="scss" scoped>
div {
  align-items: center;
  display: inline-flex;
  gap: 0.75rem;
  line-height: 1;
}
button {
  position: relative;
  transition: transform 0.125s ease-out;

  &:after {
    bottom: -0.25rem;
    content: "";
    left: -0.25rem;
    position: absolute;
    right: -0.25rem;
    top: -0.25rem;
  }

  &:hover {
    transform: scale(1.1);

    i {
      color: var(--v-vflPrimary-base);
    }
  }
}
</style>
