<template>
  <div class="user-profile-pain-container">
    <vfl-chip
      v-for="entry in sortedPain"
      :key="entry.area"
      :label="getPainText(entry)"
      v-bind="getChipStyles(entry.level)"
    />
  </div>
</template>

<script>
import VflChip from "@/components/vitrue-foundation-library/VflChip.vue";
import { mapGetters } from "vuex";
import { PainColors, PainLevel } from "@/components/user-profile/constants.js";

export default {
  name: "UserProfilePain",
  components: { VflChip },
  computed: {
    ...mapGetters("userProfile", ["pain"]),
    sortedPain() {
      return [...this.pain].sort((a, b) => b.level - a.level);
    }
  },
  methods: {
    getPainLevel(level) {
      if (level >= PainLevel.SEVERE.min) return "SEVERE";
      if (level >= PainLevel.MODERATE.min) return "MODERATE";
      return "MILD";
    },
    getChipStyles(level) {
      const painLevel = this.getPainLevel(level);

      return {
        backgroundColor: PainColors[painLevel].background,
        borderColor: PainColors[painLevel].border
      };
    },
    getPainText({ area, level }) {
      const bodyArea = this.$t(`reportText.pain.painAreaNames.${area}.title`);
      const painLevel = this.getPainLevel(level).toLowerCase();
      const intensity = this.$t(`painIntensity.${painLevel}`);

      return this.$t("userProfile.painChip", {
        area: bodyArea,
        intensity: intensity
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.user-profile-pain-container {
  display: contents;
}
</style>
