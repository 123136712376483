<template>
  <v-tooltip
    v-model="tooltipVisible"
    ref="tooltip"
    :top="top"
    :bottom="bottom"
    color="transparent"
    :open-on-click="openOnClick"
    :open-on-hover="openOnHover"
    :open-delay="100"
  >
    <template v-slot:activator="{ on, attrs }">
      <slot name="activator" v-bind="{ on, attrs }"></slot>
    </template>
    <div ref="tooltipContent">
      <slot name="content" id="tooltipContent"></slot>
    </div>
  </v-tooltip>
</template>

<script>
export default {
  name: "vfl-tooltip",
  data() {
    return { tooltipVisible: false, ignoreClose: false };
  },
  props: {
    top: Boolean,
    bottom: Boolean,
    openOnHover: {
      type: Boolean,
      default: true
    },
    openOnClick: Boolean
  },
  methods: {
    handleClickOutside(event) {
      if (!this.openOnClick) {
        return;
      }

      if (this.ignoreClose) {
        this.ignoreClose = false;
        return;
      }

      const tooltip = this.$refs.tooltip;
      const tooltipContent = this.$refs.tooltipContent;

      if (tooltip && tooltipContent && !tooltipContent.contains(event.target)) {
        this.tooltipVisible = false;
      }
    },
    showTooltip() {
      this.tooltipVisible = true;
      this.ignoreClose = true;
    }
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.handleClickOutside);
  }
};
</script>

<style scoped>
.v-tooltip__content {
  opacity: 1 !important;
  pointer-events: auto !important;
}
</style>
