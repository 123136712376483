<template>
  <span>
    <img src="/images/icons/gradient-sparkles.svg" alt="" aria-hidden="true" />
  </span>
</template>
<script>
export default {
  name: "AiAssistantBubbleIcon"
};
</script>
<style lang="scss" scoped>
span {
  align-items: center;
  display: inline-flex;
  height: 24px;
  flex: 0 0 24px;
  justify-content: center;
  position: relative;

  &:before {
    background-image: linear-gradient(
      to bottom right,
      var(--v-vflPrimary-base) 70%,
      var(--v-vflPurple-base)
    );
    border-radius: 9999px;
    bottom: 0;
    content: "";
    left: 0;
    opacity: 0.1;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
  }
}

img {
  position: relative;
}
</style>
