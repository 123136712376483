<template>
  <v-card
    v-if="showCard"
    id="featureIntroductionCard"
    class="card rounded-xl"
    max-width="500"
  >
    <v-btn
      class="close-button"
      color="black"
      style="top: 10px; right: 10px"
      icon
      @click="closeCard"
    >
      <v-icon>mdi-close</v-icon>
    </v-btn>
    <img v-if="imageLink" :src="imageLink" />
    <iframe
      v-else-if="loomLink"
      :src="loomLink"
      height="275"
      width="100%"
      frameborder="0"
      webkitallowfullscreen
      mozallowfullscreen
      allowfullscreen
    ></iframe>
    <v-card-title class="pb-2 pt-6 text-h5 font-weight-bold">{{
      title
    }}</v-card-title>
    <v-card-text class="pb-2 text-body-1"> {{ description }}</v-card-text>
    <v-card-actions class="pb-6 px-4">
      <button
        @click="markAsComplete"
        class="vfl-button-primary is-small"
        style="min-width: 100px; cursor: pointer"
      >
        {{ actionButtonText ?? $t("featureIntroductionCard.gotIt") }}
      </button>
      <span
        class="vflPrimary--text text-decoration-underline ml-4"
        style="cursor: pointer"
        @click="selectNotNow"
        >{{ $t("featureIntroductionCard.later") }}</span
      ></v-card-actions
    >
  </v-card>
</template>

<script>
import FeatureService from "@/services/new-feature-service.js";
import anime from "animejs";
import { mapMutations } from "vuex";
export default {
  data() {
    return {
      showCard: true
    };
  },
  props: {
    featureKey: String,
    title: String,
    loomLink: String,
    imageLink: String,
    description: String,
    actionButtonText: String,
    customCompleteFunction: Function
  },
  created() {
    this.addVisibleFeatureIntro(this.featureKey);
  },
  methods: {
    ...mapMutations(["addVisibleFeatureIntro", "removeVisibleFeatureIntro"]),
    async closeCard() {
      this.$mixpanel.track("Closed feature introduction", {
        featureKey: this.featureKey
      });
      await FeatureService.markFeatureSeen(this.featureKey, true);
      this.hideCardAnimation();
      this.removeVisibleFeatureIntro(this.featureKey);
    },
    async markAsComplete() {
      if (!!this.customCompleteFunction) {
        this.customCompleteFunction();
      }
      this.$mixpanel.track("Marked feature introduction as complete", {
        featureKey: this.featureKey
      });
      await FeatureService.markFeatureSeen(this.featureKey, true);
      this.hideCardAnimation();
      this.removeVisibleFeatureIntro(this.featureKey);
    },
    selectNotNow() {
      this.$mixpanel.track("Clicked not now on feature introduction", {
        featureKey: this.featureKey
      });
      this.hideCardAnimation();
    },
    hideCardAnimation() {
      let vm = this;
      let card = document.getElementById("featureIntroductionCard");
      anime({
        targets: card,
        translateY: 200,
        opacity: 0,
        duration: 2000,
        easing: "easeOutExpo",
        complete: function () {
          vm.showCard = false;
        }
      });
    }
  }
};
</script>

<style scoped>
.card {
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 1000;
  overflow: hidden;
  border: 1px solid #dde0e4;
  box-shadow: 0px 4px 4px rgba(26, 43, 55, 0.08) !important;
}

>>> .v-card__title {
  word-wrap: normal;
  word-break: normal;
}

img {
  display: block;
  height: auto;
  max-width: 100%;
}
</style>
