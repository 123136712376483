<template>
  <avatar-icon :text="name" :fallback-text="email" :size="50" />
</template>
<script>
import AvatarIcon from "@/components/common/AvatarIcon.vue";
import { mapGetters } from "vuex";

export default {
  name: "UserProfileAvatar",
  components: { AvatarIcon },
  computed: {
    ...mapGetters("userProfile", ["name", "email"])
  }
};
</script>
