import CategoryValues from "@/assets/json/BurnoutAssessment/BurnoutAssessmentCategoryValues.json";
import cards from "@/assets/json/BurnoutAssessment/BurnoutRecommendationCards.json";
import i18n from "@/plugins/i18n.js";
import { LinkTypes } from "@/services/recommendations/link-types.js";

function getBreaksText(results, options) {
  if (!results?.setup) {
    return i18n.t(options.noBreaks);
  }
  var inActiveBreaks = results.setup.breakActivity === 2;
  var text = inActiveBreaks ? options.inactiveBreaks : options.noBreaks;
  return i18n.t(text);
}

const BurnoutRecommendations = {
  exhaustion: {
    healthAndWellbeingHub_ykavx: {
      id: "health_and_wellbeing_hub_ykavx",
      showAnswer: scores => scores.exhaustion >= CategoryValues.exhaustion.high,
      json: cards.exhaustion.healthAndWellbeingHub_ykavx,
      getPriority: () => 1,
      custom: true,
      linkType: () => LinkTypes.REGULAR
    },
    relaxingactivities: {
      id: "relaxing_activities",
      showAnswer: scores =>
        scores.exhaustion >= CategoryValues.exhaustion.average &&
        scores.exhaustion < CategoryValues.exhaustion.high,
      json: cards.exhaustion.relaxingactivities,
      getPriority: () => 10,
      linkType: () => LinkTypes.REGULAR
    },
    engagemanager: {
      id: "engage_manager",
      showAnswer: scores =>
        scores.exhaustion >= CategoryValues.exhaustion.veryhigh,
      json: cards.exhaustion.engagemanager,
      getPriority: () => 1,
      linkType: () => LinkTypes.REGULAR
    },
    exercise: {
      id: "exercise",
      json: cards.exhaustion.exercise,
      getPriority: results => {
        return results.exhaustion.physicallyexhausted > 1 ? 1 : 2;
      },
      linkType: () => LinkTypes.REGULAR
    },
    musclerelaxation: {
      id: "muscle_relaxation",
      showAnswer: scores => scores.exhaustion >= CategoryValues.exhaustion.high,
      json: cards.exhaustion.musclerelaxation,
      getPriority: () => 7,
      linkType: () => LinkTypes.REGULAR
    },
    sleephygeine: {
      id: "sleep_hygeine",
      showAnswer: scores => scores.exhaustion >= CategoryValues.exhaustion.high,
      json: cards.exhaustion.sleephygeine,
      getPriority: () => 3,
      linkType: () => LinkTypes.REGULAR
    },
    fakecommute: {
      id: "fake_commute",
      showAnswer: (scores, results) => {
        if (results.setup && results.setup.workingfromhome === 2) {
          return false;
        }
        return (
          scores.exhaustion >= CategoryValues.exhaustion.high &&
          scores.exhaustion < CategoryValues.exhaustion.veryhigh
        );
      },
      json: cards.exhaustion.fakecommute,
      getPriority: () => 9,
      linkType: () => LinkTypes.REGULAR
    },
    pomodoro: {
      id: "pomodoro",
      showAnswer: (scores, results) => {
        return (
          (scores.exhaustion >= CategoryValues.exhaustion.average &&
            scores.exhaustion < CategoryValues.exhaustion.high) ||
          results.exhaustion.recoverenergy > 2
        );
      },
      json: cards.exhaustion.pomodoro,
      getPriority: results => {
        return results.exhaustion.recoverenergy > 2 ? 1 : 6;
      },
      linkType: () => LinkTypes.REGULAR
    },
    regularbreaks: {
      id: "regular_breaks",
      showAnswer: (scores, results) => {
        if (results.setup && results.setup.breaks === 1) {
          return false;
        }
        return (
          scores.exhaustion >= CategoryValues.exhaustion.high ||
          results.exhaustion.exerttired > 2 ||
          results.exhaustion.endofdayexhausted > 2
        );
      },
      json: cards.exhaustion.regularbreaks,
      getPriority: results => {
        return results.exhaustion.exerttired > 2 ||
          results.exhaustion.endofdayexhausted > 2
          ? 1
          : 6;
      },
      explanation: results =>
        getBreaksText(results, cards.exhaustion.regularbreaks.explanation),
      heading: results =>
        getBreaksText(results, cards.exhaustion.regularbreaks.heading),
      linkType: () => LinkTypes.REGULAR
    },
    deepbreathing: {
      id: "deep_breathing",
      showAnswer: scores =>
        scores.exhaustion >= CategoryValues.exhaustion.average &&
        scores.exhaustion < CategoryValues.exhaustion.high,
      json: cards.exhaustion.deepbreathing,
      getPriority: () => 8,
      linkType: () => LinkTypes.REGULAR
    },
    morningroutine: {
      id: "morning_routine",
      showAnswer: (scores, results) => {
        return (
          scores.exhaustion >= CategoryValues.exhaustion.high ||
          results.exhaustion.lackenergy > 1
        );
      },
      json: cards.exhaustion.morningroutine,
      getPriority: results => {
        return results.exhaustion.lackenergy > 1 ? 1 : 5;
      },
      linkType: () => LinkTypes.REGULAR
    }
  },
  mentaldistance: {
    support: {
      id: "support",
      showAnswer: scores =>
        scores.mentaldistance >= CategoryValues.mentaldistance.high,
      json: cards.mentaldistance.seeksupport,
      getPriority: () => 2,
      linkType: () => LinkTypes.REGULAR
    },
    support_ykavx: {
      id: "support_ykavx",
      showAnswer: scores =>
        scores.mentaldistance >= CategoryValues.mentaldistance.high,
      json: cards.mentaldistance.seeksupport_ykavx,
      getPriority: () => 2,
      custom: true,
      linkType: () => LinkTypes.REGULAR
    },
    reflection: {
      id: "reflection",
      showAnswer: (scores, results) =>
        (scores.mentaldistance >= CategoryValues.mentaldistance.average &&
          scores.mentaldistance < CategoryValues.mentaldistance.veryhigh) ||
        results.mentaldistance.enthusiasm > 2 ||
        results.mentaldistance.aversion > 2 ||
        results.mentaldistance.indifferent > 2,
      json: cards.mentaldistance.reflection,
      getPriority: () => 1,
      linkType: () => LinkTypes.REGULAR
    },
    manager: {
      id: "engage_manager",
      showAnswer: scores =>
        scores.mentaldistance >= CategoryValues.mentaldistance.veryhigh,
      json: cards.mentaldistance.engagemanager,
      getPriority: () => 3,
      linkType: () => LinkTypes.REGULAR
    },
    morningroutine: {
      id: "morning_routine",
      showAnswer: (scores, results) =>
        scores.mentaldistance >= CategoryValues.mentaldistance.high ||
        results.mentaldistance.autopilot > 2,
      json: cards.mentaldistance.morningroutine,
      getPriority: results => {
        return results.mentaldistance.autopilot > 2 ? 1 : 5;
      },
      linkType: () => LinkTypes.REGULAR
    },
    socialise: {
      id: "socialise",
      json: cards.mentaldistance.socialise,
      getPriority: results => {
        return results.mentaldistance.cynical > 2 ? 1 : 4;
      },
      linkType: () => LinkTypes.REGULAR
    },
    teamsport: {
      id: "team_sport",
      showAnswer: scores =>
        scores.mentaldistance >= CategoryValues.mentaldistance.high,
      json: cards.mentaldistance.teamsport,
      getPriority: () => 6,
      linkType: () => LinkTypes.REGULAR
    },
    teamsport_ykavx: {
      id: "team_sport_ykavx",
      showAnswer: scores =>
        scores.mentaldistance >= CategoryValues.mentaldistance.high,
      json: cards.mentaldistance.teamsport_ykavx,
      getPriority: () => 6,
      custom: true,
      linkType: () => LinkTypes.REGULAR
    }
  },
  cognitiveimpairment: {
    healthyeating: {
      id: "eat_healthy",
      showAnswer: scores =>
        (scores.cognitiveimpairment >=
          CategoryValues.cognitiveimpairment.average &&
          scores.cognitiveimpairment <
            CategoryValues.cognitiveimpairment.high) ||
        scores.cognitiveimpairment >=
          CategoryValues.cognitiveimpairment.veryhigh,
      json: cards.cognitiveimpairment.eathealthy,
      getPriority: () => 5,
      linkType: () => LinkTypes.REGULAR
    },
    stimulants: {
      id: "avoid_stimulants",
      showAnswer: scores =>
        scores.cognitiveimpairment >=
        CategoryValues.cognitiveimpairment.veryhigh,
      json: cards.cognitiveimpairment.stimulants,
      getPriority: () => 4,
      linkType: () => LinkTypes.REGULAR
    },
    stimulants_ykavx: {
      id: "avoid_stimulants_ykavx",
      showAnswer: scores =>
        scores.cognitiveimpairment >=
        CategoryValues.cognitiveimpairment.veryhigh,
      json: cards.cognitiveimpairment.stimulants_ykavx,
      getPriority: () => 4,
      custom: true,
      linkType: () => LinkTypes.REGULAR
    },
    pomodoro: {
      id: "pomodoro",
      showAnswer: (scores, results) => results.cognitiveimpairment.focused > 2,
      json: cards.cognitiveimpairment.pomodoro,
      getPriority: () => 1,
      linkType: () => LinkTypes.REGULAR
    },
    meditation: {
      id: "meditation",
      showAnswer: (scores, results) =>
        scores.cognitiveimpairment >= CategoryValues.cognitiveimpairment.high ||
        results.cognitiveimpairment.thinkclearly > 2 ||
        results.cognitiveimpairment.mistakes > 2,
      json: cards.cognitiveimpairment.meditation,
      getPriority: results => {
        return results.cognitiveimpairment.thinkclearly > 2 ||
          results.cognitiveimpairment.mistakes > 2
          ? 1
          : 6;
      },
      linkType: () => LinkTypes.REGULAR
    },
    exercise: {
      id: "exercise",
      showAnswer: (scores, results) =>
        scores.cognitiveimpairment >= CategoryValues.cognitiveimpairment.high ||
        results.cognitiveimpairment.troubleconcentrating > 2,
      json: cards.cognitiveimpairment.exercise,
      getPriority: results => {
        return results.cognitiveimpairment.troubleconcentrating > 2 ? 1 : 2;
      },
      linkType: () => LinkTypes.REGULAR
    },
    sleephygeine: {
      id: "sleep_hygeine",
      showAnswer: (scores, results) =>
        scores.cognitiveimpairment >= CategoryValues.cognitiveimpairment.high ||
        results.cognitiveimpairment.forgetfuldistracted > 2,
      json: cards.cognitiveimpairment.sleephygeine,
      getPriority: () => 1,
      linkType: () => LinkTypes.REGULAR
    },
    regularbreaks: {
      id: "regular_breaks",
      json: cards.cognitiveimpairment.regularbreaks,
      getPriority: () => 3,
      explanation: results =>
        getBreaksText(
          results,
          cards.cognitiveimpairment.regularbreaks.explanation
        ),
      heading: results =>
        getBreaksText(results, cards.cognitiveimpairment.regularbreaks.heading),
      linkType: () => LinkTypes.REGULAR
    },
    fakecommute: {
      id: "fake_commute",
      showAnswer: (scores, results) => {
        if (results.setup && results.setup.workingfromhome === 2) {
          return false;
        }
        return (
          scores.cognitiveimpairment >=
            CategoryValues.cognitiveimpairment.average &&
          scores.cognitiveimpairment < CategoryValues.cognitiveimpairment.high
        );
      },
      json: cards.cognitiveimpairment.fakecommute,
      getPriority: () => 8,
      linkType: () => LinkTypes.REGULAR
    }
  },
  emotionalimpairment: {
    emotionalintelligence: {
      id: "emotional_intelligence",
      showAnswer: (scores, results) =>
        (scores.emotionalimpairment >=
          CategoryValues.emotionalimpairment.average &&
          scores.emotionalimpairment <
            CategoryValues.emotionalimpairment.high) ||
        results.emotionalimpairment.controlemotions > 2 ||
        results.emotionalimpairment.upset > 2,
      json: cards.emotionalimpairment.emotionalintelligence,
      getPriority: results => {
        return results.emotionalimpairment.controlemotions > 2 ||
          results.emotionalimpairment.upset > 2
          ? 1
          : 2;
      },
      linkType: () => LinkTypes.REGULAR
    },
    mindfulnessmeditation: {
      id: "mindfulness_meditation",
      showAnswer: (scores, results) =>
        (scores.emotionalimpairment >=
          CategoryValues.emotionalimpairment.high &&
          scores.emotionalimpairment <
            CategoryValues.emotionalimpairment.veryhigh) ||
        results.emotionalimpairment.recogniseemotions > 2 ||
        results.emotionalimpairment.overreact > 2,
      json: cards.emotionalimpairment.mindfulnessmeditation,
      getPriority: () => 1,
      linkType: () => LinkTypes.REGULAR
    },
    joyfulactivities: {
      id: "joyful_activities",
      showAnswer: scores =>
        scores.emotionalimpairment >=
          CategoryValues.emotionalimpairment.average &&
        scores.emotionalimpairment <
          CategoryValues.emotionalimpairment.veryhigh,
      json: cards.emotionalimpairment.joyfulactivities,
      getPriority: () => 4,
      linkType: () => LinkTypes.REGULAR
    },
    gratefulnessmeditation: {
      id: "gracefulness_meditation",
      showAnswer: scores =>
        scores.emotionalimpairment <
          CategoryValues.emotionalimpairment.average ||
        scores.emotionalimpairment >= CategoryValues.emotionalimpairment.high,
      json: cards.emotionalimpairment.gratefulnessmeditation,
      getPriority: () => 3,
      linkType: () => LinkTypes.REGULAR
    },
    guidedimagery: {
      id: "guided_imagery",
      showAnswer: scores =>
        scores.emotionalimpairment >= CategoryValues.emotionalimpairment.high,
      json: cards.emotionalimpairment.guidedimagery,
      getPriority: () => 5,
      linkType: () => LinkTypes.REGULAR
    },
    support: {
      id: "support",
      showAnswer: scores =>
        scores.emotionalimpairment >=
        CategoryValues.emotionalimpairment.veryhigh,
      json: cards.emotionalimpairment.seeksupport,
      getPriority: () => 7,
      linkType: () => LinkTypes.REGULAR
    }
  },
  secondarysymptoms: {
    musclerelaxation: {
      id: "muscle_relaxation",
      showAnswer: (scores, results) =>
        (scores.secondarysymptoms >= CategoryValues.secondarysymptoms.high &&
          scores.secondarysymptoms <
            CategoryValues.secondarysymptoms.veryhigh) ||
        results.secondarysymptoms.headaches > 1 ||
        results.secondarysymptoms.musclepain > 2,
      json: cards.secondarysymptoms.musclerelaxation,
      getPriority: results => {
        return results.secondarysymptoms.headaches > 1 ||
          results.secondarysymptoms.musclepain > 2
          ? 1
          : 3;
      },
      linkType: () => LinkTypes.REGULAR
    },
    sleephygeine: {
      id: "sleep_hygeine",
      showAnswer: (scores, results) =>
        scores.secondarysymptoms >= CategoryValues.secondarysymptoms.veryhigh ||
        results.secondarysymptoms.troublesleeping > 2,
      json: cards.secondarysymptoms.sleephygeine,
      getPriority: results => {
        return results.secondarysymptoms.troublesleeping > 2 ? 1 : 2;
      },
      linkType: () => LinkTypes.REGULAR
    },
    exercise: {
      id: "exercise",
      showAnswer: scores =>
        scores.secondarysymptoms >= CategoryValues.secondarysymptoms.high,
      json: cards.secondarysymptoms.exercise,
      getPriority: () => 1,
      linkType: () => LinkTypes.REGULAR
    },
    mindfulnessmeditation: {
      id: "mindfulness_meditation",
      showAnswer: scores =>
        scores.secondarysymptoms >= CategoryValues.secondarysymptoms.average &&
        scores.secondarysymptoms < CategoryValues.secondarysymptoms.veryhigh,
      json: cards.secondarysymptoms.mindfulnessmeditation,
      getPriority: () => 4,
      linkType: () => LinkTypes.REGULAR
    },
    nature: {
      id: "nature",
      showAnswer: scores =>
        scores.secondarysymptoms >= CategoryValues.secondarysymptoms.high,
      json: cards.secondarysymptoms.nature,
      getPriority: () => 5,
      linkType: () => LinkTypes.REGULAR
    },
    guidedimagery: {
      id: "guided_imagery",
      showAnswer: (scores, results) =>
        scores.secondarysymptoms >= CategoryValues.secondarysymptoms.veryhigh ||
        results.secondarysymptoms.worry > 2,
      json: cards.secondarysymptoms.guidedimagery,
      getPriority: results => {
        return results.secondarysymptoms.worry > 2 ? 1 : 7;
      },
      linkType: () => LinkTypes.REGULAR
    },
    bodyscan: {
      id: "body_scan",
      showAnswer: (scores, results) =>
        scores.secondarysymptoms < CategoryValues.secondarysymptoms.high ||
        scores.secondarysymptoms >= CategoryValues.secondarysymptoms.veryhigh ||
        results.secondarysymptoms.tense > 2,
      json: cards.secondarysymptoms.bodyscan,
      getPriority: results => {
        return results.secondarysymptoms.tense > 2 ? 1 : 6;
      },
      linkType: () => LinkTypes.REGULAR
    },
    deepbreathing: {
      id: "deep_breathing",
      showAnswer: (scores, results) =>
        scores.secondarysymptoms >= CategoryValues.secondarysymptoms.average ||
        results.secondarysymptoms.panicattacks > 1,
      json: cards.secondarysymptoms.deepbreathing,
      getPriority: results => {
        return results.secondarysymptoms.panicattacks > 1 ? 1 : 8;
      },
      linkType: () => LinkTypes.REGULAR
    }
  },
  fixed: [
    {
      id: "speak_to_manager_ykavx",
      json: cards.fixed.speakToYourManager_ykavx,
      linkType: () => LinkTypes.REGULAR
    },
    {
      id: "healthy_minds_helpline_ykavx",
      json: cards.fixed.healthyMinds_ykavx,
      linkType: () => LinkTypes.REGULAR
    },
    {
      id: "general_burnout_ykavx",
      json: cards.fixed.generalBurnout_ykavx,
      linkType: () => LinkTypes.REGULAR
    },
    {
      id: "manager_burnout_ykavx",
      json: cards.fixed.managerBurnout_ykavx,
      linkType: () => LinkTypes.REGULAR
    },
    {
      id: "work_related_stress_ykavx",
      json: cards.fixed.workRelatedStress_ykavx,
      linkType: () => LinkTypes.REGULAR
    },
    {
      id: "working_from_home_mental_health_ykavx",
      json: cards.fixed.workingFromHomeMentalHealth_ykavx,
      linkType: () => LinkTypes.REGULAR
    },
    {
      id: "cbt_techniques_ykavx",
      json: cards.fixed.cbtTechniques_ykavx,
      linkType: () => LinkTypes.REGULAR
    },
    {
      id: "personal_energy_ykavx",
      json: cards.fixed.personalEnergy_ykavx,
      linkType: () => LinkTypes.REGULAR
    },
    {
      id: "be_me_app_ykavx",
      json: cards.fixed.beMeApp_ykavx,
      linkType: () => LinkTypes.REGULAR
    },
    {
      id: "support_services_ttqWel",
      json: cards.fixed.support_services_ttqWel,
      linkType: () => LinkTypes.TIPS
    },
    {
      id: "wellbeing_resources_ttqWel",
      json: cards.fixed.wellbeing_resources_ttqWel,
      linkType: () => LinkTypes.TIPS
    },
    {
      id: "mental_health_support_ttqWel",
      json: cards.fixed.mental_health_support_ttqWel,
      linkType: () => LinkTypes.TIPS
    },
    {
      id: "medical_care_ttqWel",
      json: cards.fixed.medical_care_ttqWel,
      linkType: () => LinkTypes.TIPS
    },
    {
      id: "learning_resources_ttqWel",
      json: cards.fixed.learning_resources_ttqWel,
      linkType: () => LinkTypes.TIPS
    }
  ]
};

function DefaultRecommendations(jsonFile) {
  return {
    defaultSleephygeine: {
      json: jsonFile.exhaustion.sleephygeine,
      getPriority: () => 1,
      linkType: () => LinkTypes.REGULAR
    },
    meditation: {
      json: jsonFile.cognitiveimpairment.meditation,
      getPriority: () => 1,
      linkType: () => LinkTypes.REGULAR
    },
    defaultExercise: {
      json: jsonFile.exhaustion.exercise,
      getPriority: () => 1,
      linkType: () => LinkTypes.REGULAR
    },
    defaultReflection: {
      json: jsonFile.mentaldistance.reflection,
      getPriority: () => 1,
      linkType: () => LinkTypes.REGULAR
    }
  };
}

export { BurnoutRecommendations, DefaultRecommendations };
