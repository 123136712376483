const strictGuidRegex =
  /^([0-9A-Fa-f]{8}[-][0-9A-Fa-f]{4}[-][0-9A-Fa-f]{4}[-][0-9A-Fa-f]{4}[-][0-9A-Fa-f]{12})$/;
const containsGuidRegex =
  /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/;

export function isGuidValid(guid) {
  return (
    strictGuidRegex.test(guid) &&
    guid !== "00000000-0000-0000-0000-000000000000"
  );
}

export function sanitizeGuid(guid) {
  const match = guid.match(containsGuidRegex);
  return match ? match[0] : null;
}

export class AuthException extends Error {
  constructor(message) {
    super(message);
    this.name = "AuthException";
  }
}
