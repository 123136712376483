<template>
  <user-profile-block>
    <template #title
      ><span>Equipment</span>
      <button
        v-if="!isLoading"
        @click="addEquipment"
        class="vfl-button-link ml-auto"
        style="text-decoration: none"
      >
        <v-icon size="14" color="vflPrimary">mdi-plus</v-icon>Add equipment
      </button></template
    >
    <template #content>
      <div v-if="isLoading">
        <v-skeleton-loader type="text@3" />
      </div>
      <div v-else>
        <div
          class="mb-1 hover-show-button"
          v-for="equipment in equipment"
          :key="equipment.type + equipment.date"
        >
          <p :class="equipment.note ? 'mb-0' : 'mb-4'">
            {{ equipment.type }}
            <button class="sp-button ml-2" @click="removeEquipment(equipment)">
              Remove
            </button>
          </p>
          <small class="mb-4" style="display: block" v-if="equipment.note">
            {{ equipment.note }}
          </small>
        </div>
      </div>
      <v-dialog v-model="isAddingEquipment" width="480">
        <v-card>
          <v-card-title class="mb-4">Add equipment</v-card-title>
          <v-card-text>
            <h3 class="mb-2">Name</h3>
            <v-text-field
              label=""
              v-model="newEquipmentType"
              clearable
              outlined
              class="sp-text-area"
              hide-details
            ></v-text-field>
            <h3 class="mb-2 mt-4">Note</h3>
            <v-textarea
              label=""
              v-model="newEquipmentNote"
              rows="2"
              class="sp-text-area"
              hide-details
              outlined
            ></v-textarea>
            <div class="d-flex align-center justify-end">
              <button class="sp-cancel-button mr-2" @click="cancelAddEquipment">
                Cancel
              </button>
              <button
                class="sp-add-button"
                @click="addNewEquipment"
                :disabled="!newEquipmentType.trim()"
              >
                Save
              </button>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </template>
  </user-profile-block>
</template>

<script>
import UserProfileBlock from "@/components/user-profile/UserProfileBlock.vue";
import { mapGetters } from "vuex";
import dummyData from "@/components/user-profile/dummy-data.json";

export default {
  name: "UserProfileEquipment",
  components: { UserProfileBlock },
  data() {
    return {
      equipment: dummyData.equipment,
      isAddingEquipment: false,
      newEquipmentType: "",
      newEquipmentNote: ""
    };
  },
  computed: {
    ...mapGetters("userProfile", ["loadingStates"]),
    isLoading() {
      return this.loadingStates.notes;
    },

    todayDate() {
      const date = new Date();
      let day = date.getDate().toString().padStart(2, "0");
      let month = (date.getMonth() + 1).toString().padStart(2, "0"); // getMonth() is zero-based
      let year = date.getFullYear().toString().slice(2); // Get last two digits of the year
      return `${day}/${month}/${year}`; // Formats date as 'DD/MM/YY'
    }
  },
  methods: {
    addEquipment() {
      this.isAddingEquipment = true;
    },
    addNewEquipment() {
      if (this.newEquipmentType.trim()) {
        const newEquipment = {
          type: this.newEquipmentType,
          note: this.newEquipmentNote,
          date: this.todayDate
        };
        this.equipment.push(newEquipment);
        this.cancelAddEquipment();
      }
    },
    cancelAddEquipment() {
      this.isAddingEquipment = false;
      this.newEquipmentType = "";
      this.newEquipmentNote = "";
    },
    removeEquipment(equipment) {
      this.equipment = this.equipment.filter(e => e.type !== equipment.type);
    }
  }
};
</script>

<style lang="scss" scoped>
.sp-button {
  color: var(--v-vflPrimary-base);
  font-weight: 600;
  font-size: 14px;
}

.hover-show-button {
  .sp-button {
    opacity: 0;
    transition: opacity 0.125s ease-out;
  }

  &:hover .sp-button {
    opacity: 1;
  }
}

.sp-text-area {
  border-radius: 8px;
  margin-bottom: 0.5rem;
  line-height: 1.3;
}

.sp-cancel-button {
  font-size: 0.875rem;
  border: 1px solid #ddd;
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
}

.sp-add-button {
  background: var(--v-vflPrimary-base);
  color: white;
  font-size: 0.875rem;
  border: 1px solid var(--v-vflPrimary-base);
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  cursor: pointer;

  &[disabled] {
    cursor: default;
    opacity: 0.5;
  }
}
</style>
