import {
  STAGE_MAPPING,
  STAGES
} from "@/components/assessment/menopause/report/constants";
import { capitaliseFirstLetter } from "@/components/assessment/menopause/report/helpers.js";

export const PAIN_CONTENT = {
  areasCardTitle: "Pain areas",
  contentCardTitle: "Pain doesn't have to be a part of life",
  getStageText: stage => STAGE_MAPPING[stage] || STAGES.MENOPAUSE,
  getSectionTitle: stageText => `Your pain and ${stageText}`,
  getContentBody: stage => {
    const stageText = STAGE_MAPPING[stage] || STAGES.MENOPAUSE;
    const capitalizedStage = capitaliseFirstLetter(stageText);

    return [
      `${capitalizedStage} brings natural changes, including a drop in oestrogen levels, which may reduce some of its anti-inflammatory effects. However, it also showcases the body's incredible adaptability and resilience.`,
      `${capitalizedStage} itself doesn't mean you have to live with pain! There are simple adjustments and gentle movements that can often ease discomfort.`
    ];
  }
};
