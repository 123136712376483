export const PositiveText = {
  TRACKPAD: { priority: 1, text: "trackpad" },
  ROLLER_MOUSE: { priority: 1, text: "rollerMouse" },
  ERGONOMIC_MOUSE: { priority: 1, text: "ergonomicMouse" },
  ERGONOMIC_KEYBOARD: { priority: 2, text: "ergonomicKeyboard" },
  VERTICAL_SCREEN_AND_OTHER: { priority: 3, text: "verticalScreenWithOther" },
  ULTRA_WIDE_SCREEN: { priority: 3, text: "ultraWideScreen" },
  CORNER_DESK: { priority: 4, text: "cornerDesk" },
  CURVED_DESK: { priority: 4, text: "curvedDesk" },
  LAPTOP_STAND: { priority: 5, text: "laptopStand" },
  CHAIR_WITH_ARMRESTS: { priority: 6, text: "chairWithArmrest" },
  WINDOW_MULTIPLE: { priority: 7, text: "window.multiple" },
  WINDOW_MONITOR_ADJACENT: {
    priority: 7,
    text: "window.monitorAdjacentToWindow"
  },
  WINDOW_MONITOR_FACING: { priority: 7, text: "window.monitorFacingWindow" },
  WINDOW_MONITOR_FACING_AWAY: {
    priority: 7,
    text: "window.monitorFacingAwayFromWindow"
  },
  WINDOW_UNKNOWN: { priority: 7, text: "window.unknownPosition" },
  WATER: { priority: 8, text: "water" },
  PLANTS: { priority: 9, text: "plants" },
  REGULAR_SCREEN: { priority: 10, text: "regularScreen" },
  LAMP: { priority: 11, text: "lamp" },
  KEYBOARD: { priority: 12, text: "keyboard" },
  MOUSE: { priority: 13, text: "mouse" },
  WRIST_REST: { priority: 14, text: "wristRest" }
};

export const NegativeText = {
  PHONE_BOOTH: { priority: 1, text: "phoneBooth" },
  SOFA: { priority: 2, text: "sofa" },
  BED: { priority: 2, text: "bed" },
  STOOL: { priority: 2, text: "stool" },
  BEAN_BAG: { priority: 2, text: "beanBag" },
  GYM_BALL: { priority: 2, text: "gymBall" },
  ONLY_VERTICAL_SCREEN: { priority: 3, text: "onlyVerticalScreen" },
  ULTRA_WIDE_SCREEN_AND_OTHER: { priority: 3, text: "ultraWideScreenAndOther" },
  NO_KEYBOARD: { priority: 4, text: "keyboard" },
  NO_MOUSE: { priority: 5, text: "mouse" },
  LAPTOP_STAND: { priority: 6, text: "laptopStand" },
  CLUTTER: { priority: 7, text: "clutter" },
  MULTIPLE_INPUT_DEVICES: { priority: 8, text: "multipleInputDevices" },
  LIGHTING: { priority: 9, text: "lighting" },
  CHAIR_NO_ARMREST: { priority: 10, text: "chairNoArmrest" },
  PLANTS: { priority: 11, text: "plants" },
  MULTIPLE_WATERS: { priority: 12, text: "multipleWaters" },
  NO_WATER: { priority: 13, text: "water" }
};
