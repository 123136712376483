<template>
  <div
    class="user-profile-assessment-card-container"
    :class="[
      { 'is-completed': isCompleted },
      { 'is-incomplete': !isCompleted }
    ]"
    :style="[{ backgroundColor: color }, css]"
    @click="cardClickHandler"
  >
    <div class="user-profile-assessment-card-header d-flex align-center">
      <user-profile-assessment-location
        v-if="assessment.location && assessment.location !== 'None'"
        :location="assessment.location"
      />
      <span v-else style="margin-bottom: 2px">
        <v-icon size="16" color="vflTextDark" class="d-block">{{
          assessmentIcon
        }}</v-icon>
      </span>

      <user-profile-assessment-actions
        class="ml-auto"
        :assessment="assessment"
        :is-completed="isCompleted"
        :is-loading-report="isLoadingReport"
        @viewReport="viewReport"
        @downloadReport="downloadReport"
      />
    </div>
    <h4 class="text-subtitle-2 text-body-darkest mb-4">
      {{ assessmentTitle }}
    </h4>
    <div
      class="user-profile-assessment-card-footer d-flex justify-space-between align-end mt-auto"
    >
      <p v-if="!isCompleted" class="text-caption mb-0">{{ status }}</p>

      <p
        v-else-if="canViewScore"
        class="text-h6 mb-0"
        :class="`${scoreColor}--text`"
      >
        {{ formattedScore }}
      </p>
      <p
        class="text-caption mb-0 ml-auto assessment-date"
        style="line-height: 1"
      >
        {{ formattedDate }}
      </p>
    </div>
  </div>
</template>

<script>
import { formatDateWithOrdinal } from "@/services/date-formatting-service.js";
import UserProfileAssessmentLocation from "@/components/user-profile/UserProfileAssessmentLocation.vue";
import UserProfileAssessmentActions from "@/components/user-profile/UserProfileAssessmentActions.vue";
import {
  AssessmentStateCodes,
  States
} from "@/components/common/assessment-history/assessmentHistoryConstants.js";
import {
  AssessmentCardTheme,
  AssessmentIconsMap,
  AssessmentTitlesMap,
  DESK_BACKGROUND_GRADIENT
} from "@/components/user-profile/constants.js";
import { assessmentTypes } from "@/constants/constants.js";
import { mapActions, mapGetters } from "vuex";
import { deskAssessmentScoreColor } from "@/services/deskassessment/deskassessment-scores.js";
import { OverallScoreColor } from "@/services/driver-assessment/driver-assessment-scores.js";
import BurnoutScores from "@/services/burnout/burnout-scores.js";
import { getScoreColor } from "@/services/assessment/physical-labour/score-interpretation-service.js";
import { sendEvent } from "@/services/analytics/analytics.js";

export default {
  name: "UserProfileAssessmentCard",
  components: {
    UserProfileAssessmentActions,
    UserProfileAssessmentLocation
  },
  props: {
    assessment: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isLoadingReport: false,
      showReport: false,
      isDownloadingReport: false
    };
  },
  computed: {
    ...mapGetters({
      anonymousResults: "anonymousResults",
      adminId: "userId"
    }),
    ...mapGetters("userProfile", [
      "id",
      "currentViewedAssessment",
      "currentAssessmentResults",
      "healthConditions"
    ]),
    canViewScore() {
      return !this.anonymousResults;
    },
    formattedDate() {
      const d = this.assessment.lastUpdatedAt || this.assessment.createdAt;
      return d ? formatDateWithOrdinal(d) : "";
    },
    formattedScore() {
      return this.assessment.score != null ? this.assessment.score + "%" : "-";
    },
    scoreColor() {
      if (this.assessment.score == null) return "vflNeutralDark2";
      switch (this.assessment.assessmentType) {
        case assessmentTypes.deskAssessment:
          return deskAssessmentScoreColor(this.assessment.score).color;
        case assessmentTypes.burnoutAssessment:
          return BurnoutScores.getColorFromScore(
            this.assessment.score,
            "overall"
          ).color;
        case assessmentTypes.physicalLabourAssessment:
          return getScoreColor(this.assessment.score).color;
        case assessmentTypes.driverAssessment:
          return OverallScoreColor(this.assessment.score).color;
        default:
          return "vflNeutralDark2";
      }
    },
    assessmentTitle() {
      const path = AssessmentTitlesMap[this.assessment.assessmentType];
      return this.$t(path);
    },
    isCompleted() {
      return this.assessmentState === AssessmentStateCodes.Completed;
    },
    assessmentState() {
      if (this.assessment.assessmentState !== undefined) {
        return this.assessment.assessmentState;
      }
      if (this.assessment.state !== undefined) {
        return this.assessment.state;
      }
      return null;
    },
    assessmentIcon() {
      return AssessmentIconsMap[this.assessment.assessmentType];
    },
    css() {
      if (this.assessment.assessmentType === assessmentTypes.deskAssessment) {
        return {
          backgroundImage: DESK_BACKGROUND_GRADIENT
        };
      }

      return {};
    },
    color() {
      return AssessmentCardTheme[this.assessment.assessmentType];
    },
    status() {
      const stateInfo = States[this.assessmentState];
      return this.capitalizeFirstWord(this.$t(stateInfo.text));
    },
    cardClickHandler() {
      return this.isCompleted ? this.viewReport : () => {};
    }
  },
  methods: {
    ...mapActions("userProfile", ["viewAssessment", "downloadReport"]),
    capitalizeFirstWord(str) {
      return str
        .split(" ")
        .map((word, i) => (i === 0 ? word : word.toLowerCase()))
        .join(" ");
    },
    async viewReport() {
      if (this.isLoadingReport || !this.isCompleted || this.anonymousResults) {
        return;
      }

      try {
        this.isLoadingReport = true;

        await this.viewAssessment({
          id: this.assessment.id,
          assessmentType: this.assessment.assessmentType,
          lastUpdatedAt: this.assessment.lastUpdatedAt
        });

        sendEvent("Open report from user profile", {
          end_user: this.id,
          admin: this.adminId
        });
      } catch (err) {
        this.$logger.captureException(err);
      } finally {
        this.isLoadingReport = false;
      }
    },
    downloadReport() {
      this.isDownloadingReport = true;
      this.viewReport();

      sendEvent("Download report from user profile", {
        end_user: this.id,
        admin: this.adminId
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.user-profile-assessment-card {
  &-container {
    background-color: var(--v-vflBlueVeryLight-base);
    border: 1px solid hsla(0, 0%, 0%, 0.05);
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    min-height: 106px;
    padding: 0.5rem 1rem 0.75rem;

    &.is-completed {
      cursor: pointer;
      transition: filter 0.125s ease-out;

      &:hover {
        filter: brightness(96%);
      }
    }

    &.is-incomplete {
      background-color: white !important;
      background-image: none !important;
      border-color: hsla(0, 0%, 0%, 0.1);
    }
  }

  &-footer {
    p {
      line-height: 1 !important;
    }
  }
}

.assessment-date {
  color: var(--v-vflGreyLight-base);
}
</style>
