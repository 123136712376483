export const Conditions = [
  {
    id: "mskCondition",
    text: `assessment.form.healthConditions.admin.supportDialog.mskCondition.title`
  },
  {
    id: "injury",
    text: `assessment.form.healthConditions.admin.supportDialog.mskInjury.title`
  },
  {
    id: "mobilityImpairment",
    text: `assessment.form.healthConditions.admin.supportDialog.mobilityImpairment.title`
  },
  {
    id: "visionImpairment",
    text: `assessment.form.healthConditions.admin.supportDialog.visionImpairment.title`
  },
  {
    id: "hearingLoss",
    text: `assessment.form.healthConditions.admin.supportDialog.hearingLoss.title`
  },
  {
    id: "neurodiversity",
    text: `assessment.form.healthConditions.admin.supportDialog.neurodiversity.title`
  },
  {
    id: "chronicFatigueSyndrome",
    text: `assessment.form.healthConditions.admin.supportDialog.chronicFatigueSyndrome.title`
  },
  {
    id: "fibromyalgia",
    text: `assessment.form.healthConditions.admin.supportDialog.fibromyalgia.title`
  },
  {
    id: "posturalTachycardiaSyndrome",
    text: `assessment.form.healthConditions.admin.supportDialog.posturalTachycardiaSyndrome.title`
  }
];

export const Aids = [
  {
    id: "mobilityAssistance",
    text: `assessment.form.healthConditions.admin.supportDialog.mobilityAssistance.title`,
    icon: "mdi-wheelchair"
  },
  {
    id: "hearingAid",
    text: `assessment.form.healthConditions.admin.supportDialog.hearingAid.title`,
    icon: "mdi-ear-hearing"
  }
];
