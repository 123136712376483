export const ScoreBoundaries = {
  HIGH: 34,
  MEDIUM: 50,
  LOW: 75,
  NEGLIGIBLE: 90
};

export const ScoreBoundaryNames = {
  VERY_HIGH: "veryHigh",
  HIGH: "high",
  MEDIUM: "medium",
  LOW: "low",
  NEGLIGIBLE: "negligible"
};

export const getScoreColor = score => {
  if (score >= ScoreBoundaries.NEGLIGIBLE) {
    return { color: "vflBrightGreenDark1", textColor: "vflBrightGreenDark2" };
  } else if (score >= ScoreBoundaries.LOW) {
    return { color: "vflBrightGreen", textColor: "vflBrightGreenDark2" };
  } else if (score >= ScoreBoundaries.MEDIUM) {
    return { color: "vflYellow", textColor: "vflYellowDark2" };
  } else if (score >= ScoreBoundaries.HIGH) {
    return { color: "vflOrange", textColor: "vflOrangeDark1" };
  } else {
    return { color: "vflMagenta", textColor: "vflMagentaDark1" };
  }
};

export const getScoreRatingName = score => {
  if (score >= ScoreBoundaries.NEGLIGIBLE) {
    return ScoreBoundaryNames.NEGLIGIBLE;
  } else if (score >= ScoreBoundaries.LOW) {
    return ScoreBoundaryNames.LOW;
  } else if (score >= ScoreBoundaries.MEDIUM) {
    return ScoreBoundaryNames.MEDIUM;
  } else if (score >= ScoreBoundaries.HIGH) {
    return ScoreBoundaryNames.HIGH;
  } else {
    return ScoreBoundaryNames.VERY_HIGH;
  }
};

export const SCORE_SLIDER_SECTIONS = [
  { color: "vflMagenta", percent: 0 },
  {
    color: "vflOrange",
    percent: ScoreBoundaries.HIGH
  },
  {
    color: "vflYellow",
    percent: ScoreBoundaries.MEDIUM
  },
  {
    color: "vflBrightGreen",
    percent: ScoreBoundaries.LOW
  },
  {
    color: "vflBrightGreenDark1",
    percent: ScoreBoundaries.NEGLIGIBLE
  }
];
