import {
  getActiveFeatureIntroductions,
  setFeatureIntroItemComplete,
  checkFeatureIntroComplete,
  adminCheckListCompleted
} from "@/customApi";
import store from "@/store";
import _ from "lodash";

export async function processAdminChecklist(completedItem) {
  let intros = await getActiveFeatureIntroductions();
  let checklist = _.find(
    intros,
    intro => intro.series === "AdminOnboardingTour"
  );

  if (!checklist?.items || checklist.items[completedItem] === true) return;

  await setFeatureIntroItemComplete(
    checklist.id,
    checklist.items[completedItem].id
  );
  await updateAdminChecklist();
}

export async function updateCheckListFromFeatures(features) {
  let checklist = _.find(
    features,
    intro =>
      intro.series === "AdminOnboardingTour" ||
      intro.series === "AdminOnboardingTourCompletion"
  );

  store.commit("setOnboardingChecklist", checklist);
}

export async function updateAdminChecklist() {
  let updatedFeatures = await checkFeatureIntroComplete(
    store.getters.onboardingChecklist.id
  );
  store.commit("setFeatureIntros", updatedFeatures);
  updateCheckListFromFeatures(updatedFeatures);
}

export async function checkListCompleted() {
  let updatedFeatures = await adminCheckListCompleted();
  store.commit("setFeatureIntros", updatedFeatures);
  updateCheckListFromFeatures(updatedFeatures);
}
