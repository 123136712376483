<template>
  <div class="message-input-container">
    <div class="input-wrapper vfl-scrollbar-vertical">
      <textarea
        ref="textareaRef"
        :value="internalValue"
        @input="updateValue"
        @keydown.enter="onEnter"
        :placeholder="placeholder"
        :disabled="disabled"
        :rows="rows"
        class="textarea"
      ></textarea>
      <div class="icon-wrapper">
        <slot name="icon"></slot>
      </div>
    </div>
  </div>
</template>

<script>
const INPUT_MIN_HEIGHT = 34;

export default {
  name: "MessageInput",
  props: {
    value: {
      type: String,
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false
    },
    autoGrow: {
      type: Boolean,
      default: true
    },
    rows: {
      type: Number,
      default: 3
    },
    autofocus: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      internalValue: this.value,
      isMultiline: false
    };
  },
  computed: {
    placeholder() {
      return "Write a message";
    }
  },
  watch: {
    value(newValue) {
      this.internalValue = newValue;
      this.$nextTick(this.adjustHeight);
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (this.autofocus) {
        this.$refs.textareaRef.focus();
      }
    });
    this.$nextTick(this.adjustHeight);
  },
  methods: {
    adjustHeight() {
      if (this.autoGrow && this.$refs.textareaRef) {
        const textarea = this.$refs.textareaRef;

        textarea.style.height = "auto";
        // Add 1 to prevent unnecessary scrollbar showing
        textarea.style.height = `${textarea.scrollHeight + 1}px`;

        this.isMultiline = textarea.scrollHeight > INPUT_MIN_HEIGHT + 1;
      }
    },
    updateValue(event) {
      this.internalValue = event.target.value;
      this.$emit("input", this.internalValue);
      this.$nextTick(this.adjustHeight);
    },
    onEnter(event) {
      if (this.disabled) {
        return;
      }
      if (!event.shiftKey) {
        event.preventDefault();
        this.$emit("enter");
      }
    }
  }
};
</script>

<style scoped lang="scss">
.message-input-container {
  border-radius: 18px;
  width: 100%;
}

.input-wrapper {
  display: flex;
  overflow: hidden;
}

textarea {
  background-color: transparent;
  border: none;
  flex-grow: 1;
  font-size: 0.875rem;
  line-height: 1.3;
  min-height: 34px;
  max-height: 200px;
  overflow: auto;
  padding: 0.5rem 0;
  resize: none;

  &:focus {
    outline: none;
  }

  &:disabled {
    cursor: not-allowed;
  }
}

.icon-wrapper {
  align-items: flex-end;
  display: flex;
  padding: 0.3rem 0 0.3rem 0.3rem;
}
</style>
