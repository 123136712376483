import i18n from "@/plugins/i18n.js";
import answers from "@/services/assessment/physical-labour/answers.json";
import sentry from "@/sentry";
const root = "physicalLabourAssessment.report.content";
import { HealthImpact } from "@/constants/healthImpact.js";
import { assessmentKeys } from "@/components/assessment/physical-labour/utils/constants.js";

export function getActivityCards(results) {
  return GetReportCards(getAllActivityCards, results);
}

function GetReportCards(getCards, results) {
  const cards = getCards(results);
  cards.forEach(c => {
    c.text = c.text.filter(t => t.show).map(t => i18n.t(t.text));
    c.title = i18n.t(c.title);
  });
  return cards.filter(c => c.text.length > 0);
}

function answersContain(results, section, question, answer) {
  try {
    const activity = answers[section][question][answer];
    if (activity === undefined)
      throw new Error(`No activity found for question ${question}`);
    return results[section][question].includes(activity);
  } catch (e) {
    sentry.captureException(e);
    return false;
  }
}

function getAllActivityCards(results) {
  return [
    {
      id: "repetitiveMovement",
      title: `${root}.repetitiveMovement.title`,
      imageUrl: "physical-labour-assessment/Repetitive-movement.svg",
      healthImpact: HealthImpact.NEUTRAL,
      text: [
        {
          show: answersContain(
            results,
            assessmentKeys.jobNature,
            assessmentKeys.jobActivities,
            "repetitiveMovement"
          ),
          text: `${root}.repetitiveMovement.text1`
        },
        {
          show: answersContain(
            results,
            assessmentKeys.jobNature,
            assessmentKeys.jobActivities,
            "repetitiveMovement"
          ),
          text: `${root}.repetitiveMovement.text2`
        }
      ]
    },
    {
      id: "contactStress",
      title: `${root}.contactStress.title`,
      imageUrl: "physical-labour-assessment/Contact-stress.svg",
      healthImpact: HealthImpact.NEUTRAL,
      text: [
        {
          show: answersContain(
            results,
            assessmentKeys.jobNature,
            assessmentKeys.jobActivities,
            "contactStress"
          ),
          text: `${root}.contactStress.text`
        }
      ]
    },
    {
      id: "pushOrPull",
      title: `${root}.pushOrPull.title`,
      imageUrl: "physical-labour-assessment/Pushing-pulling.svg",
      healthImpact: HealthImpact.NEUTRAL,
      text: [
        {
          show: answersContain(
            results,
            assessmentKeys.jobNature,
            assessmentKeys.jobActivities,
            "pushOrPull"
          ),
          text: `${root}.pushOrPull.text`
        }
      ]
    },
    {
      id: "carrying",
      title: `${root}.carrying.title`,
      imageUrl: "physical-labour-assessment/Carrying.svg",
      healthImpact: HealthImpact.NEUTRAL,
      text: [
        {
          show: answersContain(
            results,
            assessmentKeys.jobNature,
            assessmentKeys.jobActivities,
            "carrying"
          ),
          text: `${root}.carrying.text1`
        },
        {
          show: answersContain(
            results,
            assessmentKeys.jobNature,
            assessmentKeys.jobActivities,
            "carrying"
          ),
          text: `${root}.carrying.text2`
        }
      ]
    },
    {
      id: "heavyLifting",
      title: `${root}.heavyLifting.title`,
      imageUrl: "physical-labour-assessment/Heavy-lifting.svg",
      healthImpact: HealthImpact.NEUTRAL,
      text: [
        {
          show: answersContain(
            results,
            assessmentKeys.jobNature,
            assessmentKeys.jobActivities,
            "heavyLifting"
          ),
          text: `${root}.heavyLifting.text1`
        },
        {
          show: answersContain(
            results,
            assessmentKeys.jobNature,
            assessmentKeys.jobActivities,
            "heavyLifting"
          ),
          text: `${root}.heavyLifting.text2`
        }
      ]
    },
    {
      id: "intensePhysicalEffort",
      title: `${root}.intensePhysicalEffort.title`,
      imageUrl: "physical-labour-assessment/Intense-physical-effort.svg",
      healthImpact: HealthImpact.NEUTRAL,
      text: [
        {
          show: answersContain(
            results,
            assessmentKeys.jobNature,
            assessmentKeys.jobActivities,
            "intensePhysicalEffort"
          ),
          text: `${root}.intensePhysicalEffort.text`
        }
      ]
    },
    {
      id: "gripping",
      title: `${root}.gripping.title`,
      imageUrl: "physical-labour-assessment/Gripping.svg",
      healthImpact: HealthImpact.NEUTRAL,
      text: [
        {
          show: answersContain(
            results,
            assessmentKeys.jobNature,
            assessmentKeys.jobActivities,
            "gripping"
          ),
          text: `${root}.gripping.text1`
        },
        {
          show: answersContain(
            results,
            assessmentKeys.jobNature,
            assessmentKeys.jobActivities,
            "gripping"
          ),
          text: `${root}.gripping.text2`
        }
      ]
    },
    {
      id: "overheadWork",
      title: `${root}.overheadWork.title`,
      imageUrl: "physical-labour-assessment/Overhead-work.svg",
      healthImpact: HealthImpact.NEUTRAL,
      text: [
        {
          show: answersContain(
            results,
            assessmentKeys.jobNature,
            assessmentKeys.jobActivities,
            "overheadWork"
          ),
          text: `${root}.overheadWork.text`
        }
      ]
    },
    {
      id: "vibration",
      title: `${root}.vibration.title`,
      imageUrl: "physical-labour-assessment/Vibration.svg",
      healthImpact: HealthImpact.NEUTRAL,
      text: [
        {
          show: answersContain(
            results,
            assessmentKeys.jobNature,
            assessmentKeys.jobActivities,
            "vibration"
          ),
          text: `${root}.vibration.text`
        }
      ]
    }
  ];
}
