import answers from "@/components/assessment/pre-existing-conditions/answers.json";
import { IDS } from "@/components/common/report/cards/report-card-ids.js";
import BodyAreas from "@/components/painareas/body-areas.json";

export const PAIN_REPORT_CARD_MAPPING = {
  [IDS.KEYBOARD]: [
    {
      painGroup: BodyAreas.leftwrist.group,
      value: answers.pain.areas.leftwrist.healthConditions.carpalTunnelSyndrome
    },
    {
      painGroup: BodyAreas.leftwrist.group,
      value: answers.pain.areas.leftwrist.healthConditions.tendinitis
    },
    {
      painGroup: BodyAreas.leftwrist.group,
      value:
        answers.pain.areas.leftwrist.healthConditions.repetitiveStrainInjury
    },
    {
      painGroup: BodyAreas.leftwrist.group,
      value: answers.pain.areas.leftwrist.healthConditions.rheumatoidArthritis
    }
  ],
  [IDS.CHAIR]: [
    {
      painGroup: BodyAreas.gluteals.group,
      value: answers.pain.areas.gluteals.healthConditions.piriformisSyndrome
    },
    {
      painGroup: BodyAreas.midback.group,
      value: answers.pain.areas.midback.healthConditions.spondylosis
    },
    {
      painGroup: BodyAreas.lowerback.group,
      value: answers.pain.areas.lowerback.healthConditions.spondylosis
    },
    {
      painGroup: BodyAreas.midback.group,
      value: answers.pain.areas.midback.healthConditions.facetJointSyndrome
    },
    {
      painGroup: BodyAreas.lowerback.group,
      value: answers.pain.areas.lowerback.healthConditions.facetJointSyndrome
    },
    {
      painGroup: BodyAreas.midback.group,
      value: answers.pain.areas.midback.healthConditions.spondylolisthesis
    },
    {
      painGroup: BodyAreas.lowerback.group,
      value: answers.pain.areas.lowerback.healthConditions.spondylolisthesis
    },
    {
      painGroup: BodyAreas.gluteals.group,
      value: answers.pain.areas.gluteals.healthConditions.hipOsteoarthritis
    },
    {
      painGroup: BodyAreas.lefthip.group,
      value: answers.pain.areas.lefthip.healthConditions.osteoarthritis
    },
    {
      painGroup: BodyAreas.gluteals.group,
      value: answers.pain.areas.gluteals.healthConditions.sciatica
    },
    {
      painGroup: BodyAreas.gluteals.group,
      value:
        answers.pain.areas.gluteals.healthConditions.sacroiliacJointDysfunction
    },
    {
      painGroup: BodyAreas.gluteals.group,
      value: answers.pain.areas.gluteals.healthConditions.tendinopathy
    },
    {
      painGroup: BodyAreas.lowerback.group,
      value: answers.pain.areas.lowerback.healthConditions.herniatedDisc
    },
    {
      painGroup: BodyAreas.midback.group,
      value: answers.pain.areas.midback.healthConditions.herniatedDisc
    },
    {
      painGroup: BodyAreas.lowerback.group,
      value: answers.pain.areas.lowerback.healthConditions.scoliosis
    },
    {
      painGroup: BodyAreas.midback.group,
      value: answers.pain.areas.midback.healthConditions.scoliosis
    },
    {
      painGroup: BodyAreas.lefthip.group,
      value: answers.pain.areas.lefthip.healthConditions.avascularNecrosis
    },
    {
      painGroup: BodyAreas.lefthip.group,
      value: answers.pain.areas.lefthip.healthConditions.replacement
    },
    {
      painGroup: BodyAreas.leftknee.group,
      value: answers.pain.areas.leftknee.healthConditions.replacement
    },
    {
      painGroup: BodyAreas.midback.group,
      value: answers.pain.areas.midback.healthConditions.muscleStrainOrSprain
    },
    {
      painGroup: BodyAreas.lowerback.group,
      value: answers.pain.areas.lowerback.healthConditions.muscleStrainOrSprain
    },
    {
      painGroup: BodyAreas.midback.group,
      value: answers.pain.areas.midback.healthConditions.sciatica
    },
    {
      painGroup: BodyAreas.lowerback.group,
      value: answers.pain.areas.lowerback.healthConditions.sciatica
    },
    {
      painGroup: BodyAreas.midback.group,
      value: answers.pain.areas.midback.healthConditions.pinchedNerve
    },
    {
      painGroup: BodyAreas.lowerback.group,
      value: answers.pain.areas.lowerback.healthConditions.pinchedNerve
    },
    {
      painGroup: BodyAreas.midback.group,
      value: answers.pain.areas.midback.healthConditions.spinalStenosis
    },
    {
      painGroup: BodyAreas.lowerback.group,
      value: answers.pain.areas.lowerback.healthConditions.spinalStenosis
    },
    {
      painGroup: BodyAreas.midback.group,
      value: answers.pain.areas.midback.healthConditions.ankylosingSpondylitis
    },
    {
      painGroup: BodyAreas.lowerback.group,
      value: answers.pain.areas.lowerback.healthConditions.ankylosingSpondylitis
    }
  ],
  [IDS.STRAIN]: [
    {
      painGroup: BodyAreas.neck.group,
      value: answers.pain.areas.neck.healthConditions.whiplashInjury
    },
    {
      painGroup: BodyAreas.neck.group,
      value: answers.pain.areas.neck.healthConditions.spondylosis
    },
    {
      painGroup: BodyAreas.neck.group,
      value: answers.pain.areas.neck.healthConditions.muscleStrainOrSprain
    }
  ],
  [IDS.LAPTOP]: [
    {
      painGroup: BodyAreas.neck.group,
      value: answers.pain.areas.neck.healthConditions.whiplashInjury
    },
    {
      painGroup: BodyAreas.neck.group,
      value: answers.pain.areas.neck.healthConditions.spondylosis
    },
    {
      painGroup: BodyAreas.neck.group,
      value: answers.pain.areas.neck.healthConditions.muscleStrainOrSprain
    },
    {
      painGroup: BodyAreas.neck.group,
      value: answers.pain.areas.neck.healthConditions.facetJointSyndrome
    }
  ],
  [IDS.SCREEN_HEIGHT]: [
    {
      painGroup: BodyAreas.neck.group,
      value: answers.pain.areas.neck.healthConditions.herniatedDisc
    },
    {
      painGroup: BodyAreas.neck.group,
      value: answers.pain.areas.neck.healthConditions.cervicalMyelopathy
    },
    {
      painGroup: BodyAreas.neck.group,
      value: answers.pain.areas.neck.healthConditions.spinalStenosis
    },
    {
      painGroup: BodyAreas.neck.group,
      value: answers.pain.areas.neck.healthConditions.pinchedNerve
    }
  ],
  [IDS.SCREEN_DISTANCE]: [
    {
      painGroup: BodyAreas.neck.group,
      value: answers.pain.areas.neck.healthConditions.herniatedDisc
    },
    {
      painGroup: BodyAreas.neck.group,
      value: answers.pain.areas.neck.healthConditions.spinalStenosis
    },
    {
      painGroup: BodyAreas.neck.group,
      value: answers.pain.areas.neck.healthConditions.pinchedNerve
    }
  ],
  [IDS.STANDING_BREAKS]: [
    {
      painGroup: BodyAreas.midback.group,
      value: answers.pain.areas.midback.healthConditions.muscleStrainOrSprain
    },
    {
      painGroup: BodyAreas.lowerback.group,
      value: answers.pain.areas.lowerback.healthConditions.muscleStrainOrSprain
    },
    {
      painGroup: BodyAreas.midback.group,
      value: answers.pain.areas.midback.healthConditions.sciatica
    },
    {
      painGroup: BodyAreas.lowerback.group,
      value: answers.pain.areas.lowerback.healthConditions.sciatica
    },
    {
      painGroup: BodyAreas.midback.group,
      value: answers.pain.areas.midback.healthConditions.pinchedNerve
    },
    {
      painGroup: BodyAreas.lowerback.group,
      value: answers.pain.areas.lowerback.healthConditions.pinchedNerve
    },
    {
      painGroup: BodyAreas.midback.group,
      value: answers.pain.areas.midback.healthConditions.spinalStenosis
    },
    {
      painGroup: BodyAreas.lowerback.group,
      value: answers.pain.areas.lowerback.healthConditions.spinalStenosis
    }
  ]
};
