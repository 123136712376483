/* Get search parameters from a url */
export function getUrlSearchParameters(url) {
  let paramString = url.split("?")[1];
  if (!paramString) {
    return null;
  }

  let params = splitSearchParametersFromHasMode(paramString);
  return new URLSearchParams(params);
}

function splitSearchParametersFromHasMode(stringToSplit) {
  var hashMode = "#/";
  var paramString = stringToSplit;
  if (paramString.includes(hashMode)) {
    paramString = paramString.split(hashMode)[0];
  }
  return paramString;
}

/* Remove all Search parameters from url and update browser */
export function removeUrlParameters(url) {
  const { baseUrl, hash } = parseUrl(url);
  const newUrl = constructUrlWithoutParams(baseUrl, hash);
  updateBrowserHistory(newUrl);
}

function parseUrl(url) {
  const urlObj = new URL(url);
  return {
    baseUrl: urlObj.origin + urlObj.pathname,
    hash: urlObj.hash
  };
}

function constructUrlWithoutParams(baseUrl, hash) {
  return `${baseUrl}${clearHashParameters(hash)}`;
}

/* Remove specific Search parameters from a url and update browser */
export function removeSpecificUrlParameters(url, paramsToRemove) {
  const { baseUrl, searchParams, hash } = parseUrlWithParams(url);
  removeParams(searchParams, paramsToRemove);
  const updatedHash = processHashParameters(hash, paramsToRemove);
  const newUrl = constructUpdatedUrl(baseUrl, searchParams, updatedHash);
  updateBrowserHistory(newUrl);
}

function parseUrlWithParams(url) {
  const urlObj = new URL(url);
  return {
    baseUrl: urlObj.origin + urlObj.pathname,
    searchParams: new URLSearchParams(urlObj.search),
    hash: urlObj.hash
  };
}

function removeParams(searchParams, paramsToRemove) {
  paramsToRemove.forEach(param => searchParams.delete(param));
}

function processHashParameters(hash, paramsToRemove) {
  if (hash.includes("?")) {
    const hashSearch = new URLSearchParams(hash.split("?")[1]);
    removeParams(hashSearch, paramsToRemove);
    return (
      hash.split("?")[0] +
      (hashSearch.toString() ? "?" + hashSearch.toString() : "")
    );
  }
  return hash;
}

function constructUpdatedUrl(baseUrl, searchParams, hash) {
  return `${baseUrl}${
    searchParams.toString() ? "?" + searchParams.toString() : ""
  }${hash}`;
}

function clearHashParameters(hash) {
  if (hash.includes("?")) {
    return hash.split("?")[0];
  }
  return hash;
}

function updateBrowserHistory(url) {
  window.history.pushState({}, "", url);
}
