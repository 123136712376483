<template>
  <v-card class="px-8 pt-4 pb-6">
    <div>
      <div v-if="!inviteSent" class="dialog-header">
        <h1 class="text-h5 mr-8">{{ dialogTitle }}</h1>
        <shareable-sign-up-button
          v-if="!inviteSent"
          :assessmentType="assessmentType"
        />

        <button
          class="text-button-reset ml-auto"
          @click="$emit('input', false)"
          icon
        >
          <v-icon>clear</v-icon>
          <span class="d-sr-only">Close</span>
        </button>
      </div>
      <div v-if="!inviteSent" class="d-flex justify-space-between">
        <vfl-tab-headers
          v-if="tabs.length"
          v-model="currentTab"
          :tabs="tabs"
          class="dialog-header-tabs"
        />
        <notification-provider-selector
          v-if="showExternalNotificationSelection"
          :value="selectedProvider"
          @update:provider="handleProviderUpdate"
        />
      </div>

      <total-seats-warning-bar
        v-model="updateBanner"
        v-show="!inviteSent"
        id="inviteSeatWarning"
        sendInternalEmail
        class="my-4"
      />
      <div v-if="showUpgradeBox" class="my-3">
        <upgrade-snackbar
          v-model="showUpgradeBox"
          :numberOfInvitesRemaining="invitesRemaining"
          :numberOfEmailsToSend="numberOfEmailsToSend"
          :status="currentUserTeamAndSubscriptionBasicInformation.accessTier"
        />
      </div>

      <div v-if="!inviteSent" class="mt-8">
        <v-form v-if="currentTab === 'singleInvite'" v-model="valid" ref="form">
          <div class="d-flex flex-wrap" style="gap: 0.5rem">
            <div style="flex: 1; min-width: 200px">
              <v-autocomplete
                :dense="true"
                height="48"
                class="data-hj-suppress"
                ref="emailDropDown"
                :items="availableUserEmails"
                :label="$t('textFields.email')"
                placeholder="janedoe@email.com"
                v-model="selectedUser.email.value"
                :rules="[
                  rules.emailRequired($t('inputRules.emailExists')),
                  rules.emailValid($t('inputRules.emailFormat'), validEmail)
                ]"
                outlined
                :color="mainColor"
                data-cy="email"
                return-object
                @change="setSelectedUser"
                @keydown.enter="
                  addNewEmail(selectedUser.email.value, $event.target._value)
                "
                @blur="
                  addNewEmail(selectedUser.email.value, $event.target._value)
                "
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title
                        v-html="$t('invite.dialog.newEmailInstruction')"
                      />
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-autocomplete>
            </div>
            <div style="flex: 1; min-width: 200px">
              <v-text-field
                class="data-hj-suppress"
                :label="$t('textFields.name')"
                placeholder="Jane Doe"
                v-model="selectedUser.name"
                outlined
                :color="mainColor"
                data-cy="name"
                :dense="true"
                height="48"
              />
            </div>

            <div style="flex: 1; min-width: 200px">
              <v-tooltip
                right
                nudge-top="15px"
                :disabled="!containsInaccessibleTags"
                color="transparent"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div
                    v-on="on"
                    v-bind="attrs"
                    style="width: 100%; height: 100%"
                  >
                    <v-combobox
                      :items="shownTags"
                      item-value="id"
                      item-text="name"
                      :label="$t('invite.dialog.tagLabel')"
                      :placeholder="$t('invite.dialog.tagPlaceholder')"
                      v-model="selectedUser.tags"
                      outlined
                      multiple
                      :color="mainColor"
                      :hint="$t('invite.dialog.tagHint')"
                      persistent-hint
                      :dense="true"
                    />
                  </div>
                </template>
                <vitrue-tooltip
                  :message="$t('invite.dialog.haveReadonlyTags')"
                />
              </v-tooltip>
            </div>
          </div>
        </v-form>
        <div v-if="currentTab === 'bulkInvite'">
          <v-form v-model="valid" ref="form">
            <MultipleEmailsInputField v-model="emails" :color="mainColor"
          /></v-form>

          <div>
            <v-tooltip
              right
              nudge-top="15px"
              :disabled="!containsInaccessibleTags"
              color="transparent"
            >
              <template v-slot:activator="{ on, attrs }">
                <div v-on="on" v-bind="attrs" style="width: 100%; height: 100%">
                  <v-combobox
                    :items="shownTags"
                    item-text="name"
                    item-value="id"
                    :label="$t('invite.dialog.tagLabel')"
                    :placeholder="$t('invite.dialog.tagPlaceholder')"
                    v-model="multipleTags"
                    outlined
                    multiple
                    height="48"
                    :dense="true"
                    :color="mainColor"
                    :hint="$t('invite.dialog.tagHint')"
                    persistent-hint
                  />
                </div>
              </template>
              <vitrue-tooltip :message="$t('invite.dialog.haveReadonlyTags')" />
            </v-tooltip>
          </div>
        </div>

        <v-row no-gutters justify="end" v-if="currentTab === 'fileInvite'">
          <v-col cols="12"
            ><invite-via-file
              v-model="fileUploadEmails"
              :page="pageInFileUpload"
              @can-progress-event="canProgressInFileUpload = $event"
              @go-to-previous-page="pageInFileUpload -= 1"
          /></v-col>
          <v-col cols="auto" v-if="pageInFileUpload === 0">
            <v-tooltip
              bottom
              content-class="text-center"
              max-width="300"
              color="transparent"
            >
              <template v-slot:activator="{ on, attrs }">
                <div v-on="on" v-bind="attrs" class="primary--text">
                  <v-btn
                    @click="downloadCsvTemplate"
                    color="accent"
                    small
                    class="text-caption"
                    >{{ $t("buttons.template")
                    }}<v-icon small class="ml-2">mdi-download</v-icon></v-btn
                  >
                </div>
              </template>
              <vitrue-tooltip
                :message="$t('tooltips.downloadExampleFile')"
              /> </v-tooltip
          ></v-col>
        </v-row>

        <div
          v-if="
            currentTab === 'singleInvite' ||
            currentTab === 'bulkInvite' ||
            pageInFileUpload === 2
          "
          class="mt-6"
        >
          <invite-dialog-textarea
            @message-updated="updateMessage"
            :assessmentType="assessmentType"
            :teamName="currentUserTeamAndSubscriptionBasicInformation.name"
            :externalNotificationProvider="selectedProvider"
          />
        </div>
      </div>

      <div v-if="!inviteSent">
        <v-row class="mt-3" justify="center">
          <v-col
            cols="12"
            v-if="false"
            class="text-center text-h6"
            :class="textColor"
          >
            {{ newTotalUserText }}
          </v-col>

          <v-col
            v-if="
              currentTab === 'singleInvite' ||
              currentTab === 'bulkInvite' ||
              pageInFileUpload === 2
            "
            cols="8"
            md="4"
            lg="3"
          >
            <v-tooltip right color="transparent">
              <vitrue-tooltip
                v-if="inviteDisabled && !sentButtonClicked"
                :message="inviteDisabledTooltip"
              />

              <template v-slot:activator="{ on }">
                <span v-on="on">
                  <v-btn
                    depressed
                    class="white--text"
                    :color="mainColor"
                    rounded
                    width="100%"
                    @click="sendInvite()"
                    :disabled="inviteDisabled"
                    data-cy="invite"
                    >{{ $t("buttons.invite") }}</v-btn
                  ></span
                >
              </template>
            </v-tooltip>
          </v-col>

          <v-col
            v-if="currentTab === 'fileInvite' && pageInFileUpload !== 2"
            cols="8"
            md="4"
            lg="3"
          >
            <v-tooltip right color="transparent">
              <feature-flag-note
                v-if="!canProgressInFileUpload"
                :message="
                  pageInFileUpload === 0
                    ? $t('tooltips.uploadFile')
                    : $t('tooltips.selectEmail')
                "
              />

              <template v-slot:activator="{ on }">
                <span v-on="on">
                  <v-btn
                    :color="mainColor"
                    rounded
                    width="100%"
                    @click="pageInFileUpload += 1"
                    :disabled="!canProgressInFileUpload"
                    >{{ $t("buttons.next") }}</v-btn
                  ></span
                >
              </template>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row justify="center" class="pt-5" v-if="displaySendingEmailsBar">
          <p>
            {{
              $t("invite.dialog.sendingMessage", {
                "0": currentSendingIndex,
                "1": numberOfEmailsToSend
              })
            }}
          </p>
          <v-progress-linear color="success" indeterminate rounded height="6" />
        </v-row>
      </div>

      <v-container v-if="inviteSent">
        <InviteSentDesign
          :sent="sent"
          :failed="failed"
          :assessmentType="assessmentType"
        />
        <v-row justify="center" class="mt-5">
          <v-btn
            rounded
            class="white--text"
            :color="mainColor"
            min-width="200px"
            @click="$emit('input', false)"
            data-cy="finish"
            >{{ $t("buttons.finish") }}</v-btn
          >
        </v-row>
      </v-container>
    </div>
  </v-card>
</template>

<script>
import {
  inviteUser,
  getTeamEndUsersEmails,
  getTeamTags,
  getEndUsers
} from "@/customApi";
import { mapMutations, mapGetters } from "vuex";
import InviteSentDesign from "./InviteSentDesign.vue";
import FeatureFlagNote from "@/components/common/FeatureFlagNote.vue";
import UpgradeSnackbar from "./UpgradeSnackbar.vue";
import MultipleEmailsInputField from "./MultipleEmailsInputField.vue";
import InviteViaFile from "./InviteViaFile.vue";
import { updateAdminChecklist } from "@/services/onboarding/adminOnboarding";
import TotalSeatsWarningBar from "@/components/dashboard/TotalSeatsWarningBar.vue";
import VitrueTooltip from "@/components/common/VitrueTooltip.vue";
import ShareableSignUpButton from "@/components/account/ShareableSignUpButton.vue";
import StringService from "@/services/string-service.js";
import VflTabHeaders from "@/components/vitrue-foundation-library/tabs/VflTabHeaders.vue";
import InviteDialogTextarea from "./InviteDialogTextarea.vue";
import {
  assessmentTypes,
  ExternalNotificationProviders
} from "@/constants/constants";
import NotificationProviderSelector from "@/components/common/notification-provider/NotificationProviderSelector.vue";

export default {
  components: {
    InviteSentDesign,
    FeatureFlagNote,
    UpgradeSnackbar,
    MultipleEmailsInputField,
    InviteViaFile,
    TotalSeatsWarningBar,
    VitrueTooltip,
    ShareableSignUpButton,
    VflTabHeaders,
    InviteDialogTextarea,
    NotificationProviderSelector
  },
  props: {
    assessmentType: String,
    preloadedUser: Object
  },
  data() {
    return {
      loading: true,
      tabs: [],
      currentTab: "singleInvite",
      bulk: false,
      multipleTags: [],
      sent: [],
      failed: [],
      emails: [],
      fileUploadEmails: [],
      currentEndUsers: [],
      showUpgradeBox: false,
      remainingInvitations: 0,
      displaySendingEmailsMessage: false,
      currentSendingIndex: 0,
      sentButtonClicked: false,
      valid: false,
      rules: {
        emailRequired(m) {
          return v => !!v || m;
        },
        emailValid(m, f) {
          return v => f(v) || m;
        }
      },
      pageInFileUpload: 0,
      canProgressInFileUpload: false,
      customizedMessage: false,
      updateBanner: false,
      teamTags: [],
      availableUsers: [],
      userMenu: false,
      selectedUser: {
        name: "",
        id: null,
        email: {
          value: null
        },
        tags: []
      },
      message: "",
      selectedProvider: ExternalNotificationProviders.EMAIL
    };
  },
  created() {
    if (this.preloadedUser) {
      this.selectedUser.email.value = this.preloadedUser.email;
      this.selectedUser.id = this.preloadedUser.id;
      this.selectedUser.name = this.preloadedUser.name;
      this.selectedUser.tags = this.mapStringsToTags(this.preloadedUser.tags);
    }
  },
  async mounted() {
    this.showUpgradeBox =
      (this.isTrialUser && this.invitesRemaining <= 3) ||
      this.trialExpiredOrAssessmentsExceeded ||
      this.subscriptionHasExpired;

    this.tabs = [
      {
        id: "singleInvite",
        text: this.$t("invite.dialog.tabs.one"),
        disabled: false
      },
      {
        id: "bulkInvite",
        text: this.$t("invite.dialog.tabs.many"),
        disabled: false
      },
      {
        id: "fileInvite",
        text: this.$t("invite.dialog.tabs.file"),
        disabled: false,
        tooltip: this.$t("tooltips.fileInvite")
      }
    ];

    try {
      this.currentEndUsers = getTeamEndUsersEmails();
      getEndUsers().then(users => {
        this.availableUsers = users;
      });
      await this.getTeamTags();
      this.mapRetrievedTagsToPreloadedUser();
    } catch (ex) {
    } finally {
      this.loading = false;
    }
  },
  methods: {
    ...mapMutations(["setFeatureIntros"]),
    async sendInvite() {
      this.$refs.form?.validate();
      if (!this.valid && this.currentTab !== "fileInvite") {
        return;
      }
      // Delay introduced because of race condition between combobox's vmodel and clicking the invite button
      // if you type into combo, don't hit return and click invite the button is executed before the emails are updated.
      this.sentButtonClicked = true;
      await this.delay(20);
      this.currentSendingIndex = 0;
      this.displaySendingEmailsMessage = true;

      if (this.currentTab === "fileInvite") {
        for (let i = 0; i < this.fileUploadEmails.length; i++) {
          let userToInvite = this.fileUploadEmails[i];
          let sanitisedTags = userToInvite.tags
            ? this.splitOutTags(userToInvite.tags)
            : [];

          await this.sendNotification(
            userToInvite.fullName,
            null,
            userToInvite.email,
            sanitisedTags
          );
        }
      } else if (this.currentTab === "singleInvite") {
        await this.sendNotification(
          this.selectedUser.name,
          this.selectedUser.id,
          this.selectedUser.email.value,
          this.mapStringsToTags(this.selectedUser.tags)
        );
      } else {
        for (let i = 0; i < this.emails.length; i++) {
          await this.sendNotification(
            "",
            null,
            this.emails[i],
            this.mapStringsToTags(this.multipleTags)
          );
        }
      }

      this.updateBanner = true;

      try {
        if (this.showOnboardingChecklist) {
          updateAdminChecklist();
        }
      } catch (err) {}
    },
    delay(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    async sendNotification(name, id, email, tags) {
      email = StringService.cleanEmail(email);
      // required because of bulk email sending
      if (!this.validEmail(email)) {
        this.currentSendingIndex++;
        this.failed.push(email);
        return;
      }
      let invitation = {
        name: name,
        userId: id,
        email: { value: email.trim() },
        tags: tags,
        message: this.customizedMessage ? this.message : null,
        assessmentType: this.assessmentType || assessmentTypes.deskAssessment,
        externalNotificationProvider: this.canSendViaExternalProvider
          ? this.selectedProvider
          : ExternalNotificationProviders.EMAIL
      };
      try {
        await inviteUser(this._i18n.locale, invitation);
        this.currentSendingIndex++;
        this.sent.push(email);
      } catch (err) {
        this.$logger.captureException(err);
        this.currentSendingIndex++;
        this.failed.push(email);
      }
    },
    validEmail(item) {
      return /.+@.+\..+/.test(item);
    },
    showUpgradeBoxOnEmailInputChange() {
      if (
        this.trialExpiredOrAssessmentsExceeded ||
        this.subscriptionHasExpired
      ) {
        this.showUpgradeBox = true;
        return;
      }
      if (this.isTrialUser) {
        if (this.numberOfEmailsToSend > this.invitesRemaining) {
          this.showUpgradeBox = true;
        } else {
          this.showUpgradeBox = this.invitesRemaining <= 3;
        }
        return;
      }

      this.showUpgradeBox = false;
    },
    splitOutTags(tags) {
      return String(tags)
        .split(",")
        .map(x => {
          return { name: x.trim() };
        });
    },
    downloadCsvTemplate() {
      const link = document.createElement("a");
      link.href =
        "https://vida-public-files.s3.eu-west-2.amazonaws.com/VIDAUploadTemplate.xls";
      link.click();
    },
    async getTeamTags() {
      try {
        let dto = {
          everythingTag: false
        };
        let tags = (await getTeamTags(dto)) || [];
        this.teamTags = tags;
      } catch (err) {
        this.teamTags = [];
      }
    },
    mapRetrievedTagsToPreloadedUser() {
      if (!this.preloadedUser) {
        return;
      }

      const filteredTags = this.teamTags.filter(x => {
        return this.preloadedUser.tags.some(t => x.name === t);
      });

      this.selectedUser.tags = filteredTags;
    },
    setSelectedUser(email) {
      var existingUser = this.availableUsers.find(x => x.email.value == email);

      if (existingUser) {
        this.selectedUser.email.value = existingUser.email.value;
        this.selectedUser.id = existingUser.id;
        this.selectedUser.name = existingUser.name;
        this.selectedUser.tags = existingUser.tags;
        return;
      }

      this.selectedUser.email.value = email;
    },
    addNewEmail(e, email) {
      let partialEmail = this.availableUsers.find(x =>
        x.email.value?.toLowerCase().includes(email?.toLowerCase())
      );
      if (!email || partialEmail) {
        // email not entered or email is part of another - do not create
        return;
      }

      this.selectedUser.id = null; // reset ID if adding new user
      this.availableUsers.push(this.selectedUser);
      this.selectedUser.email.value = email;
      this.$refs.emailDropDown.blur();
    },
    mapStringsToTags(strings) {
      return strings.map(x => {
        return typeof x === "string" ? { name: x } : x;
      });
    },
    updateMessage(event) {
      this.message = event.message;
      this.customizedMessage = !event.default;
    },
    handleProviderUpdate(provider) {
      this.selectedProvider = provider;
    }
  },
  watch: {
    currentTab() {
      this.emails = [];
      this.fileUploadEmails = [];
      this.sent = [];
      this.failed = [];
      this.name = "";
      this.tag = null;
      this.valid = false;
      this.pageInFileUpload = 0;
      this.canProgressInFileUpload = false;
      this.sentButtonClicked = false;
      this.showUpgradeBox =
        (this.isTrialUser && this.invitesRemaining <= 3) ||
        this.trialExpiredOrAssessmentsExceeded ||
        this.subscriptionHasExpired;
      this.selectedUser = {
        name: "",
        email: { value: null },
        tags: []
      };
    },
    inviteSent() {
      this.showUpgradeBox = false;
    },
    emails: {
      handler: function () {
        this.showUpgradeBoxOnEmailInputChange();
      },
      deep: true
    },
    fileUploadEmails: {
      handler: function () {
        this.showUpgradeBoxOnEmailInputChange();
      },
      deep: true
    },
    value(newVal) {
      if (!newVal && this.preloadedUser) {
        this.$emit("preloadedUser");
      }
    }
  },
  computed: {
    ...mapGetters([
      "currentUserTeamAndSubscriptionBasicInformation",
      "onboardingChecklist",
      "showOnboardingChecklist",
      "trialExpiredOrAssessmentsExceeded",
      "subscriptionHasExpired"
    ]),
    dialogTitle() {
      let path = "";

      if (this.assessmentType === assessmentTypes.deskAssessment) {
        path = "desk";
      } else if (this.assessmentType === assessmentTypes.burnoutAssessment) {
        path = "burnout";
      } else if (this.assessmentType === assessmentTypes.driverAssessment) {
        path = "driver";
      } else if (
        this.assessmentType === assessmentTypes.physicalLabourAssessment
      ) {
        path = "physical";
      }

      return this.$t(`invite.dialog.title.${path}`);
    },
    mainColor() {
      return "var(--v-info-darken1)";
    },
    textColor() {
      return this.assessmentType === assessmentTypes.deskAssessment
        ? "primary--text"
        : "accent--text";
    },
    newTotalUserText() {
      if (this.currentTab === "singleInvite") {
        return this.$t("invite.dialog.totalUsersSingle", {
          0: this.totalUsersCount
        });
      }

      return this.$t("invite.dialog.totalUsers", {
        0: this.userCountDifference,
        1: this.totalUsersCount
      });
    },
    isTrialUser() {
      return (
        this.currentUserTeamAndSubscriptionBasicInformation.accessTier == "Free"
      );
    },
    results() {
      return this.sent.concat(this.failed);
    },
    inviteSent() {
      return (
        this.currentSendingIndex === this.numberOfEmailsToSend &&
        this.sentButtonClicked
      );
    },
    displaySendingEmailsBar() {
      const allEmailsSent =
        this.currentSendingIndex === this.numberOfEmailsToSend;
      return this.displaySendingEmailsMessage && !allEmailsSent;
    },
    numberOfEmailsToSend() {
      if (this.currentTab === "fileInvite") {
        return this.fileUploadEmails.length;
      }

      if (this.currentTab === "singleInvite") {
        if (this.selectedUser.email.value) {
          return 1;
        }
      }

      return this.emails.length;
    },
    totalUsersCount() {
      var totalUsers = [...this.currentEndUsers];
      if (this.currentTab === "singleInvite" && this.selectedUser.email.value) {
        totalUsers.push(this.selectedUser.email.value);
      } else if (this.currentTab === "fileInvite") {
        this.fileUploadEmails.forEach(e => {
          totalUsers.push(e[1]);
        });
      } else {
        totalUsers = totalUsers.concat(this.emails);
      }

      return totalUsers.filter(function (curItem, index, array) {
        return index == array.indexOf(curItem);
      }).length;
    },
    userCountDifference() {
      var totalExistingUsers = [...this.currentEndUsers];
      return this.totalUsersCount - totalExistingUsers.length;
    },
    invitesRemaining() {
      const emailsAllowedBySubscription =
        this.currentUserTeamAndSubscriptionBasicInformation
          .freeTrialNumberOfAssessments;
      const emailsSentSoFar =
        this.currentUserTeamAndSubscriptionBasicInformation
          .usedAssessmentInvitationsCount;

      return emailsAllowedBySubscription - emailsSentSoFar;
    },
    inviteDisabled() {
      if (
        this.loading ||
        this.sentButtonClicked ||
        this.trialExpiredOrAssessmentsExceeded ||
        this.subscriptionHasExpired
      ) {
        return true;
      }

      if (
        this.selectedProvider != ExternalNotificationProviders.EMAIL &&
        this.selectedUser.id == null &&
        this.showExternalNotificationSelection
      ) {
        return true;
      }

      if (this.isTrialUser) {
        return (
          this.invitesRemaining <= 0 ||
          this.numberOfEmailsToSend > this.invitesRemaining
        );
      }

      return false;
    },
    inviteDisabledTooltip() {
      if (
        this.selectedProvider != ExternalNotificationProviders.EMAIL &&
        this.selectedUser.id == null &&
        this.showExternalNotificationSelection
      ) {
        return this.$t("tooltips.existingUserRequired");
      }

      if (
        this.trialExpiredOrAssessmentsExceeded ||
        this.subscriptionHasExpired
      ) {
        return this.$t("tooltips.trialOrSubscriptionExpired");
      }

      if (this.isTrialUser) {
        if (this.invitesRemaining <= 0) {
          return this.$t("tooltips.freeTrialNoMoreInvites");
        }

        if (this.numberOfEmailsToSend > this.invitesRemaining) {
          return this.$t("tooltips.freeTrialNotEnoughInvites");
        }
      }

      return "";
    },
    availableUserEmails() {
      return this.availableUsers.map(x => x.email.value);
    },
    shownTags() {
      return this.teamTags.map(x => {
        return {
          name: x.name,
          access: x.access,
          type: x.type,
          disabled: x.access.toLowerCase() !== "regular" || x.type
        };
      });
    },
    containsInaccessibleTags() {
      return this.shownTags.some(x => x.disabled);
    },
    isUsingDefaultMessageTemplate() {
      return !this.customEmailTemplate;
    },
    showExternalNotificationSelection() {
      return (
        this.assessmentType === "Desk assessment" &&
        this.currentTab === "singleInvite"
      );
    },
    canSendViaExternalProvider() {
      return (
        this.showExternalNotificationSelection && this.selectedUser != null
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.dialog-header {
  align-items: center;
  border-bottom: 1px solid hsla(0, 0%, 0%, 0.1);
  display: flex;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
}

::v-deep [role="combobox"] {
  min-height: 48px !important;
}
</style>
