/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: "eu-west-2",
  aws_cognito_identity_pool_id:
    "eu-west-2:5ff614c7-6c80-4607-9c5d-c0e96a99f7ef",
  aws_cognito_region: "eu-west-2",
  aws_user_pools_id: "eu-west-2_WFFCPDlnt",
  aws_user_pools_web_client_id: "5rpufml1ukq2uamp92nc71gmu8",
  oauth: {
    domain:
      "vitrueremote04646f76-04646f76-dev.auth.eu-west-2.amazoncognito.com",
    scope: [
      "phone",
      "email",
      "openid",
      "profile",
      "aws.cognito.signin.user.admin"
    ],
    redirectSignIn: "https://localhost:8080/",
    redirectSignOut: "https://localhost:8080/",
    responseType: "code"
  },
  federationTarget: "COGNITO_USER_POOLS",
  aws_cognito_username_attributes: [],
  aws_cognito_social_providers: [],
  aws_cognito_signup_attributes: ["EMAIL"],
  aws_cognito_mfa_configuration: "OFF",
  aws_cognito_mfa_types: ["SMS"],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: []
  },
  aws_cognito_verification_mechanisms: ["EMAIL"]
};

export default awsmobile;
