<template>
  <div class="d-flex align-center">
    <exit-assessment-button
      :class="$vuetify.breakpoint.mdAndDown ? 'mr-4' : 'mr-6'"
    />
    <vida-logo v-if="signedIn" :is-icon="$vuetify.breakpoint.mdAndDown" />
  </div>
</template>
<script>
import ExitAssessmentButton from "@/components/layout/ExitAssessmentButton.vue";
import { mapGetters } from "vuex";
import VidaLogo from "@/components/layout/VidaLogo.vue";

export default {
  name: "PageHeaderAssessment",
  components: { VidaLogo, ExitAssessmentButton },
  computed: {
    ...mapGetters(["signedIn"])
  }
};
</script>
