import { HistoryItem } from "./historyItem";
import { HISTORY_TYPES } from "@/components/user-profile/history/constants.js";
export class PainHistoryItem extends HistoryItem {
  constructor(timestamp, area, previousLevel, newLevel) {
    super(timestamp, HISTORY_TYPES.PAIN);
    this.area = area;
    this.previousLevel = previousLevel;
    this.newLevel = newLevel;
  }
}
