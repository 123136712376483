<template>
  <div v-if="hasRisksOrConditions">
    <button class="vfl-button-link" @click="openDialog" style="font-size: 14px">
      {{ $t("assessment.form.healthConditions.admin.supportDialog.button") }}
    </button>
    <v-dialog
      v-if="showDialog"
      v-model="showDialog"
      max-width="80ch"
      :fullscreen="fullscreenDialog"
    >
      <user-conditions-dialog
        :conditions="conditions"
        @close="showDialog = false"
        :name="name"
        :email="email"
        :userId="id"
        openedFrom="User Profile"
      />
    </v-dialog>
  </div>
</template>

<script>
import UserConditionsDialog from "@/components/common/pre-existing-conditions/UserConditionsDialog.vue";
import { mapAndOrderRisks } from "@/components/dashboard/tables/mapUserRisks.js";
import { mapGetters, mapState } from "vuex";
import { track } from "@/customApi.js";

export default {
  components: {
    UserConditionsDialog
  },
  data() {
    return {
      showDialog: false
    };
  },
  computed: {
    ...mapGetters("userProfile", ["name", "email", "id"]),
    ...mapState("userProfile", ["healthConditions", "mskRisks"]),
    hasRisksOrConditions() {
      return this.healthConditions?.length || this.mskRisks?.length;
    },
    fullscreenDialog() {
      return this.$vuetify.breakpoint.xs;
    },
    conditions() {
      if (!this.healthConditions?.length && !this.mskRisks?.length) {
        return [];
      }

      // Filter out severe pain and high risk conditions as we get in depth health conditions from "healthConditions"
      const mappedMskRisks = mapAndOrderRisks(this.mskRisks);
      const severePainAndHighRiskIds = mappedMskRisks
        .filter(risk => risk.id === "IntensePain" || risk.id === "HighRisk")
        .map(risk => risk.id);

      return [
        ...new Set([...severePainAndHighRiskIds, ...this.healthConditions])
      ];
    }
  },
  methods: {
    openDialog() {
      this.showDialog = true;
      track("How to support dialog opened", {
        viewedUser: this.id,
        openedFrom: "User Profile"
      });
    }
  }
};
</script>
