import { standardTheme } from "@/styles/themes.js";

export const LinkTypes = {
  REGULAR: "regularLink",
  PURCHASE: "purchaseLink",
  EMAIL: "emailLink",
  VIDEO: "videoLink",
  REQUEST: "requestLink",
  EMAILREQUEST_TTQWEL: "emailRequestLink",
  INTERNAL: "internalRoute",
  APPOINTMENT: "appointmentLink",
  TIPS: "tipsLink",
  REPORT_DOWNLOAD: "reportDownload"
};

export const InfoButtonStyles = {
  [LinkTypes.REGULAR]: {
    gradientStart: standardTheme.selectedPrimary,
    gradientEnd: standardTheme.selectedPrimary,
    iconColor: standardTheme.primary,
    icon: "mdi-lightbulb-outline"
  },
  [LinkTypes.PURCHASE]: {
    gradientStart: standardTheme.vitrueLightOrange,
    gradientEnd: standardTheme.vitrueLightOrange,
    iconColor: standardTheme.vitrueOrange,
    icon: "mdi-magnify"
  },
  [LinkTypes.EMAIL]: {
    gradientStart: standardTheme.selectedPrimary,
    gradientEnd: standardTheme.selectedPrimary,
    iconColor: standardTheme.primary,
    icon: "mdi-email-arrow-right-outline"
  },
  [LinkTypes.REQUEST]: {
    gradientStart: standardTheme.vitrueLightOrange,
    gradientEnd: standardTheme.vitrueLightOrange,
    iconColor: standardTheme.vitrueOrange,
    icon: "mdi-help"
  },
  [LinkTypes.EMAILREQUEST_TTQWEL]: {
    gradientStart: standardTheme.selectedPrimary,
    gradientEnd: standardTheme.selectedPrimary,
    iconColor: standardTheme.primary,
    icon: "mdi-email-arrow-right-outline"
  },
  [LinkTypes.INTERNAL]: {
    gradientStart: standardTheme.accent,
    gradientEnd: standardTheme.vitruePurple,
    iconColor: "white",
    icon: "mdi-rocket-launch-outline"
  },
  [LinkTypes.APPOINTMENT]: {
    gradientStart: standardTheme.vitruePalePink,
    gradientEnd: standardTheme.vitruePalePink,
    iconColor: standardTheme.accent,
    icon: "mdi-calendar-multiselect-outline"
  },
  [LinkTypes.TIPS]: {
    gradientStart: standardTheme.vitruePalePink,
    gradientEnd: standardTheme.vitruePalePink,
    iconColor: standardTheme.accent,
    icon: "mdi-handshake-outline"
  },
  [LinkTypes.REPORT_DOWNLOAD]: {
    gradientStart: standardTheme.selectedPrimary,
    gradientEnd: standardTheme.selectedPrimary,
    iconColor: standardTheme.primary,
    icon: "mdi-download"
  }
};
