import {
  ElevationGeometryMapper,
  getDimensionsForWorkArea
} from "@/services/deskassessment/elevation-planner-geometry.js";

const ScreenHeightValues = {
  average: 5,
  bad: 10
};

export function isMonitorAtEyeLevel(results) {
  let elevationPlannerOptimal = true;
  let webcamOptimal = true;

  const elevationPlannerExists = !isNaN(
    results.setup?.maxScreenHeightFromEyeline
  );

  if (results.setup && elevationPlannerExists) {
    const elevationMapper = new ElevationGeometryMapper(
      getDimensionsForWorkArea(results.setup)
    );

    elevationPlannerOptimal = elevationMapper.isUsersScreenHeightOptimal(
      results.setup.deskItems,
      results.setup.elevationEyeLevel
    );
  }

  if (results.webcam && results.webcam.screenHeightAngle !== undefined) {
    webcamOptimal = isWebcamScreenHeightOptimal(
      results.webcam.screenHeightAngle
    );
  }

  return elevationPlannerOptimal && webcamOptimal;
}

export function hasMultipleScreens(results) {
  if (!results.setup.deskItems) {
    return false;
  }

  let screenCount = 0;

  results.setup.deskItems.forEach(item => {
    if (
      item.id === "laptop" ||
      item.id === "laptopWithStand" ||
      item.id.includes("computerScreen")
    ) {
      screenCount++;
    }
  });

  return screenCount > 1;
}

function isWebcamScreenHeightOptimal(screenHeightAngle) {
  return (
    screenHeightAngle >= -ScreenHeightValues.average &&
    screenHeightAngle <= ScreenHeightValues.average
  );
}

export function isItemPresent(items, itemIds) {
  if (!Array.isArray(items) || !Array.isArray(itemIds)) {
    throw new TypeError("Both arguments must be arrays");
  }
  if (items.length === 0 || itemIds.length === 0) {
    return false;
  }
  return items.some(
    item =>
      item &&
      typeof item === "object" &&
      "id" in item &&
      itemIds.includes(item.id)
  );
}

export function isItemMissing(items, itemIds) {
  if (!Array.isArray(items) || !Array.isArray(itemIds)) {
    throw new TypeError("Both arguments must be arrays");
  }
  if (items.length === 0) {
    return true;
  }
  if (itemIds.length === 0) {
    return false;
  }
  return !items.some(
    item =>
      item &&
      typeof item === "object" &&
      "id" in item &&
      itemIds.includes(item.id)
  );
}

export function findItems(items, itemIds) {
  if (!Array.isArray(items) || !Array.isArray(itemIds)) {
    throw new TypeError("Both arguments must be arrays");
  }
  if (items.length === 0 || itemIds.length === 0) {
    return [];
  }
  return items.filter(
    item =>
      item &&
      typeof item === "object" &&
      "id" in item &&
      itemIds.includes(item.id)
  );
}

export function hasPain(results) {
  const painAreas = results?.pain?.areas;

  if (!painAreas) {
    return false;
  }

  return Object.keys(painAreas).length > 0;
}
