﻿import { getTeamFeatures, startHassleFreeAssessment } from "@/customApi";

export default {
  /// Tries to bypass the login screen and return the route to a new assessment if that feature is enabled.
  async tryBypassLogin(teamId, assessmentType) {
    let features = await getTeamFeatures(teamId);
    if (!!features && !!features.featureIds) {
      if (features.featureIds.some(id => id === "BypassLogin")) {
        let invitation = {
          assessmentType: assessmentType
        };
        let assessmentId = await startHassleFreeAssessment(invitation, teamId);
        let name =
          assessmentType === "Desk assessment"
            ? "DeskAssessment"
            : "BurnoutAssessment";
        return {
          name: name,
          params: {
            assessmentId: assessmentId
          }
        };
      }
    }
    return null;
  }
};
