import answers from "@/components/assessment/pre-existing-conditions/answers.json";
import { IDS } from "@/services/recommendations/deskassessment/ids";
import BodyAreas from "@/components/painareas/body-areas.json";

export const PAIN_RECOMMENDATION_MAPPING = {
  [IDS.PAIN_COACH]: [
    {
      painGroup: BodyAreas.midback.group,
      value: answers.pain.areas.midback.healthConditions.muscleStrainOrSprain
    },
    {
      painGroup: BodyAreas.lowerback.group,
      value: answers.pain.areas.lowerback.healthConditions.muscleStrainOrSprain
    },
    {
      painGroup: BodyAreas.lefthamstring.group,
      value: answers.pain.areas.lefthamstring.healthConditions.strain
    },
    {
      painGroup: BodyAreas.neck.group,
      value: answers.pain.areas.neck.healthConditions.muscleStrainOrSprain
    },
    {
      painGroup: BodyAreas.leftwrist.group,
      value: answers.pain.areas.leftwrist.healthConditions.sprainOrStrain
    },
    {
      painGroup: BodyAreas.leftcalf.group,
      value: answers.pain.areas.leftcalf.healthConditions.strain
    },
    {
      painGroup: BodyAreas.gluteals.group,
      value: answers.pain.areas.gluteals.healthConditions.piriformisSyndrome
    },
    {
      painGroup: BodyAreas.leftshoulder.group,
      value:
        answers.pain.areas.leftshoulder.healthConditions
          .rotatorCuffTendinitisOrTear
    },
    {
      painGroup: BodyAreas.leftankle.group,
      value: answers.pain.areas.leftankle.healthConditions.plantarFasciitis
    },
    {
      painGroup: BodyAreas.leftankle.group,
      value: answers.pain.areas.leftankle.healthConditions.achillesTendinitis
    }
  ],
  [IDS.ADJUST_YOUR_CHAIR]: [
    {
      painGroup: BodyAreas.lefthip.group,
      value: answers.pain.areas.lefthip.healthConditions.osteoarthritis
    },
    {
      painGroup: BodyAreas.lefthip.group,
      value: answers.pain.areas.lefthip.healthConditions.avascularNecrosis
    },
    {
      painGroup: BodyAreas.lefthip.group,
      value: answers.pain.areas.lefthip.healthConditions.replacement
    },
    {
      painGroup: BodyAreas.leftknee.group,
      value: answers.pain.areas.leftknee.healthConditions.replacement
    },
    {
      painGroup: BodyAreas.gluteals.group,
      value: answers.pain.areas.gluteals.healthConditions.hipOsteoarthritis
    },
    {
      painGroup: BodyAreas.gluteals.group,
      value:
        answers.pain.areas.gluteals.healthConditions.sacroiliacJointDysfunction
    },
    {
      painGroup: BodyAreas.gluteals.group,
      value: answers.pain.areas.gluteals.healthConditions.tendinopathy
    }
  ],
  [IDS.SUPPORT_BACK]: [
    {
      painGroup: BodyAreas.lowerback.group,
      value: answers.pain.areas.lowerback.healthConditions.spondylosis
    },
    {
      painGroup: BodyAreas.lowerback.group,
      value: answers.pain.areas.lowerback.healthConditions.spondylolisthesis
    },
    {
      painGroup: BodyAreas.lowerback.group,
      value: answers.pain.areas.lowerback.healthConditions.ankylosingSpondylitis
    },
    {
      painGroup: BodyAreas.lowerback.group,
      value: answers.pain.areas.lowerback.healthConditions.facetJointSyndrome
    },
    {
      painGroup: BodyAreas.lowerback.group,
      value: answers.pain.areas.lowerback.healthConditions.sciatica
    },
    {
      painGroup: BodyAreas.lowerback.group,
      value: answers.pain.areas.lowerback.healthConditions.pinchedNerve
    },
    {
      painGroup: BodyAreas.lowerback.group,
      value: answers.pain.areas.lowerback.healthConditions.spinalStenosis
    },
    {
      painGroup: BodyAreas.lowerback.group,
      value: answers.pain.areas.lowerback.healthConditions.scoliosis
    },
    {
      painGroup: BodyAreas.midback.group,
      value: answers.pain.areas.midback.healthConditions.spondylosis
    },
    {
      painGroup: BodyAreas.midback.group,
      value: answers.pain.areas.midback.healthConditions.spondylolisthesis
    },
    {
      painGroup: BodyAreas.midback.group,
      value: answers.pain.areas.midback.healthConditions.ankylosingSpondylitis
    },
    {
      painGroup: BodyAreas.midback.group,
      value: answers.pain.areas.midback.healthConditions.facetJointSyndrome
    },
    {
      painGroup: BodyAreas.midback.group,
      value: answers.pain.areas.midback.healthConditions.sciatica
    },
    {
      painGroup: BodyAreas.midback.group,
      value: answers.pain.areas.midback.healthConditions.pinchedNerve
    },
    {
      painGroup: BodyAreas.midback.group,
      value: answers.pain.areas.midback.healthConditions.spinalStenosis
    },
    {
      painGroup: BodyAreas.midback.group,
      value: answers.pain.areas.midback.healthConditions.scoliosis
    },
    {
      painGroup: BodyAreas.gluteals.group,
      value: answers.pain.areas.gluteals.healthConditions.sciatica
    },
    {
      painGroup: BodyAreas.lowerback.group,
      value: answers.pain.areas.lowerback.healthConditions.ankylosingSpondylitis
    },
    {
      painGroup: BodyAreas.midback.group,
      value: answers.pain.areas.midback.healthConditions.ankylosingSpondylitis
    }
  ],
  [IDS.BREAKS]: [
    {
      painGroup: BodyAreas.leftshoulder.group,
      value: answers.pain.areas.leftshoulder.healthConditions.sprain
    },
    {
      painGroup: BodyAreas.leftwrist.group,
      value:
        answers.pain.areas.leftwrist.healthConditions.repetitiveStrainInjury
    },
    {
      painGroup: BodyAreas.leftwrist.group,
      value:
        answers.pain.areas.leftwrist.healthConditions.deQuervainsTenosynovitis
    },
    {
      painGroup: BodyAreas.lowerback.group,
      value: answers.pain.areas.lowerback.healthConditions.ankylosingSpondylitis
    },
    {
      painGroup: BodyAreas.midback.group,
      value: answers.pain.areas.midback.healthConditions.ankylosingSpondylitis
    }
  ],
  [IDS.WRIST_REST]: [
    {
      painGroup: BodyAreas.leftwrist.group,
      value: answers.pain.areas.leftwrist.healthConditions.tendinitis
    },
    {
      painGroup: BodyAreas.leftwrist.group,
      value: answers.pain.areas.leftwrist.healthConditions.carpalTunnelSyndrome
    },
    {
      painGroup: BodyAreas.leftwrist.group,
      value: answers.pain.areas.leftwrist.healthConditions.rheumatoidArthritis
    },
    {
      painGroup: BodyAreas.leftwrist.group,
      value: answers.pain.areas.leftwrist.healthConditions.osteoarthritis
    }
  ],
  [IDS.ERGONOMIC_MOUSE]: [
    {
      painGroup: BodyAreas.leftwrist.group,
      value: answers.pain.areas.leftwrist.healthConditions.carpalTunnelSyndrome
    },
    {
      painGroup: BodyAreas.leftwrist.group,
      value: answers.pain.areas.leftwrist.healthConditions.rheumatoidArthritis
    },
    {
      painGroup: BodyAreas.leftwrist.group,
      value: answers.pain.areas.leftwrist.healthConditions.osteoarthritis
    }
  ],
  [IDS.ERGONOMIC_KEYBOARD]: [
    {
      painGroup: BodyAreas.leftwrist.group,
      value: answers.pain.areas.leftwrist.healthConditions.carpalTunnelSyndrome
    },
    {
      painGroup: BodyAreas.leftwrist.group,
      value: answers.pain.areas.leftwrist.healthConditions.rheumatoidArthritis
    },
    {
      painGroup: BodyAreas.leftwrist.group,
      value: answers.pain.areas.leftwrist.healthConditions.osteoarthritis
    }
  ]
};

export const NON_PAIN_RECOMMENDATION_MAPPING = {
  [IDS.HIGH_NOISE]: [
    {
      value: answers.setup.nonPainCondition.hearingLoss
    }
  ]
};
