<template>
  <component
    :is="component"
    :class="['divider', `is-${orientation}`]"
    :style="dividerStyle"
    :role="role"
    :aria-hidden="ariaHidden"
  />
</template>

<script>
export default {
  name: "VflDivider",
  props: {
    orientation: {
      type: String,
      default: "horizontal",
      validator: value => ["horizontal", "vertical"].includes(value)
    },
    component: {
      type: String,
      default: "hr"
    },
    color: {
      type: String,
      default: "hsla(0, 0%, 0%, 0.1)"
    }
  },
  computed: {
    dividerStyle() {
      return {
        backgroundColor: this.color
      };
    },
    role() {
      return this.component === "hr" ? undefined : "separator";
    },
    ariaHidden() {
      return this.component === "hr" ? undefined : "true";
    }
  }
};
</script>

<style scoped lang="scss">
.divider {
  margin: 0;
  flex-shrink: 0;
  border: none;

  &.is-horizontal {
    height: 1px;
    width: 100%;
  }

  &.is-vertical {
    height: 100%;
    width: 1px;
  }
}
</style>
