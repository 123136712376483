import { standardTheme } from "@/styles/themes.js";
import { ScoreBoundaries as DeskScoreBoundaries } from "@/services/deskassessment/deskassessment-scores.js";
import { ScoreBoundaries as PhysicalLabourBoundaries } from "@/services/assessment/physical-labour/score-interpretation-service.js";
import { ResilienceLevel } from "@/constants/burnout.js";

const BASE_COLORS = {
  POSITIVE: {
    background: standardTheme.vflBrightGreenLight1,
    text: standardTheme.vflBrightGreenDark2
  },
  POSITIVE_LIGHT: {
    background: standardTheme.vflBrightGreenLight2,
    text: standardTheme.vflBrightGreenDark2
  },
  WARNING: {
    background: standardTheme.vflYellowLight1,
    text: standardTheme.vflYellowDark2
  },
  CAUTION: {
    background: standardTheme.vflOrangeLight1,
    text: standardTheme.vflOrangeDark2
  },
  CRITICAL: {
    background: standardTheme.vflMagentaLight1,
    text: standardTheme.vflMagentaDark2
  }
};

export const RATING_COLORS = {
  EXCELLENT: BASE_COLORS.POSITIVE,
  GOOD: BASE_COLORS.POSITIVE_LIGHT,
  FAIR: BASE_COLORS.WARNING,
  BASIC: BASE_COLORS.CAUTION
};

export const RISK_RATING_COLORS = {
  NEGLIGIBLE: BASE_COLORS.POSITIVE,
  LOW: BASE_COLORS.POSITIVE_LIGHT,
  MEDIUM: BASE_COLORS.WARNING,
  HIGH: BASE_COLORS.CAUTION,
  VERY_HIGH: BASE_COLORS.CRITICAL
};

export const NO_DATA_COLOURS = {
  BACKGROUND: standardTheme.vflNeutralLight2,
  TEXT: standardTheme.vflNeutralDark2
};

export const getStandardRatingColors = score => {
  if (score >= DeskScoreBoundaries.VERY_GOOD) return RATING_COLORS.EXCELLENT;
  if (score >= DeskScoreBoundaries.GOOD) return RATING_COLORS.GOOD;
  if (score >= DeskScoreBoundaries.AVERAGE) return RATING_COLORS.FAIR;
  return RATING_COLORS.BASIC;
};

export const getResilienceRatingColors = resilience => {
  if (resilience.level === ResilienceLevel.HIGH) return RATING_COLORS.EXCELLENT;
  if (resilience.level === ResilienceLevel.AVERAGE) return RATING_COLORS.GOOD;
  if (resilience.level === ResilienceLevel.ROOM_FOR_IMPROVEMENT)
    return RATING_COLORS.FAIR;
  return RATING_COLORS.BASIC;
};

export const getRiskRatingColors = score => {
  if (score >= PhysicalLabourBoundaries.NEGLIGIBLE)
    return RISK_RATING_COLORS.NEGLIGIBLE;
  if (score >= PhysicalLabourBoundaries.LOW) return RISK_RATING_COLORS.LOW;
  if (score >= PhysicalLabourBoundaries.MEDIUM)
    return RISK_RATING_COLORS.MEDIUM;
  if (score >= PhysicalLabourBoundaries.HIGH) return RISK_RATING_COLORS.HIGH;
  return RISK_RATING_COLORS.VERY_HIGH;
};
