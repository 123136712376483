export const HISTORY_TYPES = {
  ASSESSMENT: "ASSESSMENT",
  PAIN: "PAIN",
  RECOMMENDATION: "RECOMMENDATION",
  ACCOUNT: "ACCOUNT",
  NEEDS_ATTENTION: "NEEDS_ATTENTION"
};

export const ASSESSMENT_HISTORY_STATES = {
  CREATED: "CREATED",
  OPENED: "OPENED",
  IN_PROGRESS: "IN_PROGRESS",
  COMPLETED: "COMPLETED",
  FAILED: "FAILED",
  
  SENDGRID_PROCESSED: "SENT",
  SENDGRID_BLOCKED: "BLOCKED",
  SENDGRID_DEFERRED: "DEFERRED",
  SENDGRID_DROPPED: "DROPPED",
  SENDGRID_BOUNCED: "BOUNCED",
  SENDGRID_DELIVERED: "DELIVERED",
  SENDGRID_UNKNOWN: "UNKNOWN"
};

export const NEEDS_ATTENTION_STATES = {
  RESOLVED: "Resolved",
  ADMINUNRESOLVED: "AdminUnresolved",
  AUTOURESOLVED: "AutoUnresolved"
};
