export const OnCloseTypes = {
  DISMISSED: "Dismissed",
  SUBMITTED: "Submitted"
};

export const FEEDBACK_VISIBILITY_DELAY = 30000;

export const showAdminFeedback = route => {
  const includedRoutes = [
    "Dashboard",
    "Analytics",
    "Suggestions",
    "Certification",
    "Users",
    "Wellness",
    "ReportsArchive",
    "Settings",
    "Subscription"
  ];

  if (!includedRoutes.includes(route?.name)) {
    return false;
  }

  try {
    const vidaEntry = localStorage.getItem("vida");

    if (vidaEntry) {
      const { adminFeedbackSeen } = JSON.parse(vidaEntry);

      if (adminFeedbackSeen) {
        const lastSeenDate = new Date(adminFeedbackSeen);

        const threeMonthsAgo = new Date();
        threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);

        if (lastSeenDate >= threeMonthsAgo) {
          return false;
        }
      }
    }
  } catch (error) {
    console.error("Error accessing local storage:", error);
  }

  return true;
};

export const markFeedbackAsSeen = () => {
  try {
    const currentTime = new Date().toISOString();

    localStorage.setItem(
      "vida",
      JSON.stringify({ adminFeedbackSeen: currentTime })
    );
  } catch (error) {
    console.error("Error accessing local storage:", error);
  }
};
