<template>
  <span v-if="showLabel" class="d-inline-flex text-body-2">
    {{ text }}
  </span>
</template>

<script>
import { getRecommendationConditions } from "./recommendations/condition-recommendation-service.js";
import { getReportCardConditions } from "./report/condition-report-card-service.js";
import { viewingOwnResults } from "@/services/privacy-service.js";

export default {
  props: {
    results: Object,
    recommendationId: String,
    infoCardId: String
  },
  computed: {
    text() {
      const labels = this.conditions.map(c => this.$t(c.label));
      const uniqueConditions = [...new Set(labels)];
      const listedConditions = this.joinWithAnd(uniqueConditions).toLowerCase();
      return this.$t("assessment.form.healthConditions.reportLabel", {
        listedConditions
      });
    },
    conditions() {
      if (this.recommendationId) {
        return getRecommendationConditions(this.results, this.recommendationId);
      } else if (this.infoCardId) {
        return getReportCardConditions(this.results, this.infoCardId);
      }
      return [];
    },
    showLabel() {
      return !!this.conditions.length && viewingOwnResults(this.$route);
    }
  },
  methods: {
    joinWithAnd(items) {
      if (items.length === 0) return "";
      if (items.length === 1) return items[0];
      const and = this.$t("other.and");
      const joinedItems = items.slice(0, -1).join(", ");
      return `${joinedItems} ${and} ${items[items.length - 1]}`;
    }
  }
};
</script>

<style scoped>
span {
  background-color: var(--v-vflBlueVeryLight-base);
  border: 1px solid var(--v-vflGreyVeryLight-base);
  border-radius: 12px;
  padding: 0.5rem 1rem;
}
</style>
