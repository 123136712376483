import { LinkTypes } from "@/services/recommendations/link-types.js";
import Cards from "./RecommendationCards.json";
import sentry from "@/sentry";
import PainService from "@/services/assessment/pain/pain-assessment-service.js";
import { assessmentKeys } from "@/components/assessment/physical-labour/utils/constants.js";
import BodyAreas from "@/components/painareas/body-areas.json";
import constants from "@/components/common/assessments/answer-constants";
import { injectHighestPainAreaIntoLocale } from "./recommendation-text-helper.js";

export default function getPainReliefRecommendations(results) {
  const recommendations = getAllRecommendations();
  const cardsToShow = recommendations.filter(r => {
    try {
      return r.showAnswer(results);
    } catch (e) {
      sentry.captureException(e);
      return false;
    }
  });
  return cardsToShow.slice(0, 3);
}

function allBodyAreas() {
  return Object.keys(BodyAreas);
}

function getAllRecommendations() {
  return [
    {
      id: "physical_labour_self_massage",
      showAnswer: results => PainService.hasPain(results),
      json: Cards.selfMassage,
      linkType: () => LinkTypes.REGULAR,
      explanation: results =>
        injectHighestPainAreaIntoLocale(
          results,
          Cards.selfMassage.explanation,
          allBodyAreas()
        )
    },
    {
      id: "physical_labour_warm_pad",
      showAnswer: results =>
        PainService.hasPainAreaAnswer(
          results,
          allBodyAreas(),
          assessmentKeys.duration,
          [
            constants.PAIN.DURATION.ONE_WEEK_TO_ONE_MONTH,
            constants.PAIN.DURATION.ONE_TO_THREE_MONTHS,
            constants.PAIN.DURATION.MORE_THAN_THREE_MONTHS
          ]
        ),
      json: Cards.warmPad,
      linkType: () => LinkTypes.REGULAR,
      heading: results =>
        injectHighestPainAreaIntoLocale(
          results,
          Cards.warmPad.heading,
          allBodyAreas()
        )
    },
    {
      id: "physical_labour_foam_roller",
      showAnswer: results =>
        PainService.hasPainArea(results, "lowerback") ||
        PainService.hasPainArea(results, "midback") ||
        PainService.hasPainArea(results, "lefthip") ||
        PainService.hasPainArea(results, "righthip") ||
        PainService.hasPainArea(results, "gluteals"),
      json: Cards.foamRoller,
      linkType: () => LinkTypes.REGULAR
    },
    {
      id: "physical_labour_ice_pack",
      showAnswer: results =>
        PainService.hasPainAreaAnswer(
          results,
          allBodyAreas(),
          assessmentKeys.duration,
          [constants.PAIN.DURATION.UP_TO_SEVEN_DAYS]
        ),
      json: Cards.icePack,
      linkType: () => LinkTypes.REGULAR
    }
  ];
}
