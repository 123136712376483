<template>
  <div class="pain-comparison mb-8">
    <p
      v-if="initialValue > 0"
      class="better text-overline mb-0"
      :style="{ gridColumn: `1/${initialValue + 1}` }"
    >
      <span v-if="initialValue > 1">{{ $t("painRating.better") }}</span>
    </p>

    <div class="current" :aria-label="`Current pain level: ${initialValue}`">
      <span class="line"></span>
      <span class="text text-center">{{ $t("painRating.lastLogged") }}</span>
    </div>

    <p
      v-if="initialValue < 10"
      class="worse text-overline mb-0"
      :style="{ gridColumn: `${initialValue + 2}/-1` }"
    >
      <span v-if="initialValue < 9">{{ $t("painRating.worse") }}</span>
    </p>
  </div>
</template>
<script>
export default {
  name: "PainRatingQuestionComparison",
  props: {
    initialValue: {
      type: Number,
      required: false
    }
  }
};
</script>

<style scoped lang="scss">
.pain-comparison {
  display: grid;
  gap: 0.25rem;
  grid-template-columns: repeat(11, minmax(10px, 1fr));
  margin-top: 1rem;

  .better,
  .worse {
    border-radius: 4px;
    line-height: 1;
    padding: 0.5rem;
    text-align: center;
  }

  .better {
    background-color: #ebf8f3;
  }

  .worse {
    background-color: #faf2f7;
  }

  .current {
    display: flex;
    justify-content: center;
    position: relative;

    .line {
      background-color: var(--v-vflNeutralDark2-base);
      display: block;
      justify-self: center;
      width: 1px;
    }

    .text {
      left: 0;
      font-size: 10px;
      margin: 0.5rem auto 0;
      position: absolute;
      right: 0;
      top: 100%;
    }
  }
}
</style>
