<template>
  <v-menu
    content-class="vfl-dropdown"
    :nudge-left="offsetLeft"
    :nudge-right="offsetRight"
    :nudge-top="offsetTop"
    :nudge-bottom="offsetBottom"
    :right="alignRight"
    :left="alignLeft"
    :bottom="alignBottom"
    :top="alignTop"
    v-model="active"
    @input="onDropdownToggle"
  >
    <template v-slot:activator="{ on, attrs }">
      <button
        v-bind="attrs"
        v-on="on"
        aria-expanded="false"
        class="primary--text font-weight-bold text-small"
        :class="activatorClassList"
      >
        <slot name="activator"></slot>
        <v-icon v-if="showToggleIcon" color="primary">{{
          active ? "mdi-chevron-up" : "mdi-chevron-down"
        }}</v-icon>
      </button>
    </template>
    <ul class="vfl-dropdown-menu">
      <li
        v-for="(item, index) in items"
        :key="index"
        class="text-body-2"
        :class="{ 'is-selected': item.selected }"
      >
        <router-link v-if="item.to" :to="item.to">{{ item.text }}</router-link>
        <button v-else @click="onSelect(item)">
          <span v-if="item.icon"
            ><v-icon color="black" size="18" class="mr-1">{{
              item.icon
            }}</v-icon></span
          >{{ item.text }}
        </button>
      </li>
    </ul>
  </v-menu>
</template>

<script>
export default {
  name: "vfl-dropdown",
  props: {
    items: {
      type: Array,
      default: () => []
    },
    offsetLeft: {
      type: Number,
      default: 0
    },
    offsetRight: {
      type: Number,
      default: 0
    },
    offsetTop: {
      type: Number,
      default: 0
    },
    offsetBottom: {
      type: Number,
      default: 0
    },
    alignLeft: {
      type: Boolean,
      default: false
    },
    alignRight: {
      type: Boolean,
      default: false
    },
    alignBottom: {
      type: Boolean,
      default: false
    },
    alignTop: {
      type: Boolean,
      default: false
    },
    showToggleIcon: {
      type: Boolean,
      default: true
    },
    activatorClassList: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      active: null
    };
  },
  methods: {
    onSelect(item) {
      this.$emit("onSelect", item);
    },
    onDropdownToggle(isOpen) {
      if (isOpen) {
        this.$emit("onOpen");
      } else {
        this.$emit("onClose");
      }
    }
  }
};
</script>

<style lang="scss">
.vfl-dropdown {
  background: white;
  border: 1px solid hsl(0, 0%, 90%);
  border-radius: 12px;
  box-shadow: 0 6px 12px #b9c0c657;
}

.vfl-dropdown-menu {
  display: flex;
  flex-direction: column;
  list-style: none;
  margin: 0;
  padding: 0.5rem 0 !important;

  li {
    &.is-selected {
      color: var(--v-primary-base);
      font-weight: bold;

      a:after,
      button:after {
        visibility: visible;
      }
    }

    a,
    button {
      align-items: center;
      display: flex;
      gap: 4px;
      line-height: 1;
      padding: 0.5rem 1rem;
      width: 100%;

      &:after {
        background: var(--v-primary-base);
        border-radius: 999px;
        content: "";
        display: block;
        height: 4px;
        visibility: hidden;
        width: 4px;
      }

      &:hover {
        background-color: var(--v-vitrueGrey-lighten5);
      }
    }
  }
}
</style>
