import { NEEDS_ATTENTION_STATES } from "@/components/user-profile/history/constants.js";
import i18n from "@/plugins/i18n";

export const getNeedAttentionEventText = item => {
  const admin = item.adminName || item.adminEmail;
  switch (item.action) {
    case NEEDS_ATTENTION_STATES.RESOLVED:
      return i18n.t("userProfile.history.needsAttention.resolved", {
        admin: admin
      });
    case NEEDS_ATTENTION_STATES.ADMINUNRESOLVED:
      return i18n.t("userProfile.history.needsAttention.adminUnresolved", {
        admin: admin
      });
    case NEEDS_ATTENTION_STATES.AUTOURESOLVED:
      return i18n.t("userProfile.history.needsAttention.autoUnresolved");
    default:
      return i18n.t("userProfile.history.needsAttention.updated");
  }
};
