import router from "@/router";
import sentry from "@/sentry";
import mixpanel from "mixpanel-browser";
import * as Constants from "@/constants/constants.js";
import { updateAssessmentState, getUserAssessmentState } from "@/customApi";
import { getAssessmentResult } from "@/services/modules/api-queries";
import store from "@/store";
import i18n from "@/plugins/i18n.js";
import UserpoolService from "@/services/userpool/userpool-service.js";

export default {
  async getAssessmentStateAndResults(
    assessmentId,
    assessmentType,
    allowSettingOpened,
    loadResults = true
  ) {
    var succeeded = false;
    var currentAttempts = 0;
    var cancelLoop = false;
    var assessmentStarted = false;
    var loadedResults = null;
    var redirectObject = null;

    store.commit("addAsyncRequest");
    let userAssessmentState = null;
    while (currentAttempts < 3 && succeeded === false && cancelLoop === false) {
      try {
        currentAttempts++;
        redirectObject = null;

        // get user assessment state information
        userAssessmentState = await getUserAssessmentState(assessmentId);
        if (!userAssessmentState) {
          throw new Error("Assessment state not found");
        }

        // handle sso information
        let idp = userAssessmentState.idp;
        let teamId = userAssessmentState.teamId;
        if (idp) {
          sessionStorage.setItem("idpName", idp);
          store.commit("setTeamIdpName", idp);
        }
        if (teamId) {
          sessionStorage.setItem("teamId", teamId);
          store.commit("setTeamId", teamId);
          await UserpoolService.setUserpoolSettingsFromId(teamId);
        }

        // set users email from assessment
        let email = userAssessmentState.email;
        if (!!email) {
          store.commit("setUserEmail", email);
        }

        // set feature flags
        if (userAssessmentState.features) {
          store.dispatch("setFeatureFlags", {
            features: userAssessmentState.features
          });
        }

        // If assessment isn't started, set to opened and exit loop
        assessmentStarted = userAssessmentState.isAssessmentStarted;
        if (!assessmentStarted) {
          if (allowSettingOpened) {
            await this.setAssessmentToOpened(assessmentId, assessmentType);
          }
          succeeded = true;
          break;
        }

        // if not signed in and assessment has been started previously - direct user to sign in / sign up based on state
        if (!store.getters.signedIn) {
          let redirectAssessment = {
            assessmentType: assessmentType,
            id: assessmentId,
            results: {}
          };

          sessionStorage.setItem(
            "reportAssessment",
            JSON.stringify(redirectAssessment)
          );

          if (userAssessmentState.hasUserSignedUp || idp) {
            redirectObject = {
              name: "Login",
              params: {
                errorMsg: i18n.t("errorMessages.assessmentStartedWithAccount"),
                isReturningToPartialAssessment: true
              }
            };
          } else {
            store.commit("setRedirectAssessment", redirectAssessment);
            redirectObject = {
              name: "SignUp",
              params: {
                assessmentId: assessmentId,
                errorMsg: i18n.t("errorMessages.assessmentStartedNoAccount"),
                isReturningToPartialAssessment: true
              }
            };
          }
          succeeded = true;
          break;
        }

        // load results
        if (loadResults) {
          loadedResults = await getAssessmentResult(
            assessmentId,
            assessmentType
          );
        }

        // force state change in hotjar to update page
        hotjarStateChange(userAssessmentState, assessmentType);

        succeeded = true;
      } catch (e) {
        sentry.captureException(e);
        sentry.captureMessage(
          `Retrieving results in assessment failed, attempt number = ${currentAttempts}`,
          "error"
        );

        redirectObject = CreateRedirectObjectOnException(e);
        if (
          e.message &&
          (e.message.includes("AssessmentNotFound") ||
            e.message.includes("UnauthorizedAssessmentAccess"))
        ) {
          cancelLoop = true;
        }
      }
    }
    store.commit("removeAsyncRequest");

    return {
      assessmentStarted: assessmentStarted,
      results: loadedResults,
      redirectObject: redirectObject,
      assessmentComplete: userAssessmentState?.isAssessmentComplete
    };
  },
  async setAssessmentToOpened(assessmentId, assessmentType) {
    try {
      mixpanel.track(`${assessmentType} opened`);
      await updateAssessmentState(assessmentId, "Opened");
    } catch (err) {
      sentry.captureException(err);
    }
  }
};

function hotjarStateChange(userAssessmentState, assessmentType) {
  if (store.getters.signedIn && userAssessmentState.isAssessmentComplete) {
    hj(
      "stateChange",
      `${router.currentRoute.path}/${Constants.assessmentReportIds[assessmentType]}`
    );
  }
}

function CreateRedirectObjectOnException(exception) {
  let redirectObject = { name: "ErrorPage" };
  if (exception.response?.status == 404) {
    redirectObject.params = {
      showTitle: false,
      message: i18n.t("deskAssessment.assessmentNotAvailableText"),
      tryAgain: false
    };
  } else if (exception.response?.status == 401) {
    redirectObject.params = {
      showTitle: false,
      message: i18n.t("deskAssessment.assessmentBelongsToOtherUser"),
      tryAgain: false
    };
  } else {
    redirectObject.params = {
      showTitle: true,
      message: i18n.t("deskAssessment.technicalErrorLoadingAssessment"),
      tryAgain: true
    };
  }
  return redirectObject;
}
