<template>
  <v-sheet
    class="pa-2"
    :class="dragOver ? 'dragOverBorder' : 'noDragBorder'"
    style="cursor: pointer"
    @dragenter="setDragOverToTrue"
    @dragleave="setDragOverToFalse"
    @dragover="setDragOverToTrue"
    @drop="dropFile"
    @keypress="onKeyPress"
    @click="$refs.fileUpload.click()"
  >
    <input
      ref="fileUpload"
      type="file"
      :accept="acceptedFiles"
      @change="inputChange"
      style="display: none"
    />
    <v-row
      class="text-center title full-height"
      :class="dragOver ? 'vitrueGrey--text' : 'white--text'"
      justify="center"
      align="center"
    >
      <v-col cols="12" md="10"
        ><div>
          <v-icon
            v-if="!dragOver"
            :color="dragOver ? 'vitrueGrey' : 'white'"
            :size="small ? 50 : 75"
            >mdi-cloud-upload-outline</v-icon
          >
          <v-icon
            v-if="dragOver"
            :color="dragOver ? 'vitrueGrey' : 'white'"
            :size="small ? 50 : 75"
            >mdi-book-plus</v-icon
          >
        </div>
        <p v-if="!small" :class="extraInformationText ? 'mb-4' : 'mb-0'">
          {{ $t("fileDropComponent.info") }}
        </p>
        <p class="mb-0 text-body-2" v-if="extraInformationText && !small">
          {{ extraInformationText }}
        </p>
        <p v-if="maxFileSizeMb && !small" class="text-caption pt-1">
          {{ $t("fileDropComponent.maxSizeTest", { "0": maxFileSizeMb }) }}
        </p>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
export default {
  name: "file-drop",
  data() {
    return {
      formUpload: false,
      dragOver: false,
      acceptedFiles: this.acceptedFileTypes ?? [
        ".csv",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.ms-excel",
        ".xlsx",
        ".xls",
        "text/x-csv",
        "application/vnd.ms-excel",
        "application/csv",
        "application/x-csv",
        "text/csv",
        "text/comma-separated-values",
        "text/x-comma-separated-values"
      ]
    };
  },
  props: {
    extraInformationText: String,
    maxFileSizeMb: Number,
    acceptedFileTypes: Array,
    small: Boolean
  },
  computed: {
    maxFileSizeBytes() {
      return this.maxFileSizeMb * 1024 * 1024;
    }
  },
  methods: {
    setDragOverToTrue(e) {
      e.preventDefault();
      this.dragOver = true;
    },
    setDragOverToFalse(e) {
      e.preventDefault();
      this.dragOver = false;
    },
    dropFile(e) {
      e.preventDefault();
      if (e.dataTransfer && e.dataTransfer.files.length > 0) {
        this.handleFileChange(e.dataTransfer.files[0]);
      }
    },
    onKeyPress(e) {
      e.preventDefault();
      if (e.key === "Enter") {
        if (this.$refs.fileUpload) {
          this.$refs.fileUpload.click();
        }
      }
    },
    inputChange(e) {
      const target = e.target;
      if (target.files) {
        this.handleFileChange(target.files[0]);
      }
    },
    handleFileChange(file) {
      if (file) {
        var typeToCheck = file.type
          ? file.type
          : "." + file.name.split(".").pop();
        if (!this.acceptedFiles.includes(typeToCheck)) {
          this.$logger.captureException(
            `Tried to upload file type of ${typeToCheck}  and failed`
          );
          this.$emit(
            "file-error-type",
            this.$t("fileDropComponent.errorFileType")
          );
        } else if (this.maxFileSizeMb && file.size > this.maxFileSizeBytes) {
          this.$emit(
            "file-error-type",
            this.$t("fileDropComponent.errorFileSize")
          );
        } else {
          this.$emit("file-error-type", "");
          this.$emit("input", file);
        }
      }
      this.dragOver = false;
    }
  }
};
</script>

<style scoped>
.dragOverBorder {
  background-color: white;
  border: 5px dashed var(--v-secondary-base);
  border-radius: 30px;
}
.noDragBorder {
  border: 5px solid transparent;
  background-color: var(--v-secondary-base);
  border-radius: 25px;
}
</style>
