import {
  STAGES,
  TREATMENT_TYPES
} from "@/components/assessment/menopause/report/constants/index.js";

export const GRAPH_TYPES = {
  ...STAGES,
  ...TREATMENT_TYPES
};

export const GRAPH_TITLES = {
  HORMONE_LEVELS: "Typical changes in female hormone oestrogen levels",
  CONTRACEPTIVE_INJECTIONS: "Hormone response to contraceptive injections",
  HRT: "Oestrogen level with HRT and without HRT"
};
