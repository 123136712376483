<template>
  <div class="welcome-page-container">
    <picture class="mb-4 mb-md-0 d-block">
      <source media="(min-width: 800px)" :srcset="`/images/${imagePath}`" />
      <img :src="`/images/${imagePathMobile}`" alt="" aria-hidden="true" />
    </picture>
    <div class="d-flex align-start flex-column pa-md-4">
      <h1 class="text-h1 mb-8 mt-md-10">
        {{ $t(`${localeRoot}.welcome.title`) }}
      </h1>
      <p>
        {{ $t(`${localeRoot}.welcome.description`) }}
      </p>
      <p class="text-body-bold mb-15">
        <span
          v-for="prop in valuePropositions"
          :key="prop"
          class="d-flex align-center"
        >
          <v-icon color="var(--v-vflViolet-base)" size="small" class="mr-2"
            >mdi-check-bold</v-icon
          >
          {{ $t(`${localeRoot}.welcome.valueProposition.${prop}`) }}
        </span>
      </p>

      <button @click="next()" class="vfl-button-primary mb-4 px-16">
        {{ $t(`${localeRoot}.welcome.next`) }}
      </button>

      <p
        class="text-body-2 vflGreyLight--text mb-0 mt-md-auto"
        v-html="$t('welcomeToAssessment.termsOfService')"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "AssessmentStartPage",
  props: {
    imagePath: {
      type: String,
      required: true
    },
    imagePathMobile: {
      type: String,
      required: true
    },
    localeRoot: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      valuePropositions: ["risks", "posture", "workspace"]
    };
  },
  methods: {
    next() {
      this.$emit("next");
    }
  }
};
</script>

<style scoped lang="scss">
$breakpoint: 800px;

.welcome-page-container {
  @media screen and (min-width: $breakpoint) {
    display: grid;
    grid-template-columns: minmax(500px, 50%) 1fr;
    grid-gap: 3rem;
    margin: 0 auto;
    max-width: 1200px;
    // container padding = 2 * 16px = 32px
    min-height: calc(var(--app-content-height) - 32px);
    padding-top: 5vh;
  }
}

picture {
  @media screen and (min-width: $breakpoint) {
    grid-column: 2;
    grid-row: 1;
    max-height: 570px;
  }

  img {
    height: 100%;
    object-fit: contain;
    width: 100%;
  }
}

button {
  width: 100%;
  font-size: 1.125rem;

  @media screen and (min-width: 600px) {
    font-size: 1rem;
    width: initial;
  }
}

.terms-service {
  ::v-deep a {
    color: inherit;
  }
}
</style>
