<template>
  <div class="notification-selector">
    <div
      class="selected-display text-body-2"
      @click="toggleDropDown"
      :class="{ 'is-open': isOpen }"
    >
      <span class="selected-text">
        {{ $t(`externalNotificationProviders.via`) }}
        <v-progress-circular
          v-if="loading"
          indeterminate
          color="vflGreen"
          size="16"
          width="2"
          class="ml-2"
        ></v-progress-circular>
        <span
          v-else
          class="method-container text-body-2 text-body-bold vflGreen--text"
        >
          <img v-if="selectedIcon" :src="selectedIcon" />
          {{ selectedLabel }}
        </span>
        <v-icon v-if="!loading" class="dropdown-arrow" color="vflGreen">
          {{ isOpen ? "mdi-chevron-up" : "mdi-chevron-down" }}
        </v-icon>
      </span>
    </div>

    <div v-if="isOpen" class="dropdown-menu">
      <div
        v-for="option in visibleProviders"
        :key="option.value"
        class="dropdown-item"
        @click.stop="selectOption(option)"
      >
        <div
          class="provider-info"
          :class="{
            selectedProvider: internalValue === option.value,
            connectedProvider: option.isConnected
          }"
        >
          <img
            v-if="option.icon"
            :src="option.icon"
            :style="
              option.value === $options.ExternalNotificationProviders.EMAIL
                ? { top: '1px', position: 'relative' }
                : null
            "
          />
          {{ option.label }}
        </div>
        <button
          v-if="!option.isConnected"
          class="vfl-link text-small text-body-bold"
          @click.stop="connect"
        >
          {{ $t("buttons.connect") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { getExternalNotificationProvider } from "@/customApi";
import { ExternalNotificationProviders } from "@/constants/constants";

const providerLocalStorageVariable = "selectedNotificationProvider";

export default {
  name: "NotificationProviderSelector",
  props: {
    value: {
      type: Number,
      default: ExternalNotificationProviders.EMAIL
    }
  },
  ExternalNotificationProviders,
  data() {
    return {
      loading: true,
      isOpen: false,
      internalValue: this.value,
      providers: [
        {
          label: this.$t(`externalNotificationProviders.email`),
          value: ExternalNotificationProviders.EMAIL,
          icon: "/images/icons/email-outline.svg",
          isConnected: true,
          show: true
        },
        {
          label: this.$t("externalNotificationProviders.msTeams"),
          value: ExternalNotificationProviders.MSTEAMS,
          icon: "/images/icons/logo-teams.svg",
          isConnected: false
        },
        {
          label: this.$t(`externalNotificationProviders.slack`),
          value: ExternalNotificationProviders.SLACK,
          icon: "/images/icons/logo-slack.svg",
          isConnected: false
        }
      ]
    };
  },
  watch: {
    value(newVal) {
      this.internalValue = newVal;
    }
  },
  computed: {
    visibleProviders() {
      const connectedProviders = this.providers.filter(p => p.isConnected);
      return connectedProviders.length > 1
        ? connectedProviders
        : this.providers;
    },
    selectedOption() {
      return this.providers.find(option => option.value === this.internalValue);
    },
    selectedLabel() {
      return this.selectedOption?.label ?? "";
    },
    selectedIcon() {
      return this.selectedOption?.icon ?? null;
    }
  },
  async mounted() {
    try {
      this.loading = true;
      await this.loadAndSetDefaultProvider();
    } finally {
      this.loading = false;
    }
  },
  methods: {
    saveProviderToLocalStorage(value) {
      localStorage.setItem(providerLocalStorageVariable, value);
    },
    getProviderFromLocalStorage() {
      return localStorage.getItem(providerLocalStorageVariable);
    },
    selectOption(option) {
      if (option.isConnected) {
        this.internalValue = option.value;
        this.$emit("input", option.value);
        this.$emit("update:provider", option.value);
        this.saveProviderToLocalStorage(option.value);
        this.isOpen = false;
      }
    },
    toggleDropDown() {
      if (this.loading) return;
      this.isOpen = !this.isOpen;
    },
    connect() {
      this.$router.push("/settings");
    },
    async loadAndSetDefaultProvider() {
      const connectedProviders = await getExternalNotificationProvider();
      connectedProviders.forEach(result => {
        const provider = this.providers.find(p => p.value === result.provider);
        if (provider) {
          provider.isConnected = true;
        }
      });

      const storedProvider = this.getProviderFromLocalStorage();
      const storedProviderValue =
        storedProvider !== null ? Number(storedProvider) : null;

      const connectedStoredProvider = this.providers.find(
        p => p.value === storedProviderValue && p.isConnected
      );

      // if provider stored in local storage and is still connected use this
      if (connectedStoredProvider) {
        this.selectOption({ value: storedProviderValue, isConnected: true });
      } else {
        // Use first available provider from list returned from back end, or fallback to email
        const firstProvider =
          connectedProviders.length > 0
            ? connectedProviders[0].provider
            : ExternalNotificationProviders.EMAIL;
        this.selectOption({
          value: firstProvider,
          isConnected: true
        });
      }
    }
  }
};
</script>

<style scoped lang="scss">
.notification-selector {
  position: relative;
}

.selected-display {
  cursor: pointer;
  padding: 0.5rem 0.75rem;
  border-radius: 12px;
  min-width: 75px;
  display: flex;
  align-items: center;
}

.selected-text {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.method-container {
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.dropdown-menu {
  position: fixed;

  z-index: 9999;

  background: white;
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  margin-top: 0.25rem;
  min-width: 200px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  overflow: hidden;

  transform-origin: top left;
}

.dropdown-menu {
  opacity: 1;
  transition: opacity 0.2s ease;
}

.dropdown-menu[v-show="false"] {
  opacity: 0;
  pointer-events: none;
}

.dropdown-item {
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.dropdown-item:hover {
  background: #f5f5f5;
}

.provider-info {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.875rem;
  color: var(--v-vflGreyLight-base); // Not connected

  &.connectedProvider {
    color: black; // Connected but not selected
  }

  &.selectedProvider {
    font-weight: 700;
    color: var(--v-vflGreen-base); // Selected state

    &::after {
      content: "";
      width: 4px;
      height: 4px;
      background-color: var(--v-vflGreen-base);
      border-radius: 50%;
    }
  }
}

.dropdown-arrow {
  margin-left: auto;
}
</style>
