<template>
  <div>
    <v-select
      v-if="!minimal"
      v-model="languageValue"
      :items="shownLanguages"
      prepend-inner-icon="mdi-earth"
      disable-lookup
      hide-details
      outlined
      :label="$t('account.personalSettings.textFieldLabels.language')"
      item-text="text"
      item-value="id"
    ></v-select>
    <div v-else style="position: relative">
      <v-menu bottom left offset-y max-height="300px">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            small
            v-bind="attrs"
            v-on="on"
            :text="showTextOnIconButton"
            @click="iconMenuOpen = !iconMenuOpen"
            elevation="0"
            :color="showTextOnIconButton ? 'white' : ''"
            :icon="!showTextOnIconButton"
            ><v-icon id="bellIcon" color="black">mdi-earth</v-icon
            ><span
              v-if="showTextOnIconButton"
              class="ml-1 icon-text-style text-body-1"
              >{{ currentLanguageText }}</span
            ></v-btn
          >
        </template>

        <v-list>
          <v-list-item
            v-for="(language, index) in shownLanguages"
            :key="index"
            link
            dense
            @click="languageValue = language.id"
          >
            <v-list-item-title
              class="text-subtitle-2"
              :class="languageValue === language.id ? 'primary--text' : ''"
            >
              {{ language.text }}</v-list-item-title
            >
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </div>
</template>

<script>
import LanguageService from "@/services/language-service.js";
import { mapGetters } from "vuex";

export default {
  props: {
    minimal: Boolean
  },
  data() {
    return {
      iconMenuOpen: false,
      currentLanguage: this._i18n.locale,
      languages: [
        { id: "en-gb", text: this.$t("languages.en-gb") },
        { id: "en-us", text: this.$t("languages.en-us") },
        { id: "es", text: this.$t("languages.es") },
        { id: "es-mx", text: this.$t("languages.es-mx") }
      ],
      demoLanguages: [
        { id: "de", text: this.$t("languages.de") },
        { id: "fr", text: this.$t("languages.fr") },
        { id: "ro", text: this.$t("languages.ro") },
        { id: "bg", text: this.$t("languages.bg") },
        { id: "cs", text: this.$t("languages.cs") },
        { id: "da", text: this.$t("languages.da") },
        { id: "fi", text: this.$t("languages.fi") },
        { id: "el", text: this.$t("languages.el") },
        { id: "hu", text: this.$t("languages.hu") },
        { id: "it", text: this.$t("languages.it") },
        { id: "no", text: this.$t("languages.no") },
        { id: "pl", text: this.$t("languages.pl") },
        { id: "pt", text: this.$t("languages.pt") },
        { id: "sk", text: this.$t("languages.sk") },
        { id: "sl", text: this.$t("languages.sl") },
        { id: "sv", text: this.$t("languages.sv") },
        { id: "ha", text: this.$t("languages.ha") },
        { id: "yo", text: this.$t("languages.yo") },
        { id: "ig", text: this.$t("languages.ig") },
        { id: "ak", text: this.$t("languages.ak") },
        { id: "ee", text: this.$t("languages.ee") }
      ]
    };
  },
  watch: {
    currentLanguage(newVal) {
      let validLanguage = this.shownLanguages.map(x => x.id).includes(newVal);

      if (validLanguage) {
        LanguageService.setLocaleValue(newVal);
      }
    }
  },
  computed: {
    ...mapGetters(["salesDemoFeatures"]),
    shownLanguages() {
      if (this.salesDemoFeatures) {
        return [...this.languages, ...this.demoLanguages];
      }
      return this.languages;
    },
    currentLanguageText: {
      get() {
        return this.shownLanguages.find(x => x.id === this.languageValue).text;
      }
    },
    languageValue: {
      set(val) {
        this.currentLanguage = val;
      },
      get() {
        return this._i18n.locale;
      }
    },
    showTextOnIconButton: {
      get() {
        return this.$vuetify.breakpoint.mdAndUp;
      }
    }
  }
};
</script>

<style scoped>
.icon-text-style {
  color: black;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
}

::v-deep .v-btn {
  text-transform: none;
}
</style>
