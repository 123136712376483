import i18n from "@/plugins/i18n.js";
import constants from "@/components/common/assessments/answer-constants";
const root = "driverAssessment.report.content";

export function GetReportCards(results) {
  const cards = getAllCards(results);
  cards.forEach(c => {
    c.text = c.text.filter(t => t.show).map(t => i18n.t(t.text));
    c.title = i18n.t(c.title);
  });
  return cards.filter(c => c.text.length > 0);
}

function getAllCards(results) {
  return [
    {
      id: "drivingSetup",
      title: `${root}.drivingSetup.title`,
      imageUrl: "driver-assessment/steeringWheel.svg",
      isGood: true,
      text: [
        {
          show: () => true,
          text: `${root}.drivingSetup.text`
        }
      ]
    },
    {
      id: "shortLongDrives",
      title: `${root}.shortLongDrives.title`,
      imageUrl: "driver-assessment/route.svg",
      isGood: true,
      text: [
        {
          show:
            results.habits.journeyLength ===
            constants.DRIVER.JOURNEY_LENGTH.LESS_THAN_ONE_HOUR,
          text: `${root}.shortLongDrives.text.lessThanOneHour`
        },
        {
          show:
            results.habits.journeyLength !==
            constants.DRIVER.JOURNEY_LENGTH.LESS_THAN_ONE_HOUR,
          text: `${root}.shortLongDrives.text.moreThanOneHour`
        },
        {
          show: true,
          text: `${root}.shortLongDrives.text.both`
        }
      ]
    },
    {
      id: "prolongedSitting",
      title: `${root}.prolongedSitting.title`,
      imageUrl: "driver-assessment/prolongedSitting.svg",
      isGood:
        results.habits.journeyLength !==
          constants.DRIVER.JOURNEY_LENGTH.MORE_THAN_2_HOURS ||
        (results.habits.journeyLength ===
          constants.DRIVER.JOURNEY_LENGTH.MORE_THAN_2_HOURS &&
          results.habits.takeBreaks !== constants.YES_NO_QUESTION.NO &&
          results.habits.breakLength !==
            constants.DRIVER.BREAK_LENGTH.LESS_THAN_FIFTEEN_MINS &&
          results.habits.driveLength <=
            constants.DRIVER.DRIVE_LENGTH.TWO_HOURS &&
          results.habits.breaksExercise !== constants.YES_NO_QUESTION.NO),
      text: [
        {
          show: true,
          text: `${root}.prolongedSitting.text.1`
        },
        {
          show: true,
          text: `${root}.prolongedSitting.text.2`
        }
      ]
    },
    {
      id: "footErgonomics",
      title: `${root}.footErgonomics.title`,
      imageUrl: "driver-assessment/deadPedal.svg",
      isGood: false,
      text: [
        {
          show: [
            results.drivingPosture.feetRelaxed,
            results.drivingPosture.deadPedalUsed
          ].includes(constants.YES_NO_QUESTION.NO),
          text: `${root}.footErgonomics.text.all`
        },
        {
          show:
            results.drivingPosture.deadPedalUsed ===
            constants.YES_NO_QUESTION.NO,
          text: `${root}.footErgonomics.text.deadPedal`
        }
      ]
    },
    {
      id: "seatSetup",
      title: `${root}.seatSetup.title`,
      imageUrl: "driver-assessment/seatSetup.svg",
      isGood:
        results.drivingPosture.leaningForwards ===
          constants.YES_NO_QUESTION.NO &&
        results.drivingPosture.pedalsWithinReach ===
          constants.YES_NO_QUESTION.YES &&
        results.drivingPosture.backSupported === constants.YES_NO_QUESTION.YES,
      text: [
        {
          show: true,
          text: `${root}.seatSetup.text.1`
        },
        {
          show: true,
          text: `${root}.seatSetup.text.2`
        }
      ]
    },
    {
      id: "legsAndHipsAlignment",
      title: `${root}.legsAndHipsAlignment.title`,
      imageUrl: "driver-assessment/hipsAndKnees.svg",
      isGood: false,
      text: [
        {
          show: [
            results.drivingPosture.seatFlat,
            results.drivingPosture.upperLegsSupported,
            results.drivingPosture.horizontalUpperLegs
          ].includes(constants.YES_NO_QUESTION.NO),
          text: `${root}.legsAndHipsAlignment.text.all`
        },
        {
          show:
            results.drivingPosture.horizontalUpperLegs ===
            constants.YES_NO_QUESTION.NO,
          text: `${root}.legsAndHipsAlignment.text.hipsKneesNotAligned`
        }
      ]
    },
    {
      id: "driversKnee",
      title: `${root}.driversKnee.title`,
      imageUrl: "driver-assessment/driverKnee.svg",
      isGood: true,
      text: [
        {
          show: true,
          text: `${root}.driversKnee.text`
        }
      ]
    },
    {
      id: "neckHealth",
      title: `${root}.neckHealth.title`,
      imageUrl: "driver-assessment/mirror.svg",
      isGood: false,
      text: [
        {
          show: [
            results.drivingPosture.mirrorsEasilyVisible,
            results.drivingPosture.roadVisibleOverWheel,
            results.drivingPosture.wheelDashboardView,
            results.drivingPosture.sufficientHeadroom,
            results.drivingPosture.headrestSupportsHead
          ].includes(constants.YES_NO_QUESTION.NO),
          text: `${root}.neckHealth.text.intro`
        },
        {
          show: [
            results.drivingPosture.dashboardReadable,
            results.drivingPosture.wheelDashboardView
          ].includes(constants.YES_NO_QUESTION.NO),
          text: `${root}.neckHealth.text.visibility`
        },
        {
          show:
            results.drivingPosture.headrestSupportsHead ===
            constants.YES_NO_QUESTION.NO,
          text: `${root}.neckHealth.text.headrest`
        },
        {
          show: [
            results.drivingPosture.mirrorsEasilyVisible,
            results.drivingPosture.roadVisibleOverWheel,
            results.drivingPosture.wheelDashboardView,
            results.drivingPosture.sufficientHeadroom,
            results.drivingPosture.headrestSupportsHead
          ].includes(constants.YES_NO_QUESTION.NO),
          text: `${root}.neckHealth.text.outro`
        }
      ]
    },
    {
      id: "handWristArmHealth",
      title: `${root}.handWristArmHealth.title`,
      imageUrl: "driver-assessment/handWristArmHealth.svg",
      isGood: () => false,
      text: [
        {
          show:
            [
              results.drivingPosture.truckElbowsLocked,
              results.drivingPosture.wheelGripTension
            ].includes(constants.YES_NO_QUESTION.YES) ||
            results.drivingPosture.truckElbowsClose ===
              constants.YES_NO_QUESTION.NO,
          text: `${root}.handWristArmHealth.text.intro`
        },
        {
          show:
            results.drivingPosture.truckElbowsClose ===
            constants.YES_NO_QUESTION.NO,
          text: `${root}.handWristArmHealth.text.elbowsClose`
        },
        {
          show:
            results.drivingPosture.truckElbowsLocked ===
            constants.YES_NO_QUESTION.YES,
          text: `${root}.handWristArmHealth.text.lockedElbow`
        },
        {
          show:
            [
              results.drivingPosture.truckElbowsLocked,
              results.drivingPosture.wheelGripTension
            ].includes(constants.YES_NO_QUESTION.YES) ||
            results.drivingPosture.truckElbowsClose ===
              constants.YES_NO_QUESTION.NO,
          text: `${root}.handWristArmHealth.text.outro`
        }
      ]
    },
    {
      id: "transmission",
      title: `${root}.transmission.title`,
      imageUrl: "driver-assessment/manualAutomatic.svg",
      isGood: true,
      text: [
        {
          show:
            results.vehicle.transmission ===
              constants.DRIVER.TRANSMISSION.MANUAL ||
            results.vehicle.transmission ===
              constants.DRIVER.TRANSMISSION.AUTOMATIC,
          text: `${root}.transmission.text.manualAutomatic`
        },
        {
          show:
            results.vehicle.transmission ===
            constants.DRIVER.TRANSMISSION.ELECTRIC,
          text: `${root}.transmission.text.electric`
        }
      ]
    }
  ];
}
