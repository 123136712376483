<template>
  <div :class="classList">
    <button
      data-test-id="menopause-assessment-navigation-previous"
      @click="previous"
      class="vfl-button-primary-outline"
    >
      {{ $t("buttons.back") }}
    </button>
    <button
      data-test-id="menopause-assessment-navigation-next"
      @click="next"
      class="vfl-button-primary"
      :class="{ 'is-loading': loading }"
    >
      {{ $t("buttons.continue") }}
    </button>
  </div>
</template>

<script>
export default {
  name: "Navigation",
  props: {
    loading: Boolean,
    classList: String
  },
  methods: {
    previous() {
      this.$emit("previous");
    },
    next() {
      this.$emit("next");
    }
  }
};
</script>

<style scoped lang="scss">
div {
  display: flex;
  gap: 0.5rem;
  max-width: 100%;
}

button {
  min-width: 10rem;
}
</style>
