import { ScoreBoundaries as DeskScoreBoundaries } from "@/services/deskassessment/deskassessment-scores.js";
import i18n from "@/plugins/i18n.js";
import { ScoreBoundaries as PhysicalLabourBoundaries } from "@/services/assessment/physical-labour/score-interpretation-service.js";
import { SCORE_BOUNDARY_VALUES as DriverScoreBoundaries } from "@/services/driver-assessment/driver-assessment-scores.js";
import { ResilienceLevel } from "@/constants/burnout.js";
import BurnoutScores from "@/services/burnout/burnout-scores.js";
import { Keys } from "@/components/dashboard/cards/average-scores/constants.js";

export const getDescriptorForDeskScore = score => {
  if (score >= DeskScoreBoundaries.VERY_GOOD)
    return i18n.t(`${Keys.RATING}.regular.excellent`);
  if (score >= DeskScoreBoundaries.GOOD)
    return i18n.t(`${Keys.RATING}.regular.good`);
  if (score >= DeskScoreBoundaries.AVERAGE)
    return i18n.t(`${Keys.RATING}.regular.fair`);
  return i18n.t(`${Keys.RATING}.regular.basic`);
};

export const getDescriptorForPhysicalLabour = score => {
  if (score >= PhysicalLabourBoundaries.NEGLIGIBLE)
    return i18n.t(`${Keys.RATING}.risk.negligible`);
  if (score >= PhysicalLabourBoundaries.LOW)
    return i18n.t(`${Keys.RATING}.risk.low`);
  if (score >= PhysicalLabourBoundaries.MEDIUM)
    return i18n.t(`${Keys.RATING}.risk.medium`);
  if (score >= PhysicalLabourBoundaries.HIGH)
    return i18n.t(`${Keys.RATING}.risk.high`);
  return i18n.t(`${Keys.RATING}.risk.veryHigh`);
};

export const getDescriptorForDriver = score => {
  if (score >= DriverScoreBoundaries.GREAT)
    return i18n.t(`${Keys.RATING}.risk.negligible`);
  if (score >= DriverScoreBoundaries.GOOD)
    return i18n.t(`${Keys.RATING}.risk.low`);
  if (score >= DriverScoreBoundaries.AVERAGE)
    return i18n.t(`${Keys.RATING}.risk.medium`);
  return i18n.t(`${Keys.RATING}.risk.high`);
};

export const ADMIN_RESILIENCE_DESCRIPTORS = {
  [ResilienceLevel.NEEDS_ATTENTION]: `${Keys.RATING}.regular.basic`,
  [ResilienceLevel.ROOM_FOR_IMPROVEMENT]: `${Keys.RATING}.regular.fair`,
  [ResilienceLevel.AVERAGE]: `${Keys.RATING}.regular.good`,
  [ResilienceLevel.HIGH]: `${Keys.RATING}.regular.excellent`
};

export const getAdminResilienceDescriptor = score => {
  const resilienceLevel = BurnoutScores.getResilienceFromScore(
    score,
    "overall"
  ).level;

  return i18n.t(ADMIN_RESILIENCE_DESCRIPTORS[resilienceLevel]);
};
