import sentry from "@/sentry.js";
import { AccountHistoryItem } from "@/components/user-profile/history/item-models/accountHistoryItem.js";

function processAccountHistory(history) {
  try {
    return history.map(
      item => new AccountHistoryItem(item.date, item.accountEvent)
    );
  } catch (error) {
    sentry.captureException(error);
    return [];
  }
}

export { processAccountHistory };
