<template>
  <div>
    <v-icon class="mb-1" color="vflGreyLight">mdi-archive-lock-outline</v-icon>
    <p class="text-body-2 text-body-bold mb-0">
      {{ $t("aiAssistant.endOfConversation") }}
    </p>
  </div>
</template>

<script>
export default {
  name: "AiAssistantEndOfConversation"
};
</script>

<style scoped>
p {
  color: var(--v-vflGreyLight-base);
}
</style>
