<template>
  <div
    class="d-flex justify-space-around align-center"
    @mouseleave="$emit('hover', null)"
  >
    <div
      v-for="reaction in reactions"
      :key="reaction.value"
      :class="`mx-${spacing}`"
    >
      <v-row no-gutters justify="center">
        <vue-reaction-emoji
          :reaction="reaction.emoji"
          :is-active="value ? value.value === reaction.value : false"
          @input="$emit('input', reaction)"
          @mouseover.native="$emit('hover', reaction)"
        />
      </v-row>
      <p
        v-if="showLabels"
        :class="
          value === reaction.value
            ? 'text-body-1 font-weight-bold'
            : 'text-caption'
        "
        class="mb-0 text-center"
        :style="labelColor"
      >
        {{ $t(reaction.label) }}
      </p>
    </div>
  </div>
</template>

<script>
import { VueReactionEmoji } from "vue-feedback-reaction";
import EmojiReactions from "@/assets/json/common/EmojiReactions.json";

export default {
  name: "FeedbackEmojis",
  components: {
    VueReactionEmoji
  },
  props: {
    value: Object,
    showLabels: {
      type: Boolean,
      default: true
    },
    options: Array,
    spacing: {
      type: String,
      default: "2"
    }
  },
  created() {
    if (this.options && this.options.length > 0) {
      this.reactions = [...this.options];
    }
  },
  data() {
    return {
      reactions: EmojiReactions
    };
  },

  computed: {
    labelColor() {
      return "color:black;";
    }
  }
};
</script>
