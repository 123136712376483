<template>
  <v-card class="pa-4 pt-0">
    <v-btn @click="$emit('inputClose')" class="close-button" icon color="black">
      <v-icon>clear</v-icon>
    </v-btn>
    <v-row justify="center" class="pt-4">
      <v-img :src="'/images/' + imageName" max-width="200px" />
    </v-row>
    <v-row align="center" justify="center">
      <v-col cols="10" class="text-center">
        <p class="text-h5">{{ headingText }}</p>
        <v-form ref="form" v-model="valid" @submit.prevent>
          <v-text-field
            v-if="reportSent === false"
            outlined
            :rules="emailRules"
            v-model="email"
            :label="$t('textFields.email')"
            type="email"
            prepend-inner-icon="email"
            @keydown.enter="sendReport"
          />
        </v-form>
        <p class="text-body-1 warning--text" v-if="showError">
          {{ $t("deskAssessment.results.sendDialog.errorMessage") }}
        </p>
      </v-col>
    </v-row>
    <v-row justify="center" v-if="reportSent === false">
      <v-col sm="6" md="4">
        <v-btn
          id="cancelButton"
          color="primary"
          large
          outlined
          rounded
          width="100%"
          @click="$emit('inputClose')"
          >{{ $t("buttons.cancel") }}</v-btn
        >
      </v-col>
      <v-col sm="6" md="4">
        <v-btn
          id="sendButton"
          color="primary"
          large
          rounded
          width="100%"
          @click="sendReport"
          >{{ $t("buttons.send") }}</v-btn
        >
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { sendReport } from "../../../customApi";
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "SendReportDialog",
  components: {},
  props: { assessmentID: String, assessmentType: String },
  data() {
    const store = this.$store;
    return {
      valid: false,
      reportSent: false,
      email: store.state.userEmail,
      showError: false,
      emailRules: [
        v => !!v || this.$t("inputRules.emailExists"),
        v => /.+@.+\..+/.test(v) || this.$t("inputRules.emailFormat")
      ]
    };
  },
  computed: {
    ...mapGetters(["userEmail"]),
    imageName() {
      return this.reportSent ? "email_confirmation.png" : "mailbox.svg";
    },
    headingText() {
      return this.reportSent
        ? this.$t("deskAssessment.results.sendDialog.confirmationMessage", {
            0: this.email
          })
        : this.$t("deskAssessment.results.sendDialog.aboutToSendMessage");
    }
  },
  methods: {
    ...mapMutations(["addAsyncRequest", "removeAsyncRequest"]),
    sendReport() {
      if (this.valid) {
        this.addAsyncRequest();
        let assessmentDto = {
          id: this.assessmentID,
          assessmentType: this.assessmentType
        };
        sendReport(assessmentDto)
          .then(() => {
            this.reportSent = true;
            this.showError = false;
            this.removeAsyncRequest();
          })
          .catch(err => {
            this.showError = true;
            this.removeAsyncRequest();
          });
      }
    }
  }
};
</script>
