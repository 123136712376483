import i18n from "@/plugins/i18n";
import PainService from "@/services/assessment/pain/pain-assessment-service.js";
import { rebaPainAreas } from "@/components/assessment/physical-labour/utils/constants.js";
import BodyAreas from "@/components/painareas/body-areas.json";
import sentry from "@/sentry.js";

function injectBodyPartIntoLocale(localePath, area) {
  return i18n.t(localePath, { bodyPart: area.toLowerCase() });
}

function injectHighestPainAreaIntoLocale(results, localePath, areas = "*") {
  const painAreas = areas === "*" ? Object.values(rebaPainAreas).flat() : areas;
  const area = getHighestPainArea(results, painAreas);
  return injectBodyPartIntoLocale(localePath, area);
}

function getHighestPainArea(results, areas) {
  const painAreas = PainService.getRelevantPainAreas(results, areas);
  if (painAreas.length === 0) {
    sentry.captureMessage(
      `No matching pain area found for areas ${areas}`,
      "error"
    );
    return "";
  }
  const maxPainArea = painAreas.reduce((prev, current) => {
    return prev.level > current.level ? prev : current;
  });
  return i18n.t(BodyAreas[maxPainArea.area].shortenedName);
}

export { injectBodyPartIntoLocale, injectHighestPainAreaIntoLocale };
