import sentry from "@/sentry.js";
import { NeedsAttentionHistoryItem } from "@/components/user-profile/history/item-models/needsAttentionHistoryItem";

function processNeedsAttentionHistory(history) {
  try {
    return history.map(
      item =>
        new NeedsAttentionHistoryItem(
          item.date,
          item.action,
          item.adminName,
          item.adminEmail
        )
    );
  } catch (error) {
    sentry.captureException(error);
    return [];
  }
}

export { processNeedsAttentionHistory };
