<template>
  <div class="d-flex align-center">
    <button class="vfl-button-primary-neutral is-medium" @click="exit">
      {{ $t("layout.topbar.exit") }}
    </button>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "PageHeaderGame",
  computed: {
    ...mapGetters(["signedIn"])
  },
  methods: {
    exit() {
      this.$router.back();
    }
  }
};
</script>
