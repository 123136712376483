<template>
  <section :class="$vuetify.breakpoint.xs ? 'mx-n4' : 'mx-n8'">
    <div class="outer-container">
      <div class="container py-0 px-8">
        <LanguageSelection minimal class="language-selector" />
        <div class="transport-image" aria-hidden="true"></div>
        <div
          class="content d-flex flex-column"
          :class="
            $vuetify.breakpoint.xs ? 'justify-start pt-8' : 'justify-center'
          "
        >
          <h1 class="text-h1 text-body-darkest">
            {{ $t(`driverAssessment.welcome.title`) }}
          </h1>
          <p
            class="text-medium text-body-dark mt-6"
            v-html="$t('driverAssessment.welcome.description')"
          />

          <ul class="pa-0 mt-6">
            <li>
              {{ $t("driverAssessment.welcome.valueProposition.risks") }}
            </li>
            <li>
              {{ $t("driverAssessment.welcome.valueProposition.posture") }}
            </li>
            <li>
              {{ $t("driverAssessment.welcome.valueProposition.workspace") }}
            </li>
          </ul>
          <button
            class="vfl-button-primary is-medium mt-16 mt-md-10"
            @click="onNext"
          >
            {{ $t("driverAssessment.welcome.next") }}
          </button>
          <p
            class="text-body-2 mt-6 mb-8 disclaimer"
            v-html="$t('welcomeToAssessment.termsOfService')"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import LanguageSelection from "@/components/common/LanguageSelection.vue";

export default {
  name: "DriverAssessmentLanding",
  components: { LanguageSelection },
  mounted() {
    document.body.classList.add("assessment-landing-page");
  },
  beforeDestroy() {
    document.body.classList.remove("assessment-landing-page");
  },
  methods: {
    onNext() {
      this.$emit("next");
    }
  }
};
</script>

<style lang="scss" scoped>
@mixin container-query($breakpoint) {
  @if $breakpoint == large {
    @container (min-width: 1500px) {
      @content;
    }
  } @else if $breakpoint == medium {
    @container (max-width: 1024px) {
      @content;
    }
  } @else if $breakpoint == small {
    @container (max-width: 680px) {
      @content;
    }
  } @else if $breakpoint == xsmall {
    @container (max-width: 480px) {
      @content;
    }
  }
}

section {
  --container-max-width: 1320px;
  --container-max-width-large: 1600px;
  --container-max-width-x-large: 1800px;
  --container-min-height: calc(100vh - var(--topbar-height));

  container-type: inline-size;
}

.outer-container {
  position: relative;
}

.container {
  display: grid;
  grid-row-gap: 2rem;
  grid-template-columns: 1fr 1fr;
  max-width: var(--container-max-width);
  min-height: var(--container-min-height);

  @include container-query(medium) {
    grid-template-columns: 1fr;
  }

  @media screen and (min-width: 1500px) {
    max-width: var(--container-max-width-large);
  }

  @media screen and (min-width: 1800px) {
    max-width: var(--container-max-width-x-large);
  }
}

.language-selector {
  position: absolute;
  right: 1rem;
  top: 1rem;
}

.content {
  max-width: 50ch;
  position: relative;

  p {
    font-size: 1.125rem !important;
  }
}

.transport-image {
  background-image: url("/images/driver-assessment/transport.svg");
  background-size: cover;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;

  @include container-query(medium) {
  }

  @include container-query(xsmall) {
  }
}

ul {
  display: flex;
  list-style-type: none;

  @include container-query(small) {
    flex-direction: column;
  }

  @include container-query(xsmall) {
    display: none;
  }
}

li {
  align-items: center;
  color: var(--v-vflViolet-base);
  display: flex;
  font-weight: 700;
  gap: 0.5rem;
  margin-right: 0.5rem;

  + li:before {
    background-color: var(--v-vflViolet-base);
    border-radius: 999px;
    content: "";
    display: block;
    height: 4px;
    width: 4px;

    @include container-query(small) {
      content: none;
    }
  }
}

img {
  display: block;
  height: auto;
  max-width: 100%;
}

.vfl-button-primary {
  align-self: start;
  min-width: 200px;
  min-height: 48px;

  @include container-query(xsmall) {
    min-width: 100%;
  }
}

.disclaimer {
  ::v-deep a {
    color: inherit;
  }
}
</style>
