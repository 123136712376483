import {
  getAssessmentInviteTemplate,
  postAssessmentInviteTemplate,
  putAssessmentInviteTemplate,
  deleteAssessmentInviteTemplate
} from "@/customApi";
import mixpanel from "mixpanel-browser";

export const fetchMessageTemplates = async assessmentType => {
  try {
    const template = await getAssessmentInviteTemplate(assessmentType);

    return { data: template, error: null };
  } catch (error) {
    return { data: null, error: error };
  }
};

export const createMessageTemplate = async (assessmentType, message) => {
  try {
    const id = await postAssessmentInviteTemplate(assessmentType, message);
    mixpanel.track(`Created invite email message template`);
    return { data: id, error: null };
  } catch (error) {
    return { data: null, error: error };
  }
};

export const updateMessageTemplate = async (id, assessmentType, message) => {
  try {
    await putAssessmentInviteTemplate(id, assessmentType, message);
    mixpanel.track(`Updated invite email message template`);
    return { data: true, error: null };
  } catch (error) {
    return { data: null, error: error };
  }
};

export const deleteInviteTemplate = async id => {
  try {
    await deleteAssessmentInviteTemplate(id);
    mixpanel.track(`Deleted invite email message template`);
    return { data: true, error: null };
  } catch (error) {
    return { data: null, error: error };
  }
};
