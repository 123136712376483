import enGB from "./en-gb.json";
import enUS from "./en-us.json";
import es from "./es.json";
import latam from "./latam.json";

export default {
  isLocaleValid(localeToCompare) {
    var matching = false;
    Object.keys(this.messages).forEach(element => {
      if (localeToCompare === element) {
        matching = true;
      }
    });
    return matching;
  },
  messages: {
    "en-gb": enGB,
    "en-us": enUS,
    es: es,
    "es-mx": latam
  }
};
