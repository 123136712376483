﻿export const PhysicalLabourAssessmentSteps = {
  Welcome: {
    INDEX: -1,
    THEME: "welcome",
    STEPPER: false,
    NAVIGATION: false,
    FORM: false,
    VALIDATION: false
  },
  Sectors: {
    INDEX: 0,
    THEME: "sectors",
    STEPPER: false,
    NAVIGATION: true,
    FORM: true,
    VALIDATION: true
  },
  PainIntro: {
    INDEX: 1,
    THEME: "painIntro",
    STEPPER: false,
    NAVIGATION: true,
    FORM: false,
    VALIDATION: false
  },
  Pain: {
    INDEX: 2,
    THEME: "pain",
    STEPPER: true,
    NAVIGATION: true,
    FORM: true,
    VALIDATION: true
  },
  JobNature: {
    INDEX: 3,
    THEME: "jobNature",
    STEPPER: true,
    NAVIGATION: true,
    FORM: true,
    VALIDATION: true
  },
  JobNatureActivities: {
    INDEX: 4,
    THEME: "jobNature",
    STEPPER: true,
    NAVIGATION: true,
    FORM: true,
    VALIDATION: true
  },
  ChooseActivity: {
    INDEX: 5,
    THEME: "reba",
    STEPPER: true,
    NAVIGATION: true,
    FORM: true,
    VALIDATION: true
  },
  RebaNeck: {
    INDEX: 6,
    THEME: "reba",
    STEPPER: true,
    NAVIGATION: true,
    FORM: true,
    VALIDATION: true
  },
  RebaTrunk: {
    INDEX: 7,
    THEME: "reba",
    STEPPER: true,
    NAVIGATION: true,
    FORM: true,
    VALIDATION: true
  },
  RebaArms: {
    INDEX: 8,
    THEME: "reba",
    STEPPER: true,
    NAVIGATION: true,
    FORM: true,
    VALIDATION: true
  },
  RebaWrists: {
    INDEX: 9,
    THEME: "reba",
    STEPPER: true,
    NAVIGATION: true,
    FORM: true,
    VALIDATION: true
  },
  RebaLegs: {
    INDEX: 10,
    THEME: "reba",
    STEPPER: true,
    NAVIGATION: true,
    FORM: true,
    VALIDATION: true
  },
  RebaRefineExperience: {
    INDEX: 11,
    THEME: "reba",
    STEPPER: true,
    NAVIGATION: true,
    FORM: true,
    VALIDATION: true
  },
  Health: {
    INDEX: 12,
    THEME: "health",
    STEPPER: true,
    NAVIGATION: true,
    FORM: true,
    VALIDATION: true
  },
  Report: {
    INDEX: 13,
    THEME: "report",
    STEPPER: false,
    NAVIGATION: false,
    FORM: false,
    VALIDATION: false
  }
};

export const getStepByIndex = index => {
  return (
    Object.entries(PhysicalLabourAssessmentSteps).find(
      ([key, value]) => value.INDEX === index
    )?.[1] || null
  );
};
