<template>
  <section-header :title="$t('averageScoresOverview.title')">
    <template #actionButton>
      <dashboard-card-download-button
        class="ml-2"
        :screenReader="$t('averageScoresOverview.download.screenReader')"
        downloadElement="average-scores-container"
        :imageName="$t('averageScoresOverview.download.imageName')"
        eventName="Average scores downloaded"
        :on-clone="onClone"
      />
    </template>
  </section-header>
</template>

<script>
import SectionHeader from "@/components/dashboard/cards/common/DashboardSectionHeader.vue";
import DashboardCardDownloadButton from "@/components/dashboard/cards/common/DashboardCardDownloadButton.vue";

export default {
  name: "AverageScoresHeader",
  components: {
    SectionHeader,
    DashboardCardDownloadButton
  },
  methods: {
    onClone(clonedDocument) {
      this.$emit("onImageClonedForDownload", clonedDocument);
    }
  }
};
</script>
