const ScoreBoundaries = {
  POOR: 0,
  AVERAGE: 35,
  GOOD: 56,
  VERY_GOOD: 78,
  MAX: 100
};

function deskAssessmentScoreColor(score) {
  switch (true) {
    case score >= ScoreBoundaries.VERY_GOOD:
      return { color: "vflBrightGreenDark1", textColor: "vflBrightGreenDark2" };
    case score >= ScoreBoundaries.GOOD:
      return { color: "vflBrightGreen", textColor: "vflBrightGreenDark2" };
    case score >= ScoreBoundaries.AVERAGE:
      return { color: "vflOrange", textColor: "vflOrangeDark1" };
    case score >= ScoreBoundaries.POOR:
      return { color: "vflMagenta", textColor: "vflMagentaDark2" };
  }
}

export { ScoreBoundaries, deskAssessmentScoreColor };
