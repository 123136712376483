<template>
  <v-row class="mt-4">
    <v-col cols="12">
      <health-condition-recommendations-banner
        v-if="viewingOwnResults && enablePreExistingConditions"
        :results="results"
    /></v-col>
    <v-col
      v-for="card in recommendations"
      :key="card.id"
      cols="12"
      sm="6"
      md="6"
      lg="4"
      xl="4"
    >
      <recommendations-card
        class="fill-height"
        :ref="card.id"
        :json="card.json"
        :id="card.id"
        :linkType="card.linkType(results)"
        :assessmentId="assessmentId"
        :heading="$t(card.json.heading)"
        :subheading="$t(card.json.subheading)"
        :explanation="$t(card.json.explanation)"
        :link="card.json.link"
        :deskAssessment="results"
        @update="cardUpdated($event)"
        :loadedState="getCardState(card.id)"
      />
    </v-col>
  </v-row>
</template>

<script>
import { getRecommendations } from "./report-recommendation-generator.js";
import RecommendationsCard from "@/components/common/report/cards/Recommendation/RecommendationsCard.vue";
import RecommendationInteractionMixin from "@/components/common/report/cards/Recommendation/RecommendationInteractionMixin.js";
import { mapGetters } from "vuex";
import { viewingOwnResults } from "@/services/privacy-service.js";
import HealthConditionRecommendationsBanner from "@/components/common/report/HealthConditionRecommendationsBanner.vue";

export default {
  name: "RecommendationContent",
  components: {
    RecommendationsCard,
    HealthConditionRecommendationsBanner
  },
  props: {
    results: Object,
    assessmentId: String
  },
  mixins: [RecommendationInteractionMixin],
  async created() {
    await this.getRecommendationInteractions(this.assessmentId);
    this.recommendations = getRecommendations(this.results);
  },
  watch: {
    results() {
      this.recommendations = getRecommendations(this.results);
    }
  },
  data() {
    return {
      recommendations: []
    };
  },
  computed: {
    ...mapGetters(["enablePreExistingConditions"]),
    viewingOwnResults() {
      return viewingOwnResults(this.$route);
    }
  }
};
</script>
