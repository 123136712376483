<template>
  <ai-assistant-loader v-if="isLoadingConversations" />

  <div v-else class="pt-4 pb-4 px-4">
    <div v-if="conversations.length">
      <div
        v-for="(conversation, conversationIndex) in conversations"
        :key="conversationIndex"
        class="conversation"
      >
        <ai-assistant-message-divider
          :text="getConversationHeader(conversationIndex, conversation)"
        />
        <div
          v-for="(message, messageIndex) in conversation.messages"
          :key="messageIndex"
        >
          <AiAssistantUserMessage
            v-if="message.role === $options.roles.USER"
            :content="message.content"
          />

          <AiAssistantMessage
            v-else
            :interactionId="message.id"
            :content="message.content"
            :isTemp="message.isTemp"
            :isLast="isLastMessage(conversationIndex, messageIndex)"
            :is-system="message.role === $options.roles.SYSTEM"
          />

          <AiAssistantErrorMessage v-if="message.error" />
        </div>
        <ai-assistant-end-of-conversation
          class="ml-5"
          v-if="conversationIndex + 1 !== conversations.length"
        />
      </div>
    </div>

    <ai-assistant-empty-state v-if="showEmptyState" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import AiAssistantMessage from "@/components/AiAssistant/WindowComponents//AiAssistantMessage.vue";
import AiAssistantUserMessage from "@/components/AiAssistant/WindowComponents//AiAssistantUserMessage.vue";
import AiAssistantEmptyState from "@/components/AiAssistant/WindowComponents/AiAssistantEmptyState.vue";
import AiAssistantLoader from "@/components/AiAssistant/WindowComponents/AiAssistantLoader.vue";
import AiAssistantMessageDivider from "@/components/AiAssistant/WindowComponents/AiAssistantMessageDivider.vue";
import AiAssistantEndOfConversation from "@/components/AiAssistant/WindowComponents/AiAssistantEndOfConversation.vue";
import AiAssistantErrorMessage from "@/components/AiAssistant/WindowComponents/AiAssistantErrorMessage.vue";
import { getConversationHeader } from "@/components/AiAssistant/dateFormatterUtil.js";
import AiAssistantBubbleIcon from "@/components/AiAssistant/WindowComponents/AiAssistantBubbleIcon.vue";
import AiAssistantWindowScroller from "@/components/AiAssistant/WindowComponents/AiAssistantWindowScroller.vue";
import { Role } from "@/components/AiAssistant/constants.js";

export default {
  name: "AiAssistantWindowBody",
  components: {
    AiAssistantWindowScroller,
    AiAssistantBubbleIcon,
    AiAssistantLoader,
    AiAssistantMessage,
    AiAssistantUserMessage,
    AiAssistantEmptyState,
    AiAssistantMessageDivider,
    AiAssistantEndOfConversation,
    AiAssistantErrorMessage
  },
  roles: Role,
  computed: {
    ...mapGetters("ai", [
      "conversations",
      "error",
      "isLoadingConversations",
      "awaitingAssistantResponse"
    ]),
    showEmptyState() {
      return !this.conversations.length && !this.awaitingAssistantResponse;
    }
  },
  methods: {
    getConversationHeader(conversationIndex, conversation) {
      return getConversationHeader(
        this.conversations,
        conversationIndex,
        conversation,
        this._i18n.locale
      );
    },
    isLastMessage(conversationIndex, messageIndex) {
      const lastConversation =
        this.conversations[this.conversations.length - 1];
      return (
        lastConversation &&
        conversationIndex === this.conversations.length - 1 &&
        messageIndex === lastConversation.messages.length - 1
      );
    }
  }
};
</script>
