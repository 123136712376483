import Auth, * as AmplifyModules from "aws-amplify";
import aws_exports from "./../aws-exports";
import { AmplifyPlugin } from "aws-amplify-vue";

export function setupAWS(Vue) {
  var customUserpoolSettings = JSON.parse(
    localStorage.getItem("customUserpoolSettings")
  );
  if (customUserpoolSettings) {
    aws_exports.aws_user_pools_id = customUserpoolSettings.userpoolId;
    aws_exports.aws_user_pools_web_client_id =
      customUserpoolSettings.appClientId;
    aws_exports.oauth.domain = customUserpoolSettings.userpoolDomain;
  } else {
    if (import.meta.env.VITE_APP_AWS_USER_POOLS_ID) {
      aws_exports.aws_user_pools_id =
        import.meta.env.VITE_APP_AWS_USER_POOLS_ID;
    }

    if (import.meta.env.VITE_APP_AWS_COGNITO_IDENTITY_POOL_ID) {
      aws_exports.aws_user_pools_web_client_id =
        import.meta.env.VITE_APP_AWS_USER_POOLS_WEB_CLIENT_ID;
    }

    if (import.meta.env.VITE_APP_USER_POOL_DOMAIN) {
      aws_exports.oauth.domain = import.meta.env.VITE_APP_USER_POOL_DOMAIN;
    }
  }

  if (import.meta.env.VITE_APP_AWS_USER_POOLS_WEB_CLIENT_ID) {
    aws_exports.aws_cognito_identity_pool_id =
      import.meta.env.VITE_APP_AWS_COGNITO_IDENTITY_POOL_ID;
  }

  if (import.meta.env.VITE_APP_REDIRECT_URL) {
    aws_exports.oauth.redirectSignIn = import.meta.env.VITE_APP_REDIRECT_URL;
    aws_exports.oauth.redirectSignOut = import.meta.env.VITE_APP_REDIRECT_URL;
  }

  aws_exports.API = {
    endpoints: [
      {
        endpoint: import.meta.env.VITE_APP_BASE_URL,
        name: "ApiGateway"
      },
      {
        endpoint: import.meta.env.VITE_WEBSOCKET_BASE_URL,
        name: "ApiGatewayWebSocket"
      }
    ]
  };

  aws_exports.storage = localStorage;
  Auth.configure(aws_exports);

  Vue.use(AmplifyPlugin, AmplifyModules);
}
