<template>
  <v-card
    rounded="lg"
    class="px-5 py-3 fill-height d-flex flex-column justify-center report-information-card"
  >
    <div class="d-flex justify-center">
      <img :src="icon" width="140" height="80" alt="" />
    </div>

    <p class="text-h6 vitrueDarkGrey--text font-weight-medium my-4 text-center">
      {{ $t(`driverAssessment.report.summary.whatThisMeans.title`) }}
    </p>
    <p class="text-body-2 vitrueDarkGrey--text" id="summaryText">
      {{ summaryText }}
    </p>
    <p class="text-body-2 vitrueDarkGrey--text" id="painContributionText">
      {{ painContributionText }}
    </p>
  </v-card>
</template>

<script>
import { GetScoreRatingName } from "@/services/driver-assessment/driver-assessment-scores.js";
import ScoreService from "@/services/driver-assessment/driver-score-service.js";
import PainService from "@/services/assessment/pain/pain-assessment-service.js";
import constants from "@/components/common/assessments/answer-constants";

const baseText = "driverAssessment.report.summary.whatThisMeans";
export default {
  props: {
    results: {
      type: Object
    }
  },
  computed: {
    score() {
      return ScoreService.getOverallScore(this.results);
    },
    summaryText() {
      const rating = GetScoreRatingName(this.score);
      return this.$t(`${baseText}.content.${rating}.summary`);
    },
    painContributionText() {
      const rating = GetScoreRatingName(this.score);
      const hasPain = PainService.hasPain(this.results);
      return this.$t(
        `${baseText}.content.${rating}.${hasPain ? "withPain" : "noPain"}`
      );
    },
    icon() {
      switch (this.results.vehicle.type) {
        case constants.DRIVER.VEHICLE_TYPE.TRUCK:
          return "/images/driver-assessment/truck.svg";
        case constants.DRIVER.VEHICLE_TYPE.VAN:
          return "/images/driver-assessment/van.svg";
        default:
          return "/images/driver-assessment/car.svg";
      }
    }
  }
};
</script>
