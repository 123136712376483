<template>
  <v-card
    class="my-2 py-5 report-information-card"
    :class="{
      'd-flex justify-center flex-grow-1': sideHeader
    }"
  >
    <v-row v-if="verticalLayout === true" class="px-5">
      <v-col cols="12">
        <v-col justify="center" class="py-2">
          <div class="d-flex justify-center mb-2">
            <v-img
              :src="`/images/${imageUrl}`"
              contain
              max-width="125px"
              eager
            />
          </div>
          <div
            class="text-center text-h6 vitrueDarkGrey--text font-weight-medium"
          >
            <v-icon v-if="titleIcon" :color="color" class="mr-2 my-1">{{
              titleIcon
            }}</v-icon
            >{{ title }}
          </div>
          <div
            v-if="titleScore"
            id="titleScoreText"
            class="text-center text-h4"
            :style="barColourCSS"
            v-text="titleScore"
          />
        </v-col>
        <div
          id="divider"
          class="horizontal-bar rounded-lg"
          :style="barColourCSS"
        />
      </v-col>
      <v-col cols="12">
        <relevant-to-condition-label
          class="mb-3"
          :results="results"
          :infoCardId="type"
        />
        <div class="text-wrap text-body-1">
          <p
            v-for="(item, index) in text"
            :key="item"
            :class="index !== text.length - 1 ? 'mb-2' : ''"
            v-html="item"
          />
        </div>
        <div v-if="warningText">
          <v-row class="text-body-1" v-for="text in warningText" :key="text">
            <v-col cols="2" align-self="center">
              <v-row justify="center">
                <v-icon large color="red">warning</v-icon>
              </v-row>
            </v-col>
            <v-col cols="10">
              <p class="mb-0" v-html="text"></p>
            </v-col>
          </v-row>
        </div>
        <slot></slot>
      </v-col>
    </v-row>

    <v-row
      v-if="verticalLayout === false"
      justify="center"
      align="center"
      align-content="center"
    >
      <v-col cols="3" class="px-3">
        <v-row justify="center" class="mb-2">
          <v-img :src="`/images/${imageUrl}`" contain max-width="125px" eager />
        </v-row>
        <div
          class="text-center text-h6 vitrueDarkGrey--text font-weight-medium"
        >
          <v-icon v-if="titleIcon" :color="color" class="mr-2 my-1">{{
            titleIcon
          }}</v-icon
          >{{ title }}
        </div>
        <div
          v-if="titleScore"
          id="titleScoreText"
          class="text-center text-h4"
          :style="barColourCSS"
          v-text="titleScore"
        />
      </v-col>
      <v-col cols="1" class="align-self-stretch">
        <div
          id="divider"
          class="vertical-bar rounded-lg"
          :style="barColourCSS"
        />
      </v-col>
      <v-col cols="7" align-self="center">
        <relevant-to-condition-label
          class="mb-3"
          :results="results"
          :infoCardId="type"
        />
        <div class="text-wrap text-body-1">
          <p
            v-for="(item, index) in text"
            :key="index"
            :class="index !== text.length - 1 ? 'mb-2' : ''"
            v-html="item"
          />
        </div>
        <slot></slot>
        <div v-if="warningText">
          <v-row class="text-body-1" v-for="text in warningText" :key="text">
            <v-col cols="2" align-self="center">
              <v-row justify="center">
                <v-icon large color="red">warning</v-icon>
              </v-row>
            </v-col>
            <v-col cols="10">
              <p class="mb-0" v-html="text"></p>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
    <information-card-feedback
      :title="title"
      :text="text"
      class="pl-5 information-card-feedback"
      v-if="showCardFeedback"
    />

    <ai-seed-question-container
      v-if="seedQuestions.length"
      :items="seedQuestions"
      class="ai-seed-question-container px-9 no-print"
      nav-button-offset="1.5rem"
    />
  </v-card>
</template>

<script>
import InformationCardFeedback from "@/components/common/report/cards/InformationCardFeedback.vue";
import { assessmentTypes } from "@/constants/constants.js";
import { viewingOwnResults } from "@/services/privacy-service.js";
import RelevantToConditionLabel from "@/components/assessment/pre-existing-conditions/RelevantToConditionLabel.vue";
import SeedQuestionService from "@/services/ai/ai-seed-questions-report-card.js";
import AiSeedQuestionContainer from "@/components/common/report/cards/AiSeedQuestionContainer.vue";
import { mapGetters } from "vuex";

export default {
  name: "InformationCard",
  components: {
    InformationCardFeedback,
    RelevantToConditionLabel,
    AiSeedQuestionContainer
  },
  props: {
    title: String,
    titleScore: String,
    text: Array,
    imageUrl: String,
    sideHeader: Boolean,
    warningText: Array,
    color: String,
    verticalAlignment: {
      type: String,
      default: "align-center"
    },
    assessmentType: String,
    type: String,
    results: Object
  },
  data() {
    return {
      seedQuestions: []
    };
  },
  computed: {
    ...mapGetters(["isSimpleUser", "hideAiFeatures"]),
    verticalLayout() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        default:
          return this.sideHeader === false;
      }
    },
    barColourCSS() {
      return {
        "--color": this.color
      };
    },
    showCardFeedback() {
      const typesToShowFeedback = [
        assessmentTypes.physicalLabourAssessment,
        assessmentTypes.driverAssessment
      ];

      return (
        typesToShowFeedback.includes(this.assessmentType) &&
        viewingOwnResults(this.$route)
      );
    },
    titleIcon() {
      switch (this.color) {
        case this.$vuetify.theme.currentTheme.success:
          return "mdi-check-circle";
        case this.$vuetify.theme.currentTheme.warning:
          return "mdi-alert-circle";
        default:
          return null;
      }
    },
    canSeeSeedQuestions() {
      return (
        !this.hideAiFeatures &&
        !this.isSimpleUser &&
        !viewingOwnResults(this.$route)
      );
    }
  },
  methods: {
    setSeedQuestions() {
      if (!this.canSeeSeedQuestions) return;

      if (this.assessmentType === assessmentTypes.deskAssessment) {
        this.seedQuestions = SeedQuestionService.getSeedQuestionsForSetup(
          this.results,
          this.type
        );
      }
    }
  },
  created() {
    this.setSeedQuestions();
  }
};
</script>

<style scoped lang="scss">
#divider {
  background-color: var(--color);
}
#titleScoreText {
  color: var(--color);
}
.vertical-image-container {
  width: 125px;
  height: 125px;
  overflow: hidden;
  border-radius: 8px;
}

.report-information-card {
  display: flex;
  flex-direction: column;

  > .row {
    flex: 0;
  }

  .information-card-feedback,
  .ai-seed-question-container {
    margin-top: auto;
  }
}
</style>
