import { HistoryItem } from "./historyItem";
import { HISTORY_TYPES } from "@/components/user-profile/history/constants.js";

export class AssessmentHistoryItem extends HistoryItem {
  constructor(
    assessmentId,
    timestamp,
    historyState,
    score,
    location,
    assessmentType
  ) {
    super(timestamp, HISTORY_TYPES.ASSESSMENT);
    this.assessmentId = assessmentId;
    this.historyState = historyState;
    this.score = score;
    this.location = location;
    this.assessmentType = assessmentType;
  }
}
