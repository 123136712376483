<template>
  <v-expansion-panel
    class="pb-2 mt-6"
    style="background-color: transparent"
    :class="$vuetify.breakpoint.xs ? 'mobile-padding' : ''"
  >
    <v-expansion-panel-header
      :class="$vuetify.breakpoint.xs ? 'text-subtitle-1' : 'text-h6'"
      color="vitrueMidGrey"
    >
      <slot name="header"></slot>
    </v-expansion-panel-header>
    <v-expansion-panel-content class="pt-2">
      <slot name="content"></slot>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
export default {
  name: "vfl-panel"
};
</script>

<style lang="scss" scoped>
::v-deep .v-expansion-panel-header {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  min-height: 40px !important;
}

.mobile-padding ::v-deep .v-expansion-panel-content__wrap {
  padding-right: 4px !important;
  padding-left: 4px !important;
}
</style>
