<template>
  <div class="who-needs-attention-container">
    <who-needs-attention-header :row-count="rows?.length" class="mb-4" />

    <vfl-alert
      v-if="error && !isLoading"
      :message="$t('needsAttentionTable.error')"
      type="error"
      is-light
    />
    <who-needs-attention-table
      v-else
      :rows="rows"
      :isLoading="isLoading"
      @row-click="handleRowClick"
    />
  </div>
</template>

<script>
import WhoNeedsAttentionHeader from "@/components/dashboard/tables/WhoNeedsAttentionHeader.vue";
import WhoNeedsAttentionTable from "@/components/dashboard/tables/WhoNeedsAttentionTable.vue";
import { getTeamRisksForcurrentUser } from "@/api/teamApi.js";
import VflAlert from "@/components/vitrue-foundation-library/VflAlert.vue";
import VflSimpleTable from "@/components/vitrue-foundation-library/table/VflSimpleTable.vue";
import { mapActions, mapGetters, mapState } from "vuex";
import { sendEvent } from "@/services/analytics/analytics.js";

export default {
  name: "WhoNeedsAttention",
  components: {
    VflSimpleTable,
    VflAlert,
    WhoNeedsAttentionTable,
    WhoNeedsAttentionHeader
  },
  data() {
    return {
      isLoading: true,
      error: false,
      rows: null
    };
  },
  computed: {
    ...mapState("adminDashboard", ["refreshTrigger"]),
    ...mapGetters({ adminId: "userId" }),
    ...mapGetters("sidePanelStore", ["selectedPersonId"])
  },
  watch: {
    refreshTrigger() {
      this.getData();
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    ...mapActions("sidePanelStore", ["open"]),
    async getData() {
      this.isLoading = true;
      this.error = false;

      try {
        this.rows = await getTeamRisksForcurrentUser();
      } catch (error) {
        this.error = true;
        this.rows = null;
        this.$logger.captureException(error);
      } finally {
        this.isLoading = false;
      }
    },
    handleRowClick({ id }) {
      this.open(id);

      sendEvent("MSK Passport opened", {
        from: "Who needs attention",
        end_user: this.selectedPersonId,
        admin: this.adminId
      });
    }
  }
};
</script>
