import { inviteUser, sendAssessmentReminders } from "@/customApi";
import {
  ExternalNotificationProviders,
  assessmentTypes
} from "@/constants/constants";
import i18n from "@/plugins/i18n.js";
import mixpanel from "mixpanel-browser";

const getInitialState = () => ({
  showDialog: false,
  users: [],
  emailInvitePurpose: null,
  notificationType: "invite",
  selectedAssessmentType: {
    type: assessmentTypes.deskAssessment,
    label: i18n.t(`assessmentTypes.${assessmentTypes.deskAssessment}`)
  },
  sending: false,
  successfulUsers: [],
  failedUsers: [],
  selectedProvider: ExternalNotificationProviders.EMAIL,
  lastUsedProvider: null
});

export const peopleTableNotificationStore = {
  namespaced: true,
  state: getInitialState(),
  mutations: {
    SET_DIALOG_VISIBILITY(state, visible) {
      state.showDialog = visible;
    },
    RESET_STATE(state) {
      Object.assign(state, getInitialState());
    },
    SET_USERS(state, users) {
      state.users = users;
    },
    SET_EMAIL_INVITE_PURPOSE(state, purpose) {
      state.emailInvitePurpose = purpose;
    },
    SET_NOTIFICATION_TYPE(state, type) {
      state.notificationType = type;
    },
    SET_SELECTED_ASSESSMENT_TYPE(state, assessmentType) {
      state.selectedAssessmentType = assessmentType;
    },
    SET_SENDING(state, isSending) {
      state.sending = isSending;
    },
    SET_SUCCESSFUL_USERS(state, users) {
      state.successfulUsers = users;
    },
    SET_FAILED_USERS(state, users) {
      state.failedUsers = users;
    },
    SET_SELECTED_PROVIDER(state, provider) {
      state.selectedProvider = provider;
    },
    SET_LAST_USED_PROVIDER(state, provider) {
      state.lastUsedProvider = provider;
    }
  },
  actions: {
    openDialog({ commit }, { users, notificationType, emailInvitePurpose }) {
      commit("SET_USERS", users);
      commit("SET_NOTIFICATION_TYPE", notificationType);
      commit("SET_EMAIL_INVITE_PURPOSE", emailInvitePurpose);
      commit("SET_DIALOG_VISIBILITY", true);
    },
    closeDialog({ commit }) {
      commit("SET_DIALOG_VISIBILITY", false);
      commit("RESET_STATE");
    },
    updateAssessmentType({ commit }, assessmentType) {
      commit("SET_SELECTED_ASSESSMENT_TYPE", assessmentType);
    },
    updateProvider({ commit }, provider) {
      commit("SET_SELECTED_PROVIDER", provider);
    },
    async sendNotification({ commit, state, dispatch }) {
      commit("SET_SENDING", true);
      commit("SET_LAST_USED_PROVIDER", state.selectedProvider);

      try {
        if (state.notificationType === "invite") {
          await dispatch("sendInvites");
        } else {
          await dispatch("sendReminders");
        }

        mixpanel.track(`Assessment ${state.notificationType}s sent`, {
          userCount: state.users.length
        });
      } catch (e) {
        console.error(e);
      } finally {
        commit("SET_SENDING", false);
      }
    },
    async sendInvites({ commit, state }) {
      const successfulUsers = [];
      const failedUsers = [];

      for (const user of state.users) {
        if (!state.showDialog) {
          commit("RESET_STATE");
          break;
        }

        try {
          const invitation = {
            userId: user.id,
            assessmentType: state.selectedAssessmentType.type,
            emailInvitePurpose: state.emailInvitePurpose,
            sendInviteOnly: true,
            externalNotificationProvider: state.selectedProvider
          };

          await inviteUser(i18n.locale, invitation);
          successfulUsers.push(user);
        } catch (e) {
          failedUsers.push(user);
          console.error(e);
        }
      }
      commit("SET_SUCCESSFUL_USERS", successfulUsers);
      commit("SET_FAILED_USERS", failedUsers);
    },
    async sendReminders({ commit, state }) {
      try {
        await sendAssessmentReminders(
          state.users.map(user => user.id),
          state.selectedProvider
        );
        commit("SET_SUCCESSFUL_USERS", state.users);
      } catch (e) {
        console.error(e);
        commit("SET_FAILED_USERS", state.users);
      }
    }
  },
  getters: {
    showResults: state =>
      state.successfulUsers.length > 0 || state.failedUsers.length > 0,
    showAssessmentTypeDropdown: state => state.notificationType === "invite",
    isProviderDisabled: state => provider => {
      return (
        (provider === ExternalNotificationProviders.SLACK ||
          provider === ExternalNotificationProviders.MSTEAMS) &&
        state.selectedAssessmentType.type !== assessmentTypes.deskAssessment
      );
    }
  }
};
