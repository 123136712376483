import "./init";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import i18n from "@/plugins/i18n";

import { setupAWS } from "@/config/aws";

import { setupAnalytics } from "@/config/analytics";
import { setupPlugins } from "@/config/plugins";
import { MobileCheckPlugin } from "@/config/mobile-check";
import sentry from "@/sentry";
import "vue-tour/dist/vue-tour.css";
import "./assets/css/style.css";
import "@/styles/global.scss";

setupAWS(Vue);
setupPlugins(Vue);
setupAnalytics(Vue, router);

Vue.use(MobileCheckPlugin);

Vue.config.productionTip = false;
Vue.config.performance = true;

Vue.prototype.$logger = sentry;

new Vue({
  i18n,
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
