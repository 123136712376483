<template>
  <div
    class="label-container d-inline-flex"
    :style="{ 'background-color': backgroundColor }"
  >
    <h6 class="text-subtitle-1" :style="{ color: textColor }">
      {{ text }}
    </h6>
  </div>
</template>

<script>
export default {
  name: "AverageScoreCardLabel",
  props: {
    backgroundColor: String,
    text: String,
    textColor: String
  }
};
</script>

<style scoped>
.label-container {
  padding: 6px 12px;
  border-radius: 6px;
}
</style>
