import sentry from "@/sentry";

export class BaseTextModule {
  constructor(content = {}) {
    this.content = content;
  }

  getText(key, params = {}) {
    const text = this.content[key];
    if (!text) {
      sentry.captureException(
        `Menopause assessment: Text key "${key}" not found in module`
      );
      return "";
    }
    return this.interpolateParams(text, params);
  }

  interpolateParams(text, params) {
    return Object.entries(params).reduce(
      (result, [key, value]) => result.replace(`{{${key}}}`, value),
      text
    );
  }
}
