<template>
  <div>
    <div v-if="isOpen && attachTarget" class="custom-overlay"></div>

    <v-dialog
      v-model="isOpen"
      max-width="800px"
      :hide-overlay="!!attachTarget"
      :attach="attachTarget"
      :class="{ 'attached-z-index': !!attachTarget }"
      persistent
    >
      <v-card class="ai-feature-introduction-dialog-container"
        ><v-btn class="close-button" icon @click="closeDialog">
          <v-icon color="vflGreyDark">mdi-close</v-icon>
          <span class="d-sr-only">{{ $t("buttons.close") }}</span>
        </v-btn>

        <transition name="fade" mode="out-in">
          <component
            :is="currentStep.component"
            :key="currentStepIndex"
            :hideRoleQuestion="roleAlreadySet"
            @next-step="nextStep"
            @go-back="goBack"
            @close-dialog="closeDialog"
          ></component>
        </transition>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import FeatureService from "@/services/new-feature-service.js";
import AiFeatureIntroductionForm from "@/components/common/ai/feature-introduction/AiFeatureIntroductionForm.vue";
import AiFeatureIntroductionStart from "@/components/common/ai/feature-introduction/AiFeatureIntroductionStart.vue";
import { mapGetters } from "vuex";

export default {
  name: "AiFeatureIntroductionDialog",
  components: {
    AiFeatureIntroductionStart,
    AiFeatureIntroductionForm
  },
  data() {
    return {
      isOpen: true,
      currentStepIndex: 0,
      steps: [
        { component: "ai-feature-introduction-start" },
        { component: "ai-feature-introduction-form" }
      ],
      roleAlreadySet: false
    };
  },
  props: {
    attach: {
      type: String,
      default: null
    },
    alwaysShowRoleQuestion: {
      type: Boolean,
      default: false
    }
  },
  created() {
    if (!this.alwaysShowRoleQuestion) {
      this.roleAlreadySet = !!this.companyRole;
    }
  },
  async beforeDestroy() {
    await FeatureService.markFeatureSeen("AiIntroductionDialog", true);
  },
  computed: {
    ...mapGetters(["companyRole"]),
    currentStep() {
      return this.steps[this.currentStepIndex];
    },
    attachTarget() {
      return this.attach ? `.${this.attach}` : undefined;
    }
  },
  methods: {
    closeDialog() {
      this.isOpen = false;
      this.$emit("close");
    },
    nextStep() {
      if (this.currentStepIndex < this.steps.length - 1) {
        this.currentStepIndex++;
      } else {
        this.closeDialog();
      }
    },
    goBack() {
      if (this.currentStepIndex > 0) {
        this.currentStepIndex--;
      }
    }
  }
};
</script>

<style scoped>
.custom-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.46);
  z-index: 6 !important;
}

.ai-feature-introduction-dialog-container {
  background-image: url("/images/ai-gradient-background.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.close-button {
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  z-index: 1;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.25s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

/* These styles need to be global to override Vuetify's default styles */
::v-deep .v-dialog {
  border-radius: 24px;
  overflow-y: auto;
  box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2),
    0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12) !important;
}

::v-deep .v-dialog.rounded-dialog > .v-card {
  border-radius: 24px !important;
}

.attached-z-index {
  z-index: 7 !important;
}
</style>
